import { JobNoteModel } from "@dwo/shared/dist/models/jobNoteModel";
import {
  WorkShiftModel,
  WorkShiftType,
} from "@dwo/shared/dist/models/workShiftModel";
import {
  Box,
  Button,
  createStyles,
  Grid,
  IconButton,
  makeStyles,
  Paper,
  Theme,
  Typography,
} from "@material-ui/core";
import { ArrowBack, Event } from "@material-ui/icons";
import { EmployeeSummary } from "components/employeeTimesheet/EmployeeSummary";
import {
  displayEmployeeTimesheetColumns,
  editEmployeeTimesheetColumns,
  formatDateHeader,
  getDisplayEmployeeTimesheetRows,
  getEditEmployeeTimesheetRows,
  getEmployeeTimesheetsByDaysTableData,
  getInitialDateRange,
} from "components/employeeTimesheet/employeeTimesheetUtils";
import { findUpdatedValue } from "components/timesheetDetails/crewOverviewUtils";
import { Layout } from "components/layout/Layout";
import { LoadingSpinner } from "components/LoadingSpinner";
import { NotesModal } from "components/jobDetails/notesModal/NotesModal";
import { RangeDatePicker } from "components/RangeDatePicker";
import { TableCustom } from "components/table/TableCustom";
import { isSameDay } from "date-fns";
import { WorkShiftTableCellValue } from "features/editWorkShiftTableSlice/workShiftTableValuesSlice";
import {
  approveWorkShifts,
  clearValues,
  getAllWorkShiftsById,
  selectCurrentEmployee,
  selectDateRange,
  selectEmployeeWorkShifts,
  selectGeneralStatus,
  selectIsLoading,
  selectValues,
  selectWorkShiftTotalHours,
  setEmployeeWorkShifts,
  updateWorkShifts,
} from "features/employeeTimesheet/employeeTimesheetSlice";
import {
  createJobNote,
  deleteJobNote,
  selectIsLoading as selectIsLoadingAllComments,
  selectIsLoadingEditing,
  updateJobNote,
} from "features/jobs/jobNotes/jobNotesSlice";
import {
  prompt,
  selectResponse,
  setResponse,
} from "features/prompt/promptSlice";
import { cloneDeep, countBy } from "lodash";
import { DateRange } from "materialui-daterange-picker";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { ROOT_TIMESHEETS_MANAGEMENT } from "routes/Roots";
import { decimalTimeToHoursTime, enDashFormatDate } from "utils/dateUtils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backButton: {
      marginRight: "24px",
      padding: 0,
    },
    backIcon: {
      color: theme.palette.primary.main,
    },
    backText: {
      fontSize: "32px",
      color: theme.palette.primary.main,
      fontWeight: 600,
    },
    datePickerContainer: {
      alignItems: "center",
      display: "flex",
      height: "100%",
      justifyContent: "flex-end",
      margin: "0 24px",
      [theme.breakpoints.down("xs")]: {
        justifyContent: "flex-start",
      },
    },
    calendarIcon: { zIndex: 1, color: theme.palette.primary.dark },
    paper: {
      backgroundColor: "white",
      display: "flex",
      margin: "0 24px 8px 24px",
    },
    subTitle: {
      color: theme.palette.grey["700"],
      fontWeight: "bold",
    },
    totalHoursContainer: {
      backgroundColor: theme.palette.grey["100"],
      boxShadow:
        "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      margin: "0 24px",
      padding: "12px 40px",
    },
  }),
);

export function EmployeeTimesheetScreen() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const employeeId = parseInt(id, 10);
  const initialDateRange = useRef(getInitialDateRange());
  const currentEmployee = useSelector(selectCurrentEmployee);
  const dateRange = useSelector(selectDateRange);
  const employeeWorkShifts = useSelector(selectEmployeeWorkShifts);
  const generalStatus = useSelector(selectGeneralStatus);
  const isLoadingAllComments = useSelector(selectIsLoadingAllComments);
  const isLoadingEditingComment = useSelector(selectIsLoadingEditing);
  const isLoadingWorkshifts = useSelector(selectIsLoading);
  const promptResponse = useSelector(selectResponse);
  const values = useSelector(selectValues);
  const workShiftsTotalDecimal = useSelector(selectWorkShiftTotalHours);
  const workShiftsTotalHours = decimalTimeToHoursTime(workShiftsTotalDecimal);

  const [endDate, setEndDate] = useState<Date | undefined>(
    dateRange.endDate
      ? new Date(dateRange.endDate)
      : initialDateRange.current.endDate,
  );
  const [startDate, setStartDate] = useState<Date | undefined>(
    dateRange.startDate
      ? new Date(dateRange.startDate)
      : initialDateRange.current.startDate,
  );
  const [currentJobIdForComments, setCurrentJobIdForComments] = useState<
    number | undefined
  >();
  const [currentComments, setCurrentComments] = useState<JobNoteModel[]>([]);
  const [deleteCommentId, setDeleteCommentId] = useState<number | undefined>(
    undefined,
  );
  const [isApproving, setIsApproving] = useState<boolean>(false);
  const [isCommentsModalOpen, setIsCommentsModalOpen] = useState<boolean>(
    false,
  );
  const [isCreatingComment, setIsCreatingComment] = useState<boolean>(false);
  const [isEditModeActive, setIsEditModeActive] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isTimeOffChecked, setIsTimeOffChecked] = useState(false);
  const [isUpdateDisabled, setIsUpdateDisabled] = useState(true);
  const [isSameDaySelected, setIsSameDaySelected] = useState<boolean>(
    startDate && endDate ? isSameDay(startDate, endDate) : true,
  );
  const [
    isDisplayingSingleWorkshift,
    setIsDisplayingSingleWorkshift,
  ] = useState<boolean>(false);
  const [sortingOptions, setSortingOptions] = useState<string[][]>([]);
  const [timeOffSelectedValue, setTimeOffSelectedValue] = useState<string>(
    WorkShiftType.HOLIDAY,
  );
  const classes = useStyles();

  const isLoadingComments = isLoadingAllComments || isLoadingEditingComment;

  const getDisplayRows = useCallback(() => {
    if (currentEmployee) {
      return getDisplayEmployeeTimesheetRows(
        employeeWorkShifts,
        currentEmployee,
        isLoadingAllComments,
        handleClickCommentsLink,
        currentJobIdForComments,
      );
    }

    return [];
  }, [
    currentEmployee,
    currentJobIdForComments,
    employeeWorkShifts,
    isLoadingAllComments,
  ]);

  const getEditRows = useCallback(() => {
    if (currentEmployee) {
      return getEditEmployeeTimesheetRows(
        employeeWorkShifts,
        currentEmployee,
        isEditModeActive,
        isTimeOffChecked,
        timeOffSelectedValue,
        values,
        isLoadingAllComments,
        handleClickCommentsLink,
        currentJobIdForComments,
      );
    }

    return [];
  }, [
    currentEmployee,
    currentJobIdForComments,
    employeeWorkShifts,
    isEditModeActive,
    isLoadingAllComments,
    isTimeOffChecked,
    timeOffSelectedValue,
    values,
  ]);

  useEffect(() => {
    if (isLoadingWorkshifts) {
      setIsLoading(true);
      return;
    }
    setIsLoading(false);
  }, [isLoadingWorkshifts]);

  useEffect(() => {
    if (isDisplayingSingleWorkshift) return;

    dispatch(
      getAllWorkShiftsById(employeeId, {
        include: ["Region"],
        order: sortingOptions.length > 0 ? sortingOptions : undefined,
        where: {
          date: {
            $gte: enDashFormatDate(startDate as Date),
            $lte: enDashFormatDate(endDate as Date),
          },
        },
      }),
    );
  }, [
    dispatch,
    employeeId,
    isDisplayingSingleWorkshift,
    sortingOptions,
    startDate,
    endDate,
  ]);

  useEffect(() => {
    if (!startDate && !endDate) {
      setStartDate(
        dateRange.startDate
          ? new Date(dateRange.startDate)
          : initialDateRange.current.startDate,
      );
      setEndDate(
        dateRange.endDate
          ? new Date(dateRange.endDate)
          : initialDateRange.current.endDate,
      );
    }

    setIsSameDaySelected(
      startDate && endDate ? isSameDay(startDate, endDate) : true,
    );
  }, [dateRange, startDate, endDate]);

  useEffect(() => {
    setSortingOptions([]);
  }, [isSameDaySelected]);

  useEffect(() => {
    if (isApproving && promptResponse) {
      const workshiftIds = employeeWorkShifts.map(({ id }) => id);

      if (workshiftIds.length > 0) {
        dispatch(
          approveWorkShifts({ workshiftIds }, employeeId, {
            include: ["Region"],
            order: sortingOptions.length > 0 ? sortingOptions : undefined,
            where: {
              date: { $gte: startDate, $lte: endDate },
            },
          }),
        );
      }

      setIsApproving(false);
      dispatch(setResponse(false));
    }
  }, [
    promptResponse,
    dispatch,
    employeeId,
    employeeWorkShifts,
    endDate,
    isApproving,
    sortingOptions,
    startDate,
  ]);

  useEffect(() => {
    if (!isEditModeActive) {
      dispatch(clearValues());
    }

    if (!isTimeOffChecked) {
      dispatch(clearValues());
    }
  }, [isEditModeActive, isTimeOffChecked, dispatch]);

  useEffect(() => {
    const ids = values.map((value) => [value.idRow]);
    const countById = Object.values(countBy(ids));
    setIsUpdateDisabled(
      countById.length > 0
        ? !countById.every((count) => count % 3 === 0)
        : true,
    );
  }, [values]);

  useEffect(() => {
    if (currentJobIdForComments) {
      const comments = employeeWorkShifts.find(
        (workshift: WorkShiftModel) => workshift.id === currentJobIdForComments,
      );

      setCurrentComments(comments?.notes || []);
    }
  }, [currentJobIdForComments, employeeWorkShifts]);

  useEffect(() => {
    if (!deleteCommentId || !promptResponse || !currentJobIdForComments) return;

    dispatch(
      deleteJobNote(deleteCommentId, undefined, currentJobIdForComments),
    );
    setDeleteCommentId(undefined);
    dispatch(setResponse(false));
  }, [currentJobIdForComments, deleteCommentId, promptResponse, dispatch]);

  const handleClickAddComment = () => setIsCreatingComment(true);

  const handleClickApplyRangeDate = (dateRange: DateRange) => {
    const startDate = dateRange.startDate;
    const endDate = dateRange.endDate;
    startDate?.setHours(0, 0, 0, 0);
    endDate?.setHours(23, 59, 59, 999);
    setIsDisplayingSingleWorkshift(false);
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const handleClickApprove = () => {
    setIsApproving(true);
    dispatch(
      prompt({
        title: "You Are Approving This Job Timesheet",
        message: `Do you want to continue to approve the timesheet?`,
      }),
    );
  };

  const handleClickBack = () => {
    history.push(ROOT_TIMESHEETS_MANAGEMENT);
  };

  const handleClickCancelComment = () => {
    setIsCreatingComment(false);
    setCurrentJobIdForComments(undefined);
  };

  const handleClickCloseCommentsModal = () => {
    setIsCommentsModalOpen(false);
    setIsCreatingComment(false);
    setCurrentJobIdForComments(undefined);
  };

  const handleClickCommentsLink = (
    jobId: number,
    isAddingComment?: boolean,
  ) => {
    setCurrentJobIdForComments(jobId);
    setIsCreatingComment(!!isAddingComment);
    setIsCommentsModalOpen(true);
  };

  const handleClickDeleteComment = (commentId: string) => {
    setDeleteCommentId(parseInt(commentId, 10));
    dispatch(
      prompt({
        title: "Remove Comment?",
        message: `Are you sure you want to remove the comment from this Job?`,
      }),
    );
  };

  const handleClickEdit = () => {
    if (isTimeOffChecked && !isEditModeActive) {
      setIsTimeOffChecked(false);
    }

    if (isEditModeActive && !isTimeOffChecked) {
      setIsEditModeActive(false);
    }

    if (!isEditModeActive && !isTimeOffChecked) {
      setIsEditModeActive(true);
    }
  };

  const handleClickSaveComment = (commentText: string, commentId?: string) => {
    if (!currentJobIdForComments) return;

    if (commentId && currentComments.length > 0) {
      const selectedComment = currentComments.find(
        (comment: JobNoteModel) =>
          (comment.id as number) === parseInt(commentId, 10),
      );

      if (selectedComment) {
        const updatedComment = cloneDeep(selectedComment);

        updatedComment.dwoId = updatedComment.dwoId || undefined;
        updatedComment.note = commentText;
        updatedComment.title = updatedComment.title || undefined;
        updatedComment.workshiftId = updatedComment.workshiftId || undefined;

        dispatch(updateJobNote(commentId, updatedComment, undefined, true));

        return;
      }
    }

    const foundWorkshift = employeeWorkShifts.find(
      (workshift: WorkShiftModel) => workshift.id === currentJobIdForComments,
    );
    const jobId = foundWorkshift?.dwoJob?.job?.id;

    if (!foundWorkshift || !jobId) return;

    const dwoId =
      typeof foundWorkshift.dwoJob?.dwo?.id === "string"
        ? parseInt(foundWorkshift.dwoJob.dwo.id, 10)
        : foundWorkshift.dwoJob?.dwo?.id;
    const newComment: JobNoteModel = {
      dwoId,
      jobId: typeof jobId === "string" ? parseInt(jobId, 10) : jobId,
      note: commentText,
      workshiftId: currentJobIdForComments,
    };

    dispatch(createJobNote(newComment, undefined, true));
    setIsCreatingComment(false);
  };

  const handleClickSorting = (sortingValues: string[][]) =>
    setSortingOptions(sortingValues);

  const handleClickTimeOff = (_: React.ChangeEvent<HTMLInputElement>) =>
    setIsTimeOffChecked(!isTimeOffChecked);

  const handleSelectTimeOffValue = (selectedValue: string) =>
    setTimeOffSelectedValue(selectedValue);

  const handleClickUpdate = () => {
    if (values.length > 0 && employeeWorkShifts) {
      const valuesById = values.reduce(
        (object: Record<number, WorkShiftTableCellValue[]>, value) => {
          object[value.idRow] = [...(object[value.idRow] || []), value];
          return object;
        },
        {},
      );

      const existingWorkShifts = employeeWorkShifts.filter(
        (workShift) =>
          workShift.id ===
          values.find((value) => value.idRow === workShift.id)?.idRow,
      );

      if (existingWorkShifts.length > 0) {
        const updatedExistingWorkShifts = existingWorkShifts.reduce(
          (updatedWorkShifts: WorkShiftModel[], workShift) => {
            const workShiftValues = valuesById[workShift.id];

            if (workShiftValues) {
              const startValue = findUpdatedValue(workShiftValues, "start")
                ?.inputValue as string;
              const endValue = findUpdatedValue(workShiftValues, "end")
                ?.inputValue as string;
              const typeValue = findUpdatedValue(workShiftValues, "type")
                ?.inputValue as string;
              const updatedWorkShift = {
                id: workShift.id,
                dwoJobId: workShift.dwoJobId,
                employeeId: workShift.employeeId,
                type: typeValue,
                start: new Date(startValue).toISOString(),
                end: new Date(endValue).toISOString(),
              };
              updatedWorkShifts.push(updatedWorkShift as WorkShiftModel);
            }

            return updatedWorkShifts;
          },
          [],
        );

        if (updatedExistingWorkShifts.length > 0) {
          dispatch(
            updateWorkShifts(
              { workshifts: updatedExistingWorkShifts },
              employeeId,
              {
                include: ["Region"],
                order: sortingOptions.length > 0 ? sortingOptions : undefined,
                where: {
                  date: { $gte: startDate, $lte: endDate },
                },
              },
            ),
          );
        }
      }

      setIsEditModeActive(false);
      setIsTimeOffChecked(false);
    }
  };

  const handleClickWorkshift = (workshift: WorkShiftModel) => {
    const start = new Date(workshift.start);
    const end = new Date(workshift.end);

    setIsDisplayingSingleWorkshift(true);
    setStartDate(start);
    setEndDate(end);
    dispatch(setEmployeeWorkShifts([cloneDeep(workshift)]));
  };

  const timesheetsByDayTableData = (() => {
    if (
      employeeWorkShifts.length === 0 ||
      isSameDaySelected ||
      !startDate ||
      !endDate
    ) {
      return { columns: [], rows: [] };
    }

    return getEmployeeTimesheetsByDaysTableData(
      employeeWorkShifts,
      startDate,
      endDate,
      handleClickWorkshift,
    );
  })();

  return (
    <Layout>
      <Grid container>
        <Grid item sm={6} xs={12}>
          <Box alignItems="center" display="flex" margin="32px 24px 24px 24px">
            <IconButton
              aria-label="Go Back"
              className={classes.backButton}
              onClick={handleClickBack}
            >
              <ArrowBack className={classes.backIcon} fontSize="large" />
            </IconButton>
            <Typography className={classes.backText} component="h1">
              Employee Timesheet
            </Typography>
          </Box>
        </Grid>

        <Grid item sm={6} xs={12}>
          <Box className={classes.datePickerContainer}>
            <Box width="260px" position="relative">
              <Box
                position="absolute"
                display="flex"
                alignItems="center"
                height="48px"
                marginLeft="8px"
              >
                <Event className={classes.calendarIcon} />
              </Box>
              <RangeDatePicker
                initDateRange={{ startDate, endDate }}
                hideShortcuts
                maxDate={new Date()}
                onClickApply={handleClickApplyRangeDate}
              />
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12}>
          {isLoading && (
            <Paper className={classes.paper}>
              <Grid item sm={12}>
                <LoadingSpinner
                  BoxProps={{ padding: 10 }}
                  CircularProgressProps={{ size: "40px" }}
                />
              </Grid>
            </Paper>
          )}
          {!isLoading && (
            <EmployeeSummary
              isChecked={isTimeOffChecked}
              isEditModeActive={isEditModeActive}
              selectedValue={timeOffSelectedValue}
              onChangeTimeOffSelectValue={handleSelectTimeOffValue}
              onClickTimeOff={handleClickTimeOff}
            />
          )}
        </Grid>

        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Box
              display="flex"
              width="100%"
              justifyContent="center"
              alignItems="center"
            >
              {currentEmployee &&
                isSameDaySelected &&
                !isEditModeActive &&
                !isTimeOffChecked && (
                  <TableCustom
                    columns={displayEmployeeTimesheetColumns}
                    noRowsMessage="There are no timesheets to display."
                    rows={getDisplayRows()}
                    sortingOptions={sortingOptions}
                    isLoading={isLoadingWorkshifts}
                    onClickSort={handleClickSorting}
                  />
                )}
              {currentEmployee &&
                isSameDaySelected &&
                startDate &&
                (isEditModeActive || isTimeOffChecked) && (
                  <TableCustom
                    columns={editEmployeeTimesheetColumns(
                      formatDateHeader(startDate),
                    )}
                    noRowsMessage="There are no timesheets to display."
                    rows={getEditRows()}
                    sortingOptions={sortingOptions}
                    isLoading={isLoadingWorkshifts}
                    onClickSort={handleClickSorting}
                  />
                )}
              {!isSameDaySelected && timesheetsByDayTableData && (
                <TableCustom
                  columns={timesheetsByDayTableData.columns}
                  footerRow={timesheetsByDayTableData.footerRow}
                  isLoading={isLoadingWorkshifts}
                  noRowsMessage="There are no timesheets to display."
                  rows={timesheetsByDayTableData.rows}
                  sortingOptions={sortingOptions}
                  onClickSort={handleClickSorting}
                />
              )}
            </Box>
          </Paper>

          {isSameDaySelected && (
            <Box className={classes.totalHoursContainer}>
              <Typography variant="body2" className={classes.subTitle}>
                Total Hours: {workShiftsTotalHours}
              </Typography>
            </Box>
          )}

          <Box display="flex" justifyContent="flex-end" margin="24px">
            {isSameDaySelected && (
              <Box width="150px">
                <Button fullWidth variant="outlined" onClick={handleClickEdit}>
                  {isTimeOffChecked || isEditModeActive ? "Cancel" : "Edit"}
                </Button>
              </Box>
            )}

            <Box width="150px" marginLeft="24px">
              {isTimeOffChecked || isEditModeActive ? (
                <Button
                  disabled={isUpdateDisabled}
                  fullWidth
                  variant="contained"
                  onClick={handleClickUpdate}
                >
                  Update
                </Button>
              ) : (
                <Button
                  disabled={generalStatus === "approved"}
                  fullWidth
                  variant="contained"
                  onClick={handleClickApprove}
                >
                  {generalStatus === "approved"
                    ? "Approved"
                    : `Approve ${workShiftsTotalHours}`}
                </Button>
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>

      {isCommentsModalOpen && (
        <NotesModal
          buttonsMaxWidth="160px"
          continueBtnText="Add New Comment"
          displayJobId
          isCreatingNote={isCreatingComment}
          isLoading={isLoadingWorkshifts || isLoadingComments}
          isOpen={isCommentsModalOpen}
          notes={currentComments}
          title="Comment History"
          onClickAdd={handleClickAddComment}
          onClickCancel={handleClickCancelComment}
          onClickClose={handleClickCloseCommentsModal}
          onClickDelete={handleClickDeleteComment}
          onClickSave={handleClickSaveComment}
        />
      )}
    </Layout>
  );
}
