import { Box, createStyles, makeStyles, Theme } from "@material-ui/core";
import {
  CheckCircle,
  ErrorRounded,
  Sync,
  Timelapse,
  WarningRounded,
} from "@material-ui/icons";
import { UPLOAD_STATUS } from "@dwo/shared/dist/models/uploadHistoryModel";
import React from "react";

interface UploadStatusProps {
  status: string;
  hideStatusText?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    failedIcon: {
      color: theme.palette.error.main,
      margin: "0 32px 0 16px",
      [theme.breakpoints.down("md")]: {
        marginRight: "16px",
      },
    },
    inProgressIcon: {
      color: theme.palette.primary.main,
      margin: "0 32px 0 16px",
      transform: "rotate(90deg)",
      [theme.breakpoints.down("md")]: {
        marginRight: "16px",
      },
    },
    pendingIcon: {
      margin: "0 32px 0 16px",
      [theme.breakpoints.down("md")]: {
        marginRight: "16px",
      },
    },
    successIcon: {
      color: theme.palette.success.main,
      margin: "0 32px 0 16px",
      [theme.breakpoints.down("md")]: {
        marginRight: "16px",
      },
    },
  }),
);

export function UploadStatus({ status, hideStatusText }: UploadStatusProps) {
  const classes = useStyles();

  if (status === "New File") {
    return (
      <Box
        alignItems="center"
        display="flex"
        justifyContent="space-between"
        width="100%"
      >
        {!hideStatusText && "New File"}
      </Box>
    );
  }

  if (status === UPLOAD_STATUS.FAILED) {
    return (
      <Box
        alignItems="center"
        display="flex"
        justifyContent="space-between"
        width="100%"
      >
        {!hideStatusText && `${UPLOAD_STATUS.FAILED}`}
        <ErrorRounded className={classes.failedIcon} fontSize="small" />
      </Box>
    );
  }

  if (status === UPLOAD_STATUS.IN_PROGRESS) {
    return (
      <Box
        alignItems="center"
        display="flex"
        justifyContent="space-between"
        width="100%"
      >
        {UPLOAD_STATUS.IN_PROGRESS}
        <Sync className={classes.inProgressIcon} fontSize="small" />
      </Box>
    );
  }

  if (status === UPLOAD_STATUS.PENDING) {
    return (
      <Box
        alignItems="center"
        display="flex"
        justifyContent="space-between"
        width="100%"
      >
        {!hideStatusText && `${UPLOAD_STATUS.PENDING}`}
        <WarningRounded className={classes.pendingIcon} fontSize="small" />
      </Box>
    );
  }

  if (status === UPLOAD_STATUS.GENERATING) {
    return (
      <Box
        alignItems="center"
        display="flex"
        justifyContent="space-between"
        width="100%"
      >
        {!hideStatusText && `${UPLOAD_STATUS.GENERATING}`}
        <Timelapse className={classes.pendingIcon} fontSize="small" />
      </Box>
    );
  }

  if (status === UPLOAD_STATUS.SUCCESSFUL) {
    return (
      <Box
        alignItems="center"
        display="flex"
        justifyContent="space-between"
        width="100%"
      >
        {!hideStatusText && `${UPLOAD_STATUS.SUCCESSFUL}`}
        <CheckCircle className={classes.successIcon} fontSize="small" />
      </Box>
    );
  }

  return (
    <Box
      alignItems="center"
      display="flex"
      justifyContent="space-between"
      width="100%"
    >
      No status
    </Box>
  );
}
