import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { cloneDeep } from "lodash";
import { DateRange } from "materialui-daterange-picker";
import {
  createStyles,
  makeStyles,
  Theme,
  Paper,
  Grid,
  Box,
  Typography,
  Button,
} from "@material-ui/core";
import RefreshIcon from "@material-ui/icons/Refresh";

import { CustomPagination as Pagination } from "components/Pagination";
import { Dropdown, DropdownOption } from "components/Dropdown";
import { DropdownPagination } from "components/DropdownPagination";
import { RangeDatePicker } from "components/RangeDatePicker";
import {
  SquareToggleButton,
  ToggleButtonOptions,
} from "components/SquareToggleButton";
import { TableCustom } from "components/table/TableCustom";
import { UploadStatus } from "components/table/UploadStatus";

import { ALL_OPTION, DEFAULT_LIMIT } from "utils/sharedUtils";
import {
  companiesOptions,
  downloadTimesheetsColumns,
  getDownloadTimesheetsRows,
} from "utils/downladTimesheetsUtils";
import { getTotalPages } from "utils/timesheetsManagementUtils";
import { rowSelectOptions } from "utils/tableUtils";
import { timesheetFileStatuses } from "utils/downladTimesheetsUtils";

//import { error } from "features/error/errorSlice";
import { selectCurrentUser } from "features/logIn/sessionSlice";
import {
  selectCount,
  selectLimit,
  selectIsLoading,
  getAllDownladTimesheets,
  getAllDownladTimesheetsCSV,
  selectDownloadTimesheets,
} from "features/downloadTimesheets/downloadTimesheetsSlice";

import { ServiceOptions } from "@dwo/shared/dist/services/baseService";
import { downloadTimesheetsService } from "@dwo/shared/dist/services/downloadTimehseetsService";
import { downloadWorkshiftCSVService } from "@dwo/shared/dist/services/downloadWorkshiftCSVService";
import { DownloadFileStatusModel } from "@dwo/shared/src/models/fileDownloadStatusModel";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      fontSize: "20px",
      color: theme.palette.primary.main,
      fontWeight: 400,
      marginBottom: "16px",
    },
    paper: {
      backgroundColor: "white",
      margin: "0 24px 24px 24px",
      padding: "24px",
      borderRadius: 0,
      position: "relative",
    },
    historyFiltersContainer: {
      justifyContent: "flex-end",

      [theme.breakpoints.down("xs")]: {
        justifyContent: "flex-start",
      },
    },
    caption: {
      fontSize: "14px",
      color: theme.palette.grey[500],
      fontWeight: 400,
      marginBottom: "16px",
    },
    paginationContainer: {
      borderRadius: "2px",
      margin: "0 24px 24px",
    },
    rowSelectLabel: {
      color: theme.palette.primary.dark,
      fontWeight: "bold",
    },
    customButton: {
      width: "100%",
    },
    statusText: {
      fontSize: "14px",
      fontWeight: 600,
      justifyContent: "center",
    },
  }),
);

const companyFilterOptions: DropdownOption[] = [
  ALL_OPTION,
  ...companiesOptions,
];

let intervalId: NodeJS.Timeout;

export function DownloadTimesheets() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const currentUser = useSelector(selectCurrentUser);
  const [company, setCompany] = useState<string>("");
  const [customCompany, setCustomCompany] = useState<string>("");
  const [customDateRange, setCustomDateRange] = useState<DateRange | undefined>(
    undefined,
  );
  const [sortingOptions, setSortingOptions] = useState<string[][]>([
    ["date", "DESC"],
  ]);
  const [page, setPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<string>(
    rowSelectOptions[0].value,
  );
  const [query, setQuery] = useState<ServiceOptions>({
    limit: DEFAULT_LIMIT,
    order: [["date", "DESC"]],
  });
  const [formatTable, setFormatTable] = useState<ToggleButtonOptions>(
    ToggleButtonOptions.Left,
  );
  const [formatCustom, setFormatCustom] = useState<ToggleButtonOptions>(
    ToggleButtonOptions.Left,
  );

  const [isGenerateDisabled, setIsGenerateDisabled] = useState<boolean>(true);
  const [csvFile, setCSVFile] = useState<DownloadFileStatusModel>();
  const [iifFile, setIIFFile] = useState<DownloadFileStatusModel>();

  const [
    triggerGetGeneratedFile,
    setTriggerGetGeneratedFile,
  ] = useState<boolean>(false);

  const downloadTimesheets = useSelector(selectDownloadTimesheets);
  const currentLimit = useSelector(selectLimit) || DEFAULT_LIMIT;
  const currentCount = useSelector(selectCount);
  const isLoading = useSelector(selectIsLoading);

  useEffect(() => {
    if (formatTable === ToggleButtonOptions.Left) {
      dispatch(getAllDownladTimesheetsCSV(query));
      return;
    }
    dispatch(getAllDownladTimesheets(query));
    // eslint-disable-next-line
  }, [dispatch, query]);

  useEffect(() => {
    setCSVFile(undefined);
    setIIFFile(undefined);
    clearInterval(intervalId);

    if (
      customCompany &&
      customDateRange &&
      customDateRange.startDate &&
      customDateRange.endDate
    ) {
      setIsGenerateDisabled(false);
      return;
    }
    setIsGenerateDisabled(true);
  }, [customDateRange, customCompany]);

  useEffect(() => {
    const getFilesStatus = async () => {
      try {
        const CSVStatus = await downloadWorkshiftCSVService.timesheetsCSVFileStatus();
        setCSVFile(CSVStatus?.data?.data);
      } catch (err) {
        console.log("No CSV File created yet");
      }

      try {
        if (currentUser?.employee.role === "admin") {
          const IFFStatus = await downloadTimesheetsService.timesheetsIFFFileStatus();
          setIIFFile(IFFStatus?.data?.data);
        }
        return;
      } catch (err) {
        console.log("No IIF File created yet");
      }
    };

    getFilesStatus();

    intervalId = setInterval(() => {
      getFilesStatus();
    }, 15000);
    return () => clearInterval(intervalId);
  }, [triggerGetGeneratedFile]);

  const currentFile =
    formatCustom === ToggleButtonOptions.Left ? csvFile : iifFile;

  const handleReload = () => {
    setTriggerGetGeneratedFile(!triggerGetGeneratedFile);
  };

  const handleSelectCompany = (selectedCompany?: string) => {
    if (selectedCompany) {
      const updatedQuery = cloneDeep(query);
      updatedQuery.offset = 0;
      updatedQuery.where = {
        ...query.where,
        company:
          selectedCompany === ALL_OPTION.value ? undefined : selectedCompany,
      };
      setPage(1);
      setCompany(selectedCompany);
      setQuery(updatedQuery);
    }
  };

  const handleSelectCustomCompany = (selectedCompany: string) => {
    setCustomCompany(selectedCompany);
  };

  const handleApplyRangeDate = (dateRange: DateRange) => {
    const startDate = dateRange.startDate;
    const endDate = dateRange.endDate;
    startDate?.setHours(0, 0, 0, 0);
    endDate?.setHours(23, 59, 59, 0);
    const updatedQuery = cloneDeep(query);
    updatedQuery.offset = 0;

    if (startDate && endDate) {
      updatedQuery.where = {
        ...query.where,
        date: { $gte: startDate, $lte: endDate },
      };
    } else {
      if (updatedQuery.where?.date) {
        delete updatedQuery.where.date;
      }
    }

    setPage(1);
    setQuery(updatedQuery);
  };

  const handleCustomRangeDate = (dateRange: DateRange) => {
    const startDate = dateRange.startDate;
    const endDate = dateRange.endDate;
    startDate?.setHours(0, 0, 0, 0);
    endDate?.setHours(23, 59, 59, 0);

    if (startDate && endDate) {
      setCustomDateRange({ startDate, endDate });
    } else {
      setCustomDateRange(undefined);
    }
  };

  const handleClickSort = (sortingValues: string[][]) => {
    setSortingOptions(sortingValues);
    setQuery((prevQuery) => {
      return {
        ...cloneDeep(prevQuery),
        order: sortingValues.length > 0 ? sortingValues : undefined,
      };
    });
  };

  const handleChangePage = (newPage: number) => {
    const updatedQuery = { ...query };
    updatedQuery.offset = (newPage - 1) * currentLimit;
    setPage(newPage);
    setQuery(updatedQuery);
  };

  const handleSelectRowsPerPage = (selectedValue: string) => {
    const updatedQuery = { ...query };
    updatedQuery.offset = 0;
    updatedQuery.limit = Number(selectedValue);

    setPage(1);
    setRowsPerPage(selectedValue);
    setQuery(updatedQuery);
  };

  const downloadFile = async (id: number) => {
    const url =
      formatTable === ToggleButtonOptions.Left
        ? await downloadWorkshiftCSVService.getCSVUrl(id)
        : await downloadTimesheetsService.getIIFUrl(id);

    const anchor = document.createElement("a");
    anchor.href = url;
    anchor.download = url;
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  };

  const handleClickGenerateCustomFile = async () => {
    try {
      if (
        customCompany &&
        customDateRange &&
        customDateRange.startDate &&
        customDateRange.endDate
      ) {
        if (formatCustom === ToggleButtonOptions.Left) {
          await downloadWorkshiftCSVService.generateTimesheetsCSVFile(
            customCompany,
            customDateRange.startDate,
            customDateRange.endDate,
          );
        }
        if (
          formatCustom === ToggleButtonOptions.Right &&
          currentUser?.employee.role === "admin"
        ) {
          await downloadTimesheetsService.generateTimesheetsIIFFile(
            customCompany,
            customDateRange?.startDate,
            customDateRange?.endDate,
          );
        }

        setIsGenerateDisabled(true);
      }
    } catch (err) {
      console.log("A file is being generated.");
      /**
       * ! This Error triggers a modal showing two Buttons and a message. It was requested to keep it hidden.
       */
      // dispatch(
      //   error(
      //     {
      //       title: "Could not generate file",
      //       message: err.message,
      //     },
      //     () => handleClickGenerateCustomFile(),
      //   ),
      // );
    }
    setTriggerGetGeneratedFile(!triggerGetGeneratedFile);
  };

  const handleClickDownloadCustomFile = async () => {
    if (formatCustom === ToggleButtonOptions.Left) {
      const CSVUrl = await downloadWorkshiftCSVService.downloadTimesheetsCSVFile();
      window.open(CSVUrl.data.url);
    }
    if (
      formatCustom === ToggleButtonOptions.Right &&
      currentUser?.employee.role === "admin"
    ) {
      const IIFUrl = await downloadTimesheetsService.downloadTimesheetsIIFFile();
      window.open(IIFUrl.data.url);
    }
  };

  const handleToggleFormatTable = (option: ToggleButtonOptions) => {
    setFormatTable(option);
    handleChangePage(1);
  };
  const handleToggleFormatCustom = (option: ToggleButtonOptions) =>
    setFormatCustom(option);

  return (
    <Fragment>
      <Paper className={classes.paper}>
        <Grid container>
          <Grid item sm={6} xs={12}>
            <Typography className={classes.header}>
              History Timesheets
            </Typography>
          </Grid>

          <Grid item sm={6} xs={12}>
            <Box marginBottom="24px">
              <Grid container spacing={3} justify="flex-end">
                <Grid item>
                  {currentUser?.employee.role === "admin" ? (
                    <SquareToggleButton
                      activeButton={formatTable}
                      leftButtonText="CSV"
                      rightButtonText="IIF"
                      onClickToggleButton={handleToggleFormatTable}
                    />
                  ) : (
                    <Box />
                  )}
                </Grid>
                <Grid item>
                  <Box width="152px">
                    <Dropdown
                      id="companyDropdown"
                      label="Company"
                      options={companyFilterOptions}
                      selectedValue={company}
                      onSelectOption={handleSelectCompany}
                    />
                  </Box>
                </Grid>
                <Grid item>
                  <RangeDatePicker
                    maxDate={new Date()}
                    onClickApply={handleApplyRangeDate}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <TableCustom
              columns={downloadTimesheetsColumns}
              isLoading={isLoading}
              noRowsMessage="No files found"
              rows={getDownloadTimesheetsRows(downloadTimesheets, downloadFile)}
              sortingOptions={sortingOptions}
              onClickSort={handleClickSort}
            />
          </Grid>
        </Grid>
      </Paper>
      <Paper className={classes.paginationContainer}>
        <Box padding="12px 24px">
          <Grid alignItems="center" container spacing={4}>
            <Grid item xs={4} />
            <Grid item xs={4}>
              <Box display="flex" justifyContent="center">
                <Pagination
                  id="usersManagement-pagination"
                  isDisabled={isLoading || currentCount === 0}
                  page={page}
                  totalPages={getTotalPages(currentCount, currentLimit)}
                  onChangePage={handleChangePage}
                />
              </Box>
            </Grid>

            <Grid item xs={4}>
              <Box display="flex" alignItems="center" justifyContent="flex-end">
                <Box marginRight="8px">
                  <Typography
                    variant="caption"
                    className={classes.rowSelectLabel}
                  >
                    Rows per page:
                  </Typography>
                </Box>
                <DropdownPagination
                  id="rowsDropdown"
                  isDisabled={isLoading || currentCount === 0}
                  options={rowSelectOptions}
                  selectedValue={rowsPerPage}
                  onSelectOption={handleSelectRowsPerPage}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Paper>

      <Paper className={classes.paper}>
        <Grid container direction="column">
          <Grid item sm={6} xs={12}>
            <Typography className={classes.header}>Custom Export</Typography>
          </Grid>
          <Grid item sm={6} xs={12}>
            <Typography className={classes.caption}>
              Select company and date range, you will get a file with all the
              information you decide to segment in a “iif” or "csv" format.
            </Typography>
            {formatCustom === ToggleButtonOptions.Left && (
              <Typography className={classes.caption}>
                This file can only be retrieved on ranges of one week
              </Typography>
            )}
          </Grid>

          <Grid item sm={7} xs={12}>
            <Grid container spacing={2}>
              {currentUser?.employee.role === "admin" ? (
                <>
                  <Grid item sm={3} xs={12}>
                    <SquareToggleButton
                      activeButton={formatCustom}
                      leftButtonText="CSV"
                      rightButtonText="IIF"
                      onClickToggleButton={handleToggleFormatCustom}
                    />
                  </Grid>
                  <Grid item sm={3} xs={12}>
                    <Dropdown
                      id="exportCompanyDropdown"
                      label="Company"
                      options={companiesOptions}
                      selectedValue={customCompany}
                      onSelectOption={handleSelectCustomCompany}
                    />
                  </Grid>
                </>
              ) : (
                <Grid item sm={6} xs={12}>
                  <Dropdown
                    id="exportCompanyDropdown"
                    label="Company"
                    options={companiesOptions}
                    selectedValue={customCompany}
                    onSelectOption={handleSelectCustomCompany}
                  />
                </Grid>
              )}
              <Grid item sm={6} xs={12}>
                <RangeDatePicker
                  upAndRightLocation
                  maxDate={new Date()}
                  onClickApply={handleCustomRangeDate}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Button
                  variant="contained"
                  className={classes.customButton}
                  disabled={isGenerateDisabled}
                  onClick={handleClickGenerateCustomFile}
                >
                  Generate File
                </Button>
              </Grid>
              <Grid item xs={4}>
                <>
                  <Button
                    variant="contained"
                    className={classes.customButton}
                    disabled={
                      currentFile?.status !== timesheetFileStatuses.SUCCESSFUL
                    }
                    onClick={handleClickDownloadCustomFile}
                  >
                    Download File
                  </Button>
                </>
              </Grid>
              <Grid item xs={2}>
                <Typography className={classes.statusText}>
                  Last Status:
                </Typography>
                <UploadStatus
                  status={currentFile?.status || "New File"}
                  hideStatusText={false}
                />
              </Grid>
              <Grid item xs={2}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Button variant="outlined" onClick={handleReload}>
                    <RefreshIcon />
                  </Button>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Fragment>
  );
}
