import {
  createStyles,
  makeStyles,
  Theme,
  IconButton,
  SvgIconProps,
} from "@material-ui/core";
import { Edit } from "@material-ui/icons";
import React from "react";

interface StyleProps {
  customFontSize?: string;
  height?: string;
  isDisabled?: boolean;
  width?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: theme.palette.primary.main,
      borderRadius: "50%",
    },
    icon: {
      fontSize: ({ customFontSize }: StyleProps) => customFontSize,
    },
    iconButton: {
      backgroundColor: ({ isDisabled }: StyleProps) =>
        isDisabled ? theme.palette.grey[400] : theme.palette.primary.main,
      borderRadius: "50%",
      color: "white",
      height: ({ height }: StyleProps) => height,
      padding: "4px",
      width: ({ width }: StyleProps) => width,
      "&:hover": {
        backgroundColor: ({ isDisabled }: StyleProps) =>
          isDisabled ? theme.palette.grey[400] : theme.palette.primary.main,
      },
    },
  }),
);

export interface EditIconButtonProps {
  customFontSize?: string;
  height?: string;
  isDisabled?: boolean;
  width?: string;
  onClickEdit: () => void;
}

export function EditIconButton({
  customFontSize,
  height = "32px",
  isDisabled,
  width = "32px",
  onClickEdit,
  ...otherProps
}: EditIconButtonProps & SvgIconProps) {
  const classes = useStyles({ customFontSize, height, isDisabled, width });
  return (
    <IconButton
      aria-label="Edit"
      className={classes.iconButton}
      disabled={isDisabled}
      onClick={onClickEdit}
    >
      <Edit className={classes.icon} {...otherProps} />
    </IconButton>
  );
}
