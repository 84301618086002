import { Box, createStyles, makeStyles, Theme } from "@material-ui/core";
import React, { Fragment } from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    expenseTypeItem: {
      fontSize: "1rem",
      fontWeight: "bold",
      color: theme.palette.primary.dark,
    },
    expenseItem: {
      fontSize: "0.875rem",
      fontWeight: "bold",
      textAlign: "center",
      color: theme.palette.grey["600"],
    },
    profitBid: {
      color: theme.palette.primary.light,
      textAlign: "center",
      fontSize: "0.875rem",
      fontWeight: "bold",
    },
    profitToDate: {
      color: theme.palette.secondary.main,
      textAlign: "center",
      fontSize: "0.875rem",
      fontWeight: "bold",
    },
  }),
);

interface ExpenseItemProps {
  type: string;
  bid: string;
  total: string;
  isProfit?: boolean;
}

export const ExpenseItem = ({
  type,
  bid,
  total,
  isProfit,
}: ExpenseItemProps) => {
  const classes = useStyles();
  return (
    <Fragment>
      {isProfit ? (
        <Box display="flex">
          <Box className={classes.expenseTypeItem} flex={1}>
            {type}
          </Box>
          <Box className={classes.profitBid} flex={1}>
            {bid}
          </Box>
          <Box className={classes.profitToDate} flex={1}>
            {total}
          </Box>
        </Box>
      ) : (
        <Box display="flex">
          <Box className={classes.expenseTypeItem} flex={1}>
            {type}
          </Box>
          <Box className={classes.expenseItem} flex={1}>
            {Number(bid) ? `$${Number(bid).toLocaleString("en")}` : "--"}
          </Box>
          <Box className={classes.expenseItem} flex={1}>
            {Number(total) ? `$${Number(total).toLocaleString("en")}` : "--"}
          </Box>
        </Box>
      )}
    </Fragment>
  );
};
