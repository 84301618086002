import { TempWorkshiftModel } from "@dwo/shared/dist/models/tempWorkshiftModel";
import {
  Box,
  createStyles,
  IconButton,
  makeStyles,
  Theme,
} from "@material-ui/core";
import { DeleteSharp, Edit, PeopleAlt } from "@material-ui/icons";
import React from "react";

interface TempActionIconsProps {
  tempWorkshift: TempWorkshiftModel;
  onClickDelete: (tempWorkshift: TempWorkshiftModel) => void;
  onClickEdit: (tempWorkshift: TempWorkshiftModel) => void;
  onClickSwap: (tempWorkshift: TempWorkshiftModel) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      color: theme.palette.primary.main,
    },
    iconButton: {
      padding: "8px",
    },
  }),
);

export function TempActionIcons({
  tempWorkshift,
  onClickDelete,
  onClickEdit,
  onClickSwap,
}: TempActionIconsProps) {
  const classes = useStyles();

  const handleClickDelete = () => {
    onClickDelete(tempWorkshift);
  };

  const handleClickEdit = () => {
    onClickEdit(tempWorkshift);
  };

  const handleClickSwap = () => {
    onClickSwap(tempWorkshift);
  };

  return (
    <Box
      alignItems="center"
      display="flex"
      justifyContent="flex-end"
      width="100%"
      marginLeft="-8px"
      maxWidth="120px"
    >
      <IconButton className={classes.iconButton} onClick={handleClickSwap}>
        <PeopleAlt className={classes.icon} />
      </IconButton>
      <IconButton className={classes.iconButton} onClick={handleClickEdit}>
        <Edit className={classes.icon} />
      </IconButton>
      <IconButton className={classes.iconButton} onClick={handleClickDelete}>
        <DeleteSharp className={classes.icon} />
      </IconButton>
    </Box>
  );
}
