import {
  Avatar,
  Box,
  createStyles,
  Link,
  makeStyles,
  TableCell,
  Theme,
  Typography,
} from "@material-ui/core";
import { DriveEta, Error, Star } from "@material-ui/icons";
import { TooltipCustom } from "components/TooltipCustom";
import React, { Fragment } from "react";
import { Link as RouterLink } from "react-router-dom";
import { MainTableCell, mainTableStyles } from "utils/tableUtils";

interface TableCellCustomProps {
  cellData: MainTableCell;
}

export const cellStyles = makeStyles((theme: Theme) =>
  createStyles({
    alertIcon: {
      color: theme.palette.error.main,
    },
    alertIconHazard: {
      color: theme.palette.error.main,
      fontSize: "0.75rem",
    },
    missingHazard: {
      position: "absolute",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "100px",
      backgroundColor: "white",
      top: 0,
    },
    iconWithBackground: { fontSize: "13px", color: "#002868" },
    iconBackground: {
      backgroundColor: "white",
      position: "absolute",
      bottom: "2px",
      right: "-1px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "100px",
    },
    leftIconBackground: {
      backgroundColor: "white",
      position: "absolute",
      bottom: "2px",
      right: "-1px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "100px",
    },
    rightIconBackground: {
      backgroundColor: "white",
      position: "absolute",
      bottom: "2px",
      right: "-10px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "100px",
    },
    container: {
      display: "flex",
    },
  }),
);

export function TableCellCustom({ cellData }: TableCellCustomProps) {
  const cellClasses = cellStyles();
  const classes = mainTableStyles();

  const cellContent = (value: string | JSX.Element) => {
    if (typeof value === "string") {
      return (
        <Typography variant="body2" className={classes.textCell}>
          {value}
        </Typography>
      );
    }

    return value;
  };

  return (
    <TableCell>
      <Box className={cellClasses.container}>
        {cellData.isLink && cellData.linkTo && (
          <Link
            component={RouterLink}
            to={cellData.linkTo}
            className={classes.link}
          >
            {cellData.value}
          </Link>
        )}
        {cellData.isImage && (
          <Box position="relative">
            <Avatar src={cellData.value as string} />
            {(cellData.isHazardMissing || cellData.isPhotoMismatch) && (
              <Box className={cellClasses.missingHazard}>
                <TooltipCustom
                  title={
                    <Typography variant="subtitle2">
                      {cellData.isHazardMissing &&
                        "User did not sign hazard form"}
                      {cellData.isPhotoMismatch && "User had a photo mismatch"}
                    </Typography>
                  }
                  placement="bottom-start"
                >
                  <Error className={cellClasses.alertIconHazard} />
                </TooltipCustom>
              </Box>
            )}
            {cellData.isForeman && !cellData.isDriver && (
              <Box className={cellClasses.iconBackground}>
                <Star className={cellClasses.iconWithBackground} />
              </Box>
            )}
            {cellData.isDriver && !cellData.isForeman && (
              <Box className={cellClasses.iconBackground}>
                <DriveEta className={cellClasses.iconWithBackground} />
              </Box>
            )}
            {cellData.isDriver && cellData.isForeman && (
              <Fragment>
                <Box className={cellClasses.leftIconBackground}>
                  <Star className={cellClasses.iconWithBackground} />
                </Box>
                <Box className={cellClasses.rightIconBackground}>
                  <DriveEta className={cellClasses.iconWithBackground} />
                </Box>
              </Fragment>
            )}
          </Box>
        )}
        {!cellData.isLink && !cellData.isImage && cellContent(cellData.value)}
        {cellData.hasAlert && (
          <Box display="flex" alignItems="center" marginLeft="4px">
            <TooltipCustom
              title={
                <Typography variant="subtitle2">Biometrics Conflict</Typography>
              }
              placement="bottom-start"
            >
              <Error className={cellClasses.alertIcon} />
            </TooltipCustom>
          </Box>
        )}
      </Box>
    </TableCell>
  );
}
