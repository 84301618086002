import { createStyles, makeStyles, Theme, Typography } from "@material-ui/core";
import React from "react";
import { decimalTimeToHoursTime } from "utils/dateUtils";

interface TableDisplayTotalLabelProps {
  hours: number;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    label: {
      color: theme.palette.grey[700],
      fontWeight: 600,
      marginTop: "9px",
      textAlign: "center",
    },
  }),
);

export function TableDisplayTotalLabel({ hours }: TableDisplayTotalLabelProps) {
  const classes = useStyles();
  return (
    <Typography className={classes.label} variant="body2">
      {decimalTimeToHoursTime(hours)}
    </Typography>
  );
}
