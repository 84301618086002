import { WorkShiftLocationType } from "@dwo/shared/dist/models/workShiftModel";
import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { Box } from "@material-ui/core/";
import { DeviceUnknownOutlined, Flag, LocationCity } from "@material-ui/icons";
import { error } from "features/error/errorSlice";
import { noop } from "lodash";
import React from "react";
import { useDispatch } from "react-redux";

interface TableDisplayDateLabelProps {
  date: string;
  locationType?: WorkShiftLocationType;
  location?: string;
}

interface StyleProps {
  hasLocation?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    building: {
      color: "#000000",
      fontSize: "1.125rem",
    },
    flag: {
      color: theme.palette.primary.main,
      fontSize: "1.125rem",
    },
    unknown: {
      color: theme.palette.grey["500"],
      fontSize: "1.125rem",
    },
    container: {
      cursor: ({ hasLocation }: StyleProps) =>
        hasLocation ? "pointer" : "unset",
    },
  }),
);

export function TableDisplayDateLabel({
  date,
  locationType,
  location,
}: TableDisplayDateLabelProps) {
  const classes = useStyles({ hasLocation: location });
  const dispatch = useDispatch();
  const getLocationIcon = () => {
    switch (locationType) {
      case WorkShiftLocationType.YARD:
        return <LocationCity className={classes.building} />;
      case WorkShiftLocationType.SITE:
        return <Flag className={classes.flag} />;
      case WorkShiftLocationType.UNKNOWN:
        return <DeviceUnknownOutlined className={classes.unknown} />;
      default:
        return null;
    }
  };

  const handleClickBox = () => {
    if (location && location === "0.0, 0.0") {
      dispatch(
        error(
          {
            title: "We couldn't retrive the gps location",
            actionButtonText: "Ok",
            hideCancelButton: true,
          },
          noop,
        ),
      );
      return;
    }

    const coords = location?.split(",");
    if (coords) {
      window.open(`https://maps.google.com/?q=${coords[0]},${coords[1]}`);
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      width={date === "-" ? "100%" : "auto"}
      position="relative"
      top={getLocationIcon() !== null ? "10px" : ""}
      className={classes.container}
      onClick={location ? handleClickBox : noop}
    >
      {date}
      {date !== "-" && locationType && getLocationIcon()}
    </Box>
  );
}
