import React from "react";
import {
  makeStyles,
  Theme,
  createStyles,
  Switch,
  Typography,
  Grid,
} from "@material-ui/core";

const switchStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 48,
      height: 24,
      padding: 0,
    },
    switchBase: {
      padding: 1,
      color: theme.palette.primary.light,
      "&$checked": {
        transform: "translate(26px, 1px)",
        color: theme.palette.common.white,
        "& + $track": {
          backgroundColor: theme.palette.primary.main,
          opacity: 1,
        },
      },
    },
    thumb: {
      width: 20,
      height: 20,
    },
    track: {
      borderRadius: 24 / 2,
      backgroundColor: theme.palette.grey[300],
      opacity: 1,
      transition: theme.transitions.create(["background-color"]),
    },
    checked: {},
    focusVisible: {},
    label: {
      fontWeight: 600,
    },
    textActive: {
      color: theme.palette.primary.main,
    },
    textInactive: {
      color: theme.palette.grey[400],
    },
  }),
);

interface SwitchCustomProps {
  onText?: string;
  offText?: string;
  disabled?: boolean;
  value: boolean;
  onChange: (value: boolean) => void;
}

export function SwitchCustom({
  onText,
  offText,
  disabled,
  value,
  onChange,
}: SwitchCustomProps) {
  const classes = switchStyles();

  const handleChangeSwitch = (
    e: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => {
    onChange(checked);
  };

  return (
    <Typography className={classes.label} component="div" variant="caption">
      <Grid component="label" container alignItems="center" spacing={1}>
        <Grid
          item
          className={!value ? classes.textActive : classes.textInactive}
        >
          {offText}
        </Grid>
        <Grid item>
          <Switch
            checked={value}
            focusVisibleClassName={classes.focusVisible}
            disableRipple
            classes={{
              root: classes.root,
              switchBase: classes.switchBase,
              thumb: classes.thumb,
              track: classes.track,
              checked: classes.checked,
            }}
            onChange={handleChangeSwitch}
            disabled={disabled}
          />
        </Grid>
        <Grid
          item
          className={value ? classes.textActive : classes.textInactive}
        >
          {onText}
        </Grid>
      </Grid>
    </Typography>
  );
}
