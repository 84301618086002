import {
  Box,
  createStyles,
  List,
  ListItem,
  ListItemText,
  Theme,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { selectCurrentUser } from "features/logIn/sessionSlice";
import React from "react";
import { useSelector } from "react-redux";
import { Link, LinkProps, useLocation } from "react-router-dom";
import * as routes from "routes/Roots";
import { version as adminAppVersion } from "../../../package.json";

enum SideMenuLabels {
  SUMMARY = "Summary",
  TIMESHEETS = "Timesheets",
  JOB_MANAGEMENT = "Job Management",
  USER_MANAGEMENT = "User Management",
  UPLOAD = "Data Management",
  //APP_CHANGELOG = "App Changelog",
}

interface ListItemLinkProps {
  icon?: React.ReactElement;
  label: string;
  to: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "&.Mui-selected": {
        backgroundImage: "linear-gradient(to right, #1654B8 97%, #D50918 3%)",
        color: "white",
      },
    },
    list: {
      height: "100vh",
    },
    listItem: {
      height: "64px",
      color: theme.palette.primary.dark,
    },
    listItemText: {
      paddingLeft: "42px",
      fontWeight: "bold",
      fontFamily: theme.typography.fontFamily,
      lineHeight: "1.43",
      letterSpacing: "0.01071em",
      fontSize: "1rem",
      wordWrap: "break-word",
    },
    logoContainer: {
      margin: "0 auto",
      paddingTop: "24px",
      width: "111px",
    },
    logo: {
      maxWidth: "100%",
      height: "auto",
    },
    container: {
      backgroundColor: "#FFFFFF",
      bottom: "0",
      boxShadow: "1px 1px 8px #0000001A",
      left: "0",
      position: "fixed",
      top: "0",
      zIndex: 10,
      [theme.breakpoints.down("sm")]: {
        boxShadow: "none",
      },
      height: "100%",
    },
    appVersion: {
      fontSize: "12px",
      fontWeight: "bold",
      marginLeft: "58px",
      marginTop: "22px",
    },
  }),
);

export function SideMenu() {
  const location = useLocation();
  const currentUser = useSelector(selectCurrentUser);
  const classes = useStyles();
  const labels = [...Object.values(SideMenuLabels)];
  const sideMenuLabels =
    currentUser?.employee.role === "admin" ||
    currentUser?.employee.role === "subadmin"
      ? labels
      : labels.slice(0, labels.length - 1);

  const getPath = (label: string) => {
    switch (label) {
      case SideMenuLabels.SUMMARY:
        return routes.ROOT_HOME;
      case SideMenuLabels.TIMESHEETS:
        return routes.ROOT_TIMESHEETS_MANAGEMENT;
      case SideMenuLabels.JOB_MANAGEMENT:
        return routes.ROOT_JOB_MANAGEMENT;
      case SideMenuLabels.USER_MANAGEMENT:
        return routes.ROOT_USER_MANAGEMENT;
      case SideMenuLabels.UPLOAD:
        return routes.ROOT_UPLOAD;
      // case SideMenuLabels.APP_CHANGELOG:
      //   return routes.ROOT_APP_CHANGELOG;
      default:
        return "";
    }
  };

  const ListItemLink = (props: ListItemLinkProps) => {
    const { label, to } = props;
    const isSelected = location.pathname.split("/")[1] === to.split("/")[1];

    const renderLink = React.useMemo(
      () =>
        React.forwardRef<any, Omit<LinkProps, "to">>((itemProps, ref) => (
          <Link to={to} ref={ref} {...itemProps} />
        )),
      [to],
    );

    return (
      <ListItem
        className={classes.listItem}
        classes={{ selected: classes.root }}
        button
        component={renderLink}
        selected={isSelected}
      >
        <ListItemText
          disableTypography
          className={classes.listItemText}
          primary={label}
        />
      </ListItem>
    );
  };

  return (
    <Box className={classes.container} width="232px">
      <List className={classes.list}>
        <div className={classes.logoContainer}>
          <img className={classes.logo} alt="logo" src="/images/logo.png" />
        </div>
        {sideMenuLabels.map((label) => (
          <ListItemLink to={getPath(label)} key={label} label={label} />
        ))}
        <Typography className={classes.appVersion} variant="body2">
          <Link
            style={{ color: "black", textDecoration: "none" }}
            to="/app-changelog"
          >
            App Version {adminAppVersion}
          </Link>
        </Typography>
      </List>
    </Box>
  );
}
