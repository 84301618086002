import { DwoSummaryServiceOptions } from "@dwo/shared/dist/services/dwoService";
import { Grid } from "@material-ui/core";
import {
  getSummaryTimesheetsPerformanceData,
  selectSummaryIsLoadingTimesheetData,
  selectSummarySelectedDateRange,
  selectSummarySelectedRegion,
  selectTimesheetSelectedForeman,
} from "features/summary/summarySlice";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SummaryTimesheetsCard } from "./SummaryTimesheetsCard";
import { cloneDeep } from "lodash/fp";
import { LoadingSpinner } from "components/LoadingSpinner";
import { SummaryTimesheetOverviewCard } from "./SummaryTimesheetOverviewCard";
import { TimesheetsStatusCard } from "./TimesheetsStatusCard";
import { TimesheetWorkforceCard } from "./TimesheetWorkforceCard";
import { enDashFormatDate } from "utils/dateUtils";

export function TimesheetTab() {
  const dispatch = useDispatch();
  const currentDateRange = useSelector(selectSummarySelectedDateRange);
  const currentRegion = useSelector(selectSummarySelectedRegion);
  const isLoading = useSelector(selectSummaryIsLoadingTimesheetData);
  const selectedForeman = useSelector(selectTimesheetSelectedForeman);
  const [query, setQuery] = useState<DwoSummaryServiceOptions>();

  useEffect(() => {
    const hasDates = query?.startDate && query?.endDate;

    if (query && hasDates) {
      dispatch(getSummaryTimesheetsPerformanceData(query));
    }
  }, [dispatch, query]);

  useEffect(() => {
    if (!currentDateRange) return;

    setQuery((prevQuery: DwoSummaryServiceOptions | undefined) => {
      const updatedQuery = prevQuery
        ? cloneDeep(prevQuery)
        : ({} as DwoSummaryServiceOptions);

      updatedQuery.startDate = enDashFormatDate(
        new Date(currentDateRange.startDate),
      );
      updatedQuery.endDate = enDashFormatDate(
        new Date(currentDateRange.endDate),
      );

      return updatedQuery;
    });
  }, [currentDateRange]);

  useEffect(() => {
    setQuery((prevQuery: DwoSummaryServiceOptions | undefined) => {
      const updatedQuery = prevQuery
        ? cloneDeep(prevQuery)
        : ({} as DwoSummaryServiceOptions);

      updatedQuery.region =
        currentRegion === "all" || currentRegion === ""
          ? undefined
          : parseInt(currentRegion, 10);

      return updatedQuery;
    });
  }, [currentRegion]);

  useEffect(() => {
    setQuery((prevQuery: DwoSummaryServiceOptions | undefined) => {
      const updatedQuery = prevQuery
        ? cloneDeep(prevQuery)
        : ({} as DwoSummaryServiceOptions);

      updatedQuery.foremanId = selectedForeman;

      return updatedQuery;
    });
  }, [selectedForeman]);

  if (isLoading) {
    return (
      <Grid item xs={12}>
        <LoadingSpinner />
      </Grid>
    );
  }

  return (
    <Fragment>
      <Grid item xs={12}>
        <SummaryTimesheetsCard />
      </Grid>

      <Grid item xs={12}>
        <SummaryTimesheetOverviewCard />
      </Grid>

      <Grid item sm={7} xs={12}>
        <TimesheetWorkforceCard />
      </Grid>

      <Grid item sm={5} xs={12}>
        <TimesheetsStatusCard />
      </Grid>
    </Fragment>
  );
}
