import {
  Box,
  Button,
  createStyles,
  IconButton,
  makeStyles,
  Modal,
  Theme,
  Typography,
} from "@material-ui/core";
import { Cancel } from "@material-ui/icons";
import { selectRegions } from "features/region/regionSlice";
import { JobModel } from "@dwo/shared/dist/models/jobModel";
import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import { slashFormatDate } from "utils/dateUtils";
import { DEFAULT_EMPTY } from "utils/jobUtils";
import { JobForm } from "./JobForm";

interface JobModalProps {
  isEditing?: boolean;
  isOpen: boolean;
  jobData?: JobModel;
  onClickClose: () => void;
  onSubmit: (newJob: JobModel) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    closeIcon: {
      color: theme.palette.primary.main,
      padding: 0,
    },
    container: {
      backgroundColor: "white",
      outline: "0",
      maxWidth: "669px",
      width: "90%",
    },
    content: {
      backgroundColor: "white",
      height: "100%",
      maxHeight: "666px",
      maxWidth: "669px",
      outline: 0,
      overflow: "auto",
      padding: "24px 60px 0 60px",
      width: "90%",
    },
    dateLabel: {
      color: theme.palette.primary.dark,
      fontSize: "0.875rem",
    },
    dateValue: {
      color: theme.palette.grey[500],
      fontSize: "0.875rem",
      marginLeft: "16px",
    },
    header: {
      color: theme.palette.primary.dark,
      fontSize: "40px",
      fontWeight: 300,
    },
    modal: {
      alignItems: "center",
      borderRadius: "2px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
  }),
);

export function CreateEditJobModal({
  isEditing,
  isOpen,
  jobData,
  onClickClose,
  onSubmit,
}: JobModalProps) {
  const currentRegions = useSelector(selectRegions);
  const classes = useStyles();

  const handleClickSave = () =>
    document.getElementById("submitJobForm-btn")?.click();

  return (
    <Modal className={classes.modal} open={isOpen} onClose={onClickClose}>
      <Fragment>
        <Box className={classes.container}>
          <Box
            display="flex"
            width="100%"
            justifyContent="flex-end"
            padding="16px 16px 0 16px"
          >
            <IconButton
              aria-label="Close Modal"
              className={classes.closeIcon}
              onClick={onClickClose}
            >
              <Cancel fontSize="large" />
            </IconButton>
          </Box>
        </Box>

        <Box className={classes.container}>
          <Box
            alignItems="baseline"
            display="flex"
            flexWrap="wrap"
            justifyContent="space-between"
            padding="0 60px"
          >
            <Typography className={classes.header} component="h1">
              {`Job ID ${jobData?.jobId || DEFAULT_EMPTY}`}
            </Typography>
            <Box display="flex" flexDirection="row">
              <Typography className={classes.dateLabel}>Creation</Typography>
              <Typography className={classes.dateValue}>
                {`${
                  jobData?.createdAt
                    ? slashFormatDate(new Date(jobData.createdAt))
                    : slashFormatDate(new Date())
                }`}
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box className={classes.content}>
          <JobForm
            isEditing={isEditing}
            jobData={jobData}
            regions={currentRegions}
            onSubmit={onSubmit}
          />
        </Box>

        <Box className={classes.container}>
          <Box
            display="flex"
            justifyContent="flex-end"
            padding="24px 60px 40px 60px"
          >
            <Box marginRight="24px" maxWidth="174px" width="100%">
              <Button fullWidth variant="outlined" onClick={onClickClose}>
                Cancel
              </Button>
            </Box>
            <Box maxWidth="174px" width="100%">
              <Button fullWidth variant="contained" onClick={handleClickSave}>
                Save
              </Button>
            </Box>
          </Box>
        </Box>
      </Fragment>
    </Modal>
  );
}
