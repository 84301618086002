import {
  Box,
  createStyles,
  makeStyles,
  Paper,
  Theme,
  Typography,
} from "@material-ui/core";
import {
  selectSummarySelectedDateRange,
  selectSummarySelectedRegion,
  selectTimesheetsData,
} from "features/summary/summarySlice";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { TimesheetStatusColors } from "utils/summaryTimesheetUtils";
import { TimesheetInfoCard } from "./TimesheetInfoCard";
import { ROOT_TIMESHEETS_MANAGEMENT } from "routes/Roots";
import { TimeSheetTabs } from "utils/timesheetsManagementUtils";
import {
  setCrewFilters,
  setCrewQuery,
  setTabSelected,
} from "features/dwo/dwoSlice";
import { rowSelectOptions } from "utils/tableUtils";
import { DateRange } from "materialui-daterange-picker";
import {
  DwoStatusValues,
  initialCrewQuery,
} from "utils/timesheetsManagementUtils";
import { ALL_OPTION } from "utils/sharedUtils";
import { ServiceOptions } from "@dwo/shared/dist/services/baseService";
import { enDashFormatDate } from "utils/dateUtils";
import { cloneDeep } from "lodash";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardsContainer: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-between",
      margin: "0 auto",
      maxWidth: "1576px",
      width: "100%",
      [theme.breakpoints.down("md")]: {
        justifyContent: "flex-start",
      },
    },
    container: {
      display: "flex",
      flexDirection: "column",
      margin: "0 24px 24px 24px",
      padding: "24px 32px 0 32px",
    },
    title: {
      color: theme.palette.primary.main,
    },
    clickable: {
      cursor: "pointer",
    },
  }),
);

export function SummaryTimesheetsCard() {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const summaryData = useSelector(selectTimesheetsData);
  const currentDateRange = useSelector(selectSummarySelectedDateRange);
  const currentRegion = useSelector(selectSummarySelectedRegion);

  const submitedData = summaryData ? summaryData.submitted : 0;
  const total = summaryData ? summaryData.total : 0;
  const approvedData = summaryData ? summaryData.approved : 0;
  const pendingData = summaryData ? summaryData.draft : 0;
  const idMismatch = summaryData ? summaryData.idMismatch : 0;
  const teleMismatch = summaryData ? summaryData.telematicsMismatch : 0;

  const handleClickCard = (timesheetStatus: string) => () => {
    const freshQuery: ServiceOptions = cloneDeep(initialCrewQuery);

    const dateRange: DateRange = {};
    if (currentDateRange?.startDate && currentDateRange.endDate) {
      dateRange.startDate = new Date(currentDateRange.startDate);
      dateRange.endDate = new Date(currentDateRange.endDate);
      freshQuery.where = {
        ...freshQuery.where,
        date: {
          $gte: enDashFormatDate(dateRange.startDate),
          $lte: enDashFormatDate(dateRange.endDate),
        },
      };
    }

    freshQuery.region =
      currentRegion === "all" || currentRegion === ""
        ? undefined
        : parseInt(currentRegion, 10);
    freshQuery.where = {
      ...freshQuery.where,
      status:
        timesheetStatus === ALL_OPTION.value ? undefined : timesheetStatus,
    };
    freshQuery.offset = 0;

    dispatch(setTabSelected(TimeSheetTabs.Crew));
    dispatch(
      setCrewFilters({
        searchValue: "",
        region:
          currentRegion === "all" || currentRegion === ""
            ? []
            : [currentRegion],
        status: timesheetStatus,
        sortingOptions: [],
        page: 1,
        rowsPerPage: rowSelectOptions[0].value,
        dateRange,
      }),
    );
    dispatch(setCrewQuery(freshQuery));
    history.push(ROOT_TIMESHEETS_MANAGEMENT);
  };

  return (
    <Paper className={classes.container}>
      <Box width="100%" marginBottom="24px">
        <Typography className={classes.title} variant="h6">
          Timesheets
        </Typography>
      </Box>

      <Box className={classes.cardsContainer}>
        <Box
          flex={1}
          margin="0 24px 24px 0"
          maxWidth="208px"
          width="100%"
          className={classes.clickable}
          onClick={handleClickCard(ALL_OPTION.value)}
        >
          <TimesheetInfoCard data={`${total}`} description="Total" />
        </Box>
        <Box
          flex={1}
          margin="0 24px 24px 0"
          maxWidth="208px"
          width="100%"
          className={classes.clickable}
          onClick={handleClickCard(DwoStatusValues.SUBMITTED)}
        >
          <TimesheetInfoCard
            dotColor={TimesheetStatusColors.Submitted}
            data={`${submitedData}`}
            description="Submitted"
          />
        </Box>
        <Box
          flex={1}
          margin="0 24px 24px 0"
          maxWidth="208px"
          width="100%"
          className={classes.clickable}
          onClick={handleClickCard(DwoStatusValues.APPROVED)}
        >
          <TimesheetInfoCard
            dotColor={TimesheetStatusColors.Approved}
            data={approvedData}
            description="Approved"
          />
        </Box>
        <Box
          flex={1}
          margin="0 24px 24px 0"
          maxWidth="208px"
          width="100%"
          className={classes.clickable}
          onClick={handleClickCard(DwoStatusValues.DRAFT)}
        >
          <TimesheetInfoCard
            dotColor={TimesheetStatusColors.Pending}
            data={pendingData}
            description="Pending"
          />
        </Box>
        <Box flex={1} margin="0 24px 24px 0" maxWidth="208px" width="100%">
          <TimesheetInfoCard
            data={idMismatch}
            description="ID Mismatch / Need Verification"
          />
        </Box>
        <Box flex={1} margin="0 24px 24px 0" maxWidth="208px" width="100%">
          <TimesheetInfoCard
            data={teleMismatch}
            description="Telematics Mismatch"
          />
        </Box>
      </Box>
    </Paper>
  );
}
