import { CrewModel } from "@dwo/shared/dist/models/crewModel";
import { EmployeeCrewModel } from "@dwo/shared/dist/models/employeeCrewModel";
import {
  EmployeeModel,
  EmployeeRole,
} from "@dwo/shared/dist/models/employeeModel";
import { ServiceOptions } from "@dwo/shared/dist/services/baseService";
import {
  Box,
  Button,
  CircularProgress,
  Collapse,
  createStyles,
  Divider,
  Grid,
  IconButton,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import {
  Add,
  Edit,
  InfoOutlined,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from "@material-ui/icons";
import { Dropdown } from "components/Dropdown";
import { TooltipCustom } from "components/TooltipCustom";
import { ForemanModal } from "components/userManagement/Hierarchy/ForemanModal";
import { SupervisorModal } from "components/userManagement/Hierarchy/SupervisorModal";
import { CrewAssignmentsModal } from "components/userManagement/UserDetails/CrewAssignmentsModal";
import {
  createCrewWithAssignment,
  getAllCrews,
  getAllEmployeeCrews,
  selectCrews,
  selectCrewsReqStatus,
  selectFirstCrewId,
  updateCrew,
} from "features/crew/crewSlice";
import {
  createEmployeeCrew,
  deleteEmployeeCrew,
  updateEmployeeCrew,
} from "features/employee_crew/employeeCrewSlice";
import {
  getAllEmployeeJobs,
  selectEmployee,
  selectEmployeeJobs,
  selectEmployeeJobsReqStatus,
  selectIsActiveEmployee,
} from "features/employees/employeesSlice";
import { prompt, selectResponse } from "features/prompt/promptSlice";
import { useNodeScrollHeight } from "hooks/useNodeScrollHeight";
import { uniqBy } from "lodash/fp";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { ReqStatus } from "utils/sharedUtils";
import { InfoItem } from "./InfoItem";

interface StyleProps {
  isDisabled: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      color: ({ isDisabled }: StyleProps) =>
        isDisabled ? theme.palette.grey[400] : theme.palette.primary.dark,
      fontWeight: "bold",
    },
    titleContainer: {
      display: "flex",
      height: "64px",
      alignItems: "center",
      justifyContent: "space-between",
      paddingRight: "24px",
    },
    icon: {
      backgroundColor: ({ isDisabled }: StyleProps) =>
        isDisabled ? theme.palette.grey[400] : theme.palette.primary.main,
      borderRadius: "50%",
      color: "white",
      padding: "4px",
    },
    jobIdTitle: {
      color: ({ isDisabled }: StyleProps) =>
        isDisabled ? theme.palette.grey[400] : theme.palette.primary.main,
    },
    collapseIcon: {
      color: theme.palette.primary.dark,
      cursor: "pointer",
    },
  }),
);

export function Hierarchy() {
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const userId = parseInt(id, 10);
  const firstCrewId = useSelector(selectFirstCrewId);
  const crews = useSelector(selectCrews);
  const isDisabled = !useSelector(selectIsActiveEmployee);
  const currentEmployee = useSelector(selectEmployee);
  const promptResponse = useSelector(selectResponse);
  const employeeJobs = useSelector(selectEmployeeJobs);
  const jobReqStatus = useSelector(selectEmployeeJobsReqStatus);
  const crewsReqStatus = useSelector(selectCrewsReqStatus);
  const classes = useStyles({ isDisabled });
  const [isSupervisorModalOpen, setSupervisorModalOpen] = useState(false);
  const [isForemanModalOpen, setForemanModalOpen] = useState(false);
  const [isAssignmentsModalOpen, setIsAssignmentsModalOpen] = useState(false);
  const [selectedIdJob, setSelectedIdJob] = useState<string>("default");
  const [employeeSelectedIds, setEmployeeSelectedIds] = useState<string[]>([]);
  const [deleteEmployeeCrewId, setDeleteEmployeeCrewId] = useState<number>(0);
  const [isJobsLoading, setIsJobsLoading] = useState(true);
  const [isCrewLoading, setIsCrewLoading] = useState(true);
  const [isWorkersDisplayed, setIsWorkersDisplayed] = useState(false);
  const [isForemenDisplayed, setIsForemenDisplayed] = useState(false);
  const [isSupervisorsDisplayed, setIsSupervisorsDisplayed] = useState(false);
  const [workerListHeight, workersRef] = useNodeScrollHeight();
  const [foremanListHeight, foremenRef] = useNodeScrollHeight();
  const [supervisorListHeight, supervisorsRef] = useNodeScrollHeight();

  const getAllForemanCrewsQuery = useCallback((): ServiceOptions => {
    const isDefault = selectedIdJob === "default";
    return {
      where: {
        foremanId: userId,
        isDefault,
        jobId: isDefault ? undefined : parseInt(selectedIdJob, 10),
      },
      include: [
        { EmployeeCrew: [{ Employee: [{ EmployeePicture: ["Picture"] }] }] },
        "job",
      ],
    };
  }, [userId, selectedIdJob]);

  const getAllCrewsByJobIdQuery = useCallback((): ServiceOptions => {
    return {
      where: {
        jobId: parseInt(selectedIdJob, 10),
      },
      include: [
        { EmployeeCrew: [{ Employee: [{ EmployeePicture: ["Picture"] }] }] },
      ],
    };
  }, [selectedIdJob]);

  const getAllCrewsQuery = useCallback((): ServiceOptions => {
    return {
      where: {
        employeeId: userId,
      },
      include: [
        {
          Crew: [
            {
              EmployeeCrew: [{ Employee: [{ EmployeePicture: ["Picture"] }] }],
            },
          ],
        },
      ],
    };
  }, [userId]);

  const getCrewMembers = (
    roleFiler: EmployeeRole,
    crew: CrewModel | undefined = crews[0],
  ) => {
    if (crew) {
      const crewMembersList = crew.employeeCrews ? crew.employeeCrews : [];
      return crewMembersList.filter((crewMember) => {
        if (crewMember.employee) {
          return crewMember.employee.role === roleFiler && crewMember.isCurrent;
        }
        return false;
      });
    }
    return [];
  };

  const getMembersFromAllCrews = (roleFilter: EmployeeRole) => {
    const memberList = crews.reduce((list: EmployeeCrewModel[], crew) => {
      const employeeCrewList = crew.employeeCrews || [];
      const filteredCrewMembers = employeeCrewList.filter((crewMember) =>
        crewMember.employee
          ? Object.is(crewMember.employee.role, roleFilter) &&
            crewMember.isCurrent
          : false,
      );
      list.push(...filteredCrewMembers);

      return list;
    }, []);

    return uniqBy("employeeId", memberList);
  };

  const getSupervisorCrew = () =>
    crews.find((crew) => {
      if (crew.employeeCrews) {
        const foundSupervisor = crew.employeeCrews.find(
          (employee) =>
            employee.employeeId === Number(userId) && employee.isCurrent,
        );
        return Boolean(foundSupervisor);
      }
      return false;
    });

  const getMemebersFromJobCrew = (roleFiler: EmployeeRole) => {
    if (crews.length <= 1) {
      return getCrewMembers(roleFiler);
    }

    const supervisorCrew = getSupervisorCrew();

    return getCrewMembers(roleFiler, supervisorCrew);
  };

  const getCrewsData = (crews: CrewModel[]) => {
    if (crews[0]) {
      const ROLE_FILTER = "worker";
      const employeeCrewsEntries = Object.entries(crews[0]).filter(
        ([key]) => key === "employeeCrews",
      );
      if (employeeCrewsEntries[0]) {
        const [, employeeCrews] = employeeCrewsEntries[0];
        const assignedWorkers: EmployeeModel[] = employeeCrews
          .map((crew: EmployeeCrewModel) => crew.employee)
          .filter((employee: EmployeeModel) => employee.role === ROLE_FILTER);

        return {
          employeeCrews,
          assignedWorkers,
        };
      }
    }
  };

  const getCrewSupervisors = () => {
    if (
      currentEmployee?.role !== EmployeeRole.Foreman &&
      selectedIdJob === "default"
    ) {
      return getMembersFromAllCrews(EmployeeRole.Supervisor);
    }
    if (
      currentEmployee?.role !== EmployeeRole.Foreman &&
      selectedIdJob !== "default"
    ) {
      getMemebersFromJobCrew(EmployeeRole.Supervisor);
    }

    return getCrewMembers(EmployeeRole.Supervisor);
  };

  const getCrewForemen = () => {
    if (
      currentEmployee?.role !== EmployeeRole.Foreman &&
      selectedIdJob === "default"
    ) {
      return getMembersFromAllCrews(EmployeeRole.Foreman);
    }
    if (
      currentEmployee?.role !== EmployeeRole.Foreman &&
      selectedIdJob !== "default"
    ) {
      getMemebersFromJobCrew(EmployeeRole.Foreman);
    }

    return getCrewMembers(EmployeeRole.Foreman);
  };

  const getCrewWorkers = () => {
    if (
      currentEmployee?.role === EmployeeRole.Supervisor &&
      selectedIdJob === "default"
    ) {
      return getMembersFromAllCrews(EmployeeRole.Worker);
    }
    if (
      currentEmployee?.role === EmployeeRole.Supervisor &&
      selectedIdJob !== "default"
    ) {
      getMemebersFromJobCrew(EmployeeRole.Foreman);
    }
    if (currentEmployee?.role === EmployeeRole.Worker) {
      return getMembersFromAllCrews(EmployeeRole.Worker).filter(
        (employeeWorker) => employeeWorker.employeeId === Number(userId),
      );
    }

    return getCrewMembers(EmployeeRole.Worker);
  };

  const crewSupervisors = getCrewSupervisors();
  const crewForemen = getCrewForemen();
  const crewWorkers = getCrewWorkers();
  const crewsData = getCrewsData(crews);
  let regionId =
    currentEmployee &&
    currentEmployee.regions &&
    currentEmployee.regions.length > 0
      ? currentEmployee.regions[0].id
      : undefined;
  regionId = typeof regionId === "string" ? parseInt(regionId, 10) : regionId;

  const getIsUserInCrew = () => {
    if (currentEmployee?.role === EmployeeRole.Supervisor) {
      return Boolean(
        crewSupervisors.find(
          (supervisor) => supervisor.employeeId === Number(userId),
        ),
      );
    }

    if (currentEmployee?.role === EmployeeRole.Foreman) {
      return Boolean(
        crewForemen.find((foreman) => foreman.employeeId === Number(userId)),
      );
    }
    return Boolean(
      crewWorkers.find((worker) => worker.employeeId === Number(userId)),
    );
  };

  const isUserInCrew = getIsUserInCrew();

  useEffect(() => {
    if (currentEmployee?.role !== EmployeeRole.Worker) {
      dispatch(getAllEmployeeJobs(userId));
    }
    if (currentEmployee?.role === EmployeeRole.Foreman) {
      dispatch(getAllCrews(getAllForemanCrewsQuery()));
    }

    if (
      currentEmployee?.role !== EmployeeRole.Foreman &&
      selectedIdJob === "default"
    ) {
      dispatch(getAllEmployeeCrews(getAllCrewsQuery()));
    }
    if (
      currentEmployee?.role === EmployeeRole.Supervisor &&
      selectedIdJob !== "default"
    ) {
      dispatch(getAllCrews(getAllCrewsByJobIdQuery()));
    }
  }, [
    dispatch,
    getAllForemanCrewsQuery,
    getAllCrewsByJobIdQuery,
    getAllCrewsQuery,
    userId,
    selectedIdJob,
    currentEmployee,
  ]);

  useEffect(() => {
    setIsJobsLoading(jobReqStatus === ReqStatus.PENDING);
  }, [jobReqStatus]);

  useEffect(() => {
    setIsCrewLoading(crewsReqStatus === ReqStatus.PENDING);
  }, [crewsReqStatus]);

  useEffect(() => {
    if (!isAssignmentsModalOpen) {
      setEmployeeSelectedIds([]);
    }
  }, [isAssignmentsModalOpen]);

  useEffect(() => {
    if (deleteEmployeeCrewId !== 0 && promptResponse) {
      dispatch(
        deleteEmployeeCrew(deleteEmployeeCrewId, getAllForemanCrewsQuery()),
      );
      setDeleteEmployeeCrewId(0);
    }
  }, [promptResponse, dispatch, deleteEmployeeCrewId, getAllForemanCrewsQuery]);

  const handleCloseSupervisorModal = () => setSupervisorModalOpen(false);
  const handleClickOpenSupervisorModal = () => setSupervisorModalOpen(true);

  const handleCloseForemanModal = () => setForemanModalOpen(false);
  const handleClickOpenForemanModal = () => setForemanModalOpen(true);

  const handleToggleAssignmentsModal = () =>
    setIsAssignmentsModalOpen(!isAssignmentsModalOpen);

  const handleClickDelete = (id: string) => {
    if (crewsData && crewsData.employeeCrews) {
      const crewToDelete = crewsData.employeeCrews.find(
        (employeeCrew: EmployeeCrewModel) =>
          employeeCrew.employeeId === Number(id),
      );

      if (crewToDelete) {
        setDeleteEmployeeCrewId(crewToDelete.id);
        dispatch(
          prompt({
            title: "Remove Job Assignment",
            message: `Are you sure you want to remove ${crewToDelete.employee.firstName} ${crewToDelete.employee.lastName} from this job?`,
          }),
        );
      }
    }
  };

  const getJobOptions = () => {
    const idOptions = employeeJobs.map((job) => ({
      label: job.jobId as string,
      value: job.id ? job.id.toString() : "",
    }));
    return [{ label: "Default", value: "default" }, ...idOptions];
  };

  const getOptionLabel = () => {
    const foundJobOption = getJobOptions().find(
      (option) => option.value === selectedIdJob,
    );
    return foundJobOption ? foundJobOption.label : "Default";
  };

  const handleSelectIdJob = (selectedJob: string) => {
    setSelectedIdJob(selectedJob);
  };

  const handleClickSaveSupervisorModal = (supervisorSelectedId: string) => {
    if (firstCrewId) {
      const employeeCrewObj = {
        assignedAt: new Date(),
        crewId: firstCrewId,
        employeeId: Number(supervisorSelectedId),
        isCurrent: true,
        isDriver: false,
        isForeman: false,
        isSupervisor: true,
      };

      dispatch(
        createEmployeeCrew(employeeCrewObj, true, getAllForemanCrewsQuery()),
      );

      if (crewSupervisors[0] && crewSupervisors[0].id) {
        const updatedOldSupervisor: EmployeeCrewModel = {
          ...crewSupervisors[0],
          isCurrent: false,
        };
        dispatch(
          updateEmployeeCrew(
            crewSupervisors[0].id,
            updatedOldSupervisor,
            true,
            getAllForemanCrewsQuery(),
          ),
        );
      }
    }
  };

  const handleClickSaveForemanModal = (foremanSelectedId: string) => {
    const supervisorCrew = getSupervisorCrew();

    if (supervisorCrew && supervisorCrew.id) {
      const employeeCrewObj = {
        assignedAt: new Date(),
        crewId: supervisorCrew.id,
        employeeId: Number(foremanSelectedId),
        isCurrent: true,
        isDriver: false,
        isForeman: true,
        isSupervisor: false,
      };

      dispatch(
        createEmployeeCrew(employeeCrewObj, true, getAllCrewsByJobIdQuery()),
      );

      const oldForeman = supervisorCrew.employeeCrews
        ? supervisorCrew.employeeCrews.find(
            (employeeCrew) => employeeCrew.isCurrent && employeeCrew.isForeman,
          )
        : undefined;

      if (oldForeman && oldForeman.id) {
        const updatedOldForeman: EmployeeCrewModel = {
          ...oldForeman,
          isCurrent: false,
        };
        dispatch(
          updateEmployeeCrew(
            oldForeman.id,
            updatedOldForeman,
            false,
            getAllCrewsByJobIdQuery(),
          ),
        );
      }

      const updatedSupervisorCrew: CrewModel = {
        ...supervisorCrew,
        foremanId: Number(foremanSelectedId),
      };
      dispatch(
        updateCrew(
          supervisorCrew.id,
          updatedSupervisorCrew,
          true,
          getAllCrewsByJobIdQuery(),
        ),
      );
    }
  };

  const handleClickSaveCrewAssignments = () => {
    if (firstCrewId && employeeSelectedIds.length > 0) {
      employeeSelectedIds.forEach((employee) => {
        dispatch(
          createEmployeeCrew(
            {
              assignedAt: new Date(),
              crewId: firstCrewId,
              employeeId: Number(employee),
              isCurrent: true,
              isDriver: false,
              isForeman: false,
              isSupervisor: false,
            },
            true,
            getAllForemanCrewsQuery(),
          ),
        );
      });
      handleToggleAssignmentsModal();
    }
  };

  const handleClickCheckbox = (idRow: string, isChecked: boolean) => {
    if (isChecked && !employeeSelectedIds.includes(idRow)) {
      setEmployeeSelectedIds((prevState) => [...prevState, idRow]);
    }

    if (!isChecked && employeeSelectedIds.includes(idRow)) {
      setEmployeeSelectedIds((prevState) =>
        prevState.filter((employeeId: string) => employeeId !== idRow),
      );
    }
  };

  const handleClickCreateCrew = () => {
    const isDefault = selectedIdJob === "default";
    const jobId = isDefault ? undefined : parseInt(selectedIdJob, 10);
    dispatch(createCrewWithAssignment(isDefault, userId, jobId));
  };

  const handleClickCollapseWorkers = () =>
    setIsWorkersDisplayed(!isWorkersDisplayed);

  const handleClickCollapseSupervisors = () =>
    setIsSupervisorsDisplayed(!isSupervisorsDisplayed);

  const handleClickCollapseForemen = () =>
    setIsForemenDisplayed(!isForemenDisplayed);

  if (isJobsLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        padding="48px"
      >
        <CircularProgress size={40} />
      </Box>
    );
  }

  return (
    <Fragment>
      {currentEmployee?.role !== EmployeeRole.Worker && (
        <Box display="flex" alignItems="center" marginBottom="40px">
          <Box width="250px">
            <Dropdown
              id="jobIdDropdown"
              isDisabled={isDisabled}
              label="Job Id"
              options={getJobOptions()}
              selectedValue={selectedIdJob}
              onSelectOption={handleSelectIdJob}
            />
          </Box>
        </Box>
      )}

      {isCrewLoading && (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          padding="48px"
        >
          <CircularProgress size={40} />
        </Box>
      )}
      {!isCrewLoading && currentEmployee?.role !== EmployeeRole.Worker && (
        <Box marginBottom="24px">
          <Box display="flex" padding="8px 0" alignItems="center">
            <Box marginRight="8px">
              <Typography variant="h5" color="primary">
                {getOptionLabel()}
              </Typography>
            </Box>
            {currentEmployee?.role === EmployeeRole.Foreman &&
              selectedIdJob === "default" && (
                <TooltipCustom
                  title={
                    <Typography variant="subtitle2">
                      This default hierarchy would be assigned on a job if you
                      assign this Foreman.
                    </Typography>
                  }
                  placement="right"
                >
                  <InfoOutlined color="primary" />
                </TooltipCustom>
              )}
          </Box>
          <Divider />
        </Box>
      )}
      {!isCrewLoading && (crews.length === 0 || !isUserInCrew) && (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          padding="48px"
        >
          {currentEmployee?.role === "foreman" && (
            <Button
              disabled={isDisabled}
              variant="contained"
              color="primary"
              onClick={handleClickCreateCrew}
            >
              Create crew
            </Button>
          )}
          {currentEmployee?.role !== "foreman" && (
            <Typography>
              No crew found, go to a foreman or job to assign this employee into
              a crew
            </Typography>
          )}
        </Box>
      )}
      {!isCrewLoading && crews.length > 0 && isUserInCrew && (
        <Box>
          {currentEmployee?.role !== EmployeeRole.Worker && (
            <Fragment>
              <Grid container>
                <Grid item lg={2} sm={3} xs={12}>
                  <Box className={classes.titleContainer}>
                    <Typography variant="body2" className={classes.title}>
                      Supervisor
                    </Typography>
                    {currentEmployee?.role === EmployeeRole.Foreman && (
                      <IconButton
                        aria-label="edit supervisor"
                        className={classes.icon}
                        onClick={handleClickOpenSupervisorModal}
                        disabled={isDisabled}
                      >
                        {crewSupervisors.length > 0 ? <Edit /> : <Add />}
                      </IconButton>
                    )}
                  </Box>
                </Grid>
                <Grid item sm={5} xs={12}>
                  {crewSupervisors.length === 0 ? (
                    <Box display="flex" height="100%" alignItems="center">
                      <Typography>No supervisor assigned</Typography>
                    </Box>
                  ) : (
                    <Collapse
                      in={isSupervisorsDisplayed}
                      collapsedHeight={88}
                      ref={supervisorsRef}
                    >
                      <Grid container spacing={3}>
                        {crewSupervisors.map((supervisor) => (
                          <Grid item key={`${supervisor.id}`}>
                            <InfoItem
                              key={supervisor.employeeId}
                              isCurrentUser={
                                supervisor.employeeId === Number(userId)
                              }
                              employee={
                                supervisor.employee || ({} as EmployeeModel)
                              }
                            />
                          </Grid>
                        ))}
                      </Grid>
                    </Collapse>
                  )}
                </Grid>
                {supervisorListHeight > 88 && crewSupervisors.length > 0 && (
                  <Grid item xs={1}>
                    <Box display="flex" height="64px" alignItems="center">
                      <Box onClick={handleClickCollapseSupervisors}>
                        {isSupervisorsDisplayed ? (
                          <KeyboardArrowUp
                            fontSize="large"
                            className={classes.collapseIcon}
                          />
                        ) : (
                          <KeyboardArrowDown
                            fontSize="large"
                            className={classes.collapseIcon}
                          />
                        )}
                      </Box>
                    </Box>
                  </Grid>
                )}
              </Grid>
              <Box marginBottom="24px">
                <Divider />
              </Box>
            </Fragment>
          )}
          <Grid container>
            <Grid item lg={2} sm={3} xs={12}>
              <Box className={classes.titleContainer}>
                <Typography variant="body2" className={classes.title}>
                  Foreman
                </Typography>
                {currentEmployee?.role === EmployeeRole.Supervisor &&
                  selectedIdJob !== "default" && (
                    <IconButton
                      aria-label="edit foreman"
                      className={classes.icon}
                      disabled={isDisabled}
                      onClick={handleClickOpenForemanModal}
                    >
                      {crewForemen.length > 0 ? <Edit /> : <Add />}
                    </IconButton>
                  )}
              </Box>
            </Grid>
            <Grid item sm={5} xs={12}>
              {crewForemen.length === 0 ? (
                <Box display="flex" height="100%" alignItems="center">
                  <Typography>No foreman assigned</Typography>
                </Box>
              ) : (
                <Collapse
                  in={isForemenDisplayed}
                  collapsedHeight={88}
                  ref={foremenRef}
                >
                  <Grid container spacing={3}>
                    {crewForemen.map((foreman) => (
                      <Grid item key={`${foreman.id}`}>
                        <InfoItem
                          isCurrentUser={foreman.employeeId === Number(userId)}
                          employee={foreman.employee || ({} as EmployeeModel)}
                          disabled={isDisabled}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Collapse>
              )}
            </Grid>
            {foremanListHeight > 88 && crewForemen.length > 0 && (
              <Grid item xs={1}>
                <Box display="flex" height="64px" alignItems="center">
                  <Box onClick={handleClickCollapseForemen}>
                    {isForemenDisplayed ? (
                      <KeyboardArrowUp
                        fontSize="large"
                        className={classes.collapseIcon}
                      />
                    ) : (
                      <KeyboardArrowDown
                        fontSize="large"
                        className={classes.collapseIcon}
                      />
                    )}
                  </Box>
                </Box>
              </Grid>
            )}
          </Grid>
          <Box marginBottom="24px">
            <Divider />
          </Box>
          <Grid container>
            <Grid item lg={2} sm={3} xs={12}>
              <Box className={classes.titleContainer}>
                <Typography variant="body2" className={classes.title}>
                  Crew
                </Typography>
                {currentEmployee?.role === EmployeeRole.Foreman && (
                  <IconButton
                    aria-label="edit workers"
                    className={classes.icon}
                    onClick={handleToggleAssignmentsModal}
                    disabled={isDisabled}
                  >
                    {crewWorkers.length > 0 ? <Edit /> : <Add />}
                  </IconButton>
                )}
              </Box>
            </Grid>
            <Grid item sm={5} xs={12}>
              {crewWorkers.length === 0 ? (
                <Box display="flex" height="100%" alignItems="center">
                  <Typography>No worker assigned</Typography>
                </Box>
              ) : (
                <Collapse
                  in={isWorkersDisplayed}
                  collapsedHeight={88}
                  ref={workersRef}
                >
                  <Grid container spacing={3}>
                    {crewWorkers.map((worker) => (
                      <Grid item key={`${worker.id}`}>
                        <InfoItem
                          employee={worker.employee || ({} as EmployeeModel)}
                          isCurrentUser={worker.employeeId === Number(userId)}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </Collapse>
              )}
            </Grid>
            {workerListHeight > 88 && crewWorkers.length > 0 && (
              <Grid item xs={1}>
                <Box display="flex" height="64px" alignItems="center">
                  <Box onClick={handleClickCollapseWorkers}>
                    {isWorkersDisplayed ? (
                      <KeyboardArrowUp
                        fontSize="large"
                        className={classes.collapseIcon}
                      />
                    ) : (
                      <KeyboardArrowDown
                        fontSize="large"
                        className={classes.collapseIcon}
                      />
                    )}
                  </Box>
                </Box>
              </Grid>
            )}
          </Grid>
        </Box>
      )}

      <SupervisorModal
        isOpen={isSupervisorModalOpen}
        supervisorAssigned={
          crewSupervisors.length > 0 ? crewSupervisors[0].employee : undefined
        }
        regionId={regionId}
        onClickCancel={handleCloseSupervisorModal}
        onClickSave={handleClickSaveSupervisorModal}
      />

      <ForemanModal
        isOpen={isForemanModalOpen}
        foremanAssigned={
          crewForemen.length > 0 ? crewForemen[0].employee : undefined
        }
        regionId={regionId}
        onClickCancel={handleCloseForemanModal}
        onClickSave={handleClickSaveForemanModal}
      />
      {crewsData && (
        <CrewAssignmentsModal
          crewsData={crewsData}
          employeeSelectedIds={employeeSelectedIds}
          isOpen={isAssignmentsModalOpen}
          regionId={regionId}
          onToggle={handleToggleAssignmentsModal}
          onClickDelete={handleClickDelete}
          onClickSave={handleClickSaveCrewAssignments}
          onClickCheckbox={handleClickCheckbox}
        />
      )}
    </Fragment>
  );
}
