import { NotificationModel } from "@dwo/shared/dist/models/notificationModel";
import { ServiceOptions } from "@dwo/shared/dist/services/baseService";
import { notificationService } from "@dwo/shared/dist/services/notificationService";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, RootState } from "app/store";
import { DEFAULT_LIMIT } from "utils/sharedUtils";
import { error } from "features/error/errorSlice";

interface notificationState {
  notifications: NotificationModel[];
  newestNotifications: NotificationModel[];
  error: boolean;
  isLoading: boolean;
  limit?: number;
  message?: string;
  offset?: number;
  order?: any;
  count: number;
  comesFromAlerts: boolean;
  // TODO: this should be dwoJobId
  alertJobId?: number;
}

const initialState: notificationState = {
  notifications: [],
  newestNotifications: [],
  error: false,
  isLoading: false,
  limit: DEFAULT_LIMIT,
  message: "",
  offset: 0,
  order: undefined,
  count: 0,
  comesFromAlerts: false,
  alertJobId: undefined,
};

export const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    setCount: (state, action: PayloadAction<number>) => {
      state.count = action.payload;
    },
    setNotifications: (state, action: PayloadAction<NotificationModel[]>) => {
      state.notifications = action.payload;
    },
    setNewestNotifications: (
      state,
      action: PayloadAction<NotificationModel[]>,
    ) => {
      state.newestNotifications = action.payload;
    },
    setError: (state, action: PayloadAction<boolean>) => {
      state.error = action.payload;
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setLimit: (state, action: PayloadAction<number>) => {
      state.limit = action.payload;
    },
    setMessage: (state, action: PayloadAction<string>) => {
      state.message = action.payload;
    },
    setOffset: (state, action: PayloadAction<number>) => {
      state.offset = action.payload;
    },
    setOrder: (state, action: PayloadAction<any>) => {
      state.order = action.payload;
    },
    setComesFromAlerts: (state, action: PayloadAction<boolean>) => {
      state.comesFromAlerts = action.payload;
    },
    setAlertJobId: (state, action: PayloadAction<number | undefined>) => {
      state.alertJobId = action.payload;
    },
  },
});

export const {
  setNotifications,
  setNewestNotifications,
  setError,
  setIsLoading,
  setLimit,
  setMessage,
  setOffset,
  setOrder,
  setCount,
  setAlertJobId,
  setComesFromAlerts,
} = notificationSlice.actions;

export const getAllNotifications = (
  options?: ServiceOptions,
): AppThunk => async (dispatch) => {
  try {
    dispatch(setIsLoading(true));
    const {
      data,
      count,
      limit,
    } = await notificationService.getNotificationsAdmin(options);
    dispatch(setNotifications(data));
    dispatch(setLimit(limit));
    dispatch(setCount(count));
  } catch (err) {
    dispatch(
      error(
        {
          title: "Could not get all notifications",
          message: err.message,
        },
        () => dispatch(getAllNotifications(options)),
      ),
    );
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const getNewestNotifications = (
  options?: ServiceOptions,
): AppThunk => async (dispatch) => {
  try {
    dispatch(setIsLoading(true));
    const { data } = await notificationService.getNewestAlerts(options);
    dispatch(setNewestNotifications(data));
  } catch (err) {
    dispatch(
      error(
        {
          title: "Could not get newest notifications",
          message: err.message,
        },
        () => dispatch(getNewestNotifications(options)),
      ),
    );
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const deleteNotification = (id: number): AppThunk => async (
  dispatch,
  select,
) => {
  try {
    dispatch(setIsLoading(true));
    await notificationService.delete(id);
    const updatedNotifications = [...select().notification.notifications];
    const foundIndex = updatedNotifications.findIndex(
      (notification) => notification.id === Number(id),
    );

    if (foundIndex >= 0) {
      updatedNotifications.splice(foundIndex, 1);
      dispatch(setNotifications(updatedNotifications));
    }
  } catch (e) {
    dispatch(
      error(
        {
          title: "Could not delete notification",
          message: e.message,
        },
        () => dispatch(deleteNotification(id)),
      ),
    );
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const deleteAllNotifications = (): AppThunk => async (dispatch) => {
  try {
    dispatch(setIsLoading(true));
    await notificationService.clearAllNotifications();
    dispatch(setNotifications([]));
    dispatch(setCount(0));
    dispatch(setLimit(DEFAULT_LIMIT));
  } catch (e) {
    dispatch(
      error(
        {
          title: "Could not delete notifications",
          message: e.message,
        },
        () => dispatch(deleteAllNotifications()),
      ),
    );
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const selectNotifications = (state: RootState) =>
  state.notification.notifications;

export const selectNewestNotifications = (state: RootState) =>
  state.notification.newestNotifications;

export const selectIsLoading = (state: RootState) =>
  state.notification.isLoading;

export const selectCount = (state: RootState) => state.notification.count;

export const selectLimit = (state: RootState) => state.notification.limit;

export const selectComesFromAlerts = (state: RootState) =>
  state.notification.comesFromAlerts;

export const selectAlertJobId = (state: RootState) =>
  state.notification.alertJobId;

export default notificationSlice.reducer;
