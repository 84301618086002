import {
  Box,
  createStyles,
  Grid,
  makeStyles,
  TextField,
  Theme,
  Typography,
} from "@material-ui/core";

import { DatePickerButton } from "components/DatePickerButton";
import { GlobalModal } from "components/globalModal/GlobalModal";
import { LoadingSpinner } from "components/LoadingSpinner";
import {
  getCurrentVehicle,
  selectCurrentVehivle,
  selectForemanId,
  selectIsLoading,
} from "features/vehicles/vehiclesSlice";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { slashFormatDateWithHours } from "utils/dateUtils";
import { DEFAULT_EMPTY } from "utils/jobUtils";

interface VehicleModalProps {
  isOpen: boolean;
  jobDisplayedId: string;
  onClickClose: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      display: "flex",
      padding: "24px",
      [theme.breakpoints.down("xs")]: {
        flexDirection: "column",
      },
    },
    jobId: {
      color: theme.palette.primary.main,
    },
    jobIdDatePickerContainer: {
      display: "flex",
      justifyContent: "space-between",
      marginTop: "-8px",
      padding: "0 24px 16px 24px",
      [theme.breakpoints.down("xs")]: {
        flexDirection: "column",
        "& > h6": {
          marginBottom: "24px",
        },
      },
    },
    lastField: {
      margin: "0 24px 0 0",
      [theme.breakpoints.down("xs")]: {
        margin: 0,
      },
    },
    leftFields: {
      margin: "0 24px 24px 0",
      [theme.breakpoints.down("xs")]: {
        margin: "0 0 24px 0",
      },
    },
    picture: {
      height: "150px",
      width: "150px",
    },
    root: {
      "& .MuiInputBase-root.Mui-disabled": {
        color: theme.palette.grey[500],
      },
      "& .MuiInput-underline.Mui-disabled:before": {
        borderBottomStyle: "double",
      },
    },
  }),
);

export function VehicleModal({
  isOpen,
  jobDisplayedId,
  onClickClose,
}: VehicleModalProps) {
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const jobId = parseInt(id, 10);
  const currentVehicle = useSelector(selectCurrentVehivle);
  const foremanId = useSelector(selectForemanId);
  const isLoading = useSelector(selectIsLoading);
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const classes = useStyles();

  useEffect(() => {
    if (foremanId) {
      dispatch(getCurrentVehicle(foremanId, jobId, selectedDate));
    }
  }, [foremanId, jobId, selectedDate, dispatch]);

  const handleDateChange = (date: Date) => {
    const currentDate = new Date();
    const updatedDate = new Date(date.getTime());

    updatedDate.setHours(
      currentDate.getHours(),
      currentDate.getMinutes(),
      currentDate.getSeconds(),
      currentDate.getMilliseconds(),
    );

    setSelectedDate(updatedDate);
  };

  const jobIdContainer = (
    <Box className={classes.jobIdDatePickerContainer}>
      <Typography
        className={classes.jobId}
        variant="h6"
      >{`Job ID ${jobDisplayedId}`}</Typography>
      <DatePickerButton
        dateValue={selectedDate}
        disabledFuture
        isDisabled={isLoading}
        onSelectDate={handleDateChange}
      />
    </Box>
  );

  return (
    <GlobalModal
      Filters={jobIdContainer}
      modalMaxWidth="768px"
      open={isOpen}
      showActionButtons={false}
      title="Vehicles"
      onClickClose={onClickClose}
    >
      {isLoading && <LoadingSpinner />}
      {!isLoading && !currentVehicle && (
        <Box
          alignItems="center"
          display="flex"
          justifyContent="center"
          padding="80px 24px"
        >
          <Typography align="center" variant="h6">
            There is no vehicle in use right now.
          </Typography>
        </Box>
      )}
      {!isLoading && currentVehicle && (
        <Box className={classes.content}>
          <Grid container>
            <Grid container item xs={12}>
              <Grid item sm={6} xs={12}>
                <Box className={classes.leftFields}>
                  <TextField
                    classes={{ root: classes.root }}
                    disabled
                    label="Vehicle ID"
                    value={currentVehicle.id}
                  />
                </Box>

                <Box className={classes.leftFields}>
                  <TextField
                    classes={{ root: classes.root }}
                    disabled
                    label="Model"
                    value={currentVehicle.model || DEFAULT_EMPTY}
                  />
                </Box>

                <Box className={classes.leftFields}>
                  <TextField
                    classes={{ root: classes.root }}
                    disabled
                    label="Driver"
                    value={currentVehicle.driver?.name || DEFAULT_EMPTY}
                  />
                </Box>
              </Grid>

              <Grid item sm={6} xs={12}>
                <Box marginBottom="24px">
                  <TextField
                    classes={{ root: classes.root }}
                    disabled
                    label="Start Hour"
                    value={
                      currentVehicle.startDate
                        ? slashFormatDateWithHours(
                            new Date(currentVehicle.startDate),
                          )
                        : DEFAULT_EMPTY
                    }
                  />
                </Box>

                <Box marginBottom="24px">
                  <TextField
                    classes={{ root: classes.root }}
                    disabled
                    label="End Hour"
                    value={
                      currentVehicle.endDate
                        ? slashFormatDateWithHours(
                            new Date(currentVehicle.endDate),
                          )
                        : DEFAULT_EMPTY
                    }
                  />
                </Box>
              </Grid>
            </Grid>

            <Grid container item xs={12}>
              <Grid item sm={6} xs={12}>
                <Box className={classes.leftFields}>
                  <TextField
                    classes={{ root: classes.root }}
                    disabled
                    label="Miles In"
                    value={`${currentVehicle.milesIn || 0} miles`}
                  />
                </Box>
              </Grid>

              <Grid item sm={6} xs={12}>
                <Box marginBottom="24px">
                  <TextField
                    classes={{ root: classes.root }}
                    disabled
                    label="Miles Out"
                    value={`${currentVehicle.milesOut || 0} miles`}
                  />
                </Box>
              </Grid>
            </Grid>

            <Grid container item xs={12}>
              <Grid item sm={6} xs={12}>
                <Box className={classes.lastField}>
                  <TextField
                    classes={{ root: classes.root }}
                    disabled
                    label="Total Distance"
                    value={`${currentVehicle.totalDistance || 0} miles`}
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      )}
    </GlobalModal>
  );
}
