import { GlobalModal } from "components/globalModal/GlobalModal";
import { JobModel } from "@dwo/shared/dist/models/jobModel";
import React, { useEffect, useState } from "react";
import { TableCustom } from "components/table/TableCustom";
import { noop } from "lodash/fp";
import { useDispatch, useSelector } from "react-redux";
import {
  createWorkshifts,
  getForemanJobs,
  selectCurrentDWO,
  selectForemanJobs,
  selectIsLoadingForemanJobs,
  selectJobsOfDWOs,
} from "features/dwo/dwoSlice";
import {
  foremanJobsModalColumns,
  getForemanJobsModalTableRows,
} from "utils/timesheetDetailsUtils";
import { cloneDeep } from "lodash";

interface JobsModalProps {
  isOpen: boolean;
  onClickCancel: () => void;
}

export function JobsModal({ isOpen, onClickCancel }: JobsModalProps) {
  const dispatch = useDispatch();
  const currentDWO = useSelector(selectCurrentDWO);
  const isLoading = useSelector(selectIsLoadingForemanJobs);
  const jobsOfDWO = useSelector(selectJobsOfDWOs);
  const foremanJobs = useSelector(selectForemanJobs);
  const [selectedJobs, setSelectedJobs] = useState<JobModel[]>([]);

  useEffect(() => {
    if (currentDWO) {
      dispatch(getForemanJobs(currentDWO.foremanId));
    }
  }, [dispatch, currentDWO]);

  const getJobsList = (): JobModel[] => {
    return foremanJobs.filter((foremanJob) => {
      const found = jobsOfDWO.find((dwoJob) => dwoJob.id === foremanJob.id);
      return !found;
    });
  };

  const handleClickCheckbox = (idJob: string, isChecked: boolean) => {
    const foundJobIndex = selectedJobs.findIndex(
      (job) => job.id?.toString() === idJob,
    );

    if (isChecked && foundJobIndex === -1) {
      const foundJob = foremanJobs.find((job) => job.id?.toString() === idJob);
      if (foundJob) {
        const updatedJobs = cloneDeep(selectedJobs);
        updatedJobs.push(foundJob);
        setSelectedJobs(updatedJobs);
      }
      return;
    }

    if (!isChecked && foundJobIndex !== -1) {
      const updatedJobs = cloneDeep(selectedJobs);
      updatedJobs.splice(foundJobIndex, 1);
      setSelectedJobs(updatedJobs);
    }
  };

  const handleClickSave = () => {
    if (currentDWO && selectedJobs.length > 0) {
      dispatch(createWorkshifts(currentDWO, selectedJobs));
    }
    onClickCancel();
  };

  return (
    <GlobalModal
      modalMaxWidth="700px"
      open={isOpen}
      title="Add Jobs to DWO"
      onClickContinue={handleClickSave}
      onClickClose={onClickCancel}
    >
      <TableCustom
        columns={foremanJobsModalColumns}
        isLoading={isLoading}
        noRowsMessage="No available jobs to display."
        rows={getForemanJobsModalTableRows(
          getJobsList(),
          selectedJobs,
          handleClickCheckbox,
        )}
        onClickSort={noop}
      />
    </GlobalModal>
  );
}
