import { selectCurrentUser } from "features/logIn/sessionSlice";
import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import { Route, RouteComponentProps, Switch } from "react-router-dom";
import * as routes from "routes/Roots";
import { NotFoundScreen } from "screens/NotFoundScreen";
import { UploadDetails } from "screens/UploadDetailsScreen";
import { UploadDownloadManagementScreen } from "screens/UploadDownloadManagementScreen";
import { ProtectedRoute } from "./ProtectedRoute";

export function UploadRouter({ match: { url } }: RouteComponentProps) {
  const currentUser = useSelector(selectCurrentUser);
  return (
    <Switch>
      {currentUser?.employee.role === "admin" ||
      currentUser?.employee.role === "subadmin" ? (
        <Fragment>
          <ProtectedRoute exact path={`${url}/`}>
            <UploadDownloadManagementScreen />
          </ProtectedRoute>
          <ProtectedRoute exact path={`${url}/:id`}>
            <UploadDetails />
          </ProtectedRoute>
        </Fragment>
      ) : (
        <ProtectedRoute exact path={`${url}/`}>
          <Route
            render={() => <NotFoundScreen header="Not Authorized" />}
            path={routes.ROOT_UPLOAD}
          />
        </ProtectedRoute>
      )}
    </Switch>
  );
}
