import { JobHazardEmployeeData } from "@dwo/shared/dist/models/DWOModel";
import { WorkShiftModel } from "@dwo/shared/dist/models/workShiftModel";
import { createStyles, makeStyles, Paper, Theme } from "@material-ui/core";
import { LoadingSpinner } from "components/LoadingSpinner";
import { TableCustom } from "components/table/TableCustom";
import {
  displayCrewOverviewColumns,
  getDisplayCrewOverviewRows,
} from "components/timesheetDetails/crewOverviewUtils";
import { TimeOffModal } from "components/timesheetDetails/TimeOffModal";
import {
  selectDWOCrewDetails,
  selectIsLoadingDWODetails,
} from "features/dwo/dwoSlice";
import { noop } from "lodash/fp";
import React from "react";
import { useSelector } from "react-redux";

interface DisplayCrewOverviewTableProps {
  jobHazardEmployeeData?: JobHazardEmployeeData[];
}

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    paper: {
      backgroundColor: "white",
      borderRadius: "0px",
      margin: "0 24px 24px 24px",
      padding: "0 24px",
      position: "relative",
      zIndex: 2,
    },
  }),
);

export function DisplayCrewOverviewTable({
  jobHazardEmployeeData = [],
}: DisplayCrewOverviewTableProps) {
  const dwoCrewDetails = useSelector(selectDWOCrewDetails);
  const isLoading = useSelector(selectIsLoadingDWODetails);
  const classes = useStyles();
  const employeesInWorkShift =
    dwoCrewDetails?.workShifts?.filter(
      (workShift: WorkShiftModel) => workShift.employee.role !== "supervisor",
    ) || [];

  return (
    <Paper className={classes.paper}>
      {isLoading && <LoadingSpinner />}
      {!isLoading && employeesInWorkShift && jobHazardEmployeeData && (
        <TableCustom
          columns={displayCrewOverviewColumns}
          noRowsMessage="There are no crew timesheets to display."
          rows={getDisplayCrewOverviewRows(
            employeesInWorkShift,
            jobHazardEmployeeData,
          )}
          onClickSort={noop}
        />
      )}
      <TimeOffModal />
    </Paper>
  );
}
