import { EmployeeModel } from "@dwo/shared/dist/models/employeeModel";
import { JobModel } from "@dwo/shared/dist/models/jobModel";
import { RegionModel } from "@dwo/shared/dist/models/regionModel";
import { ServiceOptions } from "@dwo/shared/dist/services/baseService";
import { MainTableColumn, MainTableRow } from "./tableUtils";

export const initialEmployeeDetailsQuery: ServiceOptions = {
  include: ["regions", "user", { employeeSupervisors: ["supervisor"] }],
};

export const assignJobsModalColumns: MainTableColumn[] = [
  {
    field: "checkbox",
    headerName: "",
  },
  {
    field: "jobId",
    headerName: "Job ID",
  },
  {
    field: "address",
    headerName: "Job Address",
  },
  {
    field: "region",
    headerName: "Region",
  },
  {
    field: "type",
    headerName: "Type of Job",
  },
];

export const removeJobsModalColumns: MainTableColumn[] = [
  {
    field: "jobId",
    headerName: "Job ID",
  },
  {
    field: "address",
    headerName: "Job Address",
  },
  {
    field: "region",
    headerName: "Region",
  },
  {
    field: "type",
    headerName: "Type of Job",
  },
  {
    field: "deleteIcon",
    headerName: "",
  },
];

export const getAssignJobsModalTableRows = (
  jobs: JobModel[],
  selectedJobsIds: string[],
  onClickCheckbox: (idRow: string, isChecked: boolean) => void,
) =>
  jobs.map((job: JobModel) => {
    const address = { value: job.address };
    const idRow = (job.id as number).toString();
    const jobId = { value: job.jobId as string };
    const type = { value: job.type };
    const region = (() => {
      if (!job.region) {
        return { value: "No Region" };
      }

      if (typeof job.region === "string") {
        return { value: job.region };
      }

      return { value: job.region.name };
    })();

    const innerCells = [jobId, address, region, type];
    const isCheckedRow = Boolean(
      selectedJobsIds.find((selectedId: string) => selectedId === idRow),
    );

    return {
      idRow,
      innerCells,
      isCheckedRow,
      onClickCheckbox,
    };
  });

export const getRemoveJobsModalTableRows = (
  jobs: JobModel[],
  onClickDelete: (id: string) => void,
): MainTableRow[] =>
  jobs.map((job: JobModel) => {
    const address = { value: job.address };
    const idRow = (job.id as number).toString();
    const jobId = { value: job.jobId as string };
    const type = { value: job.type };
    const region = { value: (job.region as string) || "No Region" };

    const innerCells = [jobId, address, region, type];

    return {
      idRow,
      innerCells,
      onClickDelete,
    };
  });

export const supervisorAssignmentColumns: MainTableColumn[] = [
  {
    field: "radio",
    headerName: "",
  },
  {
    field: "image",
    headerName: "",
  },
  {
    field: "name",
    headerName: "NAME",
  },
  {
    field: "employeeId",
    headerName: "ID #",
  },
  {
    field: "companyName",
    headerName: "COMPANY",
  },
];

export const getSupervisorAssignmentRows = (
  employees: EmployeeModel[],
  supervisorSelectedId: string,
  checkboxHandler: (idRow: string, isChecked: boolean) => void,
): MainTableRow[] => {
  return employees.map((employee) => {
    if (employees) {
      const srcImage = employee.employeePictures
        ? (employee.employeePictures[0].picture.url as string)
        : "";
      const image = { isImage: true, value: srcImage };
      const name = { value: `${employee.firstName} ${employee.lastName}` };
      const employeeId = { value: employee.employeeId };
      const company = { value: employee.companyName };

      const idRow = employee.id ? employee.id.toString() : "";
      const innerCells = [image, name, employeeId, company];
      const isCheckedRow = supervisorSelectedId === idRow;

      return {
        idRow,
        innerCells,
        isCheckedRow,
        onChangeRadio: checkboxHandler,
      };
    }
    return {} as MainTableRow;
  });
};

export const getForemanAssignmentRows = (
  employees: EmployeeModel[],
  foremanSelectedId: string,
  checkboxHandler: (idRow: string, isChecked: boolean) => void,
): MainTableRow[] => {
  return employees.map((employee) => {
    if (employees) {
      const srcImage = employee.employeePictures
        ? (employee.employeePictures[0].picture.url as string)
        : "";
      const image = { isImage: true, value: srcImage };
      const name = { value: `${employee.firstName} ${employee.lastName}` };
      const employeeId = { value: employee.employeeId };
      const company = { value: employee.companyName };

      const idRow = employee.id ? employee.id.toString() : "";
      const innerCells = [image, name, employeeId, company];
      const isCheckedRow = foremanSelectedId === idRow;

      return {
        idRow,
        innerCells,
        isCheckedRow,
        onChangeRadio: checkboxHandler,
      };
    }
    return {} as MainTableRow;
  });
};

export const crewAssignmentsColumns: MainTableColumn[] = [
  {
    field: "image",
    headerName: "",
  },
  {
    field: "name",
    headerName: "NAME",
  },
  {
    field: "employeeId",
    headerName: "ID #",
  },
  {
    field: "companyName",
    headerName: "COMPANY",
  },
  {
    field: "icon",
    headerName: "",
  },
];

export const getCrewAssignmentsRows = (
  employees: EmployeeModel[],
  handleDelete: (id: string) => void,
): MainTableRow[] =>
  employees.map((employee) => {
    if (employee) {
      const srcImage =
        employee.employeePictures && employee.employeePictures[0]
          ? (employee.employeePictures[0].picture.url as string)
          : employee.pictureUrl
          ? employee.pictureUrl
          : "";
      const image = { isImage: true, value: srcImage };
      const name = { value: `${employee.firstName} ${employee.lastName}` };
      const employeeId = { value: employee.employeeId };
      const company = { value: employee.companyName };

      const idRow = employee.id ? employee.id.toString() : "";
      const innerCells = [image, name, employeeId, company];

      return {
        idRow,
        innerCells,
        onClickDelete: handleDelete,
      };
    }
    return {} as MainTableRow;
  });

export const crewAvailableColumns: MainTableColumn[] = [
  {
    field: "checkbox",
    headerName: "",
  },
  {
    field: "image",
    headerName: "",
  },
  {
    field: "name",
    headerName: "NAME",
  },
  {
    field: "employeeId",
    headerName: "ID #",
  },
  {
    field: "companyName",
    headerName: "COMPANY",
  },
];

export const getCrewAvailableRows = (
  employees: EmployeeModel[],
  employeeSelectedId: string[],
  checkboxHandler: (idRow: string, isChecked: boolean) => void,
): MainTableRow[] =>
  employees.map((employee) => {
    if (employee) {
      const srcImage =
        employee.employeePictures && employee.employeePictures[0]
          ? (employee.employeePictures[0].picture.url as string)
          : "";
      const image = { isImage: true, value: srcImage };
      const name = { value: `${employee.firstName} ${employee.lastName}` };
      const employeeId = { value: employee.employeeId };
      const company = { value: employee.companyName };

      const idRow = employee.id ? employee.id.toString() : "";
      const innerCells = [image, name, employeeId, company];
      const isCheckedRow = Boolean(
        employeeSelectedId.find((idSelected) => idSelected === idRow),
      );

      return {
        idRow,
        innerCells,
        isCheckedRow,
        onClickCheckbox: checkboxHandler,
      };
    }
    return {} as MainTableRow;
  });

export const getLocationToDisplay = (regions?: RegionModel[]) =>
  regions && regions.length > 0
    ? regions
        .reduce((acc: string[], region: RegionModel) => {
          return [...acc, region.name];
        }, [])
        .join(", ")
    : "Location";
