import { useState, useCallback } from "react";

export function useNodeScrollHeight() {
  const [nodeScrollHeight, setNodeScrollHeight] = useState(0);

  const nodeRef = useCallback((node: HTMLElement | null) => {
    if (node !== null) {
      setNodeScrollHeight(node.scrollHeight);
    }
  }, []);

  return [nodeScrollHeight, nodeRef] as const;
}
