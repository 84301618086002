import {
  JobCategories,
  JobsSummaryOverviewDataElement,
} from "@dwo/shared/dist/models/jobSummaryModel";
import { Box, useTheme } from "@material-ui/core";
import Chart, { ChartTooltipModel } from "chart.js";
import {
  selectSummaryCategoriesAndColors,
  selectSummarySelectedCategory,
  selectSummarySelectedDateRange,
  setSummarySelectedTab,
} from "features/summary/summarySlice";
import React, { useEffect, useRef, useState } from "react";
import { renderToString } from "react-dom/server";
import { useDispatch, useSelector } from "react-redux";
import {
  getEmptyDataset,
  getPerDayChartData,
  getTooltipDateBartChart,
  getXTicksLabelPerDay,
  OverviewTabs,
} from "utils/summaryUtils";
import { BarChartTooltip } from "./BarChartTooltip";

interface OverviewPerDayChartProps {
  data: JobsSummaryOverviewDataElement[];
}

const tooltipId = "overviewPerDay-tooltip";
const totalDataLabel = "Total Day Hours/Cost";

export function OverviewPerDayChart({ data }: OverviewPerDayChartProps) {
  const dispatch = useDispatch();
  const [customMaxWidth, setCustomMaxWidth] = useState<string>("100%");
  const theme = useTheme();
  const chartCanvasRef = useRef({} as HTMLCanvasElement);
  const categoriesAndColors = useSelector(selectSummaryCategoriesAndColors);
  const selectedCategory = useSelector(selectSummarySelectedCategory);
  const selectedDateRange = useSelector(selectSummarySelectedDateRange);

  useEffect(() => {
    dispatch(setSummarySelectedTab(OverviewTabs.LeftTab));
  }, [dispatch]);

  useEffect(() => {
    if (!selectedDateRange) return;

    const {
      categoriesDatasetsMap,
      dateLabels,
      dataPerDay,
      totalWeekHours,
      totalWorkHoursArray,
    } = getPerDayChartData(data, selectedDateRange);
    const totalHoursDataset = totalWorkHoursArray.map((dayHours: number) => {
      if (totalWeekHours === 0) {
        return 1;
      }

      return totalWeekHours - dayHours;
    });
    const datasets = categoriesAndColors.map((cac) => {
      const currentDataset = categoriesDatasetsMap.get(
        cac.label as JobCategories,
      );

      return {
        backgroundColor: cac.color,
        barThickness: 24,
        data: currentDataset || getEmptyDataset(dateLabels.length),
        hoverBackgroundColor: cac.color,
        label: cac.label,
      };
    });

    datasets.push({
      backgroundColor: theme.palette.grey[300],
      barThickness: 24,
      data: totalHoursDataset,
      hoverBackgroundColor: theme.palette.grey[300],
      label: totalDataLabel,
    });

    const barChart = new Chart(chartCanvasRef.current, {
      data: {
        datasets,
        labels: dateLabels,
      },
      options: {
        legend: {
          display: false,
        },
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              stacked: true,
              gridLines: {
                display: false,
              },
              ticks: {
                display: true,
                callback: function (
                  value: number | string,
                  _index: number,
                  _values: number[] | string[],
                ) {
                  return getXTicksLabelPerDay(value as string);
                },
                fontColor: theme.palette.primary.dark,
                fontFamily: "Noto Sans",
                fontSize: 12,
                fontStyle: "bold",
              },
            },
          ],
          yAxes: [
            {
              stacked: true,
              gridLines: {
                display: false,
              },
              scaleLabel: {
                display: true,
                labelString: "Hours / Costs",
                fontColor: theme.palette.primary.dark,
                fontFamily: "Noto Sans",
                fontSize: 12,
                fontStyle: "bold",
              },
              ticks: {
                beginAtZero: true,
                display: false,
              },
            },
          ],
        },
        tooltips: {
          custom: (tooltipModel: ChartTooltipModel) => {
            let tooltipEl = document.getElementById(tooltipId);

            if (!tooltipEl) {
              tooltipEl = document.createElement("div");
              tooltipEl.id = tooltipId;
              document.body.appendChild(tooltipEl);
            }

            if (tooltipModel.opacity === 0) {
              tooltipEl.style.opacity = "0";
              return;
            }

            const barCategory =
              tooltipModel.body &&
              tooltipModel.body[0] &&
              tooltipModel.body[0].lines[0]
                ? tooltipModel.body[0].lines[0].split(":")[0]
                : undefined;
            const barColor =
              tooltipModel.labelColors && tooltipModel.labelColors[0]
                ? (tooltipModel.labelColors[0] as any).backgroundColor
                : undefined;
            const barDate = tooltipModel.title
              ? tooltipModel.title[0]
              : undefined;

            // Safe guard. This values appear to be valid once tooltipModel.opacity > 0
            if (!barCategory || !barColor || !barDate) {
              tooltipEl.style.opacity = "0";
              return;
            }

            const dayData = dataPerDay.find(
              (singleDayData) => singleDayData.date === barDate,
            );

            if (!dayData) {
              tooltipEl.style.opacity = "0";
              return;
            }

            let customTooltipHTMLString = undefined;

            if (barCategory === totalDataLabel) {
              customTooltipHTMLString = renderToString(
                <BarChartTooltip
                  cost={dayData.totalDayCost.toFixed(2)}
                  date={getTooltipDateBartChart(barDate)}
                  hours={dayData.totalDayHours.toFixed(2)}
                  tooltipOption={{
                    color: theme.palette.grey[500],
                    label: "Day Costs / Hours",
                  }}
                />,
              );
            } else {
              const categoryData = dayData.categoriesMapData.get(
                barCategory as JobCategories,
              );

              if (!categoryData) {
                tooltipEl.style.opacity = "0";
                return;
              }

              customTooltipHTMLString = renderToString(
                <BarChartTooltip
                  cost={parseFloat(categoryData.cost).toFixed(2)}
                  date={getTooltipDateBartChart(barDate)}
                  hours={parseFloat(categoryData.hours).toFixed(2)}
                  tooltipOption={{
                    color: barColor,
                    label: barCategory,
                  }}
                />,
              );
            }

            const position = chartCanvasRef.current.getBoundingClientRect();

            tooltipEl.innerHTML = customTooltipHTMLString;
            tooltipEl.style.opacity = "1";
            tooltipEl.style.position = "absolute";
            tooltipEl.style.left = `${
              position.left + window.pageXOffset + tooltipModel.caretX
            }px`;
            tooltipEl.style.top = `${
              position.top + window.pageYOffset + tooltipModel.caretY
            }px`;
            tooltipEl.style.pointerEvents = "none";
          },
          enabled: false,
        },
      },
      type: "bar",
    });

    if (dateLabels.length <= 5) {
      setCustomMaxWidth("360px");
      return;
    }

    if (dateLabels.length > 5 && dateLabels.length <= 10) {
      setCustomMaxWidth("720px");
      return;
    }

    if (dateLabels.length > 10) {
      setCustomMaxWidth("100%");
      return;
    }

    return () => {
      barChart.destroy();
    };
  }, [
    categoriesAndColors,
    data,
    selectedCategory,
    selectedDateRange,
    theme.palette,
  ]);

  return (
    <Box height="200px" maxWidth={customMaxWidth} width="100%">
      <canvas ref={chartCanvasRef} />
      <div id={tooltipId} />
    </Box>
  );
}
