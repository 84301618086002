import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, RootState } from "app/store";
import { RegionModel } from "@dwo/shared/dist/models/regionModel";
import { ServiceOptions } from "@dwo/shared/dist/services/baseService";
import { regionService } from "@dwo/shared/dist/services/regionService";

interface RegionState {
  count?: number;
  error: boolean;
  loading: boolean;
  message?: string;
  region?: RegionModel;
  regions: RegionModel[];
}

const initialState: RegionState = {
  count: 0,
  error: false,
  loading: false,
  message: "",
  region: undefined,
  regions: [],
};

export const regionSlice = createSlice({
  name: "regions",
  initialState,
  reducers: {
    setCount: (state, action: PayloadAction<number>) => {
      state.count = action.payload;
    },
    setError: (state, action: PayloadAction<boolean>) => {
      state.error = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setMessage: (state, action: PayloadAction<string>) => {
      state.message = action.payload;
    },
    setRegion: (state, action: PayloadAction<RegionModel>) => {
      state.region = action.payload;
    },
    setRegions: (state, action: PayloadAction<RegionModel[]>) => {
      state.regions = action.payload;
    },
  },
});

export const {
  setCount,
  setError,
  setLoading,
  setMessage,
  setRegion,
  setRegions,
} = regionSlice.actions;

export const getAllRegions = (options?: ServiceOptions): AppThunk => async (
  dispatch,
) => {
  try {
    dispatch(setLoading(true));

    const { count, data, message } = await regionService.getAll(options);

    dispatch(setError(false));
    dispatch(setCount(count));
    dispatch(setRegions(data));
    dispatch(setMessage(message || ""));
    dispatch(setLoading(false));
  } catch (error) {
    dispatch(setLoading(false));
    dispatch(setError(true));
    dispatch(setMessage(error.message));
  }
};

export const getRegionById = (
  id: number,
  options?: ServiceOptions,
): AppThunk => async (dispatch) => {
  try {
    dispatch(setLoading(true));

    const { data, message } = await regionService.getById(id, options);

    dispatch(setError(false));
    dispatch(setRegion(data));
    dispatch(setMessage(message || ""));
    dispatch(setLoading(false));
  } catch (error) {
    // TODO: dispatch Toast in case of error
    dispatch(setLoading(false));
    dispatch(setError(true));
    dispatch(setMessage(error.message));
  }
};

export const selectCount = (state: RootState) => state.region.count;
export const selectError = (state: RootState) => state.region.error;
export const selectLoading = (state: RootState) => state.region.loading;
export const selectMessage = (state: RootState) => state.region.message;
export const selectRegion = (state: RootState) => state.region.region;
export const selectRegions = (state: RootState) => state.region.regions;

export default regionSlice.reducer;
