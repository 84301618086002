import { CrewModel } from "@dwo/shared/dist/models/crewModel";
import { DWOJobModel } from "@dwo/shared/dist/models/DWOJobModel";
import { EmployeeModel } from "@dwo/shared/dist/models/employeeModel";
import { HazardModel } from "@dwo/shared/dist/models/hazardModel";
import { JobExpenseModel } from "@dwo/shared/dist/models/jobExpenseModel";
import { JobInvoiceModel } from "@dwo/shared/dist/models/jobInvoiceModel";
import { JobModel } from "@dwo/shared/dist/models/jobModel";
import { ServiceOptions } from "@dwo/shared/dist/services/baseService";
import { VehicleModel } from "@dwo/shared/dist/models/vehicleModel";
import { Box, Button, Typography } from "@material-ui/core";
import { DropdownOption } from "components/DropdownPagination";
import { ExpenseActionIcons } from "components/jobDetails/expensesModal/ExpenseActionIcons";
import { HazardDownloadIcon } from "components/jobDetails/hazardHistoryModal/HazardModalIcon";
import { HazardLink } from "components/jobDetails/hazardHistoryModal/HazardModalLink";
import { TextLink } from "components/TextLink";
import React from "react";
import { ROOT_TIMESHEETS_MANAGEMENT } from "routes/Roots";
import { MainTableCell, MainTableColumn, MainTableRow } from "utils/tableUtils";
import { slashFormatDate, slashFormatDateWithHours } from "./dateUtils";
import { DEFAULT_EMPTY, JobStatusLabels, JobStatusValues } from "./jobUtils";

export enum PROGRESS_TYPE_LABELS {
  ESTIMATED = "Estimated Progress",
  LABOR = "Labor Hours Progress",
  TIME = "Time Progress",
}

export enum PROGRESS_TYPE_VALUES {
  ALL = "all",
  ESTIMATED = "estimated",
  LABOR = "labor",
  TIME = "time",
}

export interface JobProgressFormFields {
  progress: number;
  status: JobStatusValues;
}

export const assignDriverModalColumns: MainTableColumn[] = [
  {
    field: "radio",
    headerName: "",
  },
  {
    field: "image",
    headerName: "",
  },
  {
    field: "name",
    headerName: "NAME",
  },
  {
    field: "employeeId",
    headerName: "ID #",
  },
  {
    field: "companyName",
    headerName: "COMPANY",
  },
];

export const deleteForemanModalColumns: MainTableColumn[] = [
  {
    field: "image",
    headerName: "",
  },
  {
    field: "name",
    headerName: "NAME",
  },
  {
    field: "employeeId",
    headerName: "ID #",
  },
  {
    field: "companyName",
    headerName: "COMPANY",
  },
  {
    field: "icon",
    headerName: "",
  },
];

export const assignForemanModalColumns: MainTableColumn[] = [
  {
    field: "checkbox",
    headerName: "",
  },
  {
    field: "image",
    headerName: "",
  },
  {
    field: "name",
    headerName: "NAME",
  },
  {
    field: "employeeId",
    headerName: "ID #",
  },
  {
    field: "companyName",
    headerName: "COMPANY",
  },
];

export const crewModalColums: MainTableColumn[] = [
  { field: "crewId", headerName: "Crew ID" },
  { field: "foreman", headerName: "Foreman" },
  { field: "createTimesheet", headerName: "Create Timesheet for" },
];

export const expensesModalColumns: MainTableColumn[] = [
  { field: "fileId", headerName: "ID #" },
  { field: "date", hasSorting: true, headerName: "Create Date" },
  { field: "amount", hasSorting: true, headerName: "Amount" },
  { field: "type", hasSorting: true, headerName: "Type" },
  { field: "description", headerName: "Description" },
  { field: "uploadedBy", headerName: "Uploaded By" },
  { field: "isReBillable", headerName: "Re-Billable" },
  { field: "action", headerName: "Action" },
];

export const hazardModalColumns: MainTableColumn[] = [
  { field: "hazardForm", headerName: "Hazard Form" },
  { field: "date", hasSorting: true, headerName: "Date" },
  { field: "downladFile", headerName: "Download File" },
];

export const jobProgressSelectValues: DropdownOption[] = [
  { label: JobStatusLabels.ASSIGNED, value: JobStatusValues.ASSIGNED },
  { label: JobStatusLabels.CANCELED, value: JobStatusValues.CANCELED },
  { label: JobStatusLabels.COMPLETED, value: JobStatusValues.COMPLETED },
  { label: JobStatusLabels.DONE, value: JobStatusValues.DONE },
  { label: JobStatusLabels.IN_PROGRESS, value: JobStatusValues.IN_PROGRESS },
  { label: JobStatusLabels.SUSPENDED, value: JobStatusValues.SUSPENDED },
  { label: JobStatusLabels.UNASSIGNED, value: JobStatusValues.UNASSIGNED },
];

export const jobTimesheetsModalColumns: MainTableColumn[] = [
  { field: "jobId", headerName: "Job ID" },
  { field: "jobAddress", headerName: " Job Address" },
  { field: "foreman", headerName: "Foreman" },
  { field: "timesheet", headerName: "Timesheet" },
];

export const vehiclesModalColumns: MainTableColumn[] = [
  { field: "name", headerName: "vehicle name" },
  { field: "startDate", hasSorting: true, headerName: "start date" },
  { field: "endDate", hasSorting: true, headerName: "end date" },
  { field: "distance", headerName: "total distance" },
  { field: "driver.name", headerName: "driver" },
];

export const initialJobDetailsQuery: ServiceOptions = { include: ["region"] };

export const getAssignDriverModalRows = (
  employees: EmployeeModel[],
  supervisorSelectedId: string,
  onChangeRadio: (idRow: string, isChecked: boolean) => void,
): MainTableRow[] => {
  return employees.map((employee) => {
    if (employee) {
      const image = { isImage: true, value: employee.pictureUrl || "" };
      const name = { value: `${employee.firstName} ${employee.lastName}` };
      const employeeId = { value: employee.employeeId };
      const company = { value: employee.companyName };

      const idRow = employee.id ? employee.id.toString() : "";
      const innerCells = [image, name, employeeId, company];
      const isCheckedRow = supervisorSelectedId === idRow;

      return {
        idRow,
        innerCells,
        isCheckedRow,
        onChangeRadio,
      };
    }
    return {} as MainTableRow;
  });
};

export const getDeleteForemanModalRows = (
  employees: EmployeeModel[],
  handleDelete: (id: string) => void,
): MainTableRow[] =>
  employees.map((employee) => {
    if (employee) {
      const srcImage =
        employee.employeePictures && employee.employeePictures[0]
          ? (employee.employeePictures[0].picture.url as string)
          : employee.pictureUrl
          ? employee.pictureUrl
          : "";
      const image = { isImage: true, value: srcImage };
      const name = { value: `${employee.firstName} ${employee.lastName}` };
      const employeeId = { value: employee.employeeId };
      const company = { value: employee.companyName };

      const idRow = employee.id ? employee.id.toString() : "";
      const innerCells = [image, name, employeeId, company];

      return {
        idRow,
        innerCells,
        onClickDelete: handleDelete,
      };
    }
    return {} as MainTableRow;
  });

export const getAssignForemanModalRows = (
  employees: EmployeeModel[],
  employeeSelectedId: string,
  onChangeRadio: (idRow: string, isChecked: boolean) => void,
): MainTableRow[] =>
  employees.map((employee) => {
    if (employee) {
      const srcImage =
        employee.employeePictures && employee.employeePictures[0]
          ? (employee.employeePictures[0].picture.url as string)
          : "";
      const image = { isImage: true, value: srcImage };
      const name = { value: `${employee.firstName} ${employee.lastName}` };
      const employeeId = { value: employee.employeeId };
      const company = { value: employee.companyName };

      const idRow = employee.id ? employee.id.toString() : "";
      const innerCells = [image, name, employeeId, company];
      const isCheckedRow = employeeSelectedId === idRow;

      return {
        idRow,
        innerCells,
        isCheckedRow,
        onChangeRadio,
      };
    }
    return {} as MainTableRow;
  });

export const getHazardsModalRows = (hazards: HazardModel[]): MainTableRow[] =>
  hazards.map((hazard: HazardModel) => {
    const hazardFormLink: MainTableCell = {
      value: <HazardLink url={`${hazard.pdf}`} />,
    };

    const date: MainTableCell = {
      value: slashFormatDate(new Date(hazard.date)),
    };

    const downloadIcon: MainTableCell = {
      value: (
        <HazardDownloadIcon url={`data:application/pdf;base64,${hazard.pdf}`} />
      ),
    };

    const innerCells = [hazardFormLink, date, downloadIcon];

    return {
      innerCells,
    };
  });

export const getExpensesModalRows = (
  expenses: JobExpenseModel[],
  onClickDelete: (id: string) => void,
  onClickDownload: (fileId: number) => void,
  onClickPreview: (fileId: number) => void,
): MainTableRow[] =>
  expenses.map((expense: JobExpenseModel) => {
    const id: MainTableCell = {
      value: expense.id ? expense.id.toString() : DEFAULT_EMPTY,
    };
    const createDate: MainTableCell = {
      value: getTableDate(new Date(expense.date)),
    };
    const amount: MainTableCell = {
      value: `$ ${parseFloat(expense.amount).toFixed(2)}`,
    };
    const type: MainTableCell = {
      value: expense.type.charAt(0).toUpperCase() + expense.type.slice(1),
    };
    const description: MainTableCell = {
      value: getTableDescription(expense.description),
    };
    const uploadedBy: MainTableCell = {
      value: expense.uploadedBy || "No Uploader Name",
    };
    const isReBillable: MainTableCell = {
      value: expense.isReBillable ? "Yes" : "No",
    };
    const action: MainTableCell = {
      value: (
        <ExpenseActionIcons
          expenseId={(expense.id as number).toString()}
          fileId={expense.fileId}
          onClickDelete={onClickDelete}
          onClickDownload={onClickDownload}
          onClickPreview={onClickPreview}
        />
      ),
    };

    const innerCells = [
      id,
      createDate,
      amount,
      type,
      description,
      uploadedBy,
      isReBillable,
      action,
    ];

    return {
      innerCells,
    };
  });

export const getExpensesGrandTotal = (expenses: JobExpenseModel[]) =>
  expenses
    .reduce((total: number, expense: JobExpenseModel) => {
      return (total += parseFloat(expense.amount));
    }, 0)
    .toFixed(2);

export const getProgressTypeLabel = (type: PROGRESS_TYPE_VALUES) => {
  switch (type) {
    case PROGRESS_TYPE_VALUES.ESTIMATED:
      return PROGRESS_TYPE_LABELS.ESTIMATED;
    case PROGRESS_TYPE_VALUES.LABOR:
      return PROGRESS_TYPE_LABELS.LABOR;
    case PROGRESS_TYPE_VALUES.TIME:
      return PROGRESS_TYPE_LABELS.TIME;
    default:
      return "";
  }
};

export const getTableDate = (date: Date) => {
  const splittedDate = slashFormatDateWithHours(date).split(" ");

  return (
    <Box display="flex" flexDirection="column" width="max-content">
      <Typography variant="body2">{`${splittedDate[0]}`}</Typography>
      <Typography variant="body2">{`${splittedDate[1]} ${splittedDate[2]}`}</Typography>
    </Box>
  );
};

export const getTableDescription = (
  description: string,
  maxWidth?: "260px",
) => (
  <Box maxWidth={maxWidth} width="100%">
    <Typography variant="body2">{description}</Typography>
  </Box>
);

export const getTimesheetsModalRows = (
  dwoJobList: DWOJobModel[],
  jobData: JobModel,
): MainTableRow[] =>
  dwoJobList.map((dwoJob: DWOJobModel) => {
    const jobId: MainTableCell = { value: jobData.jobId || DEFAULT_EMPTY };
    const jobAddress: MainTableCell = { value: jobData.address };
    const foreman: MainTableCell = {
      value: dwoJob.crew?.foreman?.fullName || DEFAULT_EMPTY,
    };

    const viewLink: MainTableCell = {
      value: (
        <TextLink
          text="View"
          url={`${ROOT_TIMESHEETS_MANAGEMENT}/${dwoJob.dwoId}`}
        />
      ),
    };

    const innerCells = [jobId, jobAddress, foreman, viewLink];

    return { innerCells };
  });

export const getCrewModalRows = (
  crewsData: CrewModel[],
  onClickRow: (id: string) => void,
): MainTableRow[] =>
  crewsData.map((currentCrew: CrewModel) => {
    const crewId: MainTableCell = {
      value: currentCrew.id as string,
    };
    const foreman: MainTableCell = {
      value: currentCrew.foreman?.fullName || DEFAULT_EMPTY,
    };

    const createButton: MainTableCell = {
      value: (
        <Button
          color={"primary"}
          fullWidth
          onClick={() => {
            onClickRow(currentCrew.id as string);
          }}
          variant={"contained"}
        >
          Create Timesheet
        </Button>
      ),
    };

    const innerCells = [crewId, foreman, createButton];

    return { innerCells };
  });

export const getTotalInvoiceSent = (invoices: JobInvoiceModel[]) =>
  invoices
    .reduce((total: number, invoice: JobInvoiceModel) => {
      return total + parseFloat(invoice.amount);
    }, 0.0)
    .toFixed(2);

export const getVehicleModalRows = (vehicles: VehicleModel[]): MainTableRow[] =>
  vehicles.map((vehicle: VehicleModel) => {
    const driver: MainTableCell = {
      value: vehicle.driver?.name || "No Assigned Driver",
    };
    const endDate: MainTableCell = {
      value: getTableDate(
        vehicle.endDate ? new Date(vehicle.endDate) : new Date(),
      ),
    };
    const startDate: MainTableCell = {
      value: getTableDate(
        vehicle.startDate ? new Date(vehicle.startDate) : new Date(),
      ),
    };
    const totalDistance: MainTableCell = {
      value: `${vehicle.distance?.toFixed(2) || 0} miles`,
    };
    const vehicleName: MainTableCell = { value: vehicle.name as string };

    const innerCells = [vehicleName, startDate, endDate, totalDistance, driver];

    return { innerCells };
  });

export const hasOnlyOneCrew = (jobDWOs: DWOJobModel[]) => {
  if (jobDWOs.length === 0) return false;
  if (jobDWOs.length === 1) return true;

  const firstCrewId = jobDWOs[0].crewId;
  const foundOtherCrew = jobDWOs.some((jd) => jd.crewId !== firstCrewId);
  return !foundOtherCrew;
};
