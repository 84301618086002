import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import React from "react";
import {
  getProgressTypeLabel,
  PROGRESS_TYPE_VALUES,
} from "utils/jobDetailsUtils";

interface StyleProps {
  isOvertime: boolean;
  isSelected: boolean;
  progressType: PROGRESS_TYPE_VALUES;
}

interface ProgressDescriptionItemProps {
  isSelected: boolean;
  percentage: number;
  progressType: PROGRESS_TYPE_VALUES;
  onClickItem: (type: PROGRESS_TYPE_VALUES) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    colorBox: {
      backgroundColor: ({ isOvertime, progressType }: StyleProps) => {
        switch (progressType) {
          case PROGRESS_TYPE_VALUES.ESTIMATED:
            return theme.palette.primary.dark;
          case PROGRESS_TYPE_VALUES.LABOR:
            return theme.palette.success.light;
          case PROGRESS_TYPE_VALUES.TIME:
            return isOvertime
              ? theme.palette.error.main
              : theme.palette.primary.light;
          default:
            return "";
        }
      },
      height: "11px",
      marginRight: "16px",
      opacity: ({ isSelected }: StyleProps) => (isSelected ? 1 : 0.56),
      width: "19px",
    },
    container: {
      alignItems: "center",
      cursor: "pointer",
      display: "flex",
      marginBottom: "4px",
      maxWidth: "230px",
      width: "100%",
    },
    percentageLabel: {
      color: theme.palette.primary.dark,
      fontWeight: 600,
      opacity: ({ isSelected }: StyleProps) => (isSelected ? 1 : 0.56),
    },
    textLabel: {
      color: theme.palette.primary.dark,
      fontWeight: 600,
      marginRight: "8px",
      opacity: ({ isSelected }: StyleProps) => (isSelected ? 1 : 0.56),
    },
  }),
);

export function ProgressDescriptionItem({
  isSelected,
  percentage,
  progressType,
  onClickItem,
}: ProgressDescriptionItemProps) {
  const classes = useStyles({
    isOvertime: percentage > 100.0,
    isSelected,
    progressType,
  });

  const handleClickItem = (type: PROGRESS_TYPE_VALUES) => () =>
    onClickItem(type);

  return (
    <Box className={classes.container} onClick={handleClickItem(progressType)}>
      <Box className={classes.colorBox} />
      <Box
        alignItems="center"
        display="flex"
        justifyContent="space-between"
        width="100%"
      >
        <Typography className={classes.textLabel} variant="caption">
          {getProgressTypeLabel(progressType)}
        </Typography>
        <Typography className={classes.percentageLabel} variant="caption">
          {`${percentage.toFixed(0)}%`}
        </Typography>
      </Box>
    </Box>
  );
}
