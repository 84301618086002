import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
  useTheme,
} from "@material-ui/core";
import Chart, { ChartTooltipModel } from "chart.js";
import React, { useEffect, useRef } from "react";
import {
  createCustomPieChartTooltip,
  TooltipLabelOption,
} from "utils/summaryUtils";
import { renderToString } from "react-dom/server";
import { PieChartTooltip } from "./PieChartTooltip";
import { LabelDescriptionItem } from "./LabelDescriptionItem";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chartName: {
      color: theme.palette.grey[700],
      marginBottom: "8px",
    },
    content: {
      alignItems: "center",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      marginBottom: "24px",
    },
    labelsContainer: {
      display: "flex",
      "& > div:first-child": {
        marginRight: "8px",
      },
    },
  }),
);

interface SummaryJobsTypesCardProps {
  active: number;
  chartName: string;
  tooltipId: string;
  unassigned: number;
}

export function WorkforceChart({
  active,
  chartName,
  tooltipId,
  unassigned,
}: SummaryJobsTypesCardProps) {
  const chartCanvasRef = useRef({} as HTMLCanvasElement);
  const classes = useStyles();
  const theme = useTheme();

  const tooltipOptions: TooltipLabelOption[] = [
    {
      color: theme.palette.primary.main,
      label: `${active} Active`,
    },
    {
      color: theme.palette.primary.dark,
      label: `${unassigned} Unassigned`,
    },
  ];

  const activeLabel: TooltipLabelOption = {
    color: theme.palette.primary.main,
    label: "Active",
  };

  const unassignedLabel: TooltipLabelOption = {
    color: theme.palette.primary.dark,
    label: "Unassigned",
  };

  useEffect(() => {
    const noWorkforce = active === 0 && unassigned === 0;

    new Chart(chartCanvasRef.current, {
      data: {
        datasets: [
          {
            backgroundColor: [
              noWorkforce
                ? theme.palette.grey[300]
                : theme.palette.primary.main,
              theme.palette.primary.dark,
            ],
            borderWidth: 0,
            data: [noWorkforce ? 1 : active, unassigned],
          },
        ],
      },
      options: {
        animation: {
          animateRotate: true,
        },
        aspectRatio: 1,
        hover: {
          mode: undefined,
        },
        rotation: 0,
        tooltips: {
          custom: (tooltipModel: ChartTooltipModel) => {
            const customTooltipHTMLString = renderToString(
              <PieChartTooltip options={tooltipOptions} />,
            );

            createCustomPieChartTooltip(
              tooltipModel,
              tooltipId,
              customTooltipHTMLString,
              chartCanvasRef,
            );
          },
          enabled: false,
        },
      },
      type: "pie",
    });
  }, [active, theme.palette, tooltipId, tooltipOptions, unassigned]);

  return (
    <Box className={classes.content}>
      <Box margin="0 auto 8px auto" maxWidth="88px">
        <canvas ref={chartCanvasRef} />
        <div id={tooltipId} />
      </Box>

      <Typography className={classes.chartName} variant="body2">
        {chartName}
      </Typography>

      <Box className={classes.labelsContainer}>
        <LabelDescriptionItem itemData={activeLabel} />
        <LabelDescriptionItem itemData={unassignedLabel} />
      </Box>
    </Box>
  );
}
