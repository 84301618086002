import {
  Box,
  Button,
  createStyles,
  Grid,
  InputAdornment,
  makeStyles,
  Paper,
  TextField,
  Theme,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState, Fragment, useRef } from "react";
import SearchIcon from "@material-ui/icons/Search";
import { Dropdown, DropdownOption } from "components/Dropdown";
import { useSelector, useDispatch } from "react-redux";
import {
  selectRegions,
  selectLoading as selectRegionLoading,
  getAllRegions,
} from "features/region/regionSlice";
import { parseRegions } from "utils/homeUtils";
import { ServiceOptions } from "@dwo/shared/dist/services/baseService";
import { DropdownPagination } from "components/DropdownPagination";
import { CustomPagination as Pagination } from "components/Pagination";
import { rowSelectOptions } from "utils/tableUtils";
import { LoadingSpinner } from "components/LoadingSpinner";
import { RangeDatePicker } from "components/RangeDatePicker";
import { DateRange } from "materialui-daterange-picker";
import { CrewSortHeaders } from "./CrewSortHeaders";
import {
  selectLimit,
  selectCount,
  selectIsLoadingDWOList,
  selectDWOList,
  getAllDWOs,
  selectCrewQuery,
  selectCrewFilters,
  setCrewFilters,
  setCrewQuery,
} from "features/dwo/dwoSlice";
import { CrewTimesheetCard } from "./CrewTimesheetCard";
import {
  mapToCrewTimesheetManagment,
  getDwoStatusOptions,
  getTotalPages,
  initialCrewQuery,
} from "utils/timesheetsManagementUtils";
import { ALL_OPTION, DEBOUNCE_TIME_MS, DEFAULT_LIMIT } from "utils/sharedUtils";
import { cloneDeep } from "lodash";
import { enDashFormatDate } from "utils/dateUtils";
import { DropdownMultiple } from "components/DropdownMultiple";

const statusFilterOptions: DropdownOption[] = [
  ALL_OPTION,
  ...getDwoStatusOptions(),
];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    filtersContainer: {
      backgroundColor: "white",
      borderRadius: "0px",
      margin: "0 24px 24px 24px",
      padding: "24px 24px 16px",
      position: "relative",
      zIndex: 2,
    },
    paginationContainer: {
      borderRadius: "2px",
      margin: "0 24px 24px",
    },
    crewTimesheetsContainer: {
      margin: "0 24px 24px 24px",
    },
    rowSelectLabel: {
      color: theme.palette.primary.dark,
      fontWeight: "bold",
    },
    fakeLinkText: {
      color: "#2D72E0",
      fontSize: "14px",
      fontWeight: 400,
      textDecoration: "underline",
      cursor: "pointer",
    },
    clearFiltersButton: {
      backgroundColor: "#2D72E0",
      padding: "0",
      width: "100%",
    },
  }),
);

export function CrewTimesheetList() {
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState("");
  const [region, setRegion] = useState<string[]>([]);
  const [status, setStatus] = useState<string>("");
  const [query, setQuery] = useState<ServiceOptions>({});
  const [canSearch, setCanSearch] = useState(false);
  const [sortingOptions, setSortingOptions] = useState<string[][]>([]);
  const [page, setPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<string>(
    rowSelectOptions[0].value,
  );
  const [dateRange, setDateRange] = useState<DateRange | undefined>(undefined);
  const classes = useStyles();
  const isLoadingRegions = useSelector(selectRegionLoading);
  const isLoadingDwoList = useSelector(selectIsLoadingDWOList);
  const isLoading = isLoadingRegions || isLoadingDwoList;
  const dwoList = useSelector(selectDWOList);
  const currentRegions = useSelector(selectRegions);
  const currentLimit = useSelector(selectLimit) || DEFAULT_LIMIT;
  const currentCount = useSelector(selectCount);
  const storedFilters = useSelector(selectCrewFilters);
  const storedQuery = useSelector(selectCrewQuery);
  const isFirstRender = useRef(true);

  useEffect(() => {
    dispatch(getAllRegions());
  }, [dispatch]);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      setSearchValue(storedFilters.searchValue);
      setRegion(storedFilters.region);
      setStatus(storedFilters.status);
      setSortingOptions(storedFilters.sortingOptions);
      setPage(storedFilters.page);
      setRowsPerPage(storedFilters.rowsPerPage);
      setDateRange(storedFilters.dateRange);
      setQuery(storedQuery);
      return;
    }

    dispatch(getAllDWOs(query));
    dispatch(
      setCrewFilters({
        searchValue,
        region,
        status,
        sortingOptions,
        page,
        rowsPerPage,
        dateRange,
      }),
    );
    dispatch(setCrewQuery(query));
    // eslint-disable-next-line
  }, [dispatch, query]);

  useEffect(() => {
    if (!canSearch) return;
    const queryHandler = setTimeout(() => {
      setQuery((query) => ({
        ...query,
        where: {
          ...query.where,
          $and: {
            searchData: { $iLike: `%${searchValue.split(" ").join("%")}%` },
          },
        },
        offset: 0,
      }));
      setPage(1);
    }, DEBOUNCE_TIME_MS);

    return () => {
      clearTimeout(queryHandler);
    };
  }, [searchValue, canSearch]);

  const handleChangePage = (newPage: number) => {
    const updatedQuery = { ...query };
    updatedQuery.offset = (newPage - 1) * currentLimit;
    setPage(newPage);
    setQuery(updatedQuery);
  };

  const handleChageSearch = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(value);
    setCanSearch(true);
  };

  const handleSelectRegion = (selectedRegions: string[]) => {
    const updatedQuery = { ...query };
    updatedQuery.offset = 0;
    updatedQuery.region =
      selectedRegions.length > 0
        ? selectedRegions.map((selectedRegion) => parseInt(selectedRegion, 10))
        : undefined;
    setPage(1);
    setRegion(selectedRegions);
    setQuery(updatedQuery);
  };

  const handleSelectStatus = (selectedStatus?: string) => {
    if (selectedStatus) {
      const updatedQuery = { ...query };
      updatedQuery.offset = 0;

      updatedQuery.where = {
        ...query.where,
        status:
          selectedStatus === ALL_OPTION.value ? undefined : selectedStatus,
      };

      setPage(1);
      setStatus(selectedStatus);
      setQuery(updatedQuery);
    }
  };

  const allRegions: DropdownOption[] = parseRegions(currentRegions);

  const handleClickApplyRangeDate = (dateRange: DateRange) => {
    const startDate = dateRange.startDate;
    const endDate = dateRange.endDate;
    const updatedQuery = cloneDeep(query);
    updatedQuery.offset = 0;

    if (startDate && endDate) {
      setDateRange({ startDate, endDate });
      updatedQuery.where = {
        ...query.where,
        date: {
          $gte: enDashFormatDate(startDate),
          $lte: enDashFormatDate(endDate),
        },
      };
    } else {
      setDateRange(undefined);
      if (updatedQuery.where?.date) {
        delete updatedQuery.where.date;
      }
    }

    setPage(1);
    setQuery(updatedQuery);
  };

  const handleClickSorting = (sortingValues: string[][]) => {
    const updatedQuery = { ...query };
    setSortingOptions(sortingValues);
    updatedQuery.order = sortingValues.length > 0 ? sortingValues : undefined;
    setQuery(updatedQuery);
  };

  const handleSelectRowsPerPage = (selectedValue: string) => {
    const updatedQuery = { ...query };
    updatedQuery.offset = 0;
    updatedQuery.limit = Number(selectedValue);

    setPage(1);
    setRowsPerPage(selectedValue);
    setQuery(updatedQuery);
  };

  const handleClickClearFilters = () => {
    setSearchValue("");
    setRegion([]);
    setStatus("");
    setSortingOptions([]);
    setPage(1);
    setRowsPerPage(rowSelectOptions[0].value);
    setDateRange(undefined);
    setQuery(initialCrewQuery);
  };

  return (
    <Fragment>
      <Paper className={classes.filtersContainer}>
        <Grid container spacing={3}>
          <Grid item md={4} sm={3} xs={12}>
            <TextField
              placeholder="Search by Foreman or Supervisor"
              margin="normal"
              variant="outlined"
              value={searchValue}
              InputProps={{
                type: "search",
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              onChange={handleChageSearch}
            />
          </Grid>
          <Grid item md={2} sm={3} xs={6}>
            <DropdownMultiple
              id="regionDropdown"
              label="Regions"
              options={allRegions}
              selectedValue={region}
              onSelectOption={handleSelectRegion}
            />
          </Grid>
          <Grid item md={2} sm={3} xs={6}>
            <Dropdown
              id="statusDropdown"
              label="Status"
              options={statusFilterOptions}
              selectedValue={status}
              onSelectOption={handleSelectStatus}
            />
          </Grid>
          <Grid item md={3} sm={3} xs={12}>
            <RangeDatePicker
              initDateRange={dateRange}
              maxDate={new Date()}
              onClickApply={handleClickApplyRangeDate}
            />
          </Grid>
          <Grid item xl={1} sm={2} xs={12}>
            <Button
              variant="contained"
              className={classes.clearFiltersButton}
              onClick={handleClickClearFilters}
            >
              Clear Filters
            </Button>
          </Grid>
        </Grid>
        <Box marginTop="32px">
          <CrewSortHeaders
            sortingOptions={sortingOptions}
            onClickSort={handleClickSorting}
          />
        </Box>
      </Paper>
      <Box className={classes.crewTimesheetsContainer}>
        {isLoading && <LoadingSpinner />}
        {!isLoading &&
          dwoList.map((dwo) => (
            <Box marginBottom="16px" key={dwo.id}>
              <CrewTimesheetCard
                crewTimesheetData={mapToCrewTimesheetManagment(dwo)}
              />
            </Box>
          ))}
        {!isLoading && currentCount === 0 && (
          <Box display="flex" justifyContent="center">
            <Typography>No timesheets found</Typography>
          </Box>
        )}
      </Box>
      <Paper className={classes.paginationContainer}>
        <Box padding="12px 24px">
          <Grid alignItems="center" container spacing={4}>
            <Grid item xs={4} />
            <Grid item xs={4}>
              <Box display="flex" justifyContent="center">
                <Pagination
                  id="usersManagement-pagination"
                  isDisabled={isLoading || currentCount === 0}
                  page={page}
                  totalPages={getTotalPages(currentCount, currentLimit)}
                  onChangePage={handleChangePage}
                />
              </Box>
            </Grid>

            <Grid item xs={4}>
              <Box display="flex" alignItems="center" justifyContent="flex-end">
                <Box marginRight="8px">
                  <Typography
                    variant="caption"
                    className={classes.rowSelectLabel}
                  >
                    Rows per page:
                  </Typography>
                </Box>
                <DropdownPagination
                  id="rowsDropdown"
                  isDisabled={isLoading || currentCount === 0}
                  options={rowSelectOptions}
                  selectedValue={rowsPerPage}
                  onSelectOption={handleSelectRowsPerPage}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Fragment>
  );
}
