import { EmployeeModel } from "@dwo/shared/dist/models/employeeModel";
import {
  AppBar as MaterialAppBar,
  Avatar,
  Box,
  Button,
  createStyles,
  Drawer,
  Hidden,
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
  Theme,
  Toolbar,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { Menu as MenuIcon, Notifications, Tune } from "@material-ui/icons";
import { FilterBySupervisorModal } from "components/layout/FilterBySupervisorModal";
import { SideMenu } from "components/layout/SideMenu";
import { NotificationsMenu } from "components/layout/NotificationsMenu";
import { SupervisorsTooltipTitle } from "components/timesheetDetails/SupervisorsTooltipTitle";
import {
  closeSession,
  selectCurrentUser,
  changeToken,
} from "features/logIn/sessionSlice";
import {
  clearActiveSupervisorsWithStatus,
  selectActiveSupervisors,
  selectIsFilterActive,
} from "features/supervisors/supervisorsSlice";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";
import { ROOT_USER_MANAGEMENT } from "routes/Roots";
import { LOGIN } from "routes/Session";
import { MasqueradeUserModal } from "components/MasqueradeUser/MasqueradeUserModal";

interface StyleProps {
  isFilterActive: boolean;
  activeSupervisors: EmployeeModel[];
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icons: {
      color: theme.palette.primary.dark,
    },
    toolbar: {
      justifyContent: "flex-end",
    },
    supervisorsToolbar: {
      backgroundColor: theme.palette.primary.dark,
      height: "48px",
      display: "flex",
      justifyContent: "space-between",
      marginLeft: ({ isFilterActive }: StyleProps) =>
        isFilterActive ? "232px" : "",
      [theme.breakpoints.down("sm")]: {
        marginLeft: ({ isFilterActive }: StyleProps) =>
          isFilterActive ? "0px" : "0px",
      },
    },
    userName: {
      color: theme.palette.primary.dark,
      fontWeight: "bold",
    },
    appBar: {
      boxShadow: "1px 1px 8px #0000001A",
      left: "0",
      position: "fixed",
      right: "0",
      top: "0",
      zIndex: 5,
    },
    avatar: {
      height: "28px",
      width: "28px",
    },
    secondAvatar: {
      height: "28px",
      width: "28px",
      backgroundColor: "#707070",
      position: "absolute",
      left: "21px",
      border: "1px solid white",
    },
    text: { fontWeight: 600, color: "white" },
    underlinedText: {
      fontWeight: 600,
      color: "white",
      textDecoration: "underline",
      marginLeft: ({ activeSupervisors }: StyleProps) =>
        activeSupervisors.length > 1 ? "25px" : "5px",
    },
    tooltip: {
      backgroundColor: theme.palette.grey[100],
      borderRadius: "2px",
      padding: "16px",
    },
    button: {
      backgroundColor: "#002868",
      height: "32px",
    },
    drawerPaper: {
      height: "auto",
    },
  }),
);

export const AppBar = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const isFilterActive = useSelector(selectIsFilterActive);
  const currentUser = useSelector(selectCurrentUser);
  const activeSupervisors = useSelector(selectActiveSupervisors);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isFilterByModalOpen, setIsFilterByModalOpen] = useState(false);
  const classes = useStyles({ isFilterActive, activeSupervisors });
  const isMenuOpen = Boolean(anchorEl);
  const [isNotificationsMenuOpen, setIsNotificationsMenuOpen] = useState(false);
  const [
    isMasqueradeUserModalOpen,
    setMasqueradeUserModalOpen,
  ] = useState<boolean>(false);

  const handleClickLogout = () => {
    dispatch(closeSession());
    return <Redirect to={LOGIN} />;
  };

  const handleClickNotifications = () =>
    setIsNotificationsMenuOpen(!isNotificationsMenuOpen);

  const handleClickProfileAvatar = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleClickProfile = () => {
    history.push(`${ROOT_USER_MANAGEMENT}/${currentUser?.employeeId}`);
    handleCloseMenu();
  };

  const handleClickCloseDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const handleToggleFilterByModal = () =>
    setIsFilterByModalOpen(!isFilterByModalOpen);

  const handleClickExit = () => dispatch(clearActiveSupervisorsWithStatus());

  const handleClickSupervisor = (supervisorId: number) => {
    history.push(`${ROOT_USER_MANAGEMENT}/${supervisorId}`);
  };

  const handleClickOpenMasqueradeUser = () => setMasqueradeUserModalOpen(true);

  const handleClickCloseMasqueradeUser = () =>
    setMasqueradeUserModalOpen(false);

  const handleClickChangeUser = (supervisorSelectedId: string) => {
    dispatch(changeToken(supervisorSelectedId));
    setTimeout(() => {
      window.location.reload();
    }, 500);
  };

  const menuId = "profile-icon-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleCloseMenu}
    >
      <MenuItem onClick={handleClickProfile}>Profile</MenuItem>
      <MenuItem onClick={handleClickLogout}>Logout</MenuItem>
      {(() => {
        if (currentUser?.employee.role === "admin") {
          return (
            <MenuItem onClick={handleClickOpenMasqueradeUser}>
              Acting as other user
            </MenuItem>
          );
        }
      })()}
    </Menu>
  );

  const userImage = currentUser?.employee?.pictureUrl || "";
  const userName =
    currentUser && currentUser.employee
      ? `${currentUser.employee.firstName} ${currentUser.employee.lastName}`
      : "User";

  return (
    <React.Fragment>
      <MasqueradeUserModal
        isOpen={isMasqueradeUserModalOpen}
        onClickClose={handleClickCloseMasqueradeUser}
        onClickChangeUser={handleClickChangeUser}
      />

      <MaterialAppBar
        className={classes.appBar}
        color="inherit"
        position="static"
      >
        <Toolbar className={classes.toolbar}>
          <Hidden mdUp>
            <Box flexGrow={1} marginLeft="-12px">
              <IconButton
                aria-label="Menu"
                className={classes.icons}
                onClick={handleClickCloseDrawer}
              >
                <MenuIcon />
              </IconButton>
            </Box>
            <Drawer
              variant="temporary"
              anchor="left"
              open={isDrawerOpen}
              onClose={handleClickCloseDrawer}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
              classes={{ paper: classes.drawerPaper }}
            >
              <SideMenu />
            </Drawer>
          </Hidden>
          {currentUser?.employee.role !== "supervisor" && (
            <IconButton
              aria-label="Tune"
              className={classes.icons}
              onClick={handleToggleFilterByModal}
            >
              <Tune />
            </IconButton>
          )}
          <IconButton
            aria-label="Notifications"
            className={classes.icons}
            onClick={handleClickNotifications}
          >
            <Notifications />
          </IconButton>
          <IconButton
            aria-controls={menuId}
            aria-haspopup="true"
            aria-label="Profile"
            className={classes.icons}
            onClick={handleClickProfileAvatar}
          >
            <Avatar src={userImage} />
          </IconButton>
          <Typography className={classes.userName}>{userName}</Typography>
        </Toolbar>
        {isFilterActive && (
          <Toolbar className={classes.supervisorsToolbar}>
            <Box display="flex" alignItems="center">
              <Typography variant="body2" className={classes.text}>
                Content is now filtered by
              </Typography>
              <Box marginLeft="10px" position="relative" display="flex">
                <Avatar
                  className={classes.avatar}
                  src={
                    activeSupervisors[0].employeePictures
                      ? activeSupervisors[0].employeePictures[0].picture.url
                      : ""
                  }
                />
                {activeSupervisors.length > 1 && (
                  <Avatar className={classes.secondAvatar}>
                    <Typography variant="body2" className={classes.text}>
                      +{activeSupervisors.length - 1}
                    </Typography>
                  </Avatar>
                )}
              </Box>
              <Tooltip
                classes={{ tooltip: classes.tooltip }}
                interactive
                title={
                  <SupervisorsTooltipTitle
                    supervisors={activeSupervisors}
                    dashedList
                    onClickSupervisor={handleClickSupervisor}
                  />
                }
              >
                <Typography variant="body2" className={classes.underlinedText}>
                  {activeSupervisors.length} supervisors
                </Typography>
              </Tooltip>
            </Box>
            <Box display="flex" width="150px">
              <Button
                className={classes.button}
                fullWidth
                variant="outlined"
                onClick={handleClickExit}
              >
                <Typography variant="body2" className={classes.text}>
                  Exit "Filter by"
                </Typography>
              </Button>
            </Box>
          </Toolbar>
        )}
      </MaterialAppBar>
      {renderMenu}
      {currentUser?.employee.role !== "supervisor" && (
        <FilterBySupervisorModal
          isOpen={isFilterByModalOpen}
          onToggle={handleToggleFilterByModal}
        />
      )}
      <NotificationsMenu
        isOpen={isNotificationsMenuOpen}
        setIsNotificationsMenuOpen={setIsNotificationsMenuOpen}
      />
    </React.Fragment>
  );
};
