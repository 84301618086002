import {
  Box,
  Button,
  createStyles,
  IconButton,
  makeStyles,
  Modal,
  Theme,
  Typography,
} from "@material-ui/core";
import { Cancel } from "@material-ui/icons";
import React, { ReactNode } from "react";

interface GlobalModalProps {
  actionBtnsMaxWidth?: string;
  children?: ReactNode;
  customFooter?: JSX.Element;
  modalMaxWidth?: string;
  open: boolean;
  primaryLabel?: string;
  Filters?: ReactNode;
  showActionButtons?: boolean;
  secondaryLabel?: string;
  Tabs?: ReactNode;
  title: string;
  isContinueDisabled?: boolean;
  onClickContinue?: () => void;
  onClickCancel?: () => void;
  onClickClose: () => void;
}

interface StyleProps {
  modalMaxWidth: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    actionButton: {
      padding: "8px 4px",
    },
    header: {
      fontSize: "32px",
      color: theme.palette.primary.main,
      fontWeight: 600,
      padding: "24px",
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
    },
    container: {
      backgroundColor: "white",
      outline: "0",
      maxWidth: ({ modalMaxWidth }: StyleProps) => modalMaxWidth,
      width: "90%",
    },
    childrenContainer: {
      overflow: "auto",
      backgroundColor: "white",
      outline: "0",
      maxWidth: ({ modalMaxWidth }: StyleProps) => modalMaxWidth,
      maxHeight: "559px",
      width: "90%",
      [theme.breakpoints.down("xs")]: {
        maxHeight: "540px",
      },
    },
    closeIcon: {
      color: theme.palette.primary.main,
    },
    iconButton: {
      alignSelf: "flex-start",
      padding: "8px 8px",
      margin: "8px 16px",
    },
  }),
);

export function GlobalModal({
  actionBtnsMaxWidth = "150px",
  children,
  customFooter,
  open,
  primaryLabel = "Save",
  Filters,
  showActionButtons = true,
  secondaryLabel = "Cancel",
  Tabs,
  title = "Title",
  isContinueDisabled,
  modalMaxWidth = "570px",
  onClickContinue,
  onClickClose,
  onClickCancel = onClickClose,
}: GlobalModalProps) {
  const classes = useStyles({ modalMaxWidth });
  return (
    <Modal
      className={classes.modal}
      open={open}
      onClose={onClickClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <React.Fragment>
        <Box className={classes.container}>
          <Box display="flex" justifyContent="space-between">
            <Typography className={classes.header} component="h1">
              {title}
            </Typography>
            <IconButton className={classes.iconButton} onClick={onClickClose}>
              <Cancel className={classes.closeIcon} fontSize="large" />
            </IconButton>
          </Box>
        </Box>
        {/*if you pass tabsComponent remember to add ModalTabsContent as first child of the modal*/}
        <Box className={classes.container}>{Tabs}</Box>
        <Box className={classes.container}>{Filters}</Box>
        <Box className={classes.childrenContainer}>{children}</Box>
        {showActionButtons && (
          <Box className={classes.container}>
            <Box display="flex" justifyContent="flex-end" padding="24px">
              <Box width={actionBtnsMaxWidth}>
                <Button
                  className={classes.actionButton}
                  fullWidth
                  variant="outlined"
                  onClick={onClickCancel}
                >
                  {secondaryLabel}
                </Button>
              </Box>
              <Box width={actionBtnsMaxWidth} marginLeft="24px">
                <Button
                  className={classes.actionButton}
                  fullWidth
                  variant="contained"
                  disabled={isContinueDisabled}
                  onClick={onClickContinue}
                >
                  {primaryLabel}
                </Button>
              </Box>
            </Box>
          </Box>
        )}
        {customFooter && (
          <Box className={classes.container}>{customFooter}</Box>
        )}
      </React.Fragment>
    </Modal>
  );
}
