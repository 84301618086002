import React from "react";
import { SvgIcon } from "@material-ui/core";

interface MicrosoftIconProps {
  color?: string;
  fontSize?: number;
}

export const MicrosoftIcon = ({
  color = "black",
  fontSize,
}: MicrosoftIconProps) => {
  return (
    <SvgIcon htmlColor={color} style={{ fontSize: fontSize }}>
      <path
        d="M272,10.9h10.9V.727A.727.727,0,0,0,282.172,0H272Z"
        transform="translate(-258.898)"
      />
      <path d="M10.9,10.9V0H.727A.727.727,0,0,0,0,.727V10.9Z" />
      <path
        d="M10.9,272H0v10.172a.727.727,0,0,0,.727.727H10.9Z"
        transform="translate(0 -258.898)"
      />
      <path
        d="M272,272v10.9h10.172a.727.727,0,0,0,.727-.727V272Z"
        transform="translate(-258.898 -258.898)"
      />
    </SvgIcon>
  );
};
