import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectShow,
  selectMessage,
  selectTitle,
  setResponse,
  hide,
  selectActionButtonText,
  selectHideCancelButton,
} from "./errorSlice";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Box,
  makeStyles,
  createStyles,
} from "@material-ui/core";

const useStyles = makeStyles(() =>
  createStyles({
    button: {
      maxWidth: "150px",
      width: "100%",
    },
  }),
);

export function Error() {
  const show = useSelector(selectShow);
  const message = useSelector(selectMessage);
  const title = useSelector(selectTitle);
  const buttonText = useSelector(selectActionButtonText);
  const showCancelButton = !useSelector(selectHideCancelButton);
  const classes = useStyles();

  const dispatch = useDispatch();

  const handleClickCancel = () => {
    dispatch(setResponse(false));
    dispatch(hide());
  };

  const handleClickRetry = () => {
    dispatch(setResponse(true));
    dispatch(hide());
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={show}
      onClose={handleClickCancel}
      aria-labelledby="error-dialog-title"
      aria-describedby="error-dialog-description"
    >
      <DialogTitle id="error-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="error-dialog-description">
          {message}
        </DialogContentText>
      </DialogContent>
      <Box padding="8px 16px">
        <DialogActions>
          {showCancelButton && (
            <Box
              display="flex"
              justifyContent="flex-end"
              paddingRight="24px"
              width="100%"
            >
              <Button
                className={classes.button}
                variant="outlined"
                onClick={handleClickCancel}
              >
                Cancel
              </Button>
            </Box>
          )}
          <Button
            className={classes.button}
            variant="contained"
            onClick={handleClickRetry}
          >
            {buttonText}
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
}
