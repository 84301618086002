import { Box, Typography } from "@material-ui/core";
import React from "react";

interface HazardLinkProps {
  url: string;
}

export function HazardLink({ url }: HazardLinkProps) {
  const getPDFBlob = () => {
    const binary = atob(url.replace(/\s/g, ""));
    const len = binary.length;
    const buffer = new ArrayBuffer(len);
    const view = new Uint8Array(buffer);
    for (let i = 0; i < len; i++) {
      view[i] = binary.charCodeAt(i);
    }
    const blob = new Blob([view], { type: "application/pdf" });
    return URL.createObjectURL(blob);
  };

  return (
    <Box width="100%" alignItems="center" display="flex">
      <a href={getPDFBlob()} target="_blank" rel="noopener noreferrer">
        <Typography color="primary" variant="body1">
          View Hazard
        </Typography>
      </a>
    </Box>
  );
}
