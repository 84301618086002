import { JobsSummaryOverviewDataElement } from "@dwo/shared/dist/models/jobSummaryModel";
import { Box, useTheme } from "@material-ui/core";
import Chart, { ChartTooltipModel } from "chart.js";
import {
  selectSummaryCategoriesAndColors,
  setSummarySelectedTab,
} from "features/summary/summarySlice";
import React, { useEffect, useRef } from "react";
import { renderToString } from "react-dom/server";
import { useDispatch, useSelector } from "react-redux";
import {
  getCategoriesChartData,
  getTooltipDate,
  OverviewTabs,
  TooltipLabelOption,
} from "utils/summaryUtils";
import { BarChartTooltip } from "./BarChartTooltip";

interface OverviewSingleDayChartProps {
  data?: JobsSummaryOverviewDataElement;
}

const tooltipId = "overviewSingleDay-tooltip";

export function OverviewSingleDayChart({ data }: OverviewSingleDayChartProps) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const chartCanvasRef = useRef({} as HTMLCanvasElement);
  const categoriesAndColors = useSelector(selectSummaryCategoriesAndColors);

  useEffect(() => {
    dispatch(setSummarySelectedTab(OverviewTabs.LeftTab));
  }, [dispatch]);

  useEffect(() => {
    const { totalHours, categoriesData } = getCategoriesChartData(
      data?.categories || [],
    );
    const [
      ohElectric,
      ugGas,
      ugElectric,
      castIron,
      overhead,
      general,
    ] = categoriesData;
    const barsColors: string[] = [];
    const categoriesLabels: string[] = [];

    categoriesAndColors.forEach((labelOption: TooltipLabelOption) => {
      barsColors.push(labelOption.color);
      categoriesLabels.push(labelOption.label);
    });

    const barChart = new Chart(chartCanvasRef.current, {
      data: {
        datasets: [
          {
            backgroundColor: barsColors,
            barThickness: 24,
            data: [
              ohElectric.hours,
              ugGas.hours,
              ugElectric.hours,
              castIron.hours,
              overhead.hours,
              general.hours,
            ],
            hoverBackgroundColor: barsColors,
            label: "Category Hours",
          },
          {
            backgroundColor: theme.palette.grey[300],
            barThickness: 24,
            data:
              totalHours > 0
                ? [
                    totalHours - ohElectric.hours,
                    totalHours - ugGas.hours,
                    totalHours - ugElectric.hours,
                    totalHours - castIron.hours,
                    totalHours - overhead.hours,
                    totalHours - general.hours,
                  ]
                : [1, 1, 1, 1, 1, 1],
            hoverBackgroundColor: theme.palette.grey[300],
            label: "Total Hours",
          },
        ],
        labels: categoriesLabels,
      },
      options: {
        legend: {
          display: false,
        },
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              stacked: true,
              gridLines: {
                display: false,
              },
              scaleLabel: {
                display: true,
                labelString: "Hours / Costs",
                fontColor: theme.palette.primary.dark,
                fontFamily: "Noto Sans",
                fontSize: 12,
                fontStyle: "bold",
              },
              ticks: {
                display: false,
              },
            },
          ],
          yAxes: [
            {
              stacked: true,
              gridLines: {
                display: false,
              },
              ticks: {
                beginAtZero: true,
                display: false,
              },
            },
          ],
        },
        tooltips: {
          custom: (tooltipModel: ChartTooltipModel) => {
            let tooltipEl = document.getElementById(tooltipId);

            if (!tooltipEl) {
              tooltipEl = document.createElement("div");
              tooltipEl.id = tooltipId;
              document.body.appendChild(tooltipEl);
            }

            const category = tooltipModel.title
              ? tooltipModel.title[0]
              : undefined;

            if (tooltipModel.opacity === 0 || !category) {
              tooltipEl.style.opacity = "0";
              return;
            }

            const categoryData = categoriesData.find(
              (option) => option.label === category,
            );

            const categoryLabel = categoriesAndColors.find(
              (labelOption: TooltipLabelOption) =>
                labelOption.label === category,
            );

            if (!categoryData || !categoryLabel) {
              tooltipEl.style.opacity = "0";
              return;
            }

            const customTooltipHTMLString = renderToString(
              <BarChartTooltip
                cost={categoryData.cost.toFixed(2)}
                date={getTooltipDate(data?.date)}
                hours={categoryData.hours.toFixed(2)}
                tooltipOption={{
                  color: categoryLabel.color,
                  label: categoryLabel.label,
                }}
              />,
            );

            const position = chartCanvasRef.current.getBoundingClientRect();

            tooltipEl.innerHTML = customTooltipHTMLString;
            tooltipEl.style.opacity = "1";
            tooltipEl.style.position = "absolute";
            tooltipEl.style.left = `${
              position.left + window.pageXOffset + tooltipModel.caretX
            }px`;
            tooltipEl.style.top = `${
              position.top + window.pageYOffset + tooltipModel.caretY
            }px`;
            tooltipEl.style.pointerEvents = "none";
          },
          enabled: false,
        },
      },
      type: "bar",
    });

    return () => {
      barChart.destroy();
    };
  }, [categoriesAndColors, data, theme.palette]);

  return (
    <Box height="200px" maxWidth="360px" width="100%">
      <canvas ref={chartCanvasRef} />
      <div id={tooltipId} />
    </Box>
  );
}
