import { GlobalModal } from "components/globalModal/GlobalModal";
import { TableCustom } from "components/table/TableCustom";
import {
  getTimeOffRows,
  timeOffColumns,
} from "components/timesheetDetails/timeOffModalUtils";
import { selectJobsOfDWOs, updateDWOForTimeOff } from "features/dwo/dwoSlice";
import {
  selectIsTimeOffChecked,
  setIsTimeOffChecked,
} from "features/editWorkShiftTableSlice/workShiftTableValuesSlice";
import { noop } from "lodash/fp";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

export function TimeOffModal() {
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const dwoId = parseInt(id, 10);
  const jobsFromDWO = useSelector(selectJobsOfDWOs);
  const isTimeOffChecked = useSelector(selectIsTimeOffChecked);
  const [isHeaderChecked, setIsHeaderChecked] = useState(false);
  const [selectedDWOJobIds, setSelectedDWOJobIds] = useState<string[]>([]);
  const [clearStatus, setClearStatus] = useState("");

  useEffect(() => {
    if (!isTimeOffChecked) {
      setIsHeaderChecked(false);
      setSelectedDWOJobIds([]);
      setClearStatus("");
    }
  }, [isTimeOffChecked]);

  useEffect(() => {
    if (isHeaderChecked) {
      setClearStatus("clear");
    }
  }, [isHeaderChecked]);

  const handleToggleModal = () =>
    dispatch(setIsTimeOffChecked(!isTimeOffChecked));

  const handleClickCheckboxHeader = (isChecked: boolean) => {
    setIsHeaderChecked(isChecked);
  };

  const handleClickCheckboxRow = (idRow: string, isChecked: boolean) => {
    if (isChecked && !selectedDWOJobIds.includes(idRow)) {
      setSelectedDWOJobIds((prevState) => [...prevState, idRow]);
    }

    if (!isChecked && selectedDWOJobIds.includes(idRow)) {
      setSelectedDWOJobIds((prevState) =>
        prevState.filter((employeeId: string) => employeeId !== idRow),
      );
    }
  };

  const handleClickContinue = () => {
    if (selectedDWOJobIds.length > 0) {
      dispatch(
        updateDWOForTimeOff(dwoId, {
          dwoJobIds: selectedDWOJobIds.map((dwoJobId) => Number(dwoJobId)),
        }),
      );
      dispatch(setIsTimeOffChecked(false));
    }
  };

  return (
    <GlobalModal
      open={isTimeOffChecked}
      title="Time Off"
      onClickContinue={handleClickContinue}
      onClickClose={handleToggleModal}
    >
      <TableCustom
        columns={timeOffColumns(isHeaderChecked, handleClickCheckboxHeader)}
        noRowsMessage="There are no available jobs for Time Off."
        rows={getTimeOffRows(
          jobsFromDWO,
          selectedDWOJobIds,
          clearStatus,
          setClearStatus,
          isHeaderChecked,
          handleClickCheckboxRow,
        )}
        onClickSort={noop}
      />
    </GlobalModal>
  );
}
