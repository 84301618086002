import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState, AppThunk } from "app/store";
import {
  ChangeCredentials,
  Credentials,
} from "@dwo/shared/dist/models/credentialsModel";
import { masqueradeService } from "@dwo/shared/dist/services/masqueradeService";
import { UserModel } from "@dwo/shared/dist/models/userModel";
import { authService } from "@dwo/shared/dist/services/authService";
import { userService } from "@dwo/shared/dist/services/userService";
import { getCookie } from "utils/cookiesUtils";
import { employeeService } from "@dwo/shared/dist/services/employeeService";
import { clearActiveSupervisors } from "features/supervisors/supervisorsSlice";
import { wait } from "utils/utils";

const TOKEN_REFRESH_CHECK_INTERVAL = 60000;
const TOKEN_REFRESH_THRESHOLD = 3600000; // 1 hour
const LOCAL_STORAGE_CREDENTIALS_KEY = "credentials";

/*
  Credentials logic:
    - On login, the backend puts credentials on a cookie (dwo)
    - The app gets those credentials and saves them to localStorage
    - On consecutive app loads, the source of truth is localStorage
    - When a token gets refreshed, the credentials are updated on localStorage
    - On log out, both localStorage and cookies should be cleared
    - On production, we cannot clear cookies using JS, we need to redirect to an 
      api endpoint so the back instructs the browser to clear it with a header in the response
*/
const getSavedCredentials = () => {
  let credentials: Credentials | undefined = undefined;
  try {
    // Try to get from localstorage
    const localCredentials = localStorage.getItem(
      LOCAL_STORAGE_CREDENTIALS_KEY,
    );
    if (!localCredentials) {
      // Try to get from cookie
      const cookieCredentials = getCookie("dwo");
      if (cookieCredentials) {
        credentials = JSON.parse(cookieCredentials.substring(2));
      }
    } else {
      credentials = JSON.parse(localCredentials);
    }
  } catch (err) {
    console.error("Error getting credentials:", err);
  }

  return credentials;
};

const setSavedCredentials = (credentials: Credentials) => {
  localStorage.setItem(
    LOCAL_STORAGE_CREDENTIALS_KEY,
    JSON.stringify(credentials),
  );
};

const setSavedChangedCredentials = (changeCredentials: ChangeCredentials) => {
  localStorage.setItem(
    LOCAL_STORAGE_CREDENTIALS_KEY,
    JSON.stringify(changeCredentials),
  );
};

const clearSavedCredentials = () => {
  localStorage.removeItem(LOCAL_STORAGE_CREDENTIALS_KEY);
};

export enum SessionStatus {
  initial = "initial",
  fulfilled = "fulfilled",
  logOut = "logOut",
}

interface SessionState {
  changeCredentials?: ChangeCredentials;
  credentials?: Credentials;
  currentUser?: UserModel;
  isAuthenticated: boolean;
  isSessionConfirmed: boolean;
  status: SessionStatus;
  isRefreshing: boolean;
}

const initialState: SessionState = {
  credentials: undefined,
  currentUser: undefined,
  changeCredentials: undefined,
  isSessionConfirmed: false,
  isAuthenticated: false,
  status: SessionStatus.initial,
  isRefreshing: false,
};

export const sessionSlice = createSlice({
  name: "session",
  initialState,
  reducers: {
    logOut: (state) => {
      clearSavedCredentials();
      state.isAuthenticated = false;
      state.credentials = undefined;
      state.status = SessionStatus.logOut;
    },
    confirmSession: (state) => {
      state.isSessionConfirmed = true;
    },
    updateCredentials: (state, action: PayloadAction<Credentials>) => {
      setSavedCredentials(action.payload);
      state.credentials = action.payload;
    },
    changeCredentials: (state, action: PayloadAction<ChangeCredentials>) => {
      setSavedChangedCredentials(action.payload);
      state.changeCredentials = action.payload;
    },
    updateCurrentUser: (state, action: PayloadAction<UserModel>) => {
      state.currentUser = action.payload;
      state.isAuthenticated = true;
    },
    updateIsAuthenticated: (state, action: PayloadAction<boolean>) => {
      state.isAuthenticated = action.payload;
    },
    updateSessionStatus: (state, action: PayloadAction<SessionStatus>) => {
      state.status = action.payload;
    },
    setIsRefreshing: (state, action: PayloadAction<boolean>) => {
      state.isRefreshing = action.payload;
    },
  },
});

export const {
  changeCredentials,
  confirmSession,
  logOut,
  updateCredentials,
  updateCurrentUser,
  updateIsAuthenticated,
  updateSessionStatus,
  setIsRefreshing,
} = sessionSlice.actions;

export const closeSession = (): AppThunk => async (dispatch) => {
  dispatch(logOut());
  dispatch(clearActiveSupervisors());
  const authCookie = getCookie("dwo");
  if (authCookie) {
    const BASE_URL = window.appEnv?.baseUrl;
    // Avoid infinite loops on localhost while developing (this won't do anything in prod)
    document.cookie = "dwo= ; expires = Thu, 01 Jan 1970 00:00:00 GMT; path=/";
    // Redirect to logout url on api, so the cookie is cleared (api will redirect back to login)
    window.location.replace(`${BASE_URL}/auth/logout`);
  }
};

const populateUserSession = (): AppThunk => async (dispatch, getState) => {
  const credentials = selectSessionCredentials(getState());
  if (!credentials) {
    throw new Error("No Credentials found");
  }
  try {
    const { data } = await userService.getById(credentials.user.id, {
      include: ["employee"],
    });
    if (data.employee.role !== ("foreman" || "worker")) {
      const { data: profileData } = await employeeService.getProfiles([
        credentials.user.id,
      ]);
      data.employee.pictureUrl = profileData[0]
        ? profileData[0].pictureUrl
        : undefined;
      dispatch(updateCurrentUser(data));
      dispatch(updateIsAuthenticated(true));
      dispatch(updateSessionStatus(SessionStatus.fulfilled));
    } else {
      dispatch(closeSession());
    }
  } catch (err) {
    dispatch(closeSession());
  }
};

const waitRefresh = async (getState: any) => {
  let isRefreshing = selectIsRefreshing(getState());
  while (isRefreshing) {
    await wait(100);
    isRefreshing = selectIsRefreshing(getState());
  }
};

export const refreshToken = (waitIfRefreshing = false): AppThunk => async (
  dispatch,
  getState,
) => {
  // Try to refresh the token, logout otherwise
  const isRefreshing = selectIsRefreshing(getState());
  if (isRefreshing) {
    if (waitIfRefreshing) {
      await waitRefresh(getState);
    }
    return;
  }
  const credentials = selectSessionCredentials(getState());
  if (!credentials) {
    throw new Error("No Credentials found");
  }
  // Do the refresh and save new data
  try {
    dispatch(setIsRefreshing(true));
    const { data: newCredentials } = await authService.refresh(credentials);
    dispatch(updateCredentials(newCredentials));
    await dispatch(populateUserSession());
  } catch (err) {
    dispatch(closeSession());
  } finally {
    dispatch(setIsRefreshing(false));
  }
};

export const changeToken = (employeeId: string): AppThunk => async (
  dispatch,
  getState,
) => {
  // Try to refresh the token, logout otherwise
  const options = { where: { employeeId: Number(employeeId) } };
  const credentials = selectSessionCredentials(getState());
  if (!credentials) {
    throw new Error("No Credentials found");
  }
  // Do the refresh and save new data
  try {
    const { data: newCredentials } = await masqueradeService.changeCredentials(
      options,
    );
    const newCredentialFormat: Credentials = {
      expires: newCredentials.expires,
      token: newCredentials.token,
      refresh_token: { ...newCredentials.refresh_token },
      user: { ...newCredentials.user },
    };
    setSavedCredentials(newCredentialFormat); //(credentialFormat);
  } catch (err) {
    dispatch(closeSession());
  }
};

const checkTokenAndRefresh = (): AppThunk => async (dispatch, getState) => {
  const credentials = selectSessionCredentials(getState());
  if (!credentials) {
    return;
  }
  const tokenExpires = credentials.expires * 1000; // expires is in seconds, we are evaluating in ms
  const now = new Date().getTime();
  if (tokenExpires < now - TOKEN_REFRESH_THRESHOLD) {
    // We are within TOKEN_REFRESH_THRESHOLD until the token expires, or it has expired
    // try to refresh the token
    try {
      await dispatch(refreshToken());
    } catch (err) {
      console.warn("Couldn't refresh token:", err);
    }
  }
};

const registerTokenRefresher = (): AppThunk => async (dispatch) => {
  setInterval(
    () => dispatch(checkTokenAndRefresh()),
    TOKEN_REFRESH_CHECK_INTERVAL,
  );
};

export const initSession = (): AppThunk => async (dispatch) => {
  // Start token refresher
  dispatch(registerTokenRefresher());

  const credentials = getSavedCredentials();
  if (!credentials) {
    dispatch(confirmSession());
    dispatch(updateSessionStatus(SessionStatus.logOut));
    return;
  }

  try {
    dispatch(updateCredentials(credentials));
    await dispatch(populateUserSession());
  } catch (error) {
    // Try to refresh token, then logout if failed
    await dispatch(refreshToken());
  } finally {
    dispatch(confirmSession());
  }
};

export const selectSessionCredentials = (state: RootState) =>
  state.session.credentials;
export const selectIsSessionConfirmed = (state: RootState) =>
  state.session.isSessionConfirmed;
export const selectLogInStatus = (state: RootState) => state.session.status;
export const selectCurrentUser = (state: RootState) =>
  state.session.currentUser;
export const selectIsAuth = (state: RootState) => state.session.isAuthenticated;
export const selectIsRefreshing = (state: RootState) =>
  state.session.isRefreshing;

export const sessionReducer = sessionSlice.reducer;
