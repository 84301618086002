import { Button, createStyles, makeStyles } from "@material-ui/core";
import { error } from "features/error/errorSlice";
import { noop } from "lodash/fp";
import React, { Fragment, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { FileData } from "utils/sharedUtils";

interface UploadButtonProps {
  accept: string;
  id: string;
  isDisabled?: boolean;
  maxSize?: number;
  multiple?: boolean;
  name: string;
  selectedFiles?: FileData[];
  text?: string;
  onUploadFiles: (newFile: FileData) => void;
}

const useStyles = makeStyles(() =>
  createStyles({
    button: {
      width: "100%",
    },
    input: {
      display: "none",
    },
  }),
);

export function UploadButton({
  accept,
  id,
  isDisabled = false,
  maxSize = 1000000,
  multiple = false,
  name,
  selectedFiles,
  text = "Upload File",
  onUploadFiles,
}: UploadButtonProps) {
  const dispatch = useDispatch();
  const inputRef = useRef<HTMLInputElement>({} as HTMLInputElement);
  const classes = useStyles();

  useEffect(() => {
    if (selectedFiles?.length === 0) {
      inputRef.current.value = "";
    }
  }, [selectedFiles]);

  const handleClickButton = () => {
    inputRef.current.click();
  };

  const handleSelectFiles = ({
    target: { files },
  }: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = files ? Array.from(files) : [];

    if (fileList.length > 0) {
      fileList.forEach((file: File) => {
        uploadFileData(file);
      });
    }
  };

  const uploadFileData = (fileInput: File) => {
    const reader = new FileReader();
    reader.readAsDataURL(fileInput);

    reader.addEventListener("load", async () => {
      const fileExtension = fileInput.type.split("/")[1];
      const isValidFileType = accept.match(fileExtension);
      const isValidSize = fileInput.size <= maxSize;

      if (isValidFileType && isValidSize) {
        onUploadFiles({
          file: fileInput,
          fileName: fileInput.name,
          hasChanged: true,
          hasError: false,
          src: (await fetch(reader.result as string).then((res) =>
            res.blob(),
          )) as string,
        });
        return;
      }

      if (!isValidSize) {
        dispatch(
          error(
            {
              title:
                "One or more images are larger than 1MB, please select smaller images and try again",
              actionButtonText: "Ok",
              hideCancelButton: true,
            },
            noop,
          ),
        );
      }

      onUploadFiles({
        file: undefined,
        fileName: "",
        hasChanged: true,
        hasError: true,
        src: "",
      });
    });
  };

  return (
    <Fragment>
      <input
        accept={accept}
        className={classes.input}
        multiple={multiple}
        name={name}
        ref={inputRef}
        type="file"
        onChange={handleSelectFiles}
      />
      <Button
        className={classes.button}
        disabled={isDisabled}
        id={id}
        variant="contained"
        onClick={handleClickButton}
      >
        {text}
      </Button>
    </Fragment>
  );
}
