import { RegionModel } from "@dwo/shared/dist/models/regionModel";
export const parseRegions = (fetchedRegions: RegionModel[]) => {
  return fetchedRegions.map((region: RegionModel) => {
    return {
      label: region.name,
      value: (region.id as number).toString(),
    };
  });
};

export const jobsDataFirst = [
  { jobStatus: "Assigned", jobValue: "4’387,112", hasNote: false, note: "" },
  { jobStatus: "Suspended", jobValue: "2,603", hasNote: false, note: "" },
  { jobStatus: "Paused", jobValue: "2,603", hasNote: false, note: "" },
  { jobStatus: "Unassigned", jobValue: "2,603", hasNote: false, note: "" },
];
export const jobsDataSecond = [
  {
    jobStatus: "In Progress",
    jobValue: "4’387,112",
    hasNote: true,
    note: "5 Overtime jobs",
  },
  { jobStatus: "Billed", jobValue: "2,603" },
  {
    jobStatus: "Completed",
    jobValue: "2,603",
    hasNote: true,
    note: "5 Overtime jobs",
  },
];

export const getFirstCanvasObj = (
  main: string,
  dark: string,
  light: string,
) => ({
  data: {
    labels: [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    datasets: [
      {
        label: "Electric",
        data: [67, 21, 32, 34, 65, 76, 89, 98, 42, 8, 32, 15],
        backgroundColor: dark,
      },
      {
        label: "Gas",
        data: [20, 65, 55, 22, 12, 5, 2, 5, 31, 47, 39, 20],
        backgroundColor: main,
      },
      {
        label: "Construction",
        data: [11, 32, 19, 28, 3, 14, 8, 12, 31, 10, 16, 10],
        backgroundColor: light,
      },
    ],
  },
  options: {
    legend: {
      labels: {
        fontFamily: "Noto Sans",
      },
    },
    scales: {
      xAxes: [
        {
          stacked: true,
          gridLines: {
            display: false,
          },
        },
      ],
      yAxes: [
        {
          stacked: true,
          gridLines: {
            display: false,
          },
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  },
});

export const getFirstPieObj = (main: string, dark: string) => ({
  data: {
    labels: ["", ""],
    datasets: [
      {
        data: [50, 50],
        backgroundColor: [dark, main],
        borderWidth: 0,
      },
    ],
  },
  options: {
    legend: {
      display: false,
    },
    cutoutPercentage: 45,
  },
});

export const getSecondPieObj = (main: string, dark: string) => ({
  data: {
    labels: ["", ""],
    datasets: [
      {
        data: [15, 85],
        backgroundColor: [dark, main],
        borderWidth: 0,
      },
    ],
  },
  options: {
    legend: {
      display: false,
    },
    cutoutPercentage: 45,
  },
});

export const getThirdPieObj = (main: string, dark: string) => ({
  data: {
    labels: ["", ""],
    datasets: [
      {
        data: [30, 70],
        backgroundColor: [dark, main],
        borderWidth: 0,
      },
    ],
  },
  options: {
    legend: {
      display: false,
    },
    cutoutPercentage: 45,
  },
});

export const getThirdCanvasObj = (main: string, dark: string) => ({
  data: {
    labels: ["", "", "", ""],
    datasets: [
      {
        label: "",
        data: [40, 60, 90, 10],
        backgroundColor: dark,
      },
      {
        label: "",
        data: [60, 40, 10, 90],
        backgroundColor: main,
      },
    ],
  },
  options: {
    legend: {
      display: false,
    },
    scales: {
      xAxes: [
        {
          stacked: true,
          gridLines: {
            display: false,
          },
        },
      ],
      yAxes: [
        {
          display: false,
          stacked: true,
          gridLines: {
            display: false,
          },
          ticks: {
            beginAtZero: true,
          },
        },
      ],
    },
  },
});
