import React from "react";
import { Box } from "@material-ui/core";
import { useSelector } from "react-redux";
import { GlobalModal } from "components/globalModal/GlobalModal";
import { LoadingSpinner } from "components/LoadingSpinner";
import { NoteElement } from "./NoteElement";
import { selectCurrentUser } from "features/logIn/sessionSlice";
import { JobNoteModel } from "@dwo/shared/dist/models/jobNoteModel";
import { slashFormatDate } from "utils/dateUtils";

interface NotesModalProps {
  buttonsMaxWidth?: string;
  cancelBtnText?: string;
  continueBtnText?: string;
  displayJobId?: boolean;
  isContinueDisabled?: boolean;
  isCreatingNote?: boolean;
  isLoading: boolean;
  isOpen: boolean;
  notes: JobNoteModel[];
  title?: string;
  onClickAdd: () => void;
  onClickCancel?: () => void;
  onClickClose: () => void;
  onClickDelete?: (id: string) => void;
  onClickSave?: (text: string, title: string, id?: string) => void;
}

export function NotesModal({
  buttonsMaxWidth,
  cancelBtnText = "Go Back",
  continueBtnText = "Add New Note",
  displayJobId,
  isContinueDisabled,
  isCreatingNote,
  isLoading,
  isOpen,
  notes,
  title = "Notes",
  onClickAdd,
  onClickCancel,
  onClickClose,
  onClickDelete,
  onClickSave,
}: NotesModalProps) {
  const currentUser = useSelector(selectCurrentUser);
  const author = currentUser
    ? `${currentUser.employee.firstName} ${currentUser.employee.lastName}`
    : "User Full Name";
  const picture = currentUser?.employee?.pictureUrl || "";
  const role = currentUser?.employee.role || "User Role";

  return (
    <GlobalModal
      actionBtnsMaxWidth={buttonsMaxWidth}
      isContinueDisabled={isContinueDisabled || isCreatingNote || isLoading}
      open={isOpen}
      primaryLabel={continueBtnText}
      secondaryLabel={cancelBtnText}
      title={title}
      onClickClose={onClickClose}
      onClickContinue={onClickAdd}
    >
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <Box margin="-32px 32px 0 32px">
          {isCreatingNote && (
            <NoteElement
              author={author}
              isNew
              picture={picture}
              role={role}
              onClickCancel={onClickCancel}
              onClickSave={onClickSave}
            />
          )}
          {notes.map((note: JobNoteModel) => {
            const fullName = note.createdBy
              ? `${note.createdBy.employee.firstName} ${note.createdBy.employee.lastName}`
              : "User Full Name";
            const noteTitle = note.title;
            const noteDate = slashFormatDate(new Date(note.createdAt as Date));
            return (
              <NoteElement
                author={fullName}
                date={noteDate}
                noteTitle={noteTitle}
                id={note.id?.toString()}
                jobId={displayJobId ? note.job?.jobId : undefined}
                key={note.id}
                onClickDelete={onClickDelete}
                onClickSave={onClickSave}
                picture={note.pictureUrl as string}
                role={note.createdBy?.employee.role as string}
                noteText={note.note}
              />
            );
          })}
        </Box>
      )}
    </GlobalModal>
  );
}
