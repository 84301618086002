import {
  Button,
  Box,
  CardMedia,
  makeStyles,
  Theme,
  createStyles,
  Typography,
  useTheme,
  Grid,
} from "@material-ui/core";
import React, { Fragment, useEffect } from "react";
import { Redirect, useLocation } from "react-router-dom";
import { ROOT_HOME } from "routes/Roots";
import { useSelector, useDispatch } from "react-redux";
import { selectIsAuth } from "features/logIn/sessionSlice";
import { GoogleIcon } from "components/icons/GoogleIcons";
import { MicrosoftIcon } from "components/icons/MicrosoftIcon";
import { error } from "features/error/errorSlice";
const BASE_URL = window.appEnv?.baseUrl;

const loginStyles = makeStyles((theme: Theme) =>
  createStyles({
    logo: {
      width: "75px",
      height: "75px",
      objectFit: "unset",
      marginBottom: "25px",
    },
    image: {
      height: "100vh",
    },
    overlay: {
      backgroundColor: theme.palette.primary.main,
      opacity: 0.75,
    },
  }),
);

export const LoginScreen = () => {
  const status = new URLSearchParams(useLocation().search).get("status");
  const dispatch = useDispatch();
  const theme = useTheme();
  const classes = loginStyles();
  const isAuthenticated = useSelector(selectIsAuth);

  const handleClickSSOMs = () => {
    window.location.replace(`${BASE_URL}/auth/login/microsoft`);
  };

  const handleClickSSOGoogle = () => {
    window.location.replace(`${BASE_URL}/auth/login/google`);
  };

  useEffect(() => {
    if (status) {
      let errorText = "Account not found";
      errorText =
        status === "notFound"
          ? "Your account is not registered with this application"
          : errorText;
      errorText =
        status === "unauthorized"
          ? "You don't have permission to access this application"
          : errorText;

      dispatch(
        error(
          {
            title: "We could not log you in",
            message: errorText,
          },
          () => handleClickSSOGoogle(),
        ),
      );
    }
  }, [dispatch, status]);

  if (isAuthenticated) {
    return <Redirect to={ROOT_HOME} />;
  }

  return (
    <Fragment>
      <Grid container>
        <Grid item lg={8} md={6} sm={4} xs={true}>
          <Box position="relative">
            <Box
              className={classes.overlay}
              position="absolute"
              width="100%"
              height="100vh"
              zIndex={10}
            />
            <CardMedia
              className={classes.image}
              component="img"
              image="/images/TSU6.jpg"
            />
          </Box>
        </Grid>
        <Grid item lg={4} md={6} sm={8} xs={12}>
          <Box
            height="100vh"
            display="flex"
            alignItems="center"
            justifyContent="center"
            bgcolor="white"
          >
            <Box display="flex" flexDirection="column" alignItems="center">
              <CardMedia
                className={classes.logo}
                component="img"
                image="/images/logo.png"
              />
              <Typography variant="h4" color="primary">
                Sign in
              </Typography>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                height="121px"
                marginTop="40px"
              >
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={handleClickSSOMs}
                >
                  <Box display="flex" alignItems="center" marginRight="24px">
                    <MicrosoftIcon
                      color={theme.palette.primary.dark}
                      fontSize={16}
                    />
                  </Box>
                  Sign In with Microsoft
                </Button>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={handleClickSSOGoogle}
                >
                  <Box
                    display="flex"
                    alignItems="center"
                    marginRight="32px"
                    marginLeft="-12px"
                  >
                    <GoogleIcon
                      color={theme.palette.primary.dark}
                      fontSize={16}
                    />
                  </Box>
                  Sign In with Google
                </Button>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Fragment>
  );
};
