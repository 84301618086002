import {
  Box,
  createStyles,
  Grid,
  makeStyles,
  TextField,
  Theme,
  Typography,
} from "@material-ui/core";
import { EmployeeModel } from "@dwo/shared/dist/models/employeeModel";
import React from "react";
import { useSelector } from "react-redux";
import { selectIsActiveEmployee } from "features/employees/employeesSlice";

interface RateInformationProps {
  header: string;
  information?: EmployeeModel;
}

interface StyleProps {
  isDisabled: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      color: ({ isDisabled }: StyleProps) =>
        isDisabled ? theme.palette.grey[400] : theme.palette.primary.main,
      marginBottom: "24px",
    },
  }),
);

export function RateInformation({ header, information }: RateInformationProps) {
  const isDisabled = !useSelector(selectIsActiveEmployee);
  const classes = useStyles({ isDisabled });

  const getRates = (information: EmployeeModel) => {
    return Object.entries(information)
      .filter(([property, value]) => property.includes("rate") && !!value)
      .map(([, rateInfo]) => {
        try {
          const rateParts = rateInfo.split(" - ");
          const key = rateParts[1].split(" ")[0];
          const lastSection = rateParts[rateParts.length - 1].trim().split(" ");
          const keyValue = lastSection[lastSection.length - 1];
          return { key, keyValue };
        } catch (e) {
          return { key: "N/A", keyValue: "N/A" };
        }
      });
  };
  return (
    <Box margin="24px">
      <Typography variant="h5" className={classes.header}>
        {header}
      </Typography>
      <Grid container spacing={3} justify="flex-start">
        {information &&
          getRates(information).map((info, index) => (
            <Grid key={`${index}-${info?.key}-${info?.keyValue}`} item md={4}>
              <TextField
                value={
                  info?.keyValue
                    ? `$${info?.keyValue}/Hr`
                    : "Invalid Rate Format"
                }
                label={`${info?.key} Rate`}
                disabled={isDisabled}
              />
            </Grid>
          ))}
      </Grid>
    </Box>
  );
}
