import {
  Box,
  createStyles,
  Grid,
  makeStyles,
  Paper,
  Tab,
  Tabs,
  Theme,
  Typography,
} from "@material-ui/core";
import {
  selectSummaryOverviewData,
  selectSummarySelectedCategory,
  selectSummarySelectedDateRange,
  selectSummarySelectedTab,
  setSummaryCategoriesAndColors,
  setSummarySelectedCategory,
  setSummarySelectedTab,
} from "features/summary/summarySlice";
import { differenceInDays } from "date-fns";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { slashFormatDate } from "utils/dateUtils";
import { Dropdown } from "components/Dropdown";
import {
  JobCategoriesColors,
  OverviewChartsDateRangeCategories,
  OverviewTabs,
  summaryJobCategories,
  TooltipLabelOption,
} from "utils/summaryUtils";
import { OverviewSingleDayChart } from "./OverviewSingleDayChart";
import { LabelDescriptionItem } from "./LabelDescriptionItem";
import { OverviewPerDayChart } from "./OverviewPerDayChart";
import { JobCategories } from "@dwo/shared/dist/models/jobSummaryModel";
import { OverviewPerWeekChart } from "./OverviewPerWeekChart";
import { OverviewPerWeekCondensedChart } from "./OverviewPerWeekCondensedChart";
import { OverviewPerMonthChart } from "./OverviewPerMonthChart";
import { OverviewPerQuarterChart } from "./OverviewPerQuarterChart";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    categoriesContainer: {
      display: "flex",
      justifyContent: "flex-end",
      [theme.breakpoints.down("sm")]: {
        justifyContent: "flex-start",
      },
    },
    chartsContainer: {
      display: "flex",
      justifyContent: "center",
      width: "100%",
    },
    container: {
      display: "flex",
      flexDirection: "column",
      margin: "0 24px 24px 24px",
      padding: "24px 32px",
    },
    date: {
      color: theme.palette.primary.dark,
      fontWeight: "bold",
      margin: "0 16px 24px 16px",
      [theme.breakpoints.down("sm")]: {
        margin: "0 0 24px 0",
      },
    },
    dateContainer: {
      display: "flex",
      justifyContent: "center",
      [theme.breakpoints.down("sm")]: {
        justifyContent: "flex-start",
      },
    },
    dropdown: {
      display: "flex",
      justifyContent: "flex-end",
      marginBottom: "24px",
      maxWidth: "160px",
      width: "100%",
      [theme.breakpoints.down("sm")]: {
        maxWidth: "100%",
      },
    },
    labelsContainer: {
      display: "flex",
      flexDirection: "column",
      height: "100%",
      justifyContent: "center",
      width: "fit-content",
      [theme.breakpoints.down("sm")]: {
        paddingBottom: "24px",
      },
    },
    tab: {
      fontWeight: "bold",
      fontSize: "0.75rem",
      maxHeight: "32px",
      maxWidth: "fit-content",
      minHeight: "32px",
      minWidth: "fit-content",
      padding: "8px",
      textTransform: "capitalize",
      width: "100%",
      "& > span": {
        width: "fit-content",
      },
    },
    tabsContainer: {
      color: theme.palette.grey[600],
      minHeight: "fit-content",
    },
    title: {
      color: theme.palette.primary.main,
      marginBottom: "24px",
    },
  }),
);

export function SummaryOverviewCard() {
  const dispatch = useDispatch();
  const currentCategory = useSelector(selectSummarySelectedCategory);
  const currentDateRange = useSelector(selectSummarySelectedDateRange);
  const overviewData = useSelector(selectSummaryOverviewData);
  const overviewTab = useSelector(selectSummarySelectedTab);
  const [displayedDate, setDisplayedDate] = useState<string>("");
  const [
    dateRangeCategory,
    setDateRangeCategory,
  ] = useState<OverviewChartsDateRangeCategories>(
    OverviewChartsDateRangeCategories.PerSingleDay,
  );
  const classes = useStyles();

  const overviewPerDay = overviewData?.overviewDataPerDay;
  const overviewPerWeek = overviewData?.overviewDataPerWeek;
  const overviewPerMonth = overviewData?.overviewDataPerMonth;
  const displayTabs = overviewPerWeek || overviewPerMonth;

  useEffect(() => {
    if (!currentDateRange) return;

    const {
      startDate: startDateString,
      endDate: endDateString,
    } = currentDateRange;
    const startDate = new Date(startDateString);
    const endDate = new Date(endDateString);

    const selectedDays = differenceInDays(endDate, startDate) + 1;

    if (selectedDays === 1 && overviewData?.overviewDataPerDay) {
      setDisplayedDate(slashFormatDate(startDate));
      setDateRangeCategory(OverviewChartsDateRangeCategories.PerSingleDay);
      return;
    }

    setDisplayedDate(
      `${slashFormatDate(startDate)} - ${slashFormatDate(endDate)}`,
    );

    if (
      selectedDays > 1 &&
      selectedDays <= 21 &&
      overviewData?.overviewDataPerDay
    ) {
      setDateRangeCategory(OverviewChartsDateRangeCategories.PerDay);
      return;
    }

    if (selectedDays > 21 && overviewData?.overviewDataPerWeek) {
      setDateRangeCategory(OverviewChartsDateRangeCategories.PerWeek);
      dispatch(setSummarySelectedTab(OverviewTabs.LeftTab));
      return;
    }

    if (overviewData?.overviewDataPerMonth) {
      setDateRangeCategory(OverviewChartsDateRangeCategories.PerMonth);
      dispatch(setSummarySelectedTab(OverviewTabs.LeftTab));
      return;
    }
  }, [currentDateRange, dispatch, overviewData]);

  const ohElectricLabel: TooltipLabelOption = {
    color: JobCategoriesColors.OhElectric,
    label: JobCategories.OhElectric,
  };
  const ugGasLabel: TooltipLabelOption = {
    color: JobCategoriesColors.UgGas,
    label: JobCategories.UgGas,
  };
  const ugElectricLabel: TooltipLabelOption = {
    color: JobCategoriesColors.UgElectric,
    label: JobCategories.UgElectric,
  };
  const castIronLabel: TooltipLabelOption = {
    color: JobCategoriesColors.CasIron,
    label: JobCategories.CastIron,
  };
  const overheadLabel: TooltipLabelOption = {
    color: JobCategoriesColors.Overhead,
    label: JobCategories.Overhead,
  };
  const generalLabel: TooltipLabelOption = {
    color: JobCategoriesColors.General,
    label: JobCategories.General,
  };

  useEffect(() => {
    dispatch(
      setSummaryCategoriesAndColors([
        ohElectricLabel,
        ugGasLabel,
        ugElectricLabel,
        castIronLabel,
        overheadLabel,
        generalLabel,
      ]),
    );
  }, [
    dispatch,
    ohElectricLabel,
    ugGasLabel,
    ugElectricLabel,
    castIronLabel,
    overheadLabel,
    generalLabel,
  ]);

  const handleChangeTabs = (
    _event: React.ChangeEvent<unknown>,
    selectedTab: OverviewTabs,
  ) => {
    dispatch(setSummarySelectedTab(selectedTab));

    if (
      selectedTab === OverviewTabs.RightTab &&
      dateRangeCategory === OverviewChartsDateRangeCategories.PerWeek
    ) {
      dispatch(setSummarySelectedCategory(""));
    }
  };

  const handleSelectCategory = (selectedCategory: string) =>
    dispatch(setSummarySelectedCategory(selectedCategory));

  const getIsDropdownDisabled = () => {
    if (dateRangeCategory === OverviewChartsDateRangeCategories.PerSingleDay) {
      return true;
    }

    if (dateRangeCategory === OverviewChartsDateRangeCategories.PerWeek) {
      return overviewTab === OverviewTabs.RightTab;
    }

    return false;
  };

  return (
    <Paper className={classes.container}>
      <Grid container>
        <Grid item sm={4} xs={12}>
          <Typography className={classes.title} variant="h6">
            Overview
          </Typography>
        </Grid>

        <Grid item sm={4} xs={12}>
          <Box className={classes.dateContainer}>
            <Typography className={classes.date} variant="body1">
              {displayedDate}
            </Typography>
          </Box>
        </Grid>

        <Grid item sm={4} xs={12}>
          <Box className={classes.categoriesContainer}>
            <Box className={classes.dropdown}>
              <Dropdown
                isDisabled={getIsDropdownDisabled()}
                id="summary-categories"
                label="Categories"
                options={summaryJobCategories}
                selectedValue={currentCategory}
                onSelectOption={handleSelectCategory}
              />
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12}>
          {displayTabs && (
            <Box
              alignItems="center"
              display="flex"
              justifyContent="center"
              marginBottom="24px"
              marginTop="-24px"
              width="100%"
            >
              <Tabs
                classes={{ root: classes.tabsContainer }}
                indicatorColor="primary"
                value={overviewTab}
                onChange={handleChangeTabs}
              >
                <Tab
                  classes={{ root: classes.tab }}
                  label={
                    dateRangeCategory ===
                    OverviewChartsDateRangeCategories.PerWeek
                      ? "Weekly"
                      : "Monthly"
                  }
                />
                <Tab
                  classes={{ root: classes.tab }}
                  label={
                    dateRangeCategory ===
                    OverviewChartsDateRangeCategories.PerWeek
                      ? "Monthly"
                      : "Quarterly"
                  }
                />
              </Tabs>
            </Box>
          )}
        </Grid>

        <Grid item sm={2} xs={12}>
          <Box className={classes.labelsContainer}>
            <LabelDescriptionItem itemData={ohElectricLabel} />
            <LabelDescriptionItem itemData={ugGasLabel} />
            <LabelDescriptionItem itemData={ugElectricLabel} />
            <LabelDescriptionItem itemData={castIronLabel} />
            <LabelDescriptionItem itemData={overheadLabel} />
            <LabelDescriptionItem itemData={generalLabel} />
          </Box>
        </Grid>

        <Grid item sm={8} xs={12}>
          <Box className={classes.chartsContainer}>
            {dateRangeCategory ===
              OverviewChartsDateRangeCategories.PerSingleDay &&
              overviewPerDay && (
                <OverviewSingleDayChart data={overviewPerDay[0]} />
              )}
            {dateRangeCategory === OverviewChartsDateRangeCategories.PerDay &&
              overviewPerDay && <OverviewPerDayChart data={overviewPerDay} />}
            {dateRangeCategory === OverviewChartsDateRangeCategories.PerWeek &&
              overviewPerWeek &&
              overviewTab === OverviewTabs.LeftTab && (
                <OverviewPerWeekChart data={overviewPerWeek} />
              )}
            {dateRangeCategory === OverviewChartsDateRangeCategories.PerWeek &&
              overviewPerWeek &&
              overviewTab === OverviewTabs.RightTab && (
                <OverviewPerWeekCondensedChart data={overviewPerWeek} />
              )}
            {dateRangeCategory === OverviewChartsDateRangeCategories.PerMonth &&
              overviewPerMonth &&
              overviewTab === OverviewTabs.LeftTab && (
                <OverviewPerMonthChart data={overviewPerMonth} />
              )}
            {dateRangeCategory === OverviewChartsDateRangeCategories.PerMonth &&
              overviewPerMonth &&
              overviewTab === OverviewTabs.RightTab && (
                <OverviewPerQuarterChart data={overviewPerMonth} />
              )}
          </Box>
        </Grid>

        <Grid item sm={2} xs={12} />
      </Grid>
    </Paper>
  );
}
