import {
  Box,
  Button,
  Checkbox,
  createStyles,
  Grid,
  ListItemText,
  makeStyles,
  MenuItem,
  Select,
  Theme,
  Typography,
} from "@material-ui/core";

import { DropdownOption } from "components/Dropdown";
import { useFormik } from "formik";
import {
  NewEmployeeModel,
  EmployeeModel,
} from "@dwo/shared/dist/models/employeeModel";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  companiesOptions,
  DEFAULT_EMPLOYEE_ID,
  DEFAULT_EMPTY,
  DEFAULT_FIRST_NAME,
  DEFAULT_LAST_NAME,
  DEFAULT_NULL,
  DEFAULT_SELECT_OPTION,
  DEFAULT_USER_FORM_VALUES,
  EmployeeFormFields,
  getInitialEmployeeFormValues,
  regionsOptions,
  rolesOptions,
  specialistFieldOptions,
  validationEmployeeForm,
} from "utils/employeeFormUtils";
import { parseRegions } from "utils/homeUtils";
import { SelectCustom } from "./SelectCustom";
import { TextFieldCustom } from "./TextFieldCustom";
import {
  selectRegions,
  selectLoading as selectRegionLoading,
  getAllRegions,
} from "features/region/regionSlice";
import { getLocationToDisplay } from "utils/employeeDetailsUtils";
import { selectEmployee } from "features/employees/employeesSlice";
import { LoadingSpinner } from "components/LoadingSpinner";

interface EmployeeFormProps {
  employeeData?: NewEmployeeModel;
  isEditing?: boolean;
  onClickCheckbox?: (idRow: string, isChecked: boolean) => void;
  onSubmit: (updateEmployee: NewEmployeeModel) => void;
  regions: string;
  supervisorsData?: EmployeeModel;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    descriptionLabel: {
      color: theme.palette.primary.main,
    },
    infoLabel: {
      color: theme.palette.primary.dark,
      fontWeight: "bold",
    },
    submitButton: {
      display: "none",
    },
  }),
);

export function EmployeeForm({
  employeeData,
  isEditing,
  onSubmit,
  regions,
  supervisorsData,
}: EmployeeFormProps) {
  const classes = useStyles();
  // const currentRegions = useSelector(selectRegions);
  // const allRegions: DropdownOption[] = [...parseRegions(currentRegions)];
  const currentUser = useSelector(selectEmployee);
  const location = getLocationToDisplay(currentUser?.regions);
  const dispatch = useDispatch();

  const handleSubmit = ({
    companyName,
    email,
    employeeId,
    firstName,
    lastName,
    regionIds,
    role,
    specialistField,
    supervisorId,
    workPhone,
  }: EmployeeFormFields) => {
    onSubmit({
      ...employeeData,
      companyName:
        companyName !== DEFAULT_SELECT_OPTION ? companyName : undefined,
      email,
      employeeId,
      firstName,
      lastName,
      class:
        specialistField !== DEFAULT_SELECT_OPTION ? specialistField : undefined,
      supervisorId,
      workPhone,
      regionIds,
      role,
    } as NewEmployeeModel);
  };

  const formik = useFormik({
    initialValues: getInitialEmployeeFormValues(employeeData, supervisorsData),
    validationSchema: validationEmployeeForm(isEditing),
    onSubmit: handleSubmit,
  });

  const handleTextFieldsFocus = (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const fieldValue = event.target.value;
    const hasDefaultValue = DEFAULT_USER_FORM_VALUES.includes(fieldValue);

    if (isEditing && !hasDefaultValue) {
      return;
    }

    const fieldName = event.target.name as keyof EmployeeFormFields;

    if (!formik.touched[fieldName]) {
      formik.setFieldValue(fieldName, "");
    }
  };

  useEffect(() => {
    dispatch(getAllRegions());
  }, [dispatch]);

  const isLoadingRegions = useSelector(selectRegionLoading);
  const currentRegions = useSelector(selectRegions);
  const allRegions: DropdownOption[] = parseRegions(currentRegions);

  return (
    <form autoComplete="off" onSubmit={formik.handleSubmit}>
      <Box padding="24px 0 16px 0">
        <Typography className={classes.infoLabel} variant="body1">
          User Info
        </Typography>
      </Box>
      <Grid container spacing={2}>
        <Grid item md={6} sm={6} xs={12}>
          <SelectCustom
            {...formik.getFieldProps("role")}
            defaultValue={DEFAULT_SELECT_OPTION}
            error={formik.touched.role && !!formik.errors.role}
            errorMessage="Select a User Role"
            fullWidth
            id="userRole"
            label="User Role"
            labelId="userRole"
          >
            <MenuItem value={DEFAULT_SELECT_OPTION}>{DEFAULT_EMPTY}</MenuItem>
            {rolesOptions.map((option: DropdownOption) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </SelectCustom>
        </Grid>

        <Grid item md={6} sm={6} xs={12}>
          <SelectCustom
            {...formik.getFieldProps("regionIds")}
            defaultValue={DEFAULT_SELECT_OPTION}
            error={formik.touched.regionIds && !!formik.errors.regionIds}
            errorMessage="Select a Region"
            fullWidth
            id="regionIds"
            label="Region"
            labelId="regionIds"
          >
            {isLoadingRegions && <LoadingSpinner />}
            {!isLoadingRegions && (
              <MenuItem value={DEFAULT_SELECT_OPTION}>{DEFAULT_EMPTY}</MenuItem>
            )}
            {!isLoadingRegions &&
              allRegions.map((option: DropdownOption) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
          </SelectCustom>
        </Grid>
      </Grid>

      <Box padding="24px 0 16px 0">
        <Typography className={classes.infoLabel} variant="body1">
          Personal Info
        </Typography>
      </Box>

      <Grid container spacing={2}>
        <Grid item md={6} sm={6} xs={12}>
          <TextFieldCustom
            {...formik.getFieldProps("firstName")}
            error={formik.touched.firstName && !!formik.errors.firstName}
            errorMessage="First Name is required"
            fullWidth
            id="firstName"
            label="Emplpyee First Name"
            onFocus={handleTextFieldsFocus}
            placeholder={
              isEditing ? DEFAULT_FIRST_NAME : employeeData?.firstName
            }
          />
        </Grid>

        <Grid item md={6} sm={6} xs={12}>
          <TextFieldCustom
            {...formik.getFieldProps("lastName")}
            error={formik.touched.lastName && !!formik.errors.lastName}
            errorMessage="Last Name is required"
            fullWidth
            id="lastName"
            label="Emplpyee Last Name"
            onFocus={handleTextFieldsFocus}
            placeholder={
              isEditing ? `${DEFAULT_LAST_NAME}` : employeeData?.lastName
            }
          />
        </Grid>

        <Grid item md={4} sm={6} xs={12}>
          <SelectCustom
            {...formik.getFieldProps("companyName")}
            defaultValue={DEFAULT_SELECT_OPTION}
            error={formik.touched.companyName && !!formik.errors.companyName}
            errorMessage="Select a Company"
            fullWidth
            id="companyName"
            label="Company Name"
            labelId="companyName"
          >
            <MenuItem value={DEFAULT_SELECT_OPTION}>{DEFAULT_EMPTY}</MenuItem>
            {companiesOptions.map((option: DropdownOption) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </SelectCustom>
        </Grid>

        <Grid item md={4} sm={6} xs={12}>
          <TextFieldCustom
            {...formik.getFieldProps("employeeId")}
            error={formik.touched.employeeId && !!formik.errors.employeeId}
            errorMessage="Employee ID is required"
            fullWidth
            id="employeeId"
            label="Emplpyee ID"
            onFocus={handleTextFieldsFocus}
            placeholder={
              isEditing ? DEFAULT_EMPLOYEE_ID : employeeData?.employeeId
            }
          />
        </Grid>

        <Grid item md={4} sm={6} xs={12}>
          <TextFieldCustom
            {...formik.getFieldProps("supervisorId")}
            error={formik.touched.supervisorId && !!formik.errors.supervisorId}
            errorMessage="Supervisor ID is required"
            fullWidth
            id="supervisorId"
            label="Supervisor ID"
            onFocus={handleTextFieldsFocus}
            placeholder={
              isEditing
                ? DEFAULT_EMPLOYEE_ID
                : supervisorsData?.supervisor?.employeeId
            }
          />
        </Grid>

        <Grid item md={12} sm={12} xs={12}>
          <SelectCustom
            {...formik.getFieldProps("specialistField")}
            defaultValue={DEFAULT_SELECT_OPTION}
            error={
              formik.touched.specialistField && !!formik.errors.specialistField
            }
            errorMessage="Select a Specialist Field"
            fullWidth
            id="specialistField"
            label="Specialist Field"
            labelId="specialistField"
          >
            <MenuItem value={DEFAULT_SELECT_OPTION}>{DEFAULT_EMPTY}</MenuItem>
            {specialistFieldOptions.map((option: DropdownOption) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </SelectCustom>
        </Grid>
      </Grid>

      <Box padding="24px 0 16px 0">
        <Typography className={classes.infoLabel} variant="body1">
          Contact Info
        </Typography>
      </Box>

      <Grid container spacing={2}>
        <Grid item md={6} sm={6} xs={12}>
          <TextFieldCustom
            {...formik.getFieldProps("email")}
            error={formik.touched.email && !!formik.errors.email}
            errorMessage="Invalid Email"
            fullWidth
            id="email"
            label="Employee Email"
            onFocus={handleTextFieldsFocus}
            placeholder={isEditing ? DEFAULT_NULL : employeeData?.email}
          />
        </Grid>

        <Grid item md={6} sm={6} xs={12}>
          <TextFieldCustom
            {...formik.getFieldProps("workPhone")}
            error={formik.touched.workPhone && !!formik.errors.workPhone}
            errorMessage="Invalid Phone Number"
            fullWidth
            id="workPhone"
            label="Employee Work Phone"
            onFocus={handleTextFieldsFocus}
            placeholder={isEditing ? DEFAULT_NULL : employeeData?.workPhone}
          />
        </Grid>
      </Grid>

      <Button
        className={classes.submitButton}
        id="submitUserForm-btn"
        type="submit"
      />
    </form>
  );
}
