import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, RootState } from "app/store";
import { error } from "features/error/errorSlice";
import { cloneDeep } from "lodash";
import { JobInvoiceModel } from "@dwo/shared/dist/models/jobInvoiceModel";
import { JobModel } from "@dwo/shared/dist/models/jobModel";
import { ServiceOptions } from "@dwo/shared/dist/services/baseService";
import { jobInvoiceService } from "@dwo/shared/dist/services/jobInvoiceService";
import { jobService } from "@dwo/shared/dist/services/JobService";
import {
  hide as hideLoader,
  show as showLoader,
} from "features/loader/loaderSlice";
import { HazardModel } from "@dwo/shared/dist/models/hazardModel";
import { dwoJobService } from "@dwo/shared/dist/services/dwoJobService";
import { crewService } from "@dwo/shared/dist/services/crewService";
import { dailyJobHazardService } from "@dwo/shared/dist/services/dailyJobHazardService";
import { DWOJobModel } from "@dwo/shared/dist/models/DWOJobModel";
import { EmployeeModel } from "@dwo/shared/dist/models/employeeModel";
import { CrewModel } from "@dwo/shared/dist/models/crewModel";
import { DEFAULT_LIMIT } from "utils/sharedUtils";
import {
  initialJobQuery,
  initialJobFilters,
  JobFilters,
  JobTabs,
  initialJobOvercostFilters,
  initialJobOvercostQuery,
} from "utils/jobUtils";
import { EmployeeCrewModel } from "@dwo/shared/dist/models/employeeCrewModel";

interface JobState {
  count: number;
  error: boolean;
  foremen: EmployeeModel[];
  hazards: HazardModel[];
  invoices: JobInvoiceModel[];
  isLoading: boolean;
  isLoadingForemen: boolean;
  isLoadingHazards: boolean;
  isLoadingInvoices: boolean;
  isLoadingJobDWOs: boolean;
  isLoadingUpdate: boolean;
  job?: JobModel;
  jobDWOs: DWOJobModel[];
  jobs: JobModel[];
  limit?: number;
  offset?: number;
  order?: any;
  tabSelected: JobTabs;
  jobFilters: JobFilters;
  jobQuery: ServiceOptions;
  jobOvercostFilters: JobFilters;
  jobOvercostQuery: ServiceOptions;
}

const initialState: JobState = {
  count: 0,
  error: false,
  foremen: [],
  hazards: [],
  invoices: [],
  isLoading: false,
  isLoadingForemen: false,
  isLoadingHazards: false,
  isLoadingInvoices: false,
  isLoadingJobDWOs: false,
  isLoadingUpdate: false,
  job: undefined,
  jobDWOs: [],
  jobs: [],
  limit: DEFAULT_LIMIT,
  offset: 0,
  order: undefined,
  tabSelected: JobTabs.ALL,
  jobFilters: initialJobFilters,
  jobQuery: initialJobQuery,
  jobOvercostFilters: initialJobOvercostFilters,
  jobOvercostQuery: initialJobOvercostQuery,
};

export const jobsSlice = createSlice({
  name: "jobs",
  initialState,
  reducers: {
    setCount: (state, action: PayloadAction<number>) => {
      state.count = action.payload;
    },
    setError: (state, action: PayloadAction<boolean>) => {
      state.error = action.payload;
    },
    setForemen: (state, action: PayloadAction<EmployeeModel[]>) => {
      state.foremen = action.payload;
    },
    setHazards: (state, action: PayloadAction<HazardModel[]>) => {
      state.hazards = action.payload;
    },
    setInvoices: (state, action: PayloadAction<JobInvoiceModel[]>) => {
      state.invoices = action.payload;
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setIsLoadingForemen: (state, action: PayloadAction<boolean>) => {
      state.isLoadingForemen = action.payload;
    },
    setIsLoadingHazards: (state, action: PayloadAction<boolean>) => {
      state.isLoadingHazards = action.payload;
    },
    setIsLoadingInvoices: (state, action: PayloadAction<boolean>) => {
      state.isLoadingInvoices = action.payload;
    },
    setIsLoadingJobDWOs: (state, action: PayloadAction<boolean>) => {
      state.isLoadingJobDWOs = action.payload;
    },
    setIsLoadingUpdate: (state, action: PayloadAction<boolean>) => {
      state.isLoadingUpdate = action.payload;
    },
    setJob: (state, action: PayloadAction<JobModel>) => {
      state.job = action.payload;
    },
    setJobDWOs: (state, action: PayloadAction<DWOJobModel[]>) => {
      state.jobDWOs = action.payload;
    },
    setJobs: (state, action: PayloadAction<JobModel[]>) => {
      state.jobs = cloneDeep(action.payload);
    },
    setLimit: (state, action: PayloadAction<number>) => {
      state.limit = action.payload;
    },
    setOffset: (state, action: PayloadAction<number>) => {
      state.offset = action.payload;
    },
    setOrder: (state, action: PayloadAction<any>) => {
      state.order = action.payload;
    },
    setTabSelected: (state, action: PayloadAction<JobTabs>) => {
      state.tabSelected = action.payload;
    },
    setJobFilters: (state, action: PayloadAction<JobFilters>) => {
      state.jobFilters = action.payload;
    },
    setJobQuery: (state, action: PayloadAction<ServiceOptions>) => {
      state.jobQuery = action.payload;
    },
    setJobOvercostFilters: (state, action: PayloadAction<JobFilters>) => {
      state.jobOvercostFilters = action.payload;
    },
    setJobOvercostQuery: (state, action: PayloadAction<ServiceOptions>) => {
      state.jobOvercostQuery = action.payload;
    },
  },
});

export const {
  setCount,
  setError,
  setForemen,
  setHazards,
  setInvoices,
  setIsLoading,
  setIsLoadingForemen,
  setIsLoadingHazards,
  setIsLoadingInvoices,
  setIsLoadingJobDWOs,
  setIsLoadingUpdate,
  setJob,
  setJobDWOs,
  setJobs,
  setLimit,
  setOffset,
  setOrder,
  setTabSelected,
  setJobFilters,
  setJobQuery,
  setJobOvercostFilters,
  setJobOvercostQuery,
} = jobsSlice.actions;

export const createJob = (job: JobModel): AppThunk => async (dispatch) => {
  try {
    dispatch(setIsLoading(true));

    const { data } = await jobService.create(job);

    dispatch(setJob(data));
  } catch (e) {
    dispatch(
      error(
        {
          title: "Could not create job",
          message: e.message,
        },
        () => dispatch(createJob(job)),
      ),
    );
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const createJobInvoice = (
  jobInvoice: JobInvoiceModel,
): AppThunk => async (dispatch, select) => {
  try {
    dispatch(showLoader());

    const { data } = await jobInvoiceService.create(jobInvoice);

    dispatch(setInvoices([...select().jobs.invoices, data]));
  } catch (e) {
    dispatch(
      error(
        {
          title: "Could not create invoice",
          message: e.message,
        },
        () => dispatch(createJobInvoice(jobInvoice)),
      ),
    );
  } finally {
    dispatch(hideLoader());
  }
};

export const deleteJobInvoice = (id: number): AppThunk => async (
  dispatch,
  select,
) => {
  try {
    dispatch(showLoader());
    await jobInvoiceService.delete(id);

    const updatedInvoices = [...select().jobs.invoices];
    const foundIndex = updatedInvoices.findIndex(
      (invoice) => invoice.id === Number(id),
    );

    if (foundIndex >= 0) {
      updatedInvoices.splice(foundIndex, 1);
      dispatch(setInvoices(updatedInvoices));
    }
  } catch (e) {
    dispatch(
      error(
        {
          title: "Could not create invoice",
          message: e.message,
        },
        () => dispatch(deleteJobInvoice(id)),
      ),
    );
  } finally {
    dispatch(hideLoader());
  }
};

export const getAllJobs = (options?: ServiceOptions): AppThunk => async (
  dispatch,
) => {
  try {
    dispatch(setIsLoading(true));

    const { count, data, limit, offset, order } = await jobService.getAll(
      options,
    );

    dispatch(setCount(count));
    dispatch(setJobs(data));
    dispatch(setLimit(limit));
    dispatch(setOffset(offset));
    dispatch(setOrder(order));
  } catch (e) {
    dispatch(
      error(
        {
          title: "Could not retrieve jobs",
          message: e.message,
        },
        () => dispatch(getAllJobs(options)),
      ),
    );
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const getJobById = (
  id: number,
  options?: ServiceOptions,
): AppThunk => async (dispatch) => {
  try {
    dispatch(setIsLoading(true));

    const { data } = await jobService.getById(id, options);

    dispatch(setJob(data));
  } catch (err) {
    dispatch(
      error(
        {
          title: "Could not retrieve Job information. Please try again.",
          message: err.message,
        },
        () => dispatch(getJobById(id, options)),
      ),
    );
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const getJobDWOs = (options?: ServiceOptions): AppThunk => async (
  dispatch,
) => {
  try {
    dispatch(setIsLoadingJobDWOs(true));

    const { data } = await dwoJobService.getAll(options);

    dispatch(setJobDWOs(data));
  } catch (err) {
    dispatch(
      error(
        {
          title: "Could not retrieve Job DWOs information. Please try again.",
          message: err.message,
        },
        () => dispatch(getJobDWOs(options)),
      ),
    );
  } finally {
    dispatch(setIsLoadingJobDWOs(false));
  }
};

export const getJobForemen = (options?: ServiceOptions): AppThunk => async (
  dispatch,
) => {
  try {
    dispatch(setIsLoadingForemen(true));

    const { data } = await crewService.getAll(options);

    if (data.length > 0) {
      let crewMembers: EmployeeCrewModel[] = [];
      data.forEach((crew: CrewModel) => {
        if (crew.employeeCrews) {
          crewMembers = [...crewMembers, ...crew.employeeCrews];
        }
      });
      const crewEmployees: EmployeeCrewModel[] = crewMembers.filter(
        (member) => member.isForeman && member.isCurrent,
      );
      const jobForemen: EmployeeModel[] = crewEmployees.map(
        (foreman) => foreman.employee as EmployeeModel,
      );
      dispatch(setForemen(jobForemen));
      return;
    }

    dispatch(setForemen([]));
  } catch (err) {
    error(
      {
        title: "Could not fetch Job Foreman. Please try again.",
        message: err.message,
      },
      () => dispatch(getJobForemen(options)),
    );
  } finally {
    dispatch(setIsLoadingForemen(false));
  }
};

export const getJobHazards = (
  jobId: number,
  options?: ServiceOptions,
): AppThunk => async (dispatch, select) => {
  try {
    dispatch(setIsLoadingHazards(true));
    const { data } = await jobService.getHazardJobs(jobId, options);
    if (data.length === 0) {
      dispatch(setIsLoadingHazards(false));
      return;
    }

    const hazardsList = [];
    for (let i = 0; i < data.length; i++) {
      try {
        const { data: hazardPDF } = await dailyJobHazardService.getHazardPDF(
          Number(data[i].id),
        );
        data[i].pdf = hazardPDF;
        hazardsList.push(data[i]);
      } catch (err) {
        error(
          {
            title: "Could not retrieve Job Hazards. Please try again.",
            message: err.message,
          },
          () => dispatch(getJobHazards(jobId, options)),
        );
      }
    }
    dispatch(setIsLoadingHazards(false));
    dispatch(setHazards(hazardsList));
  } catch (err) {
    dispatch(
      error(
        {
          title: "Could not retrieve Job Hazards. Please try again.",
          message: err.message,
        },
        () => dispatch(getJobHazards(jobId, options)),
      ),
    );
  } finally {
    dispatch(setIsLoadingHazards(false));
  }
};

export const getJobInvoices = (options?: ServiceOptions): AppThunk => async (
  dispatch,
) => {
  try {
    dispatch(setIsLoadingInvoices(true));

    const { data } = await jobInvoiceService.getAll(options);

    dispatch(setInvoices(data));
  } catch (err) {
    dispatch(
      error(
        {
          title: "Could not retrieve Job Invoices. Please try again.",
          message: err.message,
        },
        () => dispatch(getJobInvoices(options)),
      ),
    );
  } finally {
    dispatch(setIsLoadingInvoices(false));
  }
};

export const updateJob = (
  body: JobModel,
  options?: ServiceOptions,
): AppThunk => async (dispatch) => {
  try {
    dispatch(setIsLoadingUpdate(true));

    await jobService.update(body.id as number, body);

    dispatch(getJobById(body.id as number, options));
  } catch (err) {
    dispatch(
      error(
        {
          title: "Could not update the job. Please try again.",
          message: err.message,
        },
        () => dispatch(updateJob(body, options)),
      ),
    );
  } finally {
    dispatch(setIsLoadingUpdate(false));
  }
};

export const updateJobInvoice = (
  id: string,
  jobInvoice: JobInvoiceModel,
): AppThunk => async (dispatch, select) => {
  try {
    dispatch(showLoader());

    const { data } = await jobInvoiceService.update(id, jobInvoice);
    const updatedInvoices = [...select().jobs.invoices];
    const foundIndex = updatedInvoices.findIndex(
      (invoice) => invoice.id === Number(id),
    );

    if (foundIndex >= 0) {
      updatedInvoices[foundIndex] = data;
      dispatch(setInvoices(updatedInvoices));
    }
  } catch (e) {
    dispatch(
      error(
        {
          title: "Could not update invoice",
          message: e.message,
        },
        () => dispatch(updateJobInvoice(id, jobInvoice)),
      ),
    );
  } finally {
    dispatch(hideLoader());
  }
};

export const selectCount = (state: RootState) => state.jobs.count;
export const selectCurrentJob = (state: RootState) => state.jobs.job;
export const selectError = (state: RootState) => state.jobs.error;
export const selectForemen = (state: RootState) => state.jobs.foremen;
export const selectHazards = (state: RootState) => state.jobs.hazards;
export const selectInvoices = (state: RootState) => state.jobs.invoices;
export const selectIsLoading = (state: RootState) => state.jobs.isLoading;
export const selectIsLoadingForemen = (state: RootState) =>
  state.jobs.isLoadingForemen;
export const selectIsLoadingHazards = (state: RootState) =>
  state.jobs.isLoadingHazards;
export const selectIsLoadingInvoices = (state: RootState) =>
  state.jobs.isLoadingInvoices;
export const selectIsLoadingJobDWOs = (state: RootState) =>
  state.jobs.isLoadingJobDWOs;
export const selectIsLoadingUpdate = (state: RootState) =>
  state.jobs.isLoadingUpdate;
export const selectJobs = (state: RootState) => state.jobs.jobs;
export const selectJobDWOs = (state: RootState) => state.jobs.jobDWOs;
export const selectLimit = (state: RootState) => state.jobs.limit;
export const selectOffset = (state: RootState) => state.jobs.offset;
export const selectOrder = (state: RootState) => state.jobs.order;
export const selectJobTabSelected = (state: RootState) =>
  state.jobs.tabSelected;
export const selectJobFilters = (state: RootState) => state.jobs.jobFilters;
export const selectJobQuery = (state: RootState) => state.jobs.jobQuery;
export const selectJobOvercostFilters = (state: RootState) =>
  state.jobs.jobOvercostFilters;
export const selectJobOvercostQuery = (state: RootState) =>
  state.jobs.jobOvercostQuery;
export default jobsSlice.reducer;
