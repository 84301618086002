import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, RootState } from "app/store";
import { error } from "features/error/errorSlice";
import { PhaseCodeModel } from "@dwo/shared/dist/models/phaseCodeModel";
import { phaseCodeService } from "@dwo/shared/dist/services/phaseCodeService";
import { ServiceOptions } from "@dwo/shared/dist/services/baseService";

interface PhaseCodeState {
  count?: number;
  error: boolean;
  loading: boolean;
  message?: string;
  phaseCode?: PhaseCodeModel;
  phaseCodes: PhaseCodeModel[];
}

const initialState: PhaseCodeState = {
  count: 0,
  error: false,
  loading: false,
  message: "",
  phaseCode: undefined,
  phaseCodes: [],
};

export const phaseCodeSlice = createSlice({
  name: "phaseCodes",
  initialState,
  reducers: {
    setCount: (state, action: PayloadAction<number>) => {
      state.count = action.payload;
    },
    setError: (state, action: PayloadAction<boolean>) => {
      state.error = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setMessage: (state, action: PayloadAction<string>) => {
      state.message = action.payload;
    },
    setPhaseCode: (state, action: PayloadAction<PhaseCodeModel>) => {
      state.phaseCode = action.payload;
    },
    setPhaseCodes: (state, action: PayloadAction<PhaseCodeModel[]>) => {
      state.phaseCodes = action.payload;
    },
  },
});

export const {
  setCount,
  setError,
  setLoading,
  setMessage,
  setPhaseCode,
  setPhaseCodes,
} = phaseCodeSlice.actions;


export const getPhaseCodeById = (
  id: number,
  options?: ServiceOptions,
): AppThunk => async (dispatch) => {
  try {
    dispatch(setLoading(true));

    const { data } = await phaseCodeService.getPhaseCodes(id, options);

    dispatch(setPhaseCodes(data));
    dispatch(setLoading(false));
  } catch (e) {
    // TODO: dispatch Toast in case of error
    dispatch(setLoading(false));
    dispatch(setError(true));
    dispatch(
      error(
        {
          title: "Could not retrieve Phase Code. Please try again.",
          message: e.message,
        },
        () => dispatch(getPhaseCodeById(id, options)),
      ),
    );
  }
};

export const selectCount = (state: RootState) => state.phaseCode.count;
export const selectError = (state: RootState) => state.phaseCode.error;
export const selectLoading = (state: RootState) => state.phaseCode.loading;
export const selectMessage = (state: RootState) => state.phaseCode.message;
export const selectPhaseCode = (state: RootState) => state.phaseCode.phaseCode;
export const selectPhaseCodes = (state: RootState) =>
  state.phaseCode.phaseCodes;

export const phaseCodeReducer = phaseCodeSlice.reducer;
