import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  AppBar,
  Box,
  Button,
  createStyles,
  Grid,
  makeStyles,
  Tab,
  Tabs,
  Theme,
  Typography,
} from "@material-ui/core";
import { DateRange } from "materialui-daterange-picker";
import { Dropdown } from "components/Dropdown";
import { DropdownOption } from "components/DropdownPagination";
import { JobPerformanceTab } from "components/summary/JobPerformanceTab";
import { Layout } from "components/layout/Layout";
import { RangeDatePicker } from "components/RangeDatePicker";
import { TimesheetTab } from "components/summary/TimesheetTab";
import { DailyRosterTab } from "components/summary/DailyRoster/DailyRosterTab";
import { selectCurrentUser } from "features/logIn/sessionSlice";
import { getAllRegions, selectRegions } from "features/region/regionSlice";
import {
  selectSummarySelectedDateRange,
  selectSummarySelectedRegion,
  selectSummaryTabSelected,
  setSummarySelectedDateRange,
  setSummarySelectedRegion,
  setSummarySelectedTab,
  setTabSelected,
  setTimesheetCalendarRangeType,
} from "features/summary/summarySlice";
import {
  getDateRangeFromStringDateRange,
  getStringDateRangeFromDateRange,
} from "utils/dateUtils";
import { getTimesheetAdjustedDateRange } from "utils/summaryTimesheetUtils";
import {
  OverviewTabs,
  parseRegionsToDropdownOptions,
  SummaryTabs,
} from "utils/summaryUtils";

const tabStyles = makeStyles((theme: Theme) =>
  createStyles({
    shadowLeft: {
      backgroundColor: theme.palette.grey[500],
      color: "white",
      fontWeight: 600,
      textTransform: "capitalize",
    },
    shadowRight: {
      backgroundColor: theme.palette.grey[500],
      color: "white",
      fontWeight: 600,
      textTransform: "capitalize",
    },
    activeTab: {
      backgroundColor: "white",
      boxShadow: "1px 1px 8px #00000029",
      color: theme.palette.primary.main,
      fontWeight: 600,
      outline: "none",
      textTransform: "capitalize",
    },
    tabsContainer: {
      backgroundColor: "transparent",
      boxShadow: "none",
      margin: "0 24px 24px 24px",
      width: "fit-content",
      zIndex: 1,
      "& .MuiTabs-root": {
        minHeight: "auto",
      },
      "& .MuiTabs-flexContainer > button": {
        minHeight: "32px",
        minWidth: "166px",
      },
      [theme.breakpoints.down("xs")]: {
        "& .MuiTabs-flexContainer > button": {
          minWidth: "fit-content",
        },
      },
    },
    indicator: {
      display: "none",
      outline: "none",
    },
  }),
);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    datePickerContainer: {
      maxWidth: "273px",
      width: "100%",
      [theme.breakpoints.down("xs")]: {
        margin: "0 0 32px 0",
        maxWidth: "100%",
      },
    },
    regionDateContainer: {
      display: "flex",
      height: "100%",
      justifyContent: "flex-end",
      margin: "32px 24px 32px 0",
      [theme.breakpoints.down("sm")]: {
        margin: "0 24px 32px 24px",
        justifyContent: "flex-start",
      },
      [theme.breakpoints.down("xs")]: {
        flexDirection: "column",
      },
    },
    regionDropdownContainer: {
      marginRight: "24px",
      maxWidth: "174px",
      width: "100%",
      [theme.breakpoints.down("xs")]: {
        margin: "0 0 24px 0",
        maxWidth: "100%",
      },
    },
    welcomeText: {
      color: theme.palette.grey[700],
      fontSize: "2.5rem",
      fontWeight: 300,
      margin: "32px 24px",
    },
    fakeLinkText: {
      color: "#2D72E0",
      fontSize: "14px",
      fontWeight: 400,
      textDecoration: "underline",
      cursor: "pointer",
    },
    clearFiltersContainer: {
      width: "120px",
      marginLeft: "24px",
    },
    clearFiltersButton: {
      backgroundColor: "#2D72E0",
      padding: "0",
      width: "100%",
    },
  }),
);

export function SummaryScreen() {
  const dispatch = useDispatch();
  const fetchedRegions = useSelector(selectRegions);
  const fetchedUser = useSelector(selectCurrentUser);
  const currentDateRange = useSelector(selectSummarySelectedDateRange);
  const currentRegion = useSelector(selectSummarySelectedRegion);
  const tabSelected = useSelector(selectSummaryTabSelected);
  const [activeTab, setActiveTab] = useState<SummaryTabs>(SummaryTabs.Roster);
  const classes = useStyles();
  const tabClasses = tabStyles();

  const firstName = fetchedUser?.employee.firstName || "User";
  const regionsOptions: DropdownOption[] =
    parseRegionsToDropdownOptions(fetchedRegions) || [];

  const handleApplyDateRange = useCallback(
    (selectedRange: DateRange) => {
      const { startDate, endDate } = selectedRange;

      if (!startDate || !endDate) return;

      if (activeTab === SummaryTabs.Timesheet) {
        const {
          startDate: updatedStart,
          endDate: updatedEnd,
          calendarRangeType,
        } = getTimesheetAdjustedDateRange(startDate, endDate);
        const stringDateRange = getStringDateRangeFromDateRange({
          startDate: updatedStart,
          endDate: updatedEnd,
        });

        dispatch(setSummarySelectedDateRange(stringDateRange));
        dispatch(setTimesheetCalendarRangeType(calendarRangeType));
        dispatch(setSummarySelectedTab(OverviewTabs.LeftTab));

        return;
      }

      const stringDateRange = getStringDateRangeFromDateRange({
        startDate,
        endDate,
      });

      dispatch(setSummarySelectedDateRange(stringDateRange));
    },
    [activeTab, dispatch],
  );

  useEffect(() => {
    setActiveTab(tabSelected);
    dispatch(getAllRegions());
    // eslint-disable-next-line
  }, [dispatch]);

  useEffect(() => {
    if (!currentDateRange) {
      handleApplyDateRange({ startDate: new Date(), endDate: new Date() });
    }
  }, [currentDateRange, handleApplyDateRange]);

  const getA11yProps = (index: number) => {
    return {
      id: `summary-tab-${index}`,
      "aria-controls": `tabPanel-${index}`,
    };
  };

  const getGradients = (index: number) => {
    if (index === 0) {
      return tabClasses.shadowLeft;
    }
    return tabClasses.shadowRight;
  };

  const handleChangeTabs = (
    _event: React.ChangeEvent<unknown>,
    selectedTab: number,
  ) => {
    if (selectedTab === activeTab) return;
    dispatch(setTabSelected(selectedTab));
    setActiveTab(selectedTab);
  };

  const handleSelectRegion = (selectedRegion: string) => {
    dispatch(setSummarySelectedRegion(selectedRegion));
  };

  const handleClickClearFilters = () => {
    dispatch(setSummarySelectedDateRange(undefined));
    dispatch(setSummarySelectedRegion(""));
  };

  return (
    <Layout>
      <Grid container>
        <Grid item md={4} xs={12}>
          <Typography
            className={classes.welcomeText}
            component="h1"
            variant="h4"
          >
            {`Hi ${firstName}, Welcome!`}
          </Typography>
        </Grid>

        <Grid item md={8} xs={12}>
          <Box className={classes.regionDateContainer}>
            <Box className={classes.regionDropdownContainer}>
              <Dropdown
                id="summary-regionDropdown"
                label="By Region"
                options={regionsOptions}
                selectedValue={currentRegion}
                onSelectOption={handleSelectRegion}
              />
            </Box>
            <Box className={classes.datePickerContainer}>
              {currentDateRange && (
                <RangeDatePicker
                  clearWithTodaysDate
                  initDateRange={getDateRangeFromStringDateRange(
                    currentDateRange,
                  )}
                  onClickApply={handleApplyDateRange}
                />
              )}
            </Box>
            <Box className={classes.clearFiltersContainer}>
              <Button
                variant="contained"
                className={classes.clearFiltersButton}
                onClick={handleClickClearFilters}
              >
                Clear Filters
              </Button>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <AppBar className={tabClasses.tabsContainer} position="static">
            <Tabs
              classes={{ indicator: tabClasses.indicator }}
              value={activeTab}
              onChange={handleChangeTabs}
            >
              <Tab
                classes={{ selected: tabClasses.activeTab }}
                className={getGradients(activeTab)}
                label="Job Performance"
                {...getA11yProps(0)}
              />

              <Tab
                classes={{ selected: tabClasses.activeTab }}
                className={getGradients(activeTab)}
                label="Timesheet"
                {...getA11yProps(1)}
              />

              <Tab
                classes={{ selected: tabClasses.activeTab }}
                className={getGradients(activeTab)}
                label="Daily Roster"
                {...getA11yProps(2)}
              />
            </Tabs>
          </AppBar>
        </Grid>

        {activeTab === SummaryTabs.Job && <JobPerformanceTab />}
        {activeTab === SummaryTabs.Timesheet && <TimesheetTab />}
        {activeTab === SummaryTabs.Roster && <DailyRosterTab />}
      </Grid>
    </Layout>
  );
}
