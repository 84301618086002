import { JobsSummaryData } from "@dwo/shared/dist/models/jobSummaryModel";
import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Tooltip,
  Typography,
} from "@material-ui/core";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import React from "react";
import { SummaryJobsOverDataTooltip } from "./SummaryJobsOverDataTooltip";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: theme.palette.grey[100],
      display: "flex",
      flexDirection: "column",
      height: "100%",
      justifyContent: "center",
      padding: "12px",
    },
    descriptionText: {
      color: theme.palette.grey[500],
    },
    dotsContainer: {
      padding: "2px",
      position: "absolute",
      right: "-20px",
      top: "-5px",
    },
    fiberIconOvercost: {
      color: theme.palette.warning.light,
      fontSize: "12px",
      position: "relative",
      right: "-7px",
      top: 0,
    },
    fiberIconOvertime: {
      color: theme.palette.error.main,
      fontSize: "12px",
      position: "relative",
      right: 0,
      top: 0,
    },
    tooltip: {
      alignItems: "center",
      backgroundColor: theme.palette.grey[100],
      borderRadius: "2px",
      boxShadow: "4px 4px 8px #00000029",
      display: "flex",
      flexDirection: "column",
      padding: 0,
    },
    totalNumberText: {
      color: theme.palette.grey[700],
      fontSize: "2rem",
      fontWeight: 600,
      lineHeight: "49px",
    },
  }),
);

interface JobSummaryCardProps {
  data: JobsSummaryData;
  description: string;
}

export function JobInfoCard({ data, description }: JobSummaryCardProps) {
  const classes = useStyles();

  const { total, overcost, overtime } = data;
  const displayingOvercost = overcost > 0;
  const displayingOvertime = overtime > 0;

  return (
    <Box className={classes.container}>
      <Box margin="0 auto" position="relative" width="fit-content">
        <Typography
          align="center"
          className={classes.totalNumberText}
          variant="h4"
        >
          {total}
        </Typography>
        {(displayingOvercost || displayingOvertime) && (
          <Tooltip
            classes={{ tooltip: classes.tooltip }}
            interactive
            placement="bottom"
            title={
              <SummaryJobsOverDataTooltip
                overcostJobs={overcost}
                overtimeJobs={overtime}
              />
            }
          >
            <Box className={classes.dotsContainer}>
              {displayingOvercost && (
                <FiberManualRecordIcon className={classes.fiberIconOvercost} />
              )}
              {displayingOvertime && (
                <FiberManualRecordIcon className={classes.fiberIconOvertime} />
              )}
            </Box>
          </Tooltip>
        )}
      </Box>
      <Typography
        align="center"
        className={classes.descriptionText}
        variant="body2"
      >
        {description}
      </Typography>
    </Box>
  );
}
