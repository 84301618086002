import React from "react";
import { SvgIcon } from "@material-ui/core";

interface SortArrowDownIconProps {
  color?: string;
  fontSize?: number;
}

export const SortArrowDownIcon = ({
  color = "black",
  fontSize,
}: SortArrowDownIconProps) => {
  return (
    <SvgIcon style={{ fontSize: fontSize }}>
      <g id="Group_2731" data-name="Group 2731" transform="translate(358 7278)">
        <rect
          id="Rectangle_2116"
          data-name="Rectangle 2116"
          width="24"
          height="24"
          transform="translate(-358 -7278)"
          fill="none"
        />
        <g
          id="Group_2436"
          data-name="Group 2436"
          transform="translate(-353 -7275)"
        >
          <g
            id="Group_2435"
            data-name="Group 2435"
            transform="translate(0 10.636)"
          >
            <path
              id="Icon_ionic-md-arrow-dropdown"
              data-name="Icon ionic-md-arrow-dropdown"
              d="M9,13.5l7.363,7.363L23.727,13.5Z"
              transform="translate(-9 -13.5)"
              fill={color}
            />
          </g>
          <path
            id="Icon_ionic-md-arrow-dropup"
            data-name="Icon ionic-md-arrow-dropup"
            d="M9,20.863,16.363,13.5l7.363,7.363Z"
            transform="translate(-9 -13.5)"
            fill="#838385"
          />
        </g>
      </g>
    </SvgIcon>
  );
};
