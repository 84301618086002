import { DownloadJobsModel } from "@dwo/shared/dist/models/downloadJobsModel";
import { DownloadFileIcon } from "components/upload/DownloadFileIcon";
import React from "react";
import { slashFormatDate } from "./dateUtils";
import { MainTableCell, MainTableColumn, MainTableRow } from "./tableUtils";

export const downloadJobsColumns: MainTableColumn[] = [
  { field: "fileName", hasSorting: true, headerName: "Name" },
  { field: "date", hasSorting: true, headerName: "Date Created" },
  { field: "download", headerName: "Download" },
];

export const getDownloadJobsRows = (
  downloadJobs: DownloadJobsModel[],
  onClickDownload: (fileId: number) => void,
): MainTableRow[] =>
  downloadJobs.map((dJobs: DownloadJobsModel) => {
    const name: MainTableCell = {
      value: dJobs.fileName,
    };
    const createDate: MainTableCell = {
      value: slashFormatDate(new Date(dJobs.date)),
    };
    const download: MainTableCell = {
      value: (
        <DownloadFileIcon
          id={dJobs.id as number}
          onClickDownload={onClickDownload}
        />
      ),
    };

    const innerCells = [name, createDate, download];

    return {
      innerCells,
    };
  });
