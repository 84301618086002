import {
  Avatar,
  Box,
  Button,
  createStyles,
  Divider,
  makeStyles,
  TextField,
  Theme,
  Typography,
} from "@material-ui/core";
import { DeleteIconButton } from "components/DeleteIconButton";
import { EditIconButton } from "components/icons/EditIconButton";
import React, { Fragment, useState } from "react";

interface NoteElementProps {
  author: string;
  id?: string;
  date?: Date | string;
  isNew?: boolean;
  jobId?: string;
  picture: string;
  role: string;
  noteText?: string;
  noteTitle?: string;
  onClickCancel?: () => void;
  onClickDelete?: (id: string) => void;
  onClickSave?: (noteText: string, noteTitle: string, id?: string) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    author: {
      color: theme.palette.grey[600],
      fontWeight: "bold",
    },
    avatar: {
      height: "40px",
      width: "40px",
    },
    noteTitle: {
      color: theme.palette.grey[500],
      fontWeight: "bold",
    },
    cancelButton: {
      maxWidth: "174px",
      marginRight: "16px",
      width: "100%",
    },
    noteText: {
      color: theme.palette.grey[500],
    },
    textField: {
      borderRadius: "2px",
      height: "100%",
      "& .MuiInput-input": {
        color: theme.palette.grey[600],
        fontSize: "0.875rem",
        padding: "16px 24px 8px 24px",
      },
      "& .MuiInput-underline": {
        borderBottom: `1px solid ${theme.palette.primary.main}`,
        "&:hover": {
          borderBottom: `1px solid ${theme.palette.primary.main}`,
        },
      },
    },
    role: {
      color: theme.palette.grey[500],
      fontWeight: "bold",
    },
    date: {
      color: theme.palette.grey[400],
      fontWeight: "bold",
    },
    saveButton: {
      maxWidth: "174px",
      width: "100%",
    },
  }),
);

const NOTE_MAX_LENGTH = 255;
const NOTE_TITLE_MAX_LENGTH = 18;

export function NoteElement({
  author,
  id,
  date,
  isNew,
  jobId,
  picture,
  role,
  noteText = "Description",
  noteTitle = "Note Title",
  onClickCancel,
  onClickDelete,
  onClickSave,
}: NoteElementProps) {
  const [inputNoteText, setInputNoteText] = useState<string>(noteText);
  const [inputNoteTitle, setInputNoteTitle] = useState<string>(noteTitle);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const classes = useStyles();

  const handleChangeTextArea = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setInputNoteText(value);
  };

  const handleChangeNoteTitle = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setInputNoteTitle(value);
  };

  const handleClickCancel = () => {
    if (isNew && onClickCancel) {
      onClickCancel();
    }

    setInputNoteText(noteText);
    setIsEditing(false);
  };

  const handleClickDelete = () => {
    if (onClickDelete) {
      onClickDelete(id as string);
    }
  };

  const handleClickEdit = () => setIsEditing(true);

  const handleClickSave = () => {
    if (onClickSave) {
      onClickSave(inputNoteText, inputNoteTitle, id);
    }
  };

  return (
    <Box display="flex" flexDirection="column" paddingTop="32px">
      <Box alignItems="center" display="flex">
        <Avatar alt={author} className={classes.avatar} src={picture} />
        <Box
          alignItems="center"
          display="flex"
          marginLeft="24px"
          justifyContent="space-between"
          width="100%"
        >
          <Box display="flex" flexDirection="column">
            <Typography className={classes.author} variant="body2">
              {author}
            </Typography>
            <Typography className={classes.role} variant="caption">
              {role}
            </Typography>
            <Typography className={classes.date} variant="caption">
              {date}
            </Typography>
          </Box>
          {jobId && (
            <Typography
              className={classes.author}
              variant="body2"
            >{`Job ID: ${jobId}`}</Typography>
          )}
        </Box>
      </Box>

      {isEditing || isNew ? (
        <Fragment>
          <Box paddingTop="24px">
            <TextField
              className={classes.noteTitle}
              rows={1}
              value={inputNoteTitle}
              onChange={handleChangeNoteTitle}
              inputProps={{
                maxLength: NOTE_TITLE_MAX_LENGTH,
              }}
            />
            <TextField
              className={classes.textField}
              multiline
              rows={5}
              value={inputNoteText}
              onChange={handleChangeTextArea}
              inputProps={{
                maxLength: NOTE_MAX_LENGTH,
              }}
            />
          </Box>

          <Box display="flex" justifyContent="flex-end" padding="24px 0">
            <Button
              className={classes.cancelButton}
              variant="outlined"
              onClick={handleClickCancel}
            >
              Cancel
            </Button>
            <Button
              className={classes.saveButton}
              disabled={
                inputNoteText.length === 0 || inputNoteText === noteText
              }
              variant="contained"
              onClick={handleClickSave}
            >
              Save
            </Button>
          </Box>
        </Fragment>
      ) : (
        <Fragment>
          <Box paddingTop="24px">
            <Typography className={classes.noteTitle} variant="body1">
              {noteTitle}
            </Typography>
            <Typography className={classes.noteText} variant="body2">
              {noteText}
            </Typography>
          </Box>

          <Box
            alignItems="center"
            display="flex"
            justifyContent="flex-end"
            padding="24px 0"
          >
            <EditIconButton onClickEdit={handleClickEdit} />
            <Box marginLeft="24px">
              <DeleteIconButton
                idToDelete={id as string}
                fontSize="default"
                height="32px"
                width="32px"
                onClickDelete={handleClickDelete}
              />
            </Box>
          </Box>
        </Fragment>
      )}

      <Divider />
    </Box>
  );
}
