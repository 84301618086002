import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import React from "react";

interface JobNoteProps {
  author: string;
  date: string;
  jobId?: string;
  text: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    author: {
      color: theme.palette.grey[700],
      fontWeight: "bold",
      marginRight: "16px",
    },
    date: {
      color: theme.palette.grey[400],
    },
    jobId: {
      color: theme.palette.grey[500],
      fontWeight: "bold",
      marginRight: "16px",
    },
    text: {
      color: theme.palette.grey[500],
    },
  }),
);

export function JobNote({ author, date, jobId, text }: JobNoteProps) {
  const classes = useStyles();

  return (
    <Box marginTop="24px">
      <Box display="flex" flexWrap="wrap" justifyContent="space-between">
        <Typography className={classes.author} variant="body2">
          {author}
        </Typography>
        <Typography className={classes.date} variant="body2">
          {date}
        </Typography>
      </Box>
      {jobId && (
        <Box paddingTop="8px">
          <Typography className={classes.jobId} variant="body2">
            {`Job ID: ${jobId}`}
          </Typography>
        </Box>
      )}
      <Box paddingTop="16px">
        <Typography className={classes.text} variant="body2">
          {text}
        </Typography>
      </Box>
    </Box>
  );
}
