import {
  AppBar,
  Box,
  createStyles,
  Grid,
  makeStyles,
  Paper,
  Tab,
  Tabs,
  Theme,
} from "@material-ui/core";
import { Summary } from "components/jobDetails/summary";
import { Workforce } from "components/jobDetails/workforce/Workforce";
import { TabContent } from "components/userManagement/UserDetails/TabContent";
import React, { Fragment } from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      backgroundColor: "white",
      borderRadius: "0px",
      margin: "0 24px 24px 24px",
      padding: "24px",
      position: "relative",
      zIndex: 2,
    },
    shadowLeft: {
      backgroundImage:
        "linear-gradient(270deg, rgba(131,131,133,1) 90%, rgba(31,31,31,1) 100%, rgba(0,0,0,1) 100%)",
      bottom: "8px",
      color: "white",
      font: `normal normal bold 14px/19px ${theme.typography.fontFamily}`,
      textTransform: "capitalize",
    },
    shadowRight: {
      backgroundImage:
        "linear-gradient(90deg, rgba(131,131,133,1) 90%, rgba(31,31,31,1) 100%, rgba(0,0,0,1) 100%)",
      bottom: "8px",
      color: "white",
      font: `normal normal bold 14px/19px ${theme.typography.fontFamily}`,
      textTransform: "capitalize",
    },
    activeTab: {
      backgroundImage:
        "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(0,0,0,1) 0%, rgba(255,255,255,1) 0%)",
      bottom: "8px",
      boxShadow:
        "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
      color: theme.palette.primary.dark,
      font: `normal normal bold 14px/19px ${theme.typography.fontFamily}`,
      outline: "none",
      textTransform: "capitalize",
    },
    tabsContainer: {
      boxShadow: "none",
      marginLeft: "24px",
      width: "fit-content",
      height: "40px",
      [theme.breakpoints.down("xs")]: {
        width: "180px",
      },
      zIndex: 1,
    },
    indicator: {
      display: "none",
      outline: "none",
    },
  }),
);

interface UserDetailsTabsProps {
  numOfTabs?: number;
  onClickCarButton: (foremanId: number) => void;
}

export function JobDetailsTabs({
  numOfTabs = 2,
  onClickCarButton,
}: UserDetailsTabsProps) {
  const [activeTab, setActiveTab] = React.useState(0);
  const classes = useStyles();

  const getA11yProps = (index: number) => {
    return {
      id: `tab-${index}`,
      "aria-controls": `tabPanel-${index}`,
    };
  };

  const getGradients = (index: number) => {
    if (index === 0) {
      return classes.shadowLeft;
    }
    return classes.shadowRight;
  };

  const handleChangeTabs = (
    event: React.ChangeEvent<unknown>,
    newValue: number,
  ) => {
    setActiveTab(newValue);
  };

  return (
    <Fragment>
      <Grid alignItems="center" container spacing={0}>
        <Grid item xs={4}>
          <Box height="32px">
            <AppBar className={classes.tabsContainer} position="relative">
              <Tabs
                classes={{ indicator: classes.indicator }}
                value={activeTab}
                onChange={handleChangeTabs}
                aria-label="User details tabs"
              >
                <Tab
                  classes={{ selected: classes.activeTab }}
                  className={getGradients(activeTab)}
                  label="Summary"
                  {...getA11yProps(0)}
                />
                {numOfTabs >= 2 && (
                  <Tab
                    classes={{ selected: classes.activeTab }}
                    className={getGradients(activeTab)}
                    label="Workforce"
                    {...getA11yProps(1)}
                  />
                )}
              </Tabs>
            </AppBar>
          </Box>
        </Grid>
      </Grid>
      <Paper className={classes.paper}>
        <TabContent index={0} value={activeTab}>
          <Summary />
        </TabContent>
        <TabContent index={1} value={activeTab}>
          <Workforce onClickCarButton={onClickCarButton} />
        </TabContent>
      </Paper>
    </Fragment>
  );
}
