import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, RootState } from "app/store";

export interface ErrorConfig {
  title: string;
  message?: string;
  hideCancelButton?: boolean;
  actionButtonText?: string;
}

interface ErrorState {
  title: string;
  message?: string;
  hideCancelButton?: boolean;
  actionButtonText: string;
  show: boolean;
  response: boolean;
}

const initialState: ErrorState = {
  title: "",
  message: undefined,
  hideCancelButton: false,
  actionButtonText: "Retry",
  show: false,
  response: false,
};

export const errorSlice = createSlice({
  name: "error",
  initialState,
  reducers: {
    show: (state) => {
      state.show = true;
    },
    hide: (state) => {
      state.show = false;
      state.actionButtonText = "Retry";
    },
    setResponse: (state, action: PayloadAction<boolean>) => {
      state.response = action.payload;
    },
    configure: (state, action: PayloadAction<ErrorConfig>) => {
      const {
        title,
        message,
        actionButtonText,
        hideCancelButton,
      } = action.payload;
      state.title = title;
      state.message = message;
      state.hideCancelButton = hideCancelButton || false;
      if (actionButtonText)
        state.actionButtonText = actionButtonText || "Retry";
    },
  },
});

export const { show, hide, configure, setResponse } = errorSlice.actions;

export const error = (
  config: ErrorConfig,
  retryCallback: VoidFunction,
): AppThunk => (dispatch, getState) => {
  dispatch(configure(config));
  dispatch(show());
  const retryInterval = setInterval(() => {
    const doShow = selectShow(getState());
    if (!doShow) {
      const shouldRetry = selectResponse(getState());
      if (shouldRetry) {
        retryCallback();
      }
      clearInterval(retryInterval);
    }
  }, 100);
};

export const selectShow = (state: RootState) => state.error.show;
export const selectTitle = (state: RootState) => state.error.title;
export const selectMessage = (state: RootState) => state.error.message;
export const selectHideCancelButton = (state: RootState) =>
  state.error.hideCancelButton;
export const selectResponse = (state: RootState) => state.error.response;
export const selectActionButtonText = (state: RootState) =>
  state.error.actionButtonText;

export const errorReducer = errorSlice.reducer;
