import { AppThunk, RootState } from "app/store";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";

import { DEFAULT_LIMIT } from "utils/sharedUtils";
import { DailyRosterModel } from "@dwo/shared/dist/models/dailyRosterModel";
import { dailyRosterService } from "@dwo/shared/dist/services/dailyRosterService";
import { ServiceOptions } from "@dwo/shared/dist/services/baseService";
import { error } from "features/error/errorSlice";

interface DailyRosterState {
  count: number;
  dailyRoster: DailyRosterModel[];
  isLoading: boolean;
  limit?: number;
  offset?: number;
  order?: any;
}

const initialState: DailyRosterState = {
  count: 0,
  dailyRoster: [],
  isLoading: false,
  limit: DEFAULT_LIMIT,
  offset: 0,
  order: undefined,
};

export const dailyRosterSlice = createSlice({
  name: "dailyRoster",
  initialState,
  reducers: {
    setCount: (state, action: PayloadAction<number>) => {
      state.count = action.payload;
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setDailyRoster: (state, action: PayloadAction<DailyRosterModel[]>) => {
      state.dailyRoster = cloneDeep(action.payload);
    },
    setLimit: (state, action: PayloadAction<number>) => {
      state.limit = action.payload;
    },
    setOffset: (state, action: PayloadAction<number>) => {
      state.offset = action.payload;
    },
    setOrder: (state, action: PayloadAction<any>) => {
      state.order = action.payload;
    },
  },
});

export const {
  setCount,
  setIsLoading,
  setDailyRoster,
  setLimit,
  setOffset,
  setOrder,
} = dailyRosterSlice.actions;

export const getDailyRoster = (
  region: any,
  date: any,
  limit: number,
  offset: number,
): AppThunk => async (dispatch) => {
  try {
    dispatch(setIsLoading(true));

    const {
      // count,
      data,
      // limit,
      // offset,
      // order,
    } = await dailyRosterService.getRoster(region, date, limit, offset);

    // dispatch(setCount(count));
    dispatch(setDailyRoster(data));
    // dispatch(setLimit(limit));
    // dispatch(setOffset(offset));
    // dispatch(setOrder(order));
  } catch (e) {
    dispatch(
      error(
        {
          title: "Could not retrieve Daily Roster.",
          message: e.message,
        },
        () => dispatch(getDailyRoster(region, date, limit, offset)),
      ),
    );
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const selectCount = (state: RootState) => state.dailyRoster.count;
export const selectIsLoadingRoster = (state: RootState) =>
  state.dailyRoster.isLoading;
export const selectDailyRoster = (state: RootState) =>
  state.dailyRoster.dailyRoster;
export const selectLimit = (state: RootState) => state.dailyRoster.limit;
export const selectOffset = (state: RootState) => state.dailyRoster.offset;
export const selectOrder = (state: RootState) => state.dailyRoster.order;

export const dailyRosterReducer = dailyRosterSlice.reducer;
