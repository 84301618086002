import { createStyles, makeStyles, Theme } from "@material-ui/core";
import React from "react";
import { TooltipLabelOption } from "utils/summaryUtils";
import { v4 as uuid } from "uuid";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: theme.palette.grey[100],
      borderRadius: "3px",
      boxShadow: "4px 4px 8px #00000036",
      display: "flex",
      flexDirection: "column",
      fontFamily: "Noto Sans",
      padding: "8px",
    },
    label: {
      fontSize: "0.75rem",
      fontWeight: 600,
      textAlign: "center",
      width: "100%",
    },
  }),
);

export interface PieChartTooltipProps {
  options: TooltipLabelOption[];
}

export function PieChartTooltip({ options }: PieChartTooltipProps) {
  const classes = useStyles();

  // I'm using HTML elements instead of material-ui ones 'cuz Typography was causing a weird bug
  // where, after the tooltip appeared, it changed all the MUI H tags font-family from Noto Sans to
  // Roboto. Using MUI makeStyles seems to work ok.
  return (
    <div className={classes.container}>
      {options.map((option: TooltipLabelOption) => (
        // In line styling 'cuz some pie chart have multiple colors, and so we can reuse this component
        <span
          className={classes.label}
          key={`tooltip-${uuid()}`}
          style={{ color: option.color }}
        >
          {option.label}
        </span>
      ))}
    </div>
  );
}
