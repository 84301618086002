import { GlobalModal } from "components/globalModal/GlobalModal";
import { TableCustom } from "components/table/TableCustom";
import { selectCrewsReqStatus } from "features/crew/crewSlice";
import { updateEmployeeCrew } from "features/employee_crew/employeeCrewSlice";
import { noop } from "lodash/fp";
import { EmployeeCrewModel } from "@dwo/shared/dist/models/employeeCrewModel";
import { EmployeeModel } from "@dwo/shared/dist/models/employeeModel";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ServiceOptions } from "@dwo/shared/dist/services/baseService";
import {
  assignDriverModalColumns,
  getAssignDriverModalRows,
} from "utils/jobDetailsUtils";
import { ReqStatus } from "utils/sharedUtils";

interface AssignDriverModalProps {
  availableDrivers: EmployeeModel[];
  doesCrewHaveDriver: boolean;
  isOpen: boolean;
  employeeCrews: EmployeeCrewModel[];
  selectedValue: string;
  getAllCrewsQuery: () => ServiceOptions;
  handleChangeValue: (idRow: string, isChecked: boolean) => void;
  onToggle: VoidFunction;
}

export function AssignDriverModal({
  availableDrivers,
  doesCrewHaveDriver,
  isOpen,
  employeeCrews,
  selectedValue,
  getAllCrewsQuery,
  handleChangeValue,
  onToggle,
}: AssignDriverModalProps) {
  const dispatch = useDispatch();
  const crewsReqStatus = useSelector(selectCrewsReqStatus);
  const isLoading = crewsReqStatus === ReqStatus.PENDING;
  const handleClickSaveDriver = () => {
    if (selectedValue && employeeCrews.length > 0) {
      const selectedEmployeeCrew = employeeCrews.find(
        (employeeCrew) => employeeCrew.employeeId === Number(selectedValue),
      );
      if (selectedEmployeeCrew) {
        const updatedEmployeeCrew = { ...selectedEmployeeCrew };
        updatedEmployeeCrew.assignedAt = new Date();
        updatedEmployeeCrew.isDriver = true;

        employeeCrews
          .filter((employeeCrew) => employeeCrew.isDriver)
          .forEach((employeeCrew) => {
            if (employeeCrew.isDriver && employeeCrew.id) {
              dispatch(
                updateEmployeeCrew(employeeCrew.id, {
                  ...employeeCrew,
                  isDriver: false,
                }),
              );
            }
          });
        dispatch(
          updateEmployeeCrew(
            updatedEmployeeCrew.id as number,
            updatedEmployeeCrew,
            true,
            getAllCrewsQuery(),
          ),
        );
        onToggle();
      }
    }
  };
  return (
    <GlobalModal
      open={isOpen}
      primaryLabel={doesCrewHaveDriver ? "Update" : "Assign"}
      title="Assign Driver"
      onClickContinue={handleClickSaveDriver}
      onClickClose={onToggle}
    >
      <TableCustom
        columns={assignDriverModalColumns}
        isLoading={isLoading}
        noRowsMessage="No drivers found"
        rows={getAssignDriverModalRows(
          availableDrivers,
          selectedValue,
          handleChangeValue,
        )}
        onClickSort={noop}
      />
    </GlobalModal>
  );
}
