import {
  Box,
  createStyles,
  makeStyles,
  Paper,
  Theme,
  Typography,
  useTheme,
} from "@material-ui/core";
import Chart, { ChartTooltipModel } from "chart.js";
import React, { useEffect, useRef } from "react";
import {
  createCustomPieChartTooltip,
  getTypeOfJobData,
  JobCategoriesColors,
  TooltipLabelOption,
} from "utils/summaryUtils";
import { renderToString } from "react-dom/server";
import { PieChartTooltip } from "./PieChartTooltip";
import { LabelDescriptionItem } from "./LabelDescriptionItem";
import { selectSummaryGeneralData } from "features/summary/summarySlice";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chartContainer: {
      maxWidth: "153px",
      [theme.breakpoints.down("md")]: {
        margin: "0 auto 24px auto",
      },
    },
    container: {
      display: "flex",
      flexDirection: "column",
      margin: "0 24px 24px 0",
      padding: "24px 32px",
      [theme.breakpoints.down("xs")]: {
        margin: "0 24px 24px 24px",
      },
    },
    content: {
      alignItems: "center",
      display: "flex",
      [theme.breakpoints.down("md")]: {
        flexDirection: "column",
      },
    },
    labelsContainer: {
      marginLeft: "40px",
      "& > div:first-child": {
        marginBottom: "10px",
      },
      [theme.breakpoints.down("lg")]: {
        marginLeft: "20px",
      },
      [theme.breakpoints.down("md")]: {
        margin: "0 auto",
      },
    },
    title: {
      color: theme.palette.primary.main,
    },
  }),
);

export function SummaryJobsTypesCard() {
  const chartCanvasRef = useRef({} as HTMLCanvasElement);
  const summaryData = useSelector(selectSummaryGeneralData);
  const classes = useStyles();
  const theme = useTheme();

  const { maintenanceJobs, newJobs } = getTypeOfJobData(summaryData);
  const tooltipId = "jobTypes-tooltip";
  const tooltipOptions: TooltipLabelOption[] = [
    {
      color: theme.palette.primary.dark,
      label: `${maintenanceJobs} Maintenance`,
    },
    {
      color: JobCategoriesColors.UgElectric,
      label: `${newJobs} New Job`,
    },
  ];

  const maintenanceLabel: TooltipLabelOption = {
    color: theme.palette.primary.dark,
    label: "Maintenance Services",
  };

  const newJobLabel: TooltipLabelOption = {
    color: JobCategoriesColors.UgElectric,
    label: "New Job",
  };

  useEffect(() => {
    const noJobs = maintenanceJobs === 0 && newJobs === 0;

    new Chart(chartCanvasRef.current, {
      data: {
        datasets: [
          {
            backgroundColor: [
              noJobs ? theme.palette.grey[300] : JobCategoriesColors.UgElectric,
              theme.palette.primary.dark,
            ],
            borderWidth: 0,
            data: [noJobs ? 1 : newJobs, maintenanceJobs],
          },
        ],
      },
      options: {
        animation: {
          animateRotate: true,
        },
        aspectRatio: 1,
        hover: {
          mode: undefined,
        },
        rotation: 0.25 * Math.PI,
        tooltips: {
          custom: (tooltipModel: ChartTooltipModel) => {
            const customTooltipHTMLString = renderToString(
              <PieChartTooltip options={tooltipOptions} />,
            );

            createCustomPieChartTooltip(
              tooltipModel,
              tooltipId,
              customTooltipHTMLString,
              chartCanvasRef,
            );
          },
          enabled: false,
        },
      },
      type: "pie",
    });
  }, [maintenanceJobs, theme.palette, tooltipOptions, newJobs]);

  return (
    <Paper className={classes.container}>
      <Box width="100%" marginBottom="35px">
        <Typography className={classes.title} variant="h6">
          Type of Job
        </Typography>
      </Box>

      <Box className={classes.content}>
        <Box className={classes.chartContainer}>
          <canvas ref={chartCanvasRef} />
          <div id={tooltipId} />
        </Box>

        <Box className={classes.labelsContainer}>
          <LabelDescriptionItem itemData={maintenanceLabel} />
          <LabelDescriptionItem itemData={newJobLabel} />
        </Box>
      </Box>
    </Paper>
  );
}
