import { WorkShiftModel } from "@dwo/shared/dist/models/workShiftModel";
import { createStyles, Grid, makeStyles, Paper } from "@material-ui/core";
import { LoadingSpinner } from "components/LoadingSpinner";
import {
  selectCurrentJob,
  selectDWOCrewDetails,
  selectDWOCrewDetailsByJobId,
  selectIsLoadingDWODetails,
} from "features/dwo/dwoSlice";
import { setDateRange } from "features/employeeTimesheet/employeeTimesheetSlice";
import React, { Fragment, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ROOT_TIMESHEETS_MANAGEMENT } from "routes/Roots";
import { v4 as uuid } from "uuid";
import { CrewMemberGridCard } from "./CrewMemberGridCard";

export interface DisplayCrewOverviewGridProps {
  selectedDate?: Date;
}

const useStyles = makeStyles(() =>
  createStyles({
    paper: {
      backgroundColor: "white",
      borderRadius: "0px",
      margin: "0 24px 24px 24px",
      padding: "0 24px 24px 24px",
      position: "relative",
      zIndex: 2,
    },
  }),
);

export function DisplayCrewOverviewGrid({
  selectedDate,
}: DisplayCrewOverviewGridProps) {
  const dispatch = useDispatch();
  const history = useHistory();
  const currentJob = useSelector(selectCurrentJob);
  const dwoCrewDetails = useSelector(selectDWOCrewDetails);
  const dwoCrewDetailsByJobId = useSelector(selectDWOCrewDetailsByJobId);
  const isLoading = useSelector(selectIsLoadingDWODetails);
  const classes = useStyles();

  const getAllWorkshifts = useCallback(() => {
    return (
      dwoCrewDetails?.workShifts?.filter(
        (workShift: WorkShiftModel) => workShift.employee.role !== "supervisor",
      ) || []
    );
  }, [dwoCrewDetails]);

  const getJobWorkshifts = useCallback(() => {
    return (
      dwoCrewDetailsByJobId?.workShifts?.filter(
        (workShift: WorkShiftModel) => workShift.employee.role !== "supervisor",
      ) || []
    );
  }, [dwoCrewDetailsByJobId]);

  const employeesInWorkShift = currentJob
    ? getJobWorkshifts()
    : getAllWorkshifts();

  const handleClickEdiIcon = (employeeId: number) => {
    if (selectedDate) {
      const startDate = new Date(selectedDate);
      const endDate = new Date(selectedDate);
      startDate.setHours(0, 0, 0, 0);
      endDate.setHours(23, 59, 59, 999);
      dispatch(
        setDateRange({
          startDate: startDate.toISOString(),
          endDate: endDate.toISOString(),
        }),
      );
    }
    history.push(`${ROOT_TIMESHEETS_MANAGEMENT}/employee/${employeeId}`);
  };

  return (
    <Paper className={classes.paper}>
      {isLoading && <LoadingSpinner />}
      {!isLoading && (
        <Grid container spacing={4}>
          {employeesInWorkShift.map((workshift: WorkShiftModel) => (
            <Fragment key={`${workshift.id}-${uuid()}`}>
              {workshift.employee && (
                <Grid item sm={4} xs={6}>
                  <CrewMemberGridCard
                    workshift={workshift}
                    onClickEditIcon={handleClickEdiIcon}
                  />
                </Grid>
              )}
            </Fragment>
          ))}
        </Grid>
      )}
    </Paper>
  );
}
