import React, { Fragment, useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { useSelector } from "react-redux";
import { DailyRoster } from "./DailyRoster";
import {
  selectSummarySelectedDateRange,
  selectSummarySelectedRegion,
} from "features/summary/summarySlice";
import { enDashFormatDate } from "utils/dateUtils";

export function DailyRosterTab() {
  const currentRegion = useSelector(selectSummarySelectedRegion);
  const currentDateRange = useSelector(selectSummarySelectedDateRange);
  const [isCurrentStartDate, setCurrentStartDate] = useState<any>(
    currentDateRange?.startDate,
  );

  useEffect(() => {
    if (!currentDateRange?.startDate) return;

    setCurrentStartDate(currentDateRange.startDate);
  }, [currentDateRange?.startDate]);

  return (
    <Fragment>
      <Grid item xs={12}>
        <DailyRoster
          rosterDate={
            currentDateRange?.startDate
              ? enDashFormatDate(new Date(isCurrentStartDate))
              : ""
          }
          rosterRegionId={currentRegion}
        />
      </Grid>
    </Fragment>
  );
}
