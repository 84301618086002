import { MainTableColumn, MainTableRow } from "./tableUtils";

export enum UploadDownloadTabs {
  UploadEmployees,
  DownloadTimesheets,
  DownloadVistaTimesheets,
  DownLoadJobs,
  DownloadEmployee,
}

export const csvHeaderNames = [
  "Active Status",
  "Employee",
  "Mobile",
  "Alt. Email 1",
  "Account No.",
  "Role",
  "Company Name",
  "Region",
  "Supervisor",
  "Class",
  "Earnings 1/Rate",
  "Earnings 2/Rate",
  "Earnings 3/Rate",
  "Earnings 4/Rate",
  "Earnings 5/Rate",
  "Earnings 6/Rate",
  "Vacation Available",
];

export const formatCsvRow = (str: string) => {
  const regex = /(?:[\t ]?)+("+)?(.*?)\1(?:[\t ]?)+(?:,|$)/gm;
  const row = str.match(regex);
  if (row) {
    return row.map((cell) => {
      let result = cell;
      if (cell[cell.length - 1] === ",") {
        result = cell.slice(0, -1);
      }
      return result.replace(/"/gi, "");
    });
  }
  return [];
};

export const getMissingCsvColumns = (columns: string[]) => {
  columns.length = csvHeaderNames.length;
  const missingColumns: string[] = [];
  csvHeaderNames.forEach((header) => {
    const columnFound = columns.find((column) => column === header);
    if (!columnFound) {
      missingColumns.push(header);
    }
  });
  return missingColumns;
};

export const getPreviewColumns = (headers: string[]): MainTableColumn[] =>
  headers.map((header) => ({ field: header, headerName: header }));

export const getPreviewRows = (csvRows: string[][]): MainTableRow[] =>
  csvRows.map((row) => {
    row.length = csvHeaderNames.length;
    return { innerCells: row.map((data) => ({ value: data })) };
  });
