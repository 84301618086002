import { Grid } from "@material-ui/core";
import { LoadingSpinner } from "components/LoadingSpinner";
import {
  selectEmployee,
  selectUpcomingJobs,
  assignJobToEmployee,
  getEmployeeJobs,
  removeAssignedJobFromEmployee,
  selectAvailableJobs,
  selectIsLoadingEmployeeJobs,
  selectIsRemovingJob,
  selectIsLoadingAssigningJob,
  selectAssignedjobs,
  selectCurrentJobs,
} from "features/employees/employeesSlice";
import { updateJob } from "features/jobs/jobsSlice";
import { prompt, selectResponse } from "features/prompt/promptSlice";
import { cloneDeep } from "lodash";
import { JobModel } from "@dwo/shared/dist/models/jobModel";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { JobStatusValues } from "utils/jobUtils";
import { AllocationModal } from "./AllocationModal";
import { CurrentJobInformation } from "./CurrentJobInformation";
import { UpcomingJobsInformation } from "./UpcomingJobsInformation";
import { EmployeeRole } from "@dwo/shared/dist/models/employeeModel";

export function AllocationInformation() {
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const employeeId = parseInt(id, 10);
  const assignedJobs = useSelector(selectAssignedjobs);
  const availableJobs = useSelector(selectAvailableJobs);
  const currentJobs = useSelector(selectCurrentJobs);
  const isLoadingAssigningJob = useSelector(selectIsLoadingAssigningJob);
  const isLoadingEmployeeJobs = useSelector(selectIsLoadingEmployeeJobs);
  const isRemovingJob = useSelector(selectIsRemovingJob);
  const upcomingJobs = useSelector(selectUpcomingJobs);
  const currentEmployee = useSelector(selectEmployee);
  const employeeRegions =
    currentEmployee?.regions && currentEmployee.regions[0];
  const regionId =
    typeof employeeRegions?.id === "string"
      ? parseFloat(employeeRegions.id)
      : employeeRegions?.id;
  const promptResponse = useSelector(selectResponse);
  const [activeTab, setActiveTab] = useState<number>(0);
  const [selectedJobs, setSelectedJobs] = useState<string[]>([]);
  const [deleteJobId, setDeleteJobId] = useState<number>(0);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const isLoading =
    isLoadingAssigningJob || isLoadingEmployeeJobs || isRemovingJob;

  const updateJobStatus = useCallback(
    (jobToUpdate: JobModel, status: JobStatusValues) => {
      if (currentEmployee && currentEmployee.role === EmployeeRole.Foreman) {
        const updatedJob = cloneDeep(jobToUpdate);
        updatedJob.status = status;
        dispatch(updateJob(updatedJob));
      }
    },
    [currentEmployee, dispatch],
  );

  useEffect(() => {
    dispatch(getEmployeeJobs(employeeId, regionId));
  }, [employeeId, dispatch, regionId]);

  useEffect(() => {
    if (deleteJobId !== 0 && promptResponse) {
      const foundJob = assignedJobs.find(
        (job: JobModel) => job.id === deleteJobId,
      );

      if (foundJob) {
        updateJobStatus(foundJob, JobStatusValues.UNASSIGNED);
      }

      dispatch(
        removeAssignedJobFromEmployee(deleteJobId, employeeId, regionId),
      );
      setDeleteJobId(0);
    }
  }, [
    assignedJobs,
    deleteJobId,
    employeeId,
    promptResponse,
    dispatch,
    updateJobStatus,
    regionId,
  ]);

  const handleChangeTabs = (
    _event: React.ChangeEvent<unknown>,
    selectedTab: number,
  ) => {
    setActiveTab(selectedTab);
  };

  const handleClickClose = () => {
    setSelectedJobs([]);
    setActiveTab(0);
    setIsModalOpen(false);
  };

  const handleClickCheckbox = (id: string, isChecked: boolean) => {
    if (isChecked && !selectedJobs.includes(id)) {
      setSelectedJobs((prevJobs) => [...prevJobs, id]);
    }

    if (!isChecked && selectedJobs.includes(id)) {
      setSelectedJobs((prevJobs) =>
        prevJobs.filter((jobId: string) => jobId !== id),
      );
    }
  };

  const handleClickDelete = (id: string) => {
    setDeleteJobId(parseInt(id, 10));
    dispatch(
      prompt({
        title: "Remove Job Assignment",
        message: `Are you sure you want to remove ${currentEmployee?.firstName} ${currentEmployee?.lastName} from this job?`,
      }),
    );
  };

  const handleClickEdit = () => setIsModalOpen(true);

  const handleClickSave = () => {
    if (
      availableJobs.length > 0 &&
      currentEmployee &&
      selectedJobs.length > 0
    ) {
      selectedJobs.forEach((jobId: string) => {
        const foundJob = availableJobs.find(
          (job: JobModel) => job.id === parseInt(jobId, 10),
        );

        if (foundJob) {
          updateJobStatus(foundJob, JobStatusValues.ASSIGNED);
        }

        dispatch(
          assignJobToEmployee(parseInt(jobId, 10), currentEmployee, regionId),
        );
      });
    }

    handleClickClose();
  };

  return (
    <Fragment>
      <Grid alignItems="center" container spacing={4}>
        <Grid item xs={12}>
          {isLoading && <LoadingSpinner />}
          {!isLoading && (
            <Fragment>
              <CurrentJobInformation currentJobs={currentJobs || []} />
              <UpcomingJobsInformation
                upcomingJobs={upcomingJobs}
                onClickEdit={handleClickEdit}
              />
            </Fragment>
          )}
        </Grid>
      </Grid>
      <AllocationModal
        activeTab={activeTab}
        assignedJobs={assignedJobs}
        availableJobs={availableJobs}
        isLoading={isLoading}
        isOpen={isModalOpen}
        selectedJobsIds={selectedJobs}
        onChangeTab={handleChangeTabs}
        onClickCancel={handleClickClose}
        onClickCheckbox={handleClickCheckbox}
        onClickDelete={handleClickDelete}
        onClickSave={handleClickSave}
      />
    </Fragment>
  );
}
