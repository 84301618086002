import { EmployeeCrewModel } from "@dwo/shared/dist/models/employeeCrewModel";
import { EmployeeModel } from "@dwo/shared/dist/models/employeeModel";
import { ServiceOptions } from "@dwo/shared/dist/services/baseService";
import {
  Box,
  Button,
  createStyles,
  Divider,
  Grid,
  IconButton,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import { Add, Edit, RemoveCircleOutline } from "@material-ui/icons";
import { InfoItem } from "components/hierarchy/InfoItem";
import { AssignDriverModal } from "components/jobDetails/workforce/AssignDriverModal";
import { AssignForemanModal } from "components/jobDetails/workforce/AssignForemanModal";
import { CrewAssignmentsModal } from "components/userManagement/UserDetails/CrewAssignmentsModal";
import { deleteCrew } from "features/crew/crewSlice";
import {
  createEmployeeCrew,
  deleteEmployeeCrew,
  getAllEmployeeCrews,
  selectEmployeeCrews,
} from "features/employee_crew/employeeCrewSlice";
import { selectCurrentUser } from "features/logIn/sessionSlice";
import { prompt, selectResponse } from "features/prompt/promptSlice";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      color: theme.palette.primary.dark,
      fontWeight: "bold",
    },
    remove: {
      color: theme.palette.grey["600"],
    },
    titleContainer: {
      display: "flex",
      height: "64px",
      alignItems: "center",
      justifyContent: "space-between",
      paddingRight: "24px",
    },
    icon: {
      backgroundColor: theme.palette.primary.main,
      borderRadius: "50%",
      color: "white",
      padding: "4px",
    },
    button: {
      borderRadius: "2px",
      width: "150px",
    },
  }),
);

interface WorkforceCrewProps {
  crewId: number;
  foremanId?: number;
  position: number;
  regionId?: number;
  jobId: number;
  getAllCrewsQuery: () => ServiceOptions;
  onClickCarButton: (foremanId: number) => void;
}

export function WorkforceCrew({
  crewId,
  foremanId,
  position,
  regionId,
  jobId,
  getAllCrewsQuery,
  onClickCarButton,
}: WorkforceCrewProps) {
  const promptResponse = useSelector(selectResponse);
  const currentUser = useSelector(selectCurrentUser);
  const dispatch = useDispatch();
  const allEmployeeCrews = useSelector(selectEmployeeCrews);
  const [isAssignForemanModalOpen, setIsAssignForemanModalOpen] = useState(
    false,
  );
  const [isAssignDriverModalOpen, setIsAssignDriverModalOpen] = useState(false);
  const [isAssignmentsModalOpen, setIsAssignmentsModalOpen] = useState(false);
  const [employeeSelectedIds, setEmployeeSelectedIds] = useState<string[]>([]);
  const [deleteEmployeeCrewId, setDeleteEmployeeCrewId] = useState<number>(0);
  const [radioSelectedValue, setRadioSelectedValue] = useState("");
  const [currentEmployeeCrews, setCurrentEmployeeCrews] = useState<
    EmployeeCrewModel[]
  >([]);
  const classes = useStyles();
  const doesCrewHaveDriver =
    currentEmployeeCrews.length > 0 &&
    currentEmployeeCrews.some((employeeCrew) => employeeCrew.isDriver);
  const workforceCrewPosition = position !== 1 ? position : null;
  const crewWorkers = currentEmployeeCrews.filter(
    (crewMember) =>
      (crewMember.employee?.role === "worker" && crewMember.isCurrent) ?? [],
  );
  const assignedWorkers = crewWorkers.map(
    (crewWorker) => crewWorker.employee,
  ) as EmployeeModel[];
  const currentForeman = currentEmployeeCrews.filter(
    (crewMember) => crewMember.isForeman && crewMember.isCurrent,
  );
  const availableDrivers = currentEmployeeCrews
    .filter(
      (employee) =>
        !employee.isDriver &&
        employee.isCurrent &&
        employee.employee?.role !== "supervisor",
    )
    .map((crewMember) => crewMember.employee);
  const doesCrewHaveCurrentEmployees = currentEmployeeCrews
    .filter((ec) => !ec.isSupervisor)
    .some((crewMember) => crewMember.isCurrent);

  useEffect(() => {
    dispatch(
      getAllEmployeeCrews(
        {
          where: { crewId },
          include: ["Employee"],
        },
        true,
      ),
    );
  }, [dispatch, crewId]);

  useEffect(() => {
    if (
      allEmployeeCrews &&
      allEmployeeCrews[0] &&
      allEmployeeCrews[0].crewId === crewId
    ) {
      setCurrentEmployeeCrews(allEmployeeCrews);
    }
  }, [allEmployeeCrews, crewId]);

  useEffect(() => {
    if (deleteEmployeeCrewId !== 0 && promptResponse) {
      dispatch(deleteEmployeeCrew(deleteEmployeeCrewId, getAllCrewsQuery()));
      setDeleteEmployeeCrewId(0);
    }
  }, [
    promptResponse,
    dispatch,
    deleteEmployeeCrewId,
    getAllCrewsQuery,
    crewId,
  ]);

  useEffect(() => {
    if (!isAssignmentsModalOpen) {
      setEmployeeSelectedIds([]);
    }
  }, [isAssignmentsModalOpen]);

  const handleToggleAssignForemanModal = () =>
    setIsAssignForemanModalOpen(!isAssignForemanModalOpen);

  const handleToggleAssignDriverModal = () =>
    setIsAssignDriverModalOpen(!isAssignDriverModalOpen);

  const handleToggleAssignmentsModal = () =>
    setIsAssignmentsModalOpen(!isAssignmentsModalOpen);

  const handleClickDelete = (id: string) => {
    if (currentEmployeeCrews.length > 0) {
      const crewToDelete = currentEmployeeCrews.find(
        (employeeCrew) => employeeCrew.employeeId === Number(id),
      );

      if (crewToDelete && crewToDelete.employee && crewToDelete.id) {
        const crewToDeleteId =
          typeof crewToDelete.id === "string"
            ? parseInt(crewToDelete.id, 10)
            : crewToDelete.id;

        setDeleteEmployeeCrewId(crewToDeleteId);
        dispatch(
          prompt({
            title: "Remove Job Assignment",
            message: `Are you sure you want to remove ${crewToDelete.employee.firstName} ${crewToDelete.employee.lastName} from this job?`,
          }),
        );
      }
    }
  };

  const handleClickCheckbox = (idRow: string, isChecked: boolean) => {
    if (isChecked && !employeeSelectedIds.includes(idRow)) {
      setEmployeeSelectedIds((prevState) => [...prevState, idRow]);
    }

    if (!isChecked && employeeSelectedIds.includes(idRow)) {
      setEmployeeSelectedIds((prevState) =>
        prevState.filter((employeeId: string) => employeeId !== idRow),
      );
    }
  };

  const handleClickSaveCrewAssignments = () => {
    if (employeeSelectedIds.length > 0) {
      employeeSelectedIds.forEach((id) => {
        dispatch(
          createEmployeeCrew(
            {
              assignedAt: new Date(),
              crewId,
              employeeId: Number(id),
              isCurrent: true,
              isDriver: false,
              isForeman: false,
              isSupervisor: false,
            },
            true,
            getAllCrewsQuery(),
          ),
        );
      });
      handleToggleAssignmentsModal();
    }
  };

  const handleChangeRadio = (idRow: string, isChecked: boolean) => {
    if (isChecked) {
      setRadioSelectedValue(idRow);
    }

    if (!isChecked) {
      setRadioSelectedValue("");
    }
  };

  const handleClickRemoveTeam = () => {
    if (position > 1 && !doesCrewHaveCurrentEmployees) {
      dispatch(deleteCrew(crewId, getAllCrewsQuery()));
    }
  };

  return (
    <Fragment>
      <Box>
        {position > 1 && !doesCrewHaveCurrentEmployees && (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            height="20px"
          >
            <IconButton onClick={handleClickRemoveTeam}>
              <RemoveCircleOutline />
            </IconButton>
            <Typography variant="body2" className={classes.remove}>
              Remove
            </Typography>
          </Box>
        )}
      </Box>
      <Box margin="24px 0px">
        <Grid container>
          <Grid item lg={2} sm={3} xs={12}>
            <Box className={classes.titleContainer}>
              <Typography variant="body2" className={classes.title}>
                Foreman {workforceCrewPosition}
              </Typography>
              {currentUser?.employee.role !== "foreman" && (
                <IconButton
                  aria-label="edit foreman"
                  className={classes.icon}
                  onClick={handleToggleAssignForemanModal}
                >
                  {currentForeman.length > 0 ? <Edit /> : <Add />}
                </IconButton>
              )}
            </Box>
          </Grid>
          <Grid item lg={4} sm={6} xs={12}>
            {currentForeman.length === 0 ? (
              <Box display="flex" height="100%" alignItems="center">
                <Typography>Add a Foreman</Typography>
              </Box>
            ) : (
              <Fragment>
                {currentForeman.map((foreman) => (
                  <InfoItem
                    key={foreman.employeeId}
                    foremanId={foremanId}
                    isCurrentUser={true}
                    isDriver={foreman.isDriver}
                    employee={
                      foreman.employee
                        ? foreman.employee
                        : ({} as EmployeeModel)
                    }
                    onClickCarButton={onClickCarButton}
                  />
                ))}
              </Fragment>
            )}
          </Grid>
          {doesCrewHaveCurrentEmployees && (
            <Grid
              alignContent="center"
              container
              item
              justify="flex-end"
              lg={6}
              sm={3}
              xs={6}
            >
              <Button
                className={classes.button}
                variant="outlined"
                onClick={handleToggleAssignDriverModal}
              >
                {doesCrewHaveDriver ? "Update Driver" : "Assign Driver"}
              </Button>
            </Grid>
          )}
        </Grid>
        <Box margin="24px 0">
          <Divider />
        </Box>
        <Grid container>
          <Grid item lg={2} sm={3} xs={12}>
            <Box className={classes.titleContainer}>
              <Typography variant="body2" className={classes.title}>
                Crew
              </Typography>
              <IconButton
                aria-label="edit workers"
                className={classes.icon}
                onClick={handleToggleAssignmentsModal}
              >
                {crewWorkers.length > 0 ? <Edit /> : <Add />}
              </IconButton>
            </Box>
          </Grid>
          <Grid item lg={8} sm={6} xs={12}>
            {crewWorkers.length === 0 ? (
              <Box display="flex" height="100%" alignItems="center">
                <Typography>Add Crew Member</Typography>
              </Box>
            ) : (
              <Grid container spacing={3}>
                {crewWorkers.map((crewMember) =>
                  crewMember && crewMember.employee ? (
                    <Grid item key={`${crewMember.employee.id}`}>
                      <InfoItem
                        employee={crewMember.employee}
                        foremanId={foremanId}
                        isDriver={
                          crewWorkers.find(
                            (employeeCrew: EmployeeCrewModel) =>
                              employeeCrew.employeeId ===
                              crewMember.employee?.id,
                          )?.isDriver
                        }
                        onClickCarButton={onClickCarButton}
                      />
                    </Grid>
                  ) : null,
                )}
              </Grid>
            )}
          </Grid>
        </Grid>
        <Box marginTop="24px">
          <Divider />
        </Box>
        {currentEmployeeCrews && (
          <AssignForemanModal
            crewId={crewId}
            currentForeman={currentForeman}
            currentEmployeeCrews={currentEmployeeCrews}
            employeeSelectedId={radioSelectedValue}
            isOpen={isAssignForemanModalOpen}
            position={position}
            jobId={jobId}
            getAllCrewsQuery={getAllCrewsQuery}
            onToggle={handleToggleAssignForemanModal}
            onClickCheckbox={handleChangeRadio}
          />
        )}
        {availableDrivers && (
          <AssignDriverModal
            availableDrivers={availableDrivers as EmployeeModel[]}
            doesCrewHaveDriver={doesCrewHaveDriver}
            isOpen={isAssignDriverModalOpen}
            employeeCrews={currentEmployeeCrews}
            selectedValue={radioSelectedValue}
            getAllCrewsQuery={getAllCrewsQuery}
            handleChangeValue={handleChangeRadio}
            onToggle={handleToggleAssignDriverModal}
          />
        )}
        {assignedWorkers && (
          <CrewAssignmentsModal
            crewsData={{ assignedWorkers }}
            employeeSelectedIds={employeeSelectedIds}
            isOpen={isAssignmentsModalOpen}
            regionId={regionId}
            jobId={jobId}
            onToggle={handleToggleAssignmentsModal}
            onClickDelete={handleClickDelete}
            onClickSave={handleClickSaveCrewAssignments}
            onClickCheckbox={handleClickCheckbox}
          />
        )}
      </Box>
    </Fragment>
  );
}
