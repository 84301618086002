import {
  createStyles,
  InputAdornment,
  makeStyles,
  TextField,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { Autocomplete, AutocompleteInputChangeReason } from "@material-ui/lab";
import { selectEmployees } from "features/employees/employeesSlice";
import { EmployeeModel } from "@dwo/shared/dist/models/employeeModel";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

interface SearchByNameOrIdProps {
  className?: string;
  employeeRoleLabel?: string;
  id?: string;
  filteredEmployees?: EmployeeModel[];
  onChange?: (employeeId: string, employeeName: string) => void;
}

const useStyles = makeStyles(() =>
  createStyles({
    inputCenter: {
      '&&[class*="MuiOutlinedInput-root"] $input': {
        padding: "0",
      },
    },
  }),
);

export function SearchByNameOrId({
  className,
  employeeRoleLabel = "Employee",
  id = "search-by-name-or-id",
  filteredEmployees,
  onChange,
}: SearchByNameOrIdProps) {
  const classes = useStyles();
  const employees = useSelector(selectEmployees);
  const [searchResult, setSearchResult] = useState({
    firstName: "",
    employeeId: "",
  });
  const [reason, setReason] = useState("");

  useEffect(() => {
    if (reason === "input" || "reset") {
      setSearchResult({ firstName: "", employeeId: "" });
    }

    if (onChange) {
      onChange("", "");
    }
  }, [reason, onChange]);

  useEffect(() => {
    if (onChange && (!!searchResult.employeeId || !!searchResult.firstName)) {
      onChange(searchResult.employeeId, searchResult.firstName);
    }
  }, [searchResult, onChange]);

  const handleChangeSearch = (e: React.ChangeEvent<unknown>, value: any) => {
    const getSearchValues = (value: string) => {
      return value.split(" - ").map((item: string) => item.split(" ")[1]);
    };
    const [employeeId, firstName] = getSearchValues(value);

    setSearchResult({ employeeId, firstName });
  };

  const handleChangeInput = (
    e: React.ChangeEvent<unknown>,
    value: any,
    reason: AutocompleteInputChangeReason,
  ) => setReason(reason);

  const getEmployeeOptions = () => {
    if (filteredEmployees) {
      return filteredEmployees.map(
        ({ firstName, lastName, employeeId }) =>
          `ID: ${employeeId} - NAME: ${firstName} ${lastName}`,
      );
    }
    return employees.map(
      ({ firstName, lastName, employeeId }) =>
        `ID: ${employeeId} - NAME: ${firstName} ${lastName}`,
    );
  };

  return (
    <Autocomplete
      className={className}
      classes={{ inputRoot: classes.inputCenter }}
      onChange={handleChangeSearch}
      onInputChange={handleChangeInput}
      freeSolo
      id={id}
      disableClearable
      options={getEmployeeOptions()}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={`Search by ${employeeRoleLabel} Name or ID`}
          margin="normal"
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            type: "search",
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  );
}
