import React, { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Button,
  createStyles,
  Grid,
  makeStyles,
  Paper,
  Theme,
  Typography,
} from "@material-ui/core";
import RefreshIcon from "@material-ui/icons/Refresh";

import { UploadStatus } from "components/table/UploadStatus";
import { error } from "features/error/errorSlice";
import { timesheetFileStatuses } from "utils/downladTimesheetsUtils";
import { downloadEmployeeCSVService } from "@dwo/shared/dist/services/downloadEmployeeCSVService";
import { DownloadFileStatusModel } from "@dwo/shared/src/models/fileDownloadStatusModel";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      backgroundColor: "white",
      margin: "0 24px 24px 24px",
      padding: "24px",
      borderRadius: 0,
      position: "relative",
      zIndex: 2,
    },
    helpText: {
      color: theme.palette.grey["400"],
    },
    customButton: {
      width: "100%",
    },
    statusText: {
      fontSize: "14px",
      fontWeight: 600,
      justifyContent: "center",
    },
    refreshButton: {
      display: "flex",
      justifyItems: "center",
    },
  }),
);

export function DownloadEmployeeCSV() {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [
    employeeCSVStatus,
    setEmployeeCSVStatus,
  ] = useState<DownloadFileStatusModel>();

  const [
    triggerGetGeneratedFile,
    setTriggerGetGeneratedFile,
  ] = useState<boolean>(false);

  useEffect(() => {
    const getFilesStatus = async () => {
      const EmployeeCSVStatus = await downloadEmployeeCSVService.employeeCSVFileStatus();
      setEmployeeCSVStatus(EmployeeCSVStatus?.data?.data);
    };

    getFilesStatus();

    const refreshStatus = setInterval(() => {
      getFilesStatus();
    }, 15000);
    return () => clearInterval(refreshStatus);
  }, [triggerGetGeneratedFile]);

  const handleClickGenerateEmployeeCSVFile = async () => {
    try {
      await downloadEmployeeCSVService.generateEmployeeCSVFile();
    } catch (err) {
      dispatch(
        error(
          {
            title: "Could not download file",
            message: err.message,
          },
          () => handleClickGenerateEmployeeCSVFile(),
        ),
      );
    }
    setTriggerGetGeneratedFile(!triggerGetGeneratedFile);
  };

  const handleClickDownloadEmployeeCSVFile = async () => {
    try {
      const EmployeeCSVUrl = await downloadEmployeeCSVService.downloadEmployeeCSVFile();
      window.open(EmployeeCSVUrl.data.url);
    } catch (err) {
      dispatch(
        error(
          {
            title: "Could not download file",
            message: err.message,
          },
          () => handleClickDownloadEmployeeCSVFile(),
        ),
      );
    }
  };

  const handleReload = async () => {
    const EmployeeCSVStatus = await downloadEmployeeCSVService.employeeCSVFileStatus();
    setEmployeeCSVStatus(EmployeeCSVStatus?.data?.data);
  };

  return (
    <Fragment>
      <Paper className={classes.paper}>
        <Grid container spacing={3} direction="column">
          <Grid item xs={12}>
            <Typography variant="body2" className={classes.helpText}>
              Download employee details.
            </Typography>
          </Grid>

          <Grid container alignItems="center" spacing={1}>
            <Grid item xs={3}>
              <Button
                variant="contained"
                className={classes.customButton}
                disabled={false}
                onClick={handleClickGenerateEmployeeCSVFile}
              >
                Generate File
              </Button>
            </Grid>
            <Grid item xs={3}>
              <Button
                variant="contained"
                className={classes.customButton}
                disabled={
                  employeeCSVStatus?.status !== timesheetFileStatuses.SUCCESSFUL
                }
                onClick={handleClickDownloadEmployeeCSVFile}
              >
                Download File
              </Button>
            </Grid>
            <Grid
              justify="center"
              item
              style={{
                textAlign: "start",
              }}
              xs={2}
            >
              <Typography className={classes.statusText}>
                Last Status:
              </Typography>
              <UploadStatus status={employeeCSVStatus?.status || ""} />
            </Grid>
            <Grid item xs={1}>
              <Button variant="outlined" onClick={handleReload}>
                <RefreshIcon />
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Fragment>
  );
}
