import { Checkbox, createStyles, makeStyles, Theme } from "@material-ui/core";
import React from "react";

interface CheckboxCustomProps {
  disabled?: boolean;
  isChecked: boolean;
  onClickCheckbox: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: 0,
      "&$checked": {
        color: theme.palette.primary.dark,
      },
      "& .MuiSvgIcon-root": {
        fontSize: "2rem",
      },
    },
    checked: {},
  }),
);

export function CheckboxCustom({
  disabled,
  isChecked,
  onClickCheckbox,
}: CheckboxCustomProps) {
  const classes = useStyles();

  return (
    <Checkbox
      aria-label="Checkbox"
      checked={isChecked}
      classes={{ root: classes.root, checked: classes.checked }}
      color="primary"
      disabled={disabled}
      onChange={onClickCheckbox}
    />
  );
}
