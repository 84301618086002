import { DWOJobModel } from "@dwo/shared/dist/models/DWOJobModel";
import { Box, Button } from "@material-ui/core";
import { TableCustom } from "components/table/TableCustom";
import {
  createSwapWorkshift,
  deleteTempWorkshift,
  getAllTempWorkshift,
  LOCATION,
  selectCurrentDWOJob,
  selectIsLoadingTempWorkshifts,
  selectTempWorkshifts,
} from "features/dwo/dwoSlice";
import { noop } from "lodash";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TempWorkshiftsModal } from "./TempWorkshiftsModal";
import {
  getTempModalRows,
  tempModalColumns,
} from "utils/timesheetDetailsUtils";
import { TempWorkshiftModel } from "@dwo/shared/dist/models/tempWorkshiftModel";
import { SwapTempWorkshiftModal } from "./SwapTempWorkshiftModal";
import { EmployeeModel } from "@dwo/shared/dist/models/employeeModel";
import {
  WorkShiftPictureMatchStatus,
  WorkShiftRequestModel,
  WorkShiftStatus,
} from "@dwo/shared/dist/models/workShiftModel";

interface TempWorkshiftSectionProps {
  regionId?: number;
}

export function TempWorkshiftSection({ regionId }: TempWorkshiftSectionProps) {
  const dispatch = useDispatch();
  const temporalWorkshifts = useSelector(selectTempWorkshifts);
  const isLoagingTemp = useSelector(selectIsLoadingTempWorkshifts);
  const currentDWOJob: DWOJobModel | undefined = useSelector(
    selectCurrentDWOJob,
  );
  const [isTempModalOpen, setIsTempModalOpen] = useState<boolean>(false);
  const [isEditTempModalOpen, setIsEditTempModalOpen] = useState<boolean>(
    false,
  );
  const [isSwapModalOpen, setIsSwapModalOpen] = useState<boolean>(false);
  const [selectedTemp, setSelectedTemp] = useState<
    TempWorkshiftModel | undefined
  >(undefined);

  useEffect(() => {
    if (currentDWOJob) {
      dispatch(getAllTempWorkshift(currentDWOJob.id as number));
    }
  }, [dispatch, currentDWOJob]);

  const handleClickAddTemp = () => setIsTempModalOpen(true);

  const handleClickCloseTempModal = () => setIsTempModalOpen(false);
  const handleClickCloseEditTempModal = () => setIsEditTempModalOpen(false);
  const handleClickCloseSwapModal = () => setIsSwapModalOpen(false);

  const handleClickSwap = (tempWorkshift: TempWorkshiftModel) => {
    setSelectedTemp(tempWorkshift);
    setIsSwapModalOpen(true);
  };

  const handleClickEdit = (tempWorkshift: TempWorkshiftModel) => {
    setSelectedTemp(tempWorkshift);
    setIsEditTempModalOpen(true);
  };

  const handleClickDelete = (tempWorkshift: TempWorkshiftModel) => {
    dispatch(deleteTempWorkshift(tempWorkshift));
  };

  const handleClickSaveSwap = (employeeSelected: EmployeeModel) => {
    if (currentDWOJob && selectedTemp) {
      const workshiftResult: WorkShiftRequestModel = {
        dwoJobId: currentDWOJob.id as number,
        employeeId: employeeSelected.id as number,
        end: selectedTemp.end,
        endLocation: LOCATION,
        endPictureMatchStatus: WorkShiftPictureMatchStatus.PENDING,
        hours: 0,
        isDriver: false,
        start: selectedTemp.start,
        startLocation: LOCATION,
        startPictureMatchStatus: WorkShiftPictureMatchStatus.PENDING,
        status: WorkShiftStatus.FINISHED,
        timeBreaks: [],
        type: "normal",
        wasTemporal: true,
      };

      dispatch(
        createSwapWorkshift(
          workshiftResult,
          selectedTemp,
          currentDWOJob.dwoId,
          currentDWOJob.jobId,
          workshiftResult.wasTemporal,
        ),
      );
    }
  };

  return (
    <Fragment>
      <Box paddingBottom="8px" display="flex" justifyContent="flex-end">
        <Button color="primary" onClick={handleClickAddTemp}>
          Add temporary worker
        </Button>
      </Box>
      <TableCustom
        columns={tempModalColumns}
        isLoading={isLoagingTemp}
        noRowsMessage="There are no temporary workers to display."
        rows={getTempModalRows(
          temporalWorkshifts,
          handleClickSwap,
          handleClickEdit,
          handleClickDelete,
        )}
        onClickSort={noop}
      />

      <TempWorkshiftsModal
        isOpen={isTempModalOpen}
        onClickClose={handleClickCloseTempModal}
      />
      <TempWorkshiftsModal
        isOpen={isEditTempModalOpen}
        isEdit={true}
        initialWorkshiftValue={selectedTemp}
        onClickClose={handleClickCloseEditTempModal}
      />

      {isSwapModalOpen && (
        <SwapTempWorkshiftModal
          jobId={currentDWOJob?.jobId}
          regionId={regionId}
          isOpen={isSwapModalOpen}
          onClickCancel={handleClickCloseSwapModal}
          onClickSave={handleClickSaveSwap}
        />
      )}
    </Fragment>
  );
}
