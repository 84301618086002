import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: theme.palette.grey[100],
      display: "flex",
      flexDirection: "column",
      height: "100%",
      justifyContent: "center",
      padding: "12px",
      width: "30%",
      marginBottom: "24px",
    },
    header: {
      color: theme.palette.grey[700],
      fontWeight: 600,
      fontSize: "14px",
    },
    totalNumberText: {
      color: theme.palette.grey[700],
      fontSize: "40px",
      fontWeight: 600,
      lineHeight: "49px",
    },
    totalInRegionText: {
      color: theme.palette.grey[700],
      fontSize: "18px",
      fontWeight: 600,
      lineHeight: "26px",
    },
  }),
);

interface TimesheetWorkforceInfoCardProps {
  header: string;
  data?: string | number;
  inRegionData?: {
    total: string | number;
    inRegion: string | number;
  };
}

export function TimesheetWorkforceInfoCard({
  header,
  data,
  inRegionData,
}: TimesheetWorkforceInfoCardProps) {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Typography align="center" className={classes.header} variant="body2">
        {header}
      </Typography>

      <Box margin="0 auto" width="fit-content">
        {inRegionData ? (
          <Typography
            align="center"
            className={classes.totalInRegionText}
            variant="h4"
          >
            {inRegionData?.total}
            <span style={{ color: "red" }}> out of </span>
            {inRegionData?.inRegion}
          </Typography>
        ) : (
          <Typography
            align="center"
            className={classes.totalNumberText}
            variant="h4"
          >
            {data}
          </Typography>
        )}
      </Box>
    </Box>
  );
}
