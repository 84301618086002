import {
  Box,
  createStyles,
  IconButton,
  makeStyles,
  Theme,
} from "@material-ui/core";
import { GetAppRounded } from "@material-ui/icons";
import React from "react";

interface DownloadFileIconProps {
  id: number;
  onClickDownload: (id: number) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      color: theme.palette.primary.main,
    },
    iconButton: {
      padding: "8px",
    },
  }),
);

export function DownloadFileIcon({
  id,
  onClickDownload,
}: DownloadFileIconProps) {
  const classes = useStyles();

  const handleClickDownload = (id?: number) => () => {
    if (id) {
      onClickDownload(id);
    }
  };

  return (
    <Box
      alignItems="center"
      display="flex"
      justifyContent="center"
      width="100%"
      // marginLeft="-8px"
      maxWidth="120px"
    >
      <IconButton
        aria-label="Download Expense"
        className={classes.iconButton}
        onClick={handleClickDownload(id)}
      >
        <GetAppRounded className={classes.icon} />
      </IconButton>
    </Box>
  );
}
