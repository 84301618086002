import {
  JobCategories,
  JobsSummaryOverviewDataPerMonth,
} from "@dwo/shared/dist/models/jobSummaryModel";
import { Box, useTheme } from "@material-ui/core";
import Chart, { ChartTooltipModel } from "chart.js";
import { endOfQuarter, format, startOfQuarter } from "date-fns";
import {
  selectSummaryCategoriesAndColors,
  selectSummarySelectedCategory,
  selectSummarySelectedDateRange,
} from "features/summary/summarySlice";
import React, { useEffect, useRef } from "react";
import { renderToString } from "react-dom/server";
import { useSelector } from "react-redux";
import { addLocalTimeOffset } from "utils/dateUtils";
import { getEmptyDataset, getQuarterlyBarChartData } from "utils/summaryUtils";
import { BarChartTooltip } from "./BarChartTooltip";

interface OverviewPerQuarterChartProps {
  data: JobsSummaryOverviewDataPerMonth[];
}

const tooltipId = "overviewPerQuarter-detailed-tooltip";
const totalDataLabel = "Total Quarter Hours / Costs";

export function OverviewPerQuarterChart({
  data,
}: OverviewPerQuarterChartProps) {
  const theme = useTheme();
  const chartCanvasRef = useRef({} as HTMLCanvasElement);
  const categoriesAndColors = useSelector(selectSummaryCategoriesAndColors);
  const selectedCategory = useSelector(selectSummarySelectedCategory);
  const selectedDateRange = useSelector(selectSummarySelectedDateRange);

  useEffect(() => {
    if (!selectedDateRange) return;

    const {
      categoriesDatasetsMap,
      dateLabels,
      totalHours,
      totalWorkHoursArray,
      monthsMap,
    } = getQuarterlyBarChartData(data, selectedDateRange);

    const totalHoursDataset = totalWorkHoursArray.map((monthHours: number) => {
      if (totalHours === 0) {
        return 1;
      }

      return totalHours - monthHours;
    });
    const datasets = categoriesAndColors.map((cac) => {
      const currentDataset = categoriesDatasetsMap.get(
        cac.label as JobCategories,
      );

      return {
        backgroundColor: cac.color,
        barThickness: 24,
        data: currentDataset || getEmptyDataset(dateLabels.length),
        hoverBackgroundColor: cac.color,
        label: cac.label,
      };
    });

    datasets.push({
      backgroundColor: theme.palette.grey[300],
      barThickness: 24,
      data: totalHoursDataset,
      hoverBackgroundColor: theme.palette.grey[300],
      label: totalDataLabel,
    });

    const barChart = new Chart(chartCanvasRef.current, {
      data: {
        datasets,
        labels: dateLabels,
      },
      options: {
        legend: {
          display: false,
        },
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              stacked: true,
              gridLines: {
                display: false,
              },
              ticks: {
                display: true,
                callback: (
                  value: number | string,
                  _index: number,
                  _values: number[] | string[],
                ) => {
                  return `${format(new Date(value), "qqq")}`;
                },
                fontColor: theme.palette.primary.dark,
                fontFamily: "Noto Sans",
                fontSize: 12,
                fontStyle: "bold",
              },
            },
          ],
          yAxes: [
            {
              stacked: true,
              gridLines: {
                display: false,
              },
              scaleLabel: {
                display: true,
                labelString: "Hours / Costs",
                fontColor: theme.palette.primary.dark,
                fontFamily: "Noto Sans",
                fontSize: 12,
                fontStyle: "bold",
              },
              ticks: {
                beginAtZero: true,
                display: false,
              },
            },
          ],
        },
        tooltips: {
          custom: (tooltipModel: ChartTooltipModel) => {
            let tooltipEl = document.getElementById(tooltipId);

            if (!tooltipEl) {
              tooltipEl = document.createElement("div");
              tooltipEl.id = tooltipId;
              document.body.appendChild(tooltipEl);
            }

            if (tooltipModel.opacity === 0) {
              tooltipEl.style.opacity = "0";
              return;
            }

            const barCategory =
              tooltipModel.body &&
              tooltipModel.body[0] &&
              tooltipModel.body[0].lines[0]
                ? tooltipModel.body[0].lines[0].split(":")[0]
                : undefined;
            const barColor =
              tooltipModel.labelColors && tooltipModel.labelColors[0]
                ? (tooltipModel.labelColors[0] as any).backgroundColor
                : undefined;
            const barDate = tooltipModel.title
              ? tooltipModel.title[0]
              : undefined;

            // Safe guard. This values appear to be valid once tooltipModel.opacity > 0
            if (!barCategory || !barColor || !barDate) {
              tooltipEl.style.opacity = "0";
              return;
            }

            const monthData = monthsMap.get(barDate);

            if (!monthData) {
              tooltipEl.style.opacity = "0";
              return;
            }

            let customTooltipHTMLString = undefined;

            const dateString = `Quarter ${format(
              addLocalTimeOffset(barDate),
              "qqqqq",
            )}`;
            const parsedBarDate = addLocalTimeOffset(barDate);
            const subDateString = `${format(
              startOfQuarter(parsedBarDate),
              "MMMM",
            )} - ${format(endOfQuarter(parsedBarDate), "MMMM")} ${format(
              parsedBarDate,
              "y",
            )}`;

            if (barCategory === totalDataLabel) {
              customTooltipHTMLString = renderToString(
                <BarChartTooltip
                  cost={monthData.monthCosts.toFixed(2)}
                  date={dateString}
                  hours={monthData.monthHours.toFixed(2)}
                  subDate={subDateString}
                  tooltipOption={{
                    color: theme.palette.grey[500],
                    label: "Quarter Costs / Hours",
                  }}
                />,
              );
            } else {
              const categoryData = monthData.monthCategoriesMap.get(
                barCategory as JobCategories,
              );

              if (!categoryData) {
                tooltipEl.style.opacity = "0";
                return;
              }

              customTooltipHTMLString = renderToString(
                <BarChartTooltip
                  cost={parseFloat(categoryData.cost).toFixed(2)}
                  date={dateString}
                  hours={parseFloat(categoryData.hours).toFixed(2)}
                  subDate={subDateString}
                  tooltipOption={{
                    color: barColor,
                    label: barCategory,
                  }}
                />,
              );
            }

            const position = chartCanvasRef.current.getBoundingClientRect();

            tooltipEl.innerHTML = customTooltipHTMLString;
            tooltipEl.style.opacity = "1";
            tooltipEl.style.position = "absolute";
            tooltipEl.style.left = `${
              position.left + window.pageXOffset + tooltipModel.caretX
            }px`;
            tooltipEl.style.top = `${
              position.top + window.pageYOffset + tooltipModel.caretY
            }px`;
            tooltipEl.style.pointerEvents = "none";
          },
          enabled: false,
        },
      },
      type: "bar",
    });

    return () => {
      barChart.destroy();
    };
  }, [
    categoriesAndColors,
    data,
    selectedCategory,
    selectedDateRange,
    theme.palette,
  ]);

  return (
    <Box height="200px" maxWidth="360px" width="100%">
      <canvas ref={chartCanvasRef} />
      <div id={tooltipId} />
    </Box>
  );
}
