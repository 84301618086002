import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { DateRange } from "materialui-daterange-picker";
import {
  createStyles,
  makeStyles,
  Theme,
  Paper,
  Grid,
  Typography,
  Button,
} from "@material-ui/core";
import RefreshIcon from "@material-ui/icons/Refresh";
import { Dropdown } from "components/Dropdown";
import { RangeDatePicker } from "components/RangeDatePicker";
import { UploadStatus } from "components/table/UploadStatus";
import { companiesOptions } from "utils/downladTimesheetsUtils";
import { timesheetFileStatuses } from "utils/downladTimesheetsUtils";
//import { error } from "features/error/errorSlice";
import { selectCurrentUser } from "features/logIn/sessionSlice";
import { downloadWorkshiftVistaCSVService } from "@dwo/shared/dist/services/downloadWorkshiftVistaCSVService";
import { DownloadFileStatusModel } from "@dwo/shared/src/models/fileDownloadStatusModel";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      fontSize: "20px",
      color: theme.palette.primary.main,
      fontWeight: 400,
      marginBottom: "16px",
    },
    paper: {
      backgroundColor: "white",
      margin: "0 24px 24px 24px",
      padding: "24px",
      borderRadius: 0,
      position: "relative",
    },
    historyFiltersContainer: {
      justifyContent: "flex-end",

      [theme.breakpoints.down("xs")]: {
        justifyContent: "flex-start",
      },
    },
    caption: {
      fontSize: "14px",
      color: theme.palette.grey[500],
      fontWeight: 400,
      marginBottom: "16px",
    },
    paginationContainer: {
      borderRadius: "2px",
      margin: "0 24px 24px",
    },
    rowSelectLabel: {
      color: theme.palette.primary.dark,
      fontWeight: "bold",
    },
    customButton: {
      width: "100%",
    },
    statusText: {
      fontSize: "14px",
      fontWeight: 600,
      justifyContent: "center",
    },
  }),
);

let intervalId: NodeJS.Timeout;

export function DownloadVistaTimesheets() {
  const classes = useStyles();
  const currentUser = useSelector(selectCurrentUser);
  const [customCompany, setCustomCompany] = useState<string>("");
  const [customDateRange, setCustomDateRange] = useState<DateRange | undefined>(
    undefined,
  );
  const [isGenerateDisabled, setIsGenerateDisabled] = useState<boolean>(true);
  const [
    currentVistaCSVFile,
    setCurrentVistaCSVFile,
  ] = useState<DownloadFileStatusModel>();
  const [
    triggerGetGeneratedFile,
    setTriggerGetGeneratedFile,
  ] = useState<boolean>(false);

  useEffect(() => {
    setCurrentVistaCSVFile(undefined);
    clearInterval(intervalId);

    if (
      customCompany &&
      customDateRange &&
      customDateRange.startDate &&
      customDateRange.endDate
    ) {
      setIsGenerateDisabled(false);
      return;
    }
    setIsGenerateDisabled(true);
  }, [customDateRange, customCompany]);

  useEffect(() => {
    const getFilesStatus = async () => {
      try {
        const CSVStatus = await downloadWorkshiftVistaCSVService.timesheetsVistaCSVFileStatus();
        setCurrentVistaCSVFile(CSVStatus?.data?.data);
      } catch (err) {
        console.log("No CSV File created yet");
      }
    };

    getFilesStatus();

    intervalId = setInterval(() => {
      getFilesStatus();
    }, 15000);
    return () => clearInterval(intervalId);
  }, [triggerGetGeneratedFile]);

  const handleReload = () => {
    setTriggerGetGeneratedFile(!triggerGetGeneratedFile);
  };

  const handleSelectCustomCompany = (selectedCompany: string) => {
    setCustomCompany(selectedCompany);
  };

  const handleCustomRangeDate = (dateRange: DateRange) => {
    const startDate = dateRange.startDate;
    const endDate = dateRange.endDate;
    startDate?.setHours(0, 0, 0, 0);
    endDate?.setHours(23, 59, 59, 0);

    if (startDate && endDate) {
      setCustomDateRange({ startDate, endDate });
    } else {
      setCustomDateRange(undefined);
    }
  };

  const handleClickGenerateCustomFile = async () => {
    try {
      if (
        customCompany &&
        customDateRange &&
        customDateRange.startDate &&
        customDateRange.endDate
      ) {
        await downloadWorkshiftVistaCSVService.generateTimesheetsVistaCSVFile(
          customCompany,
          customDateRange.startDate,
          customDateRange.endDate,
        );

        setIsGenerateDisabled(true);
      }
    } catch (err) {
      console.log("A file is being generated.");
      /**
       * ! This Error triggers a modal showing two Buttons and a message. It was requested to keep it hidden.
       */
      // dispatch(
      //   error(
      //     {
      //       title: "Could not generate file",
      //       message: err.message,
      //     },
      //     () => handleClickGenerateCustomFile(),
      //   ),
      // );
    }
    setTriggerGetGeneratedFile(!triggerGetGeneratedFile);
  };

  const handleClickDownloadCustomFile = async () => {
    const VistaCSVUrl = await downloadWorkshiftVistaCSVService.downloadTimesheetsVistaCSVFile();
    window.open(VistaCSVUrl.data.url);
  };

  return (
    <Fragment>
      <Paper className={classes.paper}>
        <Grid container direction="column">
          <Grid item sm={6} xs={12}>
            <Typography className={classes.header}>Custom Export</Typography>
          </Grid>
          <Grid item sm={6} xs={12}>
            <Typography className={classes.caption}>
              Select company and date range, you will get a file with all the
              information you decide to segment in "csv" format.
              <br />
              This file can only be retrieved on ranges of one week
            </Typography>
          </Grid>

          <Grid item sm={7} xs={12}>
            <Grid container spacing={2}>
              <Grid item sm={3} xs={12}>
                <Dropdown
                  id="exportCompanyDropdown"
                  label="Company"
                  options={companiesOptions}
                  selectedValue={customCompany}
                  onSelectOption={handleSelectCustomCompany}
                />
              </Grid>
              <Grid item sm={7} xs={12}>
                <RangeDatePicker
                  downAndLeftLocation
                  maxDate={new Date()}
                  onClickApply={handleCustomRangeDate}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <Button
                  variant="contained"
                  className={classes.customButton}
                  disabled={isGenerateDisabled}
                  onClick={handleClickGenerateCustomFile}
                >
                  Generate File
                </Button>
              </Grid>
              <Grid item xs={3}>
                <Fragment>
                  <Button
                    variant="contained"
                    className={classes.customButton}
                    disabled={
                      currentVistaCSVFile?.status !==
                      timesheetFileStatuses.SUCCESSFUL
                    }
                    onClick={handleClickDownloadCustomFile}
                  >
                    Download File
                  </Button>
                </Fragment>
              </Grid>
              <Grid item xs={2}>
                <Typography className={classes.statusText}>
                  Last Status:
                </Typography>
                <UploadStatus
                  status={currentVistaCSVFile?.status || "New File"}
                  hideStatusText={false}
                />
              </Grid>
              <Grid item xs={2}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Button variant="outlined" onClick={handleReload}>
                    <RefreshIcon />
                  </Button>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Fragment>
  );
}
