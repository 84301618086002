import { SummaryServiceOptions } from "@dwo/shared/dist/services/JobService";
import { Grid } from "@material-ui/core";
import { LoadingSpinner } from "components/LoadingSpinner";
import {
  getSummaryJobPerformanceData,
  selectSummaryIsLoadingGeneralData,
  selectSummarySelectedCategory,
  selectSummarySelectedDateRange,
  selectSummarySelectedRegion,
} from "features/summary/summarySlice";
import { cloneDeep } from "lodash/fp";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { enDashFormatDate } from "utils/dateUtils";
import { SummaryJobsCard } from "./SummaryJobsCard";
import { SummaryJobsTypesCard } from "./SummaryJobsTypesCard";
import { SummaryOverviewCard } from "./SummaryOverviewCard";
import { SummaryWorkforceCard } from "./SummaryWorkforceCard";

export function JobPerformanceTab() {
  const dispatch = useDispatch();
  const currentCategory = useSelector(selectSummarySelectedCategory);
  const currentDateRange = useSelector(selectSummarySelectedDateRange);
  const currentRegion = useSelector(selectSummarySelectedRegion);
  const isLoading = useSelector(selectSummaryIsLoadingGeneralData);
  const [query, setQuery] = useState<SummaryServiceOptions>();

  useEffect(() => {
    const hasDates = query?.startDate && query?.endDate;

    if (query && hasDates) {
      dispatch(getSummaryJobPerformanceData(query));
    }
  }, [dispatch, query]);

  useEffect(() => {
    if (!currentDateRange) return;

    setQuery((prevQuery: SummaryServiceOptions | undefined) => {
      const updatedQuery = prevQuery
        ? cloneDeep(prevQuery)
        : ({} as SummaryServiceOptions);

      updatedQuery.startDate = enDashFormatDate(
        new Date(currentDateRange.startDate),
      );
      updatedQuery.endDate = enDashFormatDate(
        new Date(currentDateRange.endDate),
      );

      return updatedQuery;
    });
  }, [currentDateRange]);

  useEffect(() => {
    setQuery((prevQuery: SummaryServiceOptions | undefined) => {
      const updatedQuery = prevQuery
        ? cloneDeep(prevQuery)
        : ({} as SummaryServiceOptions);

      updatedQuery.region =
        currentRegion === "all" || currentRegion === ""
          ? undefined
          : parseInt(currentRegion, 10);

      return updatedQuery;
    });
  }, [currentRegion]);

  useEffect(() => {
    setQuery((prevQuery: SummaryServiceOptions | undefined) => {
      const updatedQuery = prevQuery
        ? cloneDeep(prevQuery)
        : ({} as SummaryServiceOptions);

      updatedQuery.category =
        currentCategory === "all" || currentCategory === ""
          ? undefined
          : currentCategory;

      return updatedQuery;
    });
  }, [currentCategory]);

  if (isLoading) {
    return (
      <Grid item xs={12}>
        <LoadingSpinner />
      </Grid>
    );
  }

  return (
    <Fragment>
      <Grid item xs={12}>
        <SummaryJobsCard />
      </Grid>

      <Grid item xs={12}>
        <SummaryOverviewCard />
      </Grid>

      <Grid item sm={7} xs={12}>
        <SummaryWorkforceCard />
      </Grid>

      <Grid item sm={5} xs={12}>
        <SummaryJobsTypesCard />
      </Grid>
    </Fragment>
  );
}
