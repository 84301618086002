import {
  Box,
  Button,
  createStyles,
  Grid,
  InputAdornment,
  makeStyles,
  MenuItem,
  Theme,
  Typography,
} from "@material-ui/core";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { DatePicker } from "components/DatePicker";
import { DropdownOption } from "components/Dropdown";
import { useFormik } from "formik";
import { JobModel, JobStatus } from "@dwo/shared/dist/models/jobModel";
import { RegionModel } from "@dwo/shared/dist/models/regionModel";
import React, { useCallback, useState } from "react";

import { parseRegions } from "utils/homeUtils";
import {
  categoryOptions,
  commaRegex,
  companiesOptions,
  // contractTypeOptions,
  DEFAULT_ADDRESS,
  DEFAULT_BILLED_TO,
  DEFAULT_DESCRIPTION,
  DEFAULT_JOB_FORM_VALUES,
  DEFAULT_NAME,
  DEFAULT_NO_BID,
  DEFAULT_SELECT_OPTION,
  EMPTY_CURRENCY_FIELD,
  getInitialJobFormValues,
  JobFormFields,
  jobTypesOptions,
  updateEstimatedCost,
  validationJobForm,
  workOrderTypeOptions,
} from "utils/jobFormUtils";
import { DEFAULT_EMPTY } from "utils/jobUtils";
import { SelectCustom } from "./SelectCustom";
import { TextFieldCustom } from "./TextFieldCustom";

import { SearchByNameOrId } from "components/userManagement/SearchByNameOrId";
import { TableCustom } from "components/table/TableCustom";
import { cloneDeep, noop } from "lodash/fp";
import { MainTableCell, MainTableColumn, MainTableRow } from "utils/tableUtils";

interface JobFormProps {
  isEditing?: boolean;
  jobData?: JobModel;
  regions: RegionModel[];
  onSubmit: (newJob: JobModel) => void;
  workOrderNumberSelected?: string;
  onClickCheckbox?: (idRow: string, isChecked: boolean) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    descriptionLabel: {
      color: theme.palette.primary.main,
    },
    infoLabel: {
      color: theme.palette.primary.dark,
      fontWeight: "bold",
    },
    submitButton: {
      display: "none",
    },
  }),
);

export function JobForm({
  isEditing,
  jobData,
  regions,
  onSubmit,
  workOrderNumberSelected,
  onClickCheckbox,
}: JobFormProps) {
  const classes = useStyles();

  const handleSubmit = ({
    address,
    billedTo,
    category,
    companyName: company,
    // contractType,
    description,
    endDate,
    estimatedCost,
    fcc,
    inspector,
    jobType: type,
    labors,
    materials,
    other,
    regionId,
    startDate,
    workOrderNumber,
    workOrderType,
  }: JobFormFields) => {
    const bidLaborCost =
      labors && labors !== EMPTY_CURRENCY_FIELD
        ? labors.replace(commaRegex, "")
        : DEFAULT_NO_BID;
    const bidMaterialsCost =
      materials && materials !== EMPTY_CURRENCY_FIELD
        ? materials.replace(commaRegex, "")
        : DEFAULT_NO_BID;
    const bidOtherCost =
      other && other !== EMPTY_CURRENCY_FIELD
        ? other.replace(commaRegex, "")
        : DEFAULT_NO_BID;
    const bidPrice =
      estimatedCost && estimatedCost !== EMPTY_CURRENCY_FIELD
        ? estimatedCost.replace(commaRegex, "")
        : updateEstimatedCost(
            bidLaborCost,
            bidMaterialsCost,
            bidOtherCost,
          ).replace(commaRegex, "");

    onSubmit({
      ...jobData,
      address,
      amountBilled: jobData?.amountBilled || undefined,
      bidLaborCost,
      bidMaterialsCost,
      bidOtherCost,
      bidPrice,
      billedTo:
        billedTo && billedTo !== DEFAULT_BILLED_TO ? billedTo : undefined,
      category,
      company: company !== DEFAULT_SELECT_OPTION ? company : undefined,
      dateBilled: jobData?.dateBilled || undefined,
      description:
        description && description !== DEFAULT_DESCRIPTION
          ? description
          : undefined,
      endDate,
      fcc: fcc && fcc !== DEFAULT_NAME ? fcc : undefined,
      inspector:
        inspector && inspector !== DEFAULT_NAME ? inspector : undefined,
      laborCost: jobData?.laborCost || undefined,
      materialsCost: jobData?.materialsCost || undefined,
      name: jobData?.name || undefined,
      otherCost: jobData?.otherCost || undefined,
      progressPercentage: jobData?.progressPercentage || undefined,
      regionId: parseInt(regionId.toString(), 10),
      startDate,
      status: jobData?.status || JobStatus.Unassigned,
      type,
      workOrderType,
      workOrderNumber: workOrderNumber
        ? workOrderNumber === "Work order number"
          ? undefined
          : workOrderNumber.trim() || null
        : workOrderNumber?.trim() || null,
      // contractType,
    } as JobModel);
  };

  const handleUpdateCurrencyFields = (inputText: string) => {
    const inputValue = inputText.replace(commaRegex, "").split(".");
    const parsedIntegerValue = parseFloat(inputValue[0]);
    const decimalValue =
      inputValue[1] !== undefined ? `.${inputValue[1].slice(0, 2)}` : "";
    const integerPart = isNaN(parsedIntegerValue)
      ? ""
      : parsedIntegerValue.toLocaleString("en-US");

    return `${integerPart}${decimalValue}`;
  };
  const [searchedWorkOrderNumber, setSearchedWorkOrderNumber] = useState("");

  const formik = useFormik({
    initialValues: getInitialJobFormValues(jobData),
    validationSchema: validationJobForm(isEditing),
    onSubmit: handleSubmit,
  });

  const handleTextFieldsFocus = (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const fieldValue = event.target.value;
    const hasDefaultValue = DEFAULT_JOB_FORM_VALUES.includes(fieldValue);

    if (isEditing && !hasDefaultValue) {
      return;
    }

    const fieldName = event.target.name as keyof JobFormFields;

    if (!formik.touched[fieldName]) {
      formik.setFieldValue(fieldName, "");
    }
  };

  const workOrderNumbers = ["123", "456", "789"];

  const handleChangeSearch = useCallback((workOrderNumber: string) => {
    setSearchedWorkOrderNumber(workOrderNumber);
  }, []);

  const filterWorkOrderNumberSearchResult = (workOrderNumbers: []) => {
    if (searchedWorkOrderNumber) {
      return workOrderNumbers.filter(
        ({ workOrderNumber }) => workOrderNumber === searchedWorkOrderNumber,
      );
    }
    return workOrderNumbers;
  };

  const workOrderNumberColumns: MainTableColumn[] = [
    {
      field: "checkbox",
      headerName: "",
    },
    {
      field: "Work Order Number",
      headerName: "",
    },
  ];

  const getAssignWorkOrderNumberRows = (
    workOrderNumbers: ["123", "456", "789"],
    workOrderNumberSelected: string,
    onChangeRadio: (idRow: string, isChecked: boolean) => void,
  ): MainTableRow[] =>
    workOrderNumbers.map((workOrderNumber) => {
      if (workOrderNumber) {
        const workOrderNumber = { value: `${workOrderNumbers}` };

        const idRow = workOrderNumber ? workOrderNumber.toString() : "";
        const innerCells = [workOrderNumber];
        const isCheckedRow = workOrderNumberSelected === idRow;

        return {
          idRow,
          innerCells,
          isCheckedRow,
          onChangeRadio,
        };
      }
      return {} as MainTableRow;
    });

  const availableWorkOrderNumbers = workOrderNumbers.filter(
    (workOrderNumber) => {
      return workOrderNumber;
    },
  );

  return (
    <form autoComplete="off" onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        <Grid item md={8} xs={12}>
          <TextFieldCustom
            error={formik.touched.address && !!formik.errors.address}
            errorMessage="Address is required"
            fullWidth
            id="address"
            label={DEFAULT_ADDRESS}
            placeholder="Address"
            {...formik.getFieldProps("address")}
            onFocus={handleTextFieldsFocus}
          />
        </Grid>

        <Grid item md={4} xs={12}>
          <SelectCustom
            defaultValue={0}
            error={formik.touched.regionId && !!formik.errors.regionId}
            errorMessage="Select a Region"
            labelId="regionId"
            fullWidth
            id="regionId"
            label="Region"
            {...formik.getFieldProps("regionId")}
          >
            <MenuItem value={0}>{DEFAULT_EMPTY}</MenuItem>
            {parseRegions(regions).map((region: DropdownOption) => (
              <MenuItem key={region.value} value={region.value}>
                {region.label}
              </MenuItem>
            ))}
          </SelectCustom>
        </Grid>
      </Grid>

      <Box padding="24px 0 16px 0">
        <Typography className={classes.infoLabel} variant="body1">
          General Info
        </Typography>
      </Box>

      <Grid container spacing={2}>
        <Grid item md={4} sm={6} xs={12}>
          <SelectCustom
            defaultValue={DEFAULT_SELECT_OPTION}
            error={formik.touched.category && !!formik.errors.category}
            errorMessage="Select a Category"
            labelId="category"
            fullWidth
            id="category"
            label="Category"
            {...formik.getFieldProps("category")}
          >
            <MenuItem value={DEFAULT_SELECT_OPTION}>{DEFAULT_EMPTY}</MenuItem>
            {categoryOptions.map((option: DropdownOption) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </SelectCustom>
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <SelectCustom
            defaultValue={DEFAULT_SELECT_OPTION}
            error={formik.touched.jobType && !!formik.errors.jobType}
            errorMessage="Select a Job Type"
            labelId="jobType"
            fullWidth
            id="jobType"
            label="Job Type"
            {...formik.getFieldProps("jobType")}
          >
            <MenuItem value={DEFAULT_SELECT_OPTION}>{DEFAULT_EMPTY}</MenuItem>
            {jobTypesOptions.map((option: DropdownOption) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </SelectCustom>
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <SelectCustom
            defaultValue={DEFAULT_SELECT_OPTION}
            labelId="companyName"
            fullWidth
            id="companyName"
            label="Company Name"
            {...formik.getFieldProps("companyName")}
          >
            <MenuItem value={DEFAULT_SELECT_OPTION}>{DEFAULT_EMPTY}</MenuItem>
            {companiesOptions.map((option: DropdownOption) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </SelectCustom>
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <SelectCustom
            defaultValue={DEFAULT_SELECT_OPTION}
            error={
              formik.touched.workOrderType && !!formik.errors.workOrderType
            }
            errorMessage="Select Work Order Type"
            labelId="workOrderType"
            fullWidth
            id="workOrderType"
            label="Work Order Type"
            {...formik.getFieldProps("workOrderType")}
          >
            <MenuItem value={DEFAULT_SELECT_OPTION}>{DEFAULT_EMPTY}</MenuItem>
            {workOrderTypeOptions.map((option: DropdownOption) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </SelectCustom>
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <DatePicker
            autoOk
            error={formik.touched.startDate && !!formik.errors.startDate}
            errorMessage={
              (formik.errors.startDate as string) || "Invalid Start Date"
            }
            id="startDate"
            label="Start Date"
            placeholder="MM/DD/YYYY"
            {...formik.getFieldProps("startDate")}
            onChange={(date: MaterialUiPickersDate) => {
              if (date) {
                formik.setFieldValue("startDate", date);
              }
            }}
          />
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <DatePicker
            autoOk
            error={formik.touched.endDate && !!formik.errors.endDate}
            errorMessage={
              (formik.errors.endDate as string) || "Invalid End Date"
            }
            id="endDate"
            label="Estimated End Date"
            placeholder="MM/DD/YYYY"
            {...formik.getFieldProps("endDate")}
            onChange={(date: MaterialUiPickersDate) => {
              if (date) {
                const newEndDate = new Date(date.getTime());
                newEndDate.setHours(23, 59, 59, 0);
                formik.setFieldValue("endDate", newEndDate);
              }
            }}
          />
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <TextFieldCustom
            fullWidth
            id="fcc"
            label="Client name"
            placeholder={DEFAULT_NAME}
            {...formik.getFieldProps("fcc")}
            onFocus={handleTextFieldsFocus}
          />
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          <TextFieldCustom
            fullWidth
            id="workOrderNumber"
            label="Work Order Number"
            placeholder={"Work order number"}
            {...formik.getFieldProps("workOrderNumber")}
            onFocus={handleTextFieldsFocus}
          />
        </Grid>
        {/* 
        //TODO WorkOrderNumber dropdown search selection. 
        */}
        {/* <Grid item md={8} xs={12}>
          <SearchByNameOrId
            filteredEmployees={["123", "456", "789"] as any}
            onChange={handleChangeSearch}
          />
          <TableCustom
            columns={workOrderNumberColumns}
            isLoading={false}
            noRowsMessage="There are no available Work Order Number to assign."
            rows={getAssignWorkOrderNumberRows(
              workOrderNumbers as any,
              workOrderNumberSelected as any,
              onClickCheckbox as any,
            )}
            onClickSort={noop}
          />
        </Grid> */}
        {/* //* Client requested to comment Contract Type selection, the options were moved to Job Type.
         */}
        {/* <Grid item md={4} sm={6} xs={12}>
          <SelectCustom
            defaultValue={DEFAULT_SELECT_OPTION}
            error={formik.touched.contractType && !!formik.errors.contractType}
            errorMessage="Select a Job Type"
            fullWidth
            id="contractType"
            label="Contract Type"
            labelId="contractType"
            {...formik.getFieldProps("contractType")}
          >
            <MenuItem value={DEFAULT_SELECT_OPTION}>{DEFAULT_EMPTY}</MenuItem>
            {contractTypeOptions.map((option: DropdownOption) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </SelectCustom>
        </Grid> */}
      </Grid>

      <Box padding="24px 0 16px 0">
        <Typography className={classes.infoLabel} variant="body1">
          Cost Info
        </Typography>
      </Box>

      <Grid container spacing={2}>
        <Grid item md={4} sm={6} xs={12}>
          <TextFieldCustom
            error={formik.touched.labors && !!formik.errors.labors}
            errorMessage="Invalid Labor value"
            fullWidth
            id="labors"
            label="Labors"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
            {...formik.getFieldProps("labors")}
            onFocus={handleTextFieldsFocus}
            onChange={(
              event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
            ) => {
              formik.setFieldValue(
                "labors",
                handleUpdateCurrencyFields(event.target.value),
              );
            }}
          />
        </Grid>

        <Grid item md={4} sm={6} xs={12}>
          <TextFieldCustom
            error={formik.touched.materials && !!formik.errors.materials}
            errorMessage="Invalid Materials value"
            fullWidth
            id="materials"
            label="Materials"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
            {...formik.getFieldProps("materials")}
            onFocus={handleTextFieldsFocus}
            onChange={(
              event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
            ) => {
              formik.setFieldValue(
                "materials",
                handleUpdateCurrencyFields(event.target.value),
              );
            }}
          />
        </Grid>

        <Grid item md={4} sm={6} xs={12}>
          <TextFieldCustom
            error={formik.touched.other && !!formik.errors.other}
            errorMessage="Invalid Other value"
            fullWidth
            id="other"
            label="Other"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
            {...formik.getFieldProps("other")}
            onFocus={handleTextFieldsFocus}
            onChange={(
              event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
            ) => {
              formik.setFieldValue(
                "other",
                handleUpdateCurrencyFields(event.target.value),
              );
            }}
          />
        </Grid>

        <Grid item md={4} sm={6} xs={12}>
          <TextFieldCustom
            error={
              formik.touched.estimatedCost && !!formik.errors.estimatedCost
            }
            errorMessage="Invalid Estimated Cost value"
            fullWidth
            id="estimatedCost"
            label="Estimated Cost"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
            {...formik.getFieldProps("estimatedCost")}
            onFocus={handleTextFieldsFocus}
            onChange={(
              event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
            ) => {
              formik.setFieldValue(
                "estimatedCost",
                handleUpdateCurrencyFields(event.target.value),
              );
            }}
          />
        </Grid>

        <Grid item md={4} sm={6} xs={12}>
          <TextFieldCustom
            fullWidth
            id="inspector"
            label="Inspector"
            placeholder={DEFAULT_NAME}
            {...formik.getFieldProps("inspector")}
            onFocus={handleTextFieldsFocus}
          />
        </Grid>

        <Grid item md={4} sm={6} xs={12}>
          <TextFieldCustom
            error={formik.touched.billedTo && !!formik.errors.billedTo}
            errorMessage="Invalid Email"
            fullWidth
            id="billedTo"
            label="Billed To"
            placeholder={DEFAULT_BILLED_TO}
            {...formik.getFieldProps("billedTo")}
            onFocus={handleTextFieldsFocus}
          />
        </Grid>
      </Grid>

      <Box padding="32px 0 24px 0">
        <Typography className={classes.descriptionLabel} variant="h6">
          Description
        </Typography>
      </Box>

      <TextFieldCustom
        fullWidth
        id="description"
        multiline
        placeholder={DEFAULT_DESCRIPTION}
        rows={3}
        rowsMax={3}
        {...formik.getFieldProps("description")}
        onFocus={handleTextFieldsFocus}
      />

      <Button
        className={classes.submitButton}
        id="submitJobForm-btn"
        type="submit"
      />
    </form>
  );
}
