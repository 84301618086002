import { EmployeeModel } from "@dwo/shared/dist/models/employeeModel";

export enum EmployeeInformationLabels {
  firstName = "First Name",
  lastName = "Last Name",
  class = "Specialist Field",
  companyName = "Company Name",
  employeeId = "Employee Id",
  email = "Email",
  workPhone = "Work Phone",
}

const getFieldInformation = (employee: EmployeeModel) => {
  const employeeEntries = Object.entries(employee);
  return employeeEntries
    .map(([key, value]): string[] | undefined => {
      switch (key) {
        case "firstName":
          return [key, EmployeeInformationLabels.firstName, value];
        case "lastName":
          return [key, EmployeeInformationLabels.lastName, value];
        case "class":
          return [key, EmployeeInformationLabels.class, value];
        case "companyName":
          return [key, EmployeeInformationLabels.companyName, value];
        case "employeeId":
          return [key, EmployeeInformationLabels.employeeId, value];
        case "email":
          return [key, EmployeeInformationLabels.email, value];
        case "workPhone":
          return [key, EmployeeInformationLabels.workPhone, value];
        default:
          return undefined;
      }
    })
    .filter((entry): entry is string[] => entry !== undefined);
};

export const findFieldInformation = (key: string, employee: EmployeeModel) =>
  getFieldInformation(employee).find(([fieldKey]) => fieldKey === key);

export const personalInfoFields = [
  "firstName",
  "lastName",
  "class",
  "companyName",
  "employeeId",
];
export const supervisorInfoFields = [
  "firstName",
  "lastName",
  "employeeId",
  "email",
];

export const contactInfoFields = ["email", "workPhone"];
