import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
  Grid,
  Paper,
  TextField,
  InputAdornment,
  Card,
  IconButton,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import SearchIcon from "@material-ui/icons/Search";
import { Dropdown } from "components/Dropdown";
import { Layout } from "components/layout/Layout";
import React, { ChangeEvent, useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  selectNotifications,
  selectIsLoading,
  getAllNotifications,
  selectLimit,
  selectCount,
  deleteNotification,
  deleteAllNotifications,
  setAlertJobId,
  setComesFromAlerts,
} from "features/notifications/notificationSlice";
import { NotificationModel } from "@dwo/shared/dist/models/notificationModel";
import { RangeDatePicker } from "components/RangeDatePicker";
import { DateRange } from "materialui-daterange-picker";
import { ServiceOptions } from "@dwo/shared/dist/services/baseService";
import { slashFormatDate } from "utils/dateUtils";
import { DropdownPagination } from "components/DropdownPagination";
import { CustomPagination as Pagination } from "components/Pagination";
import { DEFAULT_LIMIT } from "utils/sharedUtils";
import {
  getTotalPages,
  typeOptions,
  alertOptions as options,
} from "utils/notificationUtils";
import { rowSelectOptions } from "utils/tableUtils";
import { v4 as uuid } from "uuid";
import { ROOT_TIMESHEETS_MANAGEMENT } from "routes/Roots";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      fontSize: "32px",
      color: theme.palette.primary.main,
      fontWeight: 600,
    },
    subHeader: {
      fontSize: "16px",
      color: theme.palette.primary.main,
      paddingLeft: "24px",
      fontWeight: 600,
    },
    summaryText: {
      fontSize: "16px",
      color: "#2D72E0",
      paddingLeft: "10px",
    },
    paper: {
      margin: "0 64px 0 24px",
      paddingBottom: "20px",
    },
    filterContainer: {
      padding: "24px 24px 16px",
    },
    upperBox: {
      display: "flex",
      justifyContent: "space-between",
    },
    today: {
      color: "#525B79",
      padding: "0 0 0 40px",
      fontWeight: "bold",
    },
    clearAll: {
      color: "#2D72E0",
      padding: "0 40px 0 0",
      "&:hover": {
        cursor: "pointer",
      },
    },
    notificationsWrapper: {
      paddingTop: "15px",
      marginBottom: "16px",
      display: "flex",
      justifyContent: "center",
    },
    notificationCard: {
      width: "95%",
      height: "100px",
      marginBottom: "10px",
    },
    gridContainer: {
      width: "100%",
      height: "100%",
    },
    leftSection: {
      width: "3%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    rightSection: {
      width: "97%",
      display: "flex",
      flexDirection: "row",
    },
    notificationInfo: {
      width: "80%",
      height: "100%",
    },
    titleText: {
      fontSize: "18px",
      color: "#C5C5C9",
    },
    linkText: {
      color: "#2D72E0",
    },
    fakeLinkText: {
      color: "#2D72E0",
      fontSize: "14px",
      fontWeight: 400,
      textDecoration: "underline",
      cursor: "pointer",
    },
    bottomText: {
      fontSize: "12px",
      color: "#929294",
      padding: "0 5px 0 10px",
      textAlign: "justify",
    },
    notificationRight: {
      width: "20%",
      height: "100%",
    },
    notificationClose: {
      height: "50%",
      width: "100%",
      display: "flex",
      justifyContent: "flex-end",
    },
    notificationTime: {
      height: "50%",
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
    },
    timeText: {
      fontSize: "14px",
      color: "#929294",
      paddingRight: "17px",
    },
    notificationTop: {
      height: "50%",
      display: "flex",
    },
    notificationTitle: {
      height: "100%",
      width: "auto",
      display: "flex",
      alignItems: "center",
      paddingLeft: "10px",
    },
    notificationViewMore: {
      height: "100%",
      width: "auto",
      display: "flex",
      alignItems: "center",
      paddingLeft: "15px",
    },
    notificationBottom: {
      height: "50%",
      display: "flex",
    },
    closeIcon: {
      color: "#838385",
    },
    iconButton: {
      alignSelf: "flex-start",
      padding: "8px 8px",
      margin: "10px 5px 0 0",
    },
    paginationContainer: {
      borderRadius: "2px",
      margin: "24px 64px 24px 24px",
    },
    rowSelectLabel: {
      color: theme.palette.primary.dark,
      fontWeight: "bold",
    },
  }),
);

export function NotificationsScreen() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();

  const [searchByJob, setSearchByJob] = useState<string>("");
  const [dropdownValue, setDropdownValue] = useState<string>("");
  const [query, setQuery] = useState<ServiceOptions>({
    limit: DEFAULT_LIMIT,
  });
  const [page, setPage] = useState<number>(1);
  const [rowsPerPage, setRowsPerPage] = useState<string>(
    rowSelectOptions[0].value,
  );

  const notifications = useSelector(selectNotifications);
  const isLoading = useSelector(selectIsLoading);
  const currentLimit = useSelector(selectLimit) || DEFAULT_LIMIT;
  const currentCount = useSelector(selectCount);

  useEffect(() => {
    dispatch(getAllNotifications(query));
  }, [dispatch, query]);

  const handleChangeSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value.length > 0) {
      const updatedQuery = { ...query };
      updatedQuery.offset = 0;
      updatedQuery.where = {
        ...query.where,
        searchData: value,
      };

      setPage(1);
      setSearchByJob(value);
      setQuery(updatedQuery);
    } else {
      const updatedQuery = { ...query };
      updatedQuery.offset = 0;
      delete updatedQuery.where;

      setPage(1);
      setSearchByJob(value);
      setQuery(updatedQuery);
    }
  };

  const handleDelete = (id: number) => dispatch(deleteNotification(id));

  const handleSelectFilter = (selectedFilter: string) => {
    if (selectedFilter.length > 0) {
      const updatedQuery = { ...query };
      updatedQuery.offset = 0;
      updatedQuery.where = {
        ...query.where,
        type: selectedFilter,
      };

      setPage(1);
      setDropdownValue(selectedFilter);
      setQuery(updatedQuery);
    } else {
      const updatedQuery = { ...query };
      updatedQuery.offset = 0;
      delete updatedQuery.where;

      setPage(1);
      setDropdownValue(selectedFilter);
      setQuery(updatedQuery);
    }
  };

  const handleClickApplyRangeDate = (dateRange: DateRange) => {
    const startDate = dateRange.startDate;
    const endDate = dateRange.endDate;
    startDate?.setHours(0, 0, 0, 0);
    endDate?.setHours(23, 59, 59, 0);
    const updatedQuery = { ...query };
    updatedQuery.offset = 0;

    if (startDate && endDate) {
      updatedQuery.where = {
        ...query.where,
        createdAt: { $gte: startDate, $lte: endDate },
      };
    } else {
      if (updatedQuery.where?.createdAt) {
        delete updatedQuery.where.createdAt;
      }
    }

    setQuery(updatedQuery);
  };

  const handleChangePage = (newPage: number) => {
    const updatedQuery = { ...query };
    updatedQuery.offset = (newPage - 1) * currentLimit;
    setPage(newPage);
    setQuery(updatedQuery);
  };

  const handleSelectRowsPerPage = (selectedValue: string) => {
    const updatedQuery = { ...query };
    updatedQuery.offset = 0;
    updatedQuery.limit = parseInt(selectedValue, 10);

    setPage(1);
    setRowsPerPage(selectedValue);
    setQuery(updatedQuery);
  };

  const handleClearAllAlerts = () => dispatch(deleteAllNotifications());

  const handleClickFakeLink = (alert: NotificationModel) => () => {
    try {
      // TODO: This maybe dwoJobId when back changes it
      dispatch(setAlertJobId(alert.jobId));
      dispatch(setComesFromAlerts(true));
      history.push(`${ROOT_TIMESHEETS_MANAGEMENT}/${alert.dwoId}`);
    } catch (error) {
      // silent error
    }
  };

  return (
    <Layout>
      <Box padding="32px 24px 18px 24px">
        <Typography className={classes.header} component="h1">
          Notifications
        </Typography>
      </Box>
      <Box>
        <Grid container>
          <Grid item>
            <Typography className={classes.subHeader} component="h6">
              Notifications
            </Typography>
          </Grid>
          <Grid item>
            <Typography className={classes.summaryText} component="h6">
              /Summary
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Paper className={classes.paper}>
        <Box className={classes.filterContainer}>
          <Grid alignItems="center" container spacing={4}>
            <Grid item md={7} xs={12}>
              <TextField
                value={searchByJob}
                placeholder="Search by Job Id"
                margin="normal"
                variant="outlined"
                InputProps={{
                  type: "search",
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                onChange={handleChangeSearch}
              />
            </Grid>

            <Grid item md={3} sm={3} xs={12}>
              <RangeDatePicker onClickApply={handleClickApplyRangeDate} />
            </Grid>

            <Grid item md={2} xs={12}>
              <Dropdown
                id="statusDropdown"
                label="Status"
                options={typeOptions}
                selectedValue={dropdownValue}
                onSelectOption={handleSelectFilter}
              />
            </Grid>
          </Grid>
        </Box>
        <Box className={classes.upperBox}>
          <Typography className={classes.today}>Today</Typography>
          <Typography
            className={classes.clearAll}
            onClick={handleClearAllAlerts}
          >
            Clear all
          </Typography>
        </Box>
        <Grid container className={classes.notificationsWrapper}>
          {!isLoading &&
            notifications.map((alert: NotificationModel) => (
              <Card
                className={classes.notificationCard}
                key={`alert-${uuid()}`}
              >
                <Grid container className={classes.gridContainer}>
                  <Grid
                    item
                    className={classes.leftSection}
                    style={{ backgroundColor: options[alert.type].color }}
                  >
                    {options[alert.type].icon}
                  </Grid>
                  <Grid item className={classes.rightSection}>
                    <Grid item className={classes.notificationInfo}>
                      <Grid item className={classes.notificationTop}>
                        <Grid item className={classes.notificationTitle}>
                          <Typography
                            className={classes.titleText}
                            component="h6"
                            variant="h6"
                          >
                            {options[alert.type].title}
                          </Typography>
                        </Grid>
                        <Grid item className={classes.notificationViewMore}>
                          {alert.type === "pictureMismatch" ||
                          alert.type === "locationMismatch" ? (
                            <Typography
                              className={classes.fakeLinkText}
                              onClick={handleClickFakeLink(alert)}
                            >
                              View More
                            </Typography>
                          ) : (
                            <Link
                              className={classes.linkText}
                              to={options[alert.type].redirect(alert.jobId)}
                            >
                              View More
                            </Link>
                          )}
                        </Grid>
                      </Grid>
                      <Grid item className={classes.notificationBottom}>
                        <Typography className={classes.bottomText}>
                          {alert.data &&
                            options[alert.type].message(alert.data)}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item className={classes.notificationRight}>
                      <Grid item className={classes.notificationClose}>
                        {alert.id && (
                          <IconButton
                            className={classes.iconButton}
                            onClick={() => handleDelete(Number(alert.id))}
                          >
                            <Close className={classes.closeIcon} />
                          </IconButton>
                        )}
                      </Grid>
                      <Grid item className={classes.notificationTime}>
                        <Typography className={classes.timeText}>
                          {alert.createdAt &&
                            slashFormatDate(new Date(alert.createdAt))}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Card>
            ))}
        </Grid>
      </Paper>
      <Paper className={classes.paginationContainer}>
        <Box padding="12px 24px">
          <Grid alignItems="center" container spacing={4}>
            <Grid item xs={4} />
            <Grid item xs={4}>
              <Box display="flex" justifyContent="center">
                <Pagination
                  id="usersManagement-pagination"
                  isDisabled={isLoading || currentCount === 0}
                  page={page}
                  totalPages={getTotalPages(currentCount, currentLimit)}
                  onChangePage={handleChangePage}
                />
              </Box>
            </Grid>

            <Grid item xs={4}>
              <Box display="flex" alignItems="center" justifyContent="flex-end">
                <Box marginRight="8px">
                  <Typography
                    variant="caption"
                    className={classes.rowSelectLabel}
                  >
                    Rows per page:
                  </Typography>
                </Box>
                <DropdownPagination
                  id="rowsDropdown"
                  isDisabled={isLoading || currentCount === 0}
                  options={rowSelectOptions}
                  selectedValue={rowsPerPage}
                  onSelectOption={handleSelectRowsPerPage}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Layout>
  );
}
