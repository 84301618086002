import DateFnsUtils from "@date-io/date-fns";
import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import { Error } from "@material-ui/icons";
import {
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { TooltipCustom } from "components/TooltipCustom";
import {
  removeValueByIdAndType,
  setValues,
} from "features/employeeTimesheet/employeeTimesheetSlice";
import { useFormik } from "formik";
import { noop } from "lodash/fp";
import React, { ReactNode, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";

interface TableTimePickerTimeOffProps {
  type: string;
  id: string;
  isTimeOffChecked: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textField: {
      height: "39px",
      width: "105px",
      "& .MuiInputBase-root": {
        width: "105px",
        height: "39px",
      },
      "& .MuiPickersToolbar-toolbar": {
        backgroundColor: theme.palette.primary.main,
      },
    },
    errorMessage: {
      color: theme.palette.error.main,
      paddingTop: "4px",
    },
    errorIcon: {
      position: "absolute",
      right: "-28px",
      bottom: "6px",
    },
  }),
);

export function TableTimePickerTimeOff({
  type,
  id,
  isTimeOffChecked,
}: TableTimePickerTimeOffProps) {
  const dispatch = useDispatch();
  const [inputError, setInputError] = useState<boolean | undefined>(undefined);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [formattedTime, setFormattedTime] = useState("");
  const isFirstRun = useRef(true);
  const classes = useStyles();

  const formik = useFormik({
    initialValues: {
      time: new Date()
        .toLocaleTimeString(undefined, {
          hour: "2-digit",
          minute: "2-digit",
        })
        .split(" ")[0],
    },
    validationSchema: Yup.object({
      time: Yup.string().required("Time is required"),
    }),
    onSubmit: noop,
  });

  useEffect(() => {
    if (formik.isValid && formattedTime !== "Invalid") {
      dispatch(
        setValues({
          id: type,
          idRow: Number(id),
          inputValue: formattedTime,
          inputError,
        }),
      );
    }
  }, [dispatch, formattedTime, inputError, type, id, formik.isValid]);

  useEffect(() => {
    if (inputError) {
      dispatch(removeValueByIdAndType({ id: Number(id), type }));
    }
  }, [dispatch, type, id, inputError]);

  useEffect(() => {
    if (!isTimeOffChecked) {
      setInputError(undefined);
    }
  }, [isTimeOffChecked]);

  useEffect(() => {
    if (selectedDate !== null && selectedDate.toString() !== "Invalid Date") {
      setFormattedTime(selectedDate.toString());
      setInputError(false);
    }

    if (selectedDate === null || selectedDate.toString() === "Invalid Date") {
      setInputError(true);
    }
  }, [selectedDate]);

  useEffect(() => {
    if (isFirstRun.current) return;

    if (!isFirstRun.current) {
      formik.setFieldValue("time", formattedTime);
    }
    // eslint-disable-next-line
  }, [formattedTime, isFirstRun]);

  const handleDateChange = (date: MaterialUiPickersDate) => {
    setSelectedDate(date as Date);
    isFirstRun.current = false;
  };

  const handleChangeError = (error: ReactNode) => {
    setInputError(Boolean(error));
  };

  return (
    <Box flex={1} maxWidth="200px">
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardTimePicker
          margin="normal"
          value={selectedDate}
          className={classes.textField}
          placeholder="__:__"
          format="hh:mm"
          helperText=""
          onError={handleChangeError}
          onChange={handleDateChange}
        />
      </MuiPickersUtilsProvider>
      {inputError && (
        <Box position="relative">
          <TooltipCustom
            title={
              <Typography className={classes.errorMessage} variant="subtitle2">
                Input valid time
              </Typography>
            }
            placement="right"
          >
            <Error className={classes.errorIcon} color="error" />
          </TooltipCustom>
        </Box>
      )}
    </Box>
  );
}
