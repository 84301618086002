import {
  Box,
  Button,
  createStyles,
  Divider,
  Grid,
  makeStyles,
  Paper,
  Theme,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { Layout } from "components/layout/Layout";
import { LoadingSpinner } from "components/LoadingSpinner";
import { TableCustom } from "components/table/TableCustom";
import {
  getUploadHistoryById,
  selectIsLoading,
  selectUploadHistoryDetails,
  selectUploadErrors,
  getAllUploadErrors,
  selectIsLoadingErrors,
  getUploadErrorsCSV,
} from "features/upload/uploadEmployeeSlice";
import { cloneDeep } from "lodash";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { ServiceOptions } from "@dwo/shared/dist/services/baseService";
import { MainTableRow } from "utils/tableUtils";
import {
  getUploadErrorsTableRows,
  uploadDetailsColumns,
} from "utils/uploadDetailsUtils";
import { slashFormatDateWithHours } from "utils/dateUtils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    caption: {
      fontSize: "14px",
      color: theme.palette.primary.dark,
      fontWeight: "bold",
      marginBottom: "16px",
      paddingTop: "16px",
    },
    datePickerContainer: {
      justifyContent: "flex-end",
      padding: "24px 24px 24px 0",
      [theme.breakpoints.down("xs")]: {
        justifyContent: "flex-start",
        padding: "0 24px 24px 24px",
      },
    },
    description: {
      color: theme.palette.grey[500],
    },
    errorsContainer: {
      backgroundColor: "white",
      display: "flex",
      flexDirection: "column",
    },
    errorDescription: {
      color: theme.palette.error.main,
    },
    header: {
      fontSize: "32px",
      color: theme.palette.primary.main,
      fontWeight: 600,
    },
    historyContainer: {
      backgroundColor: "white",
      display: "flex",
      flexDirection: "column",
      padding: "24px",
    },
    subheader: {
      fontSize: "20px",
      color: theme.palette.primary.main,
      fontWeight: 400,
      marginBottom: "16px",
    },
  }),
);

export function UploadDetails() {
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const uploadId = parseInt(id, 10);
  const isLoading = useSelector(selectIsLoading);
  const isLoadingErrors = useSelector(selectIsLoadingErrors);
  const uploadedFile = useSelector(selectUploadHistoryDetails);
  const uploadErrors = useSelector(selectUploadErrors);
  const [query, setQuery] = useState<ServiceOptions>({
    where: { uploadEmployeeFileId: uploadId },
  });
  const [sortingOptions, setSortingOptions] = useState<string[][]>([]);
  const classes = useStyles();

  const correctlyImported = uploadedFile?.rowsCorrectlyImported || 0;
  const ignored = uploadedFile?.rowsIgnored || 0;
  const importErrors = uploadedFile?.rowsIncorrectlyImported || 0;
  const latestStatusDate = uploadedFile?.updatedAt
    ? slashFormatDateWithHours(new Date(uploadedFile.updatedAt))
    : "No Latest Status Date";
  const totalColumns = uploadedFile?.totalColumns || 0;
  const totalRows = uploadedFile?.totalRows || 0;
  const rows: MainTableRow[] = getUploadErrorsTableRows(uploadErrors);
  const uploadStatus = isLoading
    ? "Loading..."
    : uploadedFile?.uploadStatus || "No status";

  useEffect(() => {
    dispatch(getUploadHistoryById(uploadId));
  }, [uploadId, dispatch]);

  useEffect(() => {
    dispatch(getAllUploadErrors(query));
  }, [query, dispatch]);

  const handleClickDownload = () => {
    dispatch(getUploadErrorsCSV(uploadId));
  };

  const handleClickSort = (sortingValues: string[][]) => {
    setSortingOptions(sortingValues);
    setQuery((prevQuery) => {
      return {
        ...cloneDeep(prevQuery),
        order: sortingValues.length > 0 ? sortingValues : undefined,
      };
    });
  };

  return (
    <Layout>
      <Box padding="32px 24px 24px 24px">
        <Typography className={classes.header} component="h1">
          {uploadStatus}
        </Typography>
      </Box>

      <Box padding="0 24px">
        <Paper className={classes.historyContainer}>
          <Grid container>
            <Grid item xs={12}>
              <Typography className={classes.subheader}>
                Upload History
              </Typography>
            </Grid>

            {isLoading ? (
              <Grid item xs={12}>
                <LoadingSpinner />
              </Grid>
            ) : (
              <Fragment>
                <Grid item xs={12}>
                  <Typography className={classes.caption}>
                    COLUMNS SUMMARY
                  </Typography>
                  <Divider />
                </Grid>

                <Grid item xs={2}>
                  <Box
                    className={classes.description}
                    display="flex"
                    justifyContent="space-between"
                    padding="16px 0 32px 0"
                  >
                    <Box>Total Columns:</Box>
                    <Box>{totalColumns}</Box>
                  </Box>
                </Grid>

                <Grid item xs={12}>
                  <Typography className={classes.caption}>
                    ROWS SUMMARY
                  </Typography>
                  <Divider />
                </Grid>

                <Grid item xs={2}>
                  <Box
                    className={classes.description}
                    display="flex"
                    justifyContent="space-between"
                    paddingTop="16px"
                    width="100%"
                  >
                    <Box>Total Rows:</Box>
                    <Box>{totalRows}</Box>
                  </Box>
                  <Box
                    className={classes.description}
                    display="flex"
                    justifyContent="space-between"
                    paddingTop="16px"
                    width="100%"
                  >
                    <Box>Correctly Imported:</Box>
                    <Box>{correctlyImported}</Box>
                  </Box>
                  <Tooltip
                    title="Not-Active Employees that are not already on the system are ignored"
                    arrow
                  >
                    <Box
                      className={classes.description}
                      display="flex"
                      justifyContent="space-between"
                      paddingTop="16px"
                      width="100%"
                    >
                      <Box>Ignored:</Box>
                      <Box>{ignored}</Box>
                    </Box>
                  </Tooltip>
                  <Box
                    className={classes.errorDescription}
                    display="flex"
                    justifyContent="space-between"
                    padding="16px 0 32px 0"
                    width="100%"
                  >
                    <Box>Import Errors:</Box>
                    <Box>{importErrors}</Box>
                  </Box>
                </Grid>

                <Grid item xs={12}>
                  <Typography className={classes.caption}>
                    LATEST STATUS DATE
                  </Typography>
                  <Divider />
                  <Box className={classes.description} paddingTop="16px">
                    {latestStatusDate}
                  </Box>
                </Grid>
              </Fragment>
            )}
          </Grid>
        </Paper>
      </Box>

      <Box padding="24px">
        <Paper className={classes.errorsContainer}>
          <Box padding="24px">
            <Typography className={classes.subheader}>Upload Errors</Typography>
          </Box>

          <Box margin="0 24px 24px 24px" maxWidth="160px">
            <Button
              disabled={isLoading}
              variant="outlined"
              onClick={handleClickDownload}
            >
              Download Errors
            </Button>
          </Box>

          <TableCustom
            columns={uploadDetailsColumns}
            isLoading={isLoadingErrors}
            noRowsMessage="There are no upload errors to display."
            rows={rows}
            sortingOptions={sortingOptions}
            onClickSort={handleClickSort}
          />
        </Paper>
      </Box>
    </Layout>
  );
}
