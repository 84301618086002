import { Box } from "@material-ui/core";
import React from "react";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export function TabContent({
  children,
  value,
  index,
  ...other
}: TabPanelProps) {
  return (
    <Box
      role="tabPanel"
      hidden={value !== index}
      id={`tabPanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <React.Fragment>{children}</React.Fragment>}
    </Box>
  );
}
