import React from "react";
import {
  Card,
  Box,
  Typography,
  makeStyles,
  Theme,
  createStyles,
} from "@material-ui/core";
import { DEFAULT_EMPTY } from "utils/jobUtils";
import { slashFormatDate } from "utils/dateUtils";
import { useHistory } from "react-router";
import { ROOT_TIMESHEETS_MANAGEMENT, ROOT_USER_MANAGEMENT } from "routes/Roots";
import {
  CrewTimesheetManagement,
  crewHeadersStyles,
} from "utils/timesheetsManagementUtils";
import { ArrowForwardIos } from "@material-ui/icons";

interface CrewTimesheetCardProps {
  crewTimesheetData: CrewTimesheetManagement;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      color: theme.palette.grey["500"],
      overflowWrap: "anywhere",
      paddingRight: "4px",
      textTransform: "capitalize",
    },
    link: {
      color: theme.palette.primary.light,
      overflowWrap: "anywhere",
      paddingRight: "4px",
      textDecoration: "underline",
      cursor: "pointer",
    },
    viewMore: {
      cursor: "pointer",
    },
    crewDetails: {
      color: theme.palette.primary.dark,
      fontWeight: "bold",
    },
    chebronIcon: {
      color: theme.palette.primary.dark,
    },
  }),
);

export function CrewTimesheetCard({
  crewTimesheetData,
}: CrewTimesheetCardProps) {
  const history = useHistory();
  const headersStyles = crewHeadersStyles();
  const classes = useStyles();

  const handleClickEmployeeName = (id: number) => () => {
    history.push(`${ROOT_USER_MANAGEMENT}/${id}`);
  };

  const handleClickCrewDetails = () => {
    history.push(
      `${ROOT_TIMESHEETS_MANAGEMENT}/${crewTimesheetData.crewDetailId}`,
    );
  };

  return (
    <Card>
      <Box display="flex" padding="16px 24px">
        <Box
          display="flex"
          alignItems="center"
          className={headersStyles.standardHeader}
        >
          <Typography variant="body2" className={classes.title}>
            {slashFormatDate(new Date(crewTimesheetData.date))}
          </Typography>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          className={headersStyles.standardHeader}
        >
          {crewTimesheetData.foreman ? (
            <Typography
              variant="body2"
              className={classes.link}
              onClick={handleClickEmployeeName(
                crewTimesheetData.foreman.id as number,
              )}
            >
              {crewTimesheetData.foreman.fullName}
            </Typography>
          ) : (
            <Typography variant="body2" className={classes.title}>
              {DEFAULT_EMPTY}
            </Typography>
          )}
        </Box>
        <Box
          display="flex"
          alignItems="center"
          className={headersStyles.standardHeader}
        >
          {crewTimesheetData.supervisor ? (
            <Typography
              variant="body2"
              className={classes.link}
              onClick={handleClickEmployeeName(
                crewTimesheetData.supervisor.id as number,
              )}
            >
              {crewTimesheetData.supervisor.fullName}
            </Typography>
          ) : (
            <Typography variant="body2" className={classes.title}>
              {DEFAULT_EMPTY}
            </Typography>
          )}
        </Box>
        <Box
          display="flex"
          alignItems="center"
          className={headersStyles.standardHeader}
        >
          <Typography variant="body2" className={classes.title}>
            {crewTimesheetData.region}
          </Typography>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          className={headersStyles.standardHeader}
        >
          <Typography variant="body2" className={classes.title}>
            {crewTimesheetData.status}
          </Typography>
        </Box>
        <Box className={headersStyles.standardHeader}>
          <Box
            display="flex"
            alignItems="center"
            className={classes.viewMore}
            onClick={handleClickCrewDetails}
          >
            <Box marginRight="8px">
              <Typography variant="body1" className={classes.crewDetails}>
                Crew Details
              </Typography>
            </Box>
            <ArrowForwardIos className={classes.chebronIcon} />
          </Box>
        </Box>
      </Box>
    </Card>
  );
}
