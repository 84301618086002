import { Avatar, Box, IconButton, Theme, Typography } from "@material-ui/core";
import { DriveEta } from "@material-ui/icons";
import { createStyles, makeStyles } from "@material-ui/styles";
import { EmployeeModel } from "@dwo/shared/dist/models/employeeModel";
import React, { Fragment } from "react";

interface InfoItemProps {
  employee: EmployeeModel;
  foremanId?: number;
  isCurrentUser?: boolean;
  isDriver?: boolean;
  disabled?: boolean;
  onClickCarButton?: (foremanId: number) => void;
}

interface StyleProps {
  isCurrentUser: boolean;
  disabled: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    carButton: {
      padding: "8px",
    },
    container: {
      border: ({ isCurrentUser, disabled }: StyleProps) =>
        `1px solid ${
          isCurrentUser
            ? !disabled
              ? theme.palette.primary.light
              : theme.palette.grey["500"]
            : theme.palette.grey["400"]
        }`,
      minHeight: "64px",
      minWidth: "174px",
      padding: "8px",
    },
    name: {
      color: ({ disabled }: StyleProps) => {
        if (!disabled) {
          return theme.palette.primary.dark;
        }
        if (disabled) {
          return theme.palette.grey["600"];
        }
      },
      fontWeight: ({ disabled }: StyleProps) => (disabled ? 400 : "bold"),
    },
    speciality: {
      color: theme.palette.grey["400"],
    },
    iconBackground: {
      backgroundColor: "#1654B8",
    },
    carIcon: {
      fontSize: "1.6rem",
    },
  }),
);

export function InfoItem({
  employee,
  foremanId,
  isCurrentUser = false,
  isDriver = false,
  disabled = false,
  onClickCarButton,
}: InfoItemProps) {
  const isWorker = employee.role === "worker";
  const isDisabled = !employee.isActive || disabled;
  const classes = useStyles({
    isCurrentUser,
    disabled: isDisabled,
  });
  const srcImage =
    employee.employeePictures && employee.employeePictures[0]
      ? employee.employeePictures[0].picture.url
      : "";
  const employeeRole =
    employee.role.charAt(0).toUpperCase() + employee.role.slice(1);

  const handleClickCarButton = (id?: number) => () => {
    if (id && onClickCarButton) {
      onClickCarButton(id);
    }
  };

  return (
    <Fragment>
      <Box
        display="flex"
        alignItems="center"
        className={classes.container}
        width="max-content"
      >
        <Avatar
          style={{ height: "40px", width: "40px", marginRight: "8px" }}
          src={employee.pictureUrl || srcImage}
        />
        {isWorker ? (
          <Box>
            <Typography variant="body2" className={classes.name}>
              {`${employee.firstName} ${employee.lastName}`}
            </Typography>
            <Typography variant="caption" className={classes.speciality}>
              {employeeRole}
            </Typography>
          </Box>
        ) : (
          <Box>
            <Typography variant="body2" className={classes.name}>
              {`${employee.firstName} ${employee.lastName}`}
            </Typography>
          </Box>
        )}
        {isDriver && (
          <Box display="flex" position="relative" bottom="-7px">
            <Box
              className={classes.iconBackground}
              display="flex"
              height="32px"
              width="32px"
              marginLeft="8px"
              alignItems="center"
              justifyContent="center"
              borderRadius="100px"
            >
              <IconButton
                aria-label="See Vehicle Details"
                className={classes.carButton}
                onClick={handleClickCarButton(foremanId)}
              >
                <DriveEta className={classes.carIcon} htmlColor="white" />
              </IconButton>
            </Box>
          </Box>
        )}
      </Box>
    </Fragment>
  );
}
