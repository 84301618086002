import {
  Box,
  createStyles,
  Grid,
  IconButton,
  makeStyles,
  Paper,
  Theme,
  Typography,
} from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import { AvatarWithPreviews } from "components/userManagement/UserDetails/AvatarWithPreviews";
import { Layout } from "components/layout/Layout";
import { LoadingSpinner } from "components/LoadingSpinner";
import { SwitchCustom } from "components/SwitchCustom";
import { UploadButton } from "components/UploadButton";
import { UserDetailsTabs } from "components/userManagement/UserDetails/UserDetailsTabs";
import {
  getAllEmployeePictures,
  getEmployeeById,
  selectEmployee,
  selectEmployeePictures,
  selectIsActiveEmployee,
  selectLoading,
  setUserActiveStatus,
} from "features/employees/employeesSlice";
import { selectCurrentUser } from "features/logIn/sessionSlice";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { ROOT_USER_MANAGEMENT } from "routes/Roots";
import { getLocationToDisplay } from "utils/employeeDetailsUtils";
import { FileData } from "utils/sharedUtils";

interface StyleProps {
  isActive: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backButton: {
      marginRight: "24px",
      padding: 0,
    },
    backIcon: {
      color: theme.palette.primary.main,
    },
    header: {
      fontSize: "32px",
      color: theme.palette.primary.main,
      fontWeight: 600,
    },
    location: {
      borderRight: ({ isActive }: StyleProps) =>
        `4px solid ${
          isActive ? theme.palette.secondary.main : theme.palette.grey[500]
        }`,
      color: ({ isActive }: StyleProps) =>
        isActive ? theme.palette.primary.main : theme.palette.grey[400],
      lineHeight: "1",
      paddingRight: "8px",
    },
    paper: {
      backgroundColor: ({ isActive }: StyleProps) =>
        isActive ? "white" : theme.palette.grey[100],
      display: "flex",
      margin: "0 24px 24px 24px",
      padding: "24px",
    },
    role: {
      borderRight: ({ isActive }: StyleProps) =>
        `4px solid ${
          isActive ? theme.palette.secondary.main : theme.palette.grey[500]
        }`,
      color: ({ isActive }: StyleProps) =>
        isActive ? theme.palette.primary.main : theme.palette.grey[400],
      lineHeight: "1",
      marginRight: "28px",
      paddingRight: "8px",
    },
    userName: {
      color: ({ isActive }: StyleProps) =>
        isActive ? theme.palette.primary.dark : theme.palette.grey[400],
      fontSize: "2.5rem",
      fontWeight: 300,
    },
    switchLabel: {
      color: theme.palette.primary.dark,
      fontWeight: "bold",
      marginRight: "16px",
    },
  }),
);

const ACCEPT_PICTURE_TYPES = ".jpg, .jpeg, .png";
const MAX_PICTURE_SIZE = 1000000;

export function UserDetails() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const userId = parseInt(id, 10);
  const currentUser = useSelector(selectEmployee);
  const loggedInUser = useSelector(selectCurrentUser);
  const isActive = useSelector(selectIsActiveEmployee);
  const employeePictures = useSelector(selectEmployeePictures);
  const isLoadingDetails = useSelector(selectLoading);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [pictures, setPictures] = useState<FileData[]>([]);
  const [isUploadImagesModalOpen, setIsUploadImagesModalOpen] = useState(false);

  const classes = useStyles({ isActive });
  const uploadedPictures = employeePictures && employeePictures;
  const userTabs =
    currentUser?.role === "admin" || currentUser?.role === "subadmin" ? 1 : 3;
  const location = getLocationToDisplay(currentUser?.regions);
  const role = currentUser?.role
    ? `${currentUser.role.charAt(0).toUpperCase()}${currentUser.role.slice(1)}`
    : "Role";
  const userName = currentUser?.role
    ? `${currentUser?.firstName} ${currentUser?.lastName}`
    : "User";

  useEffect(() => {
    if (isLoadingDetails) {
      setIsLoading(true);
      return;
    }
    setIsLoading(false);
  }, [isLoadingDetails]);

  useEffect(() => {
    dispatch(getEmployeeById(userId, { include: ["regions", "user"] }));
    dispatch(
      getAllEmployeePictures({
        include: ["Picture"],
        where: { employeeId: userId },
      }),
    );
  }, [dispatch, userId]);

  useEffect(() => {
    if (!isUploadImagesModalOpen) {
      setPictures([]);
    }
  }, [isUploadImagesModalOpen]);

  const handleChangeDeactivate = (value: boolean) => {
    const userId: number | string | undefined = currentUser?.id;
    if (userId) {
      dispatch(setUserActiveStatus(userId, !value));
    }
  };

  const handleToggleUploadImagesModal = () =>
    setIsUploadImagesModalOpen(!isUploadImagesModalOpen);

  const handleUploadPictures = (newPicture: FileData) => {
    if (!newPicture.hasError) {
      setPictures((prevPictures) => prevPictures.concat(newPicture));
      handleToggleUploadImagesModal();
    }
  };

  const handleClickBack = () => {
    history.push(ROOT_USER_MANAGEMENT);
  };

  return (
    <Layout>
      <Grid container>
        <Grid item md={4} xs={12}>
          <Box alignItems="center" display="flex" margin="32px 24px 24px 24px">
            <IconButton
              aria-label="Go Back"
              className={classes.backButton}
              onClick={handleClickBack}
            >
              <ArrowBack className={classes.backIcon} fontSize="large" />
            </IconButton>
            <Typography className={classes.header} component="h1">
              User Details
            </Typography>
          </Box>
        </Grid>
      </Grid>

      <Paper className={classes.paper}>
        {isLoading && (
          <Grid item sm={12}>
            <LoadingSpinner
              BoxProps={{ padding: 10 }}
              CircularProgressProps={{ size: "40px" }}
            />
          </Grid>
        )}
        {!isLoading && (
          <Grid container spacing={2}>
            <Grid item lg={2} md={3} sm={4} xs={12}>
              <AvatarWithPreviews
                isUploadImagesModalOpen={isUploadImagesModalOpen}
                uploadedPictures={uploadedPictures}
                selectedPictures={pictures}
                onToggleCarouselModal={handleToggleUploadImagesModal}
              />
              {loggedInUser?.employee.role !== "supervisor" && (
                <Box maxWidth="150px">
                  <UploadButton
                    accept={ACCEPT_PICTURE_TYPES}
                    id="userDetails-uploadPictures-button"
                    isDisabled={!isActive}
                    maxSize={MAX_PICTURE_SIZE}
                    multiple
                    name="userDetails-uploadPictures-hiddenField"
                    selectedFiles={pictures}
                    text="Upload Image"
                    onUploadFiles={handleUploadPictures}
                  />
                </Box>
              )}
            </Grid>

            <Grid item lg={10} md={9} sm={8} xs={12}>
              <Box width="100%">
                <Typography className={classes.userName} variant="h1">
                  {userName}
                </Typography>
              </Box>

              <Box display="flex" paddingTop="8px" width="100%">
                <Typography className={classes.role} variant="h6">
                  {role}
                </Typography>

                <Typography className={classes.location} variant="h6">
                  {location}
                </Typography>
              </Box>
              {currentUser && (
                <Box display="flex" marginTop="16px">
                  <Typography className={classes.switchLabel}>
                    Deactivate:
                  </Typography>
                  <SwitchCustom
                    onText="ON"
                    offText="OFF"
                    value={!currentUser.isActive}
                    onChange={handleChangeDeactivate}
                    disabled={loggedInUser?.employee.role === "supervisor"}
                  />
                </Box>
              )}
            </Grid>
          </Grid>
        )}
      </Paper>
      <UserDetailsTabs numOfTabs={userTabs} />
    </Layout>
  );
}
