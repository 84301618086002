import { Box, Button, Card, makeStyles } from "@material-ui/core";
import { DateRange, DateRangePicker } from "materialui-daterange-picker";
import React, { useEffect } from "react";
import { slashFormatDate } from "utils/dateUtils";

interface RangeDatePickerProps {
  clearWithTodaysDate?: boolean;
  hideShortcuts?: boolean;
  initDateRange?: DateRange;
  maxDate?: Date;
  minDate?: Date;
  upAndRightLocation?: boolean;
  downAndLeftLocation?: boolean;
  onClickApply: (dateRange: DateRange) => void;
}

interface StyleProps {
  hideShortcuts?: boolean;
  upAndRightLocation?: boolean;
  downAndLeftLocation?: boolean;
}

const useStyles = makeStyles(() => ({
  root: {
    "& > .MuiPaper-elevation5": {
      boxShadow: "none",
    },
    "& .MuiList-root": {
      display: ({ hideShortcuts }: StyleProps) => (hideShortcuts ? "none" : ""),
    },
  },
  dateButton: {
    width: "100%",
  },
  clearButton: { color: "#002868" },
  calendarContainer: {
    right: ({ upAndRightLocation }: StyleProps) =>
      upAndRightLocation ? "unset" : 0,
    left: ({ downAndLeftLocation }: StyleProps) =>
      downAndLeftLocation ? 0 : "unset",
    bottom: ({ upAndRightLocation }: StyleProps) =>
      upAndRightLocation ? 50 : "unset",
    zIndex: 5,
  },
}));

export function RangeDatePicker({
  clearWithTodaysDate,
  hideShortcuts,
  initDateRange,
  maxDate,
  minDate,
  upAndRightLocation,
  downAndLeftLocation,
  onClickApply,
}: RangeDatePickerProps) {
  const classes = useStyles({
    hideShortcuts,
    upAndRightLocation,
    downAndLeftLocation,
  });
  const initStartDate = initDateRange && initDateRange.startDate;
  const initEndDate = initDateRange && initDateRange.endDate;
  const initButtonText = `${
    initStartDate ? slashFormatDate(initStartDate) : "Select date"
  } - ${initEndDate ? slashFormatDate(initEndDate) : "Range"}`;

  const [buttonText, setButtonText] = React.useState(initButtonText);
  const [isOpen, setIsOpen] = React.useState(false);
  const [dateRange, setDateRange] = React.useState<DateRange>({});

  useEffect(() => {
    if (!dateRange.startDate || !dateRange.endDate) {
      setButtonText(initButtonText);
    }
  }, [dateRange, initButtonText]);

  const handleToggleOpen = () => setIsOpen(!isOpen);

  const handleChangeRange = (range: DateRange) => setDateRange(range);

  const handleClickApply = () => {
    const start = dateRange.startDate || new Date();
    const end = dateRange.endDate || start;

    setButtonText(`${slashFormatDate(start)} -  ${slashFormatDate(end)}`);
    handleToggleOpen();
    onClickApply({ startDate: start, endDate: end });
  };

  const handleClickClear = () => {
    const start = new Date();
    const end = new Date();

    start.setHours(0, 0, 0, 0);
    end.setHours(23, 59, 59, 0);

    const selectedRange = clearWithTodaysDate
      ? { startDate: start, endDate: end }
      : {};
    const buttonText = clearWithTodaysDate
      ? `${slashFormatDate(start)} -  ${slashFormatDate(end)}`
      : initButtonText;

    setDateRange(selectedRange);
    setButtonText(buttonText);
    handleToggleOpen();
    onClickApply(selectedRange);
  };

  return (
    <Box position="relative">
      <Button
        variant="outlined"
        className={classes.dateButton}
        onClick={handleToggleOpen}
      >
        {buttonText}
      </Button>

      {isOpen && (
        <Box
          position="absolute"
          width="max-content"
          className={classes.calendarContainer}
        >
          <Card>
            <DateRangePicker
              maxDate={maxDate}
              initialDateRange={initDateRange}
              minDate={minDate}
              open={true}
              toggle={handleToggleOpen}
              onChange={handleChangeRange}
              wrapperClassName={classes.root}
            />
            <Box padding="16px" display="flex" justifyContent="flex-end">
              <Box marginRight="24px">
                <Button
                  className={classes.clearButton}
                  variant="text"
                  onClick={handleClickClear}
                >
                  Clear
                </Button>
              </Box>
              <Box marginRight="24px">
                <Button variant="outlined" onClick={handleToggleOpen}>
                  Cancel
                </Button>
              </Box>
              <Button variant="contained" onClick={handleClickApply}>
                Apply
              </Button>
            </Box>
          </Card>
        </Box>
      )}
    </Box>
  );
}
