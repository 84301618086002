import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@material-ui/core";
import { cloneDeep, noop } from "lodash/fp";
import { GlobalModal } from "components/globalModal/GlobalModal";
import { ModalTabs } from "components/globalModal/ModalTabs";
import { ModalTabsContent } from "components/globalModal/ModalTabsContent";
import { SearchByNameOrId } from "components/userManagement/SearchByNameOrId";
import { TableCustom } from "components/table/TableCustom";
import { EmployeeCrewModel } from "@dwo/shared/dist/models/employeeCrewModel";
import { EmployeeModel } from "@dwo/shared/dist/models/employeeModel";
import { JobModel } from "@dwo/shared/dist/models/jobModel";
import { ServiceOptions } from "@dwo/shared/dist/services/baseService";
import {
  addDefaultCrewOnForemanSave,
  getDefaultCrewWorkersByForemanId,
  selectCrews,
  updateCrew,
} from "features/crew/crewSlice";
import {
  createEmployeeCrew,
  updateEmployeeCrew,
} from "features/employee_crew/employeeCrewSlice";
import {
  getAllEmployeesWithImage,
  selectEmployees,
  selectLoading as selectLoadingEmployees,
} from "features/employees/employeesSlice";
import { selectCurrentJob, updateJob } from "features/jobs/jobsSlice";
import { prompt, selectResponse } from "features/prompt/promptSlice";
import { selectLoading as selectLoadingRegions } from "features/region/regionSlice";
import { DEFAULT_QUERY_LIMIT } from "utils/sharedUtils";
import {
  assignForemanModalColumns,
  deleteForemanModalColumns,
  getAssignForemanModalRows,
  getDeleteForemanModalRows,
  initialJobDetailsQuery,
} from "utils/jobDetailsUtils";
import { JobStatusValues } from "utils/jobUtils";

interface AssignForemanModalProps {
  crewId: number;
  currentEmployeeCrews: EmployeeCrewModel[];
  currentForeman: EmployeeCrewModel[];
  employeeSelectedId: string;
  isOpen: boolean;
  position: number;
  jobId: number;
  getAllCrewsQuery: () => ServiceOptions;
  onToggle: VoidFunction;
  onClickCheckbox: (idRow: string, isChecked: boolean) => void;
}

export function AssignForemanModal({
  crewId,
  currentEmployeeCrews,
  currentForeman,
  employeeSelectedId,
  isOpen,
  position,
  jobId,
  getAllCrewsQuery,
  onToggle,
  onClickCheckbox,
}: AssignForemanModalProps) {
  const dispatch = useDispatch();
  const employees = useSelector(selectEmployees);
  const isLoadingEmployees = useSelector(selectLoadingEmployees);
  const isLoadingRegions = useSelector(selectLoadingRegions);
  const crews = useSelector(selectCrews);
  const promptResponse = useSelector(selectResponse);
  const currentJob = useSelector(selectCurrentJob);
  const crew = crews && crews.find((crew) => crew.id === crewId);
  const [searchedName, setSearchedName] = useState("");
  const [searchedEmployeeId, setSearchedEmployeeId] = useState("");
  const [activeTab, setActiveTab] = useState(0);
  const [foremanToDelete, setForemanToDelete] = useState<
    EmployeeCrewModel | undefined
  >();
  const [confirmDeleteForeman, setConfirmDeleteForeman] = useState(false);
  const isLoading = isLoadingEmployees || isLoadingRegions;

  useEffect(() => {
    if (isOpen) {
      dispatch(
        getAllEmployeesWithImage({
          where: {
            role: { $eq: "foreman" },
            regionId: currentJob?.regionId,
          },
          exclude: { jobId },
          limit: DEFAULT_QUERY_LIMIT,
        }),
      );
    }
  }, [dispatch, isOpen, currentJob, jobId]);

  useEffect(() => {
    if (promptResponse && confirmDeleteForeman && foremanToDelete) {
      const { id, ...updatedEmployeeCrew } = foremanToDelete;
      dispatch(
        updateEmployeeCrew(
          id as number,
          updatedEmployeeCrew,
          true,
          getAllCrewsQuery(),
        ),
      );

      if (position === 1 && crews.length === 1) {
        const updatedJob = cloneDeep(currentJob as JobModel);
        updatedJob.status = JobStatusValues.UNASSIGNED;
        dispatch(updateJob(updatedJob, initialJobDetailsQuery));
      }

      setConfirmDeleteForeman(false);
      setForemanToDelete(undefined);
    }
  }, [
    promptResponse,
    foremanToDelete,
    confirmDeleteForeman,
    getAllCrewsQuery,
    dispatch,
    currentJob,
    position,
    crews.length,
    crew,
  ]);

  const handleChangeSearch = useCallback(
    (employeeId: string, employeeName: string) => {
      setSearchedName(employeeName);
      setSearchedEmployeeId(employeeId);
    },
    [],
  );

  const filterEmployeesBySearchResult = (data: EmployeeModel[]) => {
    if (searchedEmployeeId && searchedName) {
      return data.filter(
        ({ employeeId, firstName }) =>
          employeeId === searchedEmployeeId && firstName === searchedName,
      );
    }
    return data;
  };

  const availableForemen = employees.filter((employee) => {
    if (currentForeman[0] && currentForeman[0].employee) {
      return employee.id !== currentForeman[0].employee?.id;
    }
    return employee;
  });
  const assignedForeman = () =>
    currentForeman.map((cf) => cf.employee as EmployeeModel);

  const handleClickSaveForeman = () => {
    const currentEmployeeCrew = currentEmployeeCrews.find(
      ({ isCurrent, isForeman }) => isCurrent && isForeman,
    );
    const selectedForeman = employees.find(
      ({ id }) => id === Number(employeeSelectedId),
    );
    const matchingEmployeeCrew = currentEmployeeCrews.find(
      (employeeCrew) =>
        employeeCrew.employeeId === Number(employeeSelectedId) &&
        employeeCrew.crewId ===
          (currentEmployeeCrew && currentEmployeeCrew.crewId),
    );

    if (currentEmployeeCrew) {
      const updatedEmployeeCrew = {
        ...currentEmployeeCrew,
        isCurrent: false,
        assignedAt: new Date(),
        updatedAt: new Date(),
      };

      updatedEmployeeCrew.employee = undefined;

      dispatch(
        updateEmployeeCrew(
          currentEmployeeCrew.id as number,
          updatedEmployeeCrew,
        ),
      );
    }

    if (matchingEmployeeCrew && crew) {
      const updatedEmployeeCrew = {
        ...matchingEmployeeCrew,
        crewId: crew.id as number,
        isCurrent: true,
        assignedAt: new Date(),
        updatedAt: new Date(),
      };

      updatedEmployeeCrew.employee = undefined;

      dispatch(
        updateEmployeeCrew(
          matchingEmployeeCrew.id as number,
          updatedEmployeeCrew,
        ),
      );

      const crewId = typeof crew.id === "string" ? parseInt(crew.id) : crew.id;
      const foremanId =
        typeof updatedEmployeeCrew.employeeId === "string"
          ? parseInt(updatedEmployeeCrew.employeeId, 10)
          : updatedEmployeeCrew.employeeId;

      const updatedCrew = {
        ...crew,
        id: crewId,
        foremanId: foremanId,
        updatedAt: new Date(),
      };

      dispatch(
        updateCrew(
          updatedEmployeeCrew.crewId,
          updatedCrew,
          true,
          getAllCrewsQuery(),
        ),
      );
    }

    if (!matchingEmployeeCrew && selectedForeman && crew) {
      const newEmployeeCrew = {
        assignedAt: new Date(),
        isCurrent: true,
        isDriver: false,
        employeeId: selectedForeman.id as number,
        crewId: crew.id as number,
        isForeman: true,
        isSupervisor: false,
      };

      dispatch(createEmployeeCrew(newEmployeeCrew));

      const updatedCrew = {
        ...crew,
        foremanId: selectedForeman.id as number,
        updatedAt: new Date(),
      };

      dispatch(
        updateCrew(
          newEmployeeCrew.crewId as number,
          updatedCrew,
          true,
          getAllCrewsQuery(),
        ),
      );

      if (position === 1) {
        const updatedJob = cloneDeep(currentJob as JobModel);
        updatedJob.status = JobStatusValues.ASSIGNED;
        dispatch(updateJob(updatedJob, initialJobDetailsQuery));
      }
    }

    onToggle();
    getDefaultCrewWorkersByForemanId(selectedForeman?.id as number).then(
      (defaultCrew) =>
        defaultCrew &&
        defaultCrew.workers &&
        dispatch(
          addDefaultCrewOnForemanSave(
            crewId,
            currentEmployeeCrews.filter(
              ({ isForeman, isSupervisor }) => !isForeman && !isSupervisor,
            ),
            defaultCrew.workers,
            getAllCrewsQuery(),
            defaultCrew.supervisor,
          ),
        ),
    );
  };

  const handleChangeTabs = (
    event: React.ChangeEvent<unknown>,
    newValue: number,
  ) => setActiveTab(newValue);

  const handleClickDelete = (id: string) => {
    if (currentEmployeeCrews.length > 0) {
      const crewToDelete = currentEmployeeCrews.find(
        (employeeCrew) =>
          employeeCrew.employeeId === Number(id) && employeeCrew.isCurrent,
      );

      if (crewToDelete && crewToDelete.employee) {
        const updatedCrewMember = {
          id: crewToDelete.id as number,
          isCurrent: false,
          assignedAt: crewToDelete.assignedAt,
          isDriver: crewToDelete.isDriver,
          employeeId: crewToDelete.employeeId,
          crewId: crewToDelete.crewId,
          isForeman: crewToDelete.isForeman,
          isSupervisor: crewToDelete.isSupervisor,
        };

        setForemanToDelete(updatedCrewMember);
        dispatch(
          prompt({
            title: "Remove Job Assignment",
            message: `Are you sure you want to remove ${crewToDelete.employee.firstName} ${crewToDelete.employee.lastName} from this job?`,
          }),
        );
        setConfirmDeleteForeman(true);
      }
    }
  };

  return (
    <GlobalModal
      showActionButtons={activeTab === 1}
      open={isOpen}
      Filters={
        activeTab === 1 && (
          <Box width="100%" padding="24px">
            <SearchByNameOrId
              filteredEmployees={availableForemen}
              onChange={handleChangeSearch}
            />
          </Box>
        )
      }
      Tabs={
        <ModalTabs
          activeTab={activeTab}
          firstLabel={`Assigned (${assignedForeman().length})`}
          secondLabel={`Available (${availableForemen.length})`}
          onChange={handleChangeTabs}
        />
      }
      title="Foreman"
      isContinueDisabled={employeeSelectedId.length === 0}
      onClickContinue={handleClickSaveForeman}
      onClickClose={onToggle}
    >
      <ModalTabsContent
        activeTab={activeTab}
        FirstTabContent={
          <TableCustom
            columns={deleteForemanModalColumns}
            isLoading={isLoading}
            noRowsMessage="There are no available employees to delete."
            rows={getDeleteForemanModalRows(
              assignedForeman(),
              handleClickDelete,
            )}
            onClickSort={noop}
          />
        }
        SecondTabContent={
          <TableCustom
            columns={assignForemanModalColumns}
            isLoading={isLoading}
            noRowsMessage="There are no available employees to assign."
            rows={getAssignForemanModalRows(
              filterEmployeesBySearchResult(availableForemen),
              employeeSelectedId,
              onClickCheckbox,
            )}
            onClickSort={noop}
          />
        }
      />
    </GlobalModal>
  );
}
