import {
  Box,
  createStyles,
  Divider,
  InputAdornment,
  makeStyles,
  TextField,
  Theme,
  Typography,
} from "@material-ui/core";
import { useFormik } from "formik";
import { noop } from "lodash/fp";
import React, { useEffect, useRef } from "react";
import * as Yup from "yup";
import { DatePicker } from "components/DatePicker";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { InvoiceValues } from "utils/invoicesUtils";

interface InvoiceFormProps {
  initialValue: InvoiceValues;
  handleFormikValues: (value: InvoiceValues) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    helpText: {
      color: theme.palette.grey["400"],
      width: "70%",
      marginTop: "10px",
      marginBottom: "7px",
    },
    inputField: {
      "& label + .MuiInput-formControl": {
        marginTop: 0,
        height: "100%",
      },
    },
    textFieldRoot: {
      height: "auto",
      "&> .MuiInputLabel-root": {
        color: theme.palette.primary.main,
        fontSize: "0.75rem",
        fontWeight: "bold",
      },
      "& .MuiInputAdornment-positionStart": {
        margin: "-2px -4px 0 8px",
      },
    },
    multilineTextFieldRoot: {
      height: "100%",
      "&> .MuiInputLabel-root": {
        color: theme.palette.primary.main,
        fontSize: "0.75rem",
        fontWeight: "bold",
      },
    },
    divider: {
      marginTop: "16px",
    },
    errorMessage: {
      color: theme.palette.error.main,
      paddingTop: "4px",
    },
  }),
);

export function InvoiceForm({
  initialValue,
  handleFormikValues,
}: InvoiceFormProps) {
  const classes = useStyles();
  const isFirstRender = useRef<boolean>(true);
  const formik = useFormik({
    initialValues: {
      ...initialValue.values,
    },
    validationSchema: Yup.object({
      amount: Yup.number()
        .typeError("Amount must be a number")
        .min(0)
        .required("Amount is required"),
      date: Yup.date()
        .typeError("Date must be a valid date")
        .required("Date is required"),
      invoiceNumber: Yup.string().required("Invoice number is required"),
      percentageBilled: Yup.number()
        .typeError("Percentage must be a number")
        .min(0)
        .required("Percentage billed is required"),
    }),
    onSubmit: noop,
  });

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    const hasFormErrors =
      Boolean(formik.errors.date) ||
      Boolean(formik.errors.amount) ||
      Boolean(formik.errors.invoiceNumber) ||
      Boolean(formik.errors.percentageBilled);

    handleFormikValues({
      values: formik.values,
      errors: hasFormErrors,
      id: initialValue.id,
    });
    // eslint-disable-next-line
  }, [formik.values, formik.errors]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Box>
        <Box display="flex" marginBottom="24px">
          <Box paddingRight="12px" width="50%">
            <TextField
              value={formik.values.invoiceNumber}
              classes={{ root: classes.textFieldRoot }}
              fullWidth
              id="invoiceNumber"
              label="Invoice Number"
              placeholder="Invoice 0000"
              InputLabelProps={{
                shrink: true,
              }}
              autoComplete="off"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.invoiceNumber &&
              Boolean(formik.errors.invoiceNumber) && (
                <Typography className={classes.errorMessage} variant="body2">
                  {formik.errors.invoiceNumber}
                </Typography>
              )}
          </Box>
          <Box paddingLeft="12px" width="50%">
            <DatePicker
              autoOk
              disableFuture
              error={formik.touched.date && Boolean(formik.errors.date)}
              errorMessage={(formik.errors.date as string) || ""}
              id="date"
              label="Date"
              placeholder="MM/DD/YYYY"
              InputLabelProps={{
                shrink: true,
              }}
              invalidDateMessage=""
              {...formik.getFieldProps("date")}
              onChange={(datePicked: MaterialUiPickersDate) => {
                if (datePicked) {
                  formik.setFieldValue("date", datePicked);
                }
              }}
            />
          </Box>
        </Box>
        <Box display="flex">
          <Box paddingRight="12px" width="50%">
            <TextField
              value={formik.values.amount}
              classes={{ root: classes.textFieldRoot }}
              fullWidth
              id="amount"
              label="Amount"
              placeholder="00.00"
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
              autoComplete="off"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.amount && Boolean(formik.errors.amount) && (
              <Typography className={classes.errorMessage} variant="body2">
                {formik.errors.amount}
              </Typography>
            )}
          </Box>
          <Box paddingLeft="12px" width="50%">
            <TextField
              value={formik.values.percentageBilled}
              classes={{ root: classes.textFieldRoot }}
              fullWidth
              id="percentageBilled"
              label="Percentage Billed"
              placeholder="0"
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
              autoComplete="off"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            {formik.touched.percentageBilled &&
              Boolean(formik.errors.percentageBilled) && (
                <Typography className={classes.errorMessage} variant="body2">
                  {formik.errors.percentageBilled}
                </Typography>
              )}
          </Box>
        </Box>
      </Box>
      <Divider className={classes.divider} />
    </form>
  );
}
