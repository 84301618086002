import {
  Box,
  createStyles,
  makeStyles,
  Paper,
  Theme,
  Typography,
} from "@material-ui/core";
import React from "react";
import { decimalTimeToHoursTime } from "utils/dateUtils";

interface HoursOverviewProps {
  overviewTotalHours: number;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      backgroundColor: "white",
      borderRadius: "0px",
      margin: "0 0 24px 24px",
      padding: "24px",
      position: "relative",
      zIndex: 2,
      [theme.breakpoints.down("xs")]: {
        margin: "0 24px 24px 24px",
      },
    },
    title: {
      color: theme.palette.primary.main,
      marginBottom: "16px",
    },
    hours: {
      color: theme.palette.primary.main,
      fontWeight: 700,
    },
    crewHours: {
      color: theme.palette.grey[600],
      fontWeight: 600,
      marginBottom: "3px",
    },
    blueContainer: {
      backgroundColor: "#2D72E033",
      border: `1px solid ${theme.palette.primary.light}`,
      padding: "16px",
      borderRadius: "2px",
    },
  }),
);

export function HoursOverview({ overviewTotalHours }: HoursOverviewProps) {
  const classes = useStyles();
  return (
    <Paper className={classes.paper}>
      <Typography className={classes.title} variant="h6">
        Hours Overview
      </Typography>
      <Box
        className={classes.blueContainer}
        display="flex"
        width="100%"
        flexDirection="column"
      >
        <Typography className={classes.crewHours} variant="body2">
          Total Crew <br /> Hours
        </Typography>
        <Typography className={classes.hours} component="h1" variant="h4">
          {decimalTimeToHoursTime(overviewTotalHours)}
        </Typography>
      </Box>
    </Paper>
  );
}
