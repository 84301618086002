import React, { FC } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Typography from "@material-ui/core/Typography";
import Accordion from "@material-ui/core/Accordion";
import VersionCard from "components/appChangelog/VersionCard";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    versionCollapsible: {
      with: "100%",
    },
    accordion: {
      borderRadius: "0%",
      boxShadow: "none",
    },
    border: {
      borderTop: "1px solid",
      borderTopColor: theme.palette.grey[300],
    },
    firstBorder: {
      borderTop: "2px solid",
      borderTopColor: theme.palette.grey[600],
    },
    expandIcon: {
      color: "#002868",
    },
    typography: {
      color: "#838385",
    },
    typographyDate: {
      marginLeft: "20px",
    },
  }),
);

const VersionCollapsible: FC<any> = ({ version, index }) => {
  const classes = useStyles();
  return (
    <div>
      <Accordion
        className={`${classes.accordion} ${
          index === 1 ? classes.firstBorder : classes.border
        }`}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon className={classes.expandIcon} />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography component={"span"} className={classes.typography}>
            <span className="typographyVersion">PR - {version.version}</span>
            <span className={classes.typographyDate}>Date: {version.date}</span>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography component={"span"} className={classes.versionCollapsible}>
            <VersionCard version={version} />
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default VersionCollapsible;
