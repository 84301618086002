import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";

export interface ToastState {
  isActive: boolean;
  message: string;
  status?: number;
  title?: string;
}

const toastInitialState: ToastState = {
  message: "",
  isActive: false,
};

export const toastSlice = createSlice({
  name: "toast",
  initialState: toastInitialState,
  reducers: {
    setToast: (state, { payload }: PayloadAction<ToastState>) => {
      const { title, message, isActive, status } = payload;

      state.isActive = isActive;

      if (isActive) {
        state.message = message;

        if (title) {
          state.title = title;
        }
        if (status) {
          state.status = status;
        }
      }
    },
    clearToast: () => toastInitialState,
  },
});

export const { setToast, clearToast } = toastSlice.actions;

export const selectToast = (state: RootState): ToastState => state.toast;

export default toastSlice.reducer;
