import {
  ButtonBase,
  createStyles,
  makeStyles,
  Paper,
  Theme,
  Typography,
} from "@material-ui/core";
import { Box } from "@material-ui/core/";
import { Reorder, ViewModule } from "@material-ui/icons";
import { CheckboxCustom } from "components/CheckboxCustom";
import { selectCurrentJob, setSelectedTab } from "features/dwo/dwoSlice";
import {
  selectIsTimeOffChecked,
  selectIsTimeOffDisabled,
  setIsTimeOffChecked,
} from "features/editWorkShiftTableSlice/workShiftTableValuesSlice";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { slashFormatDate } from "utils/dateUtils";

interface CrewOverviewHeaderProps {
  selectedDate?: Date;
}

interface StyleProps {
  activeTab: number;
  isJobSelected: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      backgroundColor: "white",
      borderRadius: "0px",
      margin: "0 24px",
      padding: ({ isJobSelected }: StyleProps) =>
        isJobSelected ? "21px 24px 0px 24px" : "21px 24px 35px 24px",
      position: "relative",
      zIndex: 2,
    },
    title: {
      color: theme.palette.primary.main,
      marginBottom: "16px",
    },
    dateLabel: {
      color: theme.palette.grey[700],
      fontWeight: 600,
      marginLeft: "15px",
      marginTop: "9px",
    },
    filteredDateLabel: {
      color: theme.palette.grey[700],
      fontWeight: 600,
      marginLeft: "15px",
    },
    label: {
      color: theme.palette.grey[700],
      fontWeight: 600,
    },
    tableButton: {
      flex: 1,
      backgroundColor: ({ activeTab }: StyleProps) =>
        activeTab === 0 ? "#1654B8" : "",
      borderRadius: "2px",
    },
    gridButton: {
      flex: 1,
      backgroundColor: ({ activeTab }: StyleProps) =>
        activeTab === 1 ? "#1654B8" : "",
      borderRadius: "2px",
    },
    buttonsContainer: {
      display: "flex",
      width: "150px",
      height: "40px",
      marginLeft: "20px",
      padding: "4px",
      backgroundColor: "#F0EFEF",
      borderRadius: "2px",
      border: "1px solid #C3C3C7",
      [theme.breakpoints.down("xs")]: {
        marginLeft: "0px",
      },
    },
    reorderColor: {
      color: ({ activeTab }: StyleProps) =>
        activeTab === 0 ? "white" : "#C3C3C7",
    },
    viewModuleColor: {
      color: ({ activeTab }: StyleProps) =>
        activeTab === 1 ? "white" : "#C3C3C7",
    },
    tabsContainer: {
      display: "flex",
      alignItems: "center",
      [theme.breakpoints.down("xs")]: {
        flexDirection: "column",
      },
    },
  }),
);

export function CrewOverviewHeader({ selectedDate }: CrewOverviewHeaderProps) {
  const dispatch = useDispatch();
  const selectedJob = useSelector(selectCurrentJob);
  const isTimeOffChecked = useSelector(selectIsTimeOffChecked);
  const isTimeOffDisabled = useSelector(selectIsTimeOffDisabled);
  const [activeTab, setActiveTab] = useState(0);
  const [isChecked, setIsChecked] = useState(isTimeOffChecked);
  const isJobSelected = Boolean(selectedJob);
  const classes = useStyles({ activeTab, isJobSelected });

  useEffect(() => {
    dispatch(setSelectedTab(activeTab));
  }, [activeTab, dispatch]);

  useEffect(() => {
    dispatch(setIsTimeOffChecked(isChecked));
  }, [isChecked, dispatch]);

  useEffect(() => {
    setIsChecked(isTimeOffChecked);
  }, [isTimeOffChecked]);

  const handleClickCheckbox = (_: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(!isChecked);
  };

  const handleClickTableOption = (_: React.ChangeEvent<unknown>) =>
    setActiveTab(0);

  const handleClickGridOption = (_: React.ChangeEvent<unknown>) =>
    setActiveTab(1);

  return (
    <Paper className={classes.paper}>
      <Box display="flex" width="100%" justifyContent="space-between">
        {selectedJob ? (
          <Box>
            <Box>
              <Typography className={classes.label} variant="body1">
                {selectedJob.address}
              </Typography>
            </Box>
            <Box display="flex" marginTop="11px" alignItems="baseline">
              <Typography className={classes.title} variant="body1">
                Job {selectedJob.jobId} Crew TimeSheet
              </Typography>
              <Typography className={classes.filteredDateLabel} variant="body2">
                {selectedDate ? slashFormatDate(selectedDate) : ""}
              </Typography>
            </Box>
          </Box>
        ) : (
          <Box display="flex">
            <Typography className={classes.title} variant="h6">
              Total Crew Hours
            </Typography>
            <Typography className={classes.dateLabel} variant="body2">
              {selectedDate ? slashFormatDate(selectedDate) : ""}
            </Typography>
          </Box>
        )}
        <Box className={classes.tabsContainer}>
          <Box display="flex" alignItems="center">
            <Box>
              <CheckboxCustom
                disabled={isTimeOffDisabled}
                isChecked={isChecked}
                onClickCheckbox={handleClickCheckbox}
              />
            </Box>
            <Typography className={classes.label} variant="body2">
              Time off
            </Typography>
          </Box>
          <Box className={classes.buttonsContainer}>
            <ButtonBase
              className={classes.tableButton}
              onClick={handleClickTableOption}
            >
              <Reorder className={classes.reorderColor} />
            </ButtonBase>
            <ButtonBase
              className={classes.gridButton}
              onClick={handleClickGridOption}
            >
              <ViewModule className={classes.viewModuleColor} />
            </ButtonBase>
          </Box>
        </Box>
      </Box>
    </Paper>
  );
}
