import { Grid, TextField } from "@material-ui/core";
import { JobModel } from "@dwo/shared/dist/models/jobModel";
import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import { selectIsActiveEmployee } from "features/employees/employeesSlice";

export interface AllocationFieldsProps {
  information: JobModel;
}

export function AllocationFields({ information }: AllocationFieldsProps) {
  const isDisabled = !useSelector(selectIsActiveEmployee);
  return (
    <Fragment>
      {information && (
        <Grid container spacing={3} justify="flex-start">
          <Grid item md={4}>
            <TextField
              value={information.jobId || "N/A"}
              label="Job ID"
              disabled={isDisabled}
            />
          </Grid>
          <Grid item md={4}>
            <TextField
              value={information.address || "N/A"}
              label="Job Address"
              disabled={isDisabled}
            />
          </Grid>
          <Grid item md={4}>
            <TextField
              value={information.category || "N/A"}
              label="Category"
              disabled={isDisabled}
            />
          </Grid>
          <Grid item md={4}>
            <TextField
              value={information.type || "N/A"}
              label="Job type"
              disabled={isDisabled}
            />
          </Grid>
          <Grid item md={4}>
            <TextField
              value={(information.region as string) || "N/A"}
              label="Region"
            />
          </Grid>
        </Grid>
      )}
    </Fragment>
  );
}
