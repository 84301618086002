import {
  Box,
  createStyles,
  Hidden,
  makeStyles,
  Theme,
} from "@material-ui/core";
import { AppBar } from "components/layout/AppBar";
import { SideMenu } from "components/layout/SideMenu";
import { selectIsFilterActive } from "features/supervisors/supervisorsSlice";
import React from "react";
import { useSelector } from "react-redux";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      backgroundColor: theme.palette.background.default,
      [theme.breakpoints.down("sm")]: {
        paddingLeft: "0",
      },
    },
  }),
);

export function Layout({ children }: any) {
  const isFilterActive = useSelector(selectIsFilterActive);
  const classes = useStyles();
  return (
    <Box className={classes.content}>
      <AppBar />
      <Hidden smDown>
        <SideMenu />
      </Hidden>
      <Box
        className={classes.content}
        marginTop={isFilterActive ? "112px" : "64px"}
        minHeight={`calc(100vh - ${isFilterActive ? "112px" : "64px"})`}
        paddingLeft="232px"
        width="100%"
      >
        {children}
      </Box>
    </Box>
  );
}
