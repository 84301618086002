import {
  Box,
  createStyles,
  IconButton,
  makeStyles,
  Theme,
} from "@material-ui/core";
import { DeleteSharp, GetAppRounded, Visibility } from "@material-ui/icons";
import React, { Fragment } from "react";

interface ExpenseActionIconsProps {
  expenseId: string;
  fileId?: number;
  onClickDelete: (expenseId: string) => void;
  onClickDownload: (fileId: number) => void;
  onClickPreview: (fileId: number) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      color: theme.palette.primary.main,
    },
    iconButton: {
      padding: "8px",
    },
  }),
);

export function ExpenseActionIcons({
  expenseId,
  fileId,
  onClickDelete,
  onClickDownload,
  onClickPreview,
}: ExpenseActionIconsProps) {
  const classes = useStyles();

  const handleClickDelete = (id: string) => () => {
    onClickDelete(id);
  };

  const handleClickDownload = (id?: number) => () => {
    if (id) {
      onClickDownload(id);
    }
  };

  const handleClickPreview = (id?: number) => () => {
    if (id) {
      onClickPreview(id);
    }
  };

  return (
    <Box
      alignItems="center"
      display="flex"
      justifyContent="flex-end"
      width="100%"
      marginLeft="-8px"
      maxWidth="120px"
    >
      {fileId != null && (
        <Fragment>
          <IconButton
            aria-label="Preview Expense"
            className={classes.iconButton}
            onClick={handleClickPreview(fileId)}
          >
            <Visibility className={classes.icon} />
          </IconButton>
          <IconButton
            aria-label="Download Expense"
            className={classes.iconButton}
            onClick={handleClickDownload(fileId)}
          >
            <GetAppRounded className={classes.icon} />
          </IconButton>
        </Fragment>
      )}

      <IconButton
        aria-label="Delete Expense"
        className={classes.iconButton}
        onClick={handleClickDelete(expenseId)}
      >
        <DeleteSharp className={classes.icon} />
      </IconButton>
    </Box>
  );
}
