import { WorkShiftType } from "@dwo/shared/dist/models/workShiftModel";
import {
  Box,
  createStyles,
  FormControl,
  makeStyles,
  MenuItem,
  Select,
  Theme,
  Typography,
  useTheme,
} from "@material-ui/core";
import { Error, ExpandMore } from "@material-ui/icons";
import { TooltipCustom } from "components/TooltipCustom";
import { useFormik } from "formik";
import { noop } from "lodash/fp";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import {
  setValues,
  removeValueByIdAndType,
  selectValues,
} from "features/employeeTimesheet/employeeTimesheetSlice";

interface TableSelectProps {
  id: string;
  idRow: number;
  type: string;
  isEditModeActive: boolean;
}

interface StyleProps {
  backgroundColor?: string;
  borderRadius?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dropdown: {
      backgroundColor: ({ backgroundColor }: StyleProps) =>
        backgroundColor || theme.palette.grey[300],
      borderRadius: ({ borderRadius }: StyleProps) => borderRadius || "2px",
      height: "40px",
      width: "105px",
      padding: 0,
      "& .MuiSelect-select:focus": {
        backgroundColor: theme.palette.grey[300],
      },
      "& .MuiSelect-icon": {
        right: 0,
        top: "calc(50% - 16px)",
      },
      "& .MuiSvgIcon-root": {
        color: theme.palette.primary.dark,
        fontSize: "2rem",
      },
      "& .MuiFilledInput-input": {
        padding: "10px 4px",
      },
    },
    dropdownMenu: {
      backgroundColor: theme.palette.grey["200"],
      borderRadius: "2px",
      boxShadow: "1px 1px 8px #0000001A",
      color: theme.palette.grey["500"],
      display: "flex",
      flexDirection: "column",
      marginTop: "8px",
    },
    errorMessage: {
      color: theme.palette.error.main,
      paddingTop: "4px",
    },
    root: {
      color: theme.palette.grey[600],
    },
    errorIcon: {
      position: "absolute",
      right: "-28px",
      bottom: "6px",
    },
  }),
);

export function TableSelect({
  id,
  idRow,
  type,
  isEditModeActive,
}: TableSelectProps) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const values = useSelector(selectValues);
  const [typeValue, setTypeValue] = useState("");
  const [typeValueError, setTypeValueError] = useState<boolean | undefined>(
    undefined,
  );
  const [isRowActive, setIsRowActive] = useState(false);
  const classes = useStyles({
    backgroundColor: theme.palette.background.default,
    borderRadius: "0px",
  });
  const formik = useFormik({
    initialValues: {
      type,
    },
    validationSchema: Yup.object({
      type: Yup.string()
        .required("Type is required")
        .test("empty", "Select a valid type", (type) => type !== "-"),
    }),
    initialErrors: {
      type,
    },
    onSubmit: noop,
  });

  useEffect(() => {
    if (typeValue === "-" && isRowActive) {
      setTypeValueError(true);
    } else {
      setTypeValueError(false);
    }
  }, [typeValue, isRowActive]);

  useEffect(() => {
    setIsRowActive(
      Boolean(values.find((value) => value.idRow === Number(idRow))),
    );
  }, [values, idRow]);

  useEffect(() => {
    if (formik.isValid || isRowActive) {
      dispatch(
        setValues({
          id,
          idRow: Number(idRow),
          inputValue: typeValue,
          inputError: typeValueError,
        }),
      );
    }
  }, [
    dispatch,
    typeValue,
    typeValueError,
    id,
    idRow,
    formik.isValid,
    isRowActive,
  ]);

  useEffect(() => {
    if (
      typeValueError ||
      (formik.touched.type && Boolean(formik.errors.type))
    ) {
      dispatch(removeValueByIdAndType({ id: Number(idRow), type: id }));
    }
  }, [
    dispatch,
    idRow,
    id,
    formik.touched.type,
    formik.errors.type,
    typeValueError,
  ]);

  useEffect(() => {
    if (!isEditModeActive) {
      setTypeValue("");
      setTypeValueError(undefined);
    }
  }, [isEditModeActive]);

  useEffect(() => {
    setTypeValue(formik.values.type);
  }, [formik.values.type]);

  return (
    <Box flex={1} maxWidth="85px">
      {typeValueError && (
        <Box position="relative" top="40px" right="-18px">
          <TooltipCustom
            title={
              <Typography className={classes.errorMessage} variant="subtitle2">
                Select a valid type
              </Typography>
            }
            placement="right"
          >
            <Error className={classes.errorIcon} color="error" />
          </TooltipCustom>
        </Box>
      )}
      {isRowActive && Object.is(typeValue, WorkShiftType.NO_SHOW) && (
        <Box position="relative" top="40px" right="-18px">
          <TooltipCustom
            title={
              <Typography variant="subtitle2">
                "No Show" hours are not added to the total hours
              </Typography>
            }
            placement="right"
          >
            <Error className={classes.errorIcon} color="primary" />
          </TooltipCustom>
        </Box>
      )}
      <FormControl fullWidth required variant="filled">
        <Select
          id="type"
          classes={{ root: classes.root }}
          className={classes.dropdown}
          IconComponent={ExpandMore}
          labelId="type"
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            getContentAnchorEl: undefined,
            PopoverClasses: {
              paper: classes.dropdownMenu,
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          }}
          {...formik.getFieldProps("type")}
        >
          <MenuItem value="-">- - -</MenuItem>
          <MenuItem value={WorkShiftType.NORMAL}>Current</MenuItem>
          <MenuItem value={WorkShiftType.RAINDAY}>Rain Day</MenuItem>
          <MenuItem value={WorkShiftType.PTO}>2.0x C.O</MenuItem>
          <MenuItem value={WorkShiftType.HOLIDAY}>Holiday</MenuItem>
          <MenuItem value={WorkShiftType.STANDBY}>Standby</MenuItem>
          <MenuItem value={WorkShiftType.TRAINING}>Training</MenuItem>
          <MenuItem value={WorkShiftType.CALLOUT}>1.5x C.O</MenuItem>
          <MenuItem value={WorkShiftType.NO_SHOW}>No Show</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
}
