import { Warning, CheckCircle, Error, Info } from "@material-ui/icons";
import { ROOT_JOB_MANAGEMENT, ROOT_TIMESHEETS_MANAGEMENT } from "routes/Roots";
import React from "react";

interface Option {
  color: string;
  title: string;
  message: (param: string) => string | undefined;
  icon: JSX.Element;
  redirect: (param: number) => string;
}
interface Options {
  [key: string]: Option;
}

export const getTotalPages = (currentCount: number, currentLimit: number) => {
  const pages = Math.ceil(currentCount / currentLimit);

  if (pages >= 1) {
    return pages;
  }

  return 1;
};

export const typeOptions = [
  { label: "All", value: "" },
  { label: "Finished job", value: "jobFinished" },
  { label: "Picture mismatch", value: "pictureMismatch" },
  { label: "Clockout", value: "clockout" },
  { label: "Overcost", value: "overcost" },
  { label: "Location mismatch", value: "locationMismatch" },
];

const handlePictureMismatch = (data: string) => {
  try {
    const dataObj = JSON.parse(data);
    const name = dataObj.name;
    const jobId = dataObj.jobId;
    return `${name} has a picture mismatch in the job "#${jobId}"`;
  } catch (err) {
    console.error(`Could not handle picture mismatch message ${err}`);
  }
};

const handleJobFinished = (data: string) => {
  try {
    const dataObj = JSON.parse(data);
    const jobId = dataObj.jobId;
    return `The job "#${jobId}" finished successfully`;
  } catch (err) {
    console.error(`Could not handle job finished message ${err}`);
  }
};

const handleClockout = (data: string) => {
  try {
    const dataObj = JSON.parse(data);
    const foremanName = dataObj.foremanName;
    const jobId = dataObj.jobId;
    return `The foreman ${foremanName} forgot to stop the clock for the job "#${jobId}"`;
  } catch (err) {
    console.error(`Could not handle clockout message ${err}`);
  }
};

const handleOvercost = (data: string) => {
  try {
    const dataObj = JSON.parse(data);
    const jobId = dataObj.jobId;
    return `The job "#${jobId}" has exceeded the estimated cost`;
  } catch (err) {
    console.error(`Could not handle overcost message ${err}`);
  }
};

const handleLocationMismatch = (data: string) => {
  try {
    const dataObj = JSON.parse(data);
    const jobId = dataObj.jobId;
    return `Job location is unknown or not matching Job Address or Yard for job "#${jobId}"`;
  } catch (err) {
    console.error(`Could not handle location mismatch message ${err}`);
  }
};

export const alertOptions: Options = {
  jobFinished: {
    color: "#A2E2AE",
    title: "Finish job",
    message: handleJobFinished,
    icon: <CheckCircle style={{ color: "#16B735" }} />,
    redirect: (id: number) => `${ROOT_JOB_MANAGEMENT}/${id}`,
  },
  pictureMismatch: {
    color: "#ABC7F3",
    title: "Picture mismatch",
    message: handlePictureMismatch,
    icon: <Info style={{ color: "#002868" }} />,
    redirect: (dwoId: number) => `${ROOT_TIMESHEETS_MANAGEMENT}/${dwoId}`,
  },
  clockout: {
    color: "#EE9DA3",
    title: "Clockout missing",
    message: handleClockout,
    icon: <Error style={{ color: "#D50918" }} />,
    redirect: (id: number) => `${ROOT_JOB_MANAGEMENT}/${id}`,
  },
  overcost: {
    color: "#FBD286",
    title: "Overcost",
    message: handleOvercost,
    icon: <Warning style={{ color: "#F7A60E" }} />,
    redirect: (id: number) => `${ROOT_JOB_MANAGEMENT}/${id}`,
  },
  locationMismatch: {
    color: "#EE9DA3",
    title: "Location mismatch",
    message: handleLocationMismatch,
    icon: <Error style={{ color: "#D50918" }} />,
    redirect: (dwoId: number) => `${ROOT_TIMESHEETS_MANAGEMENT}/${dwoId}`,
  },
};
