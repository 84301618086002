import { Box, useTheme } from "@material-ui/core";
import Chart, { ChartDataSets } from "chart.js";
import React, { useEffect, useRef } from "react";
import { PROGRESS_TYPE_LABELS } from "utils/jobDetailsUtils";

interface AllProgressChartProps {
  estimatedProgress: number;
  laborProgress: number;
  timeProgress: number;
}

export function AllProgressChart({
  estimatedProgress,
  laborProgress,
  timeProgress,
}: AllProgressChartProps) {
  const chartCanvasRef = useRef({} as HTMLCanvasElement);
  const theme = useTheme();

  useEffect(() => {
    const isOvertime = timeProgress > 100.0;
    const isOvertimeLabor = laborProgress > 100.0;

    new Chart(chartCanvasRef.current, {
      data: {
        datasets: [
          // Outer ring
          {
            backgroundColor: isOvertime
              ? theme.palette.error.main
              : [theme.palette.primary.light, theme.palette.grey[200]],
            borderWidth: 0,
            data: isOvertime
              ? [timeProgress]
              : [timeProgress, 100.0 - timeProgress],
            label: PROGRESS_TYPE_LABELS.TIME,
          },
          // Empty set to add space bewtween rings
          {
            weight: 1,
          },
          // Middle ring
          {
            backgroundColor: isOvertimeLabor
              ? theme.palette.error.main
              : [theme.palette.success.light, theme.palette.grey[200]],
            borderWidth: 0,
            data: isOvertimeLabor
              ? [laborProgress]
              : [laborProgress, 100.0 - laborProgress],
            label: PROGRESS_TYPE_LABELS.LABOR,
          },
          {
            weight: 1,
          },
          // Inner ring
          {
            backgroundColor: [
              theme.palette.primary.dark,
              theme.palette.grey[200],
            ],
            borderWidth: 0,
            data: [estimatedProgress, 100.0 - estimatedProgress],
            label: PROGRESS_TYPE_LABELS.ESTIMATED,
          },
        ],
      },
      options: {
        animation: {
          animateRotate: true,
        },
        aspectRatio: 1,
        cutoutPercentage: 15,
        hover: {
          mode: undefined,
        },
        tooltips: {
          backgroundColor: theme.palette.grey[200],
          bodyFontColor: theme.palette.primary.dark,
          bodyFontFamily: "Noto Sans",
          bodyFontSize: 16,
          bodyFontStyle: "bold",
          callbacks: {
            label: function (
              item: Chart.ChartTooltipItem,
              data: Chart.ChartData,
            ) {
              const dataSet = (data.datasets as ChartDataSets[])[
                item.datasetIndex as number
              ];

              if (dataSet.label) {
                const dataValue = dataSet.data as any[];
                return `${dataValue[item.index as number]}%`;
              }

              return "";
            },
          },
          cornerRadius: 2,
          displayColors: false,
          xPadding: 40,
          yPadding: 12,
        },
      },
      type: "doughnut",
    });
  }, [estimatedProgress, laborProgress, timeProgress, theme]);

  return (
    <Box
      display="flex"
      justifyContent="center"
      margin="0 auto"
      maxWidth="175px"
    >
      <canvas ref={chartCanvasRef} />
    </Box>
  );
}
