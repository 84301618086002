import { DWOEmployeeModel } from "@dwo/shared/dist/models/DWOModel";
import { ServiceOptions } from "@dwo/shared/dist/services/baseService";
import {
  Box,
  createStyles,
  Grid,
  InputAdornment,
  makeStyles,
  Paper,
  TextField,
  Theme,
  Typography,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { Dropdown } from "components/Dropdown";
import { DropdownPagination } from "components/DropdownPagination";
import { CustomPagination as Pagination } from "components/Pagination";
import { RangeDatePicker } from "components/RangeDatePicker";
import {
  SquareToggleButton,
  ToggleButtonOptions,
} from "components/SquareToggleButton";
import { TableCustom } from "components/table/TableCustom";
import {
  getDWOEmployeeList,
  selectCount,
  selectDWOEmployeeList,
  selectEmployeeFilters,
  selectEmployeeQuery,
  selectIsLoadingDWOEmployeeList,
  selectLimit,
  setEmployeeFilters,
  setEmployeeQuery,
} from "features/dwo/dwoSlice";
import { setDateRange } from "features/employeeTimesheet/employeeTimesheetSlice";
import { cloneDeep } from "lodash";
import { DateRange } from "materialui-daterange-picker";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addLocalTimeOffset, enDashFormatDate } from "utils/dateUtils";
import { DEBOUNCE_TIME_MS, DEFAULT_LIMIT } from "utils/sharedUtils";
import { rowSelectOptions } from "utils/tableUtils";
import {
  DwoStatusValues,
  employeesDWOsListTableColumns,
  getDWOEmployeeRows,
  getTotalPages,
  initialEmployeeQuery,
  timesheetsRoleDropdownOptions,
} from "utils/timesheetsManagementUtils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      borderRadius: 0,
      margin: "0 24px",
      padding: "24px",
      position: "relative",
    },
    rowSelectLabel: {
      color: theme.palette.primary.dark,
      fontWeight: "bold",
    },
    tableContainer: {
      borderRadius: 0,
      margin: "0 24px",
      paddingTop: "32px",
    },
    fakeLinkText: {
      color: "#2D72E0",
      fontSize: "14px",
      fontWeight: 400,
      textDecoration: "underline",
      cursor: "pointer",
    },
  }),
);

export function EmployeeTimesheetList() {
  const dispatch = useDispatch();
  const dwoEmployeeList = useSelector(selectDWOEmployeeList);
  const isLoading = useSelector(selectIsLoadingDWOEmployeeList);
  const [canSearch, setCanSearch] = useState(false);
  const [currentRole, setCurrentRole] = useState("");
  const [page, setPage] = useState<number>(1);
  const [query, setQuery] = useState<ServiceOptions>({});
  const [rowsPerPage, setRowsPerPage] = useState<string>(
    rowSelectOptions[0].value,
  );
  const [searchValue, setSearchValue] = useState("");
  const [sortingOptions, setSortingOptions] = useState<string[][]>([]);
  const [status, setStatus] = useState<ToggleButtonOptions>(
    ToggleButtonOptions.Left,
  );
  const [localDateRange, setLocalDateRange] = useState<DateRange | undefined>(
    undefined,
  );
  const classes = useStyles();

  const currentCount = useSelector(selectCount);
  const currentLimit = useSelector(selectLimit) || DEFAULT_LIMIT;
  const isPaginationSectionDisabled = isLoading || currentCount === 0;
  const storedFilters = useSelector(selectEmployeeFilters);
  const storedQuery = useSelector(selectEmployeeQuery);
  const isFirstRender = useRef(true);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      setCurrentRole(storedFilters.role);
      setPage(storedFilters.page);
      setRowsPerPage(storedFilters.rowsPerPage);
      setSearchValue(storedFilters.searchValue);
      setSortingOptions(storedFilters.sortingOptions);
      setStatus(storedFilters.status);
      setLocalDateRange(storedFilters.dateRange);
      setQuery(storedQuery);
      return;
    }

    dispatch(getDWOEmployeeList(query));
    dispatch(
      setEmployeeFilters({
        searchValue,
        role: currentRole,
        status,
        sortingOptions,
        page,
        rowsPerPage,
        dateRange: localDateRange,
      }),
    );
    dispatch(setEmployeeQuery(query));
    // eslint-disable-next-line
  }, [query, dispatch]);

  useEffect(() => {
    if (!canSearch) return;

    const queryHandler = setTimeout(() => {
      setQuery((prevQuery) => {
        const oldQuery = cloneDeep(prevQuery);

        return {
          ...oldQuery,
          where: {
            ...oldQuery.where,
            searchData: { $iLike: `%${searchValue.split(" ").join("%")}%` },
          },
          offset: 0,
        };
      });

      setPage(1);
    }, DEBOUNCE_TIME_MS);

    return () => {
      clearTimeout(queryHandler);
    };
  }, [searchValue, canSearch]);

  const handleChangePage = (newPage: number) => {
    const updatedQuery = cloneDeep(query);

    updatedQuery.offset = (newPage - 1) * currentLimit;

    setPage(newPage);
    setQuery(updatedQuery);
  };

  const handleChageSearch = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(value);
    setCanSearch(true);
  };

  const handleClickApplyRangeDate = (dateRange: DateRange) => {
    const startDate = dateRange.startDate;
    const endDate = dateRange.endDate;
    const updatedQuery = cloneDeep(query);
    updatedQuery.offset = 0;

    if (startDate && endDate) {
      setLocalDateRange({ startDate, endDate });
      updatedQuery.where = {
        ...query.where,
        date: {
          $gte: enDashFormatDate(startDate),
          $lte: enDashFormatDate(endDate),
        },
      };
    } else {
      setLocalDateRange(undefined);
      if (updatedQuery.where?.date) {
        delete updatedQuery.where.date;
      }
    }

    setPage(1);
    setQuery(updatedQuery);
  };

  const handleClickEmployee = (dwoEmployee: DWOEmployeeModel) => {
    const startDate = addLocalTimeOffset(dwoEmployee.date);
    const endDate = addLocalTimeOffset(dwoEmployee.date);

    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(23, 59, 59, 0);

    dispatch(
      setDateRange({
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
      }),
    );
  };

  const handleClickSorting = (sortingValues: string[][]) => {
    const updatedQuery = cloneDeep(query);
    setSortingOptions(sortingValues);
    updatedQuery.order = sortingValues.length > 0 ? sortingValues : undefined;
    setQuery(updatedQuery);
  };

  const handleSelectRole = (selectedRole?: string) => {
    if (selectedRole) {
      const updatedQuery = cloneDeep(query);

      updatedQuery.offset = 0;
      updatedQuery.where = {
        ...updatedQuery.where,
        role: selectedRole === "all" ? undefined : selectedRole,
      };

      setPage(1);
      setCurrentRole(selectedRole);
      setQuery(updatedQuery);
    }
  };

  const handleSelectRowsPerPage = (selectedValue: string) => {
    const updatedQuery = cloneDeep(query);
    updatedQuery.offset = 0;
    updatedQuery.limit = parseInt(selectedValue, 10);

    setPage(1);
    setRowsPerPage(selectedValue);
    setQuery(updatedQuery);
  };

  const handleToggleDWOStatus = (option: ToggleButtonOptions) => {
    setStatus(option);
    const updatedQuery = cloneDeep(query);

    updatedQuery.offset = 0;
    updatedQuery.where = {
      ...updatedQuery.where,
      status:
        option === ToggleButtonOptions.Left
          ? { $ne: DwoStatusValues.APPROVED }
          : { $eq: DwoStatusValues.APPROVED },
    };

    setPage(1);
    setQuery(updatedQuery);
  };

  const handleClickClearFilters = () => {
    setCurrentRole("");
    setPage(1);
    setRowsPerPage(rowSelectOptions[0].value);
    setSearchValue("");
    setSortingOptions([]);
    setStatus(ToggleButtonOptions.Left);
    setLocalDateRange(undefined);
    setQuery(initialEmployeeQuery);
  };

  return (
    <Fragment>
      <Paper className={classes.paper}>
        <Grid container spacing={3}>
          <Grid item md={4} sm={3} xs={12}>
            <TextField
              placeholder="Search by Employee ID/Name, Foreman or Supervisor"
              margin="normal"
              variant="outlined"
              value={searchValue}
              InputProps={{
                type: "search",
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              onChange={handleChageSearch}
            />
          </Grid>
          <Grid item md={2} sm={3} xs={6}>
            <Dropdown
              id="employeeTimesheet-roleDropdown"
              label="Role"
              options={timesheetsRoleDropdownOptions}
              selectedValue={currentRole}
              onSelectOption={handleSelectRole}
            />
          </Grid>
          <Grid item md={2} sm={3} xs={6}>
            <SquareToggleButton
              activeButton={status}
              leftButtonText="New"
              rightButtonText="Approved"
              onClickToggleButton={handleToggleDWOStatus}
            />
          </Grid>
          <Grid item md={4} sm={3} xs={12}>
            <RangeDatePicker
              initDateRange={localDateRange}
              maxDate={new Date()}
              onClickApply={handleClickApplyRangeDate}
            />
          </Grid>
        </Grid>
        <Box display="flex" justifyContent="flex-end" marginTop="8px">
          <Typography
            className={classes.fakeLinkText}
            onClick={handleClickClearFilters}
          >
            Clear Filters
          </Typography>
        </Box>

        <Box marginBottom="24px" paddingTop="24px">
          <TableCustom
            columns={employeesDWOsListTableColumns}
            isLoading={isLoading}
            noRowsMessage="We couldn't find any result that matched you search. Please try again."
            rows={getDWOEmployeeRows(dwoEmployeeList, handleClickEmployee)}
            sortingOptions={sortingOptions}
            onClickSort={handleClickSorting}
          />
        </Box>

        {!isLoading && (
          <Fragment>
            <Grid alignItems="center" container spacing={4}>
              <Grid item xs={4} />
              <Grid item xs={4}>
                <Box display="flex" justifyContent="center">
                  <Pagination
                    id="employeeTimesheet-pagination"
                    isDisabled={isPaginationSectionDisabled}
                    page={page}
                    totalPages={getTotalPages(currentCount, currentLimit)}
                    onChangePage={handleChangePage}
                  />
                </Box>
              </Grid>

              <Grid item xs={4}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-end"
                >
                  <Box marginRight="8px">
                    <Typography
                      variant="caption"
                      className={classes.rowSelectLabel}
                    >
                      Rows per page:
                    </Typography>
                  </Box>
                  <DropdownPagination
                    id="employeeTimesheet-rowsDropdown"
                    isDisabled={isPaginationSectionDisabled}
                    options={rowSelectOptions}
                    selectedValue={rowsPerPage}
                    onSelectOption={handleSelectRowsPerPage}
                  />
                </Box>
              </Grid>
            </Grid>
          </Fragment>
        )}
      </Paper>
    </Fragment>
  );
}
