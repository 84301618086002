import DateFnsUtils from "@date-io/date-fns";
import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import { Error } from "@material-ui/icons";
import {
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { TooltipCustom } from "components/TooltipCustom";
import {
  removeValueByIdAndType,
  selectValues,
  setValues,
} from "features/employeeTimesheet/employeeTimesheetSlice";
import { useFormik } from "formik";
import { noop } from "lodash/fp";
import React, { ReactNode, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";

interface TableTimePickerProps {
  type: string;
  date: string;
  id: string;
  isEditModeActive: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textField: {
      height: "39px",
      width: "136px",
      "& .MuiInputBase-root": {
        width: "136px",
        height: "39px",
      },
      "& .MuiPickersToolbar-toolbar": {
        backgroundColor: theme.palette.primary.main,
      },
    },
    errorMessage: {
      color: theme.palette.error.main,
      paddingTop: "4px",
    },
    errorIcon: {
      position: "absolute",
      right: "-28px",
      bottom: "6px",
    },
  }),
);

export function TableTimePicker({
  type,
  date,
  id,
  isEditModeActive,
}: TableTimePickerProps) {
  const initSelectedDate = () => {
    const today = new Date();

    if (date) {
      const oldDate = new Date(date);
      today.setHours(oldDate.getHours());
      today.setMinutes(oldDate.getMinutes());

      return today;
    }

    return today;
  };
  const dispatch = useDispatch();
  const values = useSelector(selectValues);
  const [inputError, setInputError] = useState<boolean | undefined>(undefined);
  const [selectedDate, setSelectedDate] = useState(initSelectedDate());
  const [formattedTime, setFormattedTime] = useState("");
  const [isRowActive, setIsRowActive] = useState(false);
  const isFirstRun = useRef(true);
  const classes = useStyles();

  const getFormikInitialState = (date: string) =>
    new Date(date)
      .toLocaleTimeString(undefined, {
        hour: "2-digit",
        minute: "2-digit",
      })
      .split(" ")[0];

  const formik = useFormik({
    initialValues: {
      time: getFormikInitialState(date),
    },
    validationSchema: Yup.object({
      time: Yup.string().required("Time is required"),
    }),
    initialErrors: {
      time: getFormikInitialState(date),
    },
    onSubmit: noop,
  });

  useEffect(() => {
    setIsRowActive(Boolean(values.find((value) => value.idRow === Number(id))));
  }, [values, id]);

  useEffect(() => {
    if (isRowActive && formattedTime !== "Invalid") {
      dispatch(
        setValues({
          id: type,
          idRow: Number(id),
          inputValue: formattedTime,
          inputError,
        }),
      );
    }

    if (formik.isValid && formattedTime !== "Invalid") {
      dispatch(
        setValues({
          id: type,
          idRow: Number(id),
          inputValue: formattedTime,
          inputError,
        }),
      );
    }
  }, [
    dispatch,
    formattedTime,
    inputError,
    type,
    id,
    formik.isValid,
    isRowActive,
  ]);

  useEffect(() => {
    if (inputError || (formik.touched.time && Boolean(formik.errors.time))) {
      dispatch(removeValueByIdAndType({ id: Number(id), type }));
    }
  }, [dispatch, type, id, inputError, formik.touched.time, formik.errors.time]);

  useEffect(() => {
    if (!isEditModeActive) {
      setInputError(undefined);
    }
  }, [isEditModeActive]);

  useEffect(() => {
    if (selectedDate !== null && selectedDate.toString() !== "Invalid Date") {
      setFormattedTime(selectedDate.toString());
      setInputError(false);
    }

    if (selectedDate === null || selectedDate.toString() === "Invalid Date") {
      setInputError(true);
    }
  }, [selectedDate]);

  useEffect(() => {
    if (isFirstRun.current) return;

    if (!isFirstRun.current) {
      formik.setFieldValue("time", formattedTime);
      formik.setFieldTouched("time", true);
    }
    // eslint-disable-next-line
  }, [formattedTime, isFirstRun.current]);

  const handleDateChange = (date: MaterialUiPickersDate) => {
    setSelectedDate(date as Date);
    isFirstRun.current = false;
  };

  const handleChangeError = (error: ReactNode) => {
    setInputError(Boolean(error));
  };

  return (
    <Box flex={1} maxWidth="200px">
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardTimePicker
          autoOk
          margin="normal"
          value={selectedDate}
          className={classes.textField}
          placeholder="__:__ _"
          format="hh:mm a"
          helperText=""
          onError={handleChangeError}
          onChange={handleDateChange}
        />
      </MuiPickersUtilsProvider>
      {inputError && (
        <Box position="relative">
          <TooltipCustom
            title={
              <Typography className={classes.errorMessage} variant="subtitle2">
                Input valid time
              </Typography>
            }
            placement="right"
          >
            <Error className={classes.errorIcon} color="error" />
          </TooltipCustom>
        </Box>
      )}
    </Box>
  );
}
