import { EmployeeModel } from "@dwo/shared/dist/models/employeeModel";
import { MainTableColumn, MainTableRow } from "utils/tableUtils";

export const filterBySupervisorColumns: MainTableColumn[] = [
  {
    field: "checkbox",
    headerName: "",
  },
  {
    field: "image",
    headerName: "",
  },
  {
    field: "name",
    headerName: "NAME",
  },
  {
    field: "employeeId",
    headerName: "ID #",
  },
];

export const getFilterBySupervisorRows = (
  employees: EmployeeModel[],
  employeeSelectedId: string[],
  checkboxHandler: (idRow: string, isChecked: boolean) => void,
): MainTableRow[] =>
  employees.map((employee) => {
    if (employee) {
      const srcImage =
        employee.employeePictures && employee.employeePictures[0]
          ? (employee.employeePictures[0].picture.url as string)
          : "";
      const image = { isImage: true, value: srcImage };
      const name = { value: `${employee.firstName} ${employee.lastName}` };
      const employeeId = { value: employee.employeeId };

      const idRow = employee.id ? employee.id.toString() : "";
      const innerCells = [image, name, employeeId];
      const isCheckedRow = Boolean(
        employeeSelectedId.find((idSelected) => idSelected === idRow),
      );

      return {
        idRow,
        innerCells,
        isCheckedRow,
        onClickCheckbox: checkboxHandler,
      };
    }
    return {} as MainTableRow;
  });
