import { EmployeeModel } from "@dwo/shared/dist/models/employeeModel";
import {
  TimesheetOverviewData,
  TimesheetOverviewDataElement,
} from "@dwo/shared/dist/models/timesheetSummaryModel";
import { DropdownOption } from "components/Dropdown";
import {
  addDays,
  addMonths,
  addQuarters,
  addWeeks,
  addYears,
  endOfMonth,
  endOfQuarter,
  endOfWeek,
  endOfYear,
  getWeeksInMonth,
  isSameDay,
  isSameMonth,
  isSameQuarter,
  isSameWeek,
  isSameYear,
  startOfMonth,
  startOfQuarter,
  startOfWeek,
  startOfYear,
} from "date-fns";
import {
  addLocalTimeOffset,
  getDateRangeFromStringDateRange,
  StringDateRange,
} from "./dateUtils";
import { ALL_OPTION } from "./sharedUtils";
import { getBusinessDaysBetweenDates, OverviewTabs } from "./summaryUtils";

export enum TimesheetCalendarType {
  PerWeek,
  PerMonth,
  PerQuarter,
  PerYear,
}

export enum TimesheetStatusColors {
  Approved = "#002868",
  IdMismatch = "#E84F6E",
  Pending = "#E84F6E",
  Submitted = "#00CCB1",
  TeleMismatch = "#F8872A",
}

export enum TimesheetOverviewCategories {
  PriorDate = "Prior Date",
  SelectedDate = "Selected Date",
}

export const priorDateColor = "#D9E4EB";

export const getForemenDropdownOptions = (
  foremen: EmployeeModel[],
): DropdownOption[] => {
  const foremenOptions: DropdownOption[] = foremen.map(
    (foreman: EmployeeModel) => {
      return {
        label: foreman.fullName,
        value: (foreman.id as number).toString(),
      };
    },
  );

  foremenOptions.unshift(ALL_OPTION);

  return foremenOptions;
};

export const getTimesheetAdjustedDateRange = (start: Date, end: Date) => {
  let startDate = new Date(start);
  let endDate = new Date(end);

  startDate.setHours(0, 0, 0, 0);
  endDate.setHours(23, 59, 59, 0);

  if (isSameWeek(startDate, endDate)) {
    startDate = startOfWeek(startDate);
    endDate = endOfWeek(endDate);
    return {
      startDate,
      endDate,
      calendarRangeType: TimesheetCalendarType.PerWeek,
    };
  }

  if (isSameMonth(startDate, endDate)) {
    startDate = startOfMonth(startDate);
    endDate = endOfMonth(endDate);
    return {
      startDate,
      endDate,
      calendarRangeType: TimesheetCalendarType.PerMonth,
    };
  }

  if (isSameQuarter(startDate, endDate)) {
    startDate = startOfQuarter(startDate);
    endDate = endOfQuarter(endDate);
    return {
      startDate,
      endDate,
      calendarRangeType: TimesheetCalendarType.PerQuarter,
    };
  }

  startDate = startOfYear(startDate);
  endDate = endOfYear(startDate);
  return {
    startDate,
    endDate,
    calendarRangeType: TimesheetCalendarType.PerYear,
  };
};

export const getTimesheetOverviewLegend = (
  type?: TimesheetCalendarType,
  selectedTab?: OverviewTabs,
) => {
  if (type === TimesheetCalendarType.PerWeek) {
    return "Crew Hours Compared With Prior Week";
  }

  if (type === TimesheetCalendarType.PerMonth) {
    return "Crew Hours Compared With Prior Month";
  }

  if (type === TimesheetCalendarType.PerQuarter) {
    return "Crew Hours Compared With Prior Quarter";
  }

  if (
    type === TimesheetCalendarType.PerYear &&
    selectedTab === OverviewTabs.LeftTab
  ) {
    return "Crew Hours Compared With Prior Year (Month)";
  }

  if (
    type === TimesheetCalendarType.PerYear &&
    selectedTab === OverviewTabs.RightTab
  ) {
    return "Crew Hours Compared With Prior Year (Quarters)";
  }

  return "";
};

export const getTimesheetWeekBarChartData = (
  dateRange: StringDateRange,
  currentData: TimesheetOverviewDataElement[],
  priorData: TimesheetOverviewDataElement[],
) => {
  const { startDate, endDate } = getDateRangeFromStringDateRange(dateRange);
  const currentDatesLabels: string[] = [];
  const currentDates = getBusinessDaysBetweenDates(startDate, endDate);
  const priorDates: Date[] = [];
  const emptyDatasetData: number[] = [];
  const currentHoursArray: number[] = [];
  const priorHoursArray: number[] = [];
  const currentCostsArray: number[] = [];
  const priorCostsArray: number[] = [];

  currentDates.forEach((currentDate: Date) => {
    const priorDate = addWeeks(currentDate, -1);
    const foundCurrentData = currentData.find(
      (element: TimesheetOverviewDataElement) => {
        const elementDate = addLocalTimeOffset(element.date);
        return isSameDay(elementDate, currentDate);
      },
    );
    const foundPriorData = priorData.find(
      (element: TimesheetOverviewDataElement) => {
        const elementDate = addLocalTimeOffset(element.date);
        return isSameDay(elementDate, priorDate);
      },
    );

    currentDatesLabels.push(currentDate.toISOString());
    priorDates.push(priorDate);
    emptyDatasetData.push(0);
    currentHoursArray.push(
      foundCurrentData ? parseFloat(foundCurrentData.hours) : 0,
    );
    priorHoursArray.push(foundPriorData ? parseFloat(foundPriorData.hours) : 0);
    currentCostsArray.push(
      foundCurrentData ? parseFloat(foundCurrentData.cost) : 0,
    );
    priorCostsArray.push(foundPriorData ? parseFloat(foundPriorData.cost) : 0);
  });

  return {
    currentDatesLabels,
    priorDates,
    currentDates,
    emptyDatasetData,
    currentHoursArray,
    priorHoursArray,
    currentCostsArray,
    priorCostsArray,
  };
};

export const getTimesheetMonthByWeeksData = (
  dateRange: StringDateRange,
  currentData: TimesheetOverviewData[],
  priorData: TimesheetOverviewData[],
) => {
  const { startDate } = getDateRangeFromStringDateRange(dateRange);
  const currentDatesLabels: string[] = [];
  const currentDates: Date[] = [];
  const priorDates: Date[] = [];
  const emptyDatasetData: number[] = [];
  const currentCostsArray: number[] = [];
  const priorCostsArray: number[] = [];
  const currentHoursArray: number[] = [];
  const priorHoursArray: number[] = [];
  const weeksInMonth = getWeeksInMonth(startDate);
  let currentWeekDate = startOfMonth(startDate);
  let priorWeekDate = addMonths(currentWeekDate, -1);

  for (let idx = 1; idx <= weeksInMonth; idx++) {
    const currentDate = new Date(currentWeekDate);
    const priorDate = new Date(priorWeekDate);
    const nextCurrentWeekDate = addDays(endOfWeek(currentDate), 1);
    const nextPriorWeekDate = addDays(endOfWeek(priorDate), 1);
    let currentWeekCosts = 0.0;
    let currentWeekHours = 0.0;
    let priorWeekCosts = 0.0;
    let priorWeekHours = 0.0;

    const foundCurrentWeek = currentData.find(
      (weekElement: TimesheetOverviewData) => {
        const weekDate = addLocalTimeOffset(weekElement.startDate);
        return (
          isSameWeek(weekDate, currentDate) &&
          isSameMonth(weekDate, currentDate)
        );
      },
    );

    const foundPriorWeek = priorData.find(
      (weekElement: TimesheetOverviewData) => {
        const weekDate = addLocalTimeOffset(weekElement.startDate);
        return (
          isSameWeek(weekDate, priorDate) && isSameMonth(weekDate, priorDate)
        );
      },
    );

    if (foundCurrentWeek) {
      foundCurrentWeek.data.forEach(
        (dataElement: TimesheetOverviewDataElement) => {
          currentWeekCosts += parseFloat(dataElement.cost);
          currentWeekHours += parseFloat(dataElement.hours);
        },
      );
    }

    if (foundPriorWeek) {
      foundPriorWeek.data.forEach(
        (dataElement: TimesheetOverviewDataElement) => {
          priorWeekCosts += parseFloat(dataElement.cost);
          priorWeekHours += parseFloat(dataElement.hours);
        },
      );
    }

    currentDatesLabels.push(currentDate.toISOString());
    currentDates.push(currentDate);
    priorDates.push(priorDate);
    emptyDatasetData.push(0);
    currentCostsArray.push(currentWeekCosts);
    priorCostsArray.push(priorWeekCosts);
    currentHoursArray.push(currentWeekHours);
    priorHoursArray.push(priorWeekHours);
    currentWeekDate = new Date(nextCurrentWeekDate);
    priorWeekDate = new Date(nextPriorWeekDate);
  }

  return {
    currentDatesLabels,
    currentDates,
    priorDates,
    emptyDatasetData,
    currentCostsArray,
    priorCostsArray,
    currentHoursArray,
    priorHoursArray,
  };
};

export const getTimesheetMonthData = (
  dateRange: StringDateRange,
  currentData: TimesheetOverviewData[],
  priorData: TimesheetOverviewData[],
) => {
  const { startDate } = getDateRangeFromStringDateRange(dateRange);
  const prevMonth = addMonths(startDate, -1);
  let currentMonthCosts = 0.0;
  let priorMonthCosts = 0.0;
  let currentMonthHours = 0.0;
  let priorMonthHours = 0.0;
  const currentDatesLabels: string[] = [
    prevMonth.toISOString(),
    startDate.toISOString(),
  ];

  currentData.forEach((monthElement: TimesheetOverviewData) => {
    const monthDate = addLocalTimeOffset(monthElement.startDate);

    if (isSameMonth(monthDate, startDate)) {
      monthElement.data.forEach((dataElement: TimesheetOverviewDataElement) => {
        currentMonthCosts += parseFloat(dataElement.cost);
        currentMonthHours += parseFloat(dataElement.hours);
      });
    }
  });

  priorData.forEach((monthElement: TimesheetOverviewData) => {
    const monthDate = addLocalTimeOffset(monthElement.startDate);

    if (isSameMonth(monthDate, prevMonth)) {
      monthElement.data.forEach((dataElement: TimesheetOverviewDataElement) => {
        priorMonthCosts += parseFloat(dataElement.cost);
        priorMonthHours += parseFloat(dataElement.hours);
      });
    }
  });

  return {
    currentDatesLabels,
    currentMonthCosts,
    currentMonthHours,
    priorMonthCosts,
    priorMonthHours,
  };
};

export const getTimesheetQuarterByMonthsData = (
  dateRange: StringDateRange,
  currentData: TimesheetOverviewData[],
  priorData: TimesheetOverviewData[],
) => {
  const { startDate } = getDateRangeFromStringDateRange(dateRange);
  const currentDatesLabels: string[] = [];
  const currentDates: Date[] = [];
  const priorDates: Date[] = [];
  const emptyDatasetData: number[] = [];
  const currentCostsArray: number[] = [];
  const priorCostsArray: number[] = [];
  const currentHoursArray: number[] = [];
  const priorHoursArray: number[] = [];
  const monthsInQuarter = 3;
  let currentMonthDate = startOfQuarter(startDate);
  let priorMonthDate = addMonths(currentMonthDate, -monthsInQuarter);

  for (let idx = 1; idx <= monthsInQuarter; idx++) {
    const currentDate = new Date(currentMonthDate);
    const priorDate = new Date(priorMonthDate);
    const nextCurrentMonthDate = addMonths(currentDate, 1);
    const nextPriorMonthDate = addMonths(priorDate, 1);
    let currentMonthCosts = 0.0;
    let currentMonthHours = 0.0;
    let priorMonthCosts = 0.0;
    let priorMonthHours = 0.0;

    const foundCurrentMonth = currentData.find(
      (monthElement: TimesheetOverviewData) => {
        const monthDate = addLocalTimeOffset(monthElement.startDate);
        return isSameMonth(monthDate, currentDate);
      },
    );

    const foundPriorMonth = priorData.find(
      (monthElement: TimesheetOverviewData) => {
        const monthDate = addLocalTimeOffset(monthElement.startDate);
        return isSameMonth(monthDate, priorDate);
      },
    );

    if (foundCurrentMonth) {
      foundCurrentMonth.data.forEach(
        (dataElement: TimesheetOverviewDataElement) => {
          currentMonthCosts += parseFloat(dataElement.cost);
          currentMonthHours += parseFloat(dataElement.hours);
        },
      );
    }

    if (foundPriorMonth) {
      foundPriorMonth.data.forEach(
        (dataElement: TimesheetOverviewDataElement) => {
          priorMonthCosts += parseFloat(dataElement.cost);
          priorMonthHours += parseFloat(dataElement.hours);
        },
      );
    }

    currentDatesLabels.push(currentDate.toISOString());
    currentDates.push(currentDate);
    priorDates.push(priorDate);
    emptyDatasetData.push(0);
    currentCostsArray.push(currentMonthCosts);
    priorCostsArray.push(priorMonthCosts);
    currentHoursArray.push(currentMonthHours);
    priorHoursArray.push(priorMonthHours);
    currentMonthDate = new Date(nextCurrentMonthDate);
    priorMonthDate = new Date(nextPriorMonthDate);
  }

  return {
    currentDatesLabels,
    currentDates,
    priorDates,
    emptyDatasetData,
    currentCostsArray,
    priorCostsArray,
    currentHoursArray,
    priorHoursArray,
  };
};

export const getTimesheetYearlyByMonthsData = (
  dateRange: StringDateRange,
  currentData: TimesheetOverviewData[],
  priorData: TimesheetOverviewData[],
) => {
  const { startDate } = getDateRangeFromStringDateRange(dateRange);
  const currentDatesLabels: string[] = [];
  const currentDates: Date[] = [];
  const priorDates: Date[] = [];
  const currentCostsArray: number[] = [];
  const priorCostsArray: number[] = [];
  const currentHoursArray: number[] = [];
  const priorHoursArray: number[] = [];
  const monthsInYear = 12;
  let currentMonthDate = startOfYear(startDate);
  let priorMonthDate = addYears(currentMonthDate, -1);

  for (let idx = 1; idx <= monthsInYear; idx++) {
    const currentDate = new Date(currentMonthDate);
    const priorDate = new Date(priorMonthDate);
    const nextCurrentMonthDate = addMonths(currentDate, 1);
    const nextPriorMonthDate = addMonths(priorDate, 1);
    let currentMonthCosts = 0.0;
    let currentMonthHours = 0.0;
    let priorMonthCosts = 0.0;
    let priorMonthHours = 0.0;

    const foundCurrentMonth = currentData.find(
      (monthElement: TimesheetOverviewData) => {
        const monthDate = addLocalTimeOffset(monthElement.startDate);
        return (
          isSameMonth(monthDate, currentDate) &&
          isSameYear(monthDate, currentDate)
        );
      },
    );

    const foundPriorMonth = priorData.find(
      (monthElement: TimesheetOverviewData) => {
        const monthDate = addLocalTimeOffset(monthElement.startDate);
        return (
          isSameMonth(monthDate, priorDate) && isSameYear(monthDate, priorDate)
        );
      },
    );

    if (foundCurrentMonth) {
      foundCurrentMonth.data.forEach(
        (dataElement: TimesheetOverviewDataElement) => {
          currentMonthCosts += parseFloat(dataElement.cost);
          currentMonthHours += parseFloat(dataElement.hours);
        },
      );
    }

    if (foundPriorMonth) {
      foundPriorMonth.data.forEach(
        (dataElement: TimesheetOverviewDataElement) => {
          priorMonthCosts += parseFloat(dataElement.cost);
          priorMonthHours += parseFloat(dataElement.hours);
        },
      );
    }

    currentDatesLabels.push(currentDate.toISOString());
    currentDates.push(currentDate);
    priorDates.push(priorDate);
    currentCostsArray.push(currentMonthCosts);
    priorCostsArray.push(priorMonthCosts);
    currentHoursArray.push(currentMonthHours);
    priorHoursArray.push(priorMonthHours);
    currentMonthDate = new Date(nextCurrentMonthDate);
    priorMonthDate = new Date(nextPriorMonthDate);
  }

  return {
    currentDatesLabels,
    priorDates,
    currentDates,
    currentHoursArray,
    priorHoursArray,
    currentCostsArray,
    priorCostsArray,
  };
};

export const getTimesheetYearlyByQuartersData = (
  dateRange: StringDateRange,
  currentData: TimesheetOverviewData[],
  priorData: TimesheetOverviewData[],
) => {
  const { startDate } = getDateRangeFromStringDateRange(dateRange);
  const currentDatesLabels: string[] = [];
  const currentDates: Date[] = [];
  const priorDates: Date[] = [];
  const emptyDatasetData: number[] = [];
  const currentCostsArray: number[] = [];
  const priorCostsArray: number[] = [];
  const currentHoursArray: number[] = [];
  const priorHoursArray: number[] = [];
  const quartersInYear = 4;
  let currentQuarterDate = startOfYear(startDate);
  let priorQuarterDate = addYears(currentQuarterDate, -1);

  for (let idx = 1; idx <= quartersInYear; idx++) {
    const currentDate = new Date(currentQuarterDate);
    const priorDate = new Date(priorQuarterDate);
    const nextCurrentQuarterDate = addQuarters(currentDate, 1);
    const nextPriorQuarterDate = addQuarters(priorDate, 1);
    let currentQuarterCosts = 0.0;
    let currentQuarterHours = 0.0;
    let priorQuarterCosts = 0.0;
    let priorQuarterHours = 0.0;

    currentData.forEach((monthElement: TimesheetOverviewData) => {
      const monthDate = addLocalTimeOffset(monthElement.startDate);

      if (
        isSameQuarter(monthDate, currentDate) &&
        isSameYear(monthDate, currentDate)
      ) {
        monthElement.data.forEach(
          (dataElement: TimesheetOverviewDataElement) => {
            currentQuarterCosts += parseFloat(dataElement.cost);
            currentQuarterHours += parseFloat(dataElement.hours);
          },
        );
      }
    });

    priorData.forEach((monthElement: TimesheetOverviewData) => {
      const monthDate = addLocalTimeOffset(monthElement.startDate);

      if (
        isSameQuarter(monthDate, priorDate) &&
        isSameYear(monthDate, priorDate)
      ) {
        monthElement.data.forEach(
          (dataElement: TimesheetOverviewDataElement) => {
            priorQuarterCosts += parseFloat(dataElement.cost);
            priorQuarterHours += parseFloat(dataElement.hours);
          },
        );
      }
    });

    currentDatesLabels.push(currentDate.toISOString());
    currentDates.push(currentDate);
    priorDates.push(priorDate);
    emptyDatasetData.push(0);
    currentCostsArray.push(currentQuarterCosts);
    priorCostsArray.push(priorQuarterCosts);
    currentHoursArray.push(currentQuarterHours);
    priorHoursArray.push(priorQuarterHours);
    currentQuarterDate = new Date(nextCurrentQuarterDate);
    priorQuarterDate = new Date(nextPriorQuarterDate);
  }

  return {
    currentDatesLabels,
    currentDates,
    priorDates,
    emptyDatasetData,
    currentCostsArray,
    priorCostsArray,
    currentHoursArray,
    priorHoursArray,
  };
};
