import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
  Paper,
  Grid,
  Button,
} from "@material-ui/core";
import React, { useState, Fragment } from "react";
import {
  formatCsvRow,
  getMissingCsvColumns,
  getPreviewColumns,
  getPreviewRows,
} from "utils/uploadUtils";
import { UploadInput } from "components/UploadInput";
import { FileData } from "utils/sharedUtils";
import { useDispatch } from "react-redux";
import { error } from "features/error/errorSlice";
import { noop } from "lodash/fp";
import { PreviewCsvModal } from "components/upload/PreviewCsvModal";
import { MainTableColumn, MainTableRow } from "utils/tableUtils";
import { uploadCsvFile } from "features/upload/uploadEmployeeSlice";
import { UploadHistory } from "components/upload/UploadHistory";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      fontSize: "32px",
      color: theme.palette.primary.main,
      fontWeight: 600,
    },
    paper: {
      backgroundColor: "white",
      margin: "0 24px 24px 24px",
      padding: "24px",
      borderRadius: 0,
      position: "relative",
      zIndex: 2,
    },
    helpText: {
      color: theme.palette.grey["400"],
    },
  }),
);

export function UploadScreen() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [fileName, setFileName] = useState("");
  const [previewColumns, setPreviewColumns] = useState<MainTableColumn[]>([]);
  const [previewRows, setPreviewRows] = useState<MainTableRow[]>([]);
  const [csvFile, setCsvFile] = useState<File | undefined>(undefined);

  const handleChangeCsvFile = (fileData: FileData) => {
    const file = fileData.file;
    if (file) {
      const reader = new FileReader();
      reader.readAsText(file);
      reader.addEventListener("load", () => {
        const contents = reader.result as string;
        const lines = contents.split("\n");

        if (lines.length <= 1) {
          dispatch(
            error(
              {
                title: "Error on the file",
                message:
                  "The file doesn't have any data, please upload a valid file",
                actionButtonText: "Ok",
                hideCancelButton: true,
              },
              noop,
            ),
          );
          return;
        }

        if (lines.length > 5001) {
          dispatch(
            error(
              {
                title: "Error on the file",
                message: "The file should have less than 5000 entries",
                actionButtonText: "Ok",
                hideCancelButton: true,
              },
              noop,
            ),
          );
          return;
        }

        const headers = formatCsvRow(lines[0]);

        const missingColumns = getMissingCsvColumns(headers);
        if (missingColumns.length > 0) {
          const missingColumnsStr = missingColumns
            .toString()
            .replace(/,/gi, ", ");
          dispatch(
            error(
              {
                title: "Error on the file",
                message: `The file is missing the following columns: ${missingColumnsStr}, please use the template to create the csv file`,
                actionButtonText: "Ok",
                hideCancelButton: true,
              },
              noop,
            ),
          );
          return;
        }

        const dataRows = [...lines];
        dataRows.shift();
        if (dataRows.length > 10) {
          dataRows.length = 10;
        }

        const formatedRows = dataRows.map((dataRow) => formatCsvRow(dataRow));
        setFileName(file.name);
        setPreviewColumns(getPreviewColumns(headers));
        setPreviewRows(getPreviewRows(formatedRows));
        setCsvFile(file);
        setIsPreviewOpen(true);
      });
    } else {
      dispatch(
        error(
          {
            title: "Failed to load the file",
            message: `Something went wrong, please use the template to create a .csv file before uploading it`,
            actionButtonText: "Ok",
            hideCancelButton: true,
          },
          noop,
        ),
      );
    }
  };

  const handleClickCancelPreview = () => setIsPreviewOpen(false);

  const handleClickUploadPreview = () => {
    if (csvFile) {
      setIsPreviewOpen(false);
      dispatch(uploadCsvFile(csvFile));
    }
  };

  return (
    <Fragment>
      <Paper className={classes.paper}>
        <Grid container spacing={3} direction="column">
          <Grid item lg={5} sm={8} xs={12}>
            <Box marginTop="32px">
              <UploadInput
                accept=".csv, application/vnd.ms-excel"
                id="uploadCsv"
                name="uploadCsv"
                text="Select File"
                maxSize={10000000}
                onUploadFiles={handleChangeCsvFile}
              />
            </Box>
          </Grid>
          <Grid item lg={5} sm={8} xs={12}>
            <Typography variant="body2" className={classes.helpText}>
              The data size must be less than 10MB and the number of rows less
              than 1000 To load more, use your data is in CSV text file format.
            </Typography>
          </Grid>
          <Grid item lg={5} sm={8} xs={12}>
            <a
              href={`${process.env.REACT_APP_API_BASE_URL}/export_employee_template.csv`}
              download="employee_template.csv"
              style={{ textDecoration: "none" }}
            >
              <Button variant="outlined">Download Template</Button>
            </a>
          </Grid>
        </Grid>
      </Paper>

      <UploadHistory />

      <PreviewCsvModal
        open={isPreviewOpen}
        columns={previewColumns}
        fileName={fileName}
        rows={previewRows}
        onClickCancel={handleClickCancelPreview}
        onClickUpload={handleClickUploadPreview}
      />
    </Fragment>
  );
}
