import {
  Box,
  Button,
  createStyles,
  makeStyles,
  Modal,
  Theme,
  Typography,
} from "@material-ui/core";
import React from "react";
import { MainTableRow, MainTableColumn } from "utils/tableUtils";
import { TableCustom } from "components/table/TableCustom";
import { noop } from "lodash/fp";
import PublishSharpIcon from "@material-ui/icons/PublishSharp";

interface PreviewCsvModalProps {
  open: boolean;
  fileName?: string;
  columns: MainTableColumn[];
  rows: MainTableRow[];
  onClickUpload: () => void;
  onClickCancel: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      fontSize: "20px",
      marginBottom: "8px",
    },
    helperText: {
      color: theme.palette.grey["500"],
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    container: {
      backgroundColor: "white",
      maxHeight: "calc(100vh - 48px)",
      outline: "0",
      overflow: "hidden",
      width: "85%",
    },
    tableContainer: {
      maxHeight: "calc(100vh - 251px)",
      overflowY: "auto",
    },
    uploadButton: {
      "& > .MuiButton-label > .MuiButton-iconSizeMedium > *:first-child": {
        fontSize: "32px",
      },
    },
  }),
);

export function PreviewCsvModal({
  open,
  fileName,
  columns,
  rows,
  onClickUpload,
  onClickCancel,
}: PreviewCsvModalProps) {
  const classes = useStyles();
  return (
    <Modal className={classes.modal} open={open} onClose={onClickCancel}>
      <Box className={classes.container}>
        <Box padding="24px">
          <Typography
            color="primary"
            variant="subtitle2"
            className={classes.header}
          >
            {fileName ? `Preview of file “${fileName}”` : "Preview"}
          </Typography>

          <Typography variant="body2" className={classes.helperText}>
            Data preview (Top 10 rows displayed).
          </Typography>
        </Box>
        <Box className={classes.tableContainer}>
          <TableCustom columns={columns} rows={rows} onClickSort={noop} />
        </Box>
        <Box display="flex" justifyContent="flex-end" padding="24px">
          <Box width="150px">
            <Button fullWidth variant="outlined" onClick={onClickCancel}>
              Cancel
            </Button>
          </Box>
          <Box width="150px" marginLeft="24px">
            <Button
              fullWidth
              variant="contained"
              startIcon={<PublishSharpIcon />}
              onClick={onClickUpload}
              className={classes.uploadButton}
            >
              Upload
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}
