import React, { FC } from "react";
import {
  Box,
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import { Layout } from "components/layout/Layout";
import versions from "utils/versions.json";
import VersionCard from "components/appChangelog/VersionCard";
import VersionCollapsible from "components/appChangelog/VersionCollapsible";

export interface AppChangelogProps {
  date: string;
  description: string;
  id: string;
  version: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appChangelogContainer: {
      justifyContent: "center",
      overflow: "hidden",
      padding: "0 24px 24px",
    },
    header: {
      fontSize: "32px",
      color: theme.palette.primary.main,
      fontWeight: 600,
    },
    versionLayout: {
      display: "grid",
    },
    container: {
      display: "grid",
      gridRowEnd: "2",
      gridRowStart: "1",
    },
    updateReleased: {
      color: theme.palette.primary.light,
      fontSize: "24px",
      fontStretch: "normal",
      fontStyle: "normal",
      fontWeight: "bold",
      gridColumnEnd: "3",
      gridColumnStart: "1",
      gridRowEnd: "2",
      gridRowStart: "1",
      letterSpacing: "normal",
      lineHeight: "normal",
      margin: "40px 0px 4px 23px",
      textAlign: "left",
    },
    versionDate: {
      color: theme.palette.primary.light,
      fontSize: "16px",
      margin: "52px 16px 4px 0px",
      textAlign: "left",
    },
    date: {
      fontSize: "12px",
      fontStretch: "normal",
      fontStyle: "normal",
      fontWeight: "normal",
      gridColumnEnd: "4",
      gridColumnStart: "3",
      gridRowEnd: "2",
      gridRowStart: "1",
      letterSpacing: "normal",
      lineHeight: "normal",
      margin: "52px 16px 4px 0px",
      textAlign: "right",
    },
    versionSubtitle: {
      color: theme.palette.primary.dark,
      fontSize: "24px",
      fontStretch: "normal",
      fontStyle: "normal",
      fontWeight: "bold",
      girdRowEnd: "3",
      gridColumnEnd: "4",
      gridColumnStart: "1",
      gridRowStart: "2",
      letterSpacing: "normal",
      lineHeight: "normal",
      marginLeft: "23px",
      textAlign: "left",
    },
    versionCard: {
      gridColumnStart: "1",
      gridColumnEnd: "4",
      gridRowStart: "3",
      gridRowEnd: "4",
    },
    previousVersions: {
      color: theme.palette.primary.dark,
      fontSize: "20px",
      fontStretch: "normal",
      fontStyle: "normal",
      fontWeight: "bold",
      height: "32px",
      letterSpacing: "normal",
      lineHeight: "normal",
      margin: "24px 425px 22px 23px",
      textAlign: "left",
      width: "318px",
    },
    previousVersion: {
      borderTop: theme.palette.primary.dark,
    },
  }),
);
export const AppChangelogScreen: FC = () => {
  const classes = useStyles();

  return (
    <Layout>
      <Box className={classes.appChangelogContainer}>
        {versions.map((version: AppChangelogProps, index: any) => {
          return index === 0 ? (
            <Grid container>
              <Grid item md={4} xs={12}>
                <Box
                  alignItems="center"
                  display="flex"
                  margin="32px 20px 0px 20px"
                >
                  <Typography className={classes.header} component="h1">
                    App Changelog
                  </Typography>
                </Box>
              </Grid>

              <div
                key={version.id.toString()}
                className={classes.versionLayout}
              >
                <div className={classes.container}>
                  <div className={classes.updateReleased}>
                    Last Production Release: {version.date}
                  </div>
                  <span className={classes.versionSubtitle}>
                    PR - {version.version}
                  </span>
                  <div className={classes.versionCard}>
                    <VersionCard version={version} />
                  </div>
                </div>
              </div>
            </Grid>
          ) : (
            <div key={version.id.toString()}>
              {index === 1 ? (
                <div className={classes.previousVersions}>
                  Previous Versions
                </div>
              ) : null}
              <VersionCollapsible version={version} index={index} />
            </div>
          );
        })}
      </Box>
    </Layout>
  );
};
