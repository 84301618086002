import { DWOJobModel } from "@dwo/shared/dist/models/DWOJobModel";
import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import { GlobalModal } from "components/globalModal/GlobalModal";
import { TableCustom } from "components/table/TableCustom";
import {
  selectCurrentJob,
  selectIsLoading,
  selectIsLoadingJobDWOs,
  selectJobDWOs,
} from "features/jobs/jobsSlice";
import { noop } from "lodash";
import React from "react";
import { useSelector } from "react-redux";
import {
  getTimesheetsModalRows,
  jobTimesheetsModalColumns,
} from "utils/jobDetailsUtils";

interface TimesheetsModalProps {
  isOpen: boolean;
  title?: string;
  onClickClose: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    description: {
      color: theme.palette.grey[500],
    },
  }),
);

export function TimesheetsModal({
  isOpen,
  title = "Select Timesheet",
  onClickClose,
}: TimesheetsModalProps) {
  const isLoadingDWOJobs = useSelector(selectIsLoadingJobDWOs);
  const isLoadingJobdata = useSelector(selectIsLoading);
  const jobData = useSelector(selectCurrentJob);
  const jobDWOs = useSelector(selectJobDWOs);
  const classes = useStyles();

  const foremenIds: number[] = [];

  jobDWOs.forEach((jobDwo) => {
    const foremanId = jobDwo.crew?.foremanId;

    if (foremanId && !foremenIds.find((id) => id === foremanId)) {
      foremenIds.push(foremanId);
    }
  });

  const selectedJobDwos: DWOJobModel[] = [];
  foremenIds.forEach((id) => {
    const foundJobDwo = jobDWOs.find((jd) => jd.crew?.foremanId === id);
    if (foundJobDwo) {
      selectedJobDwos.push(foundJobDwo);
    }
  });

  const isLoading = isLoadingDWOJobs || isLoadingJobdata;
  const rows =
    jobData && selectedJobDwos.length > 0
      ? getTimesheetsModalRows(selectedJobDwos, jobData)
      : [];

  const description = (
    <Box margin="0 24px 24px 24px">
      <Typography className={classes.description} variant="body2">
        Select a Timesheet to View.
      </Typography>
    </Box>
  );

  return (
    <GlobalModal
      Filters={description}
      open={isOpen}
      showActionButtons={false}
      title={title}
      onClickClose={onClickClose}
    >
      <TableCustom
        columns={jobTimesheetsModalColumns}
        isLoading={isLoading}
        rows={rows}
        onClickSort={noop}
      />
    </GlobalModal>
  );
}
