import {
  createStyles,
  FormControl,
  makeStyles,
  MenuItem,
  Select,
  Theme,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import React from "react";

export interface DropdownOption {
  label: string;
  value: string;
}

interface DropdownPaginationProps {
  id?: string;
  isDisabled?: boolean;
  isRequired?: boolean;
  name?: string;
  options: DropdownOption[];
  selectedValue?: string;
  onSelectOption: (newSelection: string) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    itemLabel: {
      justifyContent: "flex-start",
    },
    itemRoot: {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
    paper: {
      backgroundColor: theme.palette.grey["200"],
      borderRadius: "2px",
      boxShadow: "1px 1px 8px #0000001A",
      color: theme.palette.grey["500"],
      display: "flex",
      flexDirection: "column",
      marginTop: "8px",
    },
    select: {
      height: "32px",
      width: "auto",
      fontSize: "14px",
      "& .MuiOutlinedInput-input": {
        padding: "4px 24px 4px 8px",
      },
      "& .MuiSelect-iconOutlined": {
        fontSize: "24px",
        right: "1px",
        top: "calc(50% - 12px)",
      },
    },
  }),
);

export function DropdownPagination({
  id = "dropdownSelector",
  isDisabled = false,
  isRequired = false,
  name = "dropdownPaginationSelector",
  options,
  selectedValue,
  onSelectOption,
}: DropdownPaginationProps) {
  const classes = useStyles();

  const handleSelectOption = (event: React.ChangeEvent<{ value: unknown }>) => {
    onSelectOption(event.target.value as string);
  };

  const renderValue = () => {
    if (selectedValue) {
      return options.find(
        (option: DropdownOption) => option.value === selectedValue,
      )?.label;
    }

    return "";
  };

  return (
    <FormControl disabled={isDisabled} variant="outlined">
      <Select
        autoWidth
        className={classes.select}
        displayEmpty
        IconComponent={ExpandMore}
        inputProps={{
          name,
          id,
        }}
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          PopoverClasses: {
            paper: classes.paper,
          },
          getContentAnchorEl: undefined,
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        }}
        renderValue={renderValue}
        required={isRequired}
        value={selectedValue}
        onChange={handleSelectOption}
      >
        {options.map((option: DropdownOption, index: number) => (
          <MenuItem
            classes={{
              root: index !== options.length - 1 ? classes.itemRoot : undefined,
            }}
            key={option.value}
            value={option.value}
          >
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
