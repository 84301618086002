import { EmployeeModel } from "@dwo/shared/dist/models/employeeModel";
import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import { Dropdown, DropdownOption } from "components/Dropdown";
import { GlobalModal } from "components/globalModal/GlobalModal";
import {
  filterBySupervisorColumns,
  getFilterBySupervisorRows,
} from "components/layout/filterByModalUtils";
import { TableCustom } from "components/table/TableCustom";
import { SearchByNameOrId } from "components/userManagement/SearchByNameOrId";
import {
  getAllRegions,
  selectLoading as selectLoadingRegions,
  selectRegions,
} from "features/region/regionSlice";
import {
  addActiveSupervisorsWithStatus,
  getAllSupervisorsWithImage,
  selectActiveSupervisors,
  selectLoading,
  selectSupervisors,
} from "features/supervisors/supervisorsSlice";
import { noop } from "lodash/fp";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { parseRegions } from "utils/homeUtils";
import { ALL_OPTION } from "utils/sharedUtils";

interface FilterBySupervisorModalProps {
  isOpen: boolean;
  onToggle: VoidFunction;
}

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    searchBar: {
      width: "100%",
      marginTop: "16px",
    },
  }),
);

export function FilterBySupervisorModal({
  isOpen,
  onToggle,
}: FilterBySupervisorModalProps) {
  const dispatch = useDispatch();
  const supervisors = useSelector(selectSupervisors);
  const currentRegions = useSelector(selectRegions);
  const isLoadingRegions = useSelector(selectLoadingRegions);
  const isLoadingSupervisors = useSelector(selectLoading);
  const activeSupervisors = useSelector(selectActiveSupervisors);
  const [region, setRegion] = useState<string>(ALL_OPTION.value);
  const [searchedName, setSearchedName] = useState("");
  const [searchedEmployeeId, setSearchedEmployeeId] = useState("");
  const [employeeSelectedIds, setEmployeeSelectedIds] = useState<string[]>([]);
  const availableSupervisors =
    activeSupervisors && activeSupervisors.length > 0
      ? supervisors.filter(
          (supervisor) =>
            !activeSupervisors.find(
              (activeSupervisor) => activeSupervisor.id === supervisor.id,
            ),
        )
      : supervisors;
  const classes = useStyles();
  const allRegions: DropdownOption[] = [
    ALL_OPTION,
    ...parseRegions(currentRegions),
  ];
  const isLoading = isLoadingSupervisors || isLoadingRegions;

  useEffect(() => {
    if (isOpen) {
      dispatch(getAllRegions());
      dispatch(
        getAllSupervisorsWithImage({
          where: {
            role: { $eq: "supervisor" },
            regionId: region === "all" ? undefined : Number(region),
          },
        }),
      );
    }
  }, [dispatch, isOpen, region]);

  useEffect(() => {
    if (!isOpen) {
      setEmployeeSelectedIds([]);
      setRegion(ALL_OPTION.value);
    }
  }, [isOpen]);

  const handleSelectRegion = (selectedRegion?: string) => {
    if (selectedRegion) {
      setRegion(selectedRegion);
    }
  };

  const handleChangeSearch = useCallback(
    (employeeId: string, employeeName: string) => {
      setSearchedName(employeeName);
      setSearchedEmployeeId(employeeId);
    },
    [],
  );

  const filterEmployeesBySearchResult = (data: EmployeeModel[]) => {
    if (searchedEmployeeId && searchedName) {
      return data.filter(
        ({ employeeId, firstName }) =>
          employeeId === searchedEmployeeId && firstName === searchedName,
      );
    }
    return data;
  };

  const handleClickCheckbox = (idRow: string, isChecked: boolean) => {
    if (isChecked && !employeeSelectedIds.includes(idRow)) {
      setEmployeeSelectedIds((prevState) => [...prevState, idRow]);
    }

    if (!isChecked && employeeSelectedIds.includes(idRow)) {
      setEmployeeSelectedIds((prevState) =>
        prevState.filter((employeeId: string) => employeeId !== idRow),
      );
    }
  };

  const handleClickContinue = () => {
    if (employeeSelectedIds.length > 0) {
      const selectedSupervisors = availableSupervisors.filter(({ id }) =>
        employeeSelectedIds.includes(String(id)),
      );
      dispatch(addActiveSupervisorsWithStatus(selectedSupervisors));
      onToggle();
    }
  };

  return (
    <GlobalModal
      Filters={
        <Box width="100%" padding="0px 24px" marginTop="-8px">
          <Typography variant="subtitle2">
            Choose a supervisor to filter
          </Typography>
          <Box
            display="flex"
            width="100%"
            justifyContent="space-between"
            alignItems="center"
            marginTop="8px"
          >
            <Typography variant="h6" color="primary">
              Available ({availableSupervisors.length})
            </Typography>
            <Box width="150px">
              <Dropdown
                id="regionDropdown"
                label="Region"
                options={allRegions}
                selectedValue={region}
                onSelectOption={handleSelectRegion}
              />
            </Box>
          </Box>
          <SearchByNameOrId
            filteredEmployees={availableSupervisors}
            employeeRoleLabel="Supervisor"
            className={classes.searchBar}
            onChange={handleChangeSearch}
          />
        </Box>
      }
      isContinueDisabled={employeeSelectedIds.length === 0}
      open={isOpen}
      primaryLabel="Filter"
      title="Filter By"
      onClickClose={onToggle}
      onClickContinue={handleClickContinue}
    >
      <Box padding="24px 24px 0px 24px">
        <TableCustom
          columns={filterBySupervisorColumns}
          isLoading={isLoading}
          noRowsMessage="There are no available supervisors to filter by."
          rows={getFilterBySupervisorRows(
            filterEmployeesBySearchResult(availableSupervisors),
            employeeSelectedIds,
            handleClickCheckbox,
          )}
          onClickSort={noop}
        />
      </Box>
    </GlobalModal>
  );
}
