import {
  Box,
  Button,
  createStyles,
  makeStyles,
  Theme,
} from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: theme.palette.grey[200],
      border: `1px solid ${theme.palette.grey[400]}`,
      borderRadius: "2px",
      display: "flex",
      justifyContent: "center",
      height: "48px",
      overflow: "hidden",
      padding: "4px",
      "& > button:first-child": {
        marginRight: "4px",
      },
    },
    selectedButton: {
      backgroundColor: theme.palette.primary.main,
      color: "white",
      height: "auto",
      maxWidth: "50%",
      padding: "4px",
      width: "100%",
      "&:hover": {
        backgroundColor: theme.palette.primary.dark,
      },
    },
    unselectedButton: {
      color: theme.palette.grey[400],
      height: "auto",
      maxWidth: "50%",
      padding: "4px",
      width: "100%",
    },
  }),
);

export enum ToggleButtonOptions {
  Left,
  Right,
}

interface SquareToggleButtonProps {
  activeButton: ToggleButtonOptions;
  leftButtonText: string;
  rightButtonText: string;
  onClickToggleButton: (button: ToggleButtonOptions) => void;
}

export function SquareToggleButton({
  activeButton,
  leftButtonText,
  rightButtonText,
  onClickToggleButton,
}: SquareToggleButtonProps) {
  const classes = useStyles();

  const getAssignedClass = (button: ToggleButtonOptions) =>
    activeButton === button ? classes.selectedButton : classes.unselectedButton;

  const getAssignedVariant = (button: ToggleButtonOptions) =>
    activeButton === button ? "contained" : "text";

  const handleClickButton = (button: ToggleButtonOptions) => () => {
    if (button === activeButton) return;
    onClickToggleButton(button);
  };

  return (
    <Box className={classes.container}>
      <Button
        className={getAssignedClass(ToggleButtonOptions.Left)}
        variant={getAssignedVariant(ToggleButtonOptions.Left)}
        onClick={handleClickButton(ToggleButtonOptions.Left)}
      >
        {leftButtonText}
      </Button>
      <Button
        className={getAssignedClass(ToggleButtonOptions.Right)}
        variant={getAssignedVariant(ToggleButtonOptions.Right)}
        onClick={handleClickButton(ToggleButtonOptions.Right)}
      >
        {rightButtonText}
      </Button>
    </Box>
  );
}
