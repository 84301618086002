import { Box, Button, Grid, MenuItem } from "@material-ui/core";
import { DropdownOption } from "components/Dropdown";
import { GlobalModal } from "components/globalModal/GlobalModal";
import { SelectCustom } from "components/jobForm/SelectCustom";
import { TextFieldCustom } from "components/jobForm/TextFieldCustom";
import { Formik } from "formik";
import React, { useState } from "react";
import {
  JobProgressFormFields,
  jobProgressSelectValues,
} from "utils/jobDetailsUtils";
import { JobStatusValues } from "utils/jobUtils";
import * as Yup from "yup";

interface JobProgressModalProps {
  isOpen: boolean;
  progress: number;
  status: JobStatusValues;
  onClickCancel: () => void;
  onSubmit: (status: JobStatusValues, progress: string) => void;
}

export function JobProgressModal({
  isOpen,
  progress,
  status,
  onClickCancel,
  onSubmit,
}: JobProgressModalProps) {
  const [hasErrors, setHasErrors] = useState<boolean>(false);

  const validationSchema = Yup.object({
    progress: Yup.number()
      .typeError("Invalid Progress")
      .min(0, "Progress must be a value between 0 and 100")
      .max(100, "Progress must be a value between 0 and 100")
      .required("Progress is Requiered"),
    status: Yup.string().required("Please select a Status"),
  });

  const handleSubmit = ({ status, progress }: JobProgressFormFields) => {
    onSubmit(status as JobStatusValues, progress.toString());
  };

  const handleClickSave = () =>
    document.getElementById("submitJobProgressForm-btn")?.click();

  return (
    <GlobalModal
      isContinueDisabled={hasErrors}
      open={isOpen}
      title="Job Progress"
      onClickClose={onClickCancel}
      onClickContinue={handleClickSave}
    >
      <Box padding="24px 24px 110px 24px">
        <Formik
          initialValues={{
            progress,
            status,
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {(formik) => {
            const hasErrors =
              (formik.touched.progress && Boolean(formik.errors.progress)) ||
              (formik.touched.status && Boolean(formik.errors.status));
            setHasErrors(hasErrors || false);
            return (
              <form autoComplete="off" onSubmit={formik.handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item sm={6} xs={12}>
                    <SelectCustom
                      defaultValue=""
                      error={
                        formik.touched.status && Boolean(formik.errors.status)
                      }
                      errorMessage={formik.errors.status}
                      fullWidth
                      id="jobProgressForm-statusSelect"
                      label="Status"
                      labelId="jobProgressForm-statusSelect"
                      {...formik.getFieldProps("status")}
                    >
                      <MenuItem value="">Status</MenuItem>
                      {jobProgressSelectValues.map(
                        (statusOption: DropdownOption) => (
                          <MenuItem
                            key={`statusSelectOption-${statusOption.value}`}
                            value={statusOption.value}
                          >
                            {statusOption.label}
                          </MenuItem>
                        ),
                      )}
                    </SelectCustom>
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <TextFieldCustom
                      error={
                        formik.touched.progress &&
                        Boolean(formik.errors.progress)
                      }
                      errorMessage={formik.errors.progress}
                      fullWidth
                      id="jobProgressForm-progressField"
                      label="Progress %"
                      placeholder="Progress %"
                      {...formik.getFieldProps("progress")}
                    />
                  </Grid>
                </Grid>

                <Button
                  id="submitJobProgressForm-btn"
                  style={{ display: "none" }}
                  type="submit"
                />
              </form>
            );
          }}
        </Formik>
      </Box>
    </GlobalModal>
  );
}
