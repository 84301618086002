import {
  Box,
  createStyles,
  Divider,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import React, { Fragment } from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    text: {
      color: theme.palette.primary.dark,
      margin: "8px 28px",
    },
  }),
);

interface SummaryJobsOverDataTooltipProps {
  overcostJobs: number;
  overtimeJobs: number;
}

export function SummaryJobsOverDataTooltip({
  overcostJobs,
  overtimeJobs,
}: SummaryJobsOverDataTooltipProps) {
  const classes = useStyles();

  const displayingOvercost = overcostJobs > 0;
  const displayingOvertime = overtimeJobs > 0;

  if (!displayingOvercost && !displayingOvertime) {
    return <Fragment />;
  }

  return (
    <Box>
      {displayingOvertime && (
        <Typography
          className={classes.text}
          variant="body2"
        >{`${overtimeJobs} Overtime Jobs`}</Typography>
      )}
      {displayingOvercost && displayingOvertime && <Divider />}
      {displayingOvercost && (
        <Typography
          className={classes.text}
          variant="body2"
        >{`${overcostJobs} Overcost Jobs`}</Typography>
      )}
    </Box>
  );
}
