import React from "react";
import { Box, Typography, IconButton, useTheme } from "@material-ui/core";
import { mainTableStyles } from "utils/tableUtils";
import { SortArrowUp, SortArrowDown, SortArrows } from "components/icons";
import { crewHeadersStyles } from "utils/timesheetsManagementUtils";
import { cloneDeep } from "lodash";

interface SortHeadersProps {
  sortingOptions?: string[][];
  onClickSort: (sortingValues: string[][]) => void;
}

export function CrewSortHeaders({
  sortingOptions,
  onClickSort,
}: SortHeadersProps) {
  const theme = useTheme();
  const tableStyles = mainTableStyles();
  const classes = crewHeadersStyles();

  const handleClickSort = (field: string) => () => {
    if (!sortingOptions || sortingOptions.length === 0) {
      onClickSort([[field, "ASC"]]);
      return;
    }

    const sortingIndex = sortingOptions.findIndex(
      ([currentField]) => currentField === field,
    );

    const sortingOptionsUpdated = cloneDeep(sortingOptions);
    if (sortingIndex >= 0 && sortingOptions[sortingIndex][1] === "ASC") {
      sortingOptionsUpdated[sortingIndex][1] = "DESC";
      onClickSort(sortingOptionsUpdated);
      return;
    }

    sortingOptionsUpdated.splice(sortingIndex, 1);
    onClickSort(sortingOptionsUpdated);
  };

  const getSortIcon = (field: string) => {
    if (sortingOptions) {
      const sortValue = sortingOptions.find(
        ([currentField]) => currentField === field,
      );
      if (sortValue && sortValue[1] === "DESC") {
        return (
          <SortArrowDown color={theme.palette.primary.main} fontSize={18} />
        );
      }

      if (sortValue && sortValue[1] === "ASC") {
        return <SortArrowUp color={theme.palette.primary.main} fontSize={18} />;
      }
    }
    return <SortArrows fontSize={18} />;
  };

  return (
    <Box display="flex" alignItems="center">
      <Box
        display="flex"
        alignItems="center"
        className={classes.standardHeader}
      >
        <Typography variant="subtitle2" className={tableStyles.title}>
          date
        </Typography>
        <IconButton
          className={tableStyles.iconButton}
          onClick={handleClickSort("date")}
        >
          {getSortIcon("date")}
        </IconButton>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        className={classes.standardHeader}
      >
        <Typography variant="subtitle2" className={tableStyles.title}>
          foreman
        </Typography>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        className={classes.standardHeader}
      >
        <Typography variant="subtitle2" className={tableStyles.title}>
          supervisor
        </Typography>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        className={classes.standardHeader}
      >
        <Typography variant="subtitle2" className={tableStyles.title}>
          Region
        </Typography>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        className={classes.standardHeader}
      >
        <Typography variant="subtitle2" className={tableStyles.title}>
          status
        </Typography>
      </Box>
    </Box>
  );
}
