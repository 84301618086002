import {
  Box,
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Hidden,
  IconButton,
  makeStyles,
  TextField,
  Theme,
} from "@material-ui/core/";
import { Cancel } from "@material-ui/icons";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  hide,
  PromptTypes,
  PromptValueType,
  selectMessage,
  selectShow,
  selectTitle,
  selectType,
  selectValue,
  setResponse,
  setValue,
} from "features/prompt/promptSlice";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttonAction: {
      backgroundColor: theme.palette.primary.dark,
      borderRadius: "2px",
      boxShadow: "none",
      maxWidth: "174px",
      width: "100%",
    },
    buttonCancel: {
      border: `1px solid ${theme.palette.grey[500]}`,
      borderRadius: "2px",
      color: theme.palette.primary.dark,
      maxWidth: "174px",
      width: "100%",
    },
    buttonsContainer: {
      padding: "0",
    },
    buttonsSpacing: {
      "& button:last-child": {
        marginLeft: "24px",
      },
    },
    closeIcon: {
      color: theme.palette.primary.main,
      padding: "0",
    },
    content: {
      padding: "8px 24px 24px",
    },
    message: {
      marginBottom: "16px",
    },
    paper: {
      borderRadius: "2px",
    },
    title: {
      marginRight: "24px",
      maxWidth: "379px",
      padding: "0",
      "& .MuiTypography-h6": {
        color: theme.palette.primary.main,
        fontSize: "2rem",
        fontWeight: "bold",
      },
    },
  }),
);

export function Prompt() {
  const [inputValue, setInputValue] = useState<PromptValueType>();

  const message = useSelector(selectMessage);
  const show = useSelector(selectShow);
  const title = useSelector(selectTitle);
  const type = useSelector(selectType);
  const value = useSelector(selectValue);

  const dispatch = useDispatch();
  const classes = useStyles();

  const handleChangeTextInput = ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setInputValue(value);
  };

  const handleClickCancel = () => {
    setInputValue(undefined);
    dispatch(setResponse(false));
    dispatch(hide());
  };

  const handleClickConfirm = () => {
    if (type === PromptTypes.TEXT_AREA || type === PromptTypes.TEXT_INPUT) {
      dispatch(setValue(inputValue));
    }

    if (type === PromptTypes.NUMBER_INPUT) {
      dispatch(setValue(Number(inputValue)));
    }

    dispatch(setResponse(true));
    dispatch(hide());
  };

  return (
    <Dialog
      classes={{
        paper: classes.paper,
      }}
      fullWidth
      maxWidth="sm"
      open={show}
      onClose={handleClickCancel}
    >
      <Box
        alignItems="center"
        display="flex"
        flexGrow={1}
        justifyContent="space-between"
        padding="24px 24px 0 24px"
      >
        <DialogTitle
          classes={{
            root: classes.title,
          }}
        >
          {title}
        </DialogTitle>
        <Hidden xsDown>
          <IconButton
            aria-label="Close"
            className={classes.closeIcon}
            onClick={handleClickCancel}
          >
            <Cancel fontSize="large" />
          </IconButton>
        </Hidden>
      </Box>

      <DialogContent
        classes={{
          root: classes.content,
        }}
      >
        <DialogContentText
          classes={{
            root: classes.message,
          }}
        >
          {message}
        </DialogContentText>

        <Box>
          {type === PromptTypes.CUSTOM && (value as JSX.Element)}
          {type === PromptTypes.NUMBER_INPUT && (
            <TextField
              autoFocus
              defaultValue={0}
              fullWidth
              id="prompt-numberInput"
              name="promptInput"
              type="number"
              value={Number(inputValue)}
              onChange={handleChangeTextInput}
            />
          )}
          {type === PromptTypes.TEXT_AREA && (
            <TextField
              autoFocus
              defaultValue=""
              fullWidth
              id="prompt-textArea"
              multiline
              name="promptInput"
              rows={4}
              value={inputValue}
              variant="outlined"
              onChange={handleChangeTextInput}
            />
          )}
          {type === PromptTypes.TEXT_INPUT && (
            <TextField
              autoFocus
              defaultValue=""
              fullWidth
              id="prompt-textInput"
              name="promptInput"
              type="text"
              value={inputValue}
              onChange={handleChangeTextInput}
            />
          )}
        </Box>
        <DialogActions
          classes={{
            root: classes.buttonsContainer,
            spacing: classes.buttonsSpacing,
          }}
        >
          <Button
            classes={{
              root: classes.buttonCancel,
            }}
            color="primary"
            variant="outlined"
            onClick={handleClickCancel}
          >
            Cancel
          </Button>
          <Button
            classes={{
              root: classes.buttonAction,
            }}
            color="primary"
            variant="contained"
            onClick={handleClickConfirm}
          >
            Yes
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}
