import { createStyles, makeStyles, Theme } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination/Pagination";
import React from "react";

interface PaginationProps {
  defaultPage?: number;
  id?: string;
  isDisabled?: boolean;
  page?: number;
  totalPages?: number;
  onChangePage: (newPage: number) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    ul: {
      "& .MuiPaginationItem-root": {
        color: theme.palette.primary.dark,
        fontWeight: "bold",
      },
    },
  }),
);

export function CustomPagination({
  defaultPage = 1,
  id = "js-pagination",
  isDisabled = false,
  page = 1,
  totalPages = 1,
  onChangePage,
}: PaginationProps) {
  const classes = useStyles();

  const handleChangePage = (
    event: React.ChangeEvent<unknown>,
    page: number,
  ) => {
    onChangePage(page);
  };

  return (
    <Pagination
      classes={{
        ul: classes.ul,
      }}
      count={totalPages}
      defaultPage={defaultPage}
      disabled={isDisabled}
      id={id}
      page={page}
      shape="rounded"
      variant="outlined"
      onChange={handleChangePage}
    />
  );
}
