import { JobModel } from "@dwo/shared/dist/models/jobModel";
import { DEFAULT_CURRENCY_EMPTY } from "utils/jobUtils";

export const getExpensesCanvasSummary = (
  main: string,
  dark: string,
  data: { bid: number[]; expenses: number[] },
) => ({
  data: {
    labels: ["Labor", "Material", "Other"],
    datasets: [
      {
        data: data.bid,
        backgroundColor: main,
      },
      {
        data: data.expenses,
        backgroundColor: dark,
      },
    ],
  },
  options: {
    legend: {
      display: false,
    },
    scales: {
      xAxes: [
        {
          barPercentage: 1.0,
          gridLines: {
            display: true,
          },
          ticks: {
            beginAtZero: true,
            fontFamily: "Noto Sans",
            fontSize: 14,
            fontColor: "#646466",
          },
        },
      ],
      yAxes: [
        {
          gridLines: {
            display: false,
          },
          ticks: {
            beginAtZero: true,
            fontFamily: "Noto Sans",
            fontSize: 12,
            fontColor: "#646466",
            userCallback: (value: number) => value.toLocaleString("en"),
            stepSize: 0.25,
            maxTicksLimit: 5,
          },
        },
      ],
    },
  },
});

const getPrice = (price: string | undefined) => price || DEFAULT_CURRENCY_EMPTY;

const getTotalCost = (job?: JobModel, isBidCost?: boolean) => {
  if (
    isBidCost &&
    job?.bidLaborCost &&
    job?.bidMaterialsCost &&
    job?.bidOtherCost
  ) {
    return String(
      Number(job.bidLaborCost) +
        Number(job.bidMaterialsCost) +
        Number(job.bidOtherCost),
    );
  }

  if (!isBidCost && job) {
    return String(
      Number(job.laborCost ? job.laborCost : 0) +
        Number(job.materialsCost ? job.materialsCost : 0) +
        Number(job.otherCost ? job.otherCost : 0),
    );
  }

  return "0.00";
};

export const getExpenseItems = (job?: JobModel) => [
  {
    type: "Cost",
    bid: getPrice(job?.bidPrice),
    total: getTotalCost(job),
  },
  {
    type: "Labor",
    bid: getPrice(job?.bidLaborCost),
    total: getPrice(job?.laborCost),
  },
  {
    type: "Material",
    bid: getPrice(job?.bidMaterialsCost),
    total: getPrice(job?.materialsCost),
  },
  {
    type: "Other",
    bid: getPrice(job?.bidOtherCost),
    total: getPrice(job?.otherCost),
  },
];

const getProfitPercentage = (value: number, job?: JobModel): string => {
  if (job?.bidPrice && Number(job.bidPrice) > 0) {
    return String(Math.round(100 - (value / Number(job.bidPrice)) * 100));
  }
  return "00";
};

export const getProfit = (isBid: boolean, job?: JobModel) => {
  if (job) {
    const totalCost = Number(getTotalCost(job, isBid));
    const totalPercentage = Number(getProfitPercentage(totalCost, job));
    const profit = Number(job.bidPrice) - totalCost;

    return !isNaN(profit) && !isNaN(totalPercentage)
      ? profit <= 0
        ? `$${profit.toLocaleString("en")}`
        : `$${profit.toLocaleString("en")} (${totalPercentage}%)`
      : "N/A";
  }

  return "N/A";
};

export const getOvercost = (job?: JobModel) => {
  if (job) {
    return job.overtimeCost ? job.overtimeCost : "0.00";
  }
  return "0.00";
};
