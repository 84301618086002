import { createStyles, Link, makeStyles, Theme } from "@material-ui/core";
import React from "react";
import { Link as RouterLink } from "react-router-dom";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    link: {
      color: theme.palette.primary.light,
      textDecoration: "underline",
    },
  }),
);

interface TextLinkProps {
  text: string;
  url: string;
  onClickLink?: () => void;
}

export function TextLink({ text, url, onClickLink }: TextLinkProps) {
  const classes = useStyles();

  return (
    <Link
      className={classes.link}
      component={RouterLink}
      to={url}
      onClick={onClickLink}
    >
      {text}
    </Link>
  );
}
