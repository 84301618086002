import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearToast, selectToast } from "./toastSlice";

// TODO: update the design of the component
export function Toast() {
  const toast = useSelector(selectToast);
  const dispatch = useDispatch();

  useEffect(() => {
    if (toast.isActive) {
      const timeOutToDismiss = setTimeout(() => {
        dispatch(clearToast());
      }, 5000);

      return () => {
        clearTimeout(timeOutToDismiss);
      };
    }
  }, [dispatch, toast.isActive]);

  const handleDismiss = () => {
    dispatch(clearToast());
  };

  return (
    <React.Fragment>
      {toast.isActive && (
        <div>
          {toast?.title && <h3>{toast.title}</h3>}
          {toast?.status ?? null} {toast.message}
          <button onClick={handleDismiss}>dismiss</button>
        </div>
      )}
    </React.Fragment>
  );
}
