import React from "react";
import { Switch, RouteComponentProps } from "react-router-dom";
import { UserDetails } from "screens/UserDetailsScreen";
import { UserManagement } from "screens/UserManagementScreen";
import { ProtectedRoute } from "./ProtectedRoute";

export function UsersRouter({ match: { url } }: RouteComponentProps) {
  return (
    <Switch>
      <ProtectedRoute exact path={`${url}/`}>
        <UserManagement />
      </ProtectedRoute>
      <ProtectedRoute exact path={`${url}/:id`}>
        <UserDetails />
      </ProtectedRoute>
    </Switch>
  );
}
