import React, { useState } from "react";
import { FileData } from "utils/sharedUtils";
import { TextField, Box } from "@material-ui/core";
import { UploadButton } from "./UploadButton";

interface UploadInputProps {
  accept: string;
  id: string;
  isDisabled?: boolean;
  maxSize?: number;
  multiple?: boolean;
  label?: string;
  name: string;
  text?: string;
  onUploadFiles: (newFile: FileData) => void;
}

export function UploadInput({
  accept,
  id,
  isDisabled = false,
  maxSize,
  multiple,
  label = "File should be only CSV",
  name,
  text,
  onUploadFiles,
}: UploadInputProps) {
  const [fileName, setFileName] = useState("");

  const handleUploadFile = (fileData: FileData) => {
    if (fileData.file) {
      setFileName(fileData.fileName);
    }
    onUploadFiles(fileData);
  };

  return (
    <Box display="flex" alignItems="flex-end">
      <TextField
        value={fileName}
        label={label}
        disabled={isDisabled}
        placeholder="No file has been selected yet"
        InputProps={{
          readOnly: true,
        }}
        InputLabelProps={{
          shrink: true,
        }}
      />
      <Box width="150px">
        <UploadButton
          accept={accept}
          id={id}
          isDisabled={isDisabled}
          maxSize={maxSize}
          multiple={multiple}
          name={name}
          text={text}
          onUploadFiles={handleUploadFile}
        />
      </Box>
    </Box>
  );
}
