import {
  Box,
  createStyles,
  IconButton,
  makeStyles,
  Theme,
} from "@material-ui/core";
import { GetAppRounded } from "@material-ui/icons";
import React from "react";

interface HazardDownloadProps {
  url: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      color: theme.palette.primary.main,
    },
    iconButton: {
      padding: "8px",
    },
  }),
);

export function HazardDownloadIcon({ url }: HazardDownloadProps) {
  const classes = useStyles();

  return (
    <Box width="100%" justifyContent="center" display="flex" margin="0 -8px">
      <a href={url} download="JobHazardAnalysis.pdf">
        <IconButton
          aria-label="Preview Hazard Form"
          className={classes.iconButton}
        >
          <GetAppRounded className={classes.icon} />
        </IconButton>
      </a>
    </Box>
  );
}
