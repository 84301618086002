import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, RootState } from "app/store";
import { error } from "features/error/errorSlice";
import {
  EmployeeModel,
  NewEmployeeModel,
} from "@dwo/shared/dist/models/employeeModel";
import { EmployeePictureModel } from "@dwo/shared/dist/models/employeePictureModel";
import { JobModel } from "@dwo/shared/dist/models/jobModel";
import { RegionModel } from "@dwo/shared/dist/models/regionModel";
import { UserModel } from "@dwo/shared/dist/models/userModel";
import { ServiceOptions } from "@dwo/shared/dist/services/baseService";
import { employeePictureService } from "@dwo/shared/dist/services/employeePictureService";
import { employeeService } from "@dwo/shared/dist/services/employeeService";
import { jobService } from "@dwo/shared/dist/services/JobService";
import {
  show as showLoader,
  hide as hideLoader,
} from "features/loader/loaderSlice";
import { crewService } from "@dwo/shared/dist/services/crewService";
import { employeeCrewService } from "@dwo/shared/dist/services/employeeCrewService";
import { DEFAULT_LIMIT, ReqStatus } from "utils/sharedUtils";
import { EmployeeCrewModel } from "@dwo/shared/dist/models/employeeCrewModel";
import { CrewModel } from "@dwo/shared/dist/models/crewModel";
import { JobStatusValues } from "utils/jobUtils";
import {
  initialUserFilters,
  initialUserQuery,
  UserFilters,
} from "utils/userManagementUtils";

interface EmployeeState {
  assignedJobs: JobModel[];
  availableJobs: JobModel[];
  count: number;
  currentJobs?: JobModel[];
  employee?: EmployeeModel;
  employeeJobs: JobModel[];
  employeePictures?: EmployeePictureModel[];
  employees: EmployeeModel[];
  error: boolean;
  isLoadingAssigningJob: boolean;
  isLoadingEmployeeJobs: boolean;
  isLoadingUpdate: boolean;
  isEditModalOpen: boolean;
  isRemovingJob: boolean;
  limit?: number;
  loading: boolean;
  employeesReqStatus: ReqStatus;
  employeeJobsReqStatus: ReqStatus;
  message?: string;
  offset?: number;
  order?: any;
  regions?: RegionModel[];
  upcomingJobs?: JobModel[];
  userFilters: UserFilters;
  userQuery: ServiceOptions;
}

const initialState: EmployeeState = {
  assignedJobs: [],
  availableJobs: [],
  count: 0,
  currentJobs: [],
  employee: undefined,
  employeeJobs: [],
  employeePictures: [],
  employees: [],
  error: false,
  isLoadingAssigningJob: false,
  isLoadingEmployeeJobs: false,
  isLoadingUpdate: false,
  isEditModalOpen: false,
  isRemovingJob: false,
  limit: DEFAULT_LIMIT,
  loading: false,
  employeesReqStatus: ReqStatus.IDLE,
  employeeJobsReqStatus: ReqStatus.IDLE,
  message: "",
  offset: 0,
  order: undefined,
  regions: [],
  upcomingJobs: [],
  userFilters: initialUserFilters,
  userQuery: initialUserQuery,
};

export const employeesSlice = createSlice({
  name: "employees",
  initialState,
  reducers: {
    setAssignedJobs: (state, action: PayloadAction<JobModel[]>) => {
      state.assignedJobs = action.payload;
    },
    setAvailableJobs: (state, action: PayloadAction<JobModel[]>) => {
      state.availableJobs = action.payload;
    },
    setCount: (state, action: PayloadAction<number>) => {
      state.count = action.payload;
    },
    setCurrentJobs: (state, action: PayloadAction<JobModel[]>) => {
      state.currentJobs = action.payload;
    },
    setEmployee: (state, action: PayloadAction<EmployeeModel | undefined>) => {
      state.employee = action.payload;
    },
    setEmployeePictures: (
      state,
      action: PayloadAction<EmployeePictureModel[]>,
    ) => {
      state.employeePictures = action.payload;
    },
    setEmployees: (state, action: PayloadAction<EmployeeModel[]>) => {
      state.employees = action.payload;
    },
    setEmployeeJobs: (state, action: PayloadAction<JobModel[]>) => {
      state.employeeJobs = action.payload;
    },
    setEmployeeUser: (state, action: PayloadAction<UserModel>) => {
      if (state.employee) {
        state.employee.user = action.payload;
      }
    },
    setEmployeeActiveStatus: (state, action: PayloadAction<boolean>) => {
      if (state.employee) {
        state.employee.isActive = action.payload;
      }
    },
    setError: (state, action: PayloadAction<boolean>) => {
      state.error = action.payload;
    },
    setIsLoadingAssigningJob: (state, action: PayloadAction<boolean>) => {
      state.isLoadingAssigningJob = action.payload;
    },
    setIsLoadingEmployeeJobs: (state, action: PayloadAction<boolean>) => {
      state.isLoadingEmployeeJobs = action.payload;
    },
    setIsRemovingJob: (state, action: PayloadAction<boolean>) => {
      state.isRemovingJob = action.payload;
    },
    setLimit: (state, action: PayloadAction<number>) => {
      state.limit = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setIsLoadingUpdate: (state, action: PayloadAction<boolean>) => {
      state.isLoadingUpdate = action.payload;
    },
    setIsEditModalOpen: (state, action: PayloadAction<boolean>) => {
      state.isEditModalOpen = action.payload;
    },
    setEmployeesReqStatus: (state, action: PayloadAction<ReqStatus>) => {
      state.employeesReqStatus = action.payload;
    },
    setEmployeesJobsReqStatus: (state, action: PayloadAction<ReqStatus>) => {
      state.employeeJobsReqStatus = action.payload;
    },
    setMessage: (state, action: PayloadAction<string>) => {
      state.message = action.payload;
    },
    setOffset: (state, action: PayloadAction<number>) => {
      state.offset = action.payload;
    },
    setOrder: (state, action: PayloadAction<any>) => {
      state.order = action.payload;
    },
    setRegions: (state, action: PayloadAction<RegionModel[]>) => {
      state.regions = action.payload;
    },
    setUpcomingJobs: (state, action: PayloadAction<JobModel[]>) => {
      state.upcomingJobs = action.payload;
    },
    setUserFilters: (state, action: PayloadAction<UserFilters>) => {
      state.userFilters = action.payload;
    },
    setUserQuery: (state, action: PayloadAction<ServiceOptions>) => {
      state.userQuery = action.payload;
    },
  },
});

export const {
  setAssignedJobs,
  setAvailableJobs,
  setCount,
  setEmployee,
  setEmployeeActiveStatus,
  setEmployeeJobs,
  setEmployees,
  setEmployeeUser,
  setError,
  setCurrentJobs,
  setUpcomingJobs,
  setIsLoadingAssigningJob,
  setIsLoadingEmployeeJobs,
  setIsLoadingUpdate,
  setIsEditModalOpen,
  setIsRemovingJob,
  setLimit,
  setLoading,
  setEmployeesReqStatus,
  setEmployeesJobsReqStatus,
  setMessage,
  setOffset,
  setOrder,
  setRegions,
  setEmployeePictures,
  setUserFilters,
  setUserQuery,
} = employeesSlice.actions;

export const setUserActiveStatus = (
  id: number | string,
  activeStatus: boolean,
): AppThunk => async (dispatch) => {
  try {
    dispatch(showLoader());
    const { data } = await employeeService.setActive(id, activeStatus);
    dispatch(setEmployeeActiveStatus(data.isActive));
  } catch (e) {
    dispatch(
      error(
        {
          title: "Something went wrong",
          message: e.message,
        },
        () => dispatch(setUserActiveStatus(id, activeStatus)),
      ),
    );
  } finally {
    dispatch(hideLoader());
  }
};

export const assignJobToEmployee = (
  jobId: number | string,
  foreman: EmployeeModel,
  regionId?: number | string,
): AppThunk => async (dispatch) => {
  try {
    dispatch(setIsLoadingAssigningJob(true));

    const { data: crewsByJobId } = await crewService.getAll({
      where: { jobId },
    });

    const isPrimaryCrew = crewsByJobId.length === 0;

    const { data: defaultCrews } = await crewService.getAll({
      include: ["EmployeeCrew"],
      where: { foremanId: foreman.id, isDefault: true },
    });

    if (defaultCrews.length === 0) {
      throw new Error(`NO_DEFAULT_CREW`);
    }

    const parsedJobId = typeof jobId === "string" ? parseInt(jobId) : jobId;
    const parsedForemanId =
      typeof foreman.id === "string" ? parseInt(foreman.id, 10) : foreman.id;

    const { data: newCrew } = await crewService.create({
      isDefault: false,
      isPrimary: isPrimaryCrew,
      foremanId: parsedForemanId,
      jobId: parsedJobId,
    });

    if (
      defaultCrews[0].employeeCrews &&
      defaultCrews[0].employeeCrews.length > 0
    ) {
      await Promise.all(
        defaultCrews[0].employeeCrews.map(
          async (employeeCrew: EmployeeCrewModel) =>
            await employeeCrewService.create({
              isCurrent: employeeCrew.isCurrent,
              assignedAt: new Date(),
              isDriver: employeeCrew.isDriver,
              isSupervisor: employeeCrew.isSupervisor,
              isForeman: employeeCrew.isForeman,
              employeeId: employeeCrew.employeeId,
              crewId: newCrew.id as number,
            }),
        ),
      );
    }
  } catch (err) {
    const isDefaultCrewError = err.message === "NO_DEFAULT_CREW";
    dispatch(
      error(
        {
          actionButtonText: "Ok",
          hideCancelButton: isDefaultCrewError,
          message: isDefaultCrewError
            ? "Foreman doesn't have a default crew. Please create one before assigning jobs."
            : err.message,
          title: "Could not assign job to employee",
        },
        () =>
          !isDefaultCrewError &&
          dispatch(assignJobToEmployee(jobId, foreman, regionId)),
      ),
    );
  } finally {
    dispatch(getEmployeeJobs(foreman.id as number, regionId));
    dispatch(setIsLoadingAssigningJob(false));
  }
};

export const getAllEmployeePictures = (
  options?: ServiceOptions,
): AppThunk => async (dispatch) => {
  try {
    const {
      count,
      data,
      limit,
      message,
      offset,
      order,
    } = await employeePictureService.getAll(options);

    dispatch(setError(false));
    dispatch(setCount(count));
    dispatch(setEmployeePictures(data));
    dispatch(setLimit(limit));
    dispatch(setMessage(message || ""));
    dispatch(setOffset(offset));
    dispatch(setOrder(order));
    dispatch(setLoading(false));
  } catch (error) {
    dispatch(setLoading(false));
    dispatch(setError(true));
    dispatch(setMessage(error.message));
  }
};

export const getAllEmployees = (options?: ServiceOptions): AppThunk => async (
  dispatch,
) => {
  try {
    dispatch(setLoading(true));

    const {
      count,
      data,
      limit,
      message,
      offset,
      order,
    } = await employeeService.getAll(options);

    dispatch(setError(false));
    dispatch(setCount(count));
    dispatch(setEmployees(data));
    dispatch(setLimit(limit));
    dispatch(setMessage(message || ""));
    dispatch(setOffset(offset));
    dispatch(setOrder(order));
    dispatch(setLoading(false));
  } catch (err) {
    dispatch(setLoading(false));
    dispatch(setError(true));
    dispatch(setMessage(err.message));
    dispatch(
      error(
        {
          title: "Could not retrieve User information. Please try again.",
          message: err.message,
        },
        () => dispatch(getAllEmployees(options)),
      ),
    );
  }
};

export const getAllEmployeesWithImage = (
  options?: ServiceOptions | any,
): AppThunk => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    dispatch(setEmployeesReqStatus(ReqStatus.PENDING));

    const { count, data, limit, offset, order } = await employeeService.getAll(
      options,
    );

    const idList = data.map(({ id }) => id);

    const { data: imageData } = await employeePictureService.getAll({
      where: { employeeId: { $in: idList } },
      include: ["Picture"],
    });

    const dataWithImage = data.map((employeeToUpdate) => {
      const imageFound = imageData.find(
        (image) => image.employeeId === employeeToUpdate.id,
      );
      if (imageFound) {
        employeeToUpdate.employeePictures = [imageFound];
      }
      return employeeToUpdate;
    });

    dispatch(setCount(count));
    dispatch(setEmployees(dataWithImage));
    dispatch(setLimit(limit));
    dispatch(setOffset(offset));
    dispatch(setOrder(order));
    dispatch(setEmployeesReqStatus(ReqStatus.FULFILLED));
  } catch (e) {
    dispatch(setEmployeesReqStatus(ReqStatus.FILED));
    dispatch(
      error(
        {
          title: "Could not retrieve employees",
          message: e.message,
        },
        () => dispatch(getAllEmployees(options)),
      ),
    );
  } finally {
    dispatch(setLoading(false));
  }
};

export const getEmployeeById = (
  id: number,
  options?: ServiceOptions,
): AppThunk => async (dispatch, getState) => {
  try {
    // Clear old selected employee if not the same (avoid showing incorrect employee while loading)

    //https://api.dwodev.tsu1.com/api/v1/employee/1378?include=["regions","user",{"employeeSupervisors":["supervisor"]}]

    // if (options?.include?.length) {
    //   options.include.push({ employeeSupervisors: ["supervisor"] });
    // }

    const currentEmployee = selectEmployee(getState());
    if (currentEmployee && id !== currentEmployee.id) {
      dispatch(setEmployee(undefined));
    }
    dispatch(setLoading(true));

    const { data: employeeData, message } = await employeeService.getById(
      id,
      options,
    );

    if (employeeData && employeeData.regions) {
      dispatch(setRegions(employeeData.regions));
    }

    dispatch(setEmployee(employeeData));
    dispatch(setError(false));
    dispatch(setMessage(message || ""));
    dispatch(setLoading(false));
  } catch (err) {
    dispatch(setLoading(false));
    dispatch(setError(true));
    dispatch(setMessage(err.message));
    dispatch(
      error(
        {
          title: "Could not retrieve User information. Please try again.",
          message: err.message,
        },
        () => dispatch(getEmployeeById(id, options)),
      ),
    );
  }
};

export const getAllEmployeeJobs = (
  employeeId: number,
  options?: ServiceOptions,
): AppThunk => async (dispatch) => {
  try {
    dispatch(setEmployeesJobsReqStatus(ReqStatus.PENDING));
    const { data } = await jobService.getEmployeeJobs(employeeId, options);
    dispatch(setEmployeeJobs(data));
  } catch (e) {
    dispatch(
      error(
        {
          title: "Could not retrieve employee jobs",
          message: e.message,
        },
        () => dispatch(getAllEmployeeJobs(employeeId, options)),
      ),
    );
  } finally {
    dispatch(setEmployeesJobsReqStatus(ReqStatus.IDLE));
  }
};

export const getEmployeeJobs = (
  employeeId: number | string,
  regionId?: number | string,
): AppThunk => async (dispatch) => {
  try {
    dispatch(setIsLoadingEmployeeJobs(true));

    const parsedEmployeeId =
      typeof employeeId === "string" ? parseInt(employeeId, 10) : employeeId;

    const { data } = await jobService.getEmployeeJobs(parsedEmployeeId, {
      where: {
        $or: [
          { status: JobStatusValues.IN_PROGRESS },
          { status: JobStatusValues.ASSIGNED },
        ],
      },
    });

    const currentJobs: JobModel[] = [];
    const upcomingJobs: JobModel[] = [];

    data.forEach((job: JobModel) => {
      if (job.status === JobStatusValues.IN_PROGRESS) {
        currentJobs.push(job);
        return;
      }

      if (job.status === JobStatusValues.ASSIGNED) {
        upcomingJobs.push(job);
        return;
      }
    });

    dispatch(setAssignedJobs(data));
    dispatch(setCurrentJobs(currentJobs));
    dispatch(setUpcomingJobs(upcomingJobs));
    dispatch(updateEmployeeAvailableJobs(data, regionId));
  } catch (e) {
    dispatch(
      error(
        {
          title: "Could not retrieve employee jobs",
          message: e.message,
        },
        () => dispatch(getEmployeeJobs(employeeId, regionId)),
      ),
    );
  } finally {
    dispatch(setIsLoadingEmployeeJobs(false));
  }
};

export const removeAssignedJobFromEmployee = (
  jobId: number,
  foremanId: number | string,
  regionId?: number | string,
): AppThunk => async (dispatch) => {
  try {
    dispatch(setIsRemovingJob(true));

    const { data: assignedCrews } = await crewService.getAll({
      where: { foremanId, jobId },
    });

    await Promise.all(
      assignedCrews.map(
        async (crew: CrewModel) => await crewService.delete(crew.id as number),
      ),
    );
  } catch (err) {
    dispatch(
      error(
        {
          title: "Could not remove Job from Employee",
          message: err.message,
        },
        () =>
          dispatch(removeAssignedJobFromEmployee(jobId, foremanId, regionId)),
      ),
    );
  } finally {
    dispatch(getEmployeeJobs(foremanId, regionId));
    dispatch(setIsRemovingJob(false));
  }
};

export const updateEmployeeAvailableJobs = (
  assignedJobs: JobModel[],
  regionId?: number | string,
): AppThunk => async (dispatch) => {
  try {
    const { data: jobs } = await jobService.getAll({
      include: ["region"],
      where: {
        endDate: { $gt: new Date() },
        regionId,
        status: "unassigned",
      },
    });

    const availableJobs =
      assignedJobs.length > 0
        ? jobs.filter((currentJob: JobModel) => {
            const isJobAssigned = assignedJobs.some(
              (currentAssignedJob: JobModel) =>
                currentAssignedJob.id === currentJob.id,
            );

            return !isJobAssigned;
          })
        : jobs;

    dispatch(setAvailableJobs(availableJobs));
  } catch (e) {
    dispatch(
      error(
        {
          title: "Could not retrieve available jobs",
          message: e.message,
        },
        () => dispatch(updateEmployeeAvailableJobs(assignedJobs)),
      ),
    );
  }
};

function decodeHTML(html: any) {
  var txt = document.createElement("textarea");
  txt.innerHTML = html;
  return txt.value;
}

export const createEmployee = (
  body: NewEmployeeModel,
  options?: ServiceOptions,
): AppThunk => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    dispatch(setIsEditModalOpen(true));

    await employeeService.createUser(body);
    dispatch(setIsEditModalOpen(false));
  } catch (e) {
    dispatch(
      error(
        {
          title: "Could not create the user. Please try again.",
          //* Format generated by JOI Validator in the Backend; that is also why the decodeHTML fucntion is used */
          message: decodeHTML(e.response.data.data[0].error),
        },
        () => dispatch(createEmployee(body, options)),
      ),
    );
  } finally {
    dispatch(setLoading(false));
  }
};

export const updateEmployee = (
  body: NewEmployeeModel,
  options?: ServiceOptions,
): AppThunk => async (dispatch) => {
  try {
    dispatch(setIsLoadingUpdate(true));
    dispatch(setIsEditModalOpen(true));

    await employeeService.patch(body.id as number, body);
    dispatch(getEmployeeById(body.id as number, options));
    dispatch(setIsEditModalOpen(false));
  } catch (e) {
    dispatch(
      error(
        {
          title: "Could not update the user. Please try again.",
          //* Format generated by JOI Validator in the Backend; that is also why the decodeHTML fucntion is used */
          message: decodeHTML(e.response.data.data[0].error),
        },
        () => dispatch(updateEmployee(body, options)),
      ),
    );
  } finally {
    dispatch(setIsLoadingUpdate(false));
  }
};

export const uploadUserImages = (
  id: number,
  pictures: FormData,
  options?: ServiceOptions,
): AppThunk => async (dispatch) => {
  try {
    dispatch(showLoader());

    const { data, message } = await employeePictureService.postEmployeePictures(
      id,
      pictures,
    );

    dispatch(setEmployeePictures(data));
    dispatch(getAllEmployeePictures(options));
    dispatch(setError(false));

    if (message) {
      dispatch(setMessage(message));
    }
  } catch (e) {
    dispatch(
      error(
        {
          title: "Something went wrong",
          message: e.message,
        },
        () => dispatch(uploadUserImages(id, pictures)),
      ),
    );
  } finally {
    dispatch(hideLoader());
  }
};

export const deleteUserImage = (
  id: number,
  getAllEmployeePicturesQuery?: ServiceOptions,
): AppThunk => async (dispatch) => {
  try {
    dispatch(showLoader());
    await employeePictureService.delete(id);
    dispatch(getAllEmployeePictures(getAllEmployeePicturesQuery));
  } catch (e) {
    dispatch(
      error(
        {
          title: "Something went wrong",
          message: e.message,
        },
        () => dispatch(deleteUserImage(id, getAllEmployeePicturesQuery)),
      ),
    );
  } finally {
    dispatch(hideLoader());
  }
};

export const selectAssignedjobs = (state: RootState) =>
  state.employees.assignedJobs;
export const selectAvailableJobs = (state: RootState) =>
  state.employees.availableJobs;
export const selectCount = (state: RootState) => state.employees.count;
export const selectCurrentJobs = (state: RootState) =>
  state.employees.currentJobs;
export const selectEmployee = (state: RootState) => state.employees.employee;
export const selectEmployeeJobs = (state: RootState) =>
  state.employees.employeeJobs;
export const selectEmployeePictures = (state: RootState) =>
  state.employees.employeePictures;
export const selectEmployees = (state: RootState) => state.employees.employees;
export const selectError = (state: RootState) => state.employees.error;
export const selectIsActiveEmployee = (state: RootState) => {
  const { employee } = state.employees;
  return employee ? employee.isActive : false;
};
export const selectIsLoadingAssigningJob = (state: RootState) =>
  state.employees.isLoadingAssigningJob;
export const selectIsLoadingEmployeeJobs = (state: RootState) =>
  state.employees.isLoadingEmployeeJobs;
export const selectIsLoadingUpdate = (state: RootState) =>
  state.employees.isLoadingUpdate;
export const selectIsEditModalOpen = (state: RootState) =>
  state.employees.isEditModalOpen;
export const selectIsRemovingJob = (state: RootState) =>
  state.employees.isRemovingJob;
export const selectLimit = (state: RootState) => state.employees.limit;
export const selectLoading = (state: RootState) => state.employees.loading;
export const selectEmployeesReqStatus = (state: RootState) =>
  state.employees.employeesReqStatus;
export const selectEmployeeJobsReqStatus = (state: RootState) =>
  state.employees.employeeJobsReqStatus;
export const selectMessage = (state: RootState) => state.employees.message;
export const selectOffset = (state: RootState) => state.employees.offset;
export const selectOrder = (state: RootState) => state.employees.order;
export const selectRegions = (state: RootState) => state.employees.regions;
export const selectUpcomingJobs = (state: RootState) =>
  state.employees.upcomingJobs;
export const selectUserFilters = (state: RootState) =>
  state.employees.userFilters;
export const selectUserQuery = (state: RootState) => state.employees.userQuery;

export default employeesSlice.reducer;
