import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { noop } from "lodash";
import { Box } from "@material-ui/core";
import { GlobalModal } from "components/globalModal/GlobalModal";
import { SearchByNameOrId } from "components/userManagement/SearchByNameOrId";
import { TableCustom } from "components/table/TableCustom";
import { DWOJobModel } from "@dwo/shared/dist/models/DWOJobModel";
import { EmployeeModel } from "@dwo/shared/dist/models/employeeModel";
import { selectCurrentDWOJob } from "features/dwo/dwoSlice";

import {
  getAllEmployeesWithImage,
  selectEmployees,
  selectLoading as selectLoadingEmployees,
} from "features/employees/employeesSlice";
import { selectLoading as selectLoadingRegions } from "features/region/regionSlice";
import { SMALL_QUERY_LIMIT } from "utils/sharedUtils";
import { getSwapRows, swapColumns } from "utils/timesheetDetailsUtils";

interface SwapTempWorkshiftModalProps {
  employeeAssigned?: EmployeeModel;
  isOpen: boolean;
  jobId?: number | string;
  onClickCancel: () => void;
  onClickSave: (employeeSelected: EmployeeModel) => void;
  regionId?: number;
}

export function SwapTempWorkshiftModal({
  employeeAssigned,
  isOpen = false,
  jobId,
  onClickCancel,
  onClickSave,
  regionId,
}: SwapTempWorkshiftModalProps) {
  const dispatch = useDispatch();
  const employees = useSelector(selectEmployees);
  const currentDWOJob: DWOJobModel | undefined = useSelector(
    selectCurrentDWOJob,
  );
  const employeeList = useSelector(selectEmployees).filter(
    (employee) => employee.id !== employeeAssigned?.id,
  );
  const isLoadingEmployees = useSelector(selectLoadingEmployees);
  const isLoadingRegions = useSelector(selectLoadingRegions);
  const isLoading = isLoadingEmployees || isLoadingRegions;
  const [employeeIdSelected, setEmployeeIdSelected] = useState("");
  const [searchedEmployeeId, setSearchedEmployeeId] = useState("");
  const [searchedName, setSearchedName] = useState("");

  /**
   * TODO: jobId is excluded as a temporary patch, once DW-582 is fixed
   * ! https://tsu1.atlassian.net/browse/DW-582
   */
  useEffect(() => {
    if (isOpen && currentDWOJob) {
      dispatch(
        getAllEmployeesWithImage({
          where: {
            role: ["worker"],
            // role: ["foreman", "worker"],
            /**
             * * "foreman" was removed because of a Time Out error when fetching a lot of data with the DEFAULT_QUERY_LIMIT (300)
             * TODO: Pagination or Lazy Loading should be implemented
             */
            regionId,
          },
          //exclude: { jobId },
          limit: SMALL_QUERY_LIMIT,
        }),
      );
    }
  }, [dispatch, isOpen, regionId, jobId]);

  const handleClickClose = () => {
    setEmployeeIdSelected("");
    onClickCancel();
  };

  const handleClickCheckbox = (idRow: string, isChecked: boolean) => {
    if (isChecked) {
      setEmployeeIdSelected(idRow);
    }

    if (!isChecked) {
      setEmployeeIdSelected("");
    }
  };

  const handleClickSave = () => {
    const employeeSelected = employees.find(
      (employee) => employee.id === Number(employeeIdSelected),
    );

    if (employeeIdSelected && employeeSelected) {
      setEmployeeIdSelected("");
      onClickCancel();
      onClickSave(employeeSelected);
    }
  };

  const handleChangeSearch = useCallback(
    (employeeId: string, employeeName: string) => {
      setSearchedEmployeeId(employeeId);
      setSearchedName(employeeName);
    },
    [],
  );

  const filterEmployeesBySearchResult = (data: EmployeeModel[]) => {
    if (searchedEmployeeId && searchedName) {
      return data.filter(
        ({ employeeId, firstName }) =>
          employeeId === searchedEmployeeId && firstName === searchedName,
      );
    }
    return data;
  };

  return (
    <GlobalModal
      modalMaxWidth="650px"
      onClickClose={handleClickClose}
      onClickContinue={handleClickSave}
      open={isOpen}
      title="Swap temporary worker with an Employee"
      Filters={
        <Box width="100%" padding="24px">
          <SearchByNameOrId
            filteredEmployees={employeeList}
            onChange={handleChangeSearch}
          />
        </Box>
      }
    >
      <TableCustom
        columns={swapColumns}
        isLoading={isLoading}
        noRowsMessage="No crew members found"
        rows={getSwapRows(
          filterEmployeesBySearchResult(employeeList),
          employeeIdSelected,
          handleClickCheckbox,
        )}
        onClickSort={noop}
      />
    </GlobalModal>
  );
}
