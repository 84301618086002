import { store } from "app/store";
import { refreshToken } from "features/logIn/sessionSlice";

// Expose shared env
window.appEnv = {
  baseUrl: `${process.env.REACT_APP_API_BASE_URL}/api/v1`,
  store,
  platform: "browser",
  refreshToken,
};
