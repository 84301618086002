import { CrewModel } from "@dwo/shared/dist/models/crewModel";
import { EmployeeCrewModel } from "@dwo/shared/dist/models/employeeCrewModel";
import { ServiceOptions } from "@dwo/shared/dist/services/baseService";
import { crewService } from "@dwo/shared/dist/services/crewService";
import { employeeCrewService } from "@dwo/shared/dist/services/employeeCrewService";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, RootState } from "app/store";
import {
  createEmployeeCrew,
  deleteEmployeeCrew,
  updateEmployeeCrew,
} from "features/employee_crew/employeeCrewSlice";
import { error } from "features/error/errorSlice";
import {
  hide as hideLoader,
  show as showLoader,
} from "features/loader/loaderSlice";
import { prompt, setResponse } from "features/prompt/promptSlice";
import { DEFAULT_LIMIT, ReqStatus } from "utils/sharedUtils";
import { updateJob } from "features/jobs/jobsSlice";

interface crewState {
  count: number;
  crew?: CrewModel;
  crews: CrewModel[];
  crewsReqStatus: ReqStatus;
  error: boolean;
  isLoading: boolean;
  limit?: number;
  message?: string;
  offset?: number;
  order?: any;
}

const initialState: crewState = {
  count: 0,
  crew: undefined,
  crews: [],
  crewsReqStatus: ReqStatus.IDLE,
  error: false,
  isLoading: false,
  limit: DEFAULT_LIMIT,
  message: "",
  offset: 0,
  order: undefined,
};

export const crewSlice = createSlice({
  name: "crew",
  initialState,
  reducers: {
    setCount: (state, action: PayloadAction<number>) => {
      state.count = action.payload;
    },
    setCrew: (state, action: PayloadAction<CrewModel>) => {
      state.crew = action.payload;
    },
    setCrews: (state, action: PayloadAction<CrewModel[]>) => {
      state.crews = action.payload;
    },
    setCrewsReqStatus: (state, action: PayloadAction<ReqStatus>) => {
      state.crewsReqStatus = action.payload;
    },
    setError: (state, action: PayloadAction<boolean>) => {
      state.error = action.payload;
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setLimit: (state, action: PayloadAction<number>) => {
      state.limit = action.payload;
    },
    setMessage: (state, action: PayloadAction<string>) => {
      state.message = action.payload;
    },
    setOffset: (state, action: PayloadAction<number>) => {
      state.offset = action.payload;
    },
    setOrder: (state, action: PayloadAction<any>) => {
      state.order = action.payload;
    },
  },
});

export const {
  setCount,
  setCrew,
  setCrews,
  setCrewsReqStatus,
  setError,
  setIsLoading,
  setLimit,
  setMessage,
  setOffset,
  setOrder,
} = crewSlice.actions;

export const getAllCrews = (options?: ServiceOptions): AppThunk => async (
  dispatch,
) => {
  try {
    dispatch(setCrewsReqStatus(ReqStatus.PENDING));
    const { count, data, limit, offset, order } = await crewService.getAll(
      options,
    );

    dispatch(setCount(count));
    dispatch(setCrews(data));
    dispatch(setLimit(limit));
    dispatch(setOffset(offset));
    dispatch(setOrder(order));
    dispatch(setCrewsReqStatus(ReqStatus.FULFILLED));
  } catch (e) {
    dispatch(
      error(
        {
          title: "Could not get all crews",
          message: e.message,
        },
        () => dispatch(getAllCrews(options)),
      ),
    );
  } finally {
    dispatch(setCrewsReqStatus(ReqStatus.IDLE));
  }
};

export const getCrewById = (
  id: number,
  options?: ServiceOptions,
): AppThunk => async (dispatch) => {
  try {
    dispatch(setIsLoading(true));

    const { data: crewData } = await crewService.getById(id, options);

    dispatch(setCrew(crewData));
  } catch (err) {
    dispatch(
      error(
        {
          title: "Could not get the Crew information. Please try again.",
          message: err.message,
        },
        () => dispatch(getCrewById(id, options)),
      ),
    );
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const getAllEmployeeCrews = (
  options?: ServiceOptions,
): AppThunk => async (dispatch) => {
  try {
    dispatch(setCrewsReqStatus(ReqStatus.PENDING));
    const { data } = await employeeCrewService.getAll(options);

    const employeeCrews: CrewModel[] = [];
    data.forEach((item) => {
      if (item.crew) {
        return employeeCrews.push(item.crew);
      }
      return;
    });

    dispatch(setCrews(employeeCrews));

    dispatch(setCrewsReqStatus(ReqStatus.FULFILLED));
  } catch (e) {
    dispatch(
      error(
        {
          title: "Could not get all crews",
          message: e.message,
        },
        () => dispatch(getAllCrews(options)),
      ),
    );
  } finally {
    dispatch(setCrewsReqStatus(ReqStatus.IDLE));
  }
};

export const updateCrew = (
  id: number | string,
  crew: CrewModel,
  loadCrews?: boolean,
  options?: ServiceOptions,
): AppThunk => async (dispatch) => {
  try {
    dispatch(setCrewsReqStatus(ReqStatus.PENDING));
    const { data } = await crewService.update(id, crew);

    dispatch(setCrew(data));
    dispatch(setCrewsReqStatus(ReqStatus.FULFILLED));
    if (loadCrews) {
      dispatch(getAllCrews(options));
    }
  } catch (e) {
    dispatch(
      error(
        {
          title: "Could not update crew.",
          message: e.message,
        },
        () => dispatch(updateCrew(id, crew, loadCrews, options)),
      ),
    );
  } finally {
    dispatch(setCrewsReqStatus(ReqStatus.IDLE));
  }
};

export const createCrewWithAssignment = (
  isDefault: boolean,
  foremanId?: number,
  jobId?: number,
  isPrimary = true,
  loadCrews?: boolean,
  options?: ServiceOptions,
): AppThunk => async (dispatch, select) => {
  let crewCreated: CrewModel | undefined = undefined;
  try {
    dispatch(showLoader());
    const { data: crewData } = await crewService.create({
      isDefault,
      foremanId,
      isPrimary,
      jobId,
    });
    crewCreated = crewData;
    dispatch(getAllCrews(options));
  } catch (err) {
    dispatch(
      error(
        {
          title: "Failed to create crew",
          message: err.message,
        },
        () =>
          dispatch(
            createCrewWithAssignment(isDefault, foremanId, jobId, isPrimary),
          ),
      ),
    );
  } finally {
    dispatch(hideLoader());
  }

  if (crewCreated && crewCreated.id && foremanId) {
    try {
      dispatch(showLoader());
      const { data: employeeCrewData } = await employeeCrewService.create({
        crewId: crewCreated.id,
        employeeId: foremanId,
        isDriver: false,
        isCurrent: true,
        isForeman: true,
        isSupervisor: false,
        assignedAt: new Date(),
      });
      employeeCrewData.employee = select().employees.employee;
      crewCreated.employeeCrews = [employeeCrewData];
      dispatch(setCrews([crewCreated]));
      if (loadCrews) {
        dispatch(getAllCrews(options));
      }
    } catch (err) {
      dispatch(
        error(
          {
            title: "Failed to add foreman to crew",
            message: err.message,
          },
          () =>
            dispatch(
              createCrewWithAssignment(isDefault, foremanId, jobId, isPrimary),
            ),
        ),
      );
    } finally {
      dispatch(hideLoader());
    }
  }
};

export const deleteCrew = (
  id: number,
  options?: ServiceOptions,
): AppThunk => async (dispatch) => {
  try {
    dispatch(showLoader());
    await crewService.delete(id);
    dispatch(getAllCrews(options));
  } catch (e) {
    dispatch(
      error({ title: "Something went wrong", message: e.message }, () =>
        dispatch(deleteCrew(id, options)),
      ),
    );
  } finally {
    dispatch(hideLoader());
  }
};

export const addDefaultSupervisor = (
  supervisorId: number,
  getAllCrewsQuery: ServiceOptions,
): AppThunk => async (dispatch, getState) => {
  const primaryCrew = getState().crew.crews.find(
    (crewItem) => crewItem.isPrimary,
  );
  const currentSupervisor = primaryCrew?.employeeCrews?.find(
    (employeeItem) => employeeItem.isSupervisor && employeeItem.isCurrent,
  );
  const currentJob = getState().jobs.job;

  if (currentSupervisor) return;

  if (primaryCrew && currentJob) {
    const employeeCrewObj = {
      assignedAt: new Date(),
      crewId: primaryCrew.id as number,
      employeeId: supervisorId,
      isCurrent: true,
      isDriver: false,
      isForeman: false,
      isSupervisor: true,
    };

    const updatedJob = {
      address: currentJob.address,
      category: currentJob.category,
      /* Client requested to comment Contract Type selection, the options were moved to Job Type */
      // contractType: currentJob.contractType,
      endDate: currentJob.endDate,
      id: currentJob.id,
      jobId: currentJob.jobId,
      regionId: currentJob.regionId,
      startDate: currentJob.startDate,
      status: currentJob.status,
      supervisorId: supervisorId,
      type: currentJob.type,
      updatedAt: new Date(),
      workOrderType: currentJob.workOrderType,
    };
    console.log("Dispaching in defaultSupervisor");

    dispatch(createEmployeeCrew(employeeCrewObj, true, getAllCrewsQuery));
    dispatch(updateJob(updatedJob, { include: ["region"] }));
  }
};

export const addDefaultCrewOnForemanSave = (
  crewId: number,
  oldCrew: EmployeeCrewModel[],
  defaultCrew: EmployeeCrewModel[],
  getAllCrewsQuery: ServiceOptions,
  defaultSupervisor?: EmployeeCrewModel,
): AppThunk => async (dispatch, select) => {
  try {
    await dispatch(
      prompt({
        title: "Add default crew?",
        message:
          "This action will replace the previous crew with the foreman's default crew.",
      }),
    );
    const promptResponse = select().prompt.response;

    if (promptResponse) {
      dispatch(setResponse(false));
      dispatch(showLoader());
      // if old crew is empty we just create the employeeCrews
      if (oldCrew.length === 0) {
        await defaultCrew.forEach(({ employeeId, isDriver }) => {
          dispatch(
            createEmployeeCrew(
              {
                assignedAt: new Date(),
                crewId,
                employeeId,
                isCurrent: true,
                isDriver,
                isForeman: false,
                isSupervisor: false,
              },
              true,
              getAllCrewsQuery,
            ),
          );
        });
      }
      // if old crew is not empty we need to validate when to delete, update and create employeeCrews
      if (oldCrew.length > 0) {
        // if an employeeCrew from the old crew is not a part of the default crew we delete it
        const employeeCrewsToDelete = oldCrew.filter(
          ({ employeeId }) =>
            !defaultCrew.find((ec) => ec.employeeId === employeeId),
        );
        // if an employeeCrew from the old crew is also a part of the default crew we update it
        const employeeCrewsToUpdate = oldCrew.filter(({ employeeId }) =>
          defaultCrew.find((ec) => ec.employeeId === employeeId),
        );
        // if an employeeCrew from the default crew is not listed in employeeCrewsToUpdate we create it
        const employeeCrewsToCreate = defaultCrew.filter(
          ({ employeeId }) =>
            !employeeCrewsToUpdate.find((ec) => ec.employeeId === employeeId),
        );
        // 1. delete the extra employeeCrews
        if (employeeCrewsToDelete.length > 0) {
          await employeeCrewsToDelete.forEach(({ id }) =>
            dispatch(deleteEmployeeCrew(id as number, getAllCrewsQuery)),
          );
        }
        // 2. update the existing employeeCrews
        if (employeeCrewsToUpdate.length > 0) {
          await employeeCrewsToUpdate.forEach((ec) =>
            dispatch(
              updateEmployeeCrew(ec.id as number, {
                ...ec,
                assignedAt: new Date(),
                updatedAt: new Date(),
              }),
            ),
          );
        }
        // 3. create the new employeeCrews
        if (employeeCrewsToCreate.length > 0) {
          await employeeCrewsToCreate.forEach(
            ({ employeeId, isCurrent, isDriver, isForeman, isSupervisor }) =>
              dispatch(
                createEmployeeCrew({
                  assignedAt: new Date(),
                  crewId,
                  employeeId,
                  isCurrent,
                  isDriver,
                  isForeman,
                  isSupervisor,
                }),
              ),
          );
          dispatch(getAllCrews(getAllCrewsQuery));
        }
      }

      dispatch(hideLoader());

      if (defaultSupervisor) {
        dispatch(
          addDefaultSupervisor(
            defaultSupervisor.employeeId as number,
            getAllCrewsQuery,
          ),
        );
      }
    }
  } catch ({ message }) {
    dispatch(
      error({ title: "Something went wrong", message }, () =>
        dispatch(
          addDefaultCrewOnForemanSave(
            crewId,
            oldCrew,
            defaultCrew,
            getAllCrewsQuery,
          ),
        ),
      ),
    );
  }
};

export const getDefaultCrewWorkersByForemanId = async (foremanId: number) => {
  const { data } = await crewService.getAll({
    where: {
      foremanId,
      isDefault: true,
    },
    include: [{ EmployeeCrew: ["Employee"] }],
  });

  if (data.length !== 0) {
    const defaultCrew: {
      workers: EmployeeCrewModel[] | undefined;
      supervisor: EmployeeCrewModel | undefined;
    } = { workers: undefined, supervisor: undefined };

    const workers = data[0].employeeCrews?.filter(
      ({ isForeman, isSupervisor }: EmployeeCrewModel) =>
        !isForeman && !isSupervisor,
    );

    // NOTE: Right now there are cases were a subadmin role is assigned as default supervisor
    // At the time this patch was implemented we did not support that, that's why we need to
    // make sure the role is supervisor.
    const supervisor = data[0].employeeCrews?.find(
      (employeeItem: EmployeeCrewModel) =>
        employeeItem.employee?.role === "supervisor" &&
        employeeItem.isSupervisor &&
        employeeItem.isCurrent,
    );

    if (workers && workers.length > 0) {
      defaultCrew.workers = workers;
    }

    if (supervisor) {
      defaultCrew.supervisor = supervisor;
    }

    return defaultCrew;
  }

  return undefined;
};

export const selectCrew = (state: RootState) => state.crew.crew;
export const selectCrews = (state: RootState) => state.crew.crews;
export const selectCrewsReqStatus = (state: RootState) =>
  state.crew.crewsReqStatus;
export const selectFirstCrewId = (state: RootState) => {
  if (state.crew.crews[0]) {
    return state.crew.crews[0].id;
  }
  return undefined;
};
export const selectIsLoading = (state: RootState) => state.crew.isLoading;

export default crewSlice.reducer;
