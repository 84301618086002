import { Grid } from "@material-ui/core";
import { BillingInvoiceSection } from "components/jobDetails/summary/BillingInvoiceSection";
import { CostOverview } from "components/jobDetails/summary/CostOverview";
import React from "react";

export function Summary() {
  return (
    <Grid container spacing={4}>
      <CostOverview />
      <BillingInvoiceSection />
    </Grid>
  );
}
