import { CrewModel } from "@dwo/shared/dist/models/crewModel";
import { EmployeeModel } from "@dwo/shared/dist/models/employeeModel";
import { ServiceOptions } from "@dwo/shared/dist/services/baseService";
import {
  Box,
  Button,
  CircularProgress,
  createStyles,
  Grid,
  IconButton,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import { Add, Edit } from "@material-ui/icons";
import { InfoItem } from "components/hierarchy/InfoItem";
import { WorkforceCrew } from "components/jobDetails/workforce/WorkforceCrew";
import { LoadingSpinner } from "components/LoadingSpinner";
import { SupervisorModal } from "components/userManagement/Hierarchy/SupervisorModal";
import {
  createCrewWithAssignment,
  getAllCrews,
  selectCrews,
  selectCrewsReqStatus,
} from "features/crew/crewSlice";
import {
  createEmployeeCrew,
  deleteEmployeeCrew,
  updateEmployeeCrew,
} from "features/employee_crew/employeeCrewSlice";
import { selectEmployeeJobsReqStatus } from "features/employees/employeesSlice";
import { selectCurrentJob, updateJob } from "features/jobs/jobsSlice";
import { selectCurrentUser } from "features/logIn/sessionSlice";
import { selectResponse } from "features/prompt/promptSlice";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { ReqStatus } from "utils/sharedUtils";

interface WorkforceProps {
  onClickCarButton: (foremanId: number) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      color: theme.palette.primary.dark,
      fontWeight: "bold",
    },
    titleContainer: {
      display: "flex",
      height: "64px",
      alignItems: "center",
      justifyContent: "space-between",
      paddingRight: "24px",
    },
    icon: {
      backgroundColor: theme.palette.primary.main,
      borderRadius: "50%",
      color: "white",
      padding: "4px",
    },
    jobIdTitle: {
      color: theme.palette.primary.main,
    },
    button: {
      borderRadius: "2px",
      width: "150px",
    },
  }),
);

export function Workforce({ onClickCarButton }: WorkforceProps) {
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const jobId = parseInt(id, 10);
  const crews = useSelector(selectCrews);
  const currentUser = useSelector(selectCurrentUser);
  const promptResponse = useSelector(selectResponse);
  const jobReqStatus = useSelector(selectEmployeeJobsReqStatus);
  const crewsReqStatus = useSelector(selectCrewsReqStatus);
  const currentJob = useSelector(selectCurrentJob);
  const [isSupervisorModalOpen, setSupervisorModalOpen] = useState(false);
  const [deleteEmployeeCrewId, setDeleteEmployeeCrewId] = useState<number>(0);
  const [isJobsLoading, setIsJobsLoading] = useState(true);
  const [isCrewLoading, setIsCrewLoading] = useState(true);
  const [jobCrews, setJobCrews] = useState<CrewModel[]>([]);
  const getAllCrewsQuery = useCallback((): ServiceOptions => {
    return {
      where: {
        jobId,
      },
      include: [
        { EmployeeCrew: [{ Employee: [{ EmployeePicture: ["Picture"] }] }] },
        "job",
      ],
    };
  }, [jobId]);
  const primaryCrew = (() => {
    if (crews.length > 0) {
      return crews.filter((crew) => crew.isPrimary);
    }
    return [];
  })();
  const currentSupervisor =
    (primaryCrew[0] &&
      primaryCrew[0].employeeCrews?.filter(
        (crewMember) => crewMember.isSupervisor && crewMember.isCurrent,
      )) ??
    [];
  const primarySupervisors =
    (primaryCrew[0] &&
      primaryCrew[0].employeeCrews?.filter(
        (crewMember) => crewMember.isSupervisor,
      )) ??
    [];
  const primaryForemen =
    (primaryCrew[0] &&
      primaryCrew[0].employeeCrews?.filter(
        (crewMember) => crewMember.isForeman,
      )) ??
    [];
  const classes = useStyles();

  useEffect(() => {
    dispatch(getAllCrews(getAllCrewsQuery()));
  }, [dispatch, getAllCrewsQuery]);

  useEffect(() => {
    if (crews.length > 0) {
      setJobCrews([...crews].sort((value) => (value.isPrimary ? -1 : 1)));
    }
  }, [crews]);

  useEffect(() => {
    setIsJobsLoading(jobReqStatus === ReqStatus.PENDING);
  }, [jobReqStatus]);

  useEffect(() => {
    setIsCrewLoading(crewsReqStatus === ReqStatus.PENDING);
  }, [crewsReqStatus]);

  useEffect(() => {
    if (deleteEmployeeCrewId !== 0 && promptResponse) {
      dispatch(deleteEmployeeCrew(deleteEmployeeCrewId, getAllCrewsQuery()));
      setDeleteEmployeeCrewId(0);
    }
  }, [promptResponse, dispatch, deleteEmployeeCrewId, getAllCrewsQuery]);

  const handleCloseSupervisorModal = () => setSupervisorModalOpen(false);
  const handleClickOpenSupervisorModal = () => setSupervisorModalOpen(true);

  const handleClickSaveSupervisorModal = (supervisorSelectedId: string) => {
    if (primaryCrew && primaryCrew[0] && currentJob) {
      const employeeCrewObj = {
        assignedAt: new Date(),
        crewId: primaryCrew[0].id as number,
        employeeId: Number(supervisorSelectedId),
        isCurrent: true,
        isDriver: false,
        isForeman: false,
        isSupervisor: true,
      };

      const updatedJob = {
        address: currentJob.address,
        category: currentJob.category,
        /* Client requested to comment Contract Type selection, the options were moved to Job Type */
        // contractType: currentJob.contractType,
        endDate: currentJob.endDate,
        id: currentJob.id,
        jobId: currentJob.jobId,
        regionId: currentJob.regionId,
        startDate: currentJob.startDate,
        status: currentJob.status,
        supervisorId: Number(supervisorSelectedId),
        type: currentJob.type,
        updatedAt: new Date(),
        workOrderType: currentJob.workOrderType,
      };

      if (primarySupervisors.length === 0) {
        dispatch(createEmployeeCrew(employeeCrewObj, true, getAllCrewsQuery()));
        dispatch(updateJob(updatedJob, { include: ["region"] }));
      }

      if (primarySupervisors[0] && primarySupervisors[0].id) {
        dispatch(
          updateEmployeeCrew(
            primarySupervisors[0].id,
            employeeCrewObj,
            true,
            getAllCrewsQuery(),
          ),
        );
        dispatch(updateJob(updatedJob, { include: ["region"] }));
      }
    }
  };

  const handleClickCreateCrew = () => {
    if (!primaryForemen[0]) {
      dispatch(
        createCrewWithAssignment(
          false,
          undefined,
          Number(jobId),
          true,
          true,
          getAllCrewsQuery(),
        ),
      );
    }
  };

  const handleClickAddNewTeam = () => {
    if (primaryCrew[0]) {
      dispatch(
        createCrewWithAssignment(
          false,
          undefined,
          Number(jobId),
          false,
          true,
          getAllCrewsQuery(),
        ),
      );
    }
  };

  if (isJobsLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Fragment>
      {isCrewLoading && (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          padding="48px"
        >
          <CircularProgress size={40} />
        </Box>
      )}
      {!isCrewLoading && crews.length === 0 && (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          padding="48px"
        >
          <Button
            variant="contained"
            color="primary"
            onClick={handleClickCreateCrew}
          >
            Create crew
          </Button>
        </Box>
      )}
      {!isCrewLoading && crews.length > 0 && (
        <Fragment>
          <Grid container>
            <Grid item lg={2} sm={3} xs={12}>
              <Box className={classes.titleContainer}>
                <Typography variant="body2" className={classes.title}>
                  Supervisor
                </Typography>
                {currentUser?.employee.role !== "supervisor" && (
                  <IconButton
                    aria-label="edit supervisor"
                    className={classes.icon}
                    onClick={handleClickOpenSupervisorModal}
                  >
                    {currentSupervisor.length > 0 ? <Edit /> : <Add />}
                  </IconButton>
                )}
              </Box>
            </Grid>
            <Grid item lg={4} sm={6} xs={12}>
              {currentSupervisor.length === 0 ? (
                <Box display="flex" height="100%" alignItems="center">
                  <Typography>Add a Supervisor</Typography>
                </Box>
              ) : (
                <Box>
                  {currentSupervisor.map((supervisor) => (
                    <InfoItem
                      key={supervisor.employeeId}
                      employee={
                        supervisor.employee
                          ? supervisor.employee
                          : ({} as EmployeeModel)
                      }
                    />
                  ))}
                </Box>
              )}
            </Grid>
          </Grid>
          <Box paddingTop="24px" />
          {jobCrews &&
            jobCrews.map((crew, index) => (
              <WorkforceCrew
                key={crew.id as number}
                crewId={crew.id as number}
                foremanId={crew.foremanId}
                position={index + 1}
                regionId={currentJob?.regionId}
                jobId={jobId}
                getAllCrewsQuery={getAllCrewsQuery}
                onClickCarButton={onClickCarButton}
              />
            ))}
          <Box marginTop="24px" width="174px">
            <Button
              className={classes.button}
              variant="outlined"
              onClick={handleClickAddNewTeam}
            >
              Add New Team
            </Button>
          </Box>
        </Fragment>
      )}
      <SupervisorModal
        isOpen={isSupervisorModalOpen}
        regionId={currentJob?.regionId}
        supervisorAssigned={
          currentSupervisor.length > 0
            ? currentSupervisor[0].employee
            : undefined
        }
        onClickCancel={handleCloseSupervisorModal}
        onClickSave={handleClickSaveSupervisorModal}
      />
    </Fragment>
  );
}
