import {
  Box,
  Button,
  createStyles,
  Grid,
  InputAdornment,
  makeStyles,
  Paper,
  TextField,
  Theme,
  Typography,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { Dropdown, DropdownOption } from "components/Dropdown";
import { DropdownPagination } from "components/DropdownPagination";
import { Layout } from "components/layout/Layout";
import { CustomPagination as Pagination } from "components/Pagination";
import { TableCustom } from "components/table/TableCustom";
import {
  createEmployee,
  getAllEmployees,
  selectCount,
  selectEmployees,
  selectIsEditModalOpen,
  selectLimit,
  selectLoading as selectEmployeesLoading,
  selectUserFilters,
  selectUserQuery,
  setIsEditModalOpen,
  setUserFilters,
  setUserQuery,
} from "features/employees/employeesSlice";
import { selectCurrentUser } from "features/logIn/sessionSlice";
import {
  getAllRegions,
  selectLoading as selectRegionLoading,
  selectRegions,
} from "features/region/regionSlice";
import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ServiceOptions } from "@dwo/shared/dist/services/baseService";
import { parseRegions } from "utils/homeUtils";
import { rowSelectOptions } from "utils/tableUtils";
import {
  getMainTableRows,
  initialUserQuery,
  mainTableHeaderColumns,
  roleFilterOptions,
} from "utils/userManagementUtils";
import { DEFAULT_LIMIT } from "utils/sharedUtils";
import { cloneDeep } from "lodash";
import { DropdownMultiple } from "components/DropdownMultiple";
import { NewEmployeeModel } from "@dwo/shared/dist/models/employeeModel";
import { CreateEditUserModal } from "components/userManagement/EmployeeInformation/CreateEditUserModal";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      fontSize: "32px",
      color: theme.palette.primary.main,
      fontWeight: 600,
    },
    paper: {
      margin: "0 24px 24px 24px",
    },
    filterContainer: {
      padding: "24px 24px 16px",
    },
    paginationContainer: {
      padding: "24px",
    },
    rowSelectLabel: {
      color: theme.palette.primary.dark,
      fontWeight: "bold",
    },
    clearFiltersButton: {
      backgroundColor: "#2D72E0",
      padding: "0",
      width: "100%",
    },
    userButton: {
      width: "100%",
    },
  }),
);

export function UserManagement() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const currentUser = useSelector(selectCurrentUser);
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoadSearch, setIsLoadSearch] = useState(false);
  const [page, setPage] = useState<number>(1);
  const [query, setQuery] = useState<ServiceOptions>({
    limit: DEFAULT_LIMIT,
  });
  const [region, setRegion] = useState<string[]>([]);
  const [role, setRole] = useState<string>("");
  const [rowsPerPage, setRowsPerPage] = useState<string>(
    rowSelectOptions[0].value,
  );
  const [sortingOptions, setSortingOptions] = useState<string[][]>([]);
  const currentLimit = useSelector(selectLimit) || DEFAULT_LIMIT;
  const currentCount = useSelector(selectCount);
  const currentRegions = useSelector(selectRegions);
  const employeeList = useSelector(selectEmployees);
  const isLoadingEmployees = useSelector(selectEmployeesLoading);
  const isNewModalOpen = useSelector(selectIsEditModalOpen);
  const isLoadingRegions = useSelector(selectRegionLoading);
  const filters = useSelector(selectUserFilters);
  const storeQuery = useSelector(selectUserQuery);
  const isFirstRender = useRef(true);

  const isLoading = isLoadingEmployees || isLoadingRegions;

  const DEBOUNCE_TIME_MS = 1000;

  useEffect(() => {
    dispatch(getAllRegions());
  }, [dispatch]);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      setSearchTerm(filters.searchValue);
      setPage(filters.page);
      setRegion(filters.region);
      setRole(filters.role);
      setRowsPerPage(filters.rowsPerPage);
      setSortingOptions(filters.sortingOptions);
      setQuery(storeQuery);
      return;
    }
    dispatch(getAllEmployees(query));
    dispatch(
      setUserFilters({
        searchValue: searchTerm,
        page,
        region,
        role,
        rowsPerPage,
        sortingOptions,
      }),
    );
    dispatch(setUserQuery(query));
    // eslint-disable-next-line
  }, [query, dispatch]);

  useEffect(() => {
    if (!isLoadSearch) return;
    const queryHandler = setTimeout(() => {
      setQuery((query) => ({
        ...query,
        where: {
          ...query.where,
          $or: {
            fullName: { $iLike: `%${searchTerm.split(" ").join("%")}%` },
            employeeId: { $iLike: `%${searchTerm.split(" ").join("%")}%` },
          },
        },
        offset: 0,
      }));
      setPage(1);
    }, DEBOUNCE_TIME_MS);

    return () => {
      clearTimeout(queryHandler);
    };
  }, [searchTerm, isLoadSearch]);

  const getTotalPages = () => {
    const pages = Math.ceil(currentCount / currentLimit);

    if (pages >= 1) {
      return pages;
    }

    return 1;
  };

  const handleChangePage = (newPage: number) => {
    const updatedQuery = cloneDeep(query);
    updatedQuery.offset = (newPage - 1) * currentLimit;
    setPage(newPage);
    setQuery(updatedQuery);
  };

  const handleSelectRegion = (selectedRegions: string[]) => {
    const updatedQuery = cloneDeep(query);
    updatedQuery.offset = 0;

    updatedQuery.where = {
      ...query.where,
      regionId:
        selectedRegions.length > 0
          ? selectedRegions.map((selectedRegion) =>
              parseInt(selectedRegion, 10),
            )
          : undefined,
    };

    setPage(1);
    setRegion(selectedRegions);
    setQuery(updatedQuery);
  };

  const handleSelectRole = (selectedRole?: string) => {
    if (selectedRole) {
      const updatedQuery = cloneDeep(query);
      updatedQuery.offset = 0;

      updatedQuery.where = {
        ...query.where,
        role: selectedRole === "all" ? undefined : selectedRole,
      };

      setPage(1);
      setRole(selectedRole);
      setQuery(updatedQuery);
    }
  };

  const handleSelectRowsPerPage = (selectedValue: string) => {
    const updatedQuery = cloneDeep(query);
    updatedQuery.offset = 0;
    updatedQuery.limit = Number(selectedValue);

    setPage(1);
    setRowsPerPage(selectedValue);
    setQuery(updatedQuery);
  };

  const handleChangeSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
    setIsLoadSearch(true);
  };

  const handleClickCancel = () => dispatch(setIsEditModalOpen(false));

  const handleClickNewUser = () => dispatch(setIsEditModalOpen(true));

  const handleClickSave = (newEmployee: NewEmployeeModel) => {
    dispatch(createEmployee(newEmployee));
  };

  const handleClickSorting = (sortingValues: string[][]) => {
    const updatedQuery = cloneDeep(query);
    setSortingOptions(sortingValues);
    updatedQuery.order = sortingValues.length > 0 ? sortingValues : undefined;
    setQuery(updatedQuery);
  };

  const allRegions: DropdownOption[] = parseRegions(currentRegions);
  const handleClickClearFilters = () => {
    setSearchTerm("");
    setPage(1);
    setRegion([]);
    setRole("");
    setRowsPerPage(rowSelectOptions[0].value);
    setSortingOptions([]);
    setQuery(initialUserQuery);
  };

  return (
    <Layout>
      <Box padding="32px 24px 24px 24px">
        <Typography className={classes.header} component="h1">
          User Management
        </Typography>
      </Box>

      <Paper className={classes.paper}>
        <Box className={classes.filterContainer}>
          <Grid alignItems="center" container spacing={3}>
            <Grid
              item
              xl={
                currentUser?.employee.role === "admin" ||
                currentUser?.employee.role === "subadmin"
                  ? 6
                  : 7
              }
              sm={6}
              xs={12}
            >
              <TextField
                value={searchTerm}
                placeholder="Search by Employee Name or ID"
                margin="normal"
                variant="outlined"
                InputProps={{
                  type: "search",
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
                onChange={handleChangeSearch}
              />
            </Grid>
            <Grid item md={2} xs={12}>
              <DropdownMultiple
                id="regionDropdown"
                label="Filters"
                options={allRegions}
                selectedValue={region}
                onSelectOption={handleSelectRegion}
              />
            </Grid>
            <Grid item md={2} xs={12}>
              <Dropdown
                id="roleDropdown"
                label="Roles"
                options={roleFilterOptions}
                selectedValue={role}
                onSelectOption={handleSelectRole}
              />
            </Grid>
            <Grid item xl={1} sm={2} xs={12}>
              <Button
                variant="contained"
                className={classes.clearFiltersButton}
                onClick={handleClickClearFilters}
              >
                Clear Filters
              </Button>
            </Grid>

            {(currentUser?.employee.role === "admin" ||
              currentUser?.employee.role === "subadmin") && (
              <Grid item xl={1} sm={2} xs={12}>
                <Button
                  variant="contained"
                  className={classes.userButton}
                  onClick={handleClickNewUser}
                >
                  New User
                </Button>
              </Grid>
            )}
          </Grid>
        </Box>

        <TableCustom
          columns={mainTableHeaderColumns}
          isLoading={isLoading}
          noRowsMessage="We couldn't find any result that matched you search. Please try againg
          with different filters or Name/ID."
          rows={getMainTableRows(employeeList)}
          sortingOptions={sortingOptions}
          onClickSort={handleClickSorting}
        />

        <Box className={classes.paginationContainer}>
          <Grid alignItems="center" container spacing={4}>
            <Grid item xs={4} />
            <Grid item xs={4}>
              <Box display="flex" justifyContent="center">
                <Pagination
                  id="usersManagement-pagination"
                  isDisabled={isLoading || currentCount === 0}
                  page={page}
                  totalPages={getTotalPages()}
                  onChangePage={handleChangePage}
                />
              </Box>
            </Grid>

            <Grid item xs={4}>
              <Box display="flex" alignItems="center" justifyContent="flex-end">
                <Box marginRight="8px">
                  <Typography
                    variant="caption"
                    className={classes.rowSelectLabel}
                  >
                    Rows per page:
                  </Typography>
                </Box>
                <DropdownPagination
                  id="rowsDropdown"
                  isDisabled={isLoading || currentCount === 0}
                  options={rowSelectOptions}
                  selectedValue={rowsPerPage}
                  onSelectOption={handleSelectRowsPerPage}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>

        <CreateEditUserModal
          isOpen={isNewModalOpen}
          onClickClose={handleClickCancel}
          onSubmit={handleClickSave}
        />
      </Paper>
    </Layout>
  );
}
