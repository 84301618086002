import { createMuiTheme } from "@material-ui/core/styles";
import "fontsource-noto-sans";

const primary = "#1654B8";
const primaryDark = "#002868";
const primaryLight = "#2D72E0";
const secondary = "#D50918";
const background = "#F0EFEF";
const errorMain = "#D50918";
const successMain = "#16B735";
const successLight = "#00E396";
const warningMain = "#57A825";
const warningLight = "#F7A60E";

const grey100 = "#F7F7F7";
const grey200 = "#F0EFEF";
const grey300 = "#E3E3E3";
const grey400 = "#C3C3C7";
const grey500 = "#838385";
const grey600 = "#646466";
const grey700 = "#384265";
const grey800 = "#161616";
const grey900 = "#3D3D3D";

const customTheme = createMuiTheme({
  palette: {
    primary: { main: primary, dark: primaryDark, light: primaryLight },
    secondary: { main: secondary },
    background: { default: background },
    divider: "#E3E3E3",
    grey: {
      "100": grey100,
      "200": grey200,
      "300": grey300,
      "400": grey400,
      "500": grey500,
      "600": grey600,
      "700": grey700,
      "800": grey800,
      "900": grey900,
    },
    error: {
      main: errorMain,
    },
    success: {
      main: successMain,
      light: successLight,
    },
    warning: {
      main: warningMain,
      light: warningLight,
    },
  },
  typography: {
    fontFamily: "Noto Sans",
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: "2px",
        textTransform: "none",
        padding: "12px",
        height: "48px",
        fontSize: "16px",
        fontWeight: "bold",
      },
      outlined: {
        border: `1px solid ${grey400}`,
        padding: "12px",
        color: primaryDark,
        backgroundColor: "white",
      },
      contained: {
        backgroundColor: primaryDark,
        borderRadius: "2px",
        boxShadow: "none",
        color: "white",
      },
    },
    MuiFormControl: {
      marginNormal: {
        marginBottom: "0",
        marginTop: "0",
      },
    },
    MuiOutlinedInput: {
      input: {
        padding: "14px 12px 14px 24px",
      },
      root: {
        backgroundColor: "white",
        borderRadius: 0,
        color: primaryDark,
        fontWeight: "bold",
        height: "48px",

        "& .MuiSelect-select:focus": {
          backgroundColor: "white",
        },

        "& .MuiSelect-icon": {
          color: primaryDark,
          fontSize: "2rem",
          top: "calc(50% - 16px)",
        },

        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          border: "1px solid #838385",
        },

        "&:hover .MuiOutlinedInput-notchedOutline": {
          borderColor: "#838385",
        },
      },
    },
    MuiPaper: {
      root: {
        borderRadius: "2px",
      },
    },
    MuiTextField: {
      root: {
        backgroundColor: background,
        height: "48px",
        width: "100%",
        "& > .MuiInputLabel-root": {
          color: grey400,
          padding: "4px 8px",
        },
        color: grey500,
      },
    },
    MuiInput: {
      input: {
        paddingLeft: "8px",
      },
      underline: {
        "&:after": {
          borderBottom: `1px solid ${primaryDark}`,
        },
        "&:before": {
          borderBottom: `1px solid ${primaryDark}`,
        },
        "&:hover:not(.Mui-disabled):before": {
          borderBottom: `1px solid ${primaryDark}`,
        },
        borderBottom: `1px solid ${primaryDark}`,
      },
    },
    MuiInputLabel: {
      filled: {
        "&.MuiInputLabel-shrink": {
          transform: "translate(12px, -4px) scale(0.75)",
        },
      },
    },
    MuiFilledInput: {
      root: {
        "&:focused": {
          backgroundColor: grey300,
        },
        "&:hover": {
          backgroundColor: grey300,
        },
        "&.Mui-focused": {
          backgroundColor: grey300,
        },
        transition: "none",
      },
      underline: {
        "&:after": {
          borderBottom: `2px solid ${primaryDark}`,
        },
        "&:before": {
          borderBottom: `2px solid ${primaryDark}`,
        },
        "&:hover:before": {
          borderBottom: `2px solid ${primaryDark}`,
        },
      },
    },
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: "none",
      },
    },
    MuiTypography: {
      root: {
        userSelect: "none",
      },
    },
  },
});

export default customTheme;
