import {
  Box,
  Button,
  createStyles,
  Divider,
  Grid,
  makeStyles,
  Theme,
  Typography,
  useTheme,
} from "@material-ui/core";
import Chart from "chart.js";
import { AddExpensesModal } from "components/jobDetails/summary/AddExpensesModal";
import {
  getExpenseItems,
  getExpensesCanvasSummary,
  getOvercost,
  getProfit,
} from "components/jobDetails/summary/costOverviewUtils";
import { ExpenseItem } from "components/jobDetails/summary/ExpenseItem";
import { selectCurrentJob } from "features/jobs/jobsSlice";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { slashFormatDate } from "utils/dateUtils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      color: theme.palette.primary.main,
      marginRight: "16px",
    },
    button: {
      width: "150px",
    },
    blueDotStatus: {
      height: "12px",
      width: "12px",
      borderRadius: "50px",
      backgroundColor: theme.palette.primary.light,
      border: theme.palette.primary.light,
      marginRight: "5px",
    },
    darkBlueDotStatus: {
      height: "12px",
      width: "12px",
      borderRadius: "50px",
      backgroundColor: theme.palette.primary.dark,
      border: theme.palette.primary.dark,
      marginRight: "5px",
    },
    toDate: {
      fontWeight: "bold",
      color: theme.palette.primary.dark,
    },
    bid: {
      fontWeight: "bold",
      color: theme.palette.primary.light,
    },
  }),
);

export function CostOverview() {
  const theme = useTheme();
  const currentJob = useSelector(selectCurrentJob);
  const [isAddExpensesModalOpen, setIsAddExpensesModalOpen] = useState(false);
  const expensesCanvas = useRef({} as HTMLCanvasElement);
  const classes = useStyles();
  const areEstimatedExpensesDefined =
    currentJob &&
    (currentJob.bidLaborCost ||
      currentJob.bidMaterialsCost ||
      currentJob.bidOtherCost);

  useEffect(() => {
    if (currentJob) {
      const expensesCanvasObj = getExpensesCanvasSummary(
        theme.palette.primary.main,
        theme.palette.primary.dark,
        {
          bid: [
            Number(currentJob.bidLaborCost),
            Number(currentJob.bidMaterialsCost),
            Number(currentJob.bidOtherCost),
          ],
          expenses: [
            Number(currentJob.laborCost),
            Number(currentJob.materialsCost),
            Number(currentJob.otherCost),
          ],
        },
      );

      const chart = new Chart(expensesCanvas.current, {
        type: "bar",
        data: expensesCanvasObj.data,
        options: expensesCanvasObj.options,
      });

      return () => {
        chart.destroy();
      };
    }
  }, [theme.palette.primary, currentJob]);

  const handleToggleAddExpensesModal = () =>
    setIsAddExpensesModalOpen(!isAddExpensesModalOpen);

  return (
    <Fragment>
      <Grid item sm={5} xs={12}>
        <Box
          display="flex"
          marginBottom={areEstimatedExpensesDefined ? "64px" : "0"}
        >
          <Typography variant="h5" className={classes.header}>
            Cost Overview
          </Typography>
        </Box>
        {areEstimatedExpensesDefined ? (
          <canvas ref={expensesCanvas} />
        ) : (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100%"
          >
            <Typography>Nothing to display.</Typography>
          </Box>
        )}
      </Grid>
      <Grid item sm={7} xs={12}>
        <Box
          display="flex"
          width="100%"
          justifyContent="flex-end"
          marginBottom="26px"
        >
          <Button
            className={classes.button}
            variant="outlined"
            onClick={handleToggleAddExpensesModal}
          >
            Add Expenses
          </Button>
        </Box>
        <Box display="flex">
          <Box flex={1} marginBottom="21px" />
          <Box flex={1} marginBottom="21px">
            <Box display="flex" justifyContent="center" alignItems="center">
              <Box className={classes.blueDotStatus} />
              <Typography className={classes.bid} variant="body2">
                Bid
              </Typography>
            </Box>
          </Box>
          <Box flex={1} marginBottom="21px">
            <Box display="flex" justifyContent="center" alignItems="center">
              <Box className={classes.darkBlueDotStatus} />
              <Typography className={classes.toDate} variant="body2">
                To Date {slashFormatDate(new Date())}
              </Typography>
            </Box>
          </Box>
        </Box>
        {getExpenseItems(currentJob).map(({ type, bid, total }) => (
          <Box marginBottom="21px" key={`${type}-${bid}-${total}`}>
            <ExpenseItem type={type} bid={bid} total={total} />
          </Box>
        ))}
        <Divider />
        <Box marginTop="21px">
          <ExpenseItem
            type="Profit"
            bid={getProfit(true, currentJob)}
            total={getProfit(false, currentJob)}
            isProfit
          />
        </Box>
        <Box marginTop="21px">
          <ExpenseItem
            type="Overtime cost"
            bid="N/A"
            total={getOvercost(currentJob)}
          />
        </Box>
      </Grid>
      <AddExpensesModal
        isOpen={isAddExpensesModalOpen}
        onToggle={handleToggleAddExpensesModal}
      />
    </Fragment>
  );
}
