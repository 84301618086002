import DateFnsUtils from "@date-io/date-fns";
import {
  Box,
  createStyles,
  makeStyles,
  Paper,
  Theme,
  Typography,
} from "@material-ui/core";
import { Calendar, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import { isSameDay } from "date-fns/esm";
import React, { Fragment } from "react";

interface TimesheetsPerDayCardProps {
  selectedDate?: Date;
  startDate?: Date;
  onSelectDate: (date: Date) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      margin: "0 auto",
      overflow: "hidden",
      "& .MuiPickersCalendarHeader-daysHeader": {
        justifyContent: "space-between",
      },
      "& .MuiPickersCalendarHeader-dayLabel": {
        width: "24px",
      },
      "& > div:first-of-type": {
        width: "100%",
      },
      "& div.MuiPickersCalendarHeader-switchHeader": {
        marginBottom: "16px",
      },
      "& div.MuiPickersCalendarHeader-switchHeader p.MuiTypography-alignCenter": {
        color: theme.palette.primary.main,
        marginLeft: "4px",
        textAlign: "start",
      },
      "& div.MuiPickersCalendarHeader-switchHeader button.MuiIconButton-root": {
        padding: "0",
      },
      "& div.MuiPickersCalendarHeader-switchHeader > button.MuiIconButton-root:first-child": {
        color: theme.palette.primary.dark,
        order: 1,
      },
      "& div.MuiPickersCalendarHeader-switchHeader > button.MuiIconButton-root:last-child": {
        color: theme.palette.primary.dark,
        order: 2,
      },
      [theme.breakpoints.down("sm")]: {
        alignItems: "center",
        maxWidth: "260px",
      },
    },
    currentDate: {
      border: `2px solid ${theme.palette.grey[400]}`,
      borderRadius: "50%",
      height: "24px",
      padding: "0px 2px",
      width: "24px",
    },
    currentDateSelected: {
      backgroundColor: theme.palette.primary.main,
      border: `2px solid ${theme.palette.grey[400]}`,
      borderRadius: "50%",
      color: "white",
      height: "24px",
      padding: "0px 2px",
      width: "24px",
      "&:hover": {
        color: theme.palette.grey[400],
      },
      "&:hover span > p": {
        fontWeight: 600,
      },
    },
    dateText: {
      color: theme.palette.primary.main,
      cursor: "pointer",
      textDecoration: "underline",
    },
    dateTextNoLink: {
      color: theme.palette.primary.main,
    },
    iconCurrentDate: {
      border: `1px solid ${theme.palette.grey[400]}`,
      borderRadius: "50%",
      height: "12px",
      marginRight: "12px",
      width: "12px",
    },
    iconSelectedDate: {
      backgroundColor: theme.palette.primary.main,
      borderRadius: "50%",
      height: "12px",
      marginRight: "12px",
      width: "12px",
    },
    iconStartDate: {
      border: `1px solid ${theme.palette.primary.dark}`,
      borderRadius: "50%",
      height: "12px",
      marginRight: "12px",
      width: "12px",
    },
    paper: {
      flex: 1,
      margin: "0 24px 24px 0",
      padding: "24px",
      [theme.breakpoints.down("sm")]: {
        marginLeft: "24px",
      },
    },
    startDate: {
      border: `2px solid ${theme.palette.primary.dark}`,
      borderRadius: "50%",
      height: "24px",
      padding: "0px 2px",
      width: "24px",
    },
    startDateSelected: {
      backgroundColor: theme.palette.primary.main,
      border: `2px solid ${theme.palette.primary.dark}`,
      borderRadius: "50%",
      color: "white",
      height: "24px",
      padding: "0px 2px",
      width: "24px",
      "&:hover": {
        color: theme.palette.grey[400],
      },
      "&:hover span > p": {
        fontWeight: 600,
      },
    },
    title: {
      color: theme.palette.primary.main,
      marginBottom: "16px",
    },
    transition: {
      width: "100%",
      "& > div > div.MuiPickersCalendar-week:not(:last-child)": {
        marginBottom: "12px",
      },
    },
    week: {
      justifyContent: "space-between",
      "& .MuiPickersDay-day": {
        [theme.breakpoints.up("lg")]: {
          height: "24px",
          width: "24px",
        },
        height: "16px",
        width: "16px",
        [theme.breakpoints.down("sm")]: {
          height: "24px",
          width: "24px",
        },
      },
      "& .MuiPickersDay-daySelected": {
        backgroundColor: theme.palette.primary.main,
      },
      "& :not(.MuiPickersDay-daySelected).MuiPickersDay-day span > p": {
        color: theme.palette.grey[400],
        fontSize: "1rem",
      },
      "& .MuiPickersDay-daySelected span > p": {
        color: "white",
        fontWeight: 600,
      },
    },
  }),
);

export function TimesheetsPerDayCard({
  selectedDate,
  startDate,
  onSelectDate,
}: TimesheetsPerDayCardProps) {
  const classes = useStyles();
  const hasStartDate = !!startDate;

  const handleChangeDate = (date: MaterialUiPickersDate) => {
    const newDate = new Date((date as Date).getTime());

    newDate.setHours(0, 0, 0, 0);

    onSelectDate(newDate);
  };

  const handleClickCurrentDateFocus = () => onSelectDate(new Date());

  const handleClickStartDateFocus = () => {
    if (startDate) {
      const currentDate = new Date();
      const newDate = new Date(startDate);
      newDate.setHours(
        currentDate.getHours(),
        currentDate.getMinutes(),
        currentDate.getSeconds(),
        currentDate.getMilliseconds(),
      );
      onSelectDate(newDate);
    }
  };

  const renderDay = (
    day: MaterialUiPickersDate,
    selectedDate: MaterialUiPickersDate,
    dayInCurrentMonth: boolean,
    dayComponent: JSX.Element,
  ) => {
    if (startDate) {
      const newStartDate = new Date(startDate);

      if (
        isSameDay(selectedDate as Date, newStartDate) &&
        isSameDay(day as Date, newStartDate) &&
        dayInCurrentMonth
      ) {
        return React.cloneElement(dayComponent, {
          className: classes.startDateSelected,
        });
      }

      if (isSameDay(day as Date, newStartDate) && dayInCurrentMonth) {
        return React.cloneElement(dayComponent, {
          className: classes.startDate,
        });
      }
    }

    const currentDate = new Date();

    if (
      isSameDay(selectedDate as Date, currentDate) &&
      isSameDay(day as Date, currentDate) &&
      dayInCurrentMonth
    ) {
      return React.cloneElement(dayComponent, {
        className: classes.currentDateSelected,
      });
    }

    if (isSameDay(day as Date, currentDate) && dayInCurrentMonth) {
      return React.cloneElement(dayComponent, {
        className: classes.currentDate,
      });
    }

    return dayComponent;
  };

  return (
    <Paper className={classes.paper}>
      <Box className={classes.container}>
        <Typography className={classes.title} variant="h6">
          Timesheet Per Day
        </Typography>

        {selectedDate && (
          <Fragment>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Calendar
                allowKeyboardControl={false}
                classes={{
                  transitionContainer: classes.transition,
                  week: classes.week,
                }}
                date={selectedDate}
                disableFuture
                onChange={handleChangeDate}
                minDate={startDate}
                renderDay={renderDay}
              />
            </MuiPickersUtilsProvider>

            <Box display="flex" flexDirection="column">
              <Box alignItems="center" display="flex" marginBottom="8px">
                <Box className={classes.iconCurrentDate} />
                <Box onClick={handleClickCurrentDateFocus}>
                  <Typography className={classes.dateText} variant="body2">
                    Current Date
                  </Typography>
                </Box>
              </Box>

              {hasStartDate && (
                <Box alignItems="center" display="flex" marginBottom="8px">
                  <Box className={classes.iconStartDate} />
                  <Box onClick={handleClickStartDateFocus}>
                    <Typography className={classes.dateText} variant="body2">
                      Start Date
                    </Typography>
                  </Box>
                </Box>
              )}

              <Box alignItems="center" display="flex">
                <Box className={classes.iconSelectedDate} />
                <Typography className={classes.dateTextNoLink} variant="body2">
                  Selected Date
                </Typography>
              </Box>
            </Box>
          </Fragment>
        )}
      </Box>
    </Paper>
  );
}
