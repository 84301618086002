import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    link: {
      color: theme.palette.primary.light,
      cursor: "pointer",
      textDecoration: "underline",
    },
  }),
);

interface ClickableTextProps {
  linkText: string;
  onClickLink: () => void;
}

export function ClickableText({ linkText, onClickLink }: ClickableTextProps) {
  const classes = useStyles();

  return (
    <Box onClick={onClickLink}>
      <Typography align="center" className={classes.link} variant="body2">
        {linkText}
      </Typography>
    </Box>
  );
}
