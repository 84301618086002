import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import React from "react";
import { TooltipLabelOption } from "utils/summaryUtils";

interface StyleProps {
  iconColor: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      color: ({ iconColor }: StyleProps) => iconColor,
      fontSize: "12px",
      marginRight: "4px",
    },
    text: {
      color: theme.palette.grey[500],
    },
  }),
);

interface LabelDescriptionItemProps {
  itemData: TooltipLabelOption;
}

export function LabelDescriptionItem({ itemData }: LabelDescriptionItemProps) {
  const classes = useStyles({ iconColor: itemData.color });

  return (
    <Box alignItems="center" display="flex">
      <FiberManualRecordIcon className={classes.icon} />
      <Typography className={classes.text} variant="body2">
        {itemData.label}
      </Typography>
    </Box>
  );
}
