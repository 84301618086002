import DateFnsUtils from "@date-io/date-fns";
import { createStyles, makeStyles, Theme, Typography } from "@material-ui/core";
import {
  KeyboardDatePicker,
  KeyboardDatePickerProps,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import "date-fns";
import React, { Fragment } from "react";

interface DatePickerProps {
  error?: boolean;
  errorMessage?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    errorMessage: {
      color: theme.palette.error.main,
      paddingTop: "4px",
    },
    field: {
      backgroundColor: theme.palette.grey[300],
      borderRadius: "2px",
      "& input": {
        height: "48px",
        margin: "8px 8px -8px 8px",
        padding: 0,
      },
      "& label + .MuiInput-formControl": {
        marginTop: 0,
      },
      "& .MuiInputBase-root": {
        color: theme.palette.grey[600],
      },
      "& .MuiInputBase-root.Mui-disabled": {
        color: theme.palette.grey[600],
      },
      "& .MuiInputBase-multiline": {
        padding: "16px 8px 8px 8px",
      },
      "&  > .MuiInputLabel-root": {
        color: theme.palette.primary.main,
        fontSize: "0.75rem",
        fontWeight: "bold",
      },
      "& .MuiIconButton-root": {
        color: theme.palette.primary.dark,
        margin: "8px 4px -8px 4px",
        padding: "4px",
      },
    },
  }),
);

export function DatePicker({
  error,
  errorMessage,
  ...props
}: DatePickerProps & KeyboardDatePickerProps) {
  const classes = useStyles();
  return (
    <Fragment>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          className={classes.field}
          disableToolbar
          variant="inline"
          format="MM/dd/yyyy"
          margin="normal"
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
          {...props}
        />
      </MuiPickersUtilsProvider>
      {error && (
        <Typography className={classes.errorMessage} variant="body2">
          {errorMessage}
        </Typography>
      )}
    </Fragment>
  );
}
