import {
  EmployeeModel,
  EmployeeTimesheet,
} from "@dwo/shared/dist/models/employeeModel";
import { JobNoteModel } from "@dwo/shared/dist/models/jobNoteModel";
import { WorkShiftModel } from "@dwo/shared/dist/models/workShiftModel";
import { ServiceOptions } from "@dwo/shared/dist/services/baseService";
import { employeeService } from "@dwo/shared/dist/services/employeeService";
import { workShiftService } from "@dwo/shared/dist/services/workShiftService";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, RootState } from "app/store";
import { WorkShiftTableCellValue } from "features/editWorkShiftTableSlice/workShiftTableValuesSlice";
import { error } from "features/error/errorSlice";
import {
  hide as hideLoader,
  show as showLoader,
} from "features/loader/loaderSlice";
import { cloneDeep } from "lodash";

interface employeeTimesheetState {
  currentEmployee?: EmployeeModel;
  dateRange: { startDate?: string; endDate?: string };
  employeeWorkShifts: WorkShiftModel[];
  generalStatus?: string;
  isLoading: boolean;
  values: WorkShiftTableCellValue[];
  workShiftsTotalHours: number;
}

const initialState: employeeTimesheetState = {
  currentEmployee: undefined,
  dateRange: { startDate: undefined, endDate: undefined },
  employeeWorkShifts: [],
  generalStatus: undefined,
  isLoading: false,
  values: [],
  workShiftsTotalHours: 0,
};

export const employeeTimesheetSlice = createSlice({
  name: "employeeTimesheet",
  initialState,
  reducers: {
    setDateRange: (
      state,
      action: PayloadAction<{ startDate?: string; endDate?: string }>,
    ) => {
      state.dateRange = action.payload;
    },
    setCurrentEmployee: (state, action: PayloadAction<EmployeeModel>) => {
      state.currentEmployee = action.payload;
    },
    setEmployeeWorkShifts: (state, action: PayloadAction<WorkShiftModel[]>) => {
      state.employeeWorkShifts = action.payload;
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setValues: (state, action: PayloadAction<WorkShiftTableCellValue>) => {
      const foundIndex = state.values.findIndex(
        (value) =>
          value.idRow === action.payload.idRow &&
          value.id === action.payload.id,
      );
      if (foundIndex !== -1) {
        state.values[foundIndex] = action.payload;
      } else {
        state.values.push(action.payload);
      }
    },
    removeValueByIdAndType: (
      state,
      action: PayloadAction<{ id: number; type: string }>,
    ) => {
      const foundIndex = state.values.findIndex(
        (value) =>
          value.idRow === action.payload.id && value.id === action.payload.type,
      );
      if (foundIndex !== -1) {
        state.values.splice(foundIndex, 1);
      }
    },
    clearValues: (state) => {
      state.values = [];
    },
    setGeneralStatus: (state, action: PayloadAction<string>) => {
      state.generalStatus = action.payload;
    },
    setWorkShiftsTotalHours: (state, action: PayloadAction<number>) => {
      state.workShiftsTotalHours = action.payload;
    },
    addNewTimesheetComment: (state, action: PayloadAction<JobNoteModel>) => {
      const newComment = action.payload;

      if (!newComment.workshiftId) return;

      const foundIndex = state.employeeWorkShifts.findIndex(
        (ws) => ws.id === newComment.workshiftId,
      );

      if (foundIndex === -1) return;

      const comments = state.employeeWorkShifts[foundIndex].notes;
      const updatedComments = comments ? cloneDeep(comments) : [];

      updatedComments.unshift(newComment);
      state.employeeWorkShifts[foundIndex].notes = updatedComments;
    },
    deleteTimesheetComment: (
      state,
      action: PayloadAction<{ commentId: number; workshiftId: number }>,
    ) => {
      const commentId = action.payload.commentId;
      const workshiftId = action.payload.workshiftId;
      const foundIndex = state.employeeWorkShifts.findIndex(
        (ws) => ws.id === workshiftId,
      );

      if (workshiftId === -1) return;

      const comments = state.employeeWorkShifts[foundIndex].notes;
      const updatedNotes = comments ? cloneDeep(comments) : [];

      state.employeeWorkShifts[foundIndex].notes = updatedNotes.filter(
        (comment) => comment.id !== commentId,
      );
    },
    updateTimesheetComment: (state, action: PayloadAction<JobNoteModel>) => {
      const updatedComment = action.payload;

      if (!updatedComment.workshiftId) return;

      const foundIndex = state.employeeWorkShifts.findIndex(
        (ws) => ws.id === updatedComment.workshiftId,
      );

      if (foundIndex === -1) return;

      const comments = state.employeeWorkShifts[foundIndex].notes;
      let updatedComments = comments ? cloneDeep(comments) : [];

      updatedComments = updatedComments.filter(
        (comment) => comment.id !== updatedComment.id,
      );
      updatedComments.unshift(updatedComment);
      state.employeeWorkShifts[foundIndex].notes = updatedComments;
    },
  },
});

export const {
  setDateRange,
  setCurrentEmployee,
  setEmployeeWorkShifts,
  setIsLoading,
  setValues,
  removeValueByIdAndType,
  clearValues,
  setGeneralStatus,
  setWorkShiftsTotalHours,
  addNewTimesheetComment,
  deleteTimesheetComment,
  updateTimesheetComment,
} = employeeTimesheetSlice.actions;

export const getAllWorkShiftsById = (
  employeeId: number,
  options?: ServiceOptions,
): AppThunk => async (dispatch) => {
  try {
    dispatch(setIsLoading(true));

    const {
      data,
    }: { data: EmployeeTimesheet } = await employeeService.getAllWorkShiftsById(
      employeeId,
      options,
    );
    const { data: profileData } = await employeeService.getProfiles(
      [employeeId],
      undefined,
      true,
    );

    data.employee.pictureUrl = profileData[0]?.pictureUrl;

    const { employee, status, workShifts } = data;

    dispatch(setCurrentEmployee(employee));
    dispatch(setEmployeeWorkShifts(workShifts));
    dispatch(
      setWorkShiftsTotalHours(
        workShifts.reduce(
          (total: number, workShift: WorkShiftModel) => total + workShift.hours,
          0,
        ),
      ),
    );
    dispatch(setGeneralStatus(status));
  } catch (err) {
    dispatch(
      error(
        {
          title: "Could not get Employee WorkShifts",
          message: err.message,
        },
        () => dispatch(getAllWorkShiftsById(employeeId, options)),
      ),
    );
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const approveWorkShifts = (
  workShiftIds: {
    workshiftIds: number[];
  },
  employeeId: number,
  options?: ServiceOptions,
): AppThunk => async (dispatch) => {
  try {
    dispatch(showLoader());
    await workShiftService.approveWorkShifts(workShiftIds);
    dispatch(getAllWorkShiftsById(employeeId, options));
  } catch (err) {
    dispatch(
      error(
        {
          title: "Could not approve the Timesheet. Please try again.",
          message: err.message,
        },
        () => dispatch(approveWorkShifts(workShiftIds, employeeId, options)),
      ),
    );
  } finally {
    dispatch(hideLoader());
  }
};

export const updateWorkShifts = (
  workShifts: Record<"workshifts", WorkShiftModel[]>,
  employeeId: number,
  options?: ServiceOptions,
): AppThunk => async (dispatch) => {
  try {
    dispatch(showLoader());
    await workShiftService.updateWorkShifts(workShifts);
    dispatch(getAllWorkShiftsById(employeeId, options));
  } catch (err) {
    dispatch(
      error(
        {
          title: "Could not update workShifts.",
          message: err.message,
        },
        () => dispatch(updateWorkShifts(workShifts, employeeId, options)),
      ),
    );
  } finally {
    dispatch(hideLoader());
  }
};

export const selectDateRange = (state: RootState) =>
  state.employeeTimesheet.dateRange;
export const selectCurrentEmployee = (state: RootState) =>
  state.employeeTimesheet.currentEmployee;
export const selectEmployeeWorkShifts = (state: RootState) =>
  state.employeeTimesheet.employeeWorkShifts;
export const selectGeneralStatus = (state: RootState) =>
  state.employeeTimesheet.generalStatus;
export const selectIsLoading = (state: RootState) =>
  state.employeeTimesheet.isLoading;
export const selectValues = (state: RootState) =>
  state.employeeTimesheet.values;
export const selectWorkShiftTotalHours = (state: RootState) =>
  state.employeeTimesheet.workShiftsTotalHours;

export default employeeTimesheetSlice.reducer;
