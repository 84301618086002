import { Grid } from "@material-ui/core";
import { selectJobsOfDWOs } from "features/dwo/dwoSlice";
import { JobModel } from "@dwo/shared/dist/models/jobModel";
import React from "react";
import { useSelector } from "react-redux";
import { HoursOverview } from "./HoursOverview";
import { HoursOverviewPerJob, OverviewDisplay } from "./HoursOverviewPerJob";

type DWOJobOverview = [string, number, string, number, number];

export function TimesheetHoursOverview() {
  const jobsFromDWO = useSelector(selectJobsOfDWOs);

  const getOverviews = (): DWOJobOverview[] => {
    if (jobsFromDWO.length > 0) {
      return jobsFromDWO.map((job: JobModel) => [
        job.jobId as string,
        job.hours as number,
        job.type as string,
        job.id as number,
        job.dwoJobId as number,
      ]);
    }
    return [] as DWOJobOverview[];
  };

  const overviews: DWOJobOverview[] = getOverviews();

  const overviewTotalHours = overviews.reduce(
    (total, overview: DWOJobOverview) => total + overview[1],
    0,
  );

  const getOverviewDisplays = (): OverviewDisplay[] => {
    const listOfOverviews = [];
    for (let i = 0; i < overviews.length; i += 2) {
      if (jobsFromDWO[i + 1]) {
        const overview = {
          firstOverview: {
            jobId: jobsFromDWO[i].jobId,
            jobType: jobsFromDWO[i].type,
            totalHours: jobsFromDWO[i].hours,
            id: jobsFromDWO[i].id,
            dwoJobId: jobsFromDWO[i].dwoJobId,
          },
          secondOverview: {
            jobId: jobsFromDWO[i + 1].jobId,
            jobType: jobsFromDWO[i + 1].type,
            totalHours: jobsFromDWO[i + 1].hours,
            id: jobsFromDWO[i + 1].id,
            dwoJobId: jobsFromDWO[i + 1].dwoJobId,
          },
        };
        listOfOverviews.push(overview);
      }

      if (!jobsFromDWO[i + 1]) {
        const overview = {
          firstOverview: {
            jobId: jobsFromDWO[i].jobId,
            jobType: jobsFromDWO[i].type,
            totalHours: jobsFromDWO[i].hours,
            id: jobsFromDWO[i].id,
            dwoJobId: jobsFromDWO[i].dwoJobId,
          },
          secondOverview: undefined,
        };
        listOfOverviews.push(overview);
      }
    }
    return listOfOverviews as OverviewDisplay[];
  };

  return (
    <Grid container>
      <Grid item sm={5} xs={12}>
        <HoursOverview overviewTotalHours={overviewTotalHours} />
      </Grid>

      <Grid item sm={7} xs={12}>
        <HoursOverviewPerJob
          jobsWithTimesheetDetails={jobsFromDWO}
          overviewDisplays={getOverviewDisplays()}
        />
      </Grid>
    </Grid>
  );
}
