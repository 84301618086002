import {
  Box,
  createStyles,
  makeStyles,
  Paper,
  Theme,
  Typography,
  useTheme,
} from "@material-ui/core";
import Chart, { ChartTooltipModel } from "chart.js";
import React, { useEffect, useRef } from "react";
import {
  createCustomPieChartTooltip,
  getTimesheetStatusValue,
  StatusTimesheetSummary,
  TooltipLabelOption,
} from "utils/summaryUtils";
import { renderToString } from "react-dom/server";
import { PieChartTooltip } from "./PieChartTooltip";
import { LabelDescriptionItem } from "./LabelDescriptionItem";
import { selectTimesheetsStatusData } from "features/summary/summarySlice";
import { useSelector } from "react-redux";
import { TimesheetStatusPercentageData } from "@dwo/shared/dist/models/timesheetSummaryModel";
import { TimesheetStatusColors } from "utils/summaryTimesheetUtils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chartContainer: {
      maxWidth: "153px",
      [theme.breakpoints.down("md")]: {
        margin: "0 auto 24px auto",
      },
    },
    container: {
      display: "flex",
      flexDirection: "column",
      margin: "0 24px 24px 0",
      padding: "24px 32px",
      [theme.breakpoints.down("xs")]: {
        margin: "0 24px 24px 24px",
      },
    },
    content: {
      alignItems: "center",
      display: "flex",
      marginBottom: "19px",
      [theme.breakpoints.down("md")]: {
        flexDirection: "column",
      },
    },
    labelsContainer: {
      marginLeft: "40px",
      "& > div": {
        marginBottom: "10px",
      },
      [theme.breakpoints.down("lg")]: {
        marginLeft: "20px",
      },
      [theme.breakpoints.down("md")]: {
        margin: "0 auto",
      },
    },
    title: {
      color: theme.palette.primary.main,
    },
  }),
);

export function TimesheetsStatusCard() {
  const chartCanvasRef = useRef({} as HTMLCanvasElement);
  const summaryData: TimesheetStatusPercentageData[] = useSelector(
    selectTimesheetsStatusData,
  );
  const classes = useStyles();
  const theme = useTheme();

  const approvedData = getTimesheetStatusValue(
    summaryData,
    StatusTimesheetSummary.Approved,
  );

  const submittedData = getTimesheetStatusValue(
    summaryData,
    StatusTimesheetSummary.Submitted,
  );

  const draftData = getTimesheetStatusValue(
    summaryData,
    StatusTimesheetSummary.Draft,
  );

  const tooltipId = "timesheetsStatus-tooltip";
  const tooltipOptions: TooltipLabelOption[] = [
    {
      color: TimesheetStatusColors.Approved,
      label: `Approved ${approvedData.percent.toFixed(2)}%`,
    },
    {
      color: TimesheetStatusColors.Submitted,
      label: `Submitted ${submittedData.percent.toFixed(2)}%`,
    },
    {
      color: TimesheetStatusColors.Pending,
      label: `Pending ${draftData.percent.toFixed(2)}%`,
    },
  ];

  const approvedLabel: TooltipLabelOption = {
    color: TimesheetStatusColors.Approved,
    label: `Approved ${approvedData.percent.toFixed(2)}%`,
  };

  const submittedLabel: TooltipLabelOption = {
    color: TimesheetStatusColors.Submitted,
    label: `Submitted ${submittedData.percent.toFixed(2)}%`,
  };

  const draftLabel: TooltipLabelOption = {
    color: TimesheetStatusColors.Pending,
    label: `Pending ${draftData.percent.toFixed(2)}%`,
  };

  useEffect(() => {
    const noTimesheets =
      approvedData.percent === 0 &&
      submittedData.percent === 0 &&
      draftData.percent === 0;

    new Chart(chartCanvasRef.current, {
      data: {
        datasets: [
          {
            backgroundColor: [
              noTimesheets
                ? theme.palette.grey[300]
                : TimesheetStatusColors.Approved,
              TimesheetStatusColors.Submitted,
              TimesheetStatusColors.Pending,
            ],
            borderWidth: 0,
            data: [
              noTimesheets ? 1 : approvedData.percent,
              submittedData.percent,
              draftData.percent,
            ],
          },
        ],
      },
      options: {
        animation: {
          animateRotate: true,
        },
        aspectRatio: 1,
        hover: {
          mode: undefined,
        },
        rotation: 0.25 * Math.PI,
        tooltips: {
          custom: (tooltipModel: ChartTooltipModel) => {
            const customTooltipHTMLString = renderToString(
              <PieChartTooltip options={tooltipOptions} />,
            );

            createCustomPieChartTooltip(
              tooltipModel,
              tooltipId,
              customTooltipHTMLString,
              chartCanvasRef,
            );
          },
          enabled: false,
        },
      },
      type: "pie",
    });
  }, [
    approvedData.percent,
    submittedData.percent,
    draftData.percent,
    theme.palette,
    tooltipOptions,
  ]);

  return (
    <Paper className={classes.container}>
      <Box width="100%" marginBottom="16px">
        <Typography className={classes.title} variant="h6">
          Timesheet Status
        </Typography>
      </Box>

      <Box className={classes.content}>
        <Box className={classes.chartContainer}>
          <canvas ref={chartCanvasRef} />
          <div id={tooltipId} />
        </Box>

        <Box className={classes.labelsContainer}>
          <LabelDescriptionItem itemData={approvedLabel} />
          <LabelDescriptionItem itemData={submittedLabel} />
          <LabelDescriptionItem itemData={draftLabel} />
        </Box>
      </Box>
    </Paper>
  );
}
