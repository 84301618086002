import { EmployeeModel } from "@dwo/shared/dist/models/employeeModel";
import { JobNoteModel } from "@dwo/shared/dist/models/jobNoteModel";
import {
  WorkShiftModel,
  WorkShiftType,
} from "@dwo/shared/dist/models/workShiftModel";
import { Box } from "@material-ui/core";
import { ClickableText } from "components/ClickableText";
import { TableDisplayDateLabel } from "components/employeeTimesheet/TableDisplayDateLabel";
import { TableSelect } from "components/employeeTimesheet/TableSelect";
import { TableTimePicker } from "components/employeeTimesheet/TableTimePicker";
import { TableTimePickerTimeOff } from "components/employeeTimesheet/TableTimePickerTimeOff";
import { TableSelectTimeOff } from "components/TableSelectTimeOff";
import { TextLink } from "components/TextLink";
import { findUpdatedValue } from "components/timesheetDetails/crewOverviewUtils";
import { DateTimeDiffLabel } from "components/timesheetDetails/DateTimeDiffLabel";
import { TableDisplayHoursTypeLabel } from "components/timesheetDetails/TableDisplayHoursTypeLabel";
import { format } from "date-fns";
import { WorkShiftTableCellValue } from "features/editWorkShiftTableSlice/workShiftTableValuesSlice";
import React from "react";
import * as routes from "routes/Roots";
import { addLocalTimeOffset } from "utils/dateUtils";
import { DEFAULT_EMPTY } from "utils/jobUtils";
import { MainTableCell, MainTableColumn, MainTableRow } from "utils/tableUtils";
import { TableComments } from "./TableComments";
import { decimalTimeToHoursTime } from "utils/dateUtils";
export interface EmployeeTimesheetsByDayTableData {
  columns: MainTableColumn[];
  footerRow?: MainTableRow;
  rows: MainTableRow[];
}

export const getDropDownOptions = () => [
  {
    label: "Holiday",
    value: WorkShiftType.HOLIDAY,
  },
  {
    label: "No Show",
    value: WorkShiftType.NO_SHOW,
  },
];

export const displayEmployeeTimesheetColumns: MainTableColumn[] = [
  {
    field: "jobId",
    headerName: "ID #",
    hasSorting: true,
  },
  {
    field: "address",
    headerName: "JOB ADDRESS",
    hasSorting: true,
  },
  {
    field: "supervisor",
    headerName: "SUPERVISOR",
    hasSorting: true,
  },
  {
    field: "foreman",
    headerName: "FOREMAN",
    hasSorting: true,
  },
  {
    field: "startTime",
    headerName: "START TIME",
  },
  {
    field: "endTime",
    headerName: "END TIME",
  },
  {
    field: "lunch",
    headerName: "LUNCH",
  },
  {
    field: "total",
    headerName: "TOTAL",
  },
  {
    field: "allComments",
    headerName: "allComments",
  },
  {
    field: "hoursType",
    headerName: "HOURS TYPE",
  },
];

export const editEmployeeTimesheetColumns = (
  formattedDate: string,
): MainTableColumn[] => [
  {
    field: "jobId",
    headerName: "ID #",
  },
  {
    field: "address",
    headerName: "JOB ADDRESS",
  },
  {
    field: "supervisor",
    headerName: "SUPERVISOR",
  },
  {
    field: "foreman",
    headerName: "FOREMAN",
  },
  {
    field: "startTime",
    headerName: `START DATE (${formattedDate})`,
  },
  {
    field: "endTime",
    headerName: `END DATE (${formattedDate})`,
  },
  {
    field: "lunch",
    headerName: "LUNCH",
  },
  {
    field: "total",
    headerName: "TOTAL",
  },
  {
    field: "comments",
    headerName: "COMMENTS",
  },
  {
    field: "hoursType",
    headerName: "HOURS TYPE",
  },
];

export const formatDateHeader = (date: Date) =>
  format(date, "MMM/dd").toUpperCase();

export const formatDateHeaderByDay = (date: Date) =>
  format(date, "EEE (MMM/dd)").toUpperCase();

export const getDatesBetweenDates = (startDate: Date, endDate: Date) => {
  const currentDate = new Date(startDate);
  const currentEndDate = new Date(endDate);
  const dates: Date[] = [];

  currentDate.setHours(0, 0, 0, 0);
  currentEndDate.setHours(0, 0, 0, 0);

  while (currentDate < currentEndDate) {
    dates.push(new Date(currentDate));
    currentDate.setDate(currentDate.getDate() + 1);
  }

  dates.push(new Date(currentEndDate));

  return dates;
};

export const getEmployeeTimesheetsByDaysTableData = (
  workshifts: WorkShiftModel[],
  startDate: Date,
  endDate: Date,
  onClickWorkshift: (workshift: WorkShiftModel) => void,
): EmployeeTimesheetsByDayTableData => {
  const dates: Date[] = getDatesBetweenDates(startDate, endDate);
  const hoursCounter: Map<string, number> = new Map();
  const jobWorkshifts: Map<number | string, WorkShiftModel[]> = new Map();
  const columns: MainTableColumn[] = dates.map((currentDate: Date) => {
    const dateHeader = formatDateHeaderByDay(currentDate);
    return {
      field: dateHeader,
      headerName: dateHeader,
    };
  });
  const rows: MainTableRow[] = [];

  columns.unshift({
    field: "foreman",
    hasSorting: true,
    headerName: "foreman",
  });

  workshifts.forEach((workshift: WorkShiftModel) => {
    const jobId = workshift.dwoJob?.job?.id;

    if (jobId) {
      const foundWorkshifts = jobWorkshifts.get(jobId);
      const updatedWorkShifts = foundWorkshifts
        ? [...foundWorkshifts, workshift]
        : [workshift];

      jobWorkshifts.set(jobId, updatedWorkShifts);
    }
  });

  jobWorkshifts.forEach((currentJobWorkshifts: WorkShiftModel[]) => {
    const innerCells: MainTableCell[] = columns.map(
      (column: MainTableColumn) => {
        if (column.field === "foreman") {
          const foreman = currentJobWorkshifts[0].dwoJob?.dwo?.foreman;

          return {
            value: foreman ? (
              <TextLink
                text={`${foreman.firstName} ${foreman.lastName}`}
                url={`${routes.ROOT_USER_MANAGEMENT}/${foreman.id as number}`}
              />
            ) : (
              ""
            ),
          };
        }

        const currentJobWorkshift = currentJobWorkshifts.find(
          (workshift: WorkShiftModel) => {
            const dwoDateString = workshift.dwoJob?.dwo?.date;

            if (!dwoDateString) return false;

            const dwoDate = addLocalTimeOffset(dwoDateString as string);

            return dwoDate
              ? column.headerName === formatDateHeaderByDay(dwoDate)
              : false;
          },
        );

        const foundHoursCounter = hoursCounter.get(column.field);

        if (!currentJobWorkshift) {
          hoursCounter.set(column.field, foundHoursCounter || 0);
          return { value: "" };
        }

        const currentHours = currentJobWorkshift.hours;
        const hoursString =
          currentHours.toString().length === 1
            ? `0${currentHours.toString()} Hrs`
            : `${currentHours}`;
        const updatedHours = foundHoursCounter
          ? foundHoursCounter + currentHours
          : currentHours;

        hoursCounter.set(column.field, updatedHours);

        const handleClickWorkshift = () =>
          onClickWorkshift(currentJobWorkshift);

        return {
          value: (
            <ClickableText
              linkText={`${hoursString}`}
              onClickLink={handleClickWorkshift}
            />
          ),
        };
      },
    );

    rows.push({ innerCells });
  });

  const footerCells: MainTableCell[] = [];

  hoursCounter.forEach((columnHours: number) => {
    const totalString =
      columnHours.toString().length === 1
        ? `0${columnHours.toString()} Hrs`
        : `${columnHours.toString()} Hrs`;

    footerCells.push({ value: totalString });
  });

  footerCells.unshift({ value: "" });

  return {
    columns,
    footerRow: { innerCells: footerCells },
    rows,
  };
};

export const getJobComments = (
  allJobsNotes: JobNoteModel[],
  jobId?: number,
) => {
  if (allJobsNotes.length === 0 || !jobId) return [];

  return allJobsNotes.filter((note: JobNoteModel) => note.jobId === jobId);
};

export const getJobsIds = (workshifts: WorkShiftModel[]) =>
  workshifts.reduce((ids: number[], workshift: WorkShiftModel) => {
    const currentJobId =
      typeof workshift.dwoJob?.job?.id === "string"
        ? parseInt(workshift.dwoJob?.job?.id, 10)
        : workshift.dwoJob?.job?.id;

    if (currentJobId && !ids.includes(currentJobId)) {
      return [...ids, currentJobId];
    }

    return ids;
  }, []);

export const getDisplayEmployeeTimesheetRows = (
  workShifts: WorkShiftModel[],
  currentEmployee: EmployeeModel,
  isLoadingComments: boolean,
  onClickCommentsLink: (jobId: number) => void,
  currentJobId?: number,
): MainTableRow[] =>
  workShifts.map((workShift) => {
    if (workShift && currentEmployee) {
      const jobId = {
        value: workShift.dwoJob?.job?.jobId ?? "",
        isLink: true,
        linkTo: `${routes.ROOT_JOB_MANAGEMENT}/${workShift.dwoJob?.job?.id}`,
      };
      const address = { value: workShift.dwoJob?.job?.address ?? "" };
      const supervisor = {
        value: workShift.dwoJob?.job?.supervisor ? (
          `${workShift.dwoJob?.job?.supervisor?.firstName} ${workShift.dwoJob?.job?.supervisor?.lastName}`
        ) : (
          <Box textAlign="center" width="100%">
            {DEFAULT_EMPTY}
          </Box>
        ),
        isLink: Boolean(workShift.dwoJob?.job?.supervisor),
        linkTo: workShift.dwoJob?.job?.supervisor
          ? `${routes.ROOT_USER_MANAGEMENT}/${workShift.dwoJob?.job?.supervisor?.id}`
          : undefined,
      };
      const foreman = {
        value: `${workShift.dwoJob?.dwo?.foreman?.firstName} ${workShift.dwoJob?.dwo?.foreman?.lastName}`,
        isLink: true,
        linkTo: `${routes.ROOT_USER_MANAGEMENT}/${workShift.dwoJob?.dwo?.foreman?.id}`,
      };
      const startTime = {
        value: (
          <TableDisplayDateLabel
            date={format(new Date(workShift.start), "hh:mm a")}
            locationType={workShift.startLocationType}
            location={workShift.startLocation}
          />
        ),
      };
      const endTime = {
        value: (
          <TableDisplayDateLabel
            date={format(new Date(workShift.end), "hh:mm a")}
            locationType={workShift.endLocationType}
            location={workShift.endLocation}
          />
        ),
      };
      const timeBreaks = workShift.timeBreaks as {
        lunch: number;
        pause: number;
        other: number;
      };
      const lunch = {
        value: decimalTimeToHoursTime(timeBreaks.lunch),
      };

      const total = { value: decimalTimeToHoursTime(workShift.hours) };
      const isCurrentJob = workShift.id === currentJobId;
      const workshiftsNotes = workShift.notes || [];
      const comments = {
        value: currentJobId ? (
          <TableComments
            currentJobId={workShift.id}
            displayedComment={workshiftsNotes[0]}
            isLoading={isCurrentJob ? isLoadingComments : false}
            onClickLink={onClickCommentsLink}
          />
        ) : (
          <TableComments
            currentJobId={workShift.id}
            displayedComment={workshiftsNotes[0]}
            isLoading={isLoadingComments}
            onClickLink={onClickCommentsLink}
          />
        ),
      };
      const hoursType = {
        value: <TableDisplayHoursTypeLabel hoursType={workShift.type} />,
      };

      const idRow = workShift.id ? workShift.id.toString() : "";
      const innerCells = [
        jobId,
        address,
        supervisor,
        foreman,
        startTime,
        endTime,
        lunch,
        total,
        comments,
        hoursType,
      ];

      return {
        idRow,
        innerCells,
      };
    }
    return {} as MainTableRow;
  });

export const getEditEmployeeTimesheetRows = (
  workShifts: WorkShiftModel[],
  currentEmployee: EmployeeModel,
  isEditModeActive: boolean,
  isTimeOffChecked: boolean,
  timeOffSelectedValue: string,
  values: WorkShiftTableCellValue[],
  isLoadingComments: boolean,
  onClickCommentsLink: (jobId: number) => void,
  currentJobId?: number,
): MainTableRow[] =>
  workShifts.map((workShift) => {
    if (workShift && currentEmployee) {
      const idRow = workShift.id ? workShift.id.toString() : "";
      const rowUpdatedValues = values.filter(
        (value) =>
          value.idRow === Number(idRow) &&
          (isEditModeActive || isTimeOffChecked) &&
          value.inputError === false,
      );
      const jobId = {
        value: workShift.dwoJob?.job?.jobId ?? "",
        isLink: true,
        linkTo: `${routes.ROOT_JOB_MANAGEMENT}/${workShift.dwoJob?.job?.id}`,
      };
      const address = { value: workShift.dwoJob?.job?.address ?? "" };
      const supervisor = {
        value: `${workShift.dwoJob?.job?.supervisor?.firstName} ${workShift.dwoJob?.job?.supervisor?.lastName}`,
        isLink: true,
        linkTo: `${routes.ROOT_USER_MANAGEMENT}/${workShift.dwoJob?.job?.supervisor?.id}`,
      };
      const foreman = {
        value: `${workShift.dwoJob?.dwo?.foreman?.firstName} ${workShift.dwoJob?.dwo?.foreman?.lastName}`,
        isLink: true,
        linkTo: `${routes.ROOT_USER_MANAGEMENT}/${workShift.dwoJob?.dwo?.foreman?.id}`,
      };
      const startTime = {
        value: isTimeOffChecked ? (
          <TableTimePickerTimeOff
            type="start"
            id={idRow}
            isTimeOffChecked={isTimeOffChecked}
          />
        ) : (
          <TableTimePicker
            type="start"
            id={idRow}
            date={workShift.start}
            isEditModeActive={isEditModeActive}
          />
        ),
      };
      const endTime = {
        value: isTimeOffChecked ? (
          <TableTimePickerTimeOff
            type="end"
            id={idRow}
            isTimeOffChecked={isTimeOffChecked}
          />
        ) : (
          <TableTimePicker
            type="end"
            id={idRow}
            date={workShift.end}
            isEditModeActive={isEditModeActive}
          />
        ),
      };
      const timeBreaks = workShift.timeBreaks as {
        lunch: number;
        pause: number;
        other: number;
      };
      const lunch = {
        value: decimalTimeToHoursTime(timeBreaks.lunch),
      };
      const getEditTotalLabel = () => {
        const updatedStartValue = findUpdatedValue(rowUpdatedValues, "start");
        const updatedEndValue = findUpdatedValue(rowUpdatedValues, "end");

        if (updatedStartValue && updatedEndValue) {
          return (
            <DateTimeDiffLabel
              start={updatedStartValue.inputValue}
              end={updatedEndValue.inputValue}
            />
          );
        }

        return (
          <Box textAlign="center" width="100%">
            -
          </Box>
        );
      };
      const total = { value: getEditTotalLabel() };
      const isCurrentJob = workShift.id === currentJobId;
      const workshiftsNotes = workShift.notes || [];
      const comments = {
        value: currentJobId ? (
          <TableComments
            currentJobId={workShift.id}
            displayedComment={workshiftsNotes[0]}
            isLoading={isCurrentJob ? isLoadingComments : false}
            onClickLink={onClickCommentsLink}
          />
        ) : (
          <TableComments
            currentJobId={workShift.id}
            displayedComment={workshiftsNotes[0]}
            isLoading={isLoadingComments}
            onClickLink={onClickCommentsLink}
          />
        ),
      };
      const hoursType = {
        value: isTimeOffChecked ? (
          <TableSelectTimeOff
            idRow={Number(idRow)}
            id="type"
            timeOffSelectedValue={timeOffSelectedValue}
            isTimeOffChecked={isTimeOffChecked}
          />
        ) : (
          <TableSelect
            isEditModeActive={isEditModeActive}
            id="type"
            type={workShift.type}
            idRow={Number(idRow)}
          />
        ),
      };

      const innerCells = [
        jobId,
        address,
        supervisor,
        foreman,
        startTime,
        endTime,
        lunch,
        total,
        comments,
        hoursType,
      ];

      return {
        idRow,
        innerCells,
      };
    }
    return {} as MainTableRow;
  });

export const getInitialDateRange = () => {
  const endDate = new Date();
  const startDate = new Date();

  endDate.setHours(23, 59, 59, 999);
  startDate.setHours(0, 0, 0, 0);

  return { endDate, startDate };
};
