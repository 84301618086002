import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { JobModel } from "@dwo/shared/dist/models/jobModel";
import React, { Fragment } from "react";
import { MAX_DISPLAYED_JOBS } from "utils/timesheetDetailsUtils";
import { v4 as uuid } from "uuid";
import { JobsTooltipTitle } from "./JobsTooltipTitle";

interface JobsAssignedProps {
  jobs: JobModel[];
  onClickJob: (jobId: number) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    headerValue: {
      color: theme.palette.primary.main,
      cursor: "pointer",
      textDecoration: "underline",
    },
    tagsContainer: {
      display: "flex",
      flexWrap: "wrap",
    },
    tooltip: {
      backgroundColor: theme.palette.grey[100],
      borderRadius: "2px",
      padding: "16px",
    },
  }),
);

export function JobsAssigned({ jobs, onClickJob }: JobsAssignedProps) {
  const classes = useStyles();

  const displayedJobs = jobs.reduce(
    (filteredJobs: JobModel[], currentJob: JobModel) => {
      const hasJob = filteredJobs.find(
        (job: JobModel) => job.id === currentJob.id,
      );

      return hasJob ? filteredJobs : [...filteredJobs, currentJob];
    },
    [],
  );

  const handleClickJob = (jobId: number) => () => onClickJob(jobId);

  if (displayedJobs.length === 0) {
    return <Fragment />;
  }

  if (displayedJobs.length <= MAX_DISPLAYED_JOBS) {
    return (
      <Box className={classes.tagsContainer}>
        {displayedJobs.map((job: JobModel, index: number) => (
          <Box
            key={`job-${job.id as number}-${uuid()}`}
            onClick={handleClickJob(job.id as number)}
            marginRight={index < displayedJobs.length ? "4px" : 0}
          >
            <Typography className={classes.headerValue} variant="body2">
              {`#${job.jobId as string}${
                index !== displayedJobs.length - 1 ? "," : ""
              }`}
            </Typography>
          </Box>
        ))}
      </Box>
    );
  }

  return (
    <Box className={classes.tagsContainer}>
      {displayedJobs.slice(0, MAX_DISPLAYED_JOBS).map((job: JobModel) => (
        <Box
          key={`job-${job.id as number}-${uuid()}`}
          marginRight="4px"
          onClick={handleClickJob(job.id as number)}
        >
          <Typography className={classes.headerValue} variant="body2">
            {`#${job.jobId as string},`}
          </Typography>
        </Box>
      ))}
      <Tooltip
        classes={{ tooltip: classes.tooltip }}
        interactive
        placement="bottom-end"
        title={
          <JobsTooltipTitle
            jobs={displayedJobs.slice(MAX_DISPLAYED_JOBS)}
            onClickJob={handleClickJob}
          />
        }
      >
        <Box key={`job-${jobs[MAX_DISPLAYED_JOBS].id as number}-${uuid()}`}>
          <Typography className={classes.headerValue} variant="body2">
            {`#${(jobs[MAX_DISPLAYED_JOBS].jobId as string).slice(0, 3)}...`}
          </Typography>
        </Box>
      </Tooltip>
    </Box>
  );
}
