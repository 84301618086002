import {
  Box,
  createStyles,
  makeStyles,
  Paper,
  Theme,
  Typography,
} from "@material-ui/core";
import { selectTimesheetsWorkforceData } from "features/summary/summarySlice";
import React from "react";
import { useSelector } from "react-redux";
import { TimesheetWorkforceInfoCard } from "./TimesheetWorkforceInfoCard";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chartsContainer: {
      alignItems: "center",
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-between",
      "& > div:nth-child(2)": {
        marginLeft: "24px",
        marginRight: "24px",
      },
      [theme.breakpoints.down("md")]: {
        justifyContent: "center",
      },
    },
    container: {
      display: "flex",
      flexDirection: "column",
      margin: "0 24px 24px 24px",
      minHeight: "268px",
      padding: "24px 32px 0 32px",
    },
    title: {
      color: theme.palette.primary.main,
    },
  }),
);

export function TimesheetWorkforceCard() {
  const summaryWorkforceData = useSelector(selectTimesheetsWorkforceData);
  const classes = useStyles();

  const approved = summaryWorkforceData?.approvedTimeOff || 0;
  const noShow = summaryWorkforceData?.noShow || 0;
  const trainng = summaryWorkforceData?.training || 0;
  const holiday = summaryWorkforceData?.holiday || 0;
  const rainday = summaryWorkforceData?.rainday || 0;
  const pto = summaryWorkforceData?.pto || 0;
  const totalEmployees =
    summaryWorkforceData?.employeeCounts.totalEmployees || 0;
  const employeesInRegion =
    summaryWorkforceData?.employeeCounts.employeesInRegion || 0;
  const totalForemen = summaryWorkforceData?.employeeCounts.totalForemen || 0;
  const foremenInRegion =
    summaryWorkforceData?.employeeCounts.foremenInRegion || 0;
  const totalWorkers = summaryWorkforceData?.employeeCounts.totalWorkers || 0;
  const workersInRegion =
    summaryWorkforceData?.employeeCounts.workersInRegion || 0;

  return (
    <Paper className={classes.container}>
      <Box width="100%" marginBottom="32px">
        <Typography className={classes.title} variant="h6">
          Workforce
        </Typography>
      </Box>

      <Box className={classes.chartsContainer}>
        <TimesheetWorkforceInfoCard
          header="Approved Time Off"
          data={approved}
        />
        <TimesheetWorkforceInfoCard header="No Show" data={noShow} />
        <TimesheetWorkforceInfoCard header="Training" data={trainng} />
        <TimesheetWorkforceInfoCard header="Rain Day" data={rainday} />
        <TimesheetWorkforceInfoCard header="Holiday" data={holiday} />
        <TimesheetWorkforceInfoCard header="PTO" data={pto} />

        <TimesheetWorkforceInfoCard
          header="Employees count"
          inRegionData={{ total: totalEmployees, inRegion: employeesInRegion }}
        />
        <TimesheetWorkforceInfoCard
          header="Number of Laborers"
          inRegionData={{ total: totalWorkers, inRegion: workersInRegion }}
        />
        <TimesheetWorkforceInfoCard
          header="Number of Foreman"
          inRegionData={{ total: totalForemen, inRegion: foremenInRegion }}
        />
      </Box>
    </Paper>
  );
}
