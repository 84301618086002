import { EmployeeModel } from "@dwo/shared/dist/models/employeeModel";
import { ServiceOptions } from "@dwo/shared/dist/services/baseService";
import { employeePictureService } from "@dwo/shared/dist/services/employeePictureService";
import { employeeService } from "@dwo/shared/dist/services/employeeService";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, RootState } from "app/store";
import { error } from "features/error/errorSlice";
import { ReqStatus } from "utils/sharedUtils";

const SUPERVISORS_STORAGE = "supervisorsSelected";
const localStorage = window.localStorage;

const getSupervisorsFromStorage = () => {
  let supervisors = [] as EmployeeModel[];

  try {
    const supervisorsStored = localStorage.getItem(SUPERVISORS_STORAGE);

    if (supervisorsStored) {
      supervisors = JSON.parse(supervisorsStored) as EmployeeModel[];
    }
  } catch (err) {
    console.error("Could not get supervisors from local storage", err);
  }

  return supervisors;
};

interface SupervisorsState {
  employeesReqStatus: ReqStatus;
  loading: boolean;
  supervisors: EmployeeModel[];
  activeSupervisors: EmployeeModel[];
  isFilterActive: boolean;
  filterStatus?: string;
}

const initialState: SupervisorsState = {
  employeesReqStatus: ReqStatus.IDLE,
  loading: false,
  supervisors: [],
  activeSupervisors: getSupervisorsFromStorage(),
  isFilterActive: getSupervisorsFromStorage().length > 0,
  filterStatus: undefined,
};

export const supervisorsSlice = createSlice({
  name: "supervisors",
  initialState,
  reducers: {
    setSupervisors: (state, action: PayloadAction<EmployeeModel[]>) => {
      state.supervisors = action.payload;
    },
    addActiveSupervisors: (state, action: PayloadAction<EmployeeModel[]>) => {
      state.activeSupervisors = [...state.activeSupervisors, ...action.payload];
      const supervisorsString = JSON.stringify(state.activeSupervisors);
      localStorage.setItem(SUPERVISORS_STORAGE, supervisorsString);
    },
    clearActiveSupervisors: (state) => {
      state.activeSupervisors = [];
      localStorage.removeItem(SUPERVISORS_STORAGE);
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setIsFilterActive: (state, action: PayloadAction<boolean>) => {
      state.isFilterActive = action.payload;
    },
    setEmployeesReqStatus: (state, action: PayloadAction<ReqStatus>) => {
      state.employeesReqStatus = action.payload;
    },
    setFilterStatus: (state, action: PayloadAction<string | undefined>) => {
      state.filterStatus = action.payload;
    },
  },
});

export const {
  setSupervisors,
  setLoading,
  setIsFilterActive,
  addActiveSupervisors,
  clearActiveSupervisors,
  setEmployeesReqStatus,
  setFilterStatus,
} = supervisorsSlice.actions;

export const getAllSupervisorsWithImage = (
  options?: ServiceOptions,
): AppThunk => async (dispatch) => {
  try {
    dispatch(setLoading(true));
    dispatch(setEmployeesReqStatus(ReqStatus.PENDING));

    const { data } = await employeeService.getAllSupervisors(options);

    const idList = data.map(({ id }) => id);

    const { data: imageData } = await employeePictureService.getAll({
      where: { employeeId: { $in: idList } },
      include: ["Picture"],
    });

    const dataWithImage = data.map((employeeToUpdate) => {
      const imageFound = imageData.find(
        (image) => image.employeeId === employeeToUpdate.id,
      );
      if (imageFound) {
        employeeToUpdate.employeePictures = [imageFound];
      }
      return employeeToUpdate;
    });

    dispatch(setSupervisors(dataWithImage));
    dispatch(setEmployeesReqStatus(ReqStatus.FULFILLED));
  } catch (e) {
    dispatch(setEmployeesReqStatus(ReqStatus.FILED));
    dispatch(
      error(
        {
          title: "Could not retrieve employees",
          message: e.message,
        },
        () => dispatch(getAllSupervisorsWithImage()),
      ),
    );
  } finally {
    dispatch(setLoading(false));
  }
};

export const addActiveSupervisorsWithStatus = (
  activeSupervisors: EmployeeModel[],
): AppThunk => async (dispatch) => {
  try {
    dispatch(setFilterStatus("loading"));
    // This await is necessary don't remove it again
    await dispatch(addActiveSupervisors(activeSupervisors));
    dispatch(setIsFilterActive(true));
  } finally {
    dispatch(setFilterStatus(undefined));
  }
};

export const clearActiveSupervisorsWithStatus = (): AppThunk => async (
  dispatch,
) => {
  try {
    dispatch(setFilterStatus("loading"));
    // This await is necessary don't remove it again
    await dispatch(clearActiveSupervisors());
    dispatch(setIsFilterActive(false));
  } finally {
    dispatch(setFilterStatus(undefined));
  }
};

export const selectSupervisors = (state: RootState) =>
  state.supervisors.supervisors;
export const selectActiveSupervisors = (state: RootState) =>
  state.supervisors.activeSupervisors;
export const selectIsFilterActive = (state: RootState) =>
  state.supervisors.isFilterActive;
export const selectLoading = (state: RootState) => state.supervisors.loading;
export const selectEmployeesReqStatus = (state: RootState) =>
  state.supervisors.employeesReqStatus;
export const selectFilterStatus = (state: RootState) =>
  state.supervisors.filterStatus;

export default supervisorsSlice.reducer;
