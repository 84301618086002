import { EmployeeModel } from "@dwo/shared/dist/models/employeeModel";
import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import React from "react";
import { v4 as uuid } from "uuid";

interface StyleProps {
  dashedList?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tooltipText: {
      color: ({ dashedList }: StyleProps) =>
        dashedList ? theme.palette.primary.dark : theme.palette.grey[700],
      cursor: "pointer",
      fontWeight: 600,
    },
  }),
);

interface SupervisorsTooltipTitleProps {
  supervisors: EmployeeModel[];
  dashedList?: boolean;
  onClickSupervisor: (supervisorId: number) => void;
}

export function SupervisorsTooltipTitle({
  supervisors,
  dashedList,
  onClickSupervisor,
}: SupervisorsTooltipTitleProps) {
  const classes = useStyles({ dashedList });

  const handleClickSupervisor = (supervisorId: number) => () =>
    onClickSupervisor(supervisorId);

  return (
    <Box
      alignItems={dashedList ? "start" : "center"}
      display="flex"
      flexDirection="column"
      justifyContent="center"
    >
      {supervisors.map((supervisor: EmployeeModel, index: number) => (
        <Box
          key={`supervisor-${supervisor.id as number}-${uuid()}-tooltip`}
          marginBottom={index < supervisors.length ? "4px" : 0}
          onClick={handleClickSupervisor(supervisor.id as number)}
        >
          <Typography className={classes.tooltipText} variant="body2">
            {dashedList && "- "}
            {supervisor.fullName}
          </Typography>
        </Box>
      ))}
    </Box>
  );
}
