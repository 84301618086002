import {
  createStyles,
  IconButton,
  makeStyles,
  SvgIconProps,
  Theme,
} from "@material-ui/core";
import { DeleteSharp } from "@material-ui/icons";
import React from "react";

interface StyledDeleteIconProps {
  height: string;
  width: string;
}

interface DeleteIconButtonProps {
  height?: string;
  idToDelete: string;
  width?: string;
  onClickDelete: (id: string) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.primary.main,
      color: "white",
      height: ({ height }: StyledDeleteIconProps) => height,
      padding: "4px",
      width: ({ width }: StyledDeleteIconProps) => width,
      "&:hover": {
        backgroundColor: theme.palette.primary.main,
      },
    },
  }),
);

export function DeleteIconButton({
  height = "28px",
  idToDelete,
  width = "28px",
  onClickDelete,
  ...props
}: DeleteIconButtonProps & SvgIconProps) {
  const classes = useStyles({ height, width });

  const handleClickDelete = (id: string) => () => {
    onClickDelete(id);
  };

  return (
    <IconButton
      aria-label="Delete"
      classes={{
        root: classes.root,
      }}
      onClick={handleClickDelete(idToDelete)}
    >
      <DeleteSharp fontSize="small" {...props} />
    </IconButton>
  );
}
