import React, { useEffect, useState } from "react";
import {
  Box,
  createStyles,
  makeStyles,
  Paper,
  Theme,
  Typography,
  Grid,
} from "@material-ui/core";
import { ForemanCrewCard } from "components/summary/DailyRoster/ForemanCrewCard";
import { LoadingSpinner } from "components/LoadingSpinner";
import { DailyRosterModel } from "@dwo/shared/src/models/dailyRosterModel";
import { dailyRosterService } from "@dwo/shared/dist/services/dailyRosterService";

import { DEFAULT_LIMIT } from "utils/sharedUtils";
import { rowSelectOptions } from "utils/tableUtils";
import { DropdownPagination } from "components/DropdownPagination";
import { CustomPagination as Pagination } from "components/Pagination";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardsContainer: {
      // display: "flex",
      margin: "0 auto",
      maxWidth: "1576px",
      width: "100%",
    },
    container: {
      display: "flex",
      flexDirection: "column",
      margin: "0 24px 24px 24px",
      padding: "24px 32px 0 32px",
    },
    title: {
      color: theme.palette.primary.main,
    },
    clickable: {
      cursor: "pointer",
    },
    jobsContainer: {
      margin: "0 24px 24px 24px",
    },
    rowSelectLabel: {
      color: theme.palette.primary.dark,
      fontWeight: "bold",
    },
  }),
);

interface DailyRosterProps {
  rosterDate?: string | Date;
  rosterRegionId: string | number;
}

export function DailyRoster({
  rosterDate: date,
  rosterRegionId: region,
}: DailyRosterProps) {
  const classes = useStyles();
  const [roster, setRoster] = useState([]);
  const [isEmptyRoster, setIsEmptyRoster] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isCurrentCount, setCurrentCount] = useState<number>(0);
  const [isOffset, setOffset] = useState<number>(0);
  const [limit, setLimit] = useState(DEFAULT_LIMIT);
  const [page, setPage] = useState<number>(1);
  const [isRowsPerPage, setRowsPerPage] = useState<string>(
    rowSelectOptions[0].value,
  );

  const getRoster = async () => {
    try {
      setIsLoading(true);
      const rosterReponse = await dailyRosterService.getRoster(
        region,
        date,
        parseFloat(isRowsPerPage),
        isOffset,
      );
      if (rosterReponse?.data?.data?.roster.length !== 0 && date !== "") {
        setIsEmptyRoster(false);
        setCurrentCount(rosterReponse?.data?.count);
        setRoster(rosterReponse?.data?.data?.roster ?? []);
        setLimit(rosterReponse?.data?.limit ?? 1);
      } else {
        setIsEmptyRoster(true);
      }
      setIsLoading(false);
      return;
    } catch (error) {
      console.error(error);
    }
  };

  const getTotalPages = () => {
    const pages = Math.ceil(isCurrentCount / limit);
    if (pages >= 1) {
      return pages;
    }
    return 1;
  };

  const handleCurrentOffset = () => {
    if (page === 1) return;
    else {
      setOffset(parseFloat(isRowsPerPage) * page);
    }
    return;
  };

  const handleChangePage = (newPage: number) => {
    setOffset((newPage - 1) * parseFloat(isRowsPerPage));
    setPage(newPage);
  };

  const handleSelectRowsPerPage = (selectedValue: string) => {
    setPage(1);
    setOffset(0);
    setRowsPerPage(selectedValue);
  };

  useEffect(() => {
    handleCurrentOffset();
    getRoster();
  }, [date, page, region, isRowsPerPage]);

  return (
    <Paper className={classes.container}>
      <Box width="100%" marginBottom="24px">
        <Typography className={classes.title} variant="h6">
          {isEmptyRoster ? "No Roster for Today" : "Roster for Today"}
        </Typography>
      </Box>

      <Box className={classes.cardsContainer}>
        {!isEmptyRoster && isLoading ? (
          <LoadingSpinner />
        ) : (
          roster?.map((crew: DailyRosterModel) => (
            <Box flex={1} margin="0 24px 24px 0" width="100%" key={crew.jobId}>
              <ForemanCrewCard
                crew={crew.crew}
                foremanName={crew.foremanName}
                jobId={crew.jobId}
                jobPk={crew.jobPk}
                supervisorName={crew.supervisorName}
              />
            </Box>
          ))
        )}
      </Box>

      <Box padding="24px">
        <Grid alignItems="center" container spacing={4}>
          <Grid item xs={4} />
          <Grid item xs={4}>
            <Box display="flex" justifyContent="center">
              <Pagination
                id="usersManagement-pagination"
                isDisabled={isLoading || isCurrentCount === 0}
                page={page}
                totalPages={getTotalPages()}
                onChangePage={handleChangePage}
              />
            </Box>
          </Grid>

          <Grid item xs={4}>
            <Box display="flex" alignItems="center" justifyContent="flex-end">
              <Box marginRight="8px">
                <Typography
                  variant="caption"
                  className={classes.rowSelectLabel}
                >
                  Rows per page:
                </Typography>
              </Box>
              <DropdownPagination
                id="rowsDropdown"
                isDisabled={isLoading || isCurrentCount === 0}
                options={rowSelectOptions}
                selectedValue={isRowsPerPage}
                onSelectOption={handleSelectRowsPerPage}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
}
