import {
  Action,
  configureStore,
  getDefaultMiddleware,
  ThunkAction,
} from "@reduxjs/toolkit";
import crewReducer from "features/crew/crewSlice";
import dwoReducer from "features/dwo/dwoSlice";
import workShiftTableValuesReducer from "features/editWorkShiftTableSlice/workShiftTableValuesSlice";
import employeeCrewReducer from "features/employee_crew/employeeCrewSlice";
import employeesReducer from "features/employees/employeesSlice";
import employeeTimesheetReducer from "features/employeeTimesheet/employeeTimesheetSlice";
import { errorReducer } from "features/error/errorSlice";
import jobExpensesReducer from "features/jobs/jobExpenses/jobExpensesSlice";
import jobNotesReducer from "features/jobs/jobNotes/jobNotesSlice";
import jobsReducer from "features/jobs/jobsSlice";
import loaderReducer from "features/loader/loaderSlice";
import { sessionReducer } from "features/logIn/sessionSlice";
import promptReducer from "features/prompt/promptSlice";
import regionReducer from "features/region/regionSlice";
import supervisorsReducer from "features/supervisors/supervisorsSlice";
import toastReducer from "features/toast/toastSlice";
import { uploadEmployeeReducer } from "features/upload/uploadEmployeeSlice";
import vehiclesReducer from "features/vehicles/vehiclesSlice";
import { summaryReducer } from "features/summary/summarySlice";
import notificationReducer from "features/notifications/notificationSlice";
import { downloadTimesheetsReducer } from "features/downloadTimesheets/downloadTimesheetsSlice";
import { downloadJobsReducer } from "features/downloadJobs/downloadJobsSlice";
import { dailyRosterReducer } from "features/dailyRoster/dailyRosterSlice";
import { phaseCodeReducer } from "features/phaseCode/phaseCodeSlice";

export const store = configureStore({
  reducer: {
    uploadEmployee: uploadEmployeeReducer,
    crew: crewReducer,
    dwo: dwoReducer,
    workShiftTableValues: workShiftTableValuesReducer,
    employeeCrew: employeeCrewReducer,
    employeeTimesheet: employeeTimesheetReducer,
    employees: employeesReducer,
    error: errorReducer,
    jobExpenses: jobExpensesReducer,
    jobNotes: jobNotesReducer,
    jobs: jobsReducer,
    loader: loaderReducer,
    prompt: promptReducer,
    region: regionReducer,
    session: sessionReducer,
    toast: toastReducer,
    vehicles: vehiclesReducer,
    supervisors: supervisorsReducer,
    summary: summaryReducer,
    notification: notificationReducer,
    downloadTimesheets: downloadTimesheetsReducer,
    downloadJobs: downloadJobsReducer,
    dailyRoster: dailyRosterReducer,
    phaseCode: phaseCodeReducer,
  },
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [
        "dwo/setCrewFilters",
        "dwo/setCrewQuery",
        "dwo/setEmployeeFilters",
        "dwo/setEmployeeQuery",
      ],
      ignoredPaths: [
        "dwo.crewFilters",
        "dwo.crewQuery",
        "dwo.employeeFilters",
        "dwo.employeeQuery",
      ],
    },
  }),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
