import { Error } from "features/error/Error";
import { Loader } from "features/loader/Loader";
import {
  initSession,
  selectIsSessionConfirmed,
  selectLogInStatus,
} from "features/logIn/sessionSlice";
import { Prompt } from "features/prompt/Prompt";
import { selectFilterStatus } from "features/supervisors/supervisorsSlice";
import { Toast } from "features/toast/Toast";
import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch } from "react-router-dom";
import { JobRouter } from "routes/JobRouter";
import { ProtectedRoute } from "routes/ProtectedRoute";
import * as routes from "routes/Roots";
import { LOGIN } from "routes/Session";
import { TimesheetsRouter } from "routes/TimesheetsRouter";
import { UploadRouter } from "routes/UploadRouter";
import { UsersRouter } from "routes/UsersRouter";
import { AppChangelogRouter } from "routes/AppChangelogRouter";
import { LoginScreen } from "screens/LoginScreen";
import { NotificationsScreen } from "screens/NotificationsScreen";
import { NotFoundScreen } from "screens/NotFoundScreen";
import { SummaryScreen } from "screens/SummaryScreen";

function App() {
  const dispatch = useDispatch();
  const hasSessionBeenChecked = useSelector(selectIsSessionConfirmed);
  const sessionStatus = useSelector(selectLogInStatus);
  const filterStatus = useSelector(selectFilterStatus);

  useEffect(() => {
    if (!hasSessionBeenChecked) {
      dispatch(initSession());
    }
  }, [hasSessionBeenChecked, dispatch]);

  if (sessionStatus === "initial") {
    // TODO: Styled spiner
    return <Fragment />;
  }
  // needed to send requests with header after applying supervisors filter
  if (filterStatus === "loading") {
    return <Fragment />;
  }

  return (
    <React.Fragment>
      <Switch>
        <Route component={LoginScreen} path={LOGIN} />
        <ProtectedRoute
          component={SummaryScreen}
          exact
          path={routes.ROOT_HOME}
        />
        <ProtectedRoute
          component={TimesheetsRouter}
          path={routes.ROOT_TIMESHEETS_MANAGEMENT}
        />
        <ProtectedRoute
          component={JobRouter}
          path={routes.ROOT_JOB_MANAGEMENT}
        />
        <ProtectedRoute
          component={UsersRouter}
          path={routes.ROOT_USER_MANAGEMENT}
        />
        <ProtectedRoute
          component={AppChangelogRouter}
          path={routes.ROOT_APP_CHANGELOG}
        />
        <ProtectedRoute
          component={NotificationsScreen}
          path={routes.ROOT_NOTIFICATIONS}
        />
        <ProtectedRoute component={UploadRouter} path={routes.ROOT_UPLOAD} />
        <ProtectedRoute component={NotFoundScreen} />
      </Switch>
      <Prompt />
      <Loader />
      <Toast />
      <Error />
    </React.Fragment>
  );
}

export default App;
