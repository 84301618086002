import {
  Box,
  Button,
  createStyles,
  makeStyles,
  Theme,
} from "@material-ui/core";
import { GlobalModal } from "components/globalModal/GlobalModal";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TempWorkshiftModel } from "@dwo/shared/dist/models/tempWorkshiftModel";
import { TempWorkshiftValues } from "utils/timesheetDetailsUtils";
import { WorkShiftType } from "@dwo/shared/dist/models/workShiftModel";
import { TempWorkshiftsForm } from "./TempWorkshiftsForm";
import { DWOJobModel } from "@dwo/shared/dist/models/DWOJobModel";
import {
  createTempWorkshift,
  selectCurrentDWOJob,
  selectDWOCrewDetailsByJobId,
  updateTempWorkshift,
} from "features/dwo/dwoSlice";
import { enDashFormatDate } from "utils/dateUtils";

interface TempWorkshiftsModalProps {
  isOpen: boolean;
  isEdit?: boolean;
  initialWorkshiftValue?: TempWorkshiftModel;
  onClickClose: VoidFunction;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      color: theme.palette.primary.main,
      marginBottom: "8px",
    },
    addInvoicesButton: {
      width: "100%",
      marginTop: "13.5px",
    },
    addNewInvoicesButton: {
      width: "100%",
    },
    divider: {
      width: "100%",
      marginTop: "13.5px",
    },
    deleteLink: {
      color: theme.palette.primary.light,
      textDecoration: "underline",
      "&:hover": {
        cursor: "pointer",
      },
    },
  }),
);

const getWorkshiftEmptyValue = (): TempWorkshiftValues => ({
  employeeName: "",
  end: new Date().toISOString(),
  start: new Date().toISOString(),
  type: WorkShiftType.NORMAL,
});

export function TempWorkshiftsModal({
  isEdit = false,
  isOpen,
  initialWorkshiftValue,
  onClickClose,
}: TempWorkshiftsModalProps) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const currentDWOJob: DWOJobModel | undefined = useSelector(
    selectCurrentDWOJob,
  );
  const dwoCrewDetailsByJobId = useSelector(selectDWOCrewDetailsByJobId);
  const [hasFormErrors, setHasFormErrors] = useState(true);
  const [workshiftValues, setWorkshiftValues] = useState<TempWorkshiftValues>(
    getWorkshiftEmptyValue(),
  );

  const handleFormikValues = (value: TempWorkshiftValues) => {
    setWorkshiftValues(value);
  };

  useEffect(() => {
    if (isOpen && isEdit && initialWorkshiftValue) {
      setWorkshiftValues(initialWorkshiftValue);
    }

    if (isOpen && !isEdit) {
      setWorkshiftValues(getWorkshiftEmptyValue());
    }
  }, [isOpen, isEdit, initialWorkshiftValue]);

  useEffect(() => {
    setHasFormErrors(
      workshiftValues.error === undefined || workshiftValues.error,
    );
  }, [workshiftValues]);

  const handleClickSave = () => {
    const dwoDate = dwoCrewDetailsByJobId
      ? (dwoCrewDetailsByJobId.date as string)
      : enDashFormatDate(new Date());
    const ogStart = new Date(workshiftValues.start).toISOString();
    const ogEnd = new Date(workshiftValues.end).toISOString();
    const startParts = ogStart.split("T");
    const endParts = ogEnd.split("T");
    const startDate = `${dwoDate}T${startParts[1]}`;
    const endDate = `${dwoDate}T${endParts[1]}`;

    if (isEdit) {
      const editedTempWorkshift: TempWorkshiftModel = {
        id: initialWorkshiftValue?.id,
        date: initialWorkshiftValue?.date as string,
        dwoJobId: initialWorkshiftValue?.dwoJobId as number,
        employeeName: workshiftValues.employeeName,
        end: endDate,
        start: startDate,
        type: workshiftValues.type,
      };
      dispatch(updateTempWorkshift(editedTempWorkshift));
      onClickClose();
      return;
    }

    if (currentDWOJob && dwoCrewDetailsByJobId) {
      const tempWorkshift: TempWorkshiftModel = {
        date: dwoCrewDetailsByJobId.date as string,
        dwoJobId: currentDWOJob.id as number,
        employeeName: workshiftValues.employeeName,
        end: endDate,
        start: startDate,
        type: workshiftValues.type,
      };
      dispatch(createTempWorkshift(tempWorkshift));
      onClickClose();
    }
  };

  return (
    <GlobalModal
      customFooter={
        <Box display="flex" flexDirection="column" padding="16px 24px 24px">
          <Button
            className={classes.addInvoicesButton}
            disabled={hasFormErrors}
            variant="contained"
            onClick={handleClickSave}
          >
            Save
          </Button>
        </Box>
      }
      open={isOpen}
      title={isEdit ? "Edit Workshift" : "Create Workshift"}
      showActionButtons={false}
      onClickClose={onClickClose}
    >
      <Box padding="2px 24px 0px">
        <TempWorkshiftsForm
          initialValue={workshiftValues}
          handleFormikValues={handleFormikValues}
        />
      </Box>
    </GlobalModal>
  );
}
