import React from "react";
import { Box, Typography, IconButton, useTheme } from "@material-ui/core";
import { mainTableStyles } from "utils/tableUtils";
import { SortArrowUp, SortArrowDown, SortArrows } from "components/icons";
import { jobHeadersStyles } from "utils/jobUtils";
import { cloneDeep } from "lodash";

interface SortHeadersProps {
  sortingOptions?: string[][];
  onClickSort: (sortingValues: string[][]) => void;
}

export function SortHeaders({ sortingOptions, onClickSort }: SortHeadersProps) {
  const theme = useTheme();
  const tableStyles = mainTableStyles();
  const classes = jobHeadersStyles();

  const handleClickSort = (field: string) => () => {
    if (!sortingOptions || sortingOptions.length === 0) {
      onClickSort([[field, "ASC"]]);
      return;
    }

    const sortingIndex = sortingOptions.findIndex(
      ([currentField]) => currentField === field,
    );

    const sortingOptionsUpdated = cloneDeep(sortingOptions);
    if (sortingIndex >= 0 && sortingOptions[sortingIndex][1] === "ASC") {
      sortingOptionsUpdated[sortingIndex][1] = "DESC";
      onClickSort(sortingOptionsUpdated);
      return;
    }

    sortingOptionsUpdated.splice(sortingIndex, 1);
    onClickSort(sortingOptionsUpdated);
  };

  const getSortIcon = (field: string) => {
    if (sortingOptions) {
      const sortValue = sortingOptions.find(
        ([currentField]) => currentField === field,
      );
      if (sortValue && sortValue[1] === "DESC") {
        return (
          <SortArrowDown color={theme.palette.primary.main} fontSize={18} />
        );
      }

      if (sortValue && sortValue[1] === "ASC") {
        return <SortArrowUp color={theme.palette.primary.main} fontSize={18} />;
      }
    }
    return <SortArrows fontSize={18} />;
  };

  return (
    <Box display="flex" alignItems="center">
      <Box display="flex" alignItems="center" className={classes.idHeader}>
        <Typography variant="subtitle2" className={tableStyles.title}>
          ID#
        </Typography>
        <IconButton
          className={tableStyles.iconButton}
          onClick={handleClickSort("jobId")}
        >
          {getSortIcon("jobId")}
        </IconButton>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        className={classes.workOrderNumber}
      >
        <Typography variant="subtitle2" className={tableStyles.title}>
          wo#
        </Typography>
      </Box>
      <Box display="flex" alignItems="center" className={classes.addressHeader}>
        <Typography variant="subtitle2" className={tableStyles.title}>
          job address
        </Typography>
      </Box>
      <Box display="flex" alignItems="center" className={classes.typeHeader}>
        <Typography variant="subtitle2" className={tableStyles.title}>
          type of job
        </Typography>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        className={classes.supervisorheader}
      >
        <Typography variant="subtitle2" className={tableStyles.title}>
          supervisor
        </Typography>
      </Box>
      <Box display="flex" alignItems="center" className={classes.foremanHeader}>
        <Typography variant="subtitle2" className={tableStyles.title}>
          foreman
        </Typography>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        className={classes.startDateHeader}
      >
        <Typography variant="subtitle2" className={tableStyles.title}>
          start date
        </Typography>
        <IconButton
          className={tableStyles.iconButton}
          onClick={handleClickSort("startDate")}
        >
          {getSortIcon("startDate")}
        </IconButton>
      </Box>
      <Box display="flex" alignItems="center" className={classes.endDateHeader}>
        <Typography variant="subtitle2" className={tableStyles.title}>
          end date
        </Typography>
        <IconButton
          className={tableStyles.iconButton}
          onClick={handleClickSort("endDate")}
        >
          {getSortIcon("endDate")}
        </IconButton>
      </Box>
      <Box display="flex" alignItems="center" className={classes.hoursHeader}>
        <Typography variant="subtitle2" className={tableStyles.title}>
          hours
        </Typography>
        <IconButton
          className={tableStyles.iconButton}
          onClick={handleClickSort("currentHours")}
        >
          {getSortIcon("currentHours")}
        </IconButton>
      </Box>
    </Box>
  );
}
