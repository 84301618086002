import React from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { LOGIN } from "./Session";
import { useSelector } from "react-redux";
import { selectIsAuth } from "features/logIn/sessionSlice";

export const ProtectedRoute = (props: RouteProps) => {
  const isAuthenticated = useSelector(selectIsAuth);

  return isAuthenticated ? <Route {...props} /> : <Redirect to={LOGIN} />;
};
