import { createStyles, makeStyles, Theme, Typography } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    errorStringCell: {
      color: theme.palette.error.main,
      width: "max-content",
    },
  }),
);

interface ErrorCellProps {
  text: string;
}

export function ErrorCell({ text }: ErrorCellProps) {
  const classes = useStyles();
  return (
    <Typography className={classes.errorStringCell} variant="body2">
      {text}
    </Typography>
  );
}
