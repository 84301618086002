import { EmployeePictureModel } from "@dwo/shared/dist/models/employeePictureModel";
import {
  Box,
  Button,
  createStyles,
  IconButton,
  makeStyles,
  Modal,
  Theme,
  Typography,
} from "@material-ui/core";
import { ArrowBackIos, ArrowForwardIos, Cancel } from "@material-ui/icons";
import { RenderArrowProps } from "components/userManagement/UserDetails/UploadUserImagesModal";
import {
  deleteUserImage,
  selectEmployeePictures,
} from "features/employees/employeesSlice";
import React, { Fragment, useState } from "react";
import Carousel from "react-elastic-carousel";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

interface DeleteUserImagesModalProps {
  open: boolean;
  uploadedPictures: EmployeePictureModel[];
  onClickClose: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      fontSize: "32px",
      color: theme.palette.primary.main,
      fontWeight: 600,
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
    },
    container: {
      backgroundColor: "white",
      outline: "0",
      width: "570px",
      [theme.breakpoints.down("xs")]: {
        width: "85%",
      },
    },
    carouselContainer: {
      width: "570px",
      [theme.breakpoints.down("xs")]: {
        width: "85%",
      },
      backgroundColor: "white",
      display: "flex",
      justifyContent: "center",
    },
    secondaryButton: {
      color: theme.palette.primary.main,
      fontSize: "28px",
    },
    carousel: {
      "& .rec.rec-slider-container": {
        width: "395px",
      },
    },
    carouselImage: {
      width: "75px",
      height: "75px",
      objectFit: "cover",
    },
    selectedImage: {
      width: "372px",
      maxHeight: "372px",
      objectFit: "cover",
    },
    imageContainer: {
      outline: "none",
    },
    fileName: {
      marginLeft: "100px",
      color: theme.palette.error.main,
      fontSize: "14px",
      marginBottom: "8px",
    },
  }),
);

export function DeleteUserImagesModal({
  open,
  uploadedPictures,
  onClickClose,
}: DeleteUserImagesModalProps) {
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const userId = parseInt(id, 10);
  const employeePictures = useSelector(selectEmployeePictures);
  const [selectedSrc, setSelectedSrc] = useState("");
  const [selectedPath, setSelectedPath] = useState("");
  const [selectedFileName, setSelectedFileName] = useState("");
  const classes = useStyles();
  const mainPicture = uploadedPictures.length > 0 && uploadedPictures[0];
  const getAllEmployeePicturesQuery = {
    include: ["Picture"],
    where: { employeeId: userId },
  };

  const getSelectedFileName = () => {
    if (selectedFileName) {
      return selectedFileName;
    }
    return mainPicture && mainPicture.picture.fileName;
  };

  const getSelectedImage = () => {
    if (selectedSrc) {
      return selectedSrc;
    }
    return mainPicture ? mainPicture.picture.url : "";
  };

  const getPictureToDelete = () =>
    uploadedPictures.find(
      (employeePicture) =>
        employeePicture.picture.fileName === getSelectedFileName() &&
        employeePicture.picture.url === getSelectedImage(),
    );

  const getCarouselImages = () => {
    if (selectedSrc && selectedPath) {
      return uploadedPictures.filter(
        (employeePicture) =>
          employeePicture.picture.url !== selectedSrc &&
          employeePicture.picture.path !== selectedPath,
      );
    }
    return uploadedPictures.slice(1);
  };

  const handleClickImg = (fileName: string, path: string) => (
    e: React.MouseEvent<HTMLImageElement>,
  ) => {
    setSelectedSrc(e.currentTarget.getAttribute("src") as string);
    setSelectedFileName(fileName);
    setSelectedPath(path);
  };

  const handleClickDelete = () => {
    const pictureToDelete = getPictureToDelete();
    if (pictureToDelete && pictureToDelete.id && employeePictures) {
      const employeePictureToDelete = employeePictures.find(
        (picture) => picture.pictureId === pictureToDelete.pictureId,
      );

      if (employeePictureToDelete) {
        dispatch(
          deleteUserImage(
            employeePictureToDelete.id,
            getAllEmployeePicturesQuery,
          ),
        );
        setSelectedSrc("");
        setSelectedFileName("");
      }
    }
  };

  const Arrows = ({ type, onClick, isEdge }: RenderArrowProps) => {
    const pointer = type === "PREV" ? <ArrowBackIos /> : <ArrowForwardIos />;
    return (
      <Box display="flex" alignItems="center">
        <Box display="flex" height="48px" width="48px">
          <IconButton
            aria-label={
              type === "PREV" ? "carousel left arrow" : "carousel right arrow"
            }
            onClick={onClick}
            disabled={isEdge}
          >
            {pointer}
          </IconButton>
        </Box>
      </Box>
    );
  };

  return (
    <Modal
      className={classes.modal}
      open={open}
      onClose={onClickClose}
      aria-labelledby="image-carousel-modal"
      aria-describedby="review-images-to-upload"
    >
      <Fragment>
        <Box className={classes.container}>
          <Box display="flex" justifyContent="flex-end">
            <IconButton aria-label="close modal" onClick={onClickClose}>
              <Cancel className={classes.secondaryButton} />
            </IconButton>
          </Box>
        </Box>
        <Box className={classes.container}>
          <Typography className={classes.fileName} component="h6">
            {`Delete: ${getSelectedFileName()}`}
          </Typography>
          <Box
            display="flex"
            justifyContent="center"
            padding="0px 28px 28px 28px"
          >
            <img
              alt="selected"
              className={classes.selectedImage}
              src={getSelectedImage()}
            />
          </Box>
        </Box>
        <Box className={classes.carouselContainer}>
          <Box display="flex" alignItems="center">
            {uploadedPictures.length > 1 && (
              <Carousel
                className={classes.carousel}
                pagination={false}
                itemsToScroll={4}
                itemsToShow={4}
                renderArrow={Arrows}
              >
                {getCarouselImages().map((employeePicture) => (
                  <Box
                    key={employeePicture.picture.path}
                    className={classes.imageContainer}
                  >
                    <img
                      alt="carousel-item"
                      className={classes.carouselImage}
                      src={employeePicture.picture.url}
                      onClick={handleClickImg(
                        employeePicture.picture.fileName,
                        employeePicture.picture.path as string,
                      )}
                    />
                  </Box>
                ))}
              </Carousel>
            )}
          </Box>
        </Box>
        <Box
          className={classes.container}
          display="flex"
          justifyContent="center"
          padding="24px"
        >
          <Button
            style={{ width: "374px" }}
            variant="contained"
            onClick={handleClickDelete}
          >
            Delete Image
          </Button>
        </Box>
      </Fragment>
    </Modal>
  );
}
