import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import { JobModel } from "@dwo/shared/dist/models/jobModel";
import React from "react";
import { AllocationFields } from "./AllocationFields";
import { useSelector } from "react-redux";
import { selectIsActiveEmployee } from "features/employees/employeesSlice";
import { v4 as uuid } from "uuid";

interface CurrentJobInformationProps {
  currentJobs: JobModel[];
}

interface StyleProps {
  isDisabled: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      color: ({ isDisabled }: StyleProps) =>
        isDisabled ? theme.palette.grey[400] : theme.palette.primary.main,
      marginBottom: "24px",
    },
  }),
);

export function CurrentJobInformation({
  currentJobs,
}: CurrentJobInformationProps) {
  const isDisabled = !useSelector(selectIsActiveEmployee);
  const classes = useStyles({ isDisabled });
  return (
    <Box margin="24px">
      <Typography variant="h5" className={classes.header}>
        Current Job
      </Typography>
      {currentJobs &&
        currentJobs.map((job: JobModel, index: number) => (
          <Box
            key={`${job.id}-${uuid()}`}
            paddingBottom={index !== currentJobs.length - 1 ? "64px" : "0"}
          >
            <AllocationFields information={job} />
          </Box>
        ))}
    </Box>
  );
}
