import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
  useTheme,
} from "@material-ui/core";
import Chart, { ChartTooltipModel } from "chart.js";
import { format } from "date-fns";
import { endOfQuarter, startOfQuarter } from "date-fns/esm";
import {
  selectSummarySelectedDateRange,
  selectSummarySelectedTab,
  selectTimesheetLabelsAndColors,
  selectTimesheetOverviewCurrentData,
  selectTimesheetOverviewPriorData,
} from "features/summary/summarySlice";
import React, { Fragment, useEffect, useRef } from "react";
import { renderToString } from "react-dom/server";
import { useSelector } from "react-redux";
import {
  getTimesheetOverviewLegend,
  getTimesheetYearlyByQuartersData,
  TimesheetCalendarType,
  TimesheetOverviewCategories,
} from "utils/summaryTimesheetUtils";
import { BarChartTooltip } from "./BarChartTooltip";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    legend: {
      color: theme.palette.grey[500],
      paddingTop: "14px",
    },
  }),
);

const tooltipId = "timesheetQYearByQuartersChart-tooltip";

export function TimesheetYearByQuartersBarChart() {
  const theme = useTheme();
  const calendarType = useRef<TimesheetCalendarType>(
    TimesheetCalendarType.PerYear,
  );
  const chartCanvasRef = useRef<HTMLCanvasElement>({} as HTMLCanvasElement);
  const labelsAndColors = useSelector(selectTimesheetLabelsAndColors);
  const overviewTab = useSelector(selectSummarySelectedTab);
  const overviewCurrentData = useSelector(selectTimesheetOverviewCurrentData);
  const overviewPriorData = useSelector(selectTimesheetOverviewPriorData);
  const selectedDateRange = useSelector(selectSummarySelectedDateRange);
  const classes = useStyles();

  const currentData = overviewCurrentData?.overviewDataPerMonth || [];
  const priorData = overviewPriorData?.overviewDataPerMonth || [];

  useEffect(() => {
    if (!selectedDateRange) return;

    const [priorDateLabelOption, selectedDateLabelOption] = labelsAndColors;
    const {
      currentDatesLabels,
      currentDates,
      priorDates,
      emptyDatasetData,
      currentCostsArray,
      priorCostsArray,
      currentHoursArray,
      priorHoursArray,
    } = getTimesheetYearlyByQuartersData(
      selectedDateRange,
      currentData,
      priorData,
    );

    const barChart = new Chart(chartCanvasRef.current, {
      data: {
        datasets: [
          {
            backgroundColor: priorDateLabelOption.color,
            barThickness: 24,
            data: priorHoursArray,
            hoverBackgroundColor: priorDateLabelOption.color,
            label: priorDateLabelOption.label,
          },
          {
            backgroundColor: "transparent",
            barThickness: 12,
            data: emptyDatasetData,
            hoverBackgroundColor: "transparent",
            label: "emptyDataSet",
          },
          {
            backgroundColor: selectedDateLabelOption.color,
            barThickness: 24,
            data: currentHoursArray,
            hoverBackgroundColor: selectedDateLabelOption.color,
            label: selectedDateLabelOption.label,
          },
        ],
        labels: currentDatesLabels,
      },
      options: {
        legend: {
          display: false,
        },
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              gridLines: {
                display: false,
              },
              ticks: {
                display: true,
                callback: (
                  _value: number | string,
                  index: number,
                  _values: number[] | string[],
                ) => {
                  return `Q${index + 1}`;
                },
                fontColor: theme.palette.primary.dark,
                fontFamily: "Noto Sans",
                fontSize: 12,
                fontStyle: "bold",
              },
            },
          ],
          yAxes: [
            {
              gridLines: {
                display: false,
              },
              scaleLabel: {
                display: true,
                labelString: "Hours / Costs",
                fontColor: theme.palette.primary.dark,
                fontFamily: "Noto Sans",
                fontSize: 12,
                fontStyle: "bold",
              },
              ticks: {
                beginAtZero: true,
                display: false,
              },
            },
          ],
        },
        tooltips: {
          custom: (tooltipModel: ChartTooltipModel) => {
            let tooltipEl = document.getElementById(tooltipId);

            if (!tooltipEl) {
              tooltipEl = document.createElement("div");
              tooltipEl.id = tooltipId;
              document.body.appendChild(tooltipEl);
            }

            if (tooltipModel.opacity === 0) {
              tooltipEl.style.opacity = "0";
              return;
            }

            const barType =
              tooltipModel.body &&
              tooltipModel.body[0] &&
              tooltipModel.body[0].lines[0]
                ? tooltipModel.body[0].lines[0].split(":")[0]
                : undefined;
            const barIndex =
              tooltipModel.dataPoints && tooltipModel.dataPoints[0]
                ? tooltipModel.dataPoints[0].index
                : undefined;

            if (!barType || barIndex === undefined) {
              tooltipEl.style.opacity = "0";
              return;
            }

            const isPrior = barType === TimesheetOverviewCategories.PriorDate;
            const tooltipDate = isPrior
              ? priorDates[barIndex]
              : currentDates[barIndex];
            const tooltipHours = isPrior
              ? priorHoursArray[barIndex]
              : currentHoursArray[barIndex];
            const tooltipCosts = isPrior
              ? priorCostsArray[barIndex]
              : currentCostsArray[barIndex];
            const startDate = startOfQuarter(tooltipDate);
            const endDate = endOfQuarter(tooltipDate);
            const subDate = `${format(startDate, "MMMM")} - ${format(
              endDate,
              "MMMM",
            )} ${format(tooltipDate, "y")}`;
            const customTooltipHTMLString = renderToString(
              <BarChartTooltip
                cost={tooltipCosts.toFixed(2)}
                date={`${isPrior ? "Past" : ""} Quarter ${barIndex + 1},`}
                hours={tooltipHours.toFixed(2)}
                subDate={subDate}
              />,
            );
            const position = chartCanvasRef.current.getBoundingClientRect();

            tooltipEl.innerHTML = customTooltipHTMLString;
            tooltipEl.style.opacity = "1";
            tooltipEl.style.position = "absolute";
            tooltipEl.style.left = `${
              position.left + window.pageXOffset + tooltipModel.caretX
            }px`;
            tooltipEl.style.top = `${
              position.top + window.pageYOffset + tooltipModel.caretY
            }px`;
            tooltipEl.style.pointerEvents = "none";
          },
          enabled: false,
        },
      },
      type: "bar",
    });

    return () => {
      barChart.destroy();
    };
  }, [
    currentData,
    labelsAndColors,
    priorData,
    selectedDateRange,
    theme.palette,
  ]);

  return (
    <Fragment>
      <Box height="200px" maxWidth="360px" width="100%">
        <canvas ref={chartCanvasRef} />
        <div id={tooltipId} />
      </Box>
      <Typography className={classes.legend} variant="body2">
        {getTimesheetOverviewLegend(calendarType.current, overviewTab)}
      </Typography>
    </Fragment>
  );
}
