import {
  Avatar,
  Box,
  createStyles,
  makeStyles,
  Paper,
  Theme,
  Typography,
  useTheme,
} from "@material-ui/core";
import { CheckboxCustom } from "components/CheckboxCustom";
import { Dropdown } from "components/Dropdown";
import { getDropDownOptions } from "components/employeeTimesheet/employeeTimesheetUtils";
import {
  selectCurrentEmployee,
  selectGeneralStatus,
} from "features/employeeTimesheet/employeeTimesheetSlice";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

interface EmployeeSummaryProps {
  isChecked: boolean;
  isEditModeActive: boolean;
  selectedValue: string;
  onChangeTimeOffSelectValue: (selectedValue: string) => void;
  onClickTimeOff: (_: React.ChangeEvent<HTMLInputElement>) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      backgroundColor: "white",
      display: "flex",
      margin: "0 24px 24px 24px",
      padding: "24px",
    },
    mainAvatar: {
      height: "150px",
      width: "150px",
    },
    userName: {
      color: theme.palette.primary.dark,
      fontSize: "2.5rem",
      fontWeight: 300,
    },
    employeeId: {
      borderRight: `4px solid ${theme.palette.secondary.main}`,
      color: theme.palette.primary.main,
      lineHeight: "1",
      paddingRight: "11px",
    },
    role: {
      color: theme.palette.primary.main,
      lineHeight: "1",
      marginLeft: "11px",
      textTransform: "capitalize",
    },
    subTitle: {
      color: theme.palette.grey["700"],
      fontWeight: "bold",
    },
    text: {
      color: theme.palette.grey["500"],
      marginLeft: "10px",
    },
    label: {
      color: theme.palette.grey[700],
      fontWeight: 600,
    },
    thirdRow: {
      display: "flex",
      marginTop: "17px",
      alignItems: "center",
      [theme.breakpoints.down("xs")]: {
        flexDirection: "column",
      },
    },
  }),
);

export function EmployeeSummary({
  isChecked,
  isEditModeActive,
  selectedValue,
  onChangeTimeOffSelectValue,
  onClickTimeOff,
}: EmployeeSummaryProps) {
  const theme = useTheme();
  const currentEmployee = useSelector(selectCurrentEmployee);
  const generalStatus = useSelector(selectGeneralStatus);
  const [status, setStatus] = useState(generalStatus);
  const classes = useStyles();
  const employeeRegion = currentEmployee?.regions;

  useEffect(() => {
    setStatus(generalStatus);
  }, [generalStatus]);

  return (
    <Paper className={classes.paper}>
      <Box display="flex" height="100%" marginTop="5px" marginBottom="21px">
        <Avatar
          src={currentEmployee?.pictureUrl ?? ""}
          className={classes.mainAvatar}
        />
      </Box>
      <Box
        marginLeft="24px"
        display="flex"
        alignItems="baseline"
        flexDirection="column"
      >
        <Typography className={classes.userName} variant="h1">
          {currentEmployee?.fullName}
        </Typography>
        <Box display="flex" marginTop="11px">
          <Typography className={classes.employeeId} variant="h6">
            {currentEmployee?.employeeId}
          </Typography>
          <Typography className={classes.role} variant="h6">
            {currentEmployee?.role}
          </Typography>
        </Box>
        <Box className={classes.thirdRow}>
          <Typography variant="body2" className={classes.subTitle}>
            Status:
          </Typography>
          <Typography
            style={{ textTransform: "capitalize" }}
            variant="body2"
            className={classes.text}
          >
            {status}
          </Typography>
          <Box
            style={{
              backgroundColor:
                status === "approved"
                  ? theme.palette.success.main
                  : theme.palette.primary.main,
            }}
            height="12px"
            width="12px"
            borderRadius="50px"
            marginLeft="20px"
          />
          <Box display="flex" marginLeft="19px">
            <Typography variant="body2" className={classes.subTitle}>
              Region:
            </Typography>
            <Typography variant="body2" className={classes.text}>
              {employeeRegion && employeeRegion[0] && employeeRegion[0].name}
            </Typography>
          </Box>
          <Box display="flex" marginLeft="19px">
            <Typography variant="body2" className={classes.subTitle}>
              Company Name:
            </Typography>
            <Typography variant="body2" className={classes.text}>
              {currentEmployee?.companyName}
            </Typography>
          </Box>
        </Box>
        <Box display="flex" marginTop="16px" alignItems="center">
          <Box display="flex" alignItems="center">
            <CheckboxCustom
              disabled={isEditModeActive}
              isChecked={isChecked}
              onClickCheckbox={onClickTimeOff}
            />
            <Typography className={classes.text} variant="body2">
              Time off
            </Typography>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            width="142px"
            marginLeft="15px"
          >
            <Dropdown
              height="40px"
              id="timeOff-Dropdown"
              label="Select"
              options={getDropDownOptions()}
              selectedValue={selectedValue}
              onSelectOption={onChangeTimeOffSelectValue}
            />
          </Box>
        </Box>
      </Box>
    </Paper>
  );
}
