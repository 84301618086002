import React from "react";
import { JobModel } from "@dwo/shared/dist/models/jobModel";
import {
  Card,
  Box,
  Typography,
  makeStyles,
  Theme,
  createStyles,
  Divider,
} from "@material-ui/core";
import {
  jobHeadersStyles,
  getJobType,
  DEFAULT_EMPTY,
  JobStatusValues,
  getFormattedHrs,
} from "utils/jobUtils";
import { slashFormatDate } from "utils/dateUtils";
import { ArrowForward, ArrowForwardIos } from "@material-ui/icons";
import { useHistory } from "react-router";
import { ROOT_JOB_MANAGEMENT } from "routes/Roots";

interface JobCardProps {
  jobData: JobModel;
}

interface StyleProps {
  isOvercost: boolean;
  jobStatus: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      color: theme.palette.grey["500"],
      overflowWrap: "anywhere",
      paddingRight: "4px",
    },
    subTitle: {
      color: theme.palette.primary.dark,
      fontWeight: "bold",
    },
    status: {
      color: theme.palette.grey["500"],
      padding: "0 8px",
    },
    estimated: {
      color: theme.palette.grey["500"],
      padding: "0 16px 0 16px",
    },
    current: {
      color: theme.palette.grey["500"],
      padding: "0 8px",
    },
    estimatedCost: {
      color: theme.palette.primary.light,
      fontWeight: "bold",
      paddingRight: "8px",
    },
    calculatedCost: {
      color: ({ isOvercost }: StyleProps) =>
        isOvercost ? theme.palette.secondary.main : theme.palette.success.main,
      fontWeight: "bold",
    },
    arrowIcon: {
      color: ({ isOvercost }: StyleProps) =>
        isOvercost ? theme.palette.secondary.main : theme.palette.success.main,
    },
    chevronIcon: {
      color: theme.palette.primary.dark,
    },
    viewMore: {
      cursor: "pointer",
    },
    dotStatus: {
      height: "12px",
      width: "12px",
      borderRadius: "50px",
      backgroundColor: ({ jobStatus }: StyleProps) => {
        switch (jobStatus) {
          case JobStatusValues.ASSIGNED:
            return theme.palette.grey["500"];
          case JobStatusValues.COMPLETED:
            return theme.palette.success.main;
          case JobStatusValues.DONE:
            return theme.palette.grey["800"];
          case JobStatusValues.IN_PROGRESS:
            return theme.palette.primary.light;
          case JobStatusValues.SUSPENDED:
            return theme.palette.warning.light;
          case JobStatusValues.UNASSIGNED:
            return "white";
          case JobStatusValues.CANCELED:
            return theme.palette.secondary.main;

          default:
            return theme.palette.primary.light;
        }
      },
      border: ({ jobStatus }: StyleProps) =>
        `${jobStatus === JobStatusValues.UNASSIGNED ? "1px" : "0"} solid ${
          theme.palette.grey["500"]
        }`,
    },
  }),
);

export function JobCard({ jobData }: JobCardProps) {
  const history = useHistory();
  const headersStyles = jobHeadersStyles();
  const classes = useStyles({
    isOvercost: jobData.isOvercost as boolean,
    jobStatus: jobData.status,
  });

  const getSupervisor = () => {
    if (!jobData.supervisor) {
      return DEFAULT_EMPTY;
    }

    return jobData.supervisor.fullName;
  };

  const getForeman = () => {
    if (!jobData.crews) {
      return DEFAULT_EMPTY;
    }

    if (jobData.crews.length === 0) {
      return DEFAULT_EMPTY;
    }

    if (!jobData.crews[0].foreman) {
      return DEFAULT_EMPTY;
    }

    return jobData.crews[0].foreman.fullName;
  };

  const getCurrentCost = () => {
    const labor = jobData.laborCost ? parseFloat(jobData.laborCost) : 0;
    const material = jobData.materialsCost
      ? parseFloat(jobData.materialsCost)
      : 0;
    const other = jobData.otherCost ? parseFloat(jobData.otherCost) : 0;

    return (labor + material + other).toFixed(2);
  };

  const handleClickVewMore = () => {
    history.push(`${ROOT_JOB_MANAGEMENT}/${jobData.id}`);
  };

  return (
    <Card>
      <Box display="flex" padding="16px 24px">
        <Box
          display="flex"
          alignItems="center"
          className={headersStyles.idHeader}
        >
          <Typography variant="body2" className={classes.title}>
            {jobData.jobId}
          </Typography>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          className={headersStyles.workOrderNumber}
        >
          <Typography variant="body2" className={classes.title}>
            {jobData.workOrderNumber ? jobData.workOrderNumber : "- - -"}
          </Typography>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          className={headersStyles.addressHeader}
        >
          <Typography variant="body2" className={classes.title}>
            {jobData.address}
          </Typography>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          className={headersStyles.typeHeader}
        >
          <Typography variant="body2" className={classes.title}>
            {getJobType(jobData.type)}
          </Typography>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          className={headersStyles.supervisorheader}
        >
          <Typography variant="body2" className={classes.title}>
            {getSupervisor()}
          </Typography>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          className={headersStyles.foremanHeader}
        >
          <Typography variant="body2" className={classes.title}>
            {getForeman()}
          </Typography>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          className={headersStyles.startDateHeader}
        >
          <Typography variant="body2" className={classes.title}>
            {slashFormatDate(new Date(jobData.startDate))}
          </Typography>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          className={headersStyles.endDateHeader}
        >
          <Typography variant="body2" className={classes.title}>
            {slashFormatDate(new Date(jobData.endDate))}
          </Typography>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          className={headersStyles.hoursHeader}
        >
          <Typography variant="body2" className={classes.title}>
            {`${getFormattedHrs(jobData.currentHours)}Hrs`}
          </Typography>
        </Box>
      </Box>
      <Divider />
      <Box
        display="flex"
        alignItems="center"
        padding="30px 24px"
        flexWrap="wrap"
      >
        <Box display="flex" alignItems="center" marginRight="32px">
          <Typography variant="body2" className={classes.subTitle}>
            Status
          </Typography>
          <Typography variant="body2" className={classes.status}>
            {jobData.status}
          </Typography>
          <Box className={classes.dotStatus} />
        </Box>
        <Box display="flex" alignItems="center">
          <Typography variant="body2" className={classes.subTitle}>
            Job Cost
          </Typography>
          <Typography variant="body2" className={classes.estimated}>
            Estimated
          </Typography>
          <Typography
            variant="body2"
            className={classes.estimatedCost}
          >{`$ ${Number(jobData.bidPrice || 0).toFixed(2)}`}</Typography>
          <ArrowForward className={classes.arrowIcon} />
          <Typography variant="body2" className={classes.current}>
            Current
          </Typography>
          <Typography
            variant="body2"
            className={classes.calculatedCost}
          >{`$ ${getCurrentCost()}`}</Typography>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          flex="1"
          justifyContent="flex-end"
          className={classes.viewMore}
          onClick={handleClickVewMore}
        >
          <Typography variant="body1" className={classes.subTitle}>
            View More
          </Typography>
          <ArrowForwardIos className={classes.chevronIcon} />
        </Box>
      </Box>
    </Card>
  );
}
