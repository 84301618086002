import { DownloadTimesheetsModel } from "@dwo/shared/dist/models/downloadTimesheetsModel";
import { DropdownOption } from "components/Dropdown";
import { DownloadFileIcon } from "components/upload/DownloadFileIcon";
import React from "react";
import { slashFormatDate } from "./dateUtils";
import { MainTableCell, MainTableColumn, MainTableRow } from "./tableUtils";

export const companiesOptions: DropdownOption[] = [
  { label: "TSU One", value: "TSU1" },
  { label: "Pinnacle", value: "Pinnacle" },
];

export const downloadTimesheetsColumns: MainTableColumn[] = [
  { field: "fileName", hasSorting: true, headerName: "Name" },
  { field: "company", headerName: "Company" },
  { field: "date", hasSorting: true, headerName: "Date Created" },
  { field: "download", headerName: "Download" },
];

export const timesheetFileStatuses = {
  FAILED: "Failed",
  GENERATING: "Generating",
  SUCCESSFUL: "Successful",
};

export const getDownloadTimesheetsRows = (
  downloadTimesheets: DownloadTimesheetsModel[],
  onClickDownload: (fileId: number) => void,
): MainTableRow[] =>
  downloadTimesheets.map((dTimesheets: DownloadTimesheetsModel) => {
    const name: MainTableCell = {
      value: dTimesheets.fileName,
    };
    const company: MainTableCell = {
      value: dTimesheets.company,
    };
    const createDate: MainTableCell = {
      value: slashFormatDate(new Date(dTimesheets.date)),
    };
    const download: MainTableCell = {
      value: (
        <DownloadFileIcon
          id={dTimesheets.id as number}
          onClickDownload={onClickDownload}
        />
      ),
    };

    const innerCells = [name, company, createDate, download];

    return {
      innerCells,
    };
  });
