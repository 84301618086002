import React, { useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { noop } from "lodash";
import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import { GlobalModal } from "components/globalModal/GlobalModal";
import { TableCustom } from "components/table/TableCustom";
import { CrewModel } from "@dwo/shared/dist/models/crewModel";
import { crewService } from "@dwo/shared/dist/services/crewService";
import { employeeCrewService } from "@dwo/shared/dist/services/employeeCrewService";
import { ServiceOptions } from "@dwo/shared/dist/services/baseService";
import {
  getAllCrews,
  selectCrews,
  selectIsLoading,
} from "features/crew/crewSlice";
import { createEmptyTimesheet } from "features/dwo/dwoSlice";
import { selectCurrentJob } from "features/jobs/jobsSlice";
import { crewModalColums, getCrewModalRows } from "utils/jobDetailsUtils";
import { enDashFormatDate } from "utils/dateUtils";
interface TimesheetsModalProps {
  crewId?: number;
  isOpen: boolean;
  onClickClose: () => void;
  title?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    description: {
      color: theme.palette.grey[500],
    },
  }),
);

export function NoTimesheetsModal({
  isOpen,
  title = "Select a Crew",
  onClickClose,
}: TimesheetsModalProps) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const jobId = parseInt(id, 10);
  const crews = useSelector(selectCrews);
  const crewsData = useSelector(selectCrews);
  const isLoading = useSelector(selectIsLoading);
  const jobData = useSelector(selectCurrentJob);

  const getAllCrewsQuery = useCallback((): ServiceOptions => {
    return {
      where: {
        jobId,
      },
      include: ["foreman"],
    };
  }, [jobId]);

  const crewsIds: number[] = [];

  useEffect(() => {
    dispatch(getAllCrews(getAllCrewsQuery()));
  }, [dispatch, getAllCrewsQuery]);

  crews.forEach((currentCrew) => {
    const foremanId = currentCrew.foremanId;

    if (foremanId && !crewsIds.find((id) => id === foremanId)) {
      crewsIds.push(foremanId);
    }
  });

  const selectedCrews: CrewModel[] = [];
  crewsIds.forEach((id) => {
    const foundCrew = crews.find((currentCrew) => currentCrew.foremanId === id);
    if (foundCrew) {
      selectedCrews.push(foundCrew);
    }
  });

  const handleClickCreateDWO = async () => {
    const { data: crew } = await crewService.getAll({
      where: {
        jobId: jobData?.id,
      },
      include: ["job"],
    });

    const { data: employeeCrew } = await employeeCrewService.getAll({
      where: {
        crewId: handleClickRow,
        isCurrent: true,
        isForeman: true,
      },
    });

    const DWOCreation = await createEmptyTimesheet(
      crew[0],
      enDashFormatDate(new Date(jobData!.startDate)),
      Number(jobData?.id),
      Number(employeeCrew[0].employeeId),
    );
    dispatch(DWOCreation);
  };

  const handleClickRow = (id: string) => {
    console.log(id, "selected id");
    handleClickCreateDWO();
  };

  const rows =
    getAllCrewsQuery() && selectedCrews.length > 0
      ? getCrewModalRows(crewsData, handleClickRow)
      : [];

  const description = (
    <Box margin="0 24px 24px 24px">
      <Typography className={classes.description} variant="body2">
        Select a Crew to add the blank timesheet.
      </Typography>
    </Box>
  );

  return (
    <GlobalModal
      Filters={description}
      onClickClose={onClickClose}
      open={isOpen}
      primaryLabel="Continue"
      showActionButtons={false}
      title={title}
    >
      <TableCustom
        columns={crewModalColums}
        isLoading={isLoading}
        rows={rows}
        onClickSort={noop}
      />
    </GlobalModal>
  );
}
