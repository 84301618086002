import { JobModel } from "@dwo/shared/dist/models/jobModel";
import {
  Box,
  createStyles,
  Grid,
  IconButton,
  makeStyles,
  Paper,
  Theme,
  Typography,
} from "@material-ui/core";
import { ArrowBack, Info } from "@material-ui/icons";
import { Layout } from "components/layout/Layout";
import { CrewCommentsCard } from "components/timesheetDetails/CrewCommentsCard";
import { CrewOverviewHeader } from "components/timesheetDetails/CrewOverviewHeader";
import { DisplayCrewOverviewGrid } from "components/timesheetDetails/DisplayCrewOverviewGrid";
import { DisplayCrewOverviewTable } from "components/timesheetDetails/DisplayCrewOverviewTable";
import { EditCrewOverviewTable } from "components/timesheetDetails/EditCrewOverviewTable";
import { JobsAssigned } from "components/timesheetDetails/JobsAssigned";
import { SupervisorsAssigned } from "components/timesheetDetails/SupervisorsAssigned";
import { TimesheetHoursOverview } from "components/timesheetDetails/TimesheetHoursOverview";
import { TimesheetsPerDayCard } from "components/timesheetDetails/TimesheetsPerDayCard";
import {
  cleanDWOSlice,
  getAllDWOCrewDetails,
  getDWOIdByForemanIdAndDate,
  selectCurrentDWO,
  selectCurrentJob,
  selectDWOByForemanIdAndDate,
  selectForemanId,
  selectInitialJobId,
  selectJobHazardEmployeeData,
  selectJobsOfDWOs,
  selectRefreshView,
  selectSelectedTab,
  setCurrentJob,
  setRefreshView,
} from "features/dwo/dwoSlice";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
  ROOT_JOB_MANAGEMENT,
  ROOT_TIMESHEETS_MANAGEMENT,
  ROOT_USER_MANAGEMENT,
} from "routes/Roots";
import { addLocalTimeOffset, enDashFormatDate } from "utils/dateUtils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backButton: {
      marginRight: "24px",
      padding: 0,
    },
    backIcon: {
      color: theme.palette.primary.main,
    },
    header: {
      color: theme.palette.primary.main,
      fontWeight: 600,
    },
    infoLegendIcon: {
      color: theme.palette.grey[500],
      marginRight: "8px",
    },
    infoLegendLabel: {
      color: theme.palette.grey[500],
    },
    headerCard: {
      alignItems: "center",
      display: "flex",
      margin: "0 24px 24px 24px",
      padding: "18px 16px",
      [theme.breakpoints.down("xs")]: {
        flexDirection: "column",
        "& div:first-child": {
          marginBottom: "8px",
        },
      },
    },
    headerLabel: {
      color: theme.palette.grey[700],
      fontWeight: 600,
      marginRight: "8px",
    },

    rightSideCards: {
      display: "flex",
      flexDirection: "column",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "row",
      },
      [theme.breakpoints.down("xs")]: {
        flexDirection: "column",
      },
    },
  }),
);

export function TimesheetDetails() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const dwoId = parseInt(id, 10);
  const currentDWO = useSelector(selectCurrentDWO);
  const currentJob = useSelector(selectCurrentJob);
  const dwoByForemanIdAndDate = useSelector(selectDWOByForemanIdAndDate);
  const dwoForemanId = useSelector(selectForemanId);
  const initialJobId = useSelector(selectInitialJobId);
  const jobsOfDWOs = useSelector(selectJobsOfDWOs);
  const selectedTab = useSelector(selectSelectedTab);
  const jobHazardEmployeeData = useSelector(selectJobHazardEmployeeData);
  const refreshView = useSelector(selectRefreshView);
  const [selectedDate, setSelectedDate] = useState<Date>();
  const [sortingOptions, setSortingOptions] = useState<string[][]>([]);
  const classes = useStyles();

  useEffect(() => {
    if (refreshView) {
      dispatch(setRefreshView(false));
      history.go(0);
    }
  }, [refreshView, dispatch, history]);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(
      getAllDWOCrewDetails(
        dwoId,
        undefined,
        undefined,
        { include: ["Region"] },
        true,
      ),
    );

    return () => {
      dispatch(cleanDWOSlice());
    };
  }, [dwoId, dispatch]);

  useEffect(() => {
    if (currentDWO && typeof currentDWO.date === "string") {
      const newDwoDate = addLocalTimeOffset(currentDWO.date);
      setSelectedDate(newDwoDate);
    }
  }, [currentDWO]);

  useEffect(() => {
    if (!initialJobId || jobsOfDWOs.length === 0) return;

    const selectedJob = jobsOfDWOs.find(
      (job: JobModel) => (job.id as number) === initialJobId,
    );

    dispatch(setCurrentJob(selectedJob));
  }, [initialJobId, jobsOfDWOs, dispatch]);

  useEffect(() => {
    if (currentJob) {
      dispatch(
        getAllDWOCrewDetails(dwoId, currentJob.id, currentJob.dwoJobId, {
          order: sortingOptions.length > 0 ? sortingOptions : undefined,
        }),
      );
    }
  }, [dwoId, dispatch, sortingOptions, currentJob]);

  useEffect(() => {
    if (dwoForemanId && selectedDate) {
      dispatch(
        getDWOIdByForemanIdAndDate(
          dwoForemanId,
          enDashFormatDate(selectedDate),
        ),
      );
    }
  }, [dispatch, dwoForemanId, selectedDate]);

  useEffect(() => {
    if (dwoByForemanIdAndDate) {
      history.push(`${ROOT_TIMESHEETS_MANAGEMENT}/${dwoByForemanIdAndDate.id}`);
    }
  }, [dwoByForemanIdAndDate, history]);

  const handleClickBack = () => {
    history.push(ROOT_TIMESHEETS_MANAGEMENT);
  };

  const handleClickJob = (jobId: number) =>
    history.push(`${ROOT_JOB_MANAGEMENT}/${jobId}`);

  const handleClickSupervisor = (supervisorId: number) =>
    history.push(`${ROOT_USER_MANAGEMENT}/${supervisorId}`);

  const handleSelectDate = (newDate: Date) => setSelectedDate(newDate);

  const handleClickSorting = (sortingValues: string[][]) =>
    setSortingOptions(sortingValues);

  return (
    <Layout>
      <Grid container>
        <Grid item xs={12}>
          <Box alignItems="center" display="flex" padding="32px 24px 24px 24px">
            <IconButton
              aria-label="Go Back"
              className={classes.backButton}
              onClick={handleClickBack}
            >
              <ArrowBack className={classes.backIcon} fontSize="large" />
            </IconButton>
            <Typography className={classes.header} component="h1" variant="h4">
              Crew Details
            </Typography>
          </Box>
        </Grid>

        <Grid item md={9} xs={12}>
          <Paper className={classes.headerCard}>
            <Box display="flex" flexWrap="wrap" marginRight="24px">
              <Typography className={classes.headerLabel} variant="body2">
                Supervisor
              </Typography>
              <SupervisorsAssigned
                jobs={jobsOfDWOs}
                onClickSupervisor={handleClickSupervisor}
              />
            </Box>
            <Box display="flex" flexWrap="wrap">
              <Typography className={classes.headerLabel} variant="body2">
                Job Assigned
              </Typography>
              <JobsAssigned jobs={jobsOfDWOs} onClickJob={handleClickJob} />
            </Box>
          </Paper>

          <TimesheetHoursOverview />

          <CrewOverviewHeader selectedDate={selectedDate} />
          {selectedTab === 0 && !currentJob && (
            <DisplayCrewOverviewTable
              jobHazardEmployeeData={jobHazardEmployeeData}
            />
          )}
          {selectedTab === 0 && currentJob && (
            <EditCrewOverviewTable
              jobHazardEmployeeData={jobHazardEmployeeData}
              sortingOptions={sortingOptions}
              onClickSort={handleClickSorting}
            />
          )}
          {selectedTab === 1 && (
            <DisplayCrewOverviewGrid selectedDate={selectedDate} />
          )}

          <Box
            alignItems="center"
            display="flex"
            justifyContent="center"
            margin="0 24px"
            padding="16px 0"
          >
            <Info className={classes.infoLegendIcon} fontSize="small" />
            <Typography className={classes.infoLegendLabel} variant="caption">
              You Can Only Approve Hours And Add Comments On a Job Level.
            </Typography>
          </Box>
        </Grid>

        <Grid item md={3} xs={12}>
          <Box className={classes.rightSideCards}>
            <TimesheetsPerDayCard
              selectedDate={selectedDate}
              startDate={currentJob?.startDate}
              onSelectDate={handleSelectDate}
            />

            <CrewCommentsCard />
          </Box>
        </Grid>
      </Grid>
    </Layout>
  );
}
