import { DropdownOption } from "components/Dropdown";
import { EmployeeModel } from "@dwo/shared/dist/models/employeeModel";
import { MainTableColumn, MainTableRow, rowSelectOptions } from "./tableUtils";
import { ALL_OPTION, DEFAULT_LIMIT } from "./sharedUtils";
import { slashFormatDateWithHoursMinutes } from "utils/dateUtils";
import { ServiceOptions } from "@dwo/shared/dist/services/baseService";

export enum ROLE_TYPES_LABELS {
  ADMIN = "Admin",
  FOREMAN = "Foreman",
  SUBADMIN = "Subadmin",
  SUPERVISOR = "Supervisor",
  WORKER = "Worker",
}

export enum ROLE_TYPES_VALUES {
  ADMIN = "admin",
  FOREMAN = "foreman",
  SUBADMIN = "subadmin",
  SUPERVISOR = "supervisor",
  WORKER = "worker",
}

export interface UserFilters {
  searchValue: string;
  region: string[];
  role: string;
  sortingOptions: string[][];
  page: number;
  rowsPerPage: string;
}

export const initialUserFilters: UserFilters = {
  searchValue: "",
  region: [],
  role: "",
  sortingOptions: [],
  page: 1,
  rowsPerPage: rowSelectOptions[0].value,
};

export const initialUserQuery: ServiceOptions = {
  limit: DEFAULT_LIMIT,
};

export const roleFilterOptions: DropdownOption[] = [
  ALL_OPTION,
  {
    label: ROLE_TYPES_LABELS.ADMIN,
    value: ROLE_TYPES_VALUES.ADMIN,
  },
  {
    label: ROLE_TYPES_LABELS.FOREMAN,
    value: ROLE_TYPES_VALUES.FOREMAN,
  },
  {
    label: ROLE_TYPES_LABELS.SUBADMIN,
    value: ROLE_TYPES_VALUES.SUBADMIN,
  },
  {
    label: ROLE_TYPES_LABELS.SUPERVISOR,
    value: ROLE_TYPES_VALUES.SUPERVISOR,
  },
  {
    label: ROLE_TYPES_LABELS.WORKER,
    value: ROLE_TYPES_VALUES.WORKER,
  },
];

export const mainTableHeaderColumns: MainTableColumn[] = [
  {
    field: "employeeId",
    headerName: "ID #",
    hasSorting: true,
  },
  {
    field: "firstName",
    headerName: "name",
    hasSorting: true,
  },
  {
    field: "workPhone",
    headerName: "phone",
  },
  {
    field: "email",
    headerName: "email",
  },
  {
    field: "companyName",
    headerName: "company",
  },
  {
    field: "region",
    headerName: "region",
    hasSorting: true,
  },
  {
    field: "role",
    headerName: "role",
    hasSorting: true,
  },
  {
    field: "lastLogin",
    headerName: "last login",
  },
  {
    field: "isLoggedIn",
    headerName: "status",
  },
];

export const getMainTableRows = (data: EmployeeModel[]): MainTableRow[] => {
  return data.map((employee) => {
    const id = { value: employee.employeeId };
    const name = {
      value: employee.fullName,
      isLink: true,
      linkTo: `/user-management/${employee.id as number}`,
      hasAlert: employee.hasNotMatchedPictures,
    };
    const phone = { value: employee.workPhone };
    const email = { value: employee.email };
    const company = { value: employee.companyName };
    const region = { value: employee.region as string };
    const role = { value: employee.role };
    const lastLogin = {
      value: employee.lastLogin
        ? slashFormatDateWithHoursMinutes(new Date(employee.lastLogin))
        : " ---- ",
    };
    const isLoggedIn = { value: employee.isLoggedIn ? "Online" : "Offline" };

    const innerCells = [
      id,
      name,
      phone,
      email,
      company,
      region,
      role,
      lastLogin,
      isLoggedIn,
    ];
    return {
      innerCells,
    };
  });
};
