import { AppThunk, RootState } from "app/store";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { cloneDeep } from "lodash";

import { DEFAULT_LIMIT } from "utils/sharedUtils";
import { DownloadJobsModel } from "@dwo/shared/dist/models/downloadJobsModel";
import { downloadJobsService } from "@dwo/shared/dist/services/downloadJobsService";
import { ServiceOptions } from "@dwo/shared/dist/services/baseService";
import { error } from "features/error/errorSlice";

interface DownloadJobsState {
  count: number;
  isLoading: boolean;
  downloadJobs: DownloadJobsModel[];
  limit?: number;
  offset?: number;
  order?: any;
}

const initialState: DownloadJobsState = {
  count: 0,
  isLoading: false,
  downloadJobs: [],
  limit: DEFAULT_LIMIT,
  offset: 0,
  order: undefined,
};

export const downloadJobsSlice = createSlice({
  name: "downloadJobs",
  initialState,
  reducers: {
    setCount: (state, action: PayloadAction<number>) => {
      state.count = action.payload;
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setDownloadJobs: (state, action: PayloadAction<DownloadJobsModel[]>) => {
      state.downloadJobs = cloneDeep(action.payload);
    },
    setLimit: (state, action: PayloadAction<number>) => {
      state.limit = action.payload;
    },
    setOffset: (state, action: PayloadAction<number>) => {
      state.offset = action.payload;
    },
    setOrder: (state, action: PayloadAction<any>) => {
      state.order = action.payload;
    },
  },
});

export const {
  setCount,
  setIsLoading,
  setDownloadJobs,
  setLimit,
  setOffset,
  setOrder,
} = downloadJobsSlice.actions;

export const getAllDownladJobs = (options?: ServiceOptions): AppThunk => async (
  dispatch,
) => {
  try {
    dispatch(setIsLoading(true));

    const {
      count,
      data,
      limit,
      offset,
      order,
    } = await downloadJobsService.getAll(options);

    dispatch(setCount(count));
    dispatch(setDownloadJobs(data));
    dispatch(setLimit(limit));
    dispatch(setOffset(offset));
    dispatch(setOrder(order));
  } catch (e) {
    dispatch(
      error(
        {
          title: "Could not retrieve CSVs",
          message: e.message,
        },
        () => dispatch(getAllDownladJobs(options)),
      ),
    );
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const selectCount = (state: RootState) => state.downloadJobs.count;
export const selectIsLoading = (state: RootState) =>
  state.downloadJobs.isLoading;
export const selectDownloadJobs = (state: RootState) =>
  state.downloadJobs.downloadJobs;
export const selectLimit = (state: RootState) => state.downloadJobs.limit;
export const selectOffset = (state: RootState) => state.downloadJobs.offset;
export const selectOrder = (state: RootState) => state.downloadJobs.order;

export const downloadJobsReducer = downloadJobsSlice.reducer;
