import { JobModel } from "@dwo/shared/dist/models/jobModel";
import {
  Box,
  createStyles,
  makeStyles,
  Paper,
  Theme,
  Typography,
} from "@material-ui/core";
import { IconButton } from "@material-ui/core/";
import { Add, NavigateBefore, NavigateNext } from "@material-ui/icons";
import { setCurrentJob } from "features/dwo/dwoSlice";
import {
  selectAlertJobId,
  selectComesFromAlerts,
  setAlertJobId,
  setComesFromAlerts,
} from "features/notifications/notificationSlice";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { JobsModal } from "./JobsModal";
import { ToggleJobTotalHours } from "./ToggleJobTotalHours";

export type JobOverview = {
  jobId: string;
  totalHours: number;
  jobType: string;
  id: number;
  dwoJobId: number;
};

export type OverviewDisplay = {
  firstOverview?: JobOverview;
  secondOverview?: JobOverview;
};

interface HoursOverviewPerJobProps {
  overviewDisplays: OverviewDisplay[];
  jobsWithTimesheetDetails: JobModel[];
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      backgroundColor: "white",
      borderRadius: "0px",
      margin: "0 24px 24px 24px",
      padding: "24px",
      position: "relative",
      zIndex: 2,
    },
    container: {
      display: "flex",
      width: "100%",
      justifyContent: "space-between",
      alignItems: "center",
      marginBottom: "16px",
    },
    title: {
      color: theme.palette.primary.main,
    },
    iconColor: {
      color: "white",
      fontSize: "1.5rem",
    },
    iconBackground: {
      backgroundColor: theme.palette.grey[400],
      display: "flex",
      height: "18px",
      width: "18px",
      marginLeft: "14px",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "100px",
      "&:hover": {
        backgroundColor: theme.palette.primary.main,
      },
    },
    iconAddBackground: {
      backgroundColor: theme.palette.primary.main,
      display: "flex",
      height: "18px",
      marginLeft: "14px",
      width: "18px",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "100px",
    },
    iconAddColor: {
      color: "white",
      fontSize: "1rem",
    },
    emptyIcon: {
      display: "flex",
      height: "18px",
      width: "18px",
      marginLeft: "14px",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "100px",
    },
    emptyStateMessage: {
      color: theme.palette.grey[600],
      marginTop: "24px",
    },
  }),
);

export function HoursOverviewPerJob({
  overviewDisplays,
  jobsWithTimesheetDetails,
}: HoursOverviewPerJobProps) {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [carouselPosition, setCarouselPosition] = useState(0);
  const [selectedOverview, setSelectedOverview] = useState<
    JobOverview | undefined
  >(undefined);
  const [isJobsModalOpen, setIsJobsModalOpen] = useState(false);
  const isPreviousButtonDisabled = carouselPosition === 0;
  const isNextButtonDisabled = carouselPosition === overviewDisplays.length - 1;
  const overviewOnDisplay = overviewDisplays[carouselPosition];
  const comesFromAlerts = useSelector(selectComesFromAlerts);
  // TODO: this should change to dwoJobId
  const alertJobId = useSelector(selectAlertJobId);

  useEffect(() => {
    if (comesFromAlerts && alertJobId && overviewDisplays.length > 0) {
      let overviewIndex = 0;
      let preselectedOverview: JobOverview | undefined = undefined;
      const foundPreSelectedJob = overviewDisplays.find(
        (jobOverview, index) => {
          if (
            jobOverview.firstOverview &&
            jobOverview.firstOverview.id === alertJobId
          ) {
            overviewIndex = index;
            preselectedOverview = jobOverview.firstOverview;
            return true;
          }
          if (
            jobOverview.secondOverview &&
            jobOverview.secondOverview.id === alertJobId
          ) {
            overviewIndex = index;
            preselectedOverview = jobOverview.secondOverview;
            return true;
          }

          return false;
        },
      );

      if (foundPreSelectedJob) {
        setCarouselPosition(overviewIndex);
        setSelectedOverview(preselectedOverview);
      }
      dispatch(setComesFromAlerts(false));
      dispatch(setAlertJobId(undefined));
    }
    // eslint-disable-next-line
  }, [overviewDisplays]);

  useEffect(() => {
    if (selectedOverview) {
      const selectedJob = jobsWithTimesheetDetails.find(
        (job) => job.dwoJobId === selectedOverview.dwoJobId,
      );
      dispatch(setCurrentJob(selectedJob));
    }

    if (!selectedOverview) {
      dispatch(setCurrentJob(undefined));
    }
  }, [selectedOverview, jobsWithTimesheetDetails, dispatch]);

  const handleToggleSelectedOverview = (clickedOverview: JobOverview) => () => {
    if (clickedOverview.dwoJobId === selectedOverview?.dwoJobId) {
      setSelectedOverview(undefined);
      return;
    }

    setSelectedOverview(clickedOverview);
  };

  const handleClickPrevious = () => {
    if (isPreviousButtonDisabled) return;
    setCarouselPosition(carouselPosition - 1);
  };

  const handleClickNext = () => {
    if (isNextButtonDisabled) return;
    setCarouselPosition(carouselPosition + 1);
  };

  const handleClickAddJob = () => setIsJobsModalOpen(true);
  const handleClickCloseModal = () => setIsJobsModalOpen(false);

  return (
    <Fragment>
      <Paper className={classes.paper}>
        <Box className={classes.container}>
          <Box display="flex" alignItems="center">
            <Typography className={classes.title} variant="h6">
              Hours Overview Per Job
            </Typography>
            <Box className={classes.iconAddBackground}>
              <IconButton onClick={handleClickAddJob}>
                <Add className={classes.iconAddColor} />
              </IconButton>
            </Box>
          </Box>
          {overviewDisplays.length !== 0 && (
            <Box display="flex">
              {!isPreviousButtonDisabled && (
                <Box className={classes.iconBackground}>
                  <IconButton onClick={handleClickPrevious}>
                    <NavigateBefore className={classes.iconColor} />
                  </IconButton>
                </Box>
              )}
              {isNextButtonDisabled && <Box className={classes.emptyIcon} />}
              {!isNextButtonDisabled && (
                <Box className={classes.iconBackground}>
                  <IconButton onClick={handleClickNext}>
                    <NavigateNext className={classes.iconColor} />
                  </IconButton>
                </Box>
              )}
            </Box>
          )}
        </Box>
        <Box display="flex" width="100%">
          {overviewDisplays.length === 0 ? (
            <Box
              display="flex"
              width="100%"
              height="119px"
              justifyContent="center"
              alignItems="center"
            >
              <Typography className={classes.emptyStateMessage} align="center">
                Nothing to display
              </Typography>
            </Box>
          ) : (
            <Fragment>
              <ToggleJobTotalHours
                isActive={
                  overviewOnDisplay.firstOverview?.dwoJobId ===
                  selectedOverview?.dwoJobId
                }
                hours={overviewOnDisplay.firstOverview?.totalHours}
                jobId={overviewOnDisplay.firstOverview?.jobId}
                jobType={overviewOnDisplay.firstOverview?.jobType}
                onToggle={handleToggleSelectedOverview(
                  overviewOnDisplay.firstOverview as JobOverview,
                )}
              />
              <Box display="flex" marginLeft="28px" width="100%">
                {overviewOnDisplay.secondOverview && (
                  <ToggleJobTotalHours
                    isActive={
                      overviewOnDisplay.secondOverview.dwoJobId ===
                      selectedOverview?.dwoJobId
                    }
                    hours={overviewOnDisplay.secondOverview.totalHours}
                    jobId={overviewOnDisplay.secondOverview.jobId}
                    jobType={overviewOnDisplay.secondOverview.jobType}
                    onToggle={handleToggleSelectedOverview(
                      overviewOnDisplay.secondOverview,
                    )}
                  />
                )}
              </Box>
            </Fragment>
          )}
        </Box>
      </Paper>
      {isJobsModalOpen && (
        <JobsModal
          isOpen={isJobsModalOpen}
          onClickCancel={handleClickCloseModal}
        />
      )}
    </Fragment>
  );
}
