import {
  Box,
  Button,
  createStyles,
  Grid,
  makeStyles,
  Paper,
  Theme,
  Typography,
} from "@material-ui/core";
import RefreshIcon from "@material-ui/icons/Refresh";
import { RangeDatePicker } from "components/RangeDatePicker";
import { TableCustom } from "components/table/TableCustom";
import {
  getAllUploadHistory,
  selectIsLoading,
  selectUploadHistory,
} from "features/upload/uploadEmployeeSlice";
import { cloneDeep } from "lodash";
import { DateRange } from "materialui-daterange-picker";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ServiceOptions } from "@dwo/shared/dist/services/baseService";
import { MainTableRow } from "utils/tableUtils";
import {
  getUploadHistoryRows,
  uploadHistoryTableColumns,
  initialUploadHistoryQuery,
} from "utils/uploadHistoryUtils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    caption: {
      fontSize: "16px",
      color: theme.palette.grey[500],
      fontWeight: 400,
    },
    datePickerContainer: {
      justifyContent: "flex-end",
      padding: "24px 24px 24px 0",
      [theme.breakpoints.down("xs")]: {
        justifyContent: "flex-start",
        padding: "0 24px 24px 24px",
      },
    },
    header: {
      fontSize: "24px",
      color: theme.palette.primary.main,
      fontWeight: 400,
      marginBottom: "16px",
    },
    refreshButton: {
      marginRight: "16px",
    },
  }),
);

export function UploadHistory() {
  const dispatch = useDispatch();
  const isLoading = useSelector(selectIsLoading);
  const uploadHistory = useSelector(selectUploadHistory);
  const [query, setQuery] = useState<ServiceOptions>(initialUploadHistoryQuery);
  const [sortingOptions, setSortingOptions] = useState<string[][]>([]);
  const classes = useStyles();

  const rows: MainTableRow[] = getUploadHistoryRows(uploadHistory);

  useEffect(() => {
    dispatch(getAllUploadHistory(query));
  }, [query, dispatch]);

  const handleApplyRangeDate = (dateRange: DateRange) => {
    setQuery((prevQuery) => {
      const oldQuery = cloneDeep(prevQuery);
      const startDate = dateRange.startDate;
      const endDate = dateRange.endDate;

      startDate?.setHours(0, 0, 0, 0);
      endDate?.setHours(23, 59, 59, 999);

      return {
        ...oldQuery,
        where: {
          ...oldQuery.where,
          updatedAt: { $gte: startDate, $lte: endDate },
        },
      };
    });
  };

  const handleClickSort = (sortingValues: string[][]) => {
    setSortingOptions(sortingValues);
    setQuery((prevQuery) => {
      return {
        ...cloneDeep(prevQuery),
        order: sortingValues.length > 0 ? sortingValues : undefined,
      };
    });
  };

  const handleReload = () => {
    dispatch(getAllUploadHistory(query));
  };

  return (
    <Box padding="0 24px 24px 24px">
      <Paper>
        <Grid container>
          <Grid item sm={6} xs={12}>
            <Box padding="24px">
              <Typography className={classes.header}>Upload History</Typography>
              <Typography className={classes.caption}>Summary</Typography>
            </Box>
          </Grid>

          <Grid item sm={6} xs={12}>
            <Box className={classes.datePickerContainer} display="flex">
              <Button
                variant="outlined"
                className={classes.refreshButton}
                onClick={handleReload}
              >
                <RefreshIcon />
              </Button>
              <RangeDatePicker
                maxDate={new Date()}
                onClickApply={handleApplyRangeDate}
              />
            </Box>
          </Grid>

          <Grid item xs={12}>
            <TableCustom
              columns={uploadHistoryTableColumns}
              isLoading={isLoading}
              noRowsMessage="No files uploaded yet."
              rows={rows}
              sortingOptions={sortingOptions}
              onClickSort={handleClickSort}
            />
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
}
