import {
  AppBar,
  createStyles,
  makeStyles,
  Tab,
  Tabs,
  Theme,
} from "@material-ui/core";
import React from "react";

interface ModalTabsProps {
  activeTab: number;
  firstLabel: string;
  secondLabel: string;
  onChange: (event: React.ChangeEvent<unknown>, newValue: number) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    shadowLeft: {
      backgroundImage:
        "linear-gradient(270deg, rgba(131,131,133,1) 90%, rgba(31,31,31,1) 100%, rgba(0,0,0,1) 100%)",
      color: "white",
      font: `normal normal bold 14px/19px ${theme.typography.fontFamily}`,
      textTransform: "capitalize",
      maxWidth: "none",
      flexGrow: 1,
      minHeight: "40px",
    },
    shadowRight: {
      backgroundImage:
        "linear-gradient(90deg, rgba(131,131,133,1) 90%, rgba(31,31,31,1) 100%, rgba(0,0,0,1) 100%)",
      color: "white",
      font: `normal normal bold 14px/19px ${theme.typography.fontFamily}`,
      textTransform: "capitalize",
      maxWidth: "none",
      flexGrow: 1,
      minHeight: "40px",
    },
    activeTab: {
      backgroundImage:
        "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(0,0,0,1) 0%, rgba(255,255,255,1) 0%)",
      boxShadow: "0px -4px 3px rgba(50, 50, 50, 0.75)",
      color: "#384265",
      font: `normal normal bold 14px/19px ${theme.typography.fontFamily}`,
      outline: "none",
      textTransform: "capitalize",
      maxWidth: "none",
      flexGrow: 1,
      minHeight: "40px",
    },
    tabsContainer: {
      height: "40px",
      boxShadow: "none",
    },
    indicator: {
      display: "none",
      outline: "none",
    },
    root: {
      flexGrow: 1,
    },
  }),
);

export function ModalTabs({
  activeTab,
  firstLabel,
  secondLabel,
  onChange,
}: ModalTabsProps) {
  const classes = useStyles();

  const getGradients = (index: number) => {
    if (index === 0) {
      return classes.shadowLeft;
    }
    return classes.shadowRight;
  };

  const getA11yProps = (index: number) => {
    return {
      id: `tab-${index}`,
      "aria-controls": `tabPanel-${index}`,
    };
  };

  return (
    <div className={classes.root}>
      <AppBar className={classes.tabsContainer} position="static">
        <Tabs
          classes={{ indicator: classes.indicator }}
          value={activeTab}
          onChange={onChange}
          aria-label="User details tabs"
        >
          <Tab
            classes={{ selected: classes.activeTab }}
            className={getGradients(activeTab)}
            label={firstLabel}
            {...getA11yProps(0)}
          />
          <Tab
            classes={{ selected: classes.activeTab }}
            className={getGradients(activeTab)}
            label={secondLabel}
            {...getA11yProps(1)}
          />
        </Tabs>
      </AppBar>
    </div>
  );
}
