import React from "react";
import { useSelector } from "react-redux";
import { selectIsLoading } from "./loaderSlice";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  CircularProgress,
  Box,
} from "@material-ui/core";

interface ILoaderProps {
  message?: string;
}

export function Loader(props: ILoaderProps) {
  const { message = "Loading..." } = props;
  const isLoading = useSelector(selectIsLoading);

  return (
    <Dialog open={isLoading} disableBackdropClick disableEscapeKeyDown>
      <DialogContent>
        <Box display="flex">
          <Box marginRight="8px" paddingTop="2px">
            <CircularProgress size={20} />
          </Box>
          <DialogContentText>{message}</DialogContentText>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
