import { Box, Typography } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";
import { ROOT_HOME } from "routes/Roots";

interface NotFoundScreenProps {
  header: string;
}

export const NotFoundScreen = ({
  header = "Page Not Found",
}: NotFoundScreenProps) => {
  return (
    <Box padding="48px" textAlign="center">
      <Typography
        component="h1"
        variant="h1"
        style={{ fontSize: "40px", marginBottom: "16px" }}
      >
        {header}
      </Typography>
      <Link to={ROOT_HOME}>
        <Typography component="p" variant="body1">
          Back to Home
        </Typography>
      </Link>
    </Box>
  );
};
