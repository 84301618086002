import { makeStyles, Theme, createStyles } from "@material-ui/core";
import { DropdownOption } from "components/Dropdown";

export interface MainTableColumn {
  field: string;
  headerName?: string;
  hasSorting?: boolean;
  isCheckedColumn?: boolean;
  hasCheckbox?: boolean;
  onClickCheckbox?: (isChecked: boolean) => void;
}

export interface MainTableRow {
  idRow?: string;
  innerCells: MainTableCell[];
  isCheckedRow?: boolean;
  onClickCheckbox?: (idRow: string, isChecked: boolean) => void;
  onClickDelete?: (id: string) => void;
  onChangeRadio?: (idRow: string, isChecked: boolean) => void;
}

export interface MainTableCell {
  value: string | JSX.Element;
  isImage?: boolean;
  isForeman?: boolean;
  isDriver?: boolean;
  isHazardMissing?: boolean;
  isPhotoMismatch?: boolean;
  isLink?: boolean;
  hasAlert?: boolean;
  linkTo?: string;
}

export const mainTableStyles = makeStyles((theme: Theme) =>
  createStyles({
    footerRow: {
      backgroundColor: theme.palette.grey[100],
      "& > .MuiTableCell-body": {
        borderBottom: `1px solid ${theme.palette.grey[200]}`,
      },
      "& > .MuiTableCell-body p": {
        color: theme.palette.grey[900],
      },
      "& .MuiTableCell-root:first-child": {
        paddingLeft: "24px",
      },
    },
    row: {
      "& > .MuiTableCell-body": {
        color: theme.palette.grey[500],
        borderBottom: `1px solid ${theme.palette.grey[200]}`,
      },
      "& .MuiTableCell-root:first-child": {
        paddingLeft: "24px",
      },
    },
    title: {
      color: theme.palette.primary.dark,
      textTransform: "uppercase",
      fontWeight: "bold",
      width: "max-content",
    },
    header: {
      borderBottom: `2px solid ${theme.palette.grey[200]}`,
      borderTop: `2px solid ${theme.palette.grey[200]}`,
      "& .MuiTableCell-root:first-child": {
        paddingLeft: "24px",
      },
    },
    iconButton: {
      padding: "4px",
    },
    link: {
      textDecoration: "underline",
      color: theme.palette.primary.light,
    },
    textCell: {
      color: theme.palette.grey["500"],
      width: "max-content",
    },
    radio: {
      color: theme.palette.primary.dark,
      "&$checked": {
        color: theme.palette.primary.dark,
      },
    },
    checked: {},
  }),
);

export const rowSelectOptions: DropdownOption[] = [
  {
    label: "10",
    value: "10",
  },
  {
    label: "15",
    value: "15",
  },
  {
    label: "20",
    value: "20",
  },
  {
    label: "25",
    value: "25",
  },
  {
    label: "30",
    value: "30",
  },
];
