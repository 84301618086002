import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, RootState } from "app/store";
import { getAllCrews } from "features/crew/crewSlice";
import { error } from "features/error/errorSlice";
import {
  hide as hideLoader,
  show as showLoader,
} from "features/loader/loaderSlice";
import { EmployeeCrewModel } from "@dwo/shared/dist/models/employeeCrewModel";
import { ServiceOptions } from "@dwo/shared/dist/services/baseService";
import { employeeCrewService } from "@dwo/shared/dist/services/employeeCrewService";
import { employeeService } from "@dwo/shared/dist/services/employeeService";
import { DEFAULT_LIMIT } from "utils/sharedUtils";

interface employeeCrewState {
  count: number;
  employeeCrew?: EmployeeCrewModel;
  employeeCrews?: EmployeeCrewModel[];
  limit?: number;
  message?: string;
  offset?: number;
  order?: any;
}

const initialState: employeeCrewState = {
  count: 0,
  employeeCrew: undefined,
  employeeCrews: [],
  limit: DEFAULT_LIMIT,
  message: "",
  offset: 0,
  order: undefined,
};

export const employeeCrewSlice = createSlice({
  name: "employeeCrew",
  initialState,
  reducers: {
    setCount: (state, action: PayloadAction<number>) => {
      state.count = action.payload;
    },
    setEmployeeCrew: (state, action: PayloadAction<EmployeeCrewModel>) => {
      state.employeeCrew = action.payload;
    },
    setEmployeeCrews: (state, action: PayloadAction<EmployeeCrewModel[]>) => {
      state.employeeCrews = action.payload;
    },
    setLimit: (state, action: PayloadAction<number>) => {
      state.limit = action.payload;
    },
    setMessage: (state, action: PayloadAction<string>) => {
      state.message = action.payload;
    },
    setOffset: (state, action: PayloadAction<number>) => {
      state.offset = action.payload;
    },
    setOrder: (state, action: PayloadAction<any>) => {
      state.order = action.payload;
    },
  },
});

export const {
  setCount,
  setEmployeeCrew,
  setEmployeeCrews,
  setLimit,
  setMessage,
  setOffset,
  setOrder,
} = employeeCrewSlice.actions;

export const getAllEmployeeCrews = (
  options?: ServiceOptions,
  loadPictures?: boolean,
): AppThunk => async (dispatch) => {
  try {
    const { data, count, limit, offset } = await employeeCrewService.getAll(
      options,
    );

    if (loadPictures) {
      const employeeCrewIds = data.reduce(
        (acc: number[], crewMember: EmployeeCrewModel) => {
          const parsedId =
            typeof crewMember.employeeId === "string"
              ? parseInt(crewMember.employeeId, 10)
              : crewMember.employeeId;

          if (!acc.includes(parsedId)) {
            return [...acc, parsedId];
          }
          return acc;
        },
        [],
      );
      const { data: employeeProfiles } = await employeeService.getProfiles(
        employeeCrewIds,
        undefined,
        true,
      );

      data.map((crewMember) => {
        if (crewMember.employee) {
          crewMember.employee.pictureUrl = employeeProfiles.find(
            (employee) => (employee.id as number) === crewMember.employeeId,
          )?.pictureUrl;

          return crewMember;
        }
        return crewMember;
      });
    }

    dispatch(setEmployeeCrews(data));
    dispatch(setLimit(limit));
    dispatch(setCount(count));
    dispatch(setOffset(offset));
  } catch (e) {
    dispatch(
      error(
        {
          title: "Could not get all Employee Crews",
          message: e.message,
        },
        () => dispatch(getAllEmployeeCrews(options)),
      ),
    );
  }
};

export const createEmployeeCrew = (
  employeeCrew: EmployeeCrewModel,
  loadCrews?: boolean,
  options?: ServiceOptions,
): AppThunk => async (dispatch) => {
  try {
    dispatch(showLoader());
    const { data } = await employeeCrewService.create(employeeCrew);
    dispatch(setEmployeeCrew(data));
    if (loadCrews) {
      dispatch(getAllCrews(options));
    }
  } catch (err) {
    dispatch(
      error(
        {
          title: "Something went wrong",
          message: err.message,
        },
        () => dispatch(createEmployeeCrew(employeeCrew, loadCrews, options)),
      ),
    );
  } finally {
    dispatch(hideLoader());
  }
};

export const updateEmployeeCrew = (
  id: number | string,
  employeeCrew: EmployeeCrewModel,
  loadCrews?: boolean,
  options?: ServiceOptions,
): AppThunk => async (dispatch) => {
  try {
    dispatch(showLoader());
    const { data } = await employeeCrewService.update(id, employeeCrew);
    dispatch(setEmployeeCrew(data));
    if (loadCrews) {
      dispatch(getAllCrews(options));
    }
  } catch (err) {
    dispatch(
      error(
        {
          title: "Failed to update crew member",
          message: err.message,
        },
        () =>
          dispatch(updateEmployeeCrew(id, employeeCrew, loadCrews, options)),
      ),
    );
  } finally {
    dispatch(hideLoader());
  }
};

export const deleteEmployeeCrew = (
  id: number,
  options?: ServiceOptions,
): AppThunk => async (dispatch) => {
  try {
    dispatch(showLoader());
    await employeeCrewService.delete(id);
    dispatch(getAllCrews(options));
  } catch (e) {
    dispatch(
      error(
        {
          title: "Something went wrong",
          message: e.message,
        },
        () => dispatch(deleteEmployeeCrew(id, options)),
      ),
    );
  } finally {
    dispatch(hideLoader());
  }
};

export const selectEmployeeCrew = (state: RootState) =>
  state.employeeCrew.employeeCrew;
export const selectEmployeeCrews = (state: RootState) =>
  state.employeeCrew.employeeCrews;

export default employeeCrewSlice.reducer;
