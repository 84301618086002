import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, RootState } from "app/store";
import { error } from "features/error/errorSlice";
import { UploadErrorsModel } from "@dwo/shared/dist/models/uploadErrorsModel";
import { UploadHistoryModel } from "@dwo/shared/dist/models/uploadHistoryModel";
import { ServiceOptions } from "@dwo/shared/dist/services/baseService";
import { uploadEmployeeErrorsService } from "@dwo/shared/dist/services/uploadEmployeeErrorsService";
import { uploadEmployeeService } from "@dwo/shared/dist/services/uploadEmployeeService";
import { initialUploadHistoryQuery } from "utils/uploadHistoryUtils";
import { DEFAULT_LIMIT } from "utils/sharedUtils";

interface uploadEmployeeState {
  count: number;
  isLoading: boolean;
  isLoadingErrors: boolean;
  limit?: number;
  offset?: number;
  order?: any;
  uploadErrors: UploadErrorsModel[];
  uploadHistoryDetails?: UploadHistoryModel;
  uploadHistory: UploadHistoryModel[];
}

const initialState: uploadEmployeeState = {
  count: 0,
  isLoading: false,
  isLoadingErrors: false,
  limit: DEFAULT_LIMIT,
  offset: 0,
  order: undefined,
  uploadErrors: [],
  uploadHistoryDetails: undefined,
  uploadHistory: [],
};

export const uploadEmployeeSlice = createSlice({
  name: "uploadEmployee",
  initialState,
  reducers: {
    setCount: (state, action: PayloadAction<number>) => {
      state.count = action.payload;
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setIsLoadingErrors: (state, action: PayloadAction<boolean>) => {
      state.isLoadingErrors = action.payload;
    },
    setLimit: (state, action: PayloadAction<number>) => {
      state.limit = action.payload;
    },
    setOffset: (state, action: PayloadAction<number>) => {
      state.offset = action.payload;
    },
    setOrder: (state, action: PayloadAction<any>) => {
      state.order = action.payload;
    },
    setUploadErrors: (state, action: PayloadAction<UploadErrorsModel[]>) => {
      state.uploadErrors = action.payload;
    },
    setUploadHistory: (state, action: PayloadAction<UploadHistoryModel[]>) => {
      state.uploadHistory = action.payload;
    },
    setUploadHistoryDetails: (
      state,
      action: PayloadAction<UploadHistoryModel>,
    ) => {
      state.uploadHistoryDetails = action.payload;
    },
  },
});

export const {
  setCount,
  setIsLoading,
  setIsLoadingErrors,
  setLimit,
  setOffset,
  setOrder,
  setUploadHistoryDetails,
  setUploadHistory,
  setUploadErrors,
} = uploadEmployeeSlice.actions;

export const getAllUploadErrors = (
  options?: ServiceOptions,
): AppThunk => async (dispatch) => {
  dispatch(setIsLoadingErrors(true));
  try {
    const { data } = await uploadEmployeeErrorsService.getAll(options);
    dispatch(setUploadErrors(data));
  } catch (err) {
    dispatch(
      error(
        {
          title: "Could not retrieve Upload Errors.",
          message: err.message,
        },
        () => dispatch(getAllUploadErrors(options)),
      ),
    );
  } finally {
    dispatch(setIsLoadingErrors(false));
  }
};

export const getAllUploadHistory = (
  options?: ServiceOptions,
): AppThunk => async (dispatch) => {
  dispatch(setIsLoading(true));

  try {
    const {
      count,
      data,
      limit,
      offset,
      order,
    } = await uploadEmployeeService.getAll(options);

    dispatch(setCount(count));
    dispatch(setUploadHistory(data));
    dispatch(setLimit(limit));
    dispatch(setOffset(offset));
    dispatch(setOrder(order));
  } catch (err) {
    dispatch(
      error(
        {
          title: "Could not retrieve upload history",
          message: err.message,
        },
        () => dispatch(getAllUploadHistory(options)),
      ),
    );
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const getUploadErrorsCSV = (id: number): AppThunk => async (
  dispatch,
) => {
  try {
    await uploadEmployeeService.getUploadEmployeeErrorsCSV(id);
  } catch (err) {
    dispatch(
      error(
        {
          title: "Could not retrieve Upload Errors.",
          message: err.message,
        },
        () => dispatch(getUploadErrorsCSV(id)),
      ),
    );
  }
};

export const getUploadHistoryById = (
  id: number,
  options?: ServiceOptions,
): AppThunk => async (dispatch) => {
  dispatch(setIsLoading(true));
  try {
    const { data } = await uploadEmployeeService.getById(id, options);
    dispatch(setUploadHistoryDetails(data));
  } catch (err) {
    dispatch(
      error(
        {
          title: "Could not retrieve Upload History.",
          message: err.message,
        },
        () => dispatch(getUploadHistoryById(id, options)),
      ),
    );
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const uploadCsvFile = (file: File): AppThunk => async (dispatch) => {
  try {
    const formData = new FormData();
    formData.append("csv", file);
    await uploadEmployeeService.postEmployeeCsv(formData);
    dispatch(getAllUploadHistory(initialUploadHistoryQuery));
  } catch (err) {
    dispatch(
      error(
        {
          title: "Failed to upload file",
          message: err.message,
        },
        () => dispatch(uploadCsvFile(file)),
      ),
    );
  }
};

export const selectCount = (state: RootState) => state.uploadEmployee.count;
export const selectIsLoading = (state: RootState) =>
  state.uploadEmployee.isLoading;
export const selectIsLoadingErrors = (state: RootState) =>
  state.uploadEmployee.isLoadingErrors;
export const selectLimit = (state: RootState) => state.uploadEmployee.limit;
export const selectOffset = (state: RootState) => state.uploadEmployee.offset;
export const selectOrder = (state: RootState) => state.uploadEmployee.order;
export const selectUploadErrors = (state: RootState) =>
  state.uploadEmployee.uploadErrors;
export const selectUploadHistory = (state: RootState) =>
  state.uploadEmployee.uploadHistory;
export const selectUploadHistoryDetails = (state: RootState) =>
  state.uploadEmployee.uploadHistoryDetails;

export const uploadEmployeeReducer = uploadEmployeeSlice.reducer;
