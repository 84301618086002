import { GlobalModal } from "components/globalModal/GlobalModal";
import { ModalTabs } from "components/globalModal/ModalTabs";
import { ModalTabsContent } from "components/globalModal/ModalTabsContent";
import { JobModel } from "@dwo/shared/dist/models/jobModel";
import React from "react";
import {
  assignJobsModalColumns,
  getAssignJobsModalTableRows,
  getRemoveJobsModalTableRows,
  removeJobsModalColumns,
} from "utils/employeeDetailsUtils";
import { TableCustom } from "components/table/TableCustom";
import { noop } from "lodash/fp";

interface AllocationModalProps {
  activeTab: number;
  assignedJobs: JobModel[];
  availableJobs: JobModel[];
  isLoading: boolean;
  isOpen: boolean;
  selectedJobsIds: string[];
  onChangeTab: (event: React.ChangeEvent<unknown>, selectedTab: number) => void;
  onClickCancel: () => void;
  onClickCheckbox: (id: string, isChecked: boolean) => void;
  onClickDelete: (id: string) => void;
  onClickSave: () => void;
}

export function AllocationModal({
  activeTab,
  assignedJobs = [],
  availableJobs = [],
  isLoading = true,
  isOpen = false,
  selectedJobsIds = [],
  onChangeTab,
  onClickCancel,
  onClickCheckbox,
  onClickDelete,
  onClickSave,
}: AllocationModalProps) {
  const assignedContent = (
    <TableCustom
      columns={removeJobsModalColumns}
      isLoading={isLoading}
      noRowsMessage="No assigned jobs to display."
      rows={getRemoveJobsModalTableRows(assignedJobs, onClickDelete)}
      onClickSort={noop}
    />
  );

  const availableContent = (
    <TableCustom
      columns={assignJobsModalColumns}
      isLoading={isLoading}
      noRowsMessage="No available jobs to display."
      rows={getAssignJobsModalTableRows(
        availableJobs,
        selectedJobsIds,
        onClickCheckbox,
      )}
      onClickSort={noop}
    />
  );

  return (
    <GlobalModal
      modalMaxWidth="700px"
      showActionButtons={activeTab === 1}
      open={isOpen}
      Tabs={
        <ModalTabs
          activeTab={activeTab}
          firstLabel={`Assigned (${assignedJobs.length})`}
          secondLabel={`Available (${availableJobs.length})`}
          onChange={onChangeTab}
        />
      }
      title="Assignments"
      onClickContinue={onClickSave}
      onClickClose={onClickCancel}
    >
      <ModalTabsContent
        activeTab={activeTab}
        FirstTabContent={assignedContent}
        SecondTabContent={availableContent}
      />
    </GlobalModal>
  );
}
