import { createStyles, makeStyles, Theme } from "@material-ui/core";
import React from "react";
import { TooltipLabelOption } from "utils/summaryUtils";

interface StyleProps {
  categoryColor?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bottomLabelContainer: {
      display: "flex",
      justifyContent: "center",
      width: "100%",
    },
    categoryLabel: {
      color: ({ categoryColor }: StyleProps) => categoryColor,
      fontSize: "0.75rem",
      fontWeight: 600,
      textAlign: "center",
      width: "100%",
    },
    hoursLabel: {
      color: theme.palette.success.main,
      fontSize: "0.75rem",
      fontWeight: 600,
    },
    label: {
      color: theme.palette.primary.dark,
      fontSize: "0.75rem",
      fontWeight: 600,
      textAlign: "center",
      width: "100%",
    },
    labelText: {
      color: theme.palette.primary.dark,
      fontSize: "0.75rem",
      fontWeight: 600,
    },
    tooltip: {
      backgroundColor: theme.palette.grey[100],
      borderRadius: "3px",
      boxShadow: "4px 4px 8px #00000036",
      display: "flex",
      flexDirection: "column",
      fontFamily: "Noto Sans",
      padding: "8px",
    },
    totalLabel: {
      color: theme.palette.grey[500],
      fontSize: "0.75rem",
      fontWeight: 600,
    },
  }),
);

interface BarChartTooltipProps {
  cost: string;
  date: string;
  hours: string;
  subDate?: string;
  tooltipOption?: TooltipLabelOption;
}

export function BarChartTooltip({
  cost,
  date,
  hours,
  subDate,
  tooltipOption,
}: BarChartTooltipProps) {
  const classes = useStyles({ categoryColor: tooltipOption?.color });

  // I'm using HTML 'cuz using MUI can cause some bugs when displaying typographies
  return (
    <div className={classes.tooltip}>
      <span className={classes.label}>{date}</span>
      {subDate && <span className={classes.label}>{subDate}</span>}
      {tooltipOption && (
        <span className={classes.categoryLabel}>{tooltipOption.label}</span>
      )}
      <div className={classes.bottomLabelContainer}>
        <span className={classes.hoursLabel}>{hours}</span>
        <span className={classes.labelText}>&nbsp;Hours /&nbsp;</span>
        <span className={classes.totalLabel}>
          {parseFloat(cost).toLocaleString("en")}
        </span>
        <span className={classes.labelText}>&nbsp;$</span>
      </div>
    </div>
  );
}
