import React, { FC } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { AppChangelogProps } from "screens/AppChangelogScreen";

export type VersionCardProps = {
  version: AppChangelogProps;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    versionCard: {
      margin: "16px 16px 0px 23px",
    },
  }),
);

const VersionCardModule: FC<VersionCardProps> = ({ version }) => {
  const classes = useStyles();

  return <div className={classes.versionCard}>{version.description}</div>;
};

export default VersionCardModule;
