import { GoogleIcon } from "./GoogleIcons";
import { MicrosoftIcon } from "./MicrosoftIcon";
import { SortArrowDownIcon } from "./SortArrowDownIcon";
import { SortArrowUpIcon } from "./SortArrowUpIcon";
import { SortArrowsIcon } from "./SortArrowsIcon";

export const Google = GoogleIcon;
export const Microsoft = MicrosoftIcon;
export const SortArrowDown = SortArrowDownIcon;
export const SortArrowUp = SortArrowUpIcon;
export const SortArrows = SortArrowsIcon;
