import {
  createStyles,
  FormControl,
  makeStyles,
  MenuItem,
  Select,
  Theme,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import React from "react";

export interface DropdownOption {
  label: string;
  value: string | number | any;
}

interface DropdownProps {
  height?: string;
  id?: string;
  isDisabled?: boolean;
  isRequired?: boolean;
  label: string;
  name?: string;
  options: DropdownOption[];
  selectedValue?: string;
  onSelectOption: (newSelection: string) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    itemLabel: {
      justifyContent: "flex-start",
    },
    itemRoot: {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
    paper: {
      backgroundColor: "#F0EFEF",
      borderRadius: "2px",
      boxShadow: "1px 1px 8px #0000001A",
      color: "#838385",
      display: "flex",
      flexDirection: "column",
      marginTop: "8px",
    },
  }),
);

export function Dropdown({
  height = "48px",
  id = "dropdownSelector",
  isDisabled = false,
  isRequired = false,
  label,
  name = "dropdownSelector",
  options,
  selectedValue,
  onSelectOption,
}: DropdownProps) {
  const classes = useStyles();

  const handleSelectOption = (event: React.ChangeEvent<{ value: unknown }>) => {
    onSelectOption(event.target.value as string);
  };

  const renderValue = () => {
    if (selectedValue) {
      return options.find(
        (option: DropdownOption) => option.value === selectedValue,
      )?.label;
    }

    return label;
  };

  return (
    <FormControl disabled={isDisabled} fullWidth variant="outlined">
      <Select
        style={{ height }}
        autoWidth
        displayEmpty
        IconComponent={ExpandMore}
        inputProps={{
          name,
          id,
        }}
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          PopoverClasses: {
            paper: classes.paper,
          },
          getContentAnchorEl: undefined,
          transformOrigin: {
            vertical: "top",
            horizontal: "left",
          },
        }}
        renderValue={renderValue}
        required={isRequired}
        value={selectedValue}
        onChange={handleSelectOption}
      >
        {options.map((option: DropdownOption, index: number) => (
          <MenuItem
            classes={{
              root: index !== options.length - 1 ? classes.itemRoot : undefined,
            }}
            key={option.value}
            value={option.value}
          >
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
