import { WorkShiftLocationType } from "@dwo/shared/dist/models/workShiftModel";
import { createStyles, makeStyles, Theme, useTheme } from "@material-ui/core";
import { Box } from "@material-ui/core/";
import { DeviceUnknownOutlined, Flag, LocationCity } from "@material-ui/icons";
import { noop } from "lodash";
import React from "react";

interface TableDisplayDateLabelProps {
  date: string;
  locationType?: WorkShiftLocationType;
  location?: string;
}

interface StyleProps {
  hasLocation?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    building: {
      color: "#000000",
      fontSize: "1.125rem",
      marginRight: "10px",
    },
    flag: {
      color: theme.palette.primary.main,
      fontSize: "1.125rem",
      marginRight: "10px",
    },
    unknown: {
      color: theme.palette.grey["500"],
      fontSize: "1.125rem",
      marginRight: "10px",
    },
    container: {
      cursor: ({ hasLocation }: StyleProps) =>
        hasLocation ? "pointer" : "unset",
    },
  }),
);

export function TableDisplayDateLabel({
  date,
  locationType,
  location,
}: TableDisplayDateLabelProps) {
  const theme = useTheme();
  const classes = useStyles({ hasLocation: location });
  const getLocationIcon = () => {
    switch (locationType) {
      case WorkShiftLocationType.YARD:
        return <LocationCity className={classes.building} />;
      case WorkShiftLocationType.SITE:
        return <Flag className={classes.flag} />;
      case WorkShiftLocationType.UNKNOWN:
        return <DeviceUnknownOutlined className={classes.unknown} />;
      default:
        return null;
    }
  };

  const handleClickBox = () => {
    const coords = location?.split(",");
    if (coords) {
      window.open(`https://maps.google.com/?q=${coords[0]},${coords[1]}`);
    }
  };

  return (
    <Box
      display="flex"
      borderBottom={`1px solid ${theme.palette.primary.dark}`}
      paddingBottom="10px"
      className={classes.container}
      onClick={location ? handleClickBox : noop}
    >
      {getLocationIcon()}
      {date}
    </Box>
  );
}
