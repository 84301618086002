import { createStyles, makeStyles, Theme, Typography } from "@material-ui/core";
import React from "react";
import differenceInMinutes from "date-fns/differenceInMinutes";
import { decimalTimeToHoursTime } from "utils/dateUtils";

interface DateTimeDiffLabelProps {
  start: string;
  end: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    label: {
      color: theme.palette.grey[700],
      fontWeight: 600,
      marginTop: "9px",
    },
  }),
);

export const getDateRageTimeDifference = (start: Date, end: Date) => {
  const difference = Math.abs(differenceInMinutes(end, start) / 60);
  if (difference != null) {
    return decimalTimeToHoursTime(difference);
  }

  return "-";
};

export function DateTimeDiffLabel({ start, end }: DateTimeDiffLabelProps) {
  const classes = useStyles();
  const totalTimeDifference = getDateRageTimeDifference(
    new Date(start),
    new Date(end),
  );
  return (
    <Typography className={classes.label} variant="body2">
      {totalTimeDifference}
    </Typography>
  );
}
