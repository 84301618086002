import {
  JobCategories,
  JobsSummaryGeneralData,
  JobsSummaryOverviewCategory,
  JobsSummaryOverviewDataElement,
  JobsSummaryOverviewDataPerMonth,
  JobsSummaryOverviewDataPerWeek,
  JobsSummaryWorkOrder,
  JobSummaryJobsTypes,
} from "@dwo/shared/dist/models/jobSummaryModel";
import { RegionModel } from "@dwo/shared/dist/models/regionModel";
import { TimesheetStatusPercentageData } from "@dwo/shared/dist/models/timesheetSummaryModel";
import { ChartTooltipModel } from "chart.js";
import { DropdownOption } from "components/Dropdown";
import {
  addQuarters,
  differenceInCalendarMonths,
  differenceInCalendarQuarters,
  differenceInDays,
  endOfWeek,
  format,
  isSameDay,
  isSameMonth,
  isSameQuarter,
  isSameYear,
  isSunday,
  startOfWeek,
} from "date-fns";
import { cloneDeep } from "lodash";
import {
  addLocalTimeOffset,
  enDashFormatDate,
  getDateRangeFromStringDateRange,
  slashFormatDate,
  StringDateRange,
} from "./dateUtils";
import { categoryOptions } from "./jobFormUtils";
import { ALL_OPTION } from "./sharedUtils";

export enum SummaryTabs {
  Job,
  Timesheet,
  Roster,
}

export enum OverviewTabs {
  LeftTab,
  RightTab,
}

export enum OverviewChartsDateRangeCategories {
  PerSingleDay, // [1, 1],
  PerDay, // (1, 21],
  PerWeek, // (21, 1 month],
  PerMonth, // (1 month, one year],
}

export enum StatusTimesheetSummary {
  Approved = "approved",
  Submitted = "submitted",
  Draft = "draft",
}

export interface TooltipLabelOption {
  color: string;
  label: string;
}

// This colors doesn't belong to the theme. One use mostly
export enum JobCategoriesColors {
  OhElectric = "#0A47AA",
  UgGas = "#1971FF",
  UgElectric = "#30B8FF",
  CasIron = "#19FFE8",
  Overhead = "#16B8B8",
  General = "#15D943",
}

export const summaryJobCategories: DropdownOption[] = [
  ALL_OPTION,
  ...categoryOptions,
];

const ohElectric: JobsSummaryOverviewCategory = {
  category: JobCategories.OhElectric,
  cost: "0.0",
  hours: "0.0",
};
const ugGas: JobsSummaryOverviewCategory = {
  category: JobCategories.UgGas,
  cost: "0.0",
  hours: "0.0",
};
const ugElectric: JobsSummaryOverviewCategory = {
  category: JobCategories.UgElectric,
  cost: "0.0",
  hours: "0.0",
};
const castIron: JobsSummaryOverviewCategory = {
  category: JobCategories.CastIron,
  cost: "0.0",
  hours: "0.0",
};
const overhead: JobsSummaryOverviewCategory = {
  category: JobCategories.Overhead,
  cost: "0.0",
  hours: "0.0",
};
const general: JobsSummaryOverviewCategory = {
  category: JobCategories.General,
  cost: "0.0",
  hours: "0.0",
};

export const createCustomPieChartTooltip = (
  tooltipModel: ChartTooltipModel,
  tooltipId: string,
  tooltipHTMLStringComponent: string,
  chartCanvasRef: React.MutableRefObject<HTMLCanvasElement>,
) => {
  let tooltipEl = document.getElementById(tooltipId);

  if (!tooltipEl) {
    tooltipEl = document.createElement("div");
    tooltipEl.id = tooltipId;
    document.body.appendChild(tooltipEl);
  }

  if (tooltipModel.opacity === 0) {
    tooltipEl.style.opacity = "0";
    return;
  }

  const position = chartCanvasRef.current.getBoundingClientRect();

  tooltipEl.innerHTML = tooltipHTMLStringComponent;
  tooltipEl.style.opacity = "1";
  tooltipEl.style.position = "absolute";
  tooltipEl.style.left = `${
    position.left + window.pageXOffset + tooltipModel.caretX
  }px`;
  tooltipEl.style.top = `${
    position.top + window.pageYOffset + tooltipModel.caretY
  }px`;
  tooltipEl.style.pointerEvents = "none";
};

export const getCategoriesChartData = (
  categoriesData: JobsSummaryOverviewCategory[],
) => {
  let totalHours = 0.0;
  const ohElectric = {
    cost: 0.0,
    hours: 0.0,
    label: JobCategories.OhElectric,
  };
  const ugGas = { cost: 0.0, hours: 0.0, label: JobCategories.UgGas };
  const ugElectric = {
    cost: 0.0,
    hours: 0.0,
    label: JobCategories.UgElectric,
  };
  const castIron = { cost: 0.0, hours: 0.0, label: JobCategories.CastIron };
  const overhead = { cost: 0.0, hours: 0.0, label: JobCategories.Overhead };
  const general = { cost: 0.0, hours: 0.0, label: JobCategories.General };

  categoriesData.forEach((categoryElement: JobsSummaryOverviewCategory) => {
    const costToAdd = parseFloat(categoryElement.cost);
    const hoursToAdd = parseFloat(categoryElement.hours);

    totalHours += hoursToAdd;

    if (categoryElement.category === JobCategories.OhElectric) {
      ohElectric.cost = ohElectric.cost + costToAdd;
      ohElectric.hours = ohElectric.hours + hoursToAdd;
      return;
    }

    if (categoryElement.category === JobCategories.UgGas) {
      ugGas.cost = ugGas.cost + costToAdd;
      ugGas.hours = ugGas.hours + hoursToAdd;
      return;
    }

    if (categoryElement.category === JobCategories.UgElectric) {
      ugElectric.cost = ugElectric.cost + costToAdd;
      ugElectric.hours = ugElectric.hours + hoursToAdd;
      return;
    }

    if (categoryElement.category === JobCategories.CastIron) {
      castIron.cost = castIron.cost + costToAdd;
      castIron.hours = castIron.hours + hoursToAdd;
      return;
    }

    if (categoryElement.category === JobCategories.Overhead) {
      overhead.cost = overhead.cost + costToAdd;
      overhead.hours = overhead.hours + hoursToAdd;
      return;
    }

    if (categoryElement.category === JobCategories.General) {
      general.cost = general.cost + costToAdd;
      general.hours = general.hours + hoursToAdd;
      return;
    }
  });

  return {
    totalHours,
    categoriesData: [
      ohElectric,
      ugGas,
      ugElectric,
      castIron,
      overhead,
      general,
    ],
  };
};

export const getCategoriesChartDataAsMap = (
  categoriesData: JobsSummaryOverviewCategory[],
) => {
  let totalCost = 0.0;
  let totalHours = 0.0;
  const categoriesMap: Map<
    JobCategories,
    JobsSummaryOverviewCategory
  > = new Map([
    [JobCategories.OhElectric, { ...ohElectric }],
    [JobCategories.UgGas, { ...ugGas }],
    [JobCategories.UgElectric, { ...ugElectric }],
    [JobCategories.CastIron, { ...castIron }],
    [JobCategories.Overhead, { ...overhead }],
    [JobCategories.General, { ...general }],
  ]);

  categoriesData.forEach((categoryElement: JobsSummaryOverviewCategory) => {
    const costToAdd = parseFloat(categoryElement.cost);
    const hoursToAdd = parseFloat(categoryElement.hours);

    totalCost += costToAdd;
    totalHours += hoursToAdd;

    const categoryMapData = categoriesMap.get(categoryElement.category);

    if (!categoryMapData) return;

    const { cost, hours } = categoryMapData;

    categoriesMap.set(categoryElement.category, {
      ...categoryMapData,
      cost: (parseFloat(cost) + costToAdd).toString(),
      hours: (parseFloat(hours) + hoursToAdd).toString(),
    });
  });

  return {
    totalCost,
    totalHours,
    categoriesMapData: categoriesMap,
  };
};

export const getBusinessDaysBetweenDates = (startDate: Date, endDate: Date) => {
  const currentDate = new Date(startDate);
  const currentEndDate = new Date(endDate);
  const dates: Date[] = [];

  currentDate.setHours(0, 0, 0, 0);
  currentEndDate.setHours(0, 0, 0, 0);

  while (currentDate <= currentEndDate) {
    if (!isSunday(currentDate)) {
      dates.push(new Date(currentDate));
    }

    currentDate.setDate(currentDate.getDate() + 1);
  }

  return dates;
};

export const getEmptyDataset = (totalDays: number) => {
  const emptyDataset: number[] = [];

  for (let i = 0; i < totalDays; i++) {
    emptyDataset.push(0);
  }

  return emptyDataset;
};

export const getPerDayChartData = (
  data: JobsSummaryOverviewDataElement[],
  dateRange: StringDateRange,
) => {
  const { startDate, endDate } = getDateRangeFromStringDateRange(dateRange);
  const daysArr = getBusinessDaysBetweenDates(startDate, endDate);
  let totalWeekCost = 0.0;
  let totalWeekHours = 0.0;
  const dateLabels: string[] = [];
  const totalWorkHoursArray: number[] = [];
  const categoriesDatasetsMap: Map<JobCategories, number[]> = new Map();

  const dataPerDay = daysArr.map((currentDate: Date) => {
    const overviewElement = data.find(
      (overviewData: JobsSummaryOverviewDataElement) =>
        isSameDay(addLocalTimeOffset(overviewData.date), currentDate),
    );
    const dateString = slashFormatDate(currentDate);
    const categoriesData = getCategoriesChartDataAsMap(
      overviewElement?.categories || [],
    );

    dateLabels.push(dateString);
    totalWorkHoursArray.push(categoriesData.totalHours);
    totalWeekCost += categoriesData.totalCost;
    totalWeekHours += categoriesData.totalHours;

    categoriesData.categoriesMapData.forEach(
      (mapElement: JobsSummaryOverviewCategory) => {
        const dataset = categoriesDatasetsMap.get(mapElement.category);

        categoriesDatasetsMap.set(
          mapElement.category,
          dataset
            ? [...dataset, parseFloat(mapElement.hours)]
            : [parseFloat(mapElement.hours)],
        );
      },
    );

    return {
      date: dateString,
      totalDayCost: categoriesData.totalCost,
      totalDayHours: categoriesData.totalHours,
      categoriesMapData: categoriesData.categoriesMapData,
    };
  });

  return {
    categoriesDatasetsMap,
    dateLabels,
    dataPerDay,
    totalWeekCost,
    totalWeekHours,
    totalWorkHoursArray,
  };
};

export interface WeeksMap {
  weekCategoriesMap: Map<JobCategories, JobsSummaryOverviewCategory>;
  weekCosts: number;
  weekEndDate: string;
  weekHours: number;
  weekInitDate: string;
}

export const getWeeksBetweenDates = (
  startDate: Date,
  endDate: Date,
  perWeekData: JobsSummaryOverviewDataPerWeek[],
) => {
  let weekStart = startOfWeek(startDate);
  let weekEnd = endOfWeek(startDate);
  const dayDiff =
    differenceInDays(endOfWeek(endDate), startOfWeek(startDate)) + 1;
  const weeksTotal = dayDiff / 7;
  const weeksData: JobsSummaryOverviewDataPerWeek[] = [];

  for (let i = 0; i < weeksTotal; i++) {
    const dateStart = new Date(weekStart);
    const dateEnd = new Date(weekEnd);
    const foundWeek = perWeekData.find(
      (week: JobsSummaryOverviewDataPerWeek) => {
        if (!week.data[0]) return false;
        const weekDate = addLocalTimeOffset(week.data[0].date);
        return weekDate >= dateStart && weekDate <= dateEnd;
      },
    );

    if (foundWeek) {
      weeksData.push({
        data: cloneDeep(foundWeek.data),
        week: i.toString(),
      });
    } else {
      const weeksDataElement: JobsSummaryOverviewDataElement = {
        categories: [],
        date: enDashFormatDate(dateStart),
      };

      weeksData.push({
        data: cloneDeep([weeksDataElement]),
        week: i.toString(),
      });
    }

    const updatedStart = new Date(weekEnd);
    updatedStart.setDate(weekEnd.getDate() + 1);

    weekStart = new Date(updatedStart);
    weekEnd = endOfWeek(weekStart);
  }

  return weeksData;
};

export const getWeeklyBarChartData = (
  perWeekData: JobsSummaryOverviewDataPerWeek[],
  dateRange: StringDateRange,
) => {
  const { startDate, endDate } = getDateRangeFromStringDateRange(dateRange);
  const dataPerWeek = getWeeksBetweenDates(startDate, endDate, perWeekData);
  let totalHours = 0.0;
  const weeksMap: Map<string, WeeksMap> = new Map();
  const categoriesDatasetsMap: Map<JobCategories, number[]> = new Map();
  const totalWorkHoursArray: number[] = [];
  const dateLabels: string[] = [];

  dataPerWeek.forEach((week: JobsSummaryOverviewDataPerWeek) => {
    const weekCategoriesMap: Map<
      JobCategories,
      JobsSummaryOverviewCategory
    > = new Map([
      [JobCategories.OhElectric, { ...ohElectric }],
      [JobCategories.UgGas, { ...ugGas }],
      [JobCategories.UgElectric, { ...ugElectric }],
      [JobCategories.CastIron, { ...castIron }],
      [JobCategories.Overhead, { ...overhead }],
      [JobCategories.General, { ...general }],
    ]);
    const weekDate = addLocalTimeOffset(week.data[0].date);
    const weekInitDate = startOfWeek(weekDate).toISOString();
    const weekEndDate = endOfWeek(weekDate).toISOString();
    let weekCosts = 0.0;
    let weekHours = 0.0;

    week.data.forEach((weekElement: JobsSummaryOverviewDataElement) =>
      weekElement.categories.forEach(
        (weekCategory: JobsSummaryOverviewCategory) => {
          const costsToAdd = parseFloat(weekCategory.cost);
          const hoursToAdd = parseFloat(weekCategory.hours);
          const categoryMapData = weekCategoriesMap.get(weekCategory.category);

          if (!categoryMapData) return;

          const { cost, hours } = categoryMapData;

          weekCategoriesMap.set(weekCategory.category, {
            ...categoryMapData,
            cost: (parseFloat(cost) + costsToAdd).toString(),
            hours: (parseFloat(hours) + hoursToAdd).toString(),
          });

          totalHours += hoursToAdd;
          weekCosts += costsToAdd;
          weekHours += hoursToAdd;
        },
      ),
    );

    totalWorkHoursArray.push(weekHours);
    dateLabels.push(weekInitDate);

    weekCategoriesMap.forEach((mapElement: JobsSummaryOverviewCategory) => {
      const dataset = categoriesDatasetsMap.get(mapElement.category);

      categoriesDatasetsMap.set(
        mapElement.category,
        dataset
          ? [...dataset, parseFloat(mapElement.hours)]
          : [parseFloat(mapElement.hours)],
      );
    });

    weeksMap.set(weekInitDate, {
      weekCategoriesMap,
      weekCosts,
      weekEndDate,
      weekHours,
      weekInitDate,
    });
  });

  return {
    categoriesDatasetsMap,
    dateLabels,
    totalHours,
    totalWorkHoursArray,
    weeksMap,
  };
};

export const getWeeklyBarChartDataAsMonth = (
  perWeekData: JobsSummaryOverviewDataPerWeek[],
) => {
  let totalCost = 0.0;
  let totalHours = 0.0;
  const categoriesMap: Map<
    JobCategories,
    JobsSummaryOverviewCategory
  > = new Map([
    [JobCategories.OhElectric, { ...ohElectric }],
    [JobCategories.UgGas, { ...ugGas }],
    [JobCategories.UgElectric, { ...ugElectric }],
    [JobCategories.CastIron, { ...castIron }],
    [JobCategories.Overhead, { ...overhead }],
    [JobCategories.General, { ...general }],
  ]);
  const month =
    perWeekData[0] && perWeekData[0].data[0]
      ? format(addLocalTimeOffset(perWeekData[0].data[0].date), "MMMM y")
      : "No Month";

  perWeekData.forEach((weekData: JobsSummaryOverviewDataPerWeek) => {
    weekData.data.forEach((weekElement: JobsSummaryOverviewDataElement) => {
      weekElement.categories.forEach(
        (weekCategory: JobsSummaryOverviewCategory) => {
          const costToAdd = parseFloat(weekCategory.cost);
          const hoursToAdd = parseFloat(weekCategory.hours);
          const categoryMapData = categoriesMap.get(weekCategory.category);

          if (!categoryMapData) return;

          totalCost += costToAdd;
          totalHours += hoursToAdd;

          const { cost, hours } = categoryMapData;
          categoriesMap.set(weekCategory.category, {
            ...categoryMapData,
            cost: (parseFloat(cost) + costToAdd).toString(),
            hours: (parseFloat(hours) + hoursToAdd).toString(),
          });
        },
      );
    });
  });

  return {
    month,
    totalCost,
    totalHours,
    categoriesMapData: categoriesMap,
  };
};

export const getTypeOfJobData = (summaryData?: JobsSummaryGeneralData) => {
  let maintenanceJobs = 0;
  let newJobs = 0;

  summaryData?.workOrderTypes.forEach(
    (workOrderElement: JobsSummaryWorkOrder) => {
      if (workOrderElement.workOrderType === JobSummaryJobsTypes.Maintenance) {
        maintenanceJobs = workOrderElement.total;
        return;
      }

      if (workOrderElement.workOrderType === JobSummaryJobsTypes.NewJob) {
        newJobs = workOrderElement.total;
        return;
      }
    },
  );

  return {
    maintenanceJobs,
    newJobs,
  };
};

export const getTooltipDate = (date?: string) =>
  format(date ? addLocalTimeOffset(date) : new Date(), "EEEE, LLLL d");

export const getTooltipDateBartChart = (date: string) => {
  const splittedDate = date
    .split("/")
    .map((datePart: string) => parseInt(datePart, 10));
  const updatedDate = new Date(
    splittedDate[2],
    splittedDate[0] - 1,
    splittedDate[1],
  );
  return format(updatedDate, "EEEE, LLLL d");
};

export const getXTicksLabelPerDay = (date: string) => {
  const splittedDate = date
    .split("/")
    .map((datePart: string) => parseInt(datePart, 10));
  const updatedDate = new Date(
    splittedDate[2],
    splittedDate[0] - 1,
    splittedDate[1],
  );
  return format(updatedDate, "EEEEE");
};

export const parseRegionsToDropdownOptions = (
  regions: RegionModel[],
): DropdownOption[] => {
  const mappedRegions = regions.map((region: RegionModel) => {
    return {
      label: region.name,
      value: (region.id as number).toString(),
    };
  });

  return [ALL_OPTION, ...mappedRegions];
};

export const getTimesheetStatusValue = (
  statusValues: TimesheetStatusPercentageData[],
  statusType: StatusTimesheetSummary,
): TimesheetStatusPercentageData => {
  const foundStatus = statusValues.find(
    (status) => status.status === statusType,
  );
  if (foundStatus) {
    return foundStatus;
  }

  return { status: statusType, percent: 0 };
};

export interface MonthMap {
  monthCategoriesMap: Map<JobCategories, JobsSummaryOverviewCategory>;
  monthCosts: number;
  monthDate: string;
  monthHours: number;
}

export const getMonthsBetweenDates = (
  startDate: Date,
  endDate: Date,
  perMonthData: JobsSummaryOverviewDataPerMonth[],
) => {
  let currentDate = new Date(startDate);
  const monthDiff = differenceInCalendarMonths(endDate, startDate) + 1;
  const monthsData: JobsSummaryOverviewDataPerMonth[] = [];

  for (let i = 0; i < monthDiff; i++) {
    const currentMonthDate = new Date(currentDate);
    const foundMonth = perMonthData.find(
      (month: JobsSummaryOverviewDataPerMonth) => {
        if (!month.data[0]) return false;
        const monthDate = addLocalTimeOffset(month.data[0].date);
        return (
          isSameMonth(monthDate, currentMonthDate) &&
          isSameYear(monthDate, currentMonthDate)
        );
      },
    );

    if (foundMonth) {
      monthsData.push({
        data: cloneDeep(foundMonth.data),
        month: i.toString(),
      });
    } else {
      const monthEmptyDataElement: JobsSummaryOverviewDataElement = {
        categories: [],
        date: enDashFormatDate(currentMonthDate),
      };

      monthsData.push({
        data: cloneDeep([monthEmptyDataElement]),
        month: i.toString(),
      });
    }

    const updatedCurrentDate = new Date(currentDate);
    updatedCurrentDate.setMonth(currentDate.getMonth() + 1);

    currentDate = new Date(updatedCurrentDate);
  }

  return monthsData;
};

export const getMonthlyBarChartData = (
  perMonthData: JobsSummaryOverviewDataPerMonth[],
  dateRange: StringDateRange,
) => {
  const { startDate, endDate } = getDateRangeFromStringDateRange(dateRange);
  const dataPerMonth = getMonthsBetweenDates(startDate, endDate, perMonthData);
  let totalHours = 0.0;
  const monthsMap: Map<string, MonthMap> = new Map();
  const categoriesDatasetsMap: Map<JobCategories, number[]> = new Map();
  const totalWorkHoursArray: number[] = [];
  const dateLabels: string[] = [];

  dataPerMonth.forEach((currentMonth: JobsSummaryOverviewDataPerMonth) => {
    const monthCategoriesMap: Map<
      JobCategories,
      JobsSummaryOverviewCategory
    > = new Map([
      [JobCategories.OhElectric, { ...ohElectric }],
      [JobCategories.UgGas, { ...ugGas }],
      [JobCategories.UgElectric, { ...ugElectric }],
      [JobCategories.CastIron, { ...castIron }],
      [JobCategories.Overhead, { ...overhead }],
      [JobCategories.General, { ...general }],
    ]);
    const monthDate = addLocalTimeOffset(
      currentMonth.data[0].date,
    ).toISOString();
    let monthCosts = 0.0;
    let monthHours = 0.0;

    currentMonth.data.forEach((monthElement: JobsSummaryOverviewDataElement) =>
      monthElement.categories.forEach(
        (monthCategory: JobsSummaryOverviewCategory) => {
          const costsToAdd = parseFloat(monthCategory.cost);
          const hoursToAdd = parseFloat(monthCategory.hours);
          const categoryMapData = monthCategoriesMap.get(
            monthCategory.category,
          );

          if (!categoryMapData) return;

          const { cost, hours } = categoryMapData;

          monthCategoriesMap.set(monthCategory.category, {
            ...categoryMapData,
            cost: (parseFloat(cost) + costsToAdd).toString(),
            hours: (parseFloat(hours) + hoursToAdd).toString(),
          });

          totalHours += hoursToAdd;
          monthCosts += costsToAdd;
          monthHours += hoursToAdd;
        },
      ),
    );

    totalWorkHoursArray.push(monthHours);
    dateLabels.push(monthDate);

    monthCategoriesMap.forEach((mapElement: JobsSummaryOverviewCategory) => {
      const dataset = categoriesDatasetsMap.get(mapElement.category);

      categoriesDatasetsMap.set(
        mapElement.category,
        dataset
          ? [...dataset, parseFloat(mapElement.hours)]
          : [parseFloat(mapElement.hours)],
      );
    });

    monthsMap.set(monthDate, {
      monthCategoriesMap,
      monthCosts,
      monthDate,
      monthHours,
    });
  });

  return {
    categoriesDatasetsMap,
    dateLabels,
    totalHours,
    totalWorkHoursArray,
    monthsMap,
  };
};

export const getQuartersBetweenDates = (
  startDate: Date,
  endDate: Date,
  perMonthData: JobsSummaryOverviewDataPerMonth[],
) => {
  let currentDate = new Date(startDate);
  const quarterDiff = differenceInCalendarQuarters(endDate, startDate) + 1;
  const quartersData: JobsSummaryOverviewDataPerMonth[] = [];

  for (let i = 0; i < quarterDiff; i++) {
    const currentQuarterDate = new Date(currentDate);
    const quarterData: JobsSummaryOverviewDataElement[] = [];

    perMonthData.forEach((month: JobsSummaryOverviewDataPerMonth) =>
      month.data.forEach((monthElement: JobsSummaryOverviewDataElement) => {
        const monthDate = addLocalTimeOffset(monthElement.date);

        if (
          isSameQuarter(monthDate, currentQuarterDate) &&
          isSameYear(monthDate, currentQuarterDate)
        ) {
          quarterData.push(cloneDeep(monthElement));
        }
      }),
    );

    if (quarterData.length > 0) {
      quartersData.push({
        data: cloneDeep(quarterData),
        month: `Q ${i.toString() + 1}`,
      });
    } else {
      const quarterEmptyDataElement: JobsSummaryOverviewDataElement = {
        categories: [],
        date: enDashFormatDate(currentQuarterDate),
      };
      quartersData.push({
        data: cloneDeep([quarterEmptyDataElement]),
        month: `Q ${i.toString() + 1}`,
      });
    }

    currentDate = new Date(addQuarters(currentDate, 1));
  }

  return quartersData;
};

export const getQuarterlyBarChartData = (
  perMonthData: JobsSummaryOverviewDataPerMonth[],
  dateRange: StringDateRange,
) => {
  const { startDate, endDate } = getDateRangeFromStringDateRange(dateRange);
  const dataPerMonth = getQuartersBetweenDates(
    startDate,
    endDate,
    perMonthData,
  );
  let totalHours = 0.0;
  const monthsMap: Map<string, MonthMap> = new Map();
  const categoriesDatasetsMap: Map<JobCategories, number[]> = new Map();
  const totalWorkHoursArray: number[] = [];
  const dateLabels: string[] = [];

  dataPerMonth.forEach((currentMonth: JobsSummaryOverviewDataPerMonth) => {
    const monthCategoriesMap: Map<
      JobCategories,
      JobsSummaryOverviewCategory
    > = new Map([
      [JobCategories.OhElectric, { ...ohElectric }],
      [JobCategories.UgGas, { ...ugGas }],
      [JobCategories.UgElectric, { ...ugElectric }],
      [JobCategories.CastIron, { ...castIron }],
      [JobCategories.Overhead, { ...overhead }],
      [JobCategories.General, { ...general }],
    ]);
    const monthDate = addLocalTimeOffset(
      currentMonth.data[0].date,
    ).toISOString();
    let monthCosts = 0.0;
    let monthHours = 0.0;

    currentMonth.data.forEach((monthElement: JobsSummaryOverviewDataElement) =>
      monthElement.categories.forEach(
        (monthCategory: JobsSummaryOverviewCategory) => {
          const costsToAdd = parseFloat(monthCategory.cost);
          const hoursToAdd = parseFloat(monthCategory.hours);
          const categoryMapData = monthCategoriesMap.get(
            monthCategory.category,
          );

          if (!categoryMapData) return;

          const { cost, hours } = categoryMapData;

          monthCategoriesMap.set(monthCategory.category, {
            ...categoryMapData,
            cost: (parseFloat(cost) + costsToAdd).toString(),
            hours: (parseFloat(hours) + hoursToAdd).toString(),
          });

          totalHours += hoursToAdd;
          monthCosts += costsToAdd;
          monthHours += hoursToAdd;
        },
      ),
    );

    totalWorkHoursArray.push(monthHours);
    dateLabels.push(monthDate);

    monthCategoriesMap.forEach((mapElement: JobsSummaryOverviewCategory) => {
      const dataset = categoriesDatasetsMap.get(mapElement.category);

      categoriesDatasetsMap.set(
        mapElement.category,
        dataset
          ? [...dataset, parseFloat(mapElement.hours)]
          : [parseFloat(mapElement.hours)],
      );
    });

    monthsMap.set(monthDate, {
      monthCategoriesMap,
      monthCosts,
      monthDate,
      monthHours,
    });
  });

  return {
    categoriesDatasetsMap,
    dateLabels,
    totalHours,
    totalWorkHoursArray,
    monthsMap,
  };
};
