import {
  Box,
  createStyles,
  Grid,
  makeStyles,
  TextField,
  Theme,
  Typography,
} from "@material-ui/core";
import { findFieldInformation } from "components/userManagement/EmployeeInformation/EmployeeInformationUtils";
import { selectIsActiveEmployee } from "features/employees/employeesSlice";
import { EmployeeModel } from "@dwo/shared/dist/models/employeeModel";
import React from "react";
import { useSelector } from "react-redux";

interface GenerateInformationProps {
  header: string;
  information?: EmployeeModel;
  infoFields: string[];
}

interface StyleProps {
  isDisabled: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      color: ({ isDisabled }: StyleProps) =>
        isDisabled ? theme.palette.grey[400] : theme.palette.primary.main,
      marginBottom: "24px",
    },
  }),
);

export function GenerateInformation({
  header,
  information,
  infoFields,
}: GenerateInformationProps) {
  const isDisabled = !useSelector(selectIsActiveEmployee);
  const classes = useStyles({ isDisabled });
  return (
    <Box margin="24px">
      <Typography variant="h5" className={classes.header}>
        {header}
      </Typography>
      {information && (
        <Grid container spacing={3} justify="flex-start">
          {infoFields
            .map((field) => findFieldInformation(field, information))
            .map((fieldInfo) => (
              <Grid item md={4} key={fieldInfo && fieldInfo[0]}>
                <TextField
                  value={(fieldInfo && fieldInfo[2]) || "N/A"}
                  label={(fieldInfo && fieldInfo[1]) || "N/A"}
                  disabled={isDisabled}
                />
              </Grid>
            ))}
        </Grid>
      )}
    </Box>
  );
}
