import React, { useEffect, useState } from "react";
import { Box, createStyles, makeStyles, Theme } from "@material-ui/core";
import { Person as PersonIcon } from "@material-ui/icons";
import { GlobalModal } from "components/globalModal/GlobalModal";
import { employeePhotoLinkService } from "@dwo/shared/dist/services/employeePhotoLinkService";

interface TableDisplayWorkerPhotoProps {
  photoId: number;
}

interface StyleProps {
  hasPhoto?: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainContainer: {
      cursor: ({ hasPhoto }: StyleProps) => (hasPhoto ? "pointer" : "unset"),
    },
    photo: {
      height: "550px",
      objectFit: "contain",
      width: "100%",
    },
  }),
);

export function TableDisplayEmployeePhoto({
  photoId,
}: TableDisplayWorkerPhotoProps) {
  const [photoLink, setPhotoLink] = useState<string>("");
  const [isPhotoModalIsOpen, setIsPhotoModalIsOpen] = useState<boolean>(false);
  const classes = useStyles({ hasPhoto: photoLink !== "" });
  const color = photoLink !== "" ? "green" : "red"; //This changes the PersonIcon Color

  const handleClickClose = () => {
    setIsPhotoModalIsOpen(false);
  };

  const handleModalIsOpen = () => {
    if (photoLink) {
      setIsPhotoModalIsOpen(true);
    }
    return;
  };

  useEffect(() => {
    async function getPhotoLink() {
      if (photoId) {
        const { data: photoLink } = await employeePhotoLinkService.getPhotoLink(
          photoId,
        );
        setPhotoLink(photoLink.url || "");
      } else {
        setPhotoLink("");
      }
    }
    getPhotoLink();
  }, []);

  return (
    <>
      <Box
        alignItems="center"
        className={classes.mainContainer}
        display="flex"
        flexDirection="column"
        onClick={handleModalIsOpen}
        position="relative"
        top={"10px"}
        width={"100%"}
      >
        <PersonIcon style={{ color }} />
      </Box>

      {isPhotoModalIsOpen && (
        <GlobalModal
          onClickClose={handleClickClose}
          open={isPhotoModalIsOpen}
          showActionButtons={false}
          title="Employee Photo"
          modalMaxWidth={"600px"}
        >
          <Box alignItems="center">
            <img className={classes.photo} src={photoLink} />
          </Box>
        </GlobalModal>
      )}
    </>
  );
}
