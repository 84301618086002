import {
  Box,
  Button,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import { GlobalModal } from "components/globalModal/GlobalModal";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  setHazards,
  selectHazards,
  selectIsLoadingHazards,
  getJobHazards,
} from "features/jobs/jobsSlice";
import { RangeDatePicker } from "components/RangeDatePicker";
import { DateRange } from "materialui-daterange-picker";
import { ServiceOptions } from "@dwo/shared/dist/services/baseService";
import { TableCustom } from "components/table/TableCustom";
import { cloneDeep } from "lodash";
import { hazardModalColumns, getHazardsModalRows } from "utils/jobDetailsUtils";

interface HazardHistoryModalProps {
  jobId: string;
  isOpen: boolean;
  onClickClose: VoidFunction;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    viewMoreButton: {
      width: "100%",
      marginTop: "12px",
      color: theme.palette.primary.dark,
    },
  }),
);

export function HazardHistoryModal({
  jobId,
  isOpen,
  onClickClose,
}: HazardHistoryModalProps) {
  const { id } = useParams<{ id: string }>();
  const urlParamId = parseInt(id, 10);
  const dispatch = useDispatch();
  const [query, setQuery] = useState<ServiceOptions>({ limit: 10 });
  const [sortingOptions, setSortingOptions] = useState<string[][]>([]);
  const hazards = useSelector(selectHazards);
  const isLoadingHazards = useSelector(selectIsLoadingHazards);
  const classes = useStyles();

  useEffect(() => {
    if (isOpen) {
      dispatch(getJobHazards(Number(urlParamId), query));
    }
  }, [isOpen, query, dispatch, urlParamId]);

  const handleClickClose = () => {
    dispatch(setHazards([]));
    setQuery({});
    onClickClose();
  };

  const handleClickApplyRangeDate = (dateRange: DateRange) => {
    dispatch(setHazards([]));
    const startDate = dateRange.startDate
      ? new Date(dateRange.startDate)
      : undefined;
    const endDate = dateRange.endDate ? new Date(dateRange.endDate) : undefined;
    startDate?.setHours(0, 0, 0, 0);
    endDate?.setHours(23, 59, 59, 999);

    setQuery({
      ...query,
      startDate: startDate,
      endDate: endDate,
    });
  };

  const handleClickSorting = (sortingValues: string[][]) => {
    const updatedQuery = cloneDeep(query);
    setSortingOptions(sortingValues);
    updatedQuery.order = sortingValues.length > 0 ? sortingValues : undefined;
    setQuery(updatedQuery);
  };

  const handleClickViewMore = () => {
    setQuery({ ...query, limit: query.limit ? query.limit + 10 : 10 });
  };

  const filters = (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      padding="0 24px 24px"
    >
      <Typography variant="h6" color="primary">{`Job Id ${jobId}`}</Typography>
      <RangeDatePicker
        maxDate={new Date()}
        onClickApply={handleClickApplyRangeDate}
      />
    </Box>
  );

  return (
    <GlobalModal
      Filters={filters}
      customFooter={
        <Box display="flex" flexDirection="column" padding="16px 24px 24px">
          <Button
            className={classes.viewMoreButton}
            onClick={handleClickViewMore}
          >
            View More
          </Button>
        </Box>
      }
      modalMaxWidth="471px"
      open={isOpen}
      title="Hazard Form History"
      showActionButtons={false}
      onClickClose={handleClickClose}
    >
      <Box padding="2px 24px 0px">
        <TableCustom
          columns={hazardModalColumns}
          isLoading={isLoadingHazards}
          noRowsMessage="There are no hazards to display yet."
          rows={getHazardsModalRows(hazards)}
          sortingOptions={sortingOptions}
          onClickSort={handleClickSorting}
        />
      </Box>
    </GlobalModal>
  );
}
