import {
  Avatar,
  Box,
  createStyles,
  Divider,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import { GlobalModal } from "components/globalModal/GlobalModal";
import { TableCustom } from "components/table/TableCustom";
import { SearchByNameOrId } from "components/userManagement/SearchByNameOrId";
import {
  getAllEmployeesWithImage,
  selectEmployees,
  selectEmployeesReqStatus,
  setEmployeesReqStatus,
} from "features/employees/employeesSlice";
import { EmployeeModel } from "@dwo/shared/dist/models/employeeModel";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ServiceOptions } from "@dwo/shared/dist/services/baseService";
import {
  getSupervisorAssignmentRows,
  supervisorAssignmentColumns,
} from "utils/employeeDetailsUtils";
import { ReqStatus, DEFAULT_QUERY_LIMIT } from "utils/sharedUtils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textAssigned: {
      color: theme.palette.grey["500"],
    },
  }),
);

interface SupervisorModalProps {
  isOpen: boolean;
  regionId?: number;
  supervisorAssigned?: EmployeeModel;
  onClickCancel: () => void;
  onClickSave: (supervisorSelectedId: string) => void;
}

export function SupervisorModal({
  isOpen = false,
  regionId,
  supervisorAssigned,
  onClickCancel,
  onClickSave,
}: SupervisorModalProps) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const employeesReqStatus = useSelector(selectEmployeesReqStatus);
  const supervisorsList = useSelector(selectEmployees).filter(
    (supervisor) => supervisor.id !== supervisorAssigned?.id,
  );
  const isLoading = employeesReqStatus !== ReqStatus.FULFILLED;
  const [supervisorIdSelected, setSupervisorIdSelected] = useState<string>("");
  const [searchedName, setSearchedName] = useState("");
  const [searchedEmployeeId, setSearchedEmployeeId] = useState("");
  const [query] = useState<ServiceOptions>({
    where: { role: { $eq: "supervisor" }, regionId },
    limit: DEFAULT_QUERY_LIMIT,
  });

  useEffect(() => {
    if (isOpen) {
      dispatch(getAllEmployeesWithImage(query));
    }
  }, [dispatch, query, isOpen]);

  const handleClickClose = () => {
    dispatch(setEmployeesReqStatus(ReqStatus.IDLE));
    setSupervisorIdSelected("");
    onClickCancel();
  };

  const handleClickCheckbox = (idRow: string, isChecked: boolean) => {
    if (isChecked) {
      setSupervisorIdSelected(idRow);
    }

    if (!isChecked) {
      setSupervisorIdSelected("");
    }
  };

  const handleChangeSearch = useCallback(
    (employeeId: string, employeeName: string) => {
      setSearchedName(employeeName);
      setSearchedEmployeeId(employeeId);
    },
    [],
  );

  const handleClickSave = () => {
    if (supervisorIdSelected) {
      const supervisorId = supervisorIdSelected;
      dispatch(setEmployeesReqStatus(ReqStatus.IDLE));
      setSupervisorIdSelected("");
      onClickCancel();
      onClickSave(supervisorId);
    }
  };

  const filterEmployeesBySearchResult = (data: EmployeeModel[]) => {
    if (searchedEmployeeId && searchedName) {
      return data.filter(
        ({ employeeId, firstName }) =>
          employeeId === searchedEmployeeId && firstName === searchedName,
      );
    }
    return data;
  };

  const getSupervisorImg = () => {
    if (!supervisorAssigned) return "";
    if (!supervisorAssigned.employeePictures) return "";
    if (supervisorAssigned.employeePictures.length <= 0) return "";

    return supervisorAssigned.employeePictures[0].picture.url;
  };

  return (
    <GlobalModal
      open={isOpen}
      title="Assign Supervisor"
      onClickContinue={handleClickSave}
      onClickClose={handleClickClose}
    >
      <Box padding="0px 24px">
        <Typography variant="h6" color="primary">{`Assigned (${
          supervisorAssigned ? 1 : 0
        })`}</Typography>
        {supervisorAssigned ? (
          <Box padding="24px" display="flex" alignItems="center">
            <Avatar src={getSupervisorImg()} />
            <Box margin="0 32px">
              <Typography variant="body2" className={classes.textAssigned}>
                {`${supervisorAssigned.firstName} ${supervisorAssigned.lastName}`}
              </Typography>
            </Box>
            <Typography variant="body2" className={classes.textAssigned}>
              {supervisorAssigned.employeeId}
            </Typography>
          </Box>
        ) : (
          <Box padding="24px" display="flex" justifyContent="center">
            <Typography>Choose an available supervisor</Typography>
          </Box>
        )}
        <Divider />
        <Box padding="24px 0">
          <Typography variant="h6" color="primary">{`Available (${
            isLoading ? "0" : supervisorsList.length
          })`}</Typography>
        </Box>
        <Box marginBottom="24px">
          <SearchByNameOrId
            filteredEmployees={supervisorsList}
            onChange={handleChangeSearch}
          />
        </Box>

        <TableCustom
          columns={supervisorAssignmentColumns}
          isLoading={isLoading}
          noRowsMessage="No supervisors found"
          rows={getSupervisorAssignmentRows(
            filterEmployeesBySearchResult(supervisorsList),
            supervisorIdSelected,
            handleClickCheckbox,
          )}
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          onClickSort={() => {}}
        />
      </Box>
    </GlobalModal>
  );
}
