import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, RootState } from "app/store";
import { error } from "features/error/errorSlice";
import { EmployeeModel } from "@dwo/shared/dist/models/employeeModel";
import { JobNoteModel } from "@dwo/shared/dist/models/jobNoteModel";
import { jobNoteService } from "@dwo/shared/dist/services/jobNoteService";
import { ServiceOptions } from "@dwo/shared/dist/services/baseService";
import { employeeService } from "@dwo/shared/dist/services/employeeService";
import { DEFAULT_LIMIT } from "utils/sharedUtils";
import {
  addNewTimesheetComment,
  deleteTimesheetComment,
  updateTimesheetComment,
} from "features/employeeTimesheet/employeeTimesheetSlice";

interface JobNotesState {
  count: number;
  isLoading: boolean;
  isLoadingEditing: boolean;
  limit?: number;
  notes: JobNoteModel[];
  offset?: number;
  order?: any;
}

const initialState: JobNotesState = {
  count: 0,
  isLoading: false,
  isLoadingEditing: false,
  limit: DEFAULT_LIMIT,
  notes: [],
  offset: 0,
  order: undefined,
};

export const jobNotesSlice = createSlice({
  name: "jobNotes",
  initialState,
  reducers: {
    setCount: (state, action: PayloadAction<number>) => {
      state.count = action.payload;
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setIsLoadingEditing: (state, action: PayloadAction<boolean>) => {
      state.isLoadingEditing = action.payload;
    },
    setLimit: (state, action: PayloadAction<number>) => {
      state.limit = action.payload;
    },
    setJobNotes: (state, action: PayloadAction<JobNoteModel[]>) => {
      state.notes = action.payload;
    },
    setOffset: (state, action: PayloadAction<number>) => {
      state.offset = action.payload;
    },
    setOrder: (state, action: PayloadAction<any>) => {
      state.order = action.payload;
    },
  },
});

export const {
  setCount,
  setIsLoading,
  setIsLoadingEditing,
  setLimit,
  setJobNotes,
  setOffset,
  setOrder,
} = jobNotesSlice.actions;

export const createJobNote = (
  body: JobNoteModel,
  options?: ServiceOptions,
  fromWorkshift?: boolean,
): AppThunk => async (dispatch) => {
  try {
    dispatch(setIsLoadingEditing(true));

    const { data: newNote } = await jobNoteService.create(body);

    if (fromWorkshift) {
      dispatch(addNewTimesheetComment(newNote));
      return;
    }

    dispatch(getAllJobNotes(options));
  } catch (err) {
    dispatch(
      error(
        {
          title: "Could not create the Job Note. Please try again.",
          message: err.message,
        },
        () => dispatch(createJobNote(body, options, fromWorkshift)),
      ),
    );
  } finally {
    dispatch(setIsLoadingEditing(false));
  }
};

export const deleteJobNote = (
  id: number | string,
  options?: ServiceOptions,
  workshiftId?: number,
): AppThunk => async (dispatch) => {
  try {
    dispatch(setIsLoadingEditing(true));

    await jobNoteService.delete(id);

    if (workshiftId) {
      const commentId = typeof id === "string" ? parseInt(id, 10) : id;
      dispatch(deleteTimesheetComment({ commentId, workshiftId }));
      return;
    }

    dispatch(getAllJobNotes(options));
  } catch (err) {
    dispatch(
      error(
        {
          title: "Could not delete the Job Note. Please try again.",
          message: err.message,
        },
        () => dispatch(deleteJobNote(id)),
      ),
    );
  } finally {
    dispatch(setIsLoadingEditing(false));
  }
};

export const getAllJobNotes = (options?: ServiceOptions): AppThunk => async (
  dispatch,
) => {
  try {
    dispatch(setIsLoading(true));
    const { data: notesData } = await jobNoteService.getAll(options);

    if (notesData.length > 0) {
      const userIds: number[] = notesData.map(
        (note: JobNoteModel) => note.createdById as number,
      );

      const { data: employeeData } = await employeeService.getProfiles(userIds);

      notesData.map((note: JobNoteModel) => {
        note.pictureUrl = employeeData.find(
          (employee: EmployeeModel) =>
            (employee.id as number) === (note.createdById as number),
        )?.pictureUrl;

        return note;
      });
    }

    dispatch(setJobNotes(notesData));
  } catch (err) {
    dispatch(
      error(
        {
          title: "Could not retrieve Job Notes. Please try again.",
          message: err.message,
        },
        () => dispatch(getAllJobNotes(options)),
      ),
    );
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const getJobsNotes = (jobsIds: number[]): AppThunk => async (
  dispatch,
) => {
  try {
    dispatch(setIsLoading(true));

    const fetchedNotes: JobNoteModel[] = [];

    if (jobsIds.length === 0) {
      dispatch(setJobNotes(fetchedNotes));
      return;
    }

    await Promise.all(
      jobsIds.map(async (jobId: number) => {
        const { data: jobNotes } = await jobNoteService.getAll({
          where: { jobId },
          include: [{ createdBy: ["employee"] }, "job"],
          order: [["updatedAt", "DESC"]],
        });

        if (jobNotes.length > 0) {
          const userIds: number[] = jobNotes.map(
            (note: JobNoteModel) => note.createdById as number,
          );

          const { data: employeeData } = await employeeService.getProfiles(
            userIds,
          );

          jobNotes.map((note: JobNoteModel) => {
            note.pictureUrl = employeeData.find(
              (employee: EmployeeModel) =>
                (employee.id as number) === (note.createdById as number),
            )?.pictureUrl;

            return note;
          });
        }

        fetchedNotes.push(...jobNotes);
      }),
    );

    dispatch(setJobNotes(fetchedNotes));
  } catch (err) {
    dispatch(
      error(
        {
          title: "Could not get Jobs Notes. Please try again.",
          message: err.message,
        },
        () => dispatch(getJobsNotes(jobsIds)),
      ),
    );
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const updateJobNote = (
  id: number | string,
  body: JobNoteModel,
  options?: ServiceOptions,
  fromWorkshift?: boolean,
): AppThunk => async (dispatch) => {
  try {
    dispatch(setIsLoadingEditing(true));

    const { data: updatedNote } = await jobNoteService.update(id, body);

    if (fromWorkshift) {
      dispatch(updateTimesheetComment(updatedNote));
    }

    dispatch(getAllJobNotes(options));
  } catch (err) {
    dispatch(
      error(
        {
          title: "Could not update the Job Note. Please try again.",
          message: err.message,
        },
        () => dispatch(updateJobNote(id, body, options)),
      ),
    );
  } finally {
    dispatch(setIsLoadingEditing(false));
  }
};

export const selectIsLoading = (state: RootState) => state.jobNotes.isLoading;
export const selectIsLoadingEditing = (state: RootState) =>
  state.jobNotes.isLoadingEditing;
export const selectLimit = (state: RootState) => state.jobNotes.limit;
export const selectJobNotes = (state: RootState) => state.jobNotes.notes;
export const selectOffset = (state: RootState) => state.jobNotes.offset;
export const selectOrder = (state: RootState) => state.jobNotes.order;

export default jobNotesSlice.reducer;
