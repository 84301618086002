import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import React from "react";

interface StyleProps {
  dotColor?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: theme.palette.grey[100],
      display: "flex",
      flexDirection: "column",
      height: "100%",
      justifyContent: "center",
      padding: "12px",
    },
    descriptionText: {
      color: theme.palette.grey[500],
    },
    dotsContainer: {
      position: "absolute",
      right: "16px",
      top: "8px",
    },
    fiberIcon: {
      color: ({ dotColor }: StyleProps) => dotColor || theme.palette.grey[100],
      fontSize: "12px",
    },
    totalNumberText: {
      color: theme.palette.grey[700],
      fontSize: "2rem",
      fontWeight: 600,
      lineHeight: "49px",
    },
  }),
);

interface TimesheetInfoCardProps {
  dotColor?: string;
  data: string | number;
  description: string;
}

export function TimesheetInfoCard({
  dotColor,
  data,
  description,
}: TimesheetInfoCardProps) {
  const classes = useStyles({ dotColor });

  return (
    <Box className={classes.container} position="relative">
      {dotColor && (
        <Box className={classes.dotsContainer}>
          <FiberManualRecordIcon className={classes.fiberIcon} />
        </Box>
      )}
      <Box margin="0 auto" width="fit-content">
        <Typography
          align="center"
          className={classes.totalNumberText}
          variant="h4"
        >
          {data}
        </Typography>
      </Box>
      <Typography
        align="center"
        className={classes.descriptionText}
        variant="body2"
      >
        {description}
      </Typography>
    </Box>
  );
}
