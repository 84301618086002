import {
  JobCategories,
  JobsSummaryOverviewDataPerWeek,
} from "@dwo/shared/dist/models/jobSummaryModel";
import { Box, useTheme } from "@material-ui/core";
import Chart, { ChartTooltipModel } from "chart.js";
import { selectSummaryCategoriesAndColors } from "features/summary/summarySlice";
import React, { useEffect, useRef } from "react";
import { renderToString } from "react-dom/server";
import { useSelector } from "react-redux";
import {
  getWeeklyBarChartDataAsMonth,
  TooltipLabelOption,
} from "utils/summaryUtils";
import { BarChartTooltip } from "./BarChartTooltip";

interface OverviewPerWeekCondensedChartProps {
  data?: JobsSummaryOverviewDataPerWeek[];
}

const tooltipId = "overviewPerWeek-condensed-tooltip";

export function OverviewPerWeekCondensedChart({
  data,
}: OverviewPerWeekCondensedChartProps) {
  const theme = useTheme();
  const chartCanvasRef = useRef({} as HTMLCanvasElement);
  const categoriesAndColors = useSelector(selectSummaryCategoriesAndColors);

  useEffect(() => {
    const {
      month,
      totalHours,
      categoriesMapData,
    } = getWeeklyBarChartDataAsMonth(data || []);

    const barsColors: string[] = [];
    const categoriesLabels: string[] = [];
    const categoriesDataArr: number[] = [];
    const totalDataArr: number[] = [];

    categoriesAndColors.forEach((labelOption: TooltipLabelOption) => {
      barsColors.push(labelOption.color);
      categoriesLabels.push(labelOption.label);

      const categoryData = categoriesMapData.get(
        labelOption.label as JobCategories,
      );

      categoriesDataArr.push(categoryData ? parseFloat(categoryData.hours) : 0);
      totalDataArr.push(
        totalHours - (categoryData ? parseFloat(categoryData.hours) : 0),
      );
    });

    const barChart = new Chart(chartCanvasRef.current, {
      data: {
        datasets: [
          {
            backgroundColor: barsColors,
            barThickness: 24,
            data: categoriesDataArr,
            hoverBackgroundColor: barsColors,
            label: "Category Hours",
          },
          {
            backgroundColor: theme.palette.grey[300],
            barThickness: 24,
            data: totalHours > 0 ? totalDataArr : [1, 1, 1, 1, 1, 1],
            hoverBackgroundColor: theme.palette.grey[300],
            label: "Total Hours",
          },
        ],
        labels: categoriesLabels,
      },
      options: {
        legend: {
          display: false,
        },
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              stacked: true,
              gridLines: {
                display: false,
              },
              scaleLabel: {
                display: true,
                labelString: "Hours / Costs",
                fontColor: theme.palette.primary.dark,
                fontFamily: "Noto Sans",
                fontSize: 12,
                fontStyle: "bold",
              },
              ticks: {
                display: false,
              },
            },
          ],
          yAxes: [
            {
              stacked: true,
              gridLines: {
                display: false,
              },
              ticks: {
                beginAtZero: true,
                display: false,
              },
            },
          ],
        },
        tooltips: {
          custom: (tooltipModel: ChartTooltipModel) => {
            let tooltipEl = document.getElementById(tooltipId);

            if (!tooltipEl) {
              tooltipEl = document.createElement("div");
              tooltipEl.id = tooltipId;
              document.body.appendChild(tooltipEl);
            }

            const category = tooltipModel.title
              ? tooltipModel.title[0]
              : undefined;

            if (tooltipModel.opacity === 0 || !category) {
              tooltipEl.style.opacity = "0";
              return;
            }

            const categoryData = categoriesMapData.get(
              category as JobCategories,
            );

            const categoryLabel = categoriesAndColors.find(
              (labelOption: TooltipLabelOption) =>
                labelOption.label === category,
            );

            if (!categoryData || !categoryLabel) {
              tooltipEl.style.opacity = "0";
              return;
            }

            const customTooltipHTMLString = renderToString(
              <BarChartTooltip
                cost={parseFloat(categoryData.cost).toFixed(2)}
                date={month}
                hours={parseFloat(categoryData.hours).toFixed(2)}
                tooltipOption={{
                  color: categoryLabel.color,
                  label: categoryLabel.label,
                }}
              />,
            );

            const position = chartCanvasRef.current.getBoundingClientRect();

            tooltipEl.innerHTML = customTooltipHTMLString;
            tooltipEl.style.opacity = "1";
            tooltipEl.style.position = "absolute";
            tooltipEl.style.left = `${
              position.left + window.pageXOffset + tooltipModel.caretX
            }px`;
            tooltipEl.style.top = `${
              position.top + window.pageYOffset + tooltipModel.caretY
            }px`;
            tooltipEl.style.pointerEvents = "none";
          },
          enabled: false,
        },
      },
      type: "bar",
    });

    return () => {
      barChart.destroy();
    };
  }, [categoriesAndColors, data, theme.palette]);

  return (
    <Box height="200px" maxWidth="360px" width="100%">
      <canvas ref={chartCanvasRef} />
      <div id={tooltipId} />
    </Box>
  );
}
