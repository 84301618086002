import { format } from "date-fns";
import { DateRange } from "materialui-daterange-picker";

export interface StringDateRange {
  startDate: string;
  endDate: string;
}

export const enDashFormatDate = (date: Date) => format(date, "yyyy-MM-dd");
export const slashFormatDate = (date: Date) => format(date, "MM/dd/yyyy");
export const slashFormatDateWithHours = (date: Date) =>
  format(date, "MM/dd/yyyy hh:mm:ss aaaa");
export const slashFormatDateWithHoursMinutes = (date: Date) =>
  format(date, "MM/dd/yyyy hh:mm aaaa");
export const addLocalTimeOffset = (strDate: string) => {
  const splittedDate = strDate
    .split("-")
    .map((datePart: string) => parseInt(datePart, 10));

  return new Date(splittedDate[0], splittedDate[1] - 1, splittedDate[2]);
};
export const formatHoursMinutes = (date: Date) => format(date, "hh:mm aaaa");

export const getDateRangeFromStringDateRange = (
  stringRange: StringDateRange,
) => {
  const { startDate, endDate } = stringRange;

  return {
    startDate: new Date(startDate),
    endDate: new Date(endDate),
  };
};

export const getStringDateRangeFromDateRange = (
  dateRange?: DateRange,
): StringDateRange => {
  const startDate = dateRange?.startDate;
  const endDate = dateRange?.endDate;
  let updatedStartDate;
  let updatedEndDate;

  if (startDate && endDate) {
    updatedStartDate = new Date(startDate);
    updatedEndDate = new Date(endDate);
  } else {
    updatedStartDate = new Date();
    updatedEndDate = new Date();
  }

  updatedStartDate.setHours(0, 0, 0, 0);
  updatedEndDate.setHours(23, 59, 0, 0);

  return {
    startDate: updatedStartDate.toISOString(),
    endDate: updatedEndDate.toISOString(),
  };
};

export const decimalTimeToHoursTime = (time: number | undefined) => {
  const sign = time! < 0 ? "-" : "";
  const hrs = Math.floor(Math.abs(time!));
  const min = Math.floor((Math.abs(time!) * 60) % 60);
  return `${sign}${hrs}h ${min < 10 ? "0" : ""}${min}min`;
};

export const crewOverviewTablePickerFormatter = (time: number) => {
  const sign = time < 0 ? "-" : "";
  const hrs = Math.floor(Math.abs(time));
  const min = Math.floor((Math.abs(time) * 60) % 60);
  return `${sign}${hrs < 10 ? "0" : ""}${hrs}${min < 10 ? "0" : ""}:${min}`;
};
