import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { EmployeeModel } from "@dwo/shared/dist/models/employeeModel";
import { JobModel } from "@dwo/shared/dist/models/jobModel";
import React, { Fragment } from "react";
import {
  MAX_DISPLAYED_JOBS,
  MAX_DISPLAYED_SUPERVISORS,
} from "utils/timesheetDetailsUtils";
import { v4 as uuid } from "uuid";
import { SupervisorsTooltipTitle } from "./SupervisorsTooltipTitle";

interface SupervisorsAssignedProps {
  jobs: JobModel[];
  onClickSupervisor: (supervisorId: number) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    headerValue: {
      color: theme.palette.primary.main,
      cursor: "pointer",
      textDecoration: "underline",
    },
    tagsContainer: {
      display: "flex",
      flexWrap: "wrap",
    },
    tooltip: {
      backgroundColor: theme.palette.grey[100],
      borderRadius: "2px",
      padding: "16px",
    },
  }),
);

export function SupervisorsAssigned({
  jobs,
  onClickSupervisor,
}: SupervisorsAssignedProps) {
  const classes = useStyles();

  const handleClickSupervisor = (supervisorId: number) => () =>
    onClickSupervisor(supervisorId);

  if (jobs.length === 0) {
    return <Fragment />;
  }

  const supervisors: EmployeeModel[] = jobs.reduce(
    (jobsSupervisors: EmployeeModel[], job: JobModel) => {
      const currentSupervisor = job.supervisor;

      if (currentSupervisor) {
        const hasSupervisor = jobsSupervisors.find(
          (supervisor: EmployeeModel) => supervisor.id === currentSupervisor.id,
        );

        return hasSupervisor
          ? jobsSupervisors
          : [...jobsSupervisors, currentSupervisor];
      }

      return jobsSupervisors;
    },
    [],
  );

  if (supervisors.length <= MAX_DISPLAYED_SUPERVISORS) {
    return (
      <Box className={classes.tagsContainer}>
        {supervisors.map((supervisor: EmployeeModel, index: number) => (
          <Box
            key={`supervisor-${supervisor.id as number}-${uuid()}`}
            onClick={handleClickSupervisor(supervisor.id as number)}
            marginRight={index < supervisors.length ? "4px" : 0}
          >
            <Typography className={classes.headerValue} variant="body2">
              {`${supervisor.fullName}${
                index !== supervisors.length - 1 ? "," : ""
              }`}
            </Typography>
          </Box>
        ))}
      </Box>
    );
  }

  return (
    <Box className={classes.tagsContainer}>
      {supervisors
        .slice(0, MAX_DISPLAYED_SUPERVISORS)
        .map((supervisor: EmployeeModel) => (
          <Box
            key={`supervisor-${supervisor.id as number}-${uuid()}`}
            marginRight="4px"
            onClick={handleClickSupervisor(supervisor.id as number)}
          >
            <Typography className={classes.headerValue} variant="body2">
              {`${supervisor.fullName},`}
            </Typography>
          </Box>
        ))}
      <Tooltip
        classes={{ tooltip: classes.tooltip }}
        interactive
        placement="bottom-end"
        title={
          <SupervisorsTooltipTitle
            supervisors={supervisors.slice(MAX_DISPLAYED_SUPERVISORS)}
            onClickSupervisor={handleClickSupervisor}
          />
        }
      >
        <Box
          key={`supervisor-${
            supervisors[MAX_DISPLAYED_JOBS].id as number
          }-${uuid()}`}
        >
          <Typography className={classes.headerValue} variant="body2">
            {`${supervisors[MAX_DISPLAYED_JOBS].firstName.slice(0, 3)}...`}
          </Typography>
        </Box>
      </Tooltip>
    </Box>
  );
}
