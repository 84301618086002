import { WorkShiftModel } from "@dwo/shared/dist/models/workShiftModel";
import {
  Avatar,
  Box,
  createStyles,
  Divider,
  makeStyles,
  Paper,
  Theme,
  Typography,
} from "@material-ui/core";
import { DriveEta, Star } from "@material-ui/icons";
import { EditIconButton } from "components/icons/EditIconButton";
import React from "react";
import { ROOT_USER_MANAGEMENT } from "routes/Roots";
import { decimalTimeToHoursTime } from "utils/dateUtils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    anchor: {
      color: theme.palette.primary.main,
      "&:active": {
        color: theme.palette.primary.main,
      },
      "&:hover": {
        color: theme.palette.primary.main,
      },
      "&:visited": {
        color: theme.palette.primary.main,
      },
    },
    avatar: {
      height: "80px",
      width: "80px",
    },
    editIcon: {
      position: "absolute",
      right: "8px",
      top: "8px",
    },
    hoursLabel: {
      color: theme.palette.grey[500],
    },
    iconBackground: {
      alignItems: "center",
      backgroundColor: "white",
      borderRadius: "100px",
      bottom: "12px",
      display: "flex",
      justifyContent: "center",
      left: 0,
      position: "absolute",
    },
    iconWithBackground: { fontSize: "13px", color: "#002868" },
    paper: {
      alignItems: "center",
      backgroundColor: theme.palette.grey[100],
      display: "flex",
      flexDirection: "column",
      position: "relative",
    },
    role: {
      color: theme.palette.grey[600],
      fontWeight: 600,
    },
  }),
);

interface CrewMemberGridCardProps {
  workshift: WorkShiftModel;
  onClickEditIcon: (employeeId: number) => void;
}

export function CrewMemberGridCard({
  workshift,
  onClickEditIcon,
}: CrewMemberGridCardProps) {
  const classes = useStyles();

  const { employee } = workshift;
  const employeeId = employee.employeeId;
  const isDriver = workshift.isDriver;
  const isForeman = employee.role === "foreman";
  const name = employee.fullName;
  const pictureUrl = employee.pictureUrl || "";
  const role = employee.role.charAt(0).toUpperCase() + employee.role.slice(1);
  const totalHours = workshift.hours;
  const userId = employee.id as number;
  const url = `${ROOT_USER_MANAGEMENT}/${userId}`;

  const handleClickEditIcon = (id: number) => () => onClickEditIcon(id);

  return (
    <Paper className={classes.paper}>
      <Box className={classes.editIcon}>
        <EditIconButton
          customFontSize="1rem"
          height="24px"
          width="24px"
          onClickEdit={handleClickEditIcon(userId)}
        />
      </Box>

      <Box
        display="flex"
        justifyContent="center"
        padding="24px 16px 0 16px"
        width="100%"
      >
        <Box position="relative">
          <Avatar
            alt={`${name} Picture`}
            className={classes.avatar}
            src={pictureUrl}
          />
          {isForeman && !isDriver && (
            <Box className={classes.iconBackground}>
              <Star className={classes.iconWithBackground} />
            </Box>
          )}
          {isDriver && !isForeman && (
            <Box className={classes.iconBackground}>
              <DriveEta className={classes.iconWithBackground} />
            </Box>
          )}
        </Box>
      </Box>

      <Box
        display="flex"
        flexDirection="column"
        padding="8px 16px 0 16px"
        width="100%"
      >
        <a className={classes.anchor} href={url}>
          <Typography align="center" variant="body2">
            {name}
          </Typography>
          <Typography align="center" variant="body2">
            {employeeId}
          </Typography>
        </a>
      </Box>

      <Box padding="8px 16px 0 16px" width="100%">
        <Typography
          align="center"
          className={classes.hoursLabel}
          variant="body2"
        >
          Total Hours Worked:
        </Typography>
      </Box>

      <Box padding="8px 16px">
        <Typography align="center" className={classes.role} variant="body2">
          {decimalTimeToHoursTime(totalHours)}
        </Typography>
      </Box>

      <Box width="100%">
        <Divider />
      </Box>

      <Box padding="8px 16px" width="100%">
        <Typography align="center" className={classes.role} variant="body2">
          {role}
        </Typography>
      </Box>
    </Paper>
  );
}
