import { JobModel } from "@dwo/shared/dist/models/jobModel";
import { RegionModel } from "@dwo/shared/dist/models/regionModel";
import { MainTableColumn, MainTableRow } from "utils/tableUtils";

export const timeOffColumns = (
  isChecked: boolean,
  onClickCheckBox: (isChecked: boolean) => void,
): MainTableColumn[] => [
  {
    field: "checkbox",
    hasCheckbox: true,
    isCheckedColumn: isChecked,
    onClickCheckbox: onClickCheckBox,
  },
  {
    field: "id",
    headerName: "JOB ID",
  },
  {
    field: "address",
    headerName: "JOB ADDRESS",
  },
  {
    field: "region",
    headerName: "REGION",
  },
  {
    field: "category",
    headerName: "CATEGORY",
  },
];

export const getTimeOffRows = (
  jobs: JobModel[],
  jobSelectedId: string[],
  clearStatus: string,
  setClearStatus: (clearStatus: string) => void,
  isHeaderChecked: boolean,
  checkboxHandler: (idRow: string, isChecked: boolean) => void,
): MainTableRow[] =>
  jobs.map((job) => {
    if (job) {
      const idRow = job.dwoJobId ? job.dwoJobId.toString() : "";
      const id = { value: job.jobId as string };
      const address = { value: job.address };
      const jobRegion = job.region as RegionModel;
      const region = { value: jobRegion.name || "" };
      const category = { value: job.category };

      const innerCells = [id, address, region, category];
      const isCheckedRow = Boolean(
        jobSelectedId.find((idSelected) => idSelected === idRow),
      );

      if (isHeaderChecked) {
        checkboxHandler(idRow, true);
      }

      if (clearStatus === "clear" && !isHeaderChecked) {
        checkboxHandler(idRow, false);
        setClearStatus("");
      }

      return {
        idRow,
        innerCells,
        isCheckedRow,
        onClickCheckbox: checkboxHandler,
      };
    }
    return {} as MainTableRow;
  });
