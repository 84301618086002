import React from "react";
import { SvgIcon } from "@material-ui/core";

interface GoogleIconProps {
  color?: string;
  fontSize?: number;
}

export const GoogleIcon = ({ color = "black", fontSize }: GoogleIconProps) => {
  return (
    <SvgIcon htmlColor={color} style={{ fontSize: fontSize }}>
      <path
        d="M5.625,107.517a6.336,6.336,0,0,1,.9-3.257v-4.046H2.476a12.006,12.006,0,0,0,0,14.6H6.522v-4.046A6.336,6.336,0,0,1,5.625,107.517Z"
        transform="translate(0 -95.517)"
      />
      <path
        d="M198.813,373.853,196,376.666l2.813,2.813a11.909,11.909,0,0,0,7.3-2.476v-4.041h-4.041A6.374,6.374,0,0,1,198.813,373.853Z"
        transform="translate(-186.813 -355.478)"
      />
      <path
        d="M56.869,325.477l-4.046,4.046a12.189,12.189,0,0,0,1.039,1.183,11.922,11.922,0,0,0,8.485,3.515V328.6A6.378,6.378,0,0,1,56.869,325.477Z"
        transform="translate(-50.347 -310.22)"
      />
      <path
        d="M268,200.074a12.106,12.106,0,0,0-.2-2.174l-.106-.577H256v5.625h5.693a6.346,6.346,0,0,1-2.432,2.608L263.3,209.6a12.193,12.193,0,0,0,1.183-1.039A11.922,11.922,0,0,0,268,200.074Z"
        transform="translate(-244 -188.074)"
      />
      <path
        d="M203.32,7.492l.5.5,3.977-3.977-.5-.5A11.921,11.921,0,0,0,198.812,0L196,2.812l2.813,2.812A6.333,6.333,0,0,1,203.32,7.492Z"
        transform="translate(-186.812 0)"
      />
      <path
        d="M62.346,5.625V0a11.922,11.922,0,0,0-8.485,3.515A12.181,12.181,0,0,0,52.822,4.7l4.046,4.046a6.378,6.378,0,0,1,5.478-3.118Z"
        transform="translate(-50.346 0)"
      />
    </SvgIcon>
  );
};
