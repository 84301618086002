import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
  Grid,
  AppBar,
  Tabs,
  Tab,
} from "@material-ui/core";
import { Layout } from "components/layout/Layout";
import React, { useEffect, useState } from "react";
import { CrewTimesheetList } from "components/timesheetManagement/CrewTimesheetList";
import { EmployeeTimesheetList } from "components/timesheetManagement/EmployeeTimesheetList";
import { TimeSheetTabs } from "utils/timesheetsManagementUtils";
import {
  selectTimesheetTabSelected,
  setTabSelected,
} from "features/dwo/dwoSlice";
import { useDispatch, useSelector } from "react-redux";

const tabStyles = makeStyles((theme: Theme) =>
  createStyles({
    shadowLeft: {
      backgroundImage:
        "linear-gradient(270deg, rgba(131,131,133,1) 90%, rgba(31,31,31,1) 100%, rgba(0,0,0,1) 100%)",
      bottom: "8px",
      color: "white",
      font: `normal normal bold 14px/19px ${theme.typography.fontFamily}`,
      textTransform: "capitalize",
    },
    shadowRight: {
      backgroundImage:
        "linear-gradient(90deg, rgba(131,131,133,1) 90%, rgba(31,31,31,1) 100%, rgba(0,0,0,1) 100%)",
      bottom: "8px",
      color: "white",
      font: `normal normal bold 14px/19px ${theme.typography.fontFamily}`,
      textTransform: "capitalize",
    },
    activeTab: {
      backgroundImage:
        "linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(0,0,0,1) 0%, rgba(255,255,255,1) 0%)",
      bottom: "8px",
      boxShadow:
        "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
      color: theme.palette.primary.dark,
      font: `normal normal bold 14px/19px ${theme.typography.fontFamily}`,
      outline: "none",
      textTransform: "capitalize",
    },
    tabsContainer: {
      backgroundColor: "transparent",
      boxShadow: "none",
      marginLeft: "24px",
      width: "fit-content",
      height: "40px",
      [theme.breakpoints.down("xs")]: {
        width: "271px",
      },
      zIndex: 1,
    },
    indicator: {
      display: "none",
      outline: "none",
    },
  }),
);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      fontSize: "32px",
      color: theme.palette.primary.main,
      fontWeight: 600,
    },
  }),
);

export function TimesheetManagementScreen() {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(TimeSheetTabs.Crew);
  const classes = useStyles();
  const tabClasses = tabStyles();
  const tabSelected = useSelector(selectTimesheetTabSelected);

  useEffect(() => {
    setActiveTab(tabSelected);
    // eslint-disable-next-line
  }, []);

  const getA11yProps = (index: number) => {
    return {
      id: `tab-${index}`,
      "aria-controls": `tabPanel-${index}`,
    };
  };

  const getGradients = (index: number) => {
    if (index === 0) {
      return tabClasses.shadowLeft;
    }
    return tabClasses.shadowRight;
  };

  const handleChangeTabs = (
    event: React.ChangeEvent<unknown>,
    newValue: number,
  ) => {
    dispatch(setTabSelected(newValue));
    setActiveTab(newValue);
  };

  return (
    <Layout>
      <Box padding="32px 24px 24px 24px">
        <Typography className={classes.header} component="h1">
          Timesheets
        </Typography>
      </Box>
      <Grid alignItems="center" container spacing={0}>
        <Grid item xs={4}>
          <Box height="32px">
            <AppBar className={tabClasses.tabsContainer} position="static">
              <Tabs
                classes={{ indicator: tabClasses.indicator }}
                value={activeTab}
                onChange={handleChangeTabs}
              >
                <Tab
                  classes={{ selected: tabClasses.activeTab }}
                  className={getGradients(activeTab)}
                  label="Crew"
                  {...getA11yProps(0)}
                />

                <Tab
                  classes={{ selected: tabClasses.activeTab }}
                  className={getGradients(activeTab)}
                  label="Employee"
                  {...getA11yProps(1)}
                />
              </Tabs>
            </AppBar>
          </Box>
        </Grid>
      </Grid>
      {activeTab === TimeSheetTabs.Crew && <CrewTimesheetList />}
      {activeTab === TimeSheetTabs.Employee && <EmployeeTimesheetList />}
    </Layout>
  );
}
