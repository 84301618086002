import React, { useEffect, useState } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import TimeField from "react-simple-timefield";

import {
  selectValues,
  setValues,
} from "features/editWorkShiftTableSlice/workShiftTableValuesSlice";
import { useDispatch, useSelector } from "react-redux";

interface CrewOverviewTableTimeInputProps {
  defaultValue?: string;
  id: string;
  inputType: string;
  isEditModeActive: boolean;
  label?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexWrap: "wrap",
    },
    textField: {
      height: "39px",
      width: "60px",
      "& .MuiInputBase-root": {
        width: "60px",
        height: "39px",
      },
    },
  }),
);

export function CrewOverviewTableTimeInput({
  defaultValue,
  id,
  inputType,
  label,
}: CrewOverviewTableTimeInputProps) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const values = useSelector(selectValues);
  const [time, setTime] = useState(defaultValue || "");
  const [isRowActive, setIsRowActive] = useState(false);

  useEffect(() => {
    setIsRowActive(Boolean(values.find((value) => value.idRow === Number(id))));
  }, [values, id]);

  useEffect(() => {
    dispatch(
      setValues({
        id: inputType,
        idRow: Number(id),
        inputValue: time,
      }),
    );
  }, [time]);

  useEffect(() => {
    if (isRowActive) {
      dispatch(
        setValues({
          id: inputType,
          idRow: Number(id),
          inputValue: time,
        }),
      );
    }
  }, [dispatch, id, inputType, isRowActive]);

  const handleChangeTime = (event: any) => {
    setTime(event.target.value);
  };

  return (
    <form className={classes.container} noValidate>
      <TimeField
        colon=":"
        onChange={handleChangeTime}
        input={
          <TextField
            className={classes.textField}
            id={id}
            InputLabelProps={{
              shrink: true,
            }}
          />
        }
        value={defaultValue}
      />
    </form>
  );
}
