import { DropdownOption } from "components/Dropdown";
import { JobModel } from "@dwo/shared/dist/models/jobModel";
import * as Yup from "yup";
import { DEFAULT_EMPTY } from "./jobUtils";
import { JobCategories } from "@dwo/shared/dist/models/jobSummaryModel";

export const categoryOptions: DropdownOption[] = [
  { label: JobCategories.OhElectric, value: JobCategories.OhElectric },
  { label: JobCategories.UgGas, value: JobCategories.UgGas },
  { label: JobCategories.UgElectric, value: JobCategories.UgElectric },
  { label: JobCategories.CastIron, value: JobCategories.CastIron },
  { label: JobCategories.Overhead, value: JobCategories.Overhead },
  { label: JobCategories.General, value: JobCategories.General },
];

export const companiesOptions: DropdownOption[] = [
  { label: "TSU One", value: "TSU1" },
  { label: "Pinnacle", value: "Pinnacle" },
];

export const jobTypesOptions: DropdownOption[] = [
  { label: "Per Foot", value: "perfoot" },
  { label: "Per Hour", value: "hourly" },
  { label: "Bid", value: "Bid" },
  { label: "Unit", value: "Unit" },
];

export const workOrderTypeOptions: DropdownOption[] = [
  { label: "New Construction", value: "newConstruction" },
  { label: "Maintenance", value: "maintenance" },
];

/* Client requested to comment this Dropdown selection, the options were moved to Job Type */
// export const contractTypeOptions: DropdownOption[] = [
//   { label: "Bid", value: "Bid" },
//   { label: "MSA", value: "MSA" },
// ];

export interface JobFormFields {
  address: string;
  billedTo?: string;
  category: string;
  companyName?: string;
  // contractType: string;
  description?: string;
  endDate: Date;
  estimatedCost: string;
  fcc?: string;
  inspector?: string;
  jobType: string;
  labors?: string;
  materials?: string;
  other?: string;
  regionId: number;
  startDate: Date;
  workOrderNumber?: string;
  workOrderType: string;
}

export const DEFAULT_ADDRESS = "Address";
export const DEFAULT_BILLED_TO = "Email Address";
export const DEFAULT_DESCRIPTION = "Write a description of the job.";
export const DEFAULT_NAME = "Name";
export const DEFAULT_SELECT_OPTION = "NO_SELECTION";
export const EMPTY_CURRENCY_FIELD = "0000000";
export const DEFAULT_NO_BID = "0.00";
export const DEFAULT_JOB_FORM_VALUES = [
  DEFAULT_ADDRESS,
  DEFAULT_BILLED_TO,
  DEFAULT_DESCRIPTION,
  DEFAULT_NAME,
  DEFAULT_SELECT_OPTION,
  EMPTY_CURRENCY_FIELD,
  DEFAULT_NO_BID,
];
export const commaRegex = new RegExp(/,/g);

const isValidCurrency = (inputValue: string | null | undefined) => {
  const regex = new RegExp(/^[0-9]{1,3}(?:,?[0-9]{3})*(?:\.[0-9]{1,2})?$/g);

  if (!inputValue || inputValue === "" || inputValue === DEFAULT_NO_BID) {
    return true;
  }

  return regex.test(inputValue as string);
};

export const getInitialJobFormValues = (jobData?: JobModel) => {
  const labors = jobData?.bidLaborCost
    ? parseFloat(jobData.bidLaborCost).toLocaleString("en-US", {
        minimumFractionDigits: 2,
      })
    : EMPTY_CURRENCY_FIELD;
  const materials = jobData?.bidMaterialsCost
    ? parseFloat(jobData.bidMaterialsCost).toLocaleString("en-US", {
        minimumFractionDigits: 2,
      })
    : EMPTY_CURRENCY_FIELD;
  const other = jobData?.bidOtherCost
    ? parseFloat(jobData.bidOtherCost).toLocaleString("en-US", {
        minimumFractionDigits: 2,
      })
    : EMPTY_CURRENCY_FIELD;
  const estimatedCost = jobData?.bidPrice
    ? parseFloat(jobData.bidPrice).toLocaleString("en-US", {
        minimumFractionDigits: 2,
      })
    : updateEstimatedCost(
        jobData?.bidLaborCost,
        jobData?.bidMaterialsCost,
        jobData?.bidOtherCost,
      );
  const startDate = jobData?.startDate
    ? new Date(jobData.startDate)
    : new Date();
  const endDate = jobData?.endDate ? new Date(jobData.endDate) : new Date();
  startDate.setHours(0, 0, 0, 0);
  endDate.setHours(23, 59, 59, 999);

  const jobFormFields: JobFormFields = {
    address: jobData?.address || DEFAULT_ADDRESS,
    regionId: jobData?.regionId || 0,
    category: jobData?.category || DEFAULT_SELECT_OPTION,
    jobType: jobData?.type || DEFAULT_SELECT_OPTION,
    companyName: jobData?.company || DEFAULT_SELECT_OPTION,
    workOrderType: jobData?.workOrderType || DEFAULT_SELECT_OPTION,
    startDate,
    endDate,
    workOrderNumber: jobData?.workOrderNumber || "Work order number",
    fcc: jobData?.fcc || DEFAULT_NAME,
    // contractType: jobData?.contractType || DEFAULT_SELECT_OPTION,
    labors,
    materials,
    other,
    estimatedCost,
    inspector: jobData?.inspector || DEFAULT_NAME,
    billedTo: jobData?.billedTo || DEFAULT_BILLED_TO,
    description: jobData?.description || DEFAULT_DESCRIPTION,
  };

  return jobFormFields;
};

export const validationJobForm = (isEditing?: boolean) =>
  Yup.object({
    address: Yup.string()
      .required("Address is required")
      .test(
        "match",
        "Address is required",
        (address) => address !== DEFAULT_ADDRESS,
      ),
    billedTo: Yup.string().test("match", "Invalid Email", (email) => {
      const regex = new RegExp(
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
      );
      if (!email || email === "" || email === DEFAULT_BILLED_TO) {
        return true;
      }
      return regex.test(String(email));
    }),
    category: Yup.string()
      .required("Category is required")
      .test(
        "match",
        "Select a Category",
        (category) => category !== DEFAULT_SELECT_OPTION,
      ),
    companyName: Yup.string(),
    description: Yup.string(),
    endDate: Yup.date()
      .required("End Date is required")
      .test(
        "End Date must be greater than Start Date",
        "End Date must be greater than Start Date",
        function (endDate) {
          if (endDate) {
            const startDate = this.parent.startDate;
            const newEndDate = new Date(endDate.getTime());

            newEndDate.setHours(
              startDate.getHours(),
              startDate.getMinutes(),
              startDate.getSeconds(),
              startDate.getMilliseconds() + 1,
            );

            return newEndDate > startDate;
          }

          return false;
        },
      ),
    estimatedCost: Yup.string().test(
      "match",
      "Invalid Estimated Cost",
      (inputValue) => {
        return isValidCurrency(inputValue);
      },
    ),
    fcc: Yup.string(),
    inspector: Yup.string(),
    jobType: Yup.string()
      .required("Job Type is required")
      .test(
        "match",
        "Select a Job Type",
        (jobType) => jobType !== DEFAULT_SELECT_OPTION,
      ),
    labors: Yup.string().test("match", "Invalid Labors", (inputValue) => {
      return isValidCurrency(inputValue);
    }),
    materials: Yup.string().test("match", "Invalid Materials", (inputValue) => {
      return isValidCurrency(inputValue);
    }),
    other: Yup.string().test("match", "Invalid Other", (inputValue) => {
      return isValidCurrency(inputValue);
    }),
    regionId: Yup.number().required("Region is required").moreThan(0),
    startDate: Yup.date()
      .required("Start Date is required")
      .test("Past Date", "Start Date can't be past", (startDate) => {
        if (isEditing) {
          return true;
        }

        if (startDate) {
          const currentDate = new Date();

          currentDate.setHours(
            startDate.getHours(),
            startDate.getMinutes(),
            startDate.getSeconds(),
            startDate.getMilliseconds(),
          );

          return startDate >= currentDate;
        }

        return false;
      }),
    workOrderType: Yup.string()
      .required("WOT is required")
      .test(
        "match",
        "Select a Category",
        (category) => category !== DEFAULT_SELECT_OPTION,
      ),
    workOrderNumber: Yup.string(),
    // contractType: Yup.string()
    //   .required("Contract Type is required")
    //   .test(
    //     "match",
    //     "Select a Contract Type",
    //     (contractType) => contractType !== DEFAULT_SELECT_OPTION,
    //   ),
  });

export const updateEstimatedCost = (
  labors?: string,
  materials?: string,
  other?: string,
) => {
  const laborsAmount = labors ? parseFloat(labors) : 0.0;
  const materialsAmount = materials ? parseFloat(materials) : 0.0;
  const otherAmount = other ? parseFloat(other) : 0.0;
  const estimatedAmount = laborsAmount + materialsAmount + otherAmount;

  return estimatedAmount > 0
    ? estimatedAmount.toLocaleString("en-US", { minimumFractionDigits: 2 })
    : EMPTY_CURRENCY_FIELD;
};

export const getDropdownOptionLabel = (
  options: DropdownOption[],
  value: string = DEFAULT_EMPTY,
) => {
  const option = options.find(
    (option: DropdownOption) => option.value === value,
  );

  return option ? option.label : DEFAULT_EMPTY;
};
