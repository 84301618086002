import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import { GlobalModal } from "components/globalModal/GlobalModal";
import { TableCustom } from "components/table/TableCustom";
import {
  getJobForemen,
  selectForemen,
  selectIsLoadingForemen,
} from "features/jobs/jobsSlice";
import {
  getVehicles,
  selectIsLoading,
  selectVehicles,
} from "features/vehicles/vehiclesSlice";
import { EmployeeModel } from "@dwo/shared/dist/models/employeeModel";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { ServiceOptions } from "@dwo/shared/dist/services/baseService";
import {
  getVehicleModalRows,
  vehiclesModalColumns,
} from "utils/jobDetailsUtils";

interface VehicleHistoryModalProps {
  isOpen: boolean;
  jobDisplayedId: string;
  onClickClose: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    jobId: {
      color: theme.palette.primary.main,
    },
  }),
);

export function VehicleHistoryModal({
  isOpen,
  jobDisplayedId,
  onClickClose,
}: VehicleHistoryModalProps) {
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const jobId = parseInt(id, 10);
  const foremen = useSelector(selectForemen);
  const isLoadingJob = useSelector(selectIsLoading);
  const isLoadingForemen = useSelector(selectIsLoadingForemen);
  const vehicles = useSelector(selectVehicles);
  const classes = useStyles();
  const [foremenIds, setForemenIds] = useState<number[]>([]);
  const [query, setQuery] = useState<ServiceOptions>();
  const [sortingOptions, setSortingOptions] = useState<string[][]>([]);

  const isLoading = isLoadingForemen || isLoadingJob;

  useEffect(() => {
    dispatch(
      getJobForemen({
        where: {
          jobId,
        },
        include: [{ EmployeeCrew: ["employee"] }],
      }),
    );
  }, [dispatch, jobId]);

  useEffect(() => {
    if (foremen && foremen.length > 0) {
      const idsOfForemen = foremen.map(
        (foreman: EmployeeModel) => foreman.id as number,
      );

      setForemenIds(idsOfForemen);
    }
  }, [foremen]);

  useEffect(() => {
    dispatch(getVehicles(foremenIds, jobId, query));
  }, [foremenIds, jobId, query, dispatch]);

  const handleClickSort = (sortingValues: string[][]) => {
    setSortingOptions(sortingValues);
    setQuery({
      order: sortingValues.length > 0 ? sortingValues : undefined,
    });
  };

  return (
    <GlobalModal
      Filters={
        <Box marginTop="-8px" padding="0 24px 16px 24px">
          <Typography
            className={classes.jobId}
            variant="h6"
          >{`Job ID ${jobDisplayedId}`}</Typography>
        </Box>
      }
      modalMaxWidth="768px"
      open={isOpen}
      showActionButtons={false}
      title="Vehicles"
      onClickClose={onClickClose}
    >
      <TableCustom
        columns={vehiclesModalColumns}
        isLoading={isLoading}
        noRowsMessage="There are no vehicles history yet."
        rows={getVehicleModalRows(vehicles)}
        sortingOptions={sortingOptions}
        onClickSort={handleClickSort}
      />
    </GlobalModal>
  );
}
