import { JobModel } from "@dwo/shared/dist/models/jobModel";
import { RegionModel } from "@dwo/shared/dist/models/regionModel";
import {
  Box,
  Button,
  createStyles,
  Grid,
  makeStyles,
  Paper,
  Theme,
  Typography,
} from "@material-ui/core";
import { EditIconButton } from "components/icons/EditIconButton";
import { LoadingSpinner } from "components/LoadingSpinner";
import React, { useState } from "react";
import { slashFormatDate } from "utils/dateUtils";
import {
  companiesOptions,
  getDropdownOptionLabel,
  jobTypesOptions,
  workOrderTypeOptions,
  // contractTypeOptions,
} from "utils/jobFormUtils";
import { DEFAULT_CURRENCY_EMPTY, DEFAULT_EMPTY } from "utils/jobUtils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    address: {
      color: theme.palette.primary.dark,
      fontSize: "2.5rem",
      fontWeight: 300,
      letterSpacing: 0,
      marginRight: "24px",
    },
    card: {
      backgroundColor: "FFF",
      margin: "0 24px 24px 24px",
      padding: "24px",
    },
    description: {
      color: theme.palette.grey[500],
    },
    fieldLabel: {
      color: theme.palette.grey[700],
      marginRight: "16px",
    },
    fieldValue: {
      color: theme.palette.grey[700],
      fontWeight: "bold",
      marginRight: "8px",
    },
    idContainer: {
      alignItems: "center",
      display: "flex",
      margin: "0 32px 0 0",
      [theme.breakpoints.down("xs")]: {
        margin: "0 0 16px 0",
      },
    },
    idLabel: {
      color: theme.palette.grey[700],
      lineHeight: 1.2,
      marginRight: "8px",
    },
    idValue: {
      color: theme.palette.primary.main,
      lineHeight: 1.2,
    },
    moreButton: {
      border: "none",
      color: theme.palette.primary.main,
      fontSize: "0.875rem",
      fontWeight: "normal",
      height: "auto",
      padding: 0,
      textDecoration: "underline",
      "&:focus": {
        border: "none",
        textDecoration: "underline",
      },
      "&:hover": {
        cursor: "pointer",
        textDecoration: "underline",
      },
    },
    sectionTitle: {
      color: theme.palette.primary.main,
      fontWeight: "bold",
    },
    tagsContainer: {
      display: "flex",
      flexDirection: "row",
      [theme.breakpoints.down("xs")]: {
        flexDirection: "column",
      },
    },
  }),
);

interface JobDetailsCardProps {
  isLoading: boolean;
  jobData?: JobModel;
  onClickEdit: () => void;
}

export function JobDetailsCard({
  isLoading,
  jobData,
  onClickEdit,
}: JobDetailsCardProps) {
  const [isViewingMore, setIsViewingMore] = useState<boolean>(false);
  const classes = useStyles();
  const formatCost = (cost: string) =>
    parseFloat(cost).toLocaleString("en", {
      minimumFractionDigits: 2,
    });
  const address = jobData?.address || "Address";
  const billedTo = jobData?.billedTo || DEFAULT_EMPTY;
  const category = jobData?.category || DEFAULT_EMPTY;
  const company = getDropdownOptionLabel(companiesOptions, jobData?.company);
  const description = (() => {
    if (!jobData?.description || jobData.description.length === 0) {
      return "Write a description of the job.";
    }

    if (jobData.description.length > 255 && !isViewingMore) {
      return `${jobData.description.slice(0, 255)}...`;
    }

    return jobData.description;
  })();
  const estimatedDate = jobData?.endDate
    ? slashFormatDate(new Date(jobData.endDate))
    : "MM/DD/YYYY";
  const estimated =
    jobData && jobData.bidPrice
      ? `$ ${formatCost(jobData.bidPrice)}`
      : DEFAULT_CURRENCY_EMPTY;
  const fcc = jobData?.fcc || DEFAULT_EMPTY;
  const inspector = jobData?.inspector || DEFAULT_EMPTY;
  const jobId = jobData?.jobId || 'No "Job ID"';
  const labors =
    jobData && jobData.bidLaborCost
      ? `$ ${formatCost(jobData.bidLaborCost)}`
      : DEFAULT_CURRENCY_EMPTY;
  const materials =
    jobData && jobData.bidMaterialsCost
      ? `$ ${formatCost(jobData.bidMaterialsCost)}`
      : DEFAULT_CURRENCY_EMPTY;
  const others =
    jobData && jobData.bidOtherCost
      ? `$ ${formatCost(jobData.bidOtherCost)}`
      : DEFAULT_CURRENCY_EMPTY;
  const region = jobData?.region
    ? (jobData.region as RegionModel).name
    : DEFAULT_EMPTY;
  const startDate = jobData?.startDate
    ? slashFormatDate(new Date(jobData.startDate))
    : "MM/DD/YYYY";
  const type = getDropdownOptionLabel(jobTypesOptions, jobData?.type);
  const workOrderType = getDropdownOptionLabel(
    workOrderTypeOptions,
    jobData?.workOrderType,
  );
  const workOrderNumber = jobData?.workOrderNumber || DEFAULT_EMPTY;
  /* Client requested to comment Contract Type selection, the options were moved to Job Type */
  // const contractType = getDropdownOptionLabel(
  //   contractTypeOptions,
  //   jobData?.contractType,
  // );

  const handleClickViewMore = () => setIsViewingMore((prevState) => !prevState);

  if (isLoading) {
    return (
      <Paper className={classes.card}>
        <LoadingSpinner />
      </Paper>
    );
  }

  return (
    <Paper className={classes.card}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box
            alignItems="center"
            display="flex"
            justifyContent="space-between"
          >
            <Typography className={classes.address} component="h2" variant="h3">
              {address}
            </Typography>
            <EditIconButton isDisabled={isLoading} onClickEdit={onClickEdit} />
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box className={classes.tagsContainer}>
            <Box className={classes.idContainer}>
              <Typography className={classes.idLabel} variant="h6">
                JobID:
              </Typography>
              <Typography className={classes.idValue} variant="h6">
                {jobId}
              </Typography>
            </Box>

            <Box alignItems="center" display="flex">
              <Typography className={classes.fieldLabel} variant="body2">
                Region:
              </Typography>
              <Typography className={classes.fieldValue} variant="body2">
                {region}
              </Typography>
            </Box>
            <Box alignItems="center" display="flex" marginLeft={4}>
              <Typography className={classes.fieldLabel} variant="body2">
                Work Order #:
              </Typography>
              <Typography className={classes.fieldValue} variant="body2">
                {workOrderNumber}
              </Typography>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box paddingTop="16px">
            <Typography className={classes.sectionTitle}>
              General Info
            </Typography>
          </Box>
        </Grid>

        <Grid item lg={3} sm={4} xs={12}>
          <Box alignItems="flex-start" display="flex" flexWrap="wrap">
            <Typography className={classes.fieldLabel} variant="body2">
              Category:
            </Typography>
            <Typography className={classes.fieldValue} variant="body2">
              {category}
            </Typography>
          </Box>
        </Grid>

        <Grid item lg={3} sm={4} xs={12}>
          <Box alignItems="flex-start" display="flex" flexWrap="wrap">
            <Typography className={classes.fieldLabel} variant="body2">
              Job Type:
            </Typography>
            <Typography className={classes.fieldValue} variant="body2">
              {type}
            </Typography>
          </Box>
        </Grid>

        <Grid item lg={3} sm={4} xs={12}>
          <Box alignItems="flex-start" display="flex" flexWrap="wrap">
            <Typography className={classes.fieldLabel} variant="body2">
              Company:
            </Typography>
            <Typography className={classes.fieldValue} variant="body2">
              {company}
            </Typography>
          </Box>
        </Grid>

        <Grid item lg={3} sm={4} xs={12}>
          <Box alignItems="flex-start" display="flex" flexWrap="wrap">
            <Typography className={classes.fieldLabel} variant="body2">
              Inspector:
            </Typography>
            <Typography className={classes.fieldValue} variant="body2">
              {inspector}
            </Typography>
          </Box>
        </Grid>

        <Grid item sm={3} xs={12}>
          <Box alignItems="flex-start" display="flex" flexWrap="wrap">
            <Typography className={classes.fieldLabel} variant="body2">
              Start Date:
            </Typography>
            <Typography className={classes.fieldValue} variant="body2">
              {startDate}
            </Typography>
          </Box>
        </Grid>

        <Grid item sm={3} xs={12}>
          <Box alignItems="flex-start" display="flex" flexWrap="wrap">
            <Typography className={classes.fieldLabel} variant="body2">
              Estimated End Date:
            </Typography>
            <Typography className={classes.fieldValue} variant="body2">
              {estimatedDate}
            </Typography>
          </Box>
        </Grid>

        <Grid item sm={3} xs={12}>
          <Box alignItems="flex-start" display="flex" flexWrap="wrap">
            <Typography className={classes.fieldLabel} variant="body2">
              Work Order Type:
            </Typography>
            <Typography className={classes.fieldValue} variant="body2">
              {workOrderType}
            </Typography>
          </Box>
        </Grid>

        {/* Client requested to comment Contract Type selection, the options were moved to Job Type */}
        {/* <Grid item sm={3} xs={12}>
          <Box alignItems="flex-start" display="flex" flexWrap="wrap">
            <Typography className={classes.fieldLabel} variant="body2">
              Contract Type:
            </Typography>
            <Typography className={classes.fieldValue} variant="body2">
              {contractType}
            </Typography>
          </Box>
        </Grid> */}

        <Grid item xs={12}>
          <Box paddingTop="16px">
            <Typography className={classes.sectionTitle}>Cost Info</Typography>
          </Box>
        </Grid>

        <Grid item sm={4} xs={12}>
          <Box alignItems="flex-start" display="flex" flexWrap="wrap">
            <Typography className={classes.fieldLabel} variant="body2">
              Estimated Labor:
            </Typography>
            <Typography className={classes.fieldValue} variant="body2">
              {labors}
            </Typography>
          </Box>
        </Grid>

        <Grid item sm={4} xs={12}>
          <Box alignItems="flex-start" display="flex" flexWrap="wrap">
            <Typography className={classes.fieldLabel} variant="body2">
              Estimated Materials:
            </Typography>
            <Typography className={classes.fieldValue} variant="body2">
              {materials}
            </Typography>
          </Box>
        </Grid>

        <Grid item sm={4} xs={12}>
          <Box alignItems="flex-start" display="flex" flexWrap="wrap">
            <Typography className={classes.fieldLabel} variant="body2">
              Estimated Other:
            </Typography>
            <Typography className={classes.fieldValue} variant="body2">
              {others}
            </Typography>
          </Box>
        </Grid>

        <Grid item sm={4} xs={12}>
          <Box alignItems="flex-start" display="flex" flexWrap="wrap">
            <Typography className={classes.fieldLabel} variant="body2">
              Estimated Total Cost:
            </Typography>
            <Typography className={classes.fieldValue} variant="body2">
              {estimated}
            </Typography>
          </Box>
        </Grid>

        <Grid item sm={4} xs={12}>
          <Box alignItems="flex-start" display="flex" flexWrap="wrap">
            <Typography className={classes.fieldLabel} variant="body2">
              Client Name:
            </Typography>
            <Typography className={classes.fieldValue} variant="body2">
              {fcc}
            </Typography>
          </Box>
        </Grid>

        <Grid item sm={4} xs={12}>
          <Box alignItems="flex-start" display="flex" flexWrap="wrap">
            <Typography className={classes.fieldLabel} variant="body2">
              Billed to:
            </Typography>
            <Typography className={classes.fieldValue} variant="body2">
              {billedTo}
            </Typography>
          </Box>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Box paddingTop="24px">
          <Typography className={classes.sectionTitle}>Description</Typography>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Box paddingTop="16px">
          <Typography className={classes.description} variant="body2">
            {description}
            {description.length > 255 && (
              <Button
                className={classes.moreButton}
                onClick={handleClickViewMore}
                variant="text"
              >
                {isViewingMore ? ` View Less` : ` View More`}
              </Button>
            )}
          </Typography>
        </Box>
      </Grid>
    </Paper>
  );
}
