import {
  Box,
  createStyles,
  Grid,
  makeStyles,
  Paper,
  Tab,
  Tabs,
  Theme,
  Typography,
  useTheme,
} from "@material-ui/core";
import {
  selectSummarySelectedDateRange,
  selectSummarySelectedTab,
  selectTimesheetCalendarRangeType,
  selectTimesheetForemen,
  selectTimesheetSelectedForeman,
  setSummarySelectedTab,
  setTimesheetCalendarRangeType,
  setTimesheetLabelsAndColors,
  setTimesheetSelectedForeman,
} from "features/summary/summarySlice";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { slashFormatDate } from "utils/dateUtils";
import { Dropdown } from "components/Dropdown";
import {
  getForemenDropdownOptions,
  getTimesheetAdjustedDateRange,
  priorDateColor,
  TimesheetCalendarType,
  TimesheetOverviewCategories,
} from "utils/summaryTimesheetUtils";
import { LabelDescriptionItem } from "./LabelDescriptionItem";
import { ALL_OPTION } from "utils/sharedUtils";
import { TimesheetMonthByWeeksBarChart } from "./TimesheetMonthByWeeksBarChart";
import { TimesheetWeekBarChart } from "./TimesheetWeekBarChart";
import { TimesheetMonthBarChart } from "./TimesheetMonthBartChart";
import { TimesheetQuarterByMonthsBarChart } from "./TimesheetQuarterByMonthsBarChart";
import { TimesheetYearByMonthsBarChart } from "./TimesheetYearByMonthsBarChart";
import { OverviewTabs, TooltipLabelOption } from "utils/summaryUtils";
import { TimesheetYearByQuartersBarChart } from "./TimesheetYearByQuartersBarChart";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    categoriesContainer: {
      display: "flex",
      justifyContent: "flex-end",
      [theme.breakpoints.down("sm")]: {
        justifyContent: "flex-start",
      },
    },
    chartsContainer: {
      alignItems: "center",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      width: "100%",
    },
    container: {
      display: "flex",
      flexDirection: "column",
      margin: "0 24px 24px 24px",
      padding: "24px 32px",
    },
    date: {
      color: theme.palette.primary.dark,
      fontWeight: "bold",
      margin: "0 16px 24px 16px",
      [theme.breakpoints.down("sm")]: {
        margin: "0 0 24px 0",
      },
    },
    dateContainer: {
      display: "flex",
      justifyContent: "center",
      [theme.breakpoints.down("sm")]: {
        justifyContent: "flex-start",
      },
    },
    dropdown: {
      display: "flex",
      justifyContent: "flex-end",
      marginBottom: "24px",
      maxWidth: "160px",
      width: "100%",
      [theme.breakpoints.down("sm")]: {
        maxWidth: "100%",
      },
    },
    labelsContainer: {
      display: "flex",
      flexDirection: "column",
      height: "100%",
      justifyContent: "flex-start",
      width: "fit-content",
      [theme.breakpoints.down("sm")]: {
        paddingBottom: "24px",
      },
    },
    tab: {
      fontWeight: "bold",
      fontSize: "0.75rem",
      maxHeight: "32px",
      maxWidth: "fit-content",
      minHeight: "32px",
      minWidth: "fit-content",
      padding: "8px",
      textTransform: "capitalize",
      width: "100%",
      "& > span": {
        width: "fit-content",
      },
    },
    tabsContainer: {
      color: theme.palette.grey[600],
      minHeight: "fit-content",
    },
    title: {
      color: theme.palette.primary.main,
      marginBottom: "24px",
    },
  }),
);

export function SummaryTimesheetOverviewCard() {
  const dispatch = useDispatch();
  const theme = useTheme();
  const priorDateLabelOption = useRef<TooltipLabelOption>({
    color: priorDateColor,
    label: TimesheetOverviewCategories.PriorDate,
  });
  const selectedDateLabelOption = useRef<TooltipLabelOption>({
    color: theme.palette.primary.main,
    label: TimesheetOverviewCategories.SelectedDate,
  });
  const calendarRangeType = useSelector(selectTimesheetCalendarRangeType);
  const currentDateRange = useSelector(selectSummarySelectedDateRange);
  const foremen = useSelector(selectTimesheetForemen);
  const overviewTab = useSelector(selectSummarySelectedTab);
  const selectedForeman = useSelector(selectTimesheetSelectedForeman);
  const [displayedDate, setDisplayedDate] = useState<string>("");
  const classes = useStyles();

  const displayTabs =
    calendarRangeType === TimesheetCalendarType.PerMonth ||
    calendarRangeType === TimesheetCalendarType.PerYear;

  useEffect(() => {
    if (!currentDateRange) return;

    const { startDate, endDate } = currentDateRange;
    const { calendarRangeType } = getTimesheetAdjustedDateRange(
      new Date(startDate),
      new Date(endDate),
    );
    dispatch(setTimesheetCalendarRangeType(calendarRangeType));
    dispatch(setSummarySelectedTab(OverviewTabs.LeftTab));
    // eslint-disable-next-line
  }, []);

  const foremenOptions = useCallback(() => {
    return getForemenDropdownOptions(foremen);
  }, [foremen]);

  useEffect(() => {
    if (!currentDateRange) return;

    const {
      startDate: startDateString,
      endDate: endDateString,
    } = currentDateRange;
    const startDate = new Date(startDateString);
    const endDate = new Date(endDateString);

    setDisplayedDate(
      `${slashFormatDate(startDate)} - ${slashFormatDate(endDate)}`,
    );
  }, [dispatch, currentDateRange]);

  useEffect(() => {
    dispatch(
      setTimesheetLabelsAndColors([
        priorDateLabelOption.current,
        selectedDateLabelOption.current,
      ]),
    );
  }, [dispatch, priorDateLabelOption, selectedDateLabelOption]);

  const handleChangeTabs = (
    _event: React.ChangeEvent<unknown>,
    selectedTab: OverviewTabs,
  ) => dispatch(setSummarySelectedTab(selectedTab));

  const handleSelectForeman = (selectedForeman: string) =>
    dispatch(
      setTimesheetSelectedForeman(
        selectedForeman === ALL_OPTION.value ? undefined : selectedForeman,
      ),
    );

  return (
    <Paper className={classes.container}>
      <Grid container>
        <Grid item sm={4} xs={12}>
          <Typography className={classes.title} variant="h6">
            Overview
          </Typography>
        </Grid>

        <Grid item sm={4} xs={12}>
          <Box className={classes.dateContainer}>
            <Typography className={classes.date} variant="body1">
              {displayedDate}
            </Typography>
          </Box>
        </Grid>

        <Grid item sm={4} xs={12}>
          <Box className={classes.categoriesContainer}>
            <Box className={classes.dropdown}>
              <Dropdown
                id="summary-foremen"
                label="By Foreman"
                options={foremenOptions()}
                selectedValue={
                  selectedForeman ? selectedForeman.toString() : ""
                }
                onSelectOption={handleSelectForeman}
              />
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12}>
          {displayTabs && (
            <Box
              alignItems="center"
              display="flex"
              justifyContent="center"
              marginBottom="24px"
              marginTop="-24px"
              width="100%"
            >
              <Tabs
                classes={{ root: classes.tabsContainer }}
                indicatorColor="primary"
                value={overviewTab}
                onChange={handleChangeTabs}
              >
                <Tab
                  classes={{ root: classes.tab }}
                  label={
                    calendarRangeType === TimesheetCalendarType.PerMonth
                      ? "Weekly"
                      : "Monthly"
                  }
                />
                <Tab
                  classes={{ root: classes.tab }}
                  label={
                    calendarRangeType === TimesheetCalendarType.PerMonth
                      ? "Monthly"
                      : "Quarterly"
                  }
                />
              </Tabs>
            </Box>
          )}
        </Grid>

        <Grid item sm={2} xs={12}>
          <Box className={classes.labelsContainer}>
            <LabelDescriptionItem itemData={priorDateLabelOption.current} />
            <LabelDescriptionItem itemData={selectedDateLabelOption.current} />
          </Box>
        </Grid>

        <Grid item sm={8} xs={12}>
          <Box className={classes.chartsContainer}>
            {calendarRangeType === TimesheetCalendarType.PerWeek && (
              <TimesheetWeekBarChart />
            )}
            {calendarRangeType === TimesheetCalendarType.PerMonth &&
              overviewTab === OverviewTabs.LeftTab && (
                <TimesheetMonthByWeeksBarChart />
              )}
            {calendarRangeType === TimesheetCalendarType.PerMonth &&
              overviewTab === OverviewTabs.RightTab && (
                <TimesheetMonthBarChart />
              )}
            {calendarRangeType === TimesheetCalendarType.PerQuarter && (
              <TimesheetQuarterByMonthsBarChart />
            )}
            {calendarRangeType === TimesheetCalendarType.PerYear &&
              overviewTab === OverviewTabs.LeftTab && (
                <TimesheetYearByMonthsBarChart />
              )}
            {calendarRangeType === TimesheetCalendarType.PerYear &&
              overviewTab === OverviewTabs.RightTab && (
                <TimesheetYearByQuartersBarChart />
              )}
          </Box>
        </Grid>

        <Grid item sm={2} xs={12} />
      </Grid>
    </Paper>
  );
}
