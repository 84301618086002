import { TabContent } from "components/userManagement/UserDetails/TabContent";
import React, { Fragment, ReactNode } from "react";

interface ModalTabsContentProps {
  activeTab: number;
  FirstTabContent: ReactNode;
  SecondTabContent: ReactNode;
}

export function ModalTabsContent({
  activeTab,
  FirstTabContent,
  SecondTabContent,
}: ModalTabsContentProps) {
  return (
    <Fragment>
      <TabContent index={0} value={activeTab}>
        {FirstTabContent}
      </TabContent>
      <TabContent index={1} value={activeTab}>
        {SecondTabContent}
      </TabContent>
    </Fragment>
  );
}
