import React from "react";
import { RouteComponentProps, Switch } from "react-router-dom";
import { JobDetails } from "screens/JobDetailsScreen";
import { JobManagementScreen } from "screens/JobManagementScreen";
import { ProtectedRoute } from "./ProtectedRoute";

export function JobRouter({ match: { url } }: RouteComponentProps) {
  return (
    <Switch>
      <ProtectedRoute exact path={`${url}/`}>
        <JobManagementScreen />
      </ProtectedRoute>
      <ProtectedRoute exact path={`${url}/:id`}>
        <JobDetails />
      </ProtectedRoute>
    </Switch>
  );
}
