import {
  createStyles,
  makeStyles,
  TextField,
  TextFieldProps,
  Theme,
  Typography,
} from "@material-ui/core";
import React, { Fragment } from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    errorMessage: {
      color: theme.palette.error.main,
      paddingTop: "4px",
    },
    inputField: {
      backgroundColor: theme.palette.grey[300],
      borderRadius: "2px",
      "& input": {
        height: "48px",
        margin: "8px 8px -8px 8px",
        padding: 0,
      },
      "& label + .MuiInput-formControl": {
        marginTop: 0,
      },
      "& .MuiInputBase-root": {
        color: theme.palette.grey[600],
      },
      "& .MuiInputBase-root.Mui-disabled": {
        color: theme.palette.grey[600],
      },
      "& .MuiInputBase-multiline": {
        padding: "16px 8px 8px 8px",
      },
    },
    root: {
      height: "auto",
      "&> .MuiInputLabel-root": {
        color: theme.palette.primary.main,
        fontSize: "0.75rem",
        fontWeight: "bold",
      },
      "& .MuiInputAdornment-positionStart": {
        margin: "14px -4px 0 8px",
      },
    },
  }),
);

interface TextFieldCustomProps {
  error?: boolean;
  errorMessage?: string;
}

export function TextFieldCustom({
  error,
  errorMessage,
  ...textFieldProps
}: TextFieldCustomProps & TextFieldProps) {
  const classes = useStyles();

  return (
    <Fragment>
      <TextField
        classes={{ root: classes.root }}
        className={classes.inputField}
        {...textFieldProps}
      />
      {error && (
        <Typography className={classes.errorMessage} variant="body2">
          {errorMessage}
        </Typography>
      )}
    </Fragment>
  );
}
