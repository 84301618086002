import {
  createStyles,
  makeStyles,
  SvgIconProps,
  Theme,
} from "@material-ui/core";
import { FiberManualRecord } from "@material-ui/icons";
import React from "react";
import { JobStatusValues } from "utils/jobUtils";

interface JobStatusDotProps {
  customFontSize?: string;
  status: JobStatusValues;
}

interface StyleProps {
  customFontSize: string;
  status: JobStatusValues;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      color: ({ status }: StyleProps) => {
        switch (status) {
          case JobStatusValues.ASSIGNED:
            return theme.palette.grey[500];
          case JobStatusValues.COMPLETED:
            return theme.palette.success.main;
          case JobStatusValues.DONE:
            return theme.palette.grey[800];
          case JobStatusValues.IN_PROGRESS:
            return theme.palette.primary.light;
          case JobStatusValues.SUSPENDED:
            return theme.palette.warning.light;
          case JobStatusValues.UNASSIGNED:
            return theme.palette.grey[200];
          case JobStatusValues.CANCELED:
            return theme.palette.secondary.main;
          default:
            return theme.palette.primary.light;
        }
      },
      fontSize: ({ customFontSize }: StyleProps) => customFontSize,
    },
  }),
);

export function JobStatusDot({
  customFontSize = "1rem",
  status,
  ...svgIconProps
}: JobStatusDotProps & SvgIconProps) {
  const classes = useStyles({ customFontSize, status });

  return <FiberManualRecord className={classes.icon} {...svgIconProps} />;
}
