import {
  Box,
  Button,
  createStyles,
  Divider,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import { GlobalModal } from "components/globalModal/GlobalModal";
import React, { useEffect, useState, Fragment } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { v4 as uuid } from "uuid";
import { InvoiceForm } from "./InvoiceForm";
import { JobInvoiceModel } from "@dwo/shared/dist/models/jobInvoiceModel";
import {
  createJobInvoice,
  updateJobInvoice,
  deleteJobInvoice,
} from "features/jobs/jobsSlice";
import {
  InvoiceValues,
  getUpdatedInvoices,
  getHasInvoicesError,
} from "utils/invoicesUtils";

interface AddInvoicesModalProps {
  isOpen: boolean;
  isEdit?: boolean;
  initialInvoices?: JobInvoiceModel[];
  onClickClose: VoidFunction;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      color: theme.palette.primary.main,
      marginBottom: "8px",
    },
    addInvoicesButton: {
      width: "100%",
      marginTop: "13.5px",
    },
    addNewInvoicesButton: {
      width: "100%",
    },
    divider: {
      width: "100%",
      marginTop: "13.5px",
    },
    deleteLink: {
      color: theme.palette.primary.light,
      textDecoration: "underline",
      "&:hover": {
        cursor: "pointer",
      },
    },
  }),
);

const getInviceEmptyValue = (): InvoiceValues => ({
  values: {
    amount: "",
    date: new Date(),
    invoiceNumber: "",
    percentageBilled: "",
  },
  id: uuid(),
});

export function AddInvoicesModal({
  isEdit = false,
  isOpen,
  initialInvoices,
  onClickClose,
}: AddInvoicesModalProps) {
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const jobId = parseInt(id, 10);
  const [wantsToDelete, setWantsToDelete] = useState(false);
  const [selectedIdToDelete, setSelectedIdToDelete] = useState("");
  const [hasFormErrors, setHasFormErrors] = useState(true);
  const [invoicesValues, setInvoicesValues] = useState<InvoiceValues[]>([
    getInviceEmptyValue(),
  ]);
  const [invoiceValuesToEdit, setInvoiceValuesToEdit] = useState<
    InvoiceValues[]
  >([]);

  const handleFormikValues = (value: InvoiceValues) => {
    if (isEdit) {
      setInvoiceValuesToEdit(getUpdatedInvoices(value, invoiceValuesToEdit));
      return;
    }
    setInvoicesValues(getUpdatedInvoices(value, invoicesValues));
  };

  const classes = useStyles();

  useEffect(() => {
    if (isOpen && isEdit && initialInvoices) {
      setSelectedIdToDelete("");
      setWantsToDelete(false);
      const updatedInvoidesValues: InvoiceValues[] = initialInvoices.map(
        (initInvoice) => ({
          values: {
            amount: initInvoice.amount,
            date: initInvoice.date,
            invoiceNumber: initInvoice.invoiceNumber,
            percentageBilled: initInvoice.percentageBilled,
          },
          id: (initInvoice.id || uuid()).toString(),
        }),
      );
      setInvoicesValues(updatedInvoidesValues);
    }

    if (isOpen && !isEdit) {
      setInvoicesValues([getInviceEmptyValue()]);
    }

    if (!isOpen) {
      setSelectedIdToDelete("");
      setWantsToDelete(false);
    }
  }, [isOpen, isEdit, initialInvoices]);

  useEffect(() => {
    if (isEdit) {
      setHasFormErrors(getHasInvoicesError(invoiceValuesToEdit));
      return;
    }

    setHasFormErrors(getHasInvoicesError(invoicesValues));
  }, [invoicesValues, isEdit, invoiceValuesToEdit]);

  const handleClickConfirmDelete = () => {
    if (wantsToDelete && selectedIdToDelete) {
      dispatch(deleteJobInvoice(parseInt(selectedIdToDelete, 10)));
    }
  };

  const handleCkickCancelDelete = () => {
    setSelectedIdToDelete("");
    setWantsToDelete(false);
  };

  const handleClickDelete = (idToDelete: string) => () => {
    if (isEdit) {
      setSelectedIdToDelete(idToDelete);
      setWantsToDelete(true);
      return;
    }

    const valueIndex = invoicesValues.findIndex(
      (currentValue) => currentValue.id === idToDelete,
    );

    if (valueIndex >= 0) {
      const updatedInvoicesValues = [...invoicesValues];
      updatedInvoicesValues.splice(valueIndex, 1);
      setInvoicesValues(updatedInvoicesValues);
    }
  };

  const handleClickAddInvoice = () =>
    setInvoicesValues([...invoicesValues, getInviceEmptyValue()]);

  const handleClickSave = () => {
    if (isEdit) {
      if (invoiceValuesToEdit.length > 0) {
        invoiceValuesToEdit.forEach(({ values, id }) => {
          dispatch(
            updateJobInvoice(id, {
              ...values,
              jobId: Number(jobId),
            }),
          );
        });

        onClickClose();
      }
      return;
    }

    if (invoicesValues.length > 0) {
      invoicesValues.forEach(({ values }) => {
        dispatch(
          createJobInvoice({
            ...values,
            jobId: Number(jobId),
          }),
        );
      });

      onClickClose();
    }
  };

  const getTitle = () => {
    if (wantsToDelete) {
      return "Delete Billing Invoice?";
    }

    return isEdit ? "Edit Invoice" : "Add New Invoice";
  };

  return (
    <GlobalModal
      customFooter={
        wantsToDelete ? (
          <Fragment />
        ) : (
          <Box display="flex" flexDirection="column" padding="16px 24px 24px">
            {!isEdit && (
              <Fragment>
                <Button
                  className={classes.addNewInvoicesButton}
                  variant="outlined"
                  onClick={handleClickAddInvoice}
                >
                  Add New Billing
                </Button>
                <Divider className={classes.divider} />
              </Fragment>
            )}
            <Button
              className={classes.addInvoicesButton}
              disabled={hasFormErrors}
              variant="contained"
              onClick={handleClickSave}
            >
              {isEdit ? "Confirm" : "Save Invoices"}
            </Button>
          </Box>
        )
      }
      open={isOpen}
      title={getTitle()}
      showActionButtons={wantsToDelete ? true : false}
      primaryLabel="Delete"
      onClickContinue={handleClickConfirmDelete}
      onClickCancel={handleCkickCancelDelete}
      onClickClose={onClickClose}
    >
      {!wantsToDelete && (
        <Box padding="2px 24px 0px">
          {invoicesValues.map((invoice, index, invoiceArr) => (
            <Box key={invoice.id}>
              {(invoiceArr.length > 1 || isEdit) && (
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  padding="24px 0 16px"
                >
                  <Typography variant="h6" color="primary">{`Installment ${
                    index + 1
                  }`}</Typography>
                  <Typography
                    className={classes.deleteLink}
                    variant="body2"
                    onClick={handleClickDelete(invoice.id)}
                  >
                    Delete
                  </Typography>
                </Box>
              )}
              <InvoiceForm
                initialValue={invoice}
                handleFormikValues={handleFormikValues}
              />
            </Box>
          ))}
        </Box>
      )}
    </GlobalModal>
  );
}
