import {
  Box,
  Button,
  createStyles,
  IconButton,
  makeStyles,
  Modal,
  Theme,
  Typography,
} from "@material-ui/core";
import { ArrowBackIos, ArrowForwardIos, Cancel } from "@material-ui/icons";
import { uploadUserImages } from "features/employees/employeesSlice";
import React, { Fragment, useEffect, useState } from "react";
import Carousel from "react-elastic-carousel";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { FileData } from "utils/sharedUtils";

interface UploadUserImagesModalProps {
  open: boolean;
  selectedPictures: FileData[];
  onClickClose: () => void;
}

export type RenderArrowProps = {
  type: "PREV" | "NEXT";
  onClick: () => void;
  isEdge: boolean;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      fontSize: "32px",
      color: theme.palette.primary.main,
      fontWeight: 600,
    },
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
    },
    container: {
      backgroundColor: "white",
      outline: "0",
      width: "570px",
      [theme.breakpoints.down("xs")]: {
        width: "85%",
      },
    },
    carouselContainer: {
      width: "570px",
      [theme.breakpoints.down("xs")]: {
        width: "85%",
      },
      backgroundColor: "white",
      display: "flex",
      justifyContent: "center",
    },
    secondaryButton: {
      color: theme.palette.primary.main,
      fontSize: "28px",
    },
    carousel: {
      "& .rec.rec-slider-container": {
        width: "395px",
      },
    },
    carouselImage: {
      width: "75px",
      height: "75px",
      objectFit: "cover",
    },
    selectedImage: {
      width: "372px",
      maxHeight: "372px",
      objectFit: "cover",
    },
    imageContainer: {
      outline: "none",
    },
    fileName: {
      marginLeft: "100px",
      color: theme.palette.grey["500"],
      fontSize: "14px",
      marginBottom: "8px",
    },
  }),
);

export function UploadUserImagesModal({
  open,
  selectedPictures,
  onClickClose,
}: UploadUserImagesModalProps) {
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const userId = parseInt(id, 10);
  const [selectedSrc, setSelectedSrc] = useState("");
  const [selectedFileName, setSelectedFileName] = useState("");
  const [uploadImages, setUploadImages] = useState(false);
  const classes = useStyles();

  const mainPicture = selectedPictures.length > 0 && selectedPictures[0];

  const handleUploadFile = (files: FileData[]) => {
    const formData = new FormData();

    if (files.length > 0) {
      files.forEach((picture) => {
        formData.append("images", picture.src, picture.fileName);
      });
    }

    return formData;
  };

  useEffect(() => {
    if (!uploadImages) return;
    dispatch(
      uploadUserImages(userId, handleUploadFile(selectedPictures), {
        include: ["Picture"],
        where: { employeeId: userId },
      }),
    );
    setUploadImages(false);
  }, [dispatch, selectedPictures, userId, uploadImages]);

  const handleUploadImages = () => {
    setUploadImages(true);
    onClickClose();
  };

  const getSelectedFileName = () => {
    if (selectedFileName) {
      return selectedFileName;
    }
    return mainPicture && mainPicture.fileName;
  };

  const getSelectedImage = () => {
    if (selectedSrc) {
      return selectedSrc;
    }
    return mainPicture ? URL.createObjectURL(mainPicture.src) : "";
  };

  const getCarouselImages = () => {
    if (selectedSrc && selectedFileName) {
      return selectedPictures.filter(
        (picture) => picture.fileName !== selectedFileName,
      );
    }
    return selectedPictures.slice(1);
  };

  const handleClickImg = (fileName: string) => (
    e: React.MouseEvent<HTMLImageElement>,
  ) => {
    setSelectedSrc(e.currentTarget.getAttribute("src") as string);
    setSelectedFileName(fileName);
  };

  const Arrows = ({ type, onClick, isEdge }: RenderArrowProps) => {
    const pointer = type === "PREV" ? <ArrowBackIos /> : <ArrowForwardIos />;
    return (
      <Box display="flex" alignItems="center">
        <Box display="flex" height="48px" width="48px">
          <IconButton
            aria-label={
              type === "PREV" ? "carousel left arrow" : "carousel right arrow"
            }
            onClick={onClick}
            disabled={isEdge}
          >
            {pointer}
          </IconButton>
        </Box>
      </Box>
    );
  };

  return (
    <Modal
      disablePortal
      className={classes.modal}
      open={open}
      onClose={onClickClose}
      aria-labelledby="image-carousel-modal"
      aria-describedby="review-images-to-upload"
    >
      <Fragment>
        <Box className={classes.container}>
          <Box display="flex" justifyContent="flex-end">
            <IconButton aria-label="close modal" onClick={onClickClose}>
              <Cancel className={classes.secondaryButton} />
            </IconButton>
          </Box>
        </Box>
        <Box className={classes.container}>
          <Typography className={classes.fileName} component="h6">
            {getSelectedFileName()}
          </Typography>
          <Box
            display="flex"
            justifyContent="center"
            padding="0px 28px 28px 28px"
          >
            <img
              alt="selected"
              className={classes.selectedImage}
              src={getSelectedImage()}
            />
          </Box>
        </Box>
        <Box className={classes.carouselContainer}>
          <Box display="flex" alignItems="center">
            {selectedPictures.length > 1 && (
              <Carousel
                className={classes.carousel}
                pagination={false}
                itemsToScroll={4}
                itemsToShow={4}
                renderArrow={Arrows}
              >
                {getCarouselImages().map(({ src, fileName }) => (
                  <Box key={fileName} className={classes.imageContainer}>
                    <img
                      alt="carousel-item"
                      className={classes.carouselImage}
                      src={URL.createObjectURL(src)}
                      onClick={handleClickImg(fileName)}
                    />
                  </Box>
                ))}
              </Carousel>
            )}
          </Box>
        </Box>
        <Box
          className={classes.container}
          display="flex"
          justifyContent="center"
          padding="24px"
        >
          <Button
            style={{ width: "374px" }}
            variant="contained"
            onClick={handleUploadImages}
          >
            Confirm Upload
          </Button>
        </Box>
      </Fragment>
    </Modal>
  );
}
