import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, RootState } from "app/store";

export type PromptValueType = number | string | JSX.Element | undefined;

export enum PromptTypes {
  BOOLEAN = "BOOLEAN",
  CUSTOM = "CUSTOM",
  NUMBER_INPUT = "NUMBER_INPUT",
  TEXT_AREA = "TEXT_AREA",
  TEXT_INPUT = "TEXT_INPUT",
}

export interface PromptConfig {
  title: string;
  message: string;
  type?: PromptTypes;
  value?: PromptValueType;
}

interface PromptState {
  title: string;
  message: string;
  show: boolean;
  response: boolean;
  type?: PromptTypes;
  value?: PromptValueType;
}

const initialState: PromptState = {
  title: "",
  message: "",
  show: false,
  response: false,
  type: PromptTypes.BOOLEAN,
  value: undefined,
};

export const promptSlice = createSlice({
  name: "prompt",
  initialState,
  reducers: {
    configure: (state, action: PayloadAction<PromptConfig>) => {
      const { title, message, type, value } = action.payload;
      state.title = title;
      state.message = message;
      state.response = false;
      state.type = type || PromptTypes.BOOLEAN;
      state.value = value ?? state.value;
    },
    hide: (state) => {
      state.show = false;
    },
    setResponse: (state, action: PayloadAction<boolean>) => {
      state.response = action.payload;
    },
    setValue: (state, action: PayloadAction<PromptValueType>) => {
      state.value = action.payload;
    },
    show: (state) => {
      state.show = true;
    },
  },
});

export const {
  configure,
  hide,
  setResponse,
  setValue,
  show,
} = promptSlice.actions;

export const prompt = (config: PromptConfig): AppThunk => (
  dispatch,
  getState,
) => {
  dispatch(configure(config));
  dispatch(show());

  return new Promise((resolve, _) => {
    const timerHandle = setInterval(() => {
      const doShow = selectShow(getState());

      if (!doShow) {
        resolve(selectResponse(getState()));
        clearInterval(timerHandle);
      }
    }, 100);
  });
};

export const selectMessage = (state: RootState) => state.prompt.message;
export const selectResponse = (state: RootState) => state.prompt.response;
export const selectTitle = (state: RootState) => state.prompt.title;
export const selectType = (state: RootState) => state.prompt.type;
export const selectShow = (state: RootState) => state.prompt.show;
export const selectValue = (state: RootState) => state.prompt.value;

export default promptSlice.reducer;
