import { EmployeeModel } from "@dwo/shared/dist/models/employeeModel";
import {
  JobsSummaryChartData,
  JobsSummaryGeneralData,
} from "@dwo/shared/dist/models/jobSummaryModel";
import {
  TimesheetsDwosData,
  TimesheetsWorkforceData,
  TimesheetStatusPercentageData,
  TimesheetChartPriorData,
  TimesheetChartCurrentData,
} from "@dwo/shared/dist/models/timesheetSummaryModel";
import { AppThunk, RootState } from "app/store";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  getStringDateRangeFromDateRange,
  StringDateRange,
} from "utils/dateUtils";
import {
  jobService,
  SummaryServiceOptions,
} from "@dwo/shared/dist/services/JobService";
import {
  dwoService,
  DwoSummaryServiceOptions,
} from "@dwo/shared/dist/services/dwoService";
import { EmployeesSummaryWorkforce } from "@dwo/shared/dist/models/employeeSummaryModel";
import { employeeService } from "@dwo/shared/dist/services/employeeService";
import { error } from "features/error/errorSlice";
import {
  OverviewTabs,
  SummaryTabs,
  TooltipLabelOption,
} from "utils/summaryUtils";
import { TimesheetCalendarType } from "utils/summaryTimesheetUtils";

interface SummaryState {
  categoriesAndColors: TooltipLabelOption[];
  isLoadingGeneralData: boolean;
  isLoadingTimesheetData: boolean;
  jobsData?: JobsSummaryGeneralData;
  overviewData?: JobsSummaryChartData;
  selectedCategory: string;
  selectedDateRange?: StringDateRange;
  selectedRegion: string;
  selectedTab: OverviewTabs;
  timesheetsData?: TimesheetsDwosData;
  timesheetsWorkforceData?: TimesheetsWorkforceData;
  timesheetsStatusData: TimesheetStatusPercentageData[];
  workforceData?: EmployeesSummaryWorkforce;
  timesheetForemen: EmployeeModel[];
  timesheetSelectedForeman?: string;
  timesheetLabelsAndColors: TooltipLabelOption[];
  timesheetCalendarRangeType?: TimesheetCalendarType;
  overviewCurrentData?: TimesheetChartCurrentData;
  overviewPriorData?: TimesheetChartPriorData;
  tabSelected: SummaryTabs;
}

const initialState: SummaryState = {
  categoriesAndColors: [],
  isLoadingGeneralData: false,
  isLoadingTimesheetData: false,
  jobsData: undefined,
  overviewData: undefined,
  selectedCategory: "",
  selectedDateRange: getStringDateRangeFromDateRange({
    startDate: new Date(),
    endDate: new Date(),
  }),
  selectedRegion: "",
  selectedTab: OverviewTabs.LeftTab,
  timesheetsData: undefined,
  timesheetsWorkforceData: undefined,
  timesheetsStatusData: [],
  workforceData: undefined,
  timesheetForemen: [],
  timesheetSelectedForeman: undefined,
  timesheetLabelsAndColors: [],
  timesheetCalendarRangeType: undefined,
  overviewCurrentData: undefined,
  overviewPriorData: undefined,
  tabSelected: SummaryTabs.Job,
};

export const summarySlice = createSlice({
  name: "summary",
  initialState,
  reducers: {
    setSummaryCategoriesAndColors: (
      state,
      action: PayloadAction<TooltipLabelOption[]>,
    ) => {
      state.categoriesAndColors = action.payload;
    },
    setSummaryIsLoadingGeneralData: (state, action: PayloadAction<boolean>) => {
      state.isLoadingGeneralData = action.payload;
    },
    setSummaryIsLoadingTimesheetData: (
      state,
      action: PayloadAction<boolean>,
    ) => {
      state.isLoadingTimesheetData = action.payload;
    },
    setSummaryJobsData: (
      state,
      action: PayloadAction<JobsSummaryGeneralData | undefined>,
    ) => {
      state.jobsData = action.payload;
    },
    setSummaryOverviewData: (
      state,
      action: PayloadAction<JobsSummaryChartData | undefined>,
    ) => {
      state.overviewData = action.payload;
    },
    setSummarySelectedCategory: (state, action: PayloadAction<string>) => {
      state.selectedCategory = action.payload;
    },
    setSummarySelectedDateRange: (
      state,
      action: PayloadAction<StringDateRange | undefined>,
    ) => {
      state.selectedDateRange = action.payload;
    },
    setSummarySelectedRegion: (state, action: PayloadAction<string>) => {
      state.selectedRegion = action.payload;
    },
    setSummarySelectedTab: (state, action: PayloadAction<OverviewTabs>) => {
      state.selectedTab = action.payload;
    },
    setSummaryWorkforceData: (
      state,
      action: PayloadAction<EmployeesSummaryWorkforce | undefined>,
    ) => {
      state.workforceData = action.payload;
    },
    setTimesheetsData: (
      state,
      action: PayloadAction<TimesheetsDwosData | undefined>,
    ) => {
      state.timesheetsData = action.payload;
    },
    setTimesheetsStatusData: (
      state,
      action: PayloadAction<TimesheetStatusPercentageData[]>,
    ) => {
      state.timesheetsStatusData = action.payload;
    },
    setTimesheetsWorkforceData: (
      state,
      action: PayloadAction<TimesheetsWorkforceData | undefined>,
    ) => {
      state.timesheetsWorkforceData = action.payload;
    },
    setTimesheetForemen: (state, action: PayloadAction<EmployeeModel[]>) => {
      state.timesheetForemen = action.payload;
    },
    setTimesheetSelectedForeman: (
      state,
      action: PayloadAction<string | undefined>,
    ) => {
      state.timesheetSelectedForeman = action.payload;
    },
    setTimesheetLabelsAndColors: (
      state,
      action: PayloadAction<TooltipLabelOption[]>,
    ) => {
      state.timesheetLabelsAndColors = action.payload;
    },
    setTimesheetCalendarRangeType: (
      state,
      action: PayloadAction<TimesheetCalendarType | undefined>,
    ) => {
      state.timesheetCalendarRangeType = action.payload;
    },
    setTimesheetOverviewCurrentData: (
      state,
      action: PayloadAction<TimesheetChartCurrentData | undefined>,
    ) => {
      state.overviewCurrentData = action.payload;
    },
    setTimesheetOverviewPriorData: (
      state,
      action: PayloadAction<TimesheetChartPriorData | undefined>,
    ) => {
      state.overviewPriorData = action.payload;
    },
    setTabSelected: (state, action: PayloadAction<SummaryTabs>) => {
      state.tabSelected = action.payload;
    },
  },
});

export const getSummaryJobPerformanceData = (
  options?: SummaryServiceOptions,
): AppThunk => async (dispatch) => {
  try {
    dispatch(setSummaryIsLoadingGeneralData(true));
    const {
      data: { chart, jobs },
    } = await jobService.getSummaryData(options);
    const {
      data: { workforce },
    } = await employeeService.getSummaryData(options);
    dispatch(setSummaryJobsData(jobs));
    dispatch(setSummaryOverviewData(chart));
    dispatch(setSummaryWorkforceData(workforce));
  } catch (err) {
    dispatch(
      error(
        {
          title: "Could not retrieve Summary data. Please try again.",
          message: err.message,
        },
        () => dispatch(getSummaryJobPerformanceData(options)),
      ),
    );
  } finally {
    dispatch(setSummaryIsLoadingGeneralData(false));
  }
};

export const getSummaryTimesheetsPerformanceData = (
  options?: DwoSummaryServiceOptions,
): AppThunk => async (dispatch) => {
  try {
    dispatch(setSummaryIsLoadingTimesheetData(true));
    const {
      data: { chart, chartOld, dwos, workforce, percentages },
    } = await dwoService.getDwoSummaryData(options);
    const { data: foremen } = await employeeService.getAll({
      where: {
        role: "foreman",
      },
    });
    dispatch(setTimesheetsData(dwos));
    dispatch(setTimesheetsStatusData(percentages));
    dispatch(setTimesheetsWorkforceData(workforce));
    dispatch(setTimesheetForemen(foremen || []));
    dispatch(setTimesheetOverviewCurrentData(chart));
    dispatch(setTimesheetOverviewPriorData(chartOld));
  } catch (err) {
    dispatch(
      error(
        {
          title:
            "Could not retrieve Timesheets Summary data. Please try again.",
          message: err.message,
        },
        () => dispatch(getSummaryTimesheetsPerformanceData(options)),
      ),
    );
  } finally {
    dispatch(setSummaryIsLoadingTimesheetData(false));
  }
};

export const {
  setSummaryCategoriesAndColors,
  setSummaryIsLoadingGeneralData,
  setSummaryIsLoadingTimesheetData,
  setSummaryJobsData,
  setSummaryOverviewData,
  setSummarySelectedCategory,
  setSummarySelectedDateRange,
  setSummarySelectedRegion,
  setSummarySelectedTab,
  setSummaryWorkforceData,
  setTimesheetsData,
  setTimesheetsStatusData,
  setTimesheetsWorkforceData,
  setTimesheetForemen,
  setTimesheetSelectedForeman,
  setTimesheetLabelsAndColors,
  setTimesheetCalendarRangeType,
  setTimesheetOverviewCurrentData,
  setTimesheetOverviewPriorData,
  setTabSelected,
} = summarySlice.actions;

export const selectSummaryCategoriesAndColors = (state: RootState) =>
  state.summary.categoriesAndColors;
export const selectSummaryGeneralData = (state: RootState) =>
  state.summary.jobsData;
export const selectSummaryIsLoadingGeneralData = (state: RootState) =>
  state.summary.isLoadingGeneralData;
export const selectSummaryIsLoadingTimesheetData = (state: RootState) =>
  state.summary.isLoadingTimesheetData;
export const selectSummaryOverviewData = (state: RootState) =>
  state.summary.overviewData;
export const selectSummarySelectedCategory = (state: RootState) =>
  state.summary.selectedCategory;
export const selectSummarySelectedDateRange = (state: RootState) =>
  state.summary.selectedDateRange;
export const selectSummarySelectedRegion = (state: RootState) =>
  state.summary.selectedRegion;
export const selectSummarySelectedTab = (state: RootState) =>
  state.summary.selectedTab;
export const selectSummaryWorkforceData = (state: RootState) =>
  state.summary.workforceData;
export const selectTimesheetsData = (state: RootState) =>
  state.summary.timesheetsData;
export const selectTimesheetForemen = (state: RootState) =>
  state.summary.timesheetForemen;
export const selectTimesheetSelectedForeman = (state: RootState) =>
  state.summary.timesheetSelectedForeman;
export const selectTimesheetLabelsAndColors = (state: RootState) =>
  state.summary.timesheetLabelsAndColors;
export const selectTimesheetCalendarRangeType = (state: RootState) =>
  state.summary.timesheetCalendarRangeType;
export const selectTimesheetsStatusData = (state: RootState) =>
  state.summary.timesheetsStatusData;
export const selectTimesheetsWorkforceData = (state: RootState) =>
  state.summary.timesheetsWorkforceData;
export const selectTimesheetOverviewCurrentData = (state: RootState) =>
  state.summary.overviewCurrentData;
export const selectTimesheetOverviewPriorData = (state: RootState) =>
  state.summary.overviewPriorData;
export const selectSummaryTabSelected = (state: RootState) =>
  state.summary.tabSelected;

export const summaryReducer = summarySlice.reducer;
