import { Box, createStyles, makeStyles, Theme } from "@material-ui/core";
import React from "react";

interface DownloadFileCellProps {
  fileName: string;
  url: string;
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    downloadIcon: {
      color: theme.palette.primary.main,
      margin: "0 32px",
      [theme.breakpoints.down("md")]: {
        marginRight: "16px",
      },
    },
  }),
);

export function DownloadFileCell({ fileName, url }: DownloadFileCellProps) {
  const classes = useStyles();

  return (
    <Box
      alignItems="center"
      display="flex"
      justifyContent="space-between"
      width="100%"
    >
      {fileName}
      {/* The dowload button was hidden for security reasons, by uncommenting this section it will be rendered again. */}
      {/* <a href={url}>
        <GetAppRounded className={classes.downloadIcon} />
      </a> */}
    </Box>
  );
}
