import {
  Box,
  createStyles,
  makeStyles,
  Paper,
  Theme,
  Typography,
} from "@material-ui/core";
import { selectSummaryWorkforceData } from "features/summary/summarySlice";
import React from "react";
import { useSelector } from "react-redux";
import { WorkforceChart } from "./WorkforceChart";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    chartsContainer: {
      alignItems: "center",
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "flex-start",
      "& > div:nth-child(2)": {
        marginLeft: "24px",
        marginRight: "24px",
      },
      [theme.breakpoints.down("md")]: {
        justifyContent: "center",
      },
    },
    container: {
      display: "flex",
      flexDirection: "column",
      margin: "0 24px 24px 24px",
      minHeight: "268px",
      padding: "24px 32px 0 32px",
    },
    title: {
      color: theme.palette.primary.main,
    },
  }),
);

export function SummaryWorkforceCard() {
  const summaryWorkforceData = useSelector(selectSummaryWorkforceData);
  const classes = useStyles();

  const crew = {
    active: summaryWorkforceData?.crewMembers.assigned || 0,
    unassigned: summaryWorkforceData?.crewMembers.unassigned || 0,
  };
  const foremen = {
    active: summaryWorkforceData?.foremen.assigned || 0,
    unassigned: summaryWorkforceData?.foremen.unassigned || 0,
  };
  const supervisors = {
    active: summaryWorkforceData?.supervisors.assigned || 0,
    unassigned: summaryWorkforceData?.supervisors.unassigned || 0,
  };

  return (
    <Paper className={classes.container}>
      <Box width="100%" marginBottom="32px">
        <Typography className={classes.title} variant="h6">
          Workforce
        </Typography>
      </Box>

      <Box className={classes.chartsContainer}>
        <WorkforceChart
          active={supervisors.active}
          chartName="Supervisors"
          tooltipId="supervisorsWorkforce-tooltip"
          unassigned={supervisors.unassigned}
        />
        <WorkforceChart
          active={foremen.active}
          chartName="Foremen"
          tooltipId="foremenWorkforce-tooltip"
          unassigned={foremen.unassigned}
        />
        <WorkforceChart
          active={crew.active}
          chartName="Crew Members"
          tooltipId="crewWorkforce-tooltip"
          unassigned={crew.unassigned}
        />
      </Box>
    </Paper>
  );
}
