import {
  Box,
  Button,
  createStyles,
  IconButton,
  makeStyles,
  Paper,
  Theme,
  Typography,
} from "@material-ui/core";
import { AddCircle } from "@material-ui/icons";
import { LoadingSpinner } from "components/LoadingSpinner";
import { NotesModal } from "components/jobDetails/notesModal/NotesModal";
import {
  createJobNote,
  deleteJobNote,
  getAllJobNotes,
  selectIsLoading,
  selectIsLoadingEditing,
  selectJobNotes,
  updateJobNote,
} from "features/jobs/jobNotes/jobNotesSlice";
import { prompt, selectResponse } from "features/prompt/promptSlice";
import { cloneDeep } from "lodash";
import React, { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { JobNote } from "./JobNote";
import { useDispatch, useSelector } from "react-redux";
import { JobNoteModel } from "@dwo/shared/dist/models/jobNoteModel";
import { slashFormatDate } from "utils/dateUtils";
import { ServiceOptions } from "@dwo/shared/dist/services/baseService";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    addIcon: {
      color: theme.palette.primary.main,
      height: "32px",
      padding: 0,
      width: "32px",
    },
    missingNotes: {
      color: theme.palette.grey[600],
      marginTop: "24px",
    },
    paper: {
      backgroundColor: "white",
      padding: "24px",
    },
    title: {
      color: theme.palette.primary.main,
    },
    viewMoreButton: {
      color: theme.palette.primary.main,
      fontSize: "0.875rem",
      fontWeight: "normal",
      height: "auto",
      marginTop: "16px",
      padding: "0 8px",
      width: "fit-content",
      "& .MuiButton-label": {
        textDecoration: "underline",
      },
    },
  }),
);

const MAX_NOTES = 2;

export function JobNotesCard() {
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const jobId = parseInt(id, 10);
  const isLoadingNotes = useSelector(selectIsLoading);
  const isLoadingEditing = useSelector(selectIsLoadingEditing);
  const promptResponse = useSelector(selectResponse);
  const isLoading = isLoadingNotes || isLoadingEditing;
  const notes = useSelector(selectJobNotes);
  const [deleteNoteId, setDeleteNotebId] = useState<string>("");
  const [isCreatingNote, setIsCreatingNote] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [fetchNotesQuery] = useState<ServiceOptions>({
    where: { jobId },
    include: [{ createdBy: ["employee"] }],
    order: [["updatedAt", "DESC"]],
  });
  const classes = useStyles();

  useEffect(() => {
    dispatch(getAllJobNotes(fetchNotesQuery));
  }, [jobId, fetchNotesQuery, dispatch]);

  useEffect(() => {
    if (deleteNoteId !== "" && promptResponse) {
      dispatch(deleteJobNote(deleteNoteId as string, fetchNotesQuery));
      setDeleteNotebId("");
    }
  }, [deleteNoteId, promptResponse, fetchNotesQuery, dispatch]);

  const handleClickAddButton = () => {
    setIsCreatingNote(true);
    setIsModalOpen(true);
  };

  const handleClickAddNote = () => setIsCreatingNote(true);

  const handleClickCancelNote = () => setIsCreatingNote(false);

  const handleClickClose = () => {
    setIsModalOpen(false);
    setIsCreatingNote(false);
  };

  const handleClickDelete = (id: string) => {
    setDeleteNotebId(id);
    dispatch(
      prompt({
        title: "Remove Note?",
        message: `Are you sure you want to remove the note from this job?`,
      }),
    );
  };

  const handleClickSave = (
    noteText: string,
    noteTitle: string,
    id?: string,
  ) => {
    setIsCreatingNote(false);

    if (id) {
      const selectedNote = notes.find(
        (note: JobNoteModel) => (note.id as number) === parseInt(id, 10),
      );

      if (selectedNote) {
        const updatedNote = cloneDeep(selectedNote);

        updatedNote.dwoId = updatedNote.dwoId || undefined;
        updatedNote.note = noteText;
        updatedNote.title = updatedNote.title || undefined;
        updatedNote.workshiftId = updatedNote.workshiftId || undefined;
        dispatch(updateJobNote(id, updatedNote, fetchNotesQuery));
        return;
      }
    }

    const newNote: JobNoteModel = {
      jobId,
      title: noteTitle,
      note: noteText,
    };

    dispatch(createJobNote(newNote, fetchNotesQuery));
  };

  const handleClickViewMore = () => {
    setIsCreatingNote(false);
    setIsModalOpen(true);
  };

  const renderedNotes = (() => {
    if (notes.length <= MAX_NOTES) {
      return notes.map((jobNote: JobNoteModel) => {
        const fullName = jobNote.createdBy
          ? `${jobNote.createdBy.employee.firstName} ${jobNote.createdBy.employee.lastName}`
          : "User Full Name";
        const date = slashFormatDate(new Date(jobNote.updatedAt as Date));
        return (
          <JobNote
            author={fullName}
            date={date}
            key={jobNote.id as number}
            text={jobNote.note}
          />
        );
      });
    }

    const shownNotes = [];

    for (let i = 0; i < MAX_NOTES; i++) {
      const fullName = notes[i].createdBy
        ? `${notes[i].createdBy?.employee.firstName} ${notes[i].createdBy?.employee.lastName}`
        : "User Full Name";
      const date = slashFormatDate(new Date(notes[i].updatedAt as Date));
      shownNotes.push(
        <JobNote
          author={fullName}
          date={date}
          key={notes[i].id as number}
          text={notes[i].note}
        />,
      );
    }

    return shownNotes;
  })();

  return (
    <Fragment>
      <Paper className={classes.paper}>
        <Box alignItems="center" display="flex" justifyContent="space-between">
          <Typography className={classes.title} variant="h6">
            Notes
          </Typography>
          <IconButton
            aria-label="Add Note"
            className={classes.addIcon}
            disabled={isLoading}
            onClick={handleClickAddButton}
          >
            <AddCircle fontSize="large" />
          </IconButton>
        </Box>

        {isLoading && <LoadingSpinner />}
        {!isLoading && notes.length === 0 && (
          <Typography className={classes.missingNotes} align="center">
            There are no notes to display. Try adding a new note.
          </Typography>
        )}
        {!isLoading && notes.length > 0 && (
          <Box display="flex" flexDirection="column">
            {renderedNotes}
            {notes.length > 2 && (
              <Box display="flex" justifyContent="center">
                <Button
                  className={classes.viewMoreButton}
                  onClick={handleClickViewMore}
                >
                  View More
                </Button>
              </Box>
            )}
          </Box>
        )}
      </Paper>

      <NotesModal
        isCreatingNote={isCreatingNote}
        isLoading={isLoading}
        isOpen={isModalOpen}
        notes={notes}
        onClickAdd={handleClickAddNote}
        onClickCancel={handleClickCancelNote}
        onClickClose={handleClickClose}
        onClickDelete={handleClickDelete}
        onClickSave={handleClickSave}
      />
    </Fragment>
  );
}
