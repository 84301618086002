import DateFnsUtils from "@date-io/date-fns";
import {
  Box,
  Button,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import { Event } from "@material-ui/icons";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import React, { Fragment, useRef, useState } from "react";
import { slashFormatDate } from "utils/dateUtils";

interface DatePickerButtonProps {
  dateValue: Date;
  disabledFuture?: boolean;
  isDisabled?: boolean;
  onSelectDate: (date: Date) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      maxWidth: "152px",
      padding: "12px",
      width: "100%",
      [theme.breakpoints.down("xs")]: {
        maxWidth: "100%",
      },
    },
    icon: {
      color: theme.palette.primary.dark,
      marginRight: "16px",
    },
    text: {
      color: theme.palette.primary.dark,
      fontWeight: 600,
    },
  }),
);

export function DatePickerButton({
  dateValue,
  disabledFuture,
  isDisabled,
  onSelectDate,
}: DatePickerButtonProps) {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState<boolean>(false);
  const classes = useStyles();

  const handleSelectDate = (date: MaterialUiPickersDate) => {
    if (date) {
      onSelectDate(date as Date);
    }
  };

  const handleToggleDatePicker = () =>
    setIsDatePickerOpen((prevState) => !prevState);

  const handleCloseDatePicker = () => setIsDatePickerOpen(false);

  return (
    <Fragment>
      <Button
        className={classes.button}
        disabled={isDisabled}
        ref={buttonRef}
        variant="outlined"
        onClick={handleToggleDatePicker}
      >
        <Event className={classes.icon} fontSize="small" />
        <Typography className={classes.text} variant="body1">
          {slashFormatDate(dateValue)}
        </Typography>
      </Button>
      <Box
        height="1px"
        left={10000}
        position="absolute"
        width="1px"
        zIndex={-1}
      >
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            autoOk
            disableFuture={disabledFuture}
            disableToolbar
            open={isDatePickerOpen}
            PopoverProps={{
              anchorEl: buttonRef.current,
            }}
            value={dateValue}
            variant="inline"
            onChange={handleSelectDate}
            onClose={handleCloseDatePicker}
          />
        </MuiPickersUtilsProvider>
      </Box>
    </Fragment>
  );
}
