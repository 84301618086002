import React from "react";
import { useHistory } from "react-router";
import {
  Box,
  Card,
  createStyles,
  Divider,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import { DailyRosterCrewMemeberModel } from "@dwo/shared/src/models/dailyRosterModel";
import { ROOT_JOB_MANAGEMENT } from "routes/Roots";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: theme.palette.grey[100],
      display: "flex",
      flexDirection: "column",
      height: "100%",
      justifyContent: "center",
      padding: "12px",
    },
    idLabel: {
      color: theme.palette.primary.dark,
      fontWeight: "bold",
      lineHeight: 1.2,
      marginRight: "8px",
    },
    crewNamesList: {
      color: theme.palette.grey[700],
      display: "flex",
      flexDirection: "column",
      fontSize: "2rem",
      fontWeight: 600,
      lineHeight: "49px",
      textAlign: "center",
    },
    viewJob: {
      color: theme.palette.primary["light"],
      overflowWrap: "anywhere",
      paddingRight: "4px",
      cursor: "pointer",
    },
    title: {
      color: theme.palette.grey["500"],
      overflowWrap: "anywhere",
      paddingRight: "4px",
    },
    subTitle: {
      color: theme.palette.primary.dark,
      fontWeight: "bold",
      margin: "0px 8px",
    },
    clockInTrue: {
      height: "12px",
      width: "12px",
      borderRadius: "50px",
      backgroundColor: "green",
      border: "2px",
    },
    clockInFalse: {
      height: "12px",
      width: "12px",
      borderRadius: "50px",
      backgroundColor: "red",
      border: "2px",
    },
  }),
);

interface ForemanCrewCardProps {
  crew?: DailyRosterCrewMemeberModel[];
  foremanName?: string;
  jobId?: string | number;
  jobPk?: string | number;
  supervisorName?: string;
}

/**
 * * jobPk refers to Job Primary Key from the backend.
 * * The frontend uses id for it but since here is being used inside a model related to the Crews it could create a bit of confussion, so the backend naming is used instead.
 */

export function ForemanCrewCard({
  crew,
  foremanName,
  jobId,
  jobPk,
  supervisorName,
}: ForemanCrewCardProps) {
  const classes = useStyles();
  const history = useHistory();

  const handleClickViewJob = () => {
    history.push(`${ROOT_JOB_MANAGEMENT}/${jobPk}`);
  };

  return (
    <Card>
      <Box display="flex" padding="16px 24px" justifyContent="space-between">
        <Box
          alignItems="center"
          className={classes.viewJob}
          display="flex"
          onClick={handleClickViewJob}
        >
          <Typography variant="body2" className={classes.title}>
            JOB ID
          </Typography>
          <Typography variant="body2" className={classes.viewJob}>
            {jobId}
          </Typography>
        </Box>

        <Box display="flex" alignItems="center">
          <Typography variant="body2" className={classes.title}>
            Foreman Name
          </Typography>
          <Typography variant="body2" className={classes.subTitle}>
            {foremanName}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center">
          <Typography variant="body2" className={classes.title}>
            Supervisor Name
          </Typography>
          <Typography variant="body2" className={classes.subTitle}>
            {supervisorName}
          </Typography>
        </Box>
      </Box>

      <Divider />

      <Box
        display="flex"
        alignItems="center"
        padding="30px 24px"
        flexWrap="wrap"
      >
        {crew &&
          crew?.map((crewMember) => (
            <Box
              alignItems="center"
              // className={classes.crewNamesList}
              display="flex"
              key={crewMember.fullName + crewMember.lastName}
              marginRight="32px"
            >
              {crewMember.fullName}
              <Typography variant="body2" className={classes.subTitle}>
                Status
              </Typography>
              {crewMember.clockInStatus === true ? (
                <Box className={classes.clockInTrue} />
              ) : (
                <Box className={classes.clockInFalse} />
              )}
            </Box>
          ))}
      </Box>
    </Card>
  );
}
