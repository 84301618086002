import { JobHazardEmployeeData } from "@dwo/shared/dist/models/DWOModel";
import {
  WorkShiftModel,
  WorkShiftPictureMatchStatus,
} from "@dwo/shared/dist/models/workShiftModel";
import { Box } from "@material-ui/core/";
import { CrewOverviewTableSelect } from "components/timesheetDetails/CrewOverviewTableSelect";
import { CrewOverviewPhaseCodeSelect } from "components/timesheetDetails/CrewOverviewPhaseCodeSelect";
import { CrewOverviewTableSelectTimeOff } from "components/timesheetDetails/CrewOverviewTableSelectTimeOff";
import { CrewOverviewTableTimePicker } from "components/timesheetDetails/CrewOverviewTableTimePicker";
import { CrewOverviewTableTimePickerTimeOff } from "components/timesheetDetails/CrewOverviewTableTimePickerTimeOff";
import { DateTimeDiffLabel } from "components/timesheetDetails/DateTimeDiffLabel";
import { TableDisplayDateLabel } from "components/timesheetDetails/TableDisplayDateLabel";
import { TableDisplayHoursTypeLabel } from "components/timesheetDetails/TableDisplayHoursTypeLabel";
import { TableDisplayEmployeePhoto } from "components/timesheetDetails/TableDisplayEmployeePhoto";
import { TableDisplayTotalLabel } from "components/timesheetDetails/TableDisplayTotalLabel";
import { CrewOverviewTableTimeInput } from "components/timesheetDetails/CrewOverviewTableTimeInput";
import { format, intervalToDuration } from "date-fns";
import { WorkShiftTableCellValue } from "features/editWorkShiftTableSlice/workShiftTableValuesSlice";
import React from "react";
import { MainTableColumn, MainTableRow } from "utils/tableUtils";
import {
  crewOverviewTablePickerFormatter,
  decimalTimeToHoursTime,
} from "utils/dateUtils";
import {
  TimeBreakModel,
  TimeBreakStatus,
  TimeBreakType,
} from "@dwo/shared/dist/models/timeBreakModel";

export const findUpdatedValue = (
  updatedValues: WorkShiftTableCellValue[],
  id: string,
) => updatedValues.find((value) => value.id === id);

export const displayCrewOverviewColumns: MainTableColumn[] = [
  {
    field: "image",
    headerName: "PHOTO",
  },
  {
    field: "name",
    headerName: "NAME",
  },
  {
    field: "employeeId",
    headerName: "ID #",
  },
  {
    field: "role",
    headerName: "ROLE",
  },
  {
    field: "total",
    headerName: "TOTAL",
  },
  {
    field: "timesheet",
    headerName: "TIMESHEET",
  },
];

export const editCrewOverviewColumns = (isEditModeActive: boolean) => [
  {
    field: "image",
    headerName: "PHOTO",
  },
  {
    field: "fullName",
    headerName: "NAME",
    hasSorting: !isEditModeActive,
  },
  {
    field: "id",
    headerName: "ID #",
    hasSorting: !isEditModeActive,
  },
  {
    field: "role",
    headerName: "ROLE",
    hasSorting: !isEditModeActive,
  },
  {
    field: "startDate",
    headerName: "START",
  },
  {
    field: "endDate",
    headerName: "END",
  },
  { field: "LUNCH", headerName: "Lunch" },
  {
    field: "total",
    headerName: "TOTAL",
  },
  {
    field: "phaseCode",
    headerName: "PHASE CODE",
  },
  {
    field: "hoursType",
    headerName: "HOURS TYPE",
  },
];

export const getDisplayCrewOverviewRows = (
  workShifts: WorkShiftModel[],
  jobHazardEmployeeData: JobHazardEmployeeData[],
): MainTableRow[] =>
  workShifts.map((workShift: WorkShiftModel) => {
    if (workShift.employee) {
      const isHazardMissing = !jobHazardEmployeeData.find(
        (ed) => ed.id === workShift.employee.id,
      );
      const isEmployeeHazardSigned = jobHazardEmployeeData.find(
        (ed) => ed.id === workShift.employee.id,
      )?.signed;
      const hasMismatchPhoto =
        workShift.endPictureMatchStatus ===
          WorkShiftPictureMatchStatus.NOT_MATCHED ||
        workShift.startPictureMatchStatus ===
          WorkShiftPictureMatchStatus.NOT_MATCHED;
      const image = {
        value: workShift.employee.pictureUrl || "",
        isImage: true,
        isForeman: workShift.employee.role === "foreman",
        isDriver: workShift.isDriver,
        isHazardMissing: isHazardMissing || isEmployeeHazardSigned === false,
        isPhotoMismatch: hasMismatchPhoto,
      };
      const name = {
        value: `${workShift.employee.firstName} ${workShift.employee.lastName}`,
        isLink: true,
        linkTo: `/user-management/${workShift.employee.id}`,
      };
      const employeeId = { value: workShift.employee.employeeId };
      const roleString = workShift.employee.role;
      const role = {
        value: roleString.charAt(0).toUpperCase() + roleString.slice(1),
      };
      const total = { value: decimalTimeToHoursTime(workShift.hours) };
      const timesheet = {
        value: (
          <Box
            style={{ textTransform: "capitalize" }}
            textAlign={workShift.status ? "" : "center"}
            width="100%"
          >
            {workShift.status ? workShift.status : "-"}
          </Box>
        ),
      };

      const idRow = workShift.employee.id
        ? workShift.employee.id.toString()
        : "";
      const innerCells = [image, name, employeeId, role, total, timesheet];

      return {
        idRow,
        innerCells,
      };
    }
    return {} as MainTableRow;
  });

export const getEditCrewOverviewRows = (
  workShifts: WorkShiftModel[],
  jobHazardEmployeeData: JobHazardEmployeeData[],
  isEditModeActive: boolean,
  isTimeOffChecked: boolean,
  values: WorkShiftTableCellValue[],
  jobId: number
): MainTableRow[] => {
  const allEmployeeIds = workShifts.map(ws => String(ws.employee.id)).filter(item => item !== "");

  return workShifts.map((workShift) => {
    if (workShift.employee) {
      const idRow = workShift.employee.id
        ? workShift.employee.id.toString()
        : "";
      const rowUpdatedValues = values.filter(
        (value) =>
          value.idRow === Number(idRow) &&
          isEditModeActive &&
          value.inputError === false,
      );
      const isForeman = workShift.employee.role === "foreman";
      const isHazardMissing = !jobHazardEmployeeData.find(
        (ed) => ed.id === workShift.employee.id,
      );
      const isEmployeeHazardSigned = jobHazardEmployeeData.find(
        (ed) => ed.id === workShift.employee.id,
      )?.signed;
      const hasMismatchPhoto =
        workShift.endPictureMatchStatus ===
          WorkShiftPictureMatchStatus.NOT_MATCHED ||
        workShift.startPictureMatchStatus ===
          WorkShiftPictureMatchStatus.NOT_MATCHED;
      const image = {
        value: workShift.employee.pictureUrl || "",
        isImage: true,
        isForeman: workShift.employee.role === "foreman",
        isDriver: workShift.isDriver,
        isHazardMissing: isHazardMissing || isEmployeeHazardSigned === false,
        isPhotoMismatch: hasMismatchPhoto,
      };
      const fullName = {
        value: `${workShift.employee.firstName} ${workShift.employee.lastName}`,
        isLink: true,
        linkTo: `/user-management/${workShift.employee.id}`,
      };
      const id = { value: workShift.employee.employeeId };
      const roleString = workShift.employee.role;
      const role = {
        value: roleString.charAt(0).toUpperCase() + roleString.slice(1),
      };
      const getFormattedDate = (date?: string) =>
        date ? format(new Date(date), "hh:mm a") : "-";
      const startDate = {
        value: isTimeOffChecked ? (
          <CrewOverviewTableTimePickerTimeOff type="start" id={idRow} />
        ) : isEditModeActive ? (
          <CrewOverviewTableTimePicker
            type="start"
            id={idRow}
            date={workShift.start}
            isEditModeActive={isEditModeActive}
          />
        ) : (
          <>
            <TableDisplayDateLabel
              date={getFormattedDate(workShift.start)}
              locationType={workShift.startLocationType}
              location={workShift.startLocation}
            />
            <TableDisplayEmployeePhoto photoId={workShift.startPictureId} />
          </>
        ),
      };
      const endDate = {
        value: isTimeOffChecked ? (
          <CrewOverviewTableTimePickerTimeOff type="end" id={idRow} />
        ) : isEditModeActive ? (
          <CrewOverviewTableTimePicker
            type="end"
            id={idRow}
            date={workShift.end}
            isEditModeActive={isEditModeActive}
          />
        ) : (
          <>
            <TableDisplayDateLabel
              date={getFormattedDate(workShift.end)}
              locationType={workShift.endLocationType}
              location={workShift.endLocation}
            />
            <TableDisplayEmployeePhoto photoId={workShift.endPictureId} />
          </>
        ),
      };
      const allTimeBreaks: TimeBreakModel[] =
        Array.isArray(workShift.timeBreaks) && workShift.timeBreaks.length > 0
          ? workShift.timeBreaks
          : [];
      const lunchBreaks = allTimeBreaks.filter(
        (breaks) => breaks.type === TimeBreakType.LUNCH,
      );

      const lunchHours = lunchBreaks.reduce((accumulator, currentValue) => {
        if (currentValue.status === TimeBreakStatus.FINISHED) {
          const duration = intervalToDuration({
            start: new Date(currentValue.end),
            end: new Date(currentValue.start),
          });
          const hrs =
            (duration.hours || 0) +
            (duration.minutes ? duration.minutes / 60 : 0);

          return accumulator + hrs;
        }
        return accumulator + 0;
      }, 0);

      const totalLabel = <TableDisplayTotalLabel hours={workShift.hours} />;

      const lunchTime = {
        value: isTimeOffChecked ? (
          <CrewOverviewTableTimePickerTimeOff type="lunch" id={idRow} />
        ) : isEditModeActive ? (
          <CrewOverviewTableTimeInput
            defaultValue={crewOverviewTablePickerFormatter(lunchHours)}
            id={idRow}
            isEditModeActive={isEditModeActive}
            inputType="lunch"
          />
        ) : (
          <TableDisplayDateLabel date={decimalTimeToHoursTime(lunchHours)} />
        ),
      };

      const getEditTotalLabel = () => {
        const updatedStartValue = findUpdatedValue(rowUpdatedValues, "start");
        const updatedEndValue = findUpdatedValue(rowUpdatedValues, "end");

        if (updatedStartValue && updatedEndValue) {
          return (
            <DateTimeDiffLabel
              start={updatedStartValue.inputValue}
              end={updatedEndValue.inputValue}
            />
          );
        }

        return (
          <Box textAlign="center" width="100%">
            -
          </Box>
        );
      };
      const total = {
        value: isEditModeActive ? getEditTotalLabel() : totalLabel,
      };
      const displayPhaseCode = workShift.phaseCode
        ? workShift.phaseCode
        : "- - -";
      const editPhaseCode = (
        <CrewOverviewPhaseCodeSelect
          setAll={isForeman}
          jobId={jobId}
          idRow={Number(idRow)}
          allIdRows={allEmployeeIds}
          id="phaseCode"
          phaseCode={displayPhaseCode}
          isEditModeActive={isEditModeActive}
        />
      );
      const displayType = workShift.type ? workShift.type : "-";
      const editType = (
        <CrewOverviewTableSelect
          idRow={Number(idRow)}
          id="type"
          type={displayType}
          isEditModeActive={isEditModeActive}
        />
      );
      const phaseCode = {
        value: isEditModeActive ? (
          editPhaseCode
        ) : (
          <span>{displayPhaseCode}</span>
        ),
      };
      const hoursType = {
        value: isTimeOffChecked ? (
          <CrewOverviewTableSelectTimeOff id="type" idRow={Number(idRow)} />
        ) : isEditModeActive ? (
          editType
        ) : (
          <TableDisplayHoursTypeLabel hoursType={workShift.type} />
        ),
      };

      const innerCells = [
        image,
        fullName,
        id,
        role,
        startDate,
        endDate,
        lunchTime,
        total,
        phaseCode,
        hoursType,
      ];

      return {
        idRow,
        innerCells,
      };
    }
    return {} as MainTableRow;
  });
}
