import { ErrorCell } from "components/upload/ErrorCell";
import {
  UploadEmployeeData,
  UploadEmployeeErrors,
  UploadErrorsModel,
} from "@dwo/shared/dist/models/uploadErrorsModel";
import React from "react";
import { MainTableCell, MainTableColumn, MainTableRow } from "./tableUtils";
import { Box, Tooltip } from "@material-ui/core";

export const uploadDetailsColumns: MainTableColumn[] = [
  {
    field: "data.activeStatus",
    hasSorting: true,
    headerName: "Active Status",
  },
  {
    field: "data.employee",
    hasSorting: true,
    headerName: "Employee",
  },
  {
    field: "mobile",
    headerName: "Mobile",
  },
  {
    field: "alternativeEmail",
    headerName: "Alt. Email 1",
  },
  {
    field: "data.accountNo",
    hasSorting: true,
    headerName: "Account No.",
  },
  {
    field: "data.role",
    hasSorting: true,
    headerName: "Role",
  },
  {
    field: "data.companyName",
    hasSorting: true,
    headerName: "Company Name",
  },
  {
    field: "region",
    headerName: "Region",
  },
  {
    field: "supervisor",
    headerName: "Supervisor",
  },
  {
    field: "class",
    headerName: "Class",
  },
  {
    field: "earningRate1",
    headerName: "Earning Rate 1",
  },
  {
    field: "earningRate2",
    headerName: "Earning Rate 2",
  },
  {
    field: "earningRate3",
    headerName: "Earning Rate 3",
  },
  {
    field: "earningRate4",
    headerName: "Earning Rate 4",
  },
  {
    field: "earningRate5",
    headerName: "Earning Rate 5",
  },
  {
    field: "earningRate6",
    headerName: "Earning Rate 6",
  },
  {
    field: "vacationAvailable",
    headerName: "Vacation Available",
  },
];

const getCellData = (
  value: string,
  fieldName: string,
  errorsData: UploadEmployeeErrors[],
) => {
  const cellError = errorsData.find((e) => e.field === fieldName);
  if (cellError && typeof cellError.reason === "string") {
    const message = cellError.reason
      .replace(/&#x5b;/g, "[")
      .replace(/&#x5d;/g, "]");
    return (
      <Tooltip title={message} arrow>
        <Box>
          <ErrorCell text={value} />
        </Box>
      </Tooltip>
    );
  }

  return value;
};

export const getUploadErrorsTableRows = (
  errorHistory: UploadErrorsModel[],
): MainTableRow[] =>
  errorHistory.map((errorEntry: UploadErrorsModel) => {
    const rowData: UploadEmployeeData = errorEntry.data;
    const errorsData = errorEntry.errors;

    const activeStatus = getCellData(
      rowData.activeStatus || 'No "ACTIVE STATUS"',
      "activeStatus",
      errorsData,
    );
    const employee = getCellData(
      rowData.employee || 'No "EMPLOYEE"',
      "employee",
      errorsData,
    );
    const mobile = getCellData(
      rowData.mobile ? rowData.mobile.toString() : 'No "MOBILE"',
      "mobile",
      errorsData,
    );
    const altEmail1 = getCellData(
      rowData.alternativeEmail || 'No "ALT. EMAIL 1"',
      "alternativeEmail",
      errorsData,
    );
    const accountNo = getCellData(
      rowData.accountNo ? rowData.accountNo.toString() : 'No "ACCOUNT NO."',
      "accountNo",
      errorsData,
    );
    const role = getCellData(rowData.role || 'No "ROLE"', "role", errorsData);
    const companyName = getCellData(
      rowData.companyName || 'No "COMPANY NAME"',
      "companyName",
      errorsData,
    );
    const region = getCellData(
      rowData.region || 'No "REGION"',
      "region",
      errorsData,
    );
    const supervisor = getCellData(
      rowData.supervisor || 'No "SUPERVISOR"',
      "supervisor",
      errorsData,
    );
    const employeeClass = getCellData(
      rowData.class || 'No "CLASS"',
      "class",
      errorsData,
    );
    const earningRate1 = getCellData(
      rowData.earningRate1 || 'No "EARNING RATE 1"',
      "earningRate1",
      errorsData,
    );
    const earningRate2 = getCellData(
      rowData.earningRate2 || 'No "EARNING RATE 2"',
      "earningRate2",
      errorsData,
    );
    const earningRate3 = getCellData(
      rowData.earningRate3 || 'No "EARNING RATE 3"',
      "earningRate3",
      errorsData,
    );
    const earningRate4 = getCellData(
      rowData.earningRate4 || 'No "EARNING RATE 4"',
      "earningRate4",
      errorsData,
    );
    const earningRate5 = getCellData(
      rowData.earningRate5 || 'No "EARNING RATE 5"',
      "earningRate5",
      errorsData,
    );
    const earningRate6 = getCellData(
      rowData.earningRate6 || 'No "EARNING RATE 6"',
      "earningRate6",
      errorsData,
    );
    const vacationAvailable = getCellData(
      rowData.vacationAvailable
        ? rowData.vacationAvailable.toString()
        : 'No "VACATIONS AVAILABLE"',
      "vacationAvailable",
      errorsData,
    );

    const innerCells: MainTableCell[] = [
      { value: activeStatus },
      { value: employee },
      { value: mobile },
      { value: altEmail1 },
      { value: accountNo },
      { value: role },
      { value: companyName },
      { value: region },
      { value: supervisor },
      { value: employeeClass },
      { value: earningRate1 },
      { value: earningRate2 },
      { value: earningRate3 },
      { value: earningRate4 },
      { value: earningRate5 },
      { value: earningRate6 },
      { value: vacationAvailable },
    ];

    return {
      innerCells,
    };
  });
