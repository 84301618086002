import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import { JobModel } from "@dwo/shared/dist/models/jobModel";
import React from "react";
import { v4 as uuid } from "uuid";

interface JobsTooltipTitleProps {
  jobs: JobModel[];
  onClickJob: (jobId: number) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tooltipText: {
      color: theme.palette.grey[700],
      cursor: "pointer",
      fontWeight: 600,
    },
  }),
);

export function JobsTooltipTitle({ jobs, onClickJob }: JobsTooltipTitleProps) {
  const classes = useStyles();

  const handleClickJob = (jobId: number) => () => onClickJob(jobId);

  return (
    <Box
      alignItems="center"
      display="flex"
      flexDirection="column"
      justifyContent="center"
    >
      {jobs.map((job: JobModel, index: number) => (
        <Box
          key={`job-${job.id as number}-${uuid()}-tooltip`}
          marginBottom={index < jobs.length ? "4px" : 0}
          onClick={handleClickJob(job.id as number)}
        >
          <Typography className={classes.tooltipText} variant="body2">
            {job.jobId as string}
          </Typography>
        </Box>
      ))}
    </Box>
  );
}
