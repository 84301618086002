import { ServiceOptions } from "@dwo/shared/dist/services/baseService";
import { createStyles, makeStyles } from "@material-ui/core";
import { DEFAULT_LIMIT } from "./sharedUtils";
import { rowSelectOptions } from "./tableUtils";
import { JobType } from "@dwo/shared/dist/models/jobModel";

export enum JobStatusLabels {
  ASSIGNED = "Assigned",
  IN_PROGRESS = "In Progress",
  DONE = "Done",
  COMPLETED = "Completed",
  SUSPENDED = "Suspended",
  UNASSIGNED = "Unassigned",
  CANCELED = "Canceled",
}

export enum JobStatusValues {
  ASSIGNED = "assigned",
  IN_PROGRESS = "inProgress",
  DONE = "done",
  COMPLETED = "completed",
  SUSPENDED = "suspended",
  UNASSIGNED = "unassigned",
  CANCELED = "canceled",
}

export enum JobTabs {
  ALL,
  OVER_COST,
}

export interface JobFilters {
  searchValue: string;
  region: string[];
  status: string[];
  sortingOptions: string[][];
  page: number;
  rowsPerPage: string;
}

export const initialJobFilters: JobFilters = {
  searchValue: "",
  region: [],
  status: [
    JobStatusValues.ASSIGNED,
    JobStatusValues.IN_PROGRESS,
    JobStatusValues.UNASSIGNED,
  ],
  sortingOptions: [],
  page: 1,
  rowsPerPage: rowSelectOptions[0].value,
};

export const initialJobQuery: ServiceOptions = {
  limit: DEFAULT_LIMIT,
  include: [{ Crew: ["foreman"] }, "supervisor"],
  where: {
    status: [
      JobStatusValues.ASSIGNED,
      JobStatusValues.IN_PROGRESS,
      JobStatusValues.UNASSIGNED,
    ],
  },
};

export const clearedJobQuery: ServiceOptions = {
  limit: DEFAULT_LIMIT,
  include: [{ Crew: ["foreman"] }, "supervisor"],
};

export const initialJobOvercostFilters: JobFilters = {
  searchValue: "",
  region: [],
  status: [JobStatusValues.ASSIGNED, JobStatusValues.IN_PROGRESS],
  sortingOptions: [],
  page: 1,
  rowsPerPage: rowSelectOptions[0].value,
};

export const initialJobOvercostQuery: ServiceOptions = {
  limit: DEFAULT_LIMIT,
  include: [{ Crew: ["foreman"] }, "supervisor"],
  where: {
    isOvercost: "true",
    status: [JobStatusValues.ASSIGNED, JobStatusValues.IN_PROGRESS],
  },
  offset: 0,
};

export const clearedJobOvercostQuery: ServiceOptions = {
  limit: DEFAULT_LIMIT,
  include: [{ Crew: ["foreman"] }, "supervisor"],
  where: {
    isOvercost: "true",
  },
  offset: 0,
};

export const DEFAULT_EMPTY = "- - -";
export const DEFAULT_CURRENCY_EMPTY = "$ 0000000";

export const getJobStatusLabel = (status: JobStatusValues) => {
  switch (status) {
    case JobStatusValues.ASSIGNED:
      return JobStatusLabels.ASSIGNED;
    case JobStatusValues.COMPLETED:
      return JobStatusLabels.COMPLETED;
    case JobStatusValues.DONE:
      return JobStatusLabels.DONE;
    case JobStatusValues.IN_PROGRESS:
      return JobStatusLabels.IN_PROGRESS;
    case JobStatusValues.SUSPENDED:
      return JobStatusLabels.SUSPENDED;
    case JobStatusValues.UNASSIGNED:
      return JobStatusLabels.UNASSIGNED;
    case JobStatusValues.CANCELED:
      return JobStatusLabels.CANCELED;
    default:
      return "Invalid Status";
  }
};

export const getJobStatusOptions = () => [
  {
    label: JobStatusLabels.ASSIGNED,
    value: JobStatusValues.ASSIGNED,
  },
  {
    label: JobStatusLabels.IN_PROGRESS,
    value: JobStatusValues.IN_PROGRESS,
  },
  {
    label: JobStatusLabels.DONE,
    value: JobStatusValues.DONE,
  },
  {
    label: JobStatusLabels.COMPLETED,
    value: JobStatusValues.COMPLETED,
  },
  {
    label: JobStatusLabels.SUSPENDED,
    value: JobStatusValues.SUSPENDED,
  },
  {
    label: JobStatusLabels.UNASSIGNED,
    value: JobStatusValues.UNASSIGNED,
  },
];

export const jobHeadersStyles = makeStyles(() =>
  createStyles({
    idHeader: {
      width: "10%",
    },
    workOrderNumber: {
      width: "11.5%",
    },
    addressHeader: {
      width: "15%",
    },
    typeHeader: {
      width: "10%",
    },
    supervisorheader: {
      width: "14%",
    },
    foremanHeader: {
      width: "14%",
    },
    startDateHeader: {
      width: "10%",
    },
    endDateHeader: {
      width: "9.5%",
    },
    hoursHeader: {
      width: "6%",
    },
  }),
);

export const getJobType = (type: string) =>
  type === JobType.Hourly
    ? "Per Hr"
    : type === JobType.PerFoot
    ? "Per Foot"
    : type === JobType.Bid
    ? JobType.Bid
    : JobType.Unit;

export const getFormattedHrs = (hrs: number | undefined | null) => {
  if (!hrs) {
    return 0;
  }

  return Math.ceil(hrs);
};
