import { DownloadTimesheetsModel } from "@dwo/shared/dist/models/downloadTimesheetsModel";
import { ServiceOptions } from "@dwo/shared/dist/services/baseService";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, RootState } from "app/store";
import { cloneDeep } from "lodash";
import { DEFAULT_LIMIT } from "utils/sharedUtils";
import { downloadTimesheetsService } from "@dwo/shared/dist/services/downloadTimehseetsService";
import { downloadWorkshiftCSVService } from "@dwo/shared/dist/services/downloadWorkshiftCSVService";
import { error } from "features/error/errorSlice";

interface DownloadTimeSheetsState {
  count: number;
  isLoading: boolean;
  downloadTimesheets: DownloadTimesheetsModel[];
  limit?: number;
  offset?: number;
  order?: any;
}

const initialState: DownloadTimeSheetsState = {
  count: 0,
  isLoading: false,
  downloadTimesheets: [],
  limit: DEFAULT_LIMIT,
  offset: 0,
  order: undefined,
};

export const downloadTimesheetsSlice = createSlice({
  name: "downloadTimesheets",
  initialState,
  reducers: {
    setCount: (state, action: PayloadAction<number>) => {
      state.count = action.payload;
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setDownloadTimesheets: (
      state,
      action: PayloadAction<DownloadTimesheetsModel[]>,
    ) => {
      state.downloadTimesheets = cloneDeep(action.payload);
    },
    setLimit: (state, action: PayloadAction<number>) => {
      state.limit = action.payload;
    },
    setOffset: (state, action: PayloadAction<number>) => {
      state.offset = action.payload;
    },
    setOrder: (state, action: PayloadAction<any>) => {
      state.order = action.payload;
    },
  },
});

export const {
  setCount,
  setIsLoading,
  setDownloadTimesheets,
  setLimit,
  setOffset,
  setOrder,
} = downloadTimesheetsSlice.actions;

export const getAllDownladTimesheets = (
  options?: ServiceOptions,
): AppThunk => async (dispatch) => {
  try {
    dispatch(setIsLoading(true));

    const {
      count,
      data,
      limit,
      offset,
      order,
    } = await downloadTimesheetsService.getAll(options);

    dispatch(setCount(count));
    dispatch(setDownloadTimesheets(data));
    dispatch(setLimit(limit));
    dispatch(setOffset(offset));
    dispatch(setOrder(order));
  } catch (e) {
    dispatch(
      error(
        {
          title: "Could not retrieve IIFs",
          message: e.message,
        },
        () => dispatch(getAllDownladTimesheets(options)),
      ),
    );
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const getAllDownladTimesheetsCSV = (
  options?: ServiceOptions,
): AppThunk => async (dispatch) => {
  try {
    dispatch(setIsLoading(true));

    const {
      count,
      data,
      limit,
      offset,
      order,
    } = await downloadWorkshiftCSVService.getAll(options);

    dispatch(setCount(count));
    dispatch(setDownloadTimesheets(data));
    dispatch(setLimit(limit));
    dispatch(setOffset(offset));
    dispatch(setOrder(order));
  } catch (e) {
    dispatch(
      error(
        {
          title: "Could not retrieve CSVs",
          message: e.message,
        },
        () => dispatch(getAllDownladTimesheetsCSV(options)),
      ),
    );
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const selectCount = (state: RootState) => state.downloadTimesheets.count;
export const selectIsLoading = (state: RootState) =>
  state.downloadTimesheets.isLoading;
export const selectDownloadTimesheets = (state: RootState) =>
  state.downloadTimesheets.downloadTimesheets;
export const selectLimit = (state: RootState) => state.downloadTimesheets.limit;
export const selectOffset = (state: RootState) =>
  state.downloadTimesheets.offset;
export const selectOrder = (state: RootState) => state.downloadTimesheets.order;

export const downloadTimesheetsReducer = downloadTimesheetsSlice.reducer;
