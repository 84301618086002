import { SvgIcon } from "@material-ui/core";
import React from "react";

interface RulerCombinedIconProps {
  color?: string;
  fontSize?: number;
}

export const RulerCombinedIcon = ({
  color = "black",
  fontSize,
}: RulerCombinedIconProps) => {
  return (
    <SvgIcon
      htmlColor={color}
      style={{ fontSize: fontSize }}
      viewBox="0 0 512 512"
    >
      <path d="M160 288h-56c-4.42 0-8-3.58-8-8v-16c0-4.42 3.58-8 8-8h56v-64h-56c-4.42 0-8-3.58-8-8v-16c0-4.42 3.58-8 8-8h56V96h-56c-4.42 0-8-3.58-8-8V72c0-4.42 3.58-8 8-8h56V32c0-17.67-14.33-32-32-32H32C14.33 0 0 14.33 0 32v448c0 2.77.91 5.24 1.57 7.8L160 329.38V288zm320 64h-32v56c0 4.42-3.58 8-8 8h-16c-4.42 0-8-3.58-8-8v-56h-64v56c0 4.42-3.58 8-8 8h-16c-4.42 0-8-3.58-8-8v-56h-64v56c0 4.42-3.58 8-8 8h-16c-4.42 0-8-3.58-8-8v-56h-41.37L24.2 510.43c2.56.66 5.04 1.57 7.8 1.57h448c17.67 0 32-14.33 32-32v-96c0-17.67-14.33-32-32-32z" />
    </SvgIcon>
  );
};
