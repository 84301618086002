import { JobNoteModel } from "@dwo/shared/dist/models/jobNoteModel";
import { Box, createStyles, makeStyles, Typography } from "@material-ui/core";
import { ClickableText } from "components/ClickableText";
import { LoadingSpinner } from "components/LoadingSpinner";
import React, { Fragment } from "react";

interface StyleProps {
  customWidth: string;
}

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      width: "100%",
    },
    note: {
      maxWidth: "240px",
      width: ({ customWidth }: StyleProps) => customWidth,
    },
  }),
);

interface TableCommentProps {
  currentJobId?: number;
  displayedComment?: JobNoteModel;
  isLoading?: boolean;
  maxChars?: number;
  onClickLink: (jobId: number, isCreatingNote?: boolean) => void;
}

export function TableComments({
  currentJobId,
  displayedComment,
  isLoading,
  maxChars = 96,
  onClickLink,
}: TableCommentProps) {
  const containerWidth = displayedComment ? "max-content" : "100%";
  const classes = useStyles({ customWidth: containerWidth });

  const handleClickLink = (
    jobId?: number,
    isCreatingComment?: boolean,
  ) => () => {
    if (!jobId) return;

    onClickLink(jobId, isCreatingComment);
  };

  return (
    <Box className={classes.container}>
      {isLoading && (
        <LoadingSpinner
          BoxProps={{ padding: 0 }}
          CircularProgressProps={{ size: "24px" }}
        />
      )}
      {!isLoading && !displayedComment && (
        <ClickableText
          linkText="+ Add new comment"
          onClickLink={handleClickLink(currentJobId, true)}
        />
      )}
      {!isLoading && displayedComment && (
        <Fragment>
          <Typography className={classes.note} paragraph variant="body2">
            {displayedComment.note.length > maxChars
              ? displayedComment.note.slice(0, maxChars) + "..."
              : displayedComment.note}
          </Typography>
          <ClickableText
            linkText="View Comments"
            onClickLink={handleClickLink(currentJobId)}
          />
        </Fragment>
      )}
    </Box>
  );
}
