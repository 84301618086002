import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, RootState } from "app/store";
import { error } from "features/error/errorSlice";
import { VehicleModel } from "@dwo/shared/dist/models/vehicleModel";
import {
  ListResponseData,
  ServiceOptions,
} from "@dwo/shared/dist/services/baseService";
import { vehicleService } from "@dwo/shared/dist/services/vehicleService";

interface VehicleState {
  currentVehicle?: VehicleModel;
  foremanId?: number;
  isLoading: boolean;
  vehicles: VehicleModel[];
}

const initialState: VehicleState = {
  currentVehicle: undefined,
  foremanId: undefined,
  isLoading: false,
  vehicles: [],
};

export const vehicleSlice = createSlice({
  name: "vehicles",
  initialState,
  reducers: {
    setCurrentVehicle: (
      state,
      action: PayloadAction<VehicleModel | undefined>,
    ) => {
      state.currentVehicle = action.payload;
    },
    setForemanId: (state, action: PayloadAction<number | undefined>) => {
      state.foremanId = action.payload;
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setVehicles: (state, action: PayloadAction<VehicleModel[]>) => {
      state.vehicles = action.payload;
    },
  },
});

export const {
  setCurrentVehicle,
  setForemanId,
  setIsLoading,
  setVehicles,
} = vehicleSlice.actions;

export const getCurrentVehicle = (
  foremanId: number,
  jobId: number,
  date: Date,
): AppThunk => async (dispatch) => {
  try {
    dispatch(setIsLoading(true));

    const { data } = await vehicleService.getCurrentVehicle(
      foremanId,
      jobId,
      date,
    );

    if (data.id) {
      dispatch(setCurrentVehicle(data));
      return;
    }

    dispatch(setCurrentVehicle(undefined));
  } catch (err) {
    dispatch(
      error(
        {
          title: "Could not fetch Vehicle data. Please try again.",
          message: err.message,
        },
        () => dispatch(getCurrentVehicle(foremanId, jobId, date)),
      ),
    );
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const getVehicles = (
  foremenIds: number[],
  jobId: number,
  options?: ServiceOptions,
): AppThunk => async (dispatch) => {
  try {
    dispatch(setIsLoading(true));

    if (foremenIds.length === 0) {
      dispatch(setVehicles([]));
      return;
    }

    const vehiclesListResponse = await Promise.all(
      foremenIds.map(
        async (foremanId: number) =>
          await vehicleService.getVehicles(foremanId, jobId, options),
      ),
    );

    const vehiclesList = vehiclesListResponse.reduce(
      (
        vehicles: VehicleModel[],
        vehicleResponse: ListResponseData<VehicleModel>,
      ) => {
        if (
          Array.isArray(vehicleResponse.data) &&
          vehicleResponse.data.length > 0
        ) {
          return [...vehicles, ...vehicleResponse.data];
        }

        return vehicles;
      },
      [],
    );

    dispatch(setVehicles(vehiclesList));
  } catch (err) {
    dispatch(
      error(
        {
          title: "Could not fetch Vehicles. Please try again.",
          message: err.message,
        },
        () => dispatch(getVehicles(foremenIds, jobId, options)),
      ),
    );
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const selectCurrentVehivle = (state: RootState) =>
  state.vehicles.currentVehicle;
export const selectForemanId = (state: RootState) => state.vehicles.foremanId;
export const selectIsLoading = (state: RootState) => state.vehicles.isLoading;
export const selectVehicles = (state: RootState) => state.vehicles.vehicles;

export default vehicleSlice.reducer;
