import React from "react";
import { Redirect, RouteComponentProps, Switch } from "react-router-dom";
import { TimesheetDetails } from "screens/TimesheetDetailsScreen";
import { ProtectedRoute } from "./ProtectedRoute";
import { TimesheetManagementScreen } from "screens/TimesheetManagementScreen";
import { EmployeeTimesheetScreen } from "screens/EmployeeTimesheetScreen";

export function TimesheetsRouter({ match: { url } }: RouteComponentProps) {
  return (
    <Switch>
      <ProtectedRoute exact path={`${url}/`}>
        <TimesheetManagementScreen />
      </ProtectedRoute>
      <ProtectedRoute exact path={`${url}/:id`}>
        <TimesheetDetails />
      </ProtectedRoute>
      <ProtectedRoute exact path={`${url}/employee/:id`}>
        <EmployeeTimesheetScreen />
      </ProtectedRoute>
      <ProtectedRoute path={`${url}/`}>
        <Redirect to={`${url}/`} />
      </ProtectedRoute>
    </Switch>
  );
}
