import {
  Box,
  createStyles,
  List,
  ListItem,
  Button,
  Grid,
  Theme,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import * as routes from "routes/Roots";
import { useDispatch, useSelector } from "react-redux";
import {
  getNewestNotifications,
  selectNewestNotifications,
  setAlertJobId,
  setComesFromAlerts,
} from "features/notifications/notificationSlice";
import { NotificationModel } from "@dwo/shared/dist/models/notificationModel";
import { alertOptions as options } from "utils/notificationUtils";

interface NotificationsProps {
  isOpen: boolean;
  setIsNotificationsMenuOpen: (isOpen: boolean) => void;
}

const height = window.innerHeight;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      color: "#2660BD",
      padding: "15px 0 0 20px",
    },
    today: {
      color: "#525B79",
      padding: "20px 0 0 20px",
      fontWeight: "bold",
    },
    listContainer: {
      height: "70vh",
    },
    list: {
      height: "100%",
    },
    itemList: {
      padding: 0,
      height: "12%",
      backgroundColor: "white",
      marginBottom: "5px",
      overflow: "hidden",
      "&:hover": {
        cursor: "pointer",
      },
    },
    gridContainer: {
      width: "100%",
      height: "100%",
    },
    gridItemIcon: {
      width: "15%",
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    gridItemContent: {
      width: "85%",
      height: "100%",
    },
    contentTop: {
      height: "50%",
      display: "flex",
      flexDirection: "row",
    },
    contentTitle: {
      width: "60%",
      height: "100%",
      display: "flex",
      alignItems: "center",
      paddingLeft: "5px",
    },
    titleText: {
      fontSize: height < 800 ? "11px" : "13px",
      color: "#C5C5C9",
      height: "100%",
      display: "flex",
      fontWeight: "bold",
      alignItems: "center",
    },
    contentLink: {
      height: "100%",
      width: "40%",
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
    },
    linkText: {
      color: "#2D72E0",
      fontSize: height < 800 ? "12px" : "14px",
    },
    fakeLinkText: {
      color: "#2D72E0",
      fontSize: height < 800 ? "12px" : "14px",
      textDecoration: "underline",
      cursor: "pointer",
    },
    contentBottom: {
      height: "50%",
      display: "flex",
      alignItems: "center",
    },
    bottomText: {
      fontSize: height < 800 ? "9px" : "10px",
      color: "#929294",
      padding: "0 5px 0 5px",
      textAlign: "justify",
    },
    viewAll: {
      display: "flex",
      justifyContent: "center",
      marginTop: "10%",
    },
    button: {
      height: "48px",
      background: "transparent",
      width: "80%",
      padding: 0,
      display: "flex",
      alignItems: "center",
    },
    linkButton: {
      textDecoration: "none",
      color: theme.palette.primary.main,
      width: "100%",
    },
    container: {
      backgroundColor: "#F7F7F7",
      bottom: "0",
      boxShadow: "1px 1px 8px #0000001A",
      right: "0",
      position: "fixed",
      top: "0",
      zIndex: 10,
      [theme.breakpoints.down("sm")]: {
        boxShadow: "none",
      },
      height: "100%",
      animation: "$slideIn 1s",
      "animation-timing-function": "ease",
    },
    ".slide-out": {
      animation: "$slideOut 1s",
      "animation-timing-function": "ease",
    },
    "@keyframes slideIn": {
      from: { right: "-232px" },
      to: { right: "0px" },
    },
    "@keyframes slideOut": {
      from: { right: "0" },
      to: { right: "-232px" },
    },
  }),
);

export function NotificationsMenu({
  isOpen,
  setIsNotificationsMenuOpen,
}: NotificationsProps) {
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();

  const node = useRef<HTMLDivElement>(document.createElement("div"));
  const notifications = useSelector(selectNewestNotifications);

  useEffect(() => {
    if (isOpen) {
      dispatch(getNewestNotifications());
    }
  }, [dispatch, isOpen]);

  useEffect(() => {
    const handleClickOutside = (e: any) => {
      if (!node.current.contains(e.target)) {
        setIsNotificationsMenuOpen(false);
      }
      return;
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setIsNotificationsMenuOpen]);

  const handleClickFakeLink = (alert: NotificationModel) => () => {
    try {
      // TODO: This maybe dwoJobId when back changes it
      dispatch(setAlertJobId(alert.jobId));
      dispatch(setComesFromAlerts(true));
      history.push(`${routes.ROOT_TIMESHEETS_MANAGEMENT}/${alert.dwoId}`);
    } catch (error) {
      // silent error
    }
  };

  return (
    <div ref={node}>
      {isOpen && (
        <Box
          id="notificationsContainer"
          className={classes.container}
          width="232px"
        >
          <Grid
            container
            direction="column"
            justify="flex-start"
            alignItems="stretch"
          >
            <Grid item>
              <Typography className={classes.title} component="h6" variant="h6">
                Notifications
              </Typography>
            </Grid>
            <Grid item>
              <Typography className={classes.today}>Today</Typography>
            </Grid>
            <Grid item className={classes.list}>
              <List className={classes.listContainer}>
                {notifications &&
                  notifications.length > 0 &&
                  notifications.map((alert: NotificationModel, index) => (
                    <ListItem className={classes.itemList} key={index}>
                      <Grid container className={classes.gridContainer}>
                        <Grid
                          item
                          className={classes.gridItemIcon}
                          style={{
                            backgroundColor: options[alert.type].color,
                          }}
                        >
                          {options[alert.type].icon}
                        </Grid>
                        <Grid item className={classes.gridItemContent}>
                          <Grid item className={classes.contentTop}>
                            <Grid item className={classes.contentTitle}>
                              <Typography
                                className={classes.titleText}
                                component="h6"
                                variant="h6"
                              >
                                {options[alert.type].title}
                              </Typography>
                            </Grid>
                            <Grid item className={classes.contentLink}>
                              {alert.type === "pictureMismatch" ||
                              alert.type === "locationMismatch" ? (
                                <Typography
                                  className={classes.fakeLinkText}
                                  onClick={handleClickFakeLink(alert)}
                                >
                                  View More
                                </Typography>
                              ) : (
                                <Link
                                  className={classes.linkText}
                                  to={options[alert.type].redirect(alert.jobId)}
                                >
                                  View More
                                </Link>
                              )}
                            </Grid>
                          </Grid>
                          <Grid item className={classes.contentBottom}>
                            <Typography className={classes.bottomText}>
                              {alert.id &&
                                options[alert.type].message(alert.data)}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    </ListItem>
                  ))}
              </List>
            </Grid>
            <Grid item className={classes.viewAll}>
              <Button className={classes.button} fullWidth variant="outlined">
                <Link
                  className={classes.linkButton}
                  to={routes.ROOT_NOTIFICATIONS}
                >
                  View All
                </Link>
              </Button>
            </Grid>
          </Grid>
        </Box>
      )}
    </div>
  );
}
