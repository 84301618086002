import {
  Box,
  ButtonBase,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import { HourglassFull } from "@material-ui/icons";
import { RulerCombinedIcon } from "components/icons/RulerCombinedIcon";
import React from "react";
import { decimalTimeToHoursTime } from "utils/dateUtils";

interface ToggleJobTotalHoursProps {
  hours?: number;
  isActive: boolean;
  jobId?: string;
  jobType?: string;
  onToggle: VoidFunction;
}

interface StyleProps {
  isActive: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    hours: {
      color: ({ isActive }: StyleProps) =>
        isActive ? "white" : theme.palette.primary.light,
      fontWeight: 700,
    },
    jobId: {
      color: ({ isActive }: StyleProps) =>
        isActive ? "white" : theme.palette.grey[600],
      fontWeight: 700,
      marginBottom: "3px",
    },
    totalHours: {
      color: ({ isActive }: StyleProps) =>
        isActive ? "white" : theme.palette.grey[600],
      fontWeight: 500,
    },
    greyContainer: {
      backgroundColor: ({ isActive }: StyleProps) =>
        isActive ? "none" : "#0000001A",
      backgroundImage: ({ isActive }: StyleProps) =>
        isActive
          ? "linear-gradient(180deg, rgba(0,40,104,1) 89%, rgba(213,9,24,1) 90%)"
          : "none",
      padding: "16px",
      borderRadius: "2px",
      display: "flex",
      width: "100%",
      flexDirection: "column",
      textAlign: "center",
    },
    button: {
      display: "flex",
      width: "100%",
    },
    iconBackground: {
      display: "flex",
      height: "27px",
      width: "27px",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "100px",
      backgroundColor: "white",
      boxShadow: "0px 3px 6px #00000029",
    },
    iconSize: {
      fontSize: "1.25rem",
    },
  }),
);

export function ToggleJobTotalHours({
  hours,
  isActive,
  jobId,
  jobType,
  onToggle,
}: ToggleJobTotalHoursProps) {
  const classes = useStyles({ isActive });
  return (
    <Box position="relative" width="100%">
      <ButtonBase className={classes.button} onClick={onToggle}>
        <Box className={classes.greyContainer}>
          <Typography className={classes.totalHours} variant="body2">
            Total Hours
          </Typography>
          <Typography className={classes.jobId} variant="body2">
            Job {jobId}
          </Typography>
          <Typography className={classes.hours} component="h1" variant="h4">
            {decimalTimeToHoursTime(hours as number)}
          </Typography>
        </Box>
      </ButtonBase>
      <Box position="absolute" bottom="-7px" left="-10px">
        <Box className={classes.iconBackground}>
          {jobType === "hourly" ? (
            <HourglassFull className={classes.iconSize} />
          ) : (
            <RulerCombinedIcon fontSize={17} />
          )}
        </Box>
      </Box>
    </Box>
  );
}
