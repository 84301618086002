import {
  Box,
  Button,
  createStyles,
  Divider,
  Grid,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import { EditIconButton } from "components/icons/EditIconButton";
import { LoadingSpinner } from "components/LoadingSpinner";
import {
  getJobInvoices,
  selectInvoices,
  selectIsLoadingInvoices,
} from "features/jobs/jobsSlice";
import { JobInvoiceModel } from "@dwo/shared/dist/models/jobInvoiceModel";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getTotalInvoiceSent } from "utils/jobDetailsUtils";
import { InvoiceRow } from "./InvoiceRow";
import { AddInvoicesModal } from "./AddInvoicesModal";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      maxWidth: "150px",
      padding: "10px",
      width: "100%",
      [theme.breakpoints.down("xs")]: {
        marginTop: "24px",
        maxWidth: "100%",
      },
    },
    header: {
      color: theme.palette.primary.main,
      marginRight: "16px",
    },
    missing: {
      color: theme.palette.grey[600],
    },
    title: {
      color: theme.palette.primary.dark,
      fontWeight: "bold",
    },
    titleTotal: {
      color: theme.palette.primary.main,
      fontWeight: "bold",
    },
  }),
);

export function BillingInvoiceSection() {
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const jobId = parseInt(id, 10);
  const invoices = useSelector(selectInvoices);
  const isLoading = useSelector(selectIsLoadingInvoices);
  const classes = useStyles();
  const [isAddInvoiceModalOpen, setIsAddInvoiceModalOpen] = useState(false);
  const [isEditInvoiceModalOpen, setIsEditInvoiceModalOpen] = useState(false);

  const totalInvoice =
    invoices.length > 0 ? getTotalInvoiceSent(invoices) : 0.0;

  useEffect(() => {
    dispatch(getJobInvoices({ where: { jobId } }));
  }, [jobId, dispatch]);

  const handleClickAddInvoice = () => setIsAddInvoiceModalOpen(true);
  const handleCloseAddInvoiceModal = () => setIsAddInvoiceModalOpen(false);
  const handleClickEditInvoice = () => {
    if (invoices.length > 0) {
      setIsEditInvoiceModalOpen(true);
    }
  };
  const handleCloseEditInvoiceModal = () => setIsEditInvoiceModalOpen(false);

  return (
    <Fragment>
      <Grid item xs={12}>
        <Box
          alignItems="center"
          display="flex"
          flexWrap="wrap"
          justifyContent="space-between"
        >
          <Box justifyContent="flex-start" display="flex">
            <Typography variant="h5" className={classes.header}>
              Billing Invoices
            </Typography>
            <EditIconButton
              isDisabled={isLoading}
              onClickEdit={handleClickEditInvoice}
            />
          </Box>

          <Button
            className={classes.button}
            disabled={isLoading}
            variant="outlined"
            onClick={handleClickAddInvoice}
          >
            Add Invoice Data
          </Button>
        </Box>
      </Grid>

      {isLoading && (
        <Grid item xs={12}>
          <LoadingSpinner />
        </Grid>
      )}

      {!isLoading && invoices.length === 0 && (
        <Grid item xs={12}>
          <Box
            alignItems="center"
            display="flex"
            justifyContent="center"
            padding="24px 16px"
          >
            <Typography align="center" className={classes.missing} variant="h6">
              There are no invoices to display. Please try adding a new one.
            </Typography>
          </Box>
        </Grid>
      )}

      {!isLoading && invoices.length > 0 && (
        <Fragment>
          <Grid item xs={12}>
            <Box display="flex">
              <Box flex={2} />

              <Box
                alignItems="center"
                display="flex"
                flex={1}
                justifyContent="flex-end"
                marginRight="8px"
              >
                <Typography className={classes.title} variant="body2">
                  Date
                </Typography>
              </Box>

              <Box
                alignItems="center"
                display="flex"
                flex={1}
                justifyContent="flex-end"
                marginRight="8px"
              >
                <Typography className={classes.title} variant="body2">
                  % Billed
                </Typography>
              </Box>

              <Box
                alignItems="center"
                display="flex"
                flex={1}
                justifyContent="flex-end"
              >
                <Typography className={classes.title} variant="body2">
                  Invoice Sent
                </Typography>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Box margin="-12px 0 -32px 0">
              {invoices.map((invoice: JobInvoiceModel) => (
                <InvoiceRow
                  billed={invoice.percentageBilled}
                  date={new Date(invoice.date)}
                  key={invoice.id}
                  number={invoice.invoiceNumber}
                  sent={invoice.amount}
                />
              ))}
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={12}>
            <Box
              alignItems="center"
              display="flex"
              justifyContent="space-between"
              marginTop="-12px"
            >
              <Typography className={classes.title} variant="body2">
                Total Invoice Sent
              </Typography>
              <Typography className={classes.titleTotal} variant="body2">
                {`$${totalInvoice}`}
              </Typography>
            </Box>
          </Grid>
        </Fragment>
      )}
      <AddInvoicesModal
        isOpen={isAddInvoiceModalOpen}
        onClickClose={handleCloseAddInvoiceModal}
      />
      <AddInvoicesModal
        isOpen={isEditInvoiceModalOpen}
        isEdit={true}
        initialInvoices={invoices}
        onClickClose={handleCloseEditInvoiceModal}
      />
    </Fragment>
  );
}
