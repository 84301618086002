export type InvoiceFormValues = {
  amount: string;
  date: Date;
  invoiceNumber: string;
  percentageBilled: string;
};

export type InvoiceValues = {
  values: InvoiceFormValues;
  errors?: boolean;
  id: string;
};

export const getUpdatedInvoices = (
  newValue: InvoiceValues,
  invoiceValuesToEdit: InvoiceValues[],
) => {
  const valueIndex = invoiceValuesToEdit.findIndex(
    (currentValue) => currentValue.id === newValue.id,
  );
  const updatedInvoicesValues = [...invoiceValuesToEdit];
  if (valueIndex >= 0) {
    updatedInvoicesValues[valueIndex] = newValue;
    return updatedInvoicesValues;
  }

  return [...updatedInvoicesValues, newValue];
};

export const getHasInvoicesError = (invoiceValues: InvoiceValues[]) => {
  return (
    invoiceValues.length === 0 ||
    invoiceValues.some((value) => value.errors === undefined || value.errors)
  );
};
