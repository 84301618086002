import { UploadStatus } from "components/table/UploadStatus";
import { DownloadFileCell } from "components/upload/DownloadFileCell";
import { UploadHistoryModel } from "@dwo/shared/dist/models/uploadHistoryModel";
import React from "react";
import { MainTableCell, MainTableColumn, MainTableRow } from "./tableUtils";
import { slashFormatDateWithHours } from "./dateUtils";
import { TextLink } from "components/TextLink";

export const uploadHistoryTableColumns: MainTableColumn[] = [
  {
    field: "updatedAt",
    hasSorting: true,
    headerName: "Uploaded Time",
  },
  {
    field: "fileName",
    hasSorting: true,
    headerName: "Source File Name",
  },
  {
    field: "uploadStatus",
    headerName: "Upload Status",
  },
  {
    field: "uploadedBy",
    hasSorting: true,
    headerName: "Uploaded By",
  },
  {
    field: "reason",
    headerName: "Reason",
  },
];

export const initialUploadHistoryQuery = (() => {
  const date = new Date();
  const startDate = new Date(date.getFullYear(), date.getMonth(), 1);
  const endDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);

  endDate.setHours(23, 59, 59, 999);

  return {
    where: { updatedAt: { $gte: startDate, $lte: endDate } },
  };
})();

export const getUploadHistoryRows = (
  uploadHistory: UploadHistoryModel[],
): MainTableRow[] => {
  return uploadHistory.map((uploadEntry: UploadHistoryModel) => {
    const date = uploadEntry.updatedAt
      ? slashFormatDateWithHours(new Date(uploadEntry.updatedAt))
      : "No Upload Date";
    const source = (
      <DownloadFileCell fileName={uploadEntry.fileName} url={uploadEntry.url} />
    );
    const status = <UploadStatus status={uploadEntry.uploadStatus} />;
    const uploadedby = uploadEntry.uploadedBy;
    const reason = (
      <TextLink text="View More" url={`/upload/${uploadEntry.id as number}`} />
    );

    const innerCells: MainTableCell[] = [
      { value: date },
      { value: source },
      { value: status },
      { value: uploadedby },
      { value: reason },
    ];

    return {
      innerCells,
    };
  });
};
