import {
  Box,
  Button,
  createStyles,
  IconButton,
  makeStyles,
  Paper,
  Theme,
  Typography,
} from "@material-ui/core";
import { AddCircle } from "@material-ui/icons";
import { cloneDeep } from "lodash";
import { LoadingSpinner } from "components/LoadingSpinner";
import { NotesModal } from "components/jobDetails/notesModal/NotesModal";
import {
  createJobNote,
  deleteJobNote,
  getAllJobNotes,
  selectIsLoading,
  selectIsLoadingEditing,
  selectJobNotes,
  setJobNotes,
  updateJobNote,
} from "features/jobs/jobNotes/jobNotesSlice";
import { prompt, selectResponse } from "features/prompt/promptSlice";
import React, { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { JobNoteModel } from "@dwo/shared/dist/models/jobNoteModel";
import { slashFormatDate } from "utils/dateUtils";
import { ServiceOptions } from "@dwo/shared/dist/services/baseService";
import { JobNote } from "components/jobDetails/JobNote";
import {
  selectCurrentJob,
  selectIsLoadingDWOJobs,
  selectJobsOfDWOs,
} from "features/dwo/dwoSlice";
import { JobModel } from "@dwo/shared/dist/models/jobModel";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    addIcon: {
      color: theme.palette.primary.main,
      height: "32px",
      padding: 0,
      width: "32px",
    },
    missingNotes: {
      color: theme.palette.grey[600],
      marginTop: "24px",
    },
    paper: {
      backgroundColor: "white",
      flex: 1,
      margin: "0 24px 24px 0",
      padding: "24px",
      [theme.breakpoints.down("xs")]: {
        marginLeft: "24px",
      },
    },
    title: {
      color: theme.palette.primary.main,
    },
    viewMoreButton: {
      color: theme.palette.primary.main,
      fontSize: "0.875rem",
      fontWeight: "normal",
      height: "auto",
      marginTop: "16px",
      padding: "0 8px",
      width: "fit-content",
      "& .MuiButton-label": {
        textDecoration: "underline",
      },
    },
  }),
);

const MAX_NOTES = 2;

export function CrewCommentsCard() {
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();
  const dwoId = parseInt(id, 10);
  const crewComments = useSelector(selectJobNotes);
  const currentJob = useSelector(selectCurrentJob);
  const dwoJobs = useSelector(selectJobsOfDWOs);
  const isLoadingComments = useSelector(selectIsLoading);
  const isLoadingDWOJobs = useSelector(selectIsLoadingDWOJobs);
  const isLoadingEditing = useSelector(selectIsLoadingEditing);
  const promptResponse = useSelector(selectResponse);
  const [deleteCommentId, setDeleteCommentId] = useState<string>("");
  const [fetchCommentsQuery, setFetchQuery] = useState<ServiceOptions>();
  const [isCreatingComment, setIsCreatingComment] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const classes = useStyles();

  const isLoading = isLoadingComments || isLoadingDWOJobs || isLoadingEditing;

  useEffect(() => {
    if (fetchCommentsQuery && !isLoadingDWOJobs) {
      dispatch(getAllJobNotes(fetchCommentsQuery));
    }

    return () => {
      dispatch(setJobNotes([]));
    };
  }, [fetchCommentsQuery, isLoadingDWOJobs, dispatch]);

  useEffect(() => {
    const query: ServiceOptions = {
      include: [{ createdBy: ["employee"] }, "job"],
      order: [["updatedAt", "DESC"]],
    };

    if (currentJob) {
      query.where = {
        jobId: currentJob.id as number,
      };

      setFetchQuery(query);
      return;
    }

    if (dwoJobs.length > 0) {
      const jobsIds = dwoJobs.map((job: JobModel) => job.id as number);
      query.where = {
        $or: {
          dwoId,
          jobId: jobsIds,
        },
      };

      setFetchQuery(query);
      return;
    }

    query.where = {
      dwoId,
    };

    setFetchQuery(query);
  }, [currentJob, dwoJobs, dwoId]);

  useEffect(() => {
    if (deleteCommentId !== "" && promptResponse) {
      dispatch(deleteJobNote(deleteCommentId as string, fetchCommentsQuery));
      setDeleteCommentId("");
    }
  }, [deleteCommentId, promptResponse, fetchCommentsQuery, dispatch]);

  const handleClickAddButton = () => {
    setIsCreatingComment(true);
    setIsModalOpen(true);
  };

  const handleClickAddNote = () => setIsCreatingComment(true);

  const handleClickCancelNote = () => setIsCreatingComment(false);

  const handleClickClose = () => {
    setIsModalOpen(false);
    setIsCreatingComment(false);
  };

  const handleClickDelete = (id: string) => {
    setDeleteCommentId(id);
    dispatch(
      prompt({
        title: "Remove Comment?",
        message: `Are you sure you want to remove the Comment from this Crew?`,
      }),
    );
  };

  const handleClickSave = (text: string, id?: string) => {
    setIsCreatingComment(false);

    if (id) {
      const selectedComment = crewComments.find(
        (comment: JobNoteModel) => (comment.id as number) === parseInt(id, 10),
      );

      if (selectedComment) {
        const updatedComment = cloneDeep(selectedComment);

        updatedComment.dwoId = updatedComment.dwoId || undefined;
        updatedComment.note = text;
        dispatch(updateJobNote(id, updatedComment, fetchCommentsQuery));
        return;
      }
    }

    if (currentJob) {
      const newComment: JobNoteModel = {
        dwoId,
        jobId: currentJob.id as number,
        note: text,
      };

      dispatch(createJobNote(newComment, fetchCommentsQuery));
    }
  };

  const handleClickViewMore = () => {
    setIsCreatingComment(false);
    setIsModalOpen(true);
  };

  const renderedNotes = (() => {
    if (crewComments.length <= MAX_NOTES) {
      return crewComments.map((comment: JobNoteModel) => {
        const fullName = comment.createdBy
          ? `${comment.createdBy.employee.firstName} ${comment.createdBy.employee.lastName}`
          : "User Full Name";
        const date = slashFormatDate(new Date(comment.updatedAt as Date));
        return (
          <JobNote
            author={fullName}
            date={date}
            jobId={!Boolean(currentJob) ? comment.job?.jobId : undefined}
            key={comment.id as number}
            text={comment.note}
          />
        );
      });
    }

    const shownNotes = [];

    for (let i = 0; i < MAX_NOTES; i++) {
      const fullName = crewComments[i].createdBy
        ? `${crewComments[i].createdBy?.employee.firstName} ${crewComments[i].createdBy?.employee.lastName}`
        : "User Full Name";
      const date = slashFormatDate(new Date(crewComments[i].updatedAt as Date));
      shownNotes.push(
        <JobNote
          author={fullName}
          date={date}
          jobId={!Boolean(currentJob) ? crewComments[i].job?.jobId : undefined}
          key={crewComments[i].id as number}
          text={crewComments[i].note}
        />,
      );
    }

    return shownNotes;
  })();

  return (
    <Fragment>
      <Paper className={classes.paper}>
        <Box alignItems="center" display="flex" justifyContent="space-between">
          <Typography className={classes.title} variant="h6">
            Crew Comments
          </Typography>
          <IconButton
            aria-label="Add Note"
            className={classes.addIcon}
            disabled={isLoading || !currentJob}
            onClick={handleClickAddButton}
          >
            <AddCircle fontSize="large" />
          </IconButton>
        </Box>

        {isLoading && <LoadingSpinner />}
        {!isLoading && crewComments.length === 0 && (
          <Typography className={classes.missingNotes} align="center">
            There are no Crew Comments to display. Try adding a new one.
          </Typography>
        )}
        {!isLoading && crewComments.length > 0 && (
          <Box display="flex" flexDirection="column">
            {renderedNotes}
            {crewComments.length > 2 && (
              <Box display="flex" justifyContent="center">
                <Button
                  className={classes.viewMoreButton}
                  onClick={handleClickViewMore}
                >
                  View More
                </Button>
              </Box>
            )}
          </Box>
        )}
      </Paper>

      <NotesModal
        displayJobId={!Boolean(currentJob)}
        isContinueDisabled={!Boolean(currentJob)}
        isCreatingNote={Boolean(currentJob) ? isCreatingComment : false}
        isLoading={isLoading}
        isOpen={isModalOpen}
        notes={crewComments}
        title="Crew Comments"
        onClickAdd={handleClickAddNote}
        onClickCancel={handleClickCancelNote}
        onClickClose={handleClickClose}
        onClickDelete={handleClickDelete}
        onClickSave={handleClickSave}
      />
    </Fragment>
  );
}
