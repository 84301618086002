import {
  createStyles,
  FormControl,
  InputLabel,
  makeStyles,
  Select,
  SelectProps,
  Theme,
  Typography,
} from "@material-ui/core";
import { ExpandMore } from "@material-ui/icons";
import React, { Fragment } from "react";
import { v4 as uuid } from "uuid";

interface SelectCustomProps {
  error?: boolean;
  errorMessage?: string;
  backgroundColor?: string;
  borderRadius?: string;
}

interface StyleProps {
  backgroundColor?: string;
  borderRadius?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dropdown: {
      backgroundColor: ({ backgroundColor }: StyleProps) =>
        backgroundColor || theme.palette.grey[300],
      borderRadius: ({ borderRadius }: StyleProps) => borderRadius || "2px",
      height: "48px",
      padding: 0,
      "& .MuiSelect-select:focus": {
        backgroundColor: theme.palette.grey[300],
      },
      "& .MuiSelect-icon": {
        right: 0,
        top: "calc(50% - 16px)",
      },
      "& .MuiSvgIcon-root": {
        color: theme.palette.primary.dark,
        fontSize: "2rem",
      },
      "& .MuiFilledInput-input": {
        padding: "20px 8px 0",
      },
    },
    dropdownMenu: {
      backgroundColor: "#F0EFEF",
      borderRadius: "2px",
      boxShadow: "1px 1px 8px #0000001A",
      color: "#838385",
      display: "flex",
      flexDirection: "column",
      marginTop: "8px",
    },
    errorMessage: {
      color: theme.palette.error.main,
      paddingTop: "4px",
    },
    label: {
      color: theme.palette.primary.main,
      fontSize: "0.75rem",
      fontWeight: "bold",
      left: "-4px",
      top: "8px",
    },
    root: {
      color: theme.palette.grey[600],
    },
  }),
);

export function SelectCustom({
  children,
  error,
  errorMessage,
  backgroundColor,
  borderRadius,
  ...selectProps
}: SelectCustomProps & SelectProps) {
  const classes = useStyles({ backgroundColor, borderRadius });
  const labelId = `selectLabelId-${uuid()}`;
  return (
    <Fragment>
      <FormControl
        fullWidth={selectProps.fullWidth}
        required={selectProps.required}
        variant="filled"
      >
        <InputLabel
          className={classes.label}
          id={selectProps.labelId || labelId}
        >
          {selectProps.label || ""}
        </InputLabel>

        <Select
          classes={{ root: classes.root }}
          className={classes.dropdown}
          IconComponent={ExpandMore}
          labelId={selectProps.labelId || labelId}
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            getContentAnchorEl: undefined,
            PopoverClasses: {
              paper: classes.dropdownMenu,
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
          }}
          {...selectProps}
        >
          {children}
        </Select>
      </FormControl>
      {error && (
        <Typography className={classes.errorMessage} variant="body2">
          {errorMessage}
        </Typography>
      )}
    </Fragment>
  );
}
