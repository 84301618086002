import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import React from "react";
import { slashFormatDate } from "utils/dateUtils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    data: {
      color: theme.palette.grey[600],
      fontWeight: "bold",
    },
    number: {
      color: theme.palette.primary.dark,
      fontWeight: "bold",
    },
  }),
);

interface InvoiceRowProps {
  billed: string;
  date: Date;
  number: string;
  sent: string;
}

export function InvoiceRow({ billed, date, number, sent }: InvoiceRowProps) {
  const classes = useStyles();

  return (
    <Box alignItems="center" display="flex" marginBottom="20px">
      <Box alignItems="center" display="flex" flex={2} marginRight="8px">
        <Typography className={classes.number} variant="body2">
          {`Invoice ${number}`}
        </Typography>
      </Box>

      <Box
        alignItems="center"
        display="flex"
        flex={1}
        justifyContent="flex-end"
        marginRight="8px"
      >
        <Typography className={classes.data} variant="body2">
          {slashFormatDate(date)}
        </Typography>
      </Box>

      <Box
        alignItems="center"
        display="flex"
        flex={1}
        justifyContent="flex-end"
        marginRight="8px"
      >
        <Typography className={classes.data} variant="body2">
          {parseFloat(billed).toFixed(2)}
        </Typography>
      </Box>

      <Box
        alignItems="center"
        display="flex"
        flex={1}
        justifyContent="flex-end"
      >
        <Typography className={classes.data} variant="body2">
          {`$${parseFloat(sent).toFixed(2)}`}
        </Typography>
      </Box>
    </Box>
  );
}
