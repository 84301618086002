export const ALL_OPTION = {
  label: "All",
  value: "all",
};

export interface FileData {
  file?: File;
  fileName: string;
  hasChanged: boolean;
  hasError: boolean;
  src: string | Blob;
}

export enum ReqStatus {
  IDLE = "idle",
  FULFILLED = "fulfilled",
  FILED = "filed",
  PENDING = "pending",
}

export const DEBOUNCE_TIME_MS = 1000;

export const DEFAULT_LIMIT = 10;

export const DEFAULT_QUERY_LIMIT = 300;

export const SMALL_QUERY_LIMIT = 180;
