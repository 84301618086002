import { EmployeePictureModel } from "@dwo/shared/dist/models/employeePictureModel";
import {
  Avatar,
  Box,
  createStyles,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import { Edit } from "@material-ui/icons";
import { DeleteUserImagesModal } from "components/userManagement/UserDetails/DeleteUserImagesModal";
import { UploadUserImagesModal } from "components/userManagement/UserDetails/UploadUserImagesModal";
import React, { Fragment, useEffect, useState } from "react";
import { FileData } from "utils/sharedUtils";

interface AvatarWithPreviewsProps {
  isUploadImagesModalOpen: boolean;
  selectedPictures: FileData[];
  uploadedPictures?: EmployeePictureModel[];
  onToggleCarouselModal: VoidFunction;
}

interface StyleProps {
  isVisibilityHovered: boolean;
}

const useStyles = makeStyles(() =>
  createStyles({
    mainAvatar: {
      height: "150px",
      width: "150px",
    },
    previewAvatar: {
      height: "32px",
      width: "32px",
      marginRight: "8px",
    },
    edit: {
      display: ({ isVisibilityHovered }: StyleProps) =>
        isVisibilityHovered ? "block" : "none",
      color: "white",
      fontSize: "48px",
    },
    editButton: {
      position: "absolute",
      width: "150px",
      height: "150px",
      zIndex: 1,
      backgroundColor: "none",
      "&:hover": {
        backgroundColor: "rgb(131, 131, 133, 0.70)",
      },
    },
  }),
);

export function AvatarWithPreviews({
  isUploadImagesModalOpen,
  selectedPictures,
  uploadedPictures,
  onToggleCarouselModal,
}: AvatarWithPreviewsProps) {
  const uploadedMainAvatar =
    uploadedPictures && uploadedPictures.length > 0 && uploadedPictures[0];
  const selectedMainAvatar = selectedPictures.length > 0 && selectedPictures[0];
  const isPictureSelected = !!selectedMainAvatar;
  const [isVisibilityHovered, setIsVisibilityHovered] = useState(false);
  const [isDeleteImagesModalOpen, setIsDeleteImagesModalOpen] = useState(false);
  const classes = useStyles({ isVisibilityHovered });

  useEffect(() => {
    if (uploadedPictures && uploadedPictures.length === 0) {
      setIsDeleteImagesModalOpen(false);
    }
  }, [uploadedPictures]);

  const getSelectedPreviewAvatars = () => {
    if (isPictureSelected && uploadedPictures?.length === 0) {
      return selectedPictures
        .slice(1, 5)
        .map(({ src, fileName }) => (
          <Avatar
            className={classes.previewAvatar}
            key={`${fileName}-${src}`}
            src={URL.createObjectURL(src)}
          />
        ));
    }

    if (uploadedPictures) {
      if (uploadedPictures.length === 5) {
        return null;
      }

      if (uploadedPictures.length < 5) {
        return selectedPictures
          .slice(0, 5 - uploadedPictures.length)
          .map(({ src, fileName }) => (
            <Avatar
              className={classes.previewAvatar}
              key={`${fileName}-${src}`}
              src={URL.createObjectURL(src)}
            />
          ));
      }
    }
  };

  const getUploadedPreviewAvatars = () => {
    if (uploadedPictures && uploadedPictures.length > 0) {
      return uploadedPictures
        .slice(1, 5)
        .map((employeePicture) => (
          <Avatar
            className={classes.previewAvatar}
            key={`${employeePicture.picture.fileName}-${employeePicture.picture.url}`}
            src={employeePicture.picture.url}
          />
        ));
    }
  };

  const handleVisibilityHover = () =>
    setIsVisibilityHovered(!isVisibilityHovered);

  const handleToggleDeleteUserImagesModal = () =>
    setIsDeleteImagesModalOpen(!isDeleteImagesModalOpen);

  return (
    <Fragment>
      <Box display="flex" flexDirection="column" width="150px">
        <Box marginBottom="16px" role="button">
          {uploadedPictures?.length === 0 ? (
            <Fragment>
              <Avatar
                className={classes.mainAvatar}
                src={
                  selectedMainAvatar
                    ? URL.createObjectURL(selectedMainAvatar.src)
                    : ""
                }
              />
            </Fragment>
          ) : (
            <Fragment>
              {uploadedMainAvatar && (
                <IconButton
                  aria-label="open image carousel modal"
                  className={classes.editButton}
                  onClick={handleToggleDeleteUserImagesModal}
                  onMouseEnter={handleVisibilityHover}
                  onMouseLeave={handleVisibilityHover}
                >
                  <Edit className={classes.edit} />
                </IconButton>
              )}
              <Avatar
                className={classes.mainAvatar}
                src={uploadedMainAvatar ? uploadedMainAvatar.picture.url : ""}
              />
            </Fragment>
          )}
        </Box>

        <Box display="flex" flexDirection="row" marginBottom="16px">
          {getUploadedPreviewAvatars()}
          {getSelectedPreviewAvatars()}
        </Box>
      </Box>
      <UploadUserImagesModal
        open={isUploadImagesModalOpen}
        selectedPictures={selectedPictures}
        onClickClose={onToggleCarouselModal}
      />
      {uploadedPictures && (
        <DeleteUserImagesModal
          open={isDeleteImagesModalOpen}
          uploadedPictures={uploadedPictures}
          onClickClose={handleToggleDeleteUserImagesModal}
        />
      )}
    </Fragment>
  );
}
