import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import { Info } from "@material-ui/icons";
import { GlobalModal } from "components/globalModal/GlobalModal";
import { TableCustom } from "components/table/TableCustom";
import { SearchByNameOrId } from "components/userManagement/SearchByNameOrId";
import {
  getAllEmployeesWithImage,
  selectEmployees,
  selectEmployeesReqStatus,
  setEmployeesReqStatus,
} from "features/employees/employeesSlice";
import { EmployeeModel } from "@dwo/shared/dist/models/employeeModel";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ServiceOptions } from "@dwo/shared/dist/services/baseService";
import {
  getSupervisorAssignmentRows,
  supervisorAssignmentColumns,
} from "utils/employeeDetailsUtils";
import { ReqStatus, DEFAULT_QUERY_LIMIT } from "utils/sharedUtils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    infoLegendIcon: {
      color: theme.palette.grey[500],
      marginRight: "10px",
    },
    infoLegendLabel: {
      color: theme.palette.grey[500],
      fontSize: "16px",
    },
  }),
);

interface MasqueradeUserModalProps {
  isOpen: boolean;
  onClickClose: () => void;
  onClickChangeUser: (supervisorSelectedId: string) => void;
  regionId?: number;
  supervisorAssigned?: EmployeeModel;
}

export function MasqueradeUserModal({
  isOpen = false,
  onClickClose,
  onClickChangeUser,
  regionId,
  supervisorAssigned,
}: MasqueradeUserModalProps) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const employeesReqStatus = useSelector(selectEmployeesReqStatus);
  const supervisorsList = useSelector(selectEmployees).filter(
    (supervisor) => supervisor.id !== supervisorAssigned?.id,
  );
  const isLoading = employeesReqStatus !== ReqStatus.FULFILLED;
  const [supervisorIdSelected, setSupervisorIdSelected] = useState<string>("");
  const [searchedName, setSearchedName] = useState("");
  const [searchedEmployeeId, setSearchedEmployeeId] = useState("");
  const [query] = useState<ServiceOptions>({
    where: { role: { $eq: "supervisor" }, regionId },
    limit: DEFAULT_QUERY_LIMIT,
  });

  useEffect(() => {
    if (isOpen) {
      dispatch(getAllEmployeesWithImage(query));
    }
  }, [dispatch, query, isOpen]);

  const handleClickClose = () => {
    dispatch(setEmployeesReqStatus(ReqStatus.IDLE));
    setSupervisorIdSelected("");
    onClickClose();
  };

  const handleClickCheckbox = (idRow: string, isChecked: boolean) => {
    if (isChecked) {
      setSupervisorIdSelected(idRow);
    }

    if (!isChecked) {
      setSupervisorIdSelected("");
    }
  };

  const handleChangeSearch = useCallback(
    (employeeId: string, employeeName: string) => {
      setSearchedEmployeeId(employeeId);
      setSearchedName(employeeName);
    },
    [],
  );

  const handleChangeUser = () => {
    if (supervisorIdSelected) {
      const supervisorId = supervisorIdSelected;
      dispatch(setEmployeesReqStatus(ReqStatus.IDLE));
      onClickClose();
      onClickChangeUser(supervisorId);
    }
  };

  const filterEmployeesBySearchResult = (data: EmployeeModel[]) => {
    if (searchedEmployeeId && searchedName) {
      return data.filter(
        ({ employeeId, firstName }) =>
          employeeId === searchedEmployeeId && firstName === searchedName,
      );
    }
    return data;
  };

  return (
    <GlobalModal
      modalMaxWidth="600px"
      onClickClose={handleClickClose}
      onClickContinue={handleChangeUser}
      open={isOpen}
      primaryLabel="Change user"
      title="Act as other user"
      customFooter={
        <Box display="flex" flexDirection="column">
          <Box
            alignItems="center"
            display="flex"
            justifyContent="center"
            padding="10px 0px 20px"
          >
            <Info className={classes.infoLegendIcon} fontSize="small" />
            <Typography className={classes.infoLegendLabel} variant="caption">
              You have to Logout to go back to your account.
            </Typography>
          </Box>
        </Box>
      }
    >
      <Box padding="0px 24px">
        <Box padding="24px 0">
          <Typography variant="h6" color="primary">{`Available (${
            isLoading ? "0" : supervisorsList.length
          })`}</Typography>
        </Box>
        <Box marginBottom="24px">
          <SearchByNameOrId
            filteredEmployees={supervisorsList}
            onChange={handleChangeSearch}
          />
        </Box>

        <TableCustom
          columns={supervisorAssignmentColumns}
          isLoading={isLoading}
          noRowsMessage="No supervisors found"
          rows={getSupervisorAssignmentRows(
            filterEmployeesBySearchResult(supervisorsList),
            supervisorIdSelected,
            handleClickCheckbox,
          )}
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          onClickSort={() => {}}
        />
      </Box>
    </GlobalModal>
  );
}
