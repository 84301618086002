import {
  Box,
  IconButton,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from "@material-ui/core";
import { CheckboxCustom } from "components/CheckboxCustom";
import { SortArrowDown, SortArrows, SortArrowUp } from "components/icons";
import React from "react";
import { MainTableColumn, mainTableStyles } from "utils/tableUtils";
import { cloneDeep } from "lodash";

interface TableHeaderProps {
  columns: MainTableColumn[];
  sortingOptions?: string[][];
  onClickSort: (sortingValues: string[][]) => void;
}

export function TableHeaderCustom({
  columns,
  sortingOptions,
  onClickSort,
}: TableHeaderProps) {
  const theme = useTheme();
  const classes = mainTableStyles();

  const handleClickSort = (field: string) => () => {
    if (!sortingOptions || sortingOptions.length === 0) {
      onClickSort([[field, "ASC"]]);
      return;
    }

    const sortingIndex = sortingOptions.findIndex(
      ([currentField]) => currentField === field,
    );

    const sortingOptionsUpdated = cloneDeep(sortingOptions);
    if (sortingIndex >= 0 && sortingOptions[sortingIndex][1] === "ASC") {
      sortingOptionsUpdated[sortingIndex][1] = "DESC";
      onClickSort(sortingOptionsUpdated);
      return;
    }

    sortingOptionsUpdated.splice(sortingIndex, 1);
    onClickSort(sortingOptionsUpdated);
  };

  const getSortIcon = (field: string) => {
    if (sortingOptions) {
      const sortValue = sortingOptions.find(
        ([currentField]) => currentField === field,
      );
      if (sortValue && sortValue[1] === "DESC") {
        return (
          <SortArrowDown color={theme.palette.primary.main} fontSize={18} />
        );
      }

      if (sortValue && sortValue[1] === "ASC") {
        return <SortArrowUp color={theme.palette.primary.main} fontSize={18} />;
      }
    }
    return <SortArrows fontSize={18} />;
  };

  const handleClickCheckbox = (
    onClickCheckbox: (isChecked: boolean) => void,
  ) => (e: React.ChangeEvent<HTMLInputElement>) => {
    onClickCheckbox(e.target.checked);
  };

  return (
    <TableHead>
      <TableRow className={classes.header}>
        {columns.map((column) => (
          <TableCell key={column.field}>
            {column.hasCheckbox &&
              column.onClickCheckbox &&
              !column.headerName && (
                <Box>
                  <CheckboxCustom
                    isChecked={column.isCheckedColumn as boolean}
                    onClickCheckbox={handleClickCheckbox(
                      column.onClickCheckbox,
                    )}
                  />
                </Box>
              )}
            {!column.hasSorting && (
              <Typography variant="subtitle2" className={classes.title}>
                {column.headerName}
              </Typography>
            )}
            {column.hasSorting && (
              <Box display="flex" alignItems="center">
                <Typography variant="subtitle2" className={classes.title}>
                  {column.headerName}
                </Typography>
                <IconButton
                  className={classes.iconButton}
                  onClick={handleClickSort(column.field)}
                >
                  {getSortIcon(column.field)}
                </IconButton>
              </Box>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
