import {
  Box,
  Button,
  createStyles,
  IconButton,
  makeStyles,
  Modal,
  Theme,
  Typography,
} from "@material-ui/core";
import { Cancel } from "@material-ui/icons";
import {
  EmployeeModel,
  NewEmployeeModel,
} from "@dwo/shared/dist/models/employeeModel";
import React, { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import { DEFAULT_EMPTY } from "utils/jobUtils";
import { EmployeeForm } from "./EmployeeForm";
import { DEFAULT_EMPLOYEE_ID, DEFAULT_NAME } from "utils/employeeFormUtils";
import { selectRegions } from "features/region/regionSlice";

import { getLocationToDisplay } from "utils/employeeDetailsUtils";
import {
  getAllEmployeePictures,
  getEmployeeById,
  selectEmployee,
  selectEmployeePictures,
  selectIsActiveEmployee,
  selectLoading,
  setUserActiveStatus,
} from "features/employees/employeesSlice";

interface UserModalProps {
  employeeData?: NewEmployeeModel;
  isEditing?: boolean;
  isOpen: boolean;
  onClickClose: () => void;
  onSubmit: (updateEmployee: NewEmployeeModel) => void;
  supervisorsData?: EmployeeModel;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    closeIcon: {
      color: theme.palette.primary.main,
      padding: 0,
    },
    container: {
      backgroundColor: "white",
      outline: "0",
      maxWidth: "700px",
      width: "90%",
    },
    content: {
      backgroundColor: "white",
      height: "100%",
      maxHeight: "530px",
      maxWidth: "700px",
      outline: 0,
      overflow: "auto",
      padding: "24px 60px 0 60px",
      width: "90%",
    },
    nameHeader: {
      color: theme.palette.primary.dark,
      fontSize: "34px",
      fontWeight: 300,
    },
    IDSubtitle: {
      color: theme.palette.primary.dark,
      fontSize: "18px",
      fontWeight: 300,
    },
    emailAlert: {
      color: "red",
      fontSize: "14px",
      fontWeight: "bold",
    },
    modal: {
      alignItems: "center",
      borderRadius: "2px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
    },
  }),
);

export function CreateEditUserModal({
  employeeData,
  isEditing,
  isOpen,
  onClickClose,
  onSubmit,
  supervisorsData,
}: UserModalProps) {
  const classes = useStyles();
  const currentRegions = useSelector(selectRegions);
  const currentUser = useSelector(selectEmployee);
  const location = getLocationToDisplay(currentUser?.regions);

  const handleClickSave = () =>
    document.getElementById("submitUserForm-btn")?.click();

  return (
    <Modal className={classes.modal} open={isOpen} onClose={onClickClose}>
      <Fragment>
        <Box className={classes.container}>
          <Box
            display="flex"
            width="100%"
            justifyContent="flex-end"
            padding="16px 16px 0 16px"
          >
            <IconButton
              aria-label="Close Modal"
              className={classes.closeIcon}
              onClick={onClickClose}
            >
              <Cancel fontSize="large" />
            </IconButton>
          </Box>
        </Box>
        <Box className={classes.container}>
          <Box padding="0 60px">
            <Typography className={classes.nameHeader} component="h1">
              {`${employeeData?.fullName || DEFAULT_NAME}`}
            </Typography>
            <Typography className={classes.IDSubtitle} component="h2">
              {`Employee ID ${employeeData?.employeeId || DEFAULT_EMPLOYEE_ID}`}
            </Typography>
          </Box>
        </Box>
        <Box className={classes.content}>
          <EmployeeForm
            employeeData={employeeData}
            isEditing={isEditing}
            onSubmit={onSubmit}
            regions={location}
            supervisorsData={supervisorsData}
          />
          <Box display={"flex"} justifyContent={"left"}>
            <Typography className={classes.emailAlert} component="h2">
              Email is required for Foreman or Supervisor.
            </Typography>
          </Box>
        </Box>
        <Box className={classes.container}>
          <Box
            display="flex"
            justifyContent="flex-end"
            padding="24px 60px 40px 60px"
          >
            <Box marginRight="24px" maxWidth="174px" width="100%">
              <Button fullWidth variant="outlined" onClick={onClickClose}>
                Cancel
              </Button>
            </Box>
            <Box maxWidth="174px" width="100%">
              <Button fullWidth variant="contained" onClick={handleClickSave}>
                Save
              </Button>
            </Box>
          </Box>
        </Box>
      </Fragment>
    </Modal>
  );
}
