import {
  Box,
  createStyles,
  makeStyles,
  Theme,
  Typography,
  useTheme,
} from "@material-ui/core";
import Chart from "chart.js";
import React, { useEffect, useRef } from "react";
import { PROGRESS_TYPE_LABELS } from "utils/jobDetailsUtils";

interface EstimatedProgressChartProps {
  progress: number;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    label: {
      color: theme.palette.primary.dark,
    },
    percentage: {
      color: theme.palette.primary.dark,
      fontWeight: 600,
      marginBottom: "4px",
    },
  }),
);

export function EstimatedProgressChart({
  progress,
}: EstimatedProgressChartProps) {
  const chartCanvasRef = useRef({} as HTMLCanvasElement);
  const classes = useStyles();
  const theme = useTheme();

  useEffect(() => {
    new Chart(chartCanvasRef.current, {
      data: {
        datasets: [
          {
            backgroundColor: [
              theme.palette.primary.dark,
              theme.palette.grey[200],
            ],
            borderWidth: 0,
            data: [progress, 100.0 - progress],
            label: PROGRESS_TYPE_LABELS.ESTIMATED,
          },
        ],
      },
      options: {
        animation: {
          animateRotate: true,
        },
        aspectRatio: 1,
        cutoutPercentage: 85,
        hover: {
          mode: undefined,
        },
        tooltips: {
          enabled: false,
        },
      },
      type: "doughnut",
    });
  }, [progress, theme]);

  return (
    <Box
      display="flex"
      justifyContent="center"
      flexDirection="column"
      margin="0 auto"
      maxWidth="175px"
      position="relative"
    >
      <canvas ref={chartCanvasRef} />
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        height="50%"
        justifyContent="center"
        left="25%"
        position="absolute"
        top="25%"
        width="50%"
      >
        <Typography
          className={classes.percentage}
          variant="h5"
        >{`${progress}%`}</Typography>
        <Typography align="center" className={classes.label} variant="body2">
          {PROGRESS_TYPE_LABELS.ESTIMATED}
        </Typography>
      </Box>
    </Box>
  );
}
