import { withStyles } from "@material-ui/styles";
import { Theme, Tooltip } from "@material-ui/core";

export const TooltipCustom = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: theme.palette.grey["100"],
    color: theme.palette.primary.dark,
    padding: "8px",
    borderRadius: 0,
    boxShadow: "4px 4px 8px #00000029",
  },
}))(Tooltip);
