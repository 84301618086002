import {
  Box,
  createStyles,
  IconButton,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";
import { Edit } from "@material-ui/icons";
import { JobModel } from "@dwo/shared/dist/models/jobModel";
import React from "react";
import { AllocationFields } from "./AllocationFields";
import { useSelector } from "react-redux";
import { selectIsActiveEmployee } from "features/employees/employeesSlice";

interface UpcomingJobsInformationProps {
  upcomingJobs?: JobModel[];
  onClickEdit: () => void;
}

interface StyleProps {
  isDisabled: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      color: ({ isDisabled }: StyleProps) =>
        isDisabled ? theme.palette.grey[400] : theme.palette.primary.main,
      marginRight: "20px",
    },
    icon: {
      backgroundColor: ({ isDisabled }: StyleProps) =>
        isDisabled ? theme.palette.grey[400] : theme.palette.primary.main,
      borderRadius: "50%",
      color: "white",
      padding: "4px",
    },
  }),
);

export function UpcomingJobsInformation({
  upcomingJobs,
  onClickEdit,
}: UpcomingJobsInformationProps) {
  const isDisabled = !useSelector(selectIsActiveEmployee);
  const classes = useStyles({ isDisabled });
  return (
    <Box margin="0 24px 24px 24px" paddingTop="24px">
      <Box alignItems="center" display="flex" marginBottom="24px">
        <Typography variant="h5" className={classes.header}>
          Upcoming Jobs
        </Typography>
        <IconButton
          className={classes.icon}
          onClick={onClickEdit}
          disabled={isDisabled}
        >
          <Edit />
        </IconButton>
      </Box>
      {upcomingJobs &&
        upcomingJobs.map((job: JobModel, index: number) => (
          <Box
            key={`${job.id}-${index}`}
            paddingBottom={index !== upcomingJobs.length - 1 ? "64px" : "0"}
          >
            <AllocationFields information={job} />
          </Box>
        ))}
    </Box>
  );
}
