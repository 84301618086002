import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  Box,
  createStyles,
  makeStyles,
  Paper,
  Theme,
  Typography,
} from "@material-ui/core";
import { cloneDeep } from "lodash";

import { JobsSummaryData } from "@dwo/shared/dist/models/jobSummaryModel";
import { ServiceOptions } from "@dwo/shared/dist/services/baseService";
import { JobInfoCard } from "./JobInfoCard";
import {
  selectSummaryGeneralData,
  selectSummarySelectedDateRange,
  selectSummarySelectedRegion,
} from "features/summary/summarySlice";
import { setJobFilters, setJobQuery } from "features/jobs/jobsSlice";
import { setJobPerformanceTabSelected } from "features/dwo/dwoSlice";
import { ALL_OPTION, DEFAULT_LIMIT } from "utils/sharedUtils";
import { JobStatusValues, JobTabs } from "utils/jobUtils";
import { rowSelectOptions } from "utils/tableUtils";
import { ROOT_JOB_MANAGEMENT } from "routes/Roots";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardsContainer: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-between",
      margin: "0 auto",
      maxWidth: "1576px",
      width: "100%",
      [theme.breakpoints.down("md")]: {
        justifyContent: "flex-start",
      },
    },
    container: {
      display: "flex",
      flexDirection: "column",
      margin: "0 24px 24px 24px",
      padding: "24px 32px 0 32px",
    },
    title: {
      color: theme.palette.primary.main,
    },
    clickable: {
      cursor: "pointer",
    },
  }),
);

export function SummaryJobsCard() {
  const summaryData = useSelector(selectSummaryGeneralData);
  const classes = useStyles();

  const currentDateRange = useSelector(selectSummarySelectedDateRange);
  const currentRegion = useSelector(selectSummarySelectedRegion);
  const dispatch = useDispatch();
  const history = useHistory();

  const assignedData: JobsSummaryData = {
    overcost: 0,
    overtime: 0,
    total: summaryData?.assigned.total || 0,
  };

  const completedData: JobsSummaryData = {
    overcost: summaryData?.completed.overcost || 0,
    overtime: summaryData?.completed.overtime || 0,
    total: summaryData?.completed.total || 0,
  };

  const inProgressData: JobsSummaryData = {
    overcost: summaryData?.inProgress.overcost || 0,
    overtime: summaryData?.inProgress.overtime || 0,
    total: summaryData?.inProgress.total || 0,
  };

  const suspendedData: JobsSummaryData = {
    overcost: summaryData?.suspended.overcost || 0,
    overtime: summaryData?.suspended.overtime || 0,
    total: summaryData?.suspended.total || 0,
  };

  const totalData: JobsSummaryData = {
    overcost: summaryData?.total.overcost || 0,
    overtime: summaryData?.total.overtime || 0,
    total: summaryData?.total.total || 0,
  };

  const unassignedData: JobsSummaryData = {
    overcost: 0,
    overtime: 0,
    total: summaryData?.unassigned.total || 0,
  };

  const handleClickCard = (jobStatus: string) => () => {
    const initialJobQuery: ServiceOptions = {
      limit: DEFAULT_LIMIT,
      include: [{ Crew: ["foreman"] }, "supervisor"],
    };

    const freshQuery: ServiceOptions = cloneDeep(initialJobQuery);

    freshQuery.where = {
      ...freshQuery.where,
      status: jobStatus === ALL_OPTION.value ? undefined : jobStatus,
    };

    dispatch(setJobPerformanceTabSelected(JobTabs.ALL));
    dispatch(
      setJobFilters({
        searchValue: "",
        region:
          currentRegion === "all" || currentRegion === ""
            ? []
            : [currentRegion],
        status: [jobStatus],
        sortingOptions: [],
        page: 1,
        rowsPerPage: rowSelectOptions[0].value,
      }),
    );
    dispatch(setJobQuery(freshQuery));
    history.push(ROOT_JOB_MANAGEMENT);
  };

  return (
    <Paper className={classes.container}>
      <Box width="100%" marginBottom="24px">
        <Typography className={classes.title} variant="h6">
          Jobs
        </Typography>
      </Box>

      <Box className={classes.cardsContainer}>
        <Box flex={2} margin="0 24px 24px 0" maxWidth="416px" width="100%">
          <JobInfoCard data={totalData} description="Total Jobs" />
        </Box>
        <Box
          flex={1}
          margin="0 24px 24px 0"
          maxWidth="208px"
          width="100%"
          className={classes.clickable}
          onClick={handleClickCard(JobStatusValues.ASSIGNED)}
        >
          <JobInfoCard data={assignedData} description="Assigned" />
        </Box>
        <Box
          flex={1}
          margin="0 24px 24px 0"
          maxWidth="208px"
          width="100%"
          className={classes.clickable}
          onClick={handleClickCard(JobStatusValues.IN_PROGRESS)}
        >
          <JobInfoCard data={inProgressData} description="In Progress" />
        </Box>
        <Box flex={1} margin="0 24px 24px 0" maxWidth="208px" width="100%">
          <JobInfoCard data={suspendedData} description="Suspended" />
        </Box>
        <Box flex={1} margin="0 24px 24px 0" maxWidth="208px" width="100%">
          <JobInfoCard data={completedData} description="Completed" />
        </Box>
        <Box flex={1} marginBottom="24px" maxWidth="208px" width="100%">
          <JobInfoCard data={unassignedData} description="Unassigned" />
        </Box>
      </Box>
    </Paper>
  );
}
