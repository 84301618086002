import {
  Box,
  createStyles,
  Grid,
  makeStyles,
  Theme,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { LoadingSpinner } from "components/LoadingSpinner";
import {
  contactInfoFields,
  personalInfoFields,
  supervisorInfoFields,
} from "components/userManagement/EmployeeInformation/EmployeeInformationUtils";
import { GenerateInformation } from "components/userManagement/EmployeeInformation/GenerateInformation";
import { RateInformation } from "components/userManagement/EmployeeInformation/RateInformation";
import {
  getEmployeeById,
  selectEmployee,
  selectIsEditModalOpen,
  selectIsLoadingUpdate,
  selectLoading,
  setIsEditModalOpen,
  updateEmployee,
} from "features/employees/employeesSlice";
import { selectCurrentUser } from "features/logIn/sessionSlice";
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { NewEmployeeModel } from "@dwo/shared/dist/models/employeeModel";
import { CreateEditUserModal } from "components/userManagement/EmployeeInformation/CreateEditUserModal";
import { EditIconButton } from "components/icons/EditIconButton";
import { initialEmployeeDetailsQuery } from "utils/employeeDetailsUtils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    errorMessage: {
      color: theme.palette.error.main,
      paddingTop: "4px",
    },
    noInfoHeader: {
      color: theme.palette.grey[400],
      marginBottom: "24px",
      marginLeft: "24px",
    },
    //TODO: Save Confirmation Modal if client asks for it.
    // goBackModal: {
    //   display: "flex",
    //   alignItems: "center",
    //   justifyContent: "center",
    //   flexDirection: "column",
    // },
    // container: {
    //   backgroundColor: "white",
    //   outline: "0",
    //   maxWidth: "615px",
    //   width: "90%",
    // },
  }),
);

export function EmployeeInformation() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const currentUser = useSelector(selectCurrentUser);
  const employee = useSelector(selectEmployee);
  const { id } = useParams<{ id: string }>();
  const userId = parseInt(id, 10);
  const isLoadingEmployeeData = useSelector(selectLoading);
  const isLoadingUpdate = useSelector(selectIsLoadingUpdate);
  const isEditModalOpen = useSelector(selectIsEditModalOpen);
  const isLoading = isLoadingEmployeeData || isLoadingUpdate;

  const supervisorsData = employee?.employeeSupervisors?.find(
    (supervisor: any) => supervisor.isCurrent === true,
  );

  const handleClickCancel = () => dispatch(setIsEditModalOpen(false));

  const handleClickUpdateEmployee = () => dispatch(setIsEditModalOpen(true));

  const handleClickSave = (updatedEmployee: NewEmployeeModel) => {
    dispatch(updateEmployee(updatedEmployee, initialEmployeeDetailsQuery));
  };

  useEffect(() => {
    dispatch(getEmployeeById(userId, initialEmployeeDetailsQuery));
  }, [userId, dispatch]);

  return (
    <Grid alignItems="center" container spacing={4}>
      <Grid item xs={12}>
        {(currentUser?.employee.role === "admin" ||
          currentUser?.employee.role === "subadmin") && (
          <Box
            alignItems="center"
            display="flex"
            justifyContent="end"
            marginBottom={"-56px"}
          >
            {employee?.role === "admin" || employee?.role === "subadmin" ? (
              <Tooltip
                title={
                  <Typography>Administrator roles cannot be edited</Typography>
                }
                placement="left"
                arrow
              >
                <Box>
                  <EditIconButton isDisabled={true} onClickEdit={() => {}} />
                </Box>
              </Tooltip>
            ) : (
              <Box>
                <EditIconButton
                  isDisabled={isLoading}
                  onClickEdit={handleClickUpdateEmployee}
                />
              </Box>
            )}
          </Box>
        )}

        {isLoading && (
          <Grid item sm={12}>
            <LoadingSpinner
              BoxProps={{ padding: 10 }}
              CircularProgressProps={{ size: "40px" }}
            />
          </Grid>
        )}

        {!isLoading && (
          <Grid item sm={12}>
            <GenerateInformation
              header="Personal Info"
              information={employee}
              infoFields={personalInfoFields}
            />
            <GenerateInformation
              header="Contact Info"
              information={employee}
              infoFields={contactInfoFields}
            />
            {supervisorsData?.supervisor ? (
              <GenerateInformation
                header="Supervisor Info"
                information={supervisorsData?.supervisor}
                infoFields={supervisorInfoFields}
              />
            ) : (
              <Typography variant="h5" className={classes.noInfoHeader}>
                No Supervisor Assigned
              </Typography>
            )}
            {currentUser?.employee.role !== "supervisor" && (
              <RateInformation header="Rate Info" information={employee} />
            )}
          </Grid>
        )}
      </Grid>
      <CreateEditUserModal
        employeeData={employee}
        supervisorsData={supervisorsData}
        isEditing={true}
        isOpen={isEditModalOpen}
        onClickClose={handleClickCancel}
        onSubmit={handleClickSave}
      />
    </Grid>
  );
}
